import { Entity, Of } from '@utils';

@Entity
export class RequestInformationInput {
    @Of(() => String)
    memberId = '';

    @Of(() => String)
    firstName = '';

    @Of(() => String)
    lastName = '';

    @Of(() => String)
    email = '';

    @Of(() => String)
    destination = '';

    @Of(() => String)
    sailDate = '';

    @Of(() => String)
    travelers = '';

    @Of(() => String)
    cruiseLine = '';

    @Of(() => String)
    shipName = '';

    @Of(() => String)
    departurePort = '';

    @Of(() => Number, { nullable: true, optional: true })
    numDays?: number;

    @Of(() => String)
    cabinType = '';

    @Of(() => String)
    additionalInfo = '';

    static of = Entity.of<RequestInformationInput>();
}
