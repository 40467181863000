import { SearchRequest } from '../request/search-request';

import { Cruise } from './cruise';
import { SearchFilters } from './search-response';

export class SavedSearch {
    items: Cruise[];
    searchParams: SearchRequest;
    filters: SearchFilters;
    restricted: boolean;
}
