import { Component, Input } from '@angular/core';
import { SiteBranding } from '@core/models';
import { UrlService } from '@shared/services';

@Component({
    selector: 'app-default-footer',
    templateUrl: './default-footer.component.html',
    styleUrls: ['./default-footer.component.scss'],
})
export class DefaultFooterComponent {
    @Input()
    siteBranding: SiteBranding;

    @Input()
    menuItems: any[] = [];

    @Input()
    logoUrl: string;

    @Input()
    copyright: string;

    currentYear: number = new Date().getFullYear();

    constructor(public url: UrlService) {}
}
