import { MemberName } from '@account/models/response/member-name';
import { PrimaryMember } from '@account/models/response/primary-member';
import { Entity, Of } from '@utils';

@Entity
export class PrimaryMemberRequest {
    @Of(() => PrimaryMember)
    primaryMember: PrimaryMember;

    @Of(() => MemberName, { nullable: true, optional: true })
    secondaryMember?: MemberName;

    @Of(() => Boolean)
    updateTravelerProfile: boolean;

    static of = Entity.of<PrimaryMemberRequest>();
}
