export enum StorageKeys {
    SEARCH_REQUEST = 'escapes_search_request',
    SEARCH_RESPONSE = 'escapes',
    SEARCH_FILTERS = 'escapes_filters',
    SEARCH_FILTERS_MIN_PRICE = 'escapes_min_price',
    SEARCH_FILTERS_MAX_PRICE = 'escapes_max_price',
    SEARCH_ITEM = 'escape_search_item',
    ITEM_TO_BOOK = 'escape_to_book',
    BOOK_RESPONSE = 'escapes_book_response',
    ROOMS_TO_BOOK = 'escapes_rooms_to_book',
    SEARCH_ORIGIN_POINT = 'escapes_search_origin_point',
    SEARCH_START_TIME = 'escapes_search_start_time',
    SEARCH_RESULTS_START_TIME = 'escapes_search_results_start_time',
    BOOK_START_TIME = 'escapes_book_start_time',
    SEARCH_TRIGGERED = 'escapes_search_triggered',
    BOOK_TRIGGERED = 'escapes_book_triggered',
}
