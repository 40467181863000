import { IsoCurrency } from '@core/models';
import { Field, mapObjectToEntityFallback } from '@utils';
import dayjs from 'dayjs';

import { CardProfile } from './card-profile';

export interface ElavonAuthResponse {
    acsTransID: string;
    authenticated: boolean;
    authenticationType: string;
    authenticationValue: string;
    cardholderInfo: string;
    dsTransID: string;
    eci: string;
    interactionCounter: string;
    message: string;
    messageCategory: string;
    messageId: string;
    messageType: string;
    messageVersion: string;
    threeDSServerTransID: string;
    transStatus: string;
    transStatusReason: string;
}

export class ElavonAuthRequest {
    @Field
    purchaseAmount: string;

    @Field
    purchaseCurrency: string;

    @Field
    purchaseExponent: number;

    @Field
    acctNumber: string;

    @Field
    cardExpiryDate: string;

    @Field
    purchaseDate: string;

    messageCategory = '01';
    transType = '01';
    threeDSRequestorAuthenticationInd = '01';
    challengeWindowSize = '03';

    meta = {
        displayMode: 'lightbox',
        challengeIframeElement: null,
    };

    static of(currency: IsoCurrency, card: CardProfile, object: Partial<ElavonAuthRequest>): ElavonAuthRequest {
        Object.assign(object, {
            purchaseDate: dayjs().format('YYYYMMDDHHmmss'),
            purchaseCurrency: currency.isoCode,
            purchaseExponent: currency.minorUnits,
            acctNumber: card.ccNumber,
            cardExpiryDate: `${card.ccExpM < 10 ? '0' + card.ccExpM : card.ccExpM}${card.ccExpY
                .toString()
                .substring(2, 4)}`,
        });

        return mapObjectToEntityFallback<ElavonAuthRequest>(object, ElavonAuthRequest);
    }
}
