<div appTranslate>
    <p
        class="section-title margin-bottom-20 margin-xs-bottom-0"
        [attr.data-i18n-code]="'core.labels.yourRecentSearches'"
    ></p>

    <div class="recent-searches-wrapper margin-bottom-60 margin-xs-bottom-20">
        <ng-container *ngFor="let search of searches">
            <button class="cursor-pointer ada-button" (click)="onSelect.emit(search)">
                <app-hotels-recent-search
                    class="recent-search-wrapper"
                    [search]="search"
                    *ngIf="module === modules.HOTELS"
                ></app-hotels-recent-search>

                <app-flights-recent-search
                    class="recent-search-wrapper"
                    [search]="search"
                    *ngIf="module === modules.FLIGHTS"
                ></app-flights-recent-search>

                <app-cars-recent-search
                    class="recent-search-wrapper"
                    [search]="search"
                    *ngIf="module === modules.CARS"
                ></app-cars-recent-search>

                <app-weeks-recent-search
                    class="recent-search-wrapper"
                    [search]="search"
                    *ngIf="module === modules.WEEKS"
                ></app-weeks-recent-search>

                <app-cruises-recent-search
                    class="recent-search-wrapper"
                    [search]="search"
                    *ngIf="module === modules.CRUISES"
                ></app-cruises-recent-search>

                <app-activities-recent-search
                    class="recent-search-wrapper"
                    [search]="search"
                    *ngIf="module === modules.ACTIVITIES"
                ></app-activities-recent-search>
            </button>
        </ng-container>
    </div>
</div>
