<div
    appTranslate
    class="points-wrapper flex-container align-items-center height-100"
    *ngIf="accountDetails; else loader"
>
    <div class="points-section flex-grow-1 text-center">
        <p class="user-property-title text-uppercase" [attr.data-i18n-code]="'core.labels.available'"></p>
        <p class="user-points margin-bottom-0">{{ accountDetails?.pointsSummary?.available | number }}</p>
    </div>

    <div class="points-section flex-grow-1 text-center">
        <p class="user-property-title text-uppercase" [attr.data-i18n-code]="'core.labels.redeemed'"></p>
        <p class="user-points margin-bottom-0">{{ accountDetails?.pointsSummary?.redeemed | number }}</p>
    </div>

    <div class="points-section flex-grow-1 text-center">
        <p class="user-property-title text-uppercase" [attr.data-i18n-code]="'core.labels.savings'"></p>
        <p class="user-points margin-bottom-0" *ngIf="accountDetails?.pointsSummary?.savings">
            {{
                accountDetails?.pointsSummary?.savings?.value
                    | formatCurrency: accountDetails?.pointsSummary?.savings?.code
            }}
        </p>
        <p class="user-points margin-bottom-0" *ngIf="!accountDetails?.pointsSummary?.savings">0</p>
    </div>
</div>

<ng-template #loader>
    <div class="loader-wrapper flex-container flex-column align-items-center justify-content-center width-100">
        <mat-spinner></mat-spinner>
    </div>
</ng-template>
