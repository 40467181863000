import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { LoaderService } from '@core/services';
import { HotelDetails } from '@hotels/models';
import { HotelsService } from '@hotels/services/hotels.service';
import { DataTemplate } from '@shared/models';
import { GlobalHelpersService, UrlService } from '@shared/services';
import { of, Subject } from 'rxjs';
import { catchError, finalize, mergeMap, take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class HotelDetailsResolverService implements Resolve<HotelDetails>, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    constructor(
        private hotelsService: HotelsService,
        private loader: LoaderService,
        private url: UrlService,
        private router: Router,
        private helpers: GlobalHelpersService
    ) {}

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    resolve(route: ActivatedRouteSnapshot): any {
        this.loader.show();

        return this.hotelsService.getDetails(route.params['id']).pipe(
            take(1),
            finalize(() => this.loader.hide()),
            mergeMap((response: DataTemplate<HotelDetails>) => {
                this.loader.hide();

                if (!response.data.images && response.data.imageUrl) {
                    response.data.images = [response.data.imageUrl, response.data.imageUrl];
                }

                response.data.stars = this.helpers.getArrayOfNumbers(response.data.rating);

                return of(response.data);
            }),
            catchError(() => {
                this.loader.hide();
                this.router.navigateByUrl(this.url.getUrl('hotels'));
                return of(null);
            })
        );
    }
}
