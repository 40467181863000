<div appTranslate id="forgot-password-form">
    <h1 class="primary-title margin-bottom-10" [attr.data-i18n-code]="'forgotPassword.headline.label'"></h1>
    <p class="margin-bottom-30" [attr.data-i18n-code]="'forgotPassword.subtitle.label'"></p>

    <form class="forgot-password-form width-100" [formGroup]="forgotPasswordFormGroup">
        <app-input
            label="core.labels.forgotPasswordUsername"
            [inputType]="'text'"
            [group]="forgotPasswordFormGroup"
            [errorMessages]="formErrors?.username"
            name="username"
        ></app-input>
        <app-input
            label="core.labels.forgotUsernameLastName"
            [inputType]="'text'"
            [group]="forgotPasswordFormGroup"
            [errorMessages]="formErrors?.lastName"
            name="lastName"
        ></app-input>

        <p class="margin-bottom-50 margin-xs-bottom-25" [attr.data-i18n-code]="'forgotPassword.info.label'"></p>

        <p class="success-message margin-bottom-20" *ngIf="successMessage" [innerHTML]="successMessage"></p>
        <p class="error-message margin-bottom-20" *ngIf="errorMessage" [innerHtml]="errorMessage"></p>

        <button
            mat-raised-button
            type="submit"
            class="action-button submit-button text-uppercase"
            [color]="'primary'"
            (click)="submitForgotPassword()"
        >
            <span [attr.data-i18n-code]="'core.labels.submit'"></span>
        </button>

        <button mat-stroked-button class="action-button text-uppercase" [color]="'primary'" (click)="target.emit(1)">
            <span [attr.data-i18n-code]="'core.labels.goBack'"></span>
        </button>
    </form>
</div>
