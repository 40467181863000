import { Injectable } from '@angular/core';
import { LoaderState } from '@core/models';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LoaderService {
    private loaderSubject = new Subject<LoaderState>();
    loaderState = this.loaderSubject.asObservable();
    loaderCount = 0;

    show(): void {
        this.loaderSubject.next({ show: true } as LoaderState);
        this.loaderCount++;
    }

    hide(forceHide?: boolean): void {
        if (this.loaderCount > 0) {
            this.loaderCount--;
        }

        if (!this.loaderCount || forceHide) {
            this.loaderCount = 0;
            this.loaderSubject.next({ show: false } as LoaderState);
        }
    }
}
