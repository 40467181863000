import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { BookRequest, BookResponse, OfferDetails, RequestReservationRequest } from '@offers/models';
import { BookPrice, CardProfile, ComputePriceInput, DataTemplate, TravelerProfile } from '@shared/models';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class OffersService {
    constructor(private http: HttpClient) {}

    getDetails(offerId: number): Observable<DataTemplate<OfferDetails>> {
        const url = `${environment.offersPath}/${offerId}`;

        return this.http.get(url) as Observable<DataTemplate<OfferDetails>>;
    }

    requestReservations(request: RequestReservationRequest): Observable<boolean> {
        const url = `${environment.offersPath}/request`;

        return this.http.post(url, request) as Observable<boolean>;
    }

    getTravelerProfiles(): Observable<DataTemplate<TravelerProfile[]>> {
        const url = `${environment.offersPath}/travprofiles`;

        return this.http.get(url) as Observable<DataTemplate<TravelerProfile[]>>;
    }

    getCardProfiles(): Observable<DataTemplate<CardProfile[]>> {
        const url = `${environment.offersPath}/ccardprofiles`;

        return this.http.get(url) as Observable<DataTemplate<CardProfile[]>>;
    }

    computePrice(offerId: string, options?: ComputePriceInput): Observable<DataTemplate<BookPrice>> {
        const url = `${environment.offersPath}/${offerId}/price`;

        let params = new HttpParams();

        if (options?.payPoints || options?.payPoints === 0) {
            params = params.append('payPoints', options.payPoints.toString());
        }

        if (options?.useCredit) {
            params = params.append('useCredit', 'true');
        }

        return this.http.get(url, { params }) as Observable<DataTemplate<BookPrice>>;
    }

    book(request: BookRequest): Observable<DataTemplate<BookResponse>> {
        const url = `${environment.offersPath}/book`;

        return this.http.post(url, request) as Observable<DataTemplate<BookResponse>>;
    }
}
