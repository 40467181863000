import { Entity, Of } from '@utils';

import { GenericListItem } from '../service-helpers/generic-search-response';

import { AssetValue } from './asset-value';

@Entity
export class BookPrice {
    @Of(() => AssetValue)
    earns: AssetValue;

    @Of(() => String)
    earnDelayDate: string;

    @Of(() => AssetValue)
    redeem: AssetValue;

    @Of(() => AssetValue)
    tax: AssetValue;

    @Of(() => Boolean, { optional: true })
    taxIncluded: boolean;

    @Of(() => AssetValue)
    totalAmount: AssetValue;

    @Of(() => AssetValue)
    youPay: AssetValue;

    @Of(() => AssetValue)
    youSave: AssetValue;

    @Of(() => Number, { optional: true })
    youSavePct: number;

    @Of(() => AssetValue)
    redeemMin: AssetValue;

    @Of(() => AssetValue)
    redeemMax: AssetValue;

    @Of(() => AssetValue)
    creditBalance: AssetValue;

    @Of(() => AssetValue)
    maxCreditAmt: AssetValue;

    @Of(() => AssetValue)
    creditUsedAmt: AssetValue;

    @Of(() => AssetValue)
    retailPrice: AssetValue = null;

    @Of(() => [GenericListItem], { optional: true })
    seatings: GenericListItem[] = [];

    @Of(() => [Number], { optional: true })
    tableSizes: number[] = [];

    @Of(() => AssetValue)
    bookingFee: AssetValue = null;

    @Of(() => AssetValue)
    providerTax: AssetValue = null;

    @Of(() => Boolean)
    redeemSliderInd = true;

    @Of(() => Boolean)
    disablePaymentProfiles = false;

    @Of(() => AssetValue)
    membershipFee: AssetValue = null;

    @Of(() => AssetValue)
    dueAtArrival: AssetValue = null;

    @Of(() => AssetValue)
    dueAtArrivalLocal: AssetValue = null;

    @Of(() => [AssetValue])
    fees: AssetValue[] = [];

    @Of(() => [AssetValue])
    hotelFees: AssetValue[] = [];

    @Of(() => [AssetValue])
    includedFees: AssetValue[] = [];

    @Of(() => [AssetValue])
    optionalFees: AssetValue[] = [];

    @Of(() => AssetValue)
    serviceCharge: AssetValue = null;

    @Of(() => AssetValue)
    youPayPerNight: AssetValue = null;

    @Of(() => AssetValue)
    reservationTotal: AssetValue = null;

    static of = Entity.of<BookPrice>();
}
