<div class="widget-2-grid">
    <ng-container *ngFor="let item of widget.elements">
        <div appTranslate class="item flex-container flex-column justify-content-space-between">
            <div class="item flex-container flex-column">
                <img [attr.src]="item.image" class="image margin-bottom-20" *ngIf="item.image" />

                <p class="title text-center" [attr.data-i18n-code]="item.titleKey"></p>

                <img [attr.src]="item.icon" class="icon" *ngIf="item.icon" />

                <p class="description text-center margin-bottom-20" [attr.data-i18n-code]="item.descriptionKey"></p>
            </div>

            <div class="flex-container justify-content-center">
                <button
                    mat-raised-button
                    [color]="'primary'"
                    [attr.data-i18n-code]="item.actionKey"
                    (click)="openModal(item.module)"
                ></button>
            </div>
        </div>
    </ng-container>
</div>
