import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'app-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent {
    @Input()
    hint: string;

    @Input()
    group: UntypedFormGroup;

    @Input()
    label: string;

    @Input()
    errorMessages: string;

    @Input()
    name: string;

    @Input()
    color: string;

    @Input()
    readonly: boolean;

    @Input()
    disabled: boolean;
}
