import { AccountDetails } from '@account/models';
import { AccountService } from '@account/services';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SiteBranding } from '@core/models';
import { HotelsService } from '@hotels/services';
import { DataTemplate, SelectOption, SharedStorageKeys, TravelerProfile } from '@shared/models';
import {
    CheckDataService,
    FormErrorService,
    I18nRefreshService,
    I18nService,
    LocationsService,
} from '@shared/services';
import { forkJoin, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-membership-user-form',
    templateUrl: './membership-user-form.component.html',
})
export class MembershipUserFormComponent implements OnInit, OnDestroy {
    @Input()
    countries: SelectOption[] = [];

    sharedStorageKeys = SharedStorageKeys;
    siteBranding: SiteBranding;
    loaded: boolean;
    provinces: SelectOption[] = [];
    restrictTraveler: boolean;
    travelerProfiles: TravelerProfile[] = [];
    accountDetails: AccountDetails;

    newTraveler = true;
    activeTraveler: TravelerProfile;
    activeTravelerId: number;
    freshProfileSwitch: boolean;

    travelerFormGroup: UntypedFormGroup;
    formErrors: { [key: string]: string } = {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        address1: '',
        city: '',
        countryId: '',
        provinceId: '',
        provinceName: '',
        postalCode: '',
    };
    validationMessages = {};

    private unsubscribe: Subject<void> = new Subject<void>();

    constructor(
        private hotelsService: HotelsService,
        private accountService: AccountService,
        private locationsService: LocationsService,
        private formErrorService: FormErrorService,
        private checkDataService: CheckDataService,
        private i18nService: I18nService,
        private i18nRefreshService: I18nRefreshService
    ) {}

    ngOnInit(): void {
        this.i18nRefreshService
            .getState()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.getValidationMessages(true);
            });

        this.getValidationMessages();
        this.populateContent();
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    populateContent(): void {
        this.siteBranding = JSON.parse(localStorage.getItem(this.sharedStorageKeys.SITE_BRANDING)) as SiteBranding;
        this.restrictTraveler = this.siteBranding?.uiConfig.moduleOptions.hotels.restrictTraveler;

        forkJoin({
            travelerProfiles: this.hotelsService.getTravelerProfiles(),
            accountDetails: this.accountService.getDetails(),
        })
            .pipe(
                takeUntil(this.unsubscribe),
                finalize(() => (this.loaded = true))
            )
            .subscribe(
                (response: {
                    travelerProfiles: DataTemplate<TravelerProfile[]>;
                    accountDetails: DataTemplate<AccountDetails>;
                }) => {
                    this.travelerProfiles = response.travelerProfiles.data;
                    this.accountDetails = response.accountDetails.data;
                    this.createTravelerForm();
                },
                (error: string) => {
                    console.error(error);
                }
            );
    }

    setActiveTraveler(traveler: TravelerProfile): void {
        if (traveler) {
            this.newTraveler = false;
            this.activeTraveler = traveler;
            this.activeTravelerId = traveler.id;
            this.freshProfileSwitch = true;
            this.travelerFormGroup.reset();
            this.travelerFormGroup.patchValue(traveler);
        } else {
            this.newTraveler = true;
            this.activeTraveler = null;
            this.travelerFormGroup.reset();
        }

        if (!this.travelerProfiles.length && this.restrictTraveler) {
            this.travelerFormGroup.patchValue({
                firstName: this.accountDetails.primaryMember?.firstName,
                lastName: this.accountDetails.primaryMember?.lastName,
            });
        }

        this.checkDataService.leadTraveler.emit(
            this.newTraveler ? this.travelerFormGroup.getRawValue() : this.activeTravelerId
        );
    }

    createTravelerForm(): void {
        this.checkDataService.checkData.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
            this.checkData();
        });

        this.travelerFormGroup = new UntypedFormGroup({
            firstName: new UntypedFormControl('', [Validators.required]),
            lastName: new UntypedFormControl('', [Validators.required]),
            email: new UntypedFormControl('', [Validators.required, Validators.email]),
            phone: new UntypedFormControl('', [Validators.required]),
            address1: new UntypedFormControl('', [Validators.required]),
            city: new UntypedFormControl('', [Validators.required]),
            countryId: new UntypedFormControl('', [Validators.required]),
            provinceId: new UntypedFormControl('', []),
            provinceName: new UntypedFormControl('', [Validators.required]),
            postalCode: new UntypedFormControl('', [Validators.required]),
            saveTraveler: new UntypedFormControl(false),
            updateTraveler: new UntypedFormControl(false),
        });

        this.travelerFormGroup.valueChanges
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() =>
                this.formErrorService.checkForErrors(this.travelerFormGroup, this.formErrors, this.validationMessages)
            );

        let prevCountry = null;
        this.travelerFormGroup.get('countryId').valueChanges.subscribe((val: number) => {
            if (val !== prevCountry) {
                prevCountry = val;
                this.loadProvinces(val);
            }
        });
    }

    loadProvinces(countryId: number): void {
        this.provinces = [];

        if (this.newTraveler) {
            this.travelerFormGroup.get('provinceName').setValidators(Validators.required);
        }

        if (countryId) {
            this.locationsService
                .getProvinces(countryId)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe(
                    (response: DataTemplate<{ [key: number]: string }>) => {
                        for (const key in response.data) {
                            if (response.data.hasOwnProperty(parseInt(key, 10))) {
                                this.provinces.push({
                                    value: parseInt(key, 10),
                                    viewValue: response.data[key],
                                });
                            }
                        }

                        if (!this.provinces.length) {
                            this.travelerFormGroup.get('provinceId').setValidators(null);
                            this.travelerFormGroup.get('provinceId').setValue(null);
                            this.travelerFormGroup.get('provinceName').setValidators(Validators.required);

                            if (!this.newTraveler && this.freshProfileSwitch) {
                                this.freshProfileSwitch = false;
                                this.travelerFormGroup.get('provinceName').setValue(this.activeTraveler.provinceName);
                            }
                        } else {
                            this.travelerFormGroup.get('provinceId').setValidators(Validators.required);
                            this.travelerFormGroup.get('provinceId').setValue(null);
                            this.travelerFormGroup.get('provinceName').setValidators(null);
                            this.travelerFormGroup.get('provinceName').setValue(null);

                            if (!this.newTraveler && this.freshProfileSwitch) {
                                this.freshProfileSwitch = false;
                                this.travelerFormGroup.get('provinceId').setValue(this.activeTraveler.provinceId);
                            }
                        }
                    },
                    (error: string) => {
                        console.error(error);
                    }
                );
        }
    }

    checkData(): void {
        this.formErrorService.markAsDirty(this.travelerFormGroup);
        this.formErrorService.checkForErrors(this.travelerFormGroup, this.formErrors, this.validationMessages);

        if (this.travelerFormGroup.valid) {
            if (this.newTraveler) {
                this.checkDataService.leadTraveler.emit(this.travelerFormGroup.getRawValue());
            } else {
                this.checkDataService.leadTraveler.emit(this.travelerFormGroup.getRawValue());
                this.checkDataService.leadTraveler.emit(this.activeTravelerId);
            }
        } else {
            this.checkDataService.leadTraveler.emit(false);
        }
    }

    getValidationMessages(swapValidationMessages = false): void {
        this.i18nService
            .preloadKeys(this.i18nService.getHotelTravelerFormValidationKeys())
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                const keys = this.i18nService.getSavedKeys();

                this.validationMessages = {
                    firstName: {
                        required: keys['core.errors.firstNameRequired'],
                    },
                    lastName: {
                        required: keys['core.errors.lastNameRequired'],
                    },
                    email: {
                        required: keys['core.errors.emailRequired'],
                        email: keys['core.errors.emailEmail'],
                    },
                    phone: {
                        required: keys['core.errors.phoneRequired'],
                    },
                    address1: {
                        required: keys['core.errors.address1Required'],
                    },
                    city: {
                        required: keys['core.errors.cityRequired'],
                    },
                    countryId: {
                        required: keys['core.errors.countryIdRequired'],
                    },
                    provinceId: {
                        required: keys['core.errors.provinceIdRequired'],
                    },
                    provinceName: {
                        required: keys['core.errors.provinceNameRequired'],
                    },
                    postalCode: {
                        required: keys['core.errors.postalCodeRequired'],
                    },
                };

                if (swapValidationMessages) {
                    this.travelerFormGroup.clearValidators();
                    this.travelerFormGroup.updateValueAndValidity();
                }
            });
    }
}
