import { AssetValue } from '@shared/models';
import { Entity, Of } from '@utils';

@Entity
export class AccountPurchase {
    @Of(() => Number)
    id: number;

    @Of(() => String)
    typeCode: string;

    @Of(() => String)
    typeName: string;

    @Of(() => String)
    date: string;

    @Of(() => String)
    status: string;

    @Of(() => String)
    invoiceNo: string;

    @Of(() => String)
    confirmationNo: string;

    @Of(() => String)
    startDate: string;

    @Of(() => String)
    endDate: string;

    @Of(() => Number)
    pointsRedeemed: number;

    @Of(() => Number)
    pointsEarned: number;

    @Of(() => Boolean)
    isTravel: boolean;

    @Of(() => Number, { optional: true })
    tripId?: number;

    @Of(() => String, { optional: true })
    tripName?: string;

    @Of(() => AssetValue)
    price: AssetValue;

    @Of(() => AssetValue, { optional: true })
    youSave?: AssetValue;

    @Of(() => String)
    description: string;

    @Of(() => String, { optional: true })
    earnDelayDate?: string;

    @Of(() => Boolean)
    invoice: boolean;

    @Of(() => Boolean)
    postpaid: boolean;

    @Of(() => String, { optional: true })
    moreInfoLink?: string;

    @Of(() => String, { optional: true })
    youSaveLabel?: string;

    @Of(() => String)
    guestEmail: string;

    @Of(() => Number)
    cardAmount: number;

    @Of(() => String)
    cardCurrency: string;

    static of = Entity.of<AccountPurchase>();
}
