<div class="payment-details-wrapper" [class.receipt]="receipt">
    <div class="row">
        <div class="col-sm-4 col-xxxs-12">
            <div class="price-info-wrapper left-column height-100">
                <div class="invoice-label text-uppercase" [attr.data-i18n-code]="'core.labels.invoiceNumber'"></div>
                <div class="invoice-number margin-bottom-20">{{ bookingDetails.txGuid }}</div>

                <div appTranslate *ngIf="bookingDetails.bookingRef">
                    <div class="invoice-label text-uppercase" [attr.data-i18n-code]="'core.labels.refNumber'"></div>
                    <div class="invoice-number margin-bottom-20">{{ bookingDetails.bookingRef }}</div>
                </div>

                <div appTranslate *ngIf="module === 'flights'">
                    <div
                        class="invoice-label text-uppercase"
                        [attr.data-i18n-code]="'flights.confirmation.supplierLocatorCode.label'"
                    ></div>
                    <div class="invoice-number margin-bottom-20">{{ bookingDetails.supplierLocatorCodes }}</div>

                    <div class="invoice-label text-uppercase" [attr.data-i18n-code]="'core.labels.pnrNumber'"></div>
                    <div class="invoice-number">{{ bookingDetails.providerLocatorCodes }}</div>
                </div>
            </div>
        </div>

        <div class="col-sm-8 col-xxxs-12 price-breakdown-wrapper">
            <div class="price-info-wrapper height-100">
                <div
                    appTranslate
                    *ngIf="
                        (module !== 'cars' || bookingDetails.prepaid) &&
                        (bookingDetails.payCardName || bookingDetails.payCardNumber)
                    "
                >
                    <h3
                        class="subsection-title margin-bottom-20"
                        [attr.data-i18n-code]="'core.labels.paymentInformation'"
                    ></h3>
                    <div
                        class="flex-container justify-content-space-between margin-bottom-10"
                        *ngIf="bookingDetails.payCardName"
                    >
                        <span class="price-label" [attr.data-i18n-code]="'core.labels.cardHolder'"></span>
                        <span class="price-value">{{ bookingDetails.payCardName }}</span>
                    </div>

                    <div
                        class="flex-container justify-content-space-between margin-bottom-10"
                        *ngIf="bookingDetails.payCardType"
                    >
                        <span class="price-label" [attr.data-i18n-code]="'core.labels.cardType'"></span>
                        <span class="price-value">{{ bookingDetails.payCardType }}</span>
                    </div>

                    <div
                        class="flex-container justify-content-space-between margin-bottom-10"
                        *ngIf="bookingDetails.payCardNumber"
                    >
                        <span class="price-label" [attr.data-i18n-code]="'core.labels.cardNumber'"></span>
                        <span class="price-value">{{ bookingDetails.payCardNumber }}</span>
                    </div>

                    <div
                        class="flex-container justify-content-space-between margin-bottom-50"
                        *ngIf="bookingDetails.payCardExpDate"
                    >
                        <span class="price-label" [attr.data-i18n-code]="'core.labels.cardExpirationDate'"></span>
                        <span class="price-value">{{ bookingDetails.payCardExpDate }}</span>
                    </div>
                </div>

                <h3
                    class="subsection-title margin-bottom-20"
                    [attr.data-i18n-code]="'core.labels.priceSummary'"
                    *ngIf="!confirmationDetails"
                ></h3>
                <h3
                    class="subsection-title margin-bottom-20"
                    [attr.data-i18n-code]="'reservations.cancellation.priceSummary'"
                    *ngIf="confirmationDetails"
                ></h3>

                <ng-container *ngFor="let price of bookingDetails.payPriceBreakdown; let i = index">
                    <div class="flex-container justify-content-space-between margin-bottom-10">
                        <span
                            class="price-label"
                            [class.sub-item]="price.isItalic"
                            [class.text-italic]="price.isItalic"
                            >{{ price.label }}</span
                        >
                        <span
                            class="price-value"
                            [class.sub-item]="price.isItalic"
                            [class.text-italic]="price.isItalic"
                            [class.line-through]="price.type === 'retail'"
                            [innerHtml]="price?.value"
                        ></span>
                    </div>
                </ng-container>

                <ng-container *ngIf="module === Modules.CARS">
                    <div class="taxes-breakdown-wrapper">
                        <ng-container *ngFor="let fee of includedFees">
                            <div class="flex-container justify-content-space-between margin-bottom-10">
                                <span class="price-label sub-item">{{ fee.label }}</span>
                                <span class="price-value sub-item">{{ fee.value }}</span>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>

                <ng-container *ngIf="module === Modules.HOTELS">
                    <div class="price-separator margin-top-20 margin-bottom-20"></div>

                    <div
                        class="flex-container justify-content-space-between margin-bottom-10"
                        *ngIf="bookingDetails?.taxTotal"
                    >
                        <span class="price-label" [attr.data-i18n-code]="'core.assetValues.tax.label'"></span>
                        <span class="price-value">{{ bookingDetails.taxTotal.value }}</span>
                    </div>

                    <div class="taxes-breakdown-wrapper">
                        <ng-container *ngFor="let fee of includedFees">
                            <div class="flex-container justify-content-space-between margin-bottom-10">
                                <span class="price-label sub-item">{{ fee.label }}</span>
                                <span class="price-value sub-item">{{ fee?.value }}</span>
                            </div>
                        </ng-container>
                    </div>

                    <div
                        class="flex-container justify-content-space-between margin-bottom-10"
                        *ngIf="serviceCharge?.value"
                    >
                        <span class="price-label">{{ serviceCharge.label }}</span>
                        <span class="price-value">{{ serviceCharge.value }}</span>
                    </div>

                    <div class="price-separator margin-top-20 margin-bottom-20"></div>
                </ng-container>

                <ng-container *ngIf="module !== Modules.CARS">
                    <div class="flex-container justify-content-space-between margin-bottom-10">
                        <span
                            class="price-total-label bold"
                            [attr.data-i18n-code]="
                                module === 'cars' && !bookingDetails.prepaid
                                    ? 'cars.confirmation.estimatedPrice'
                                    : module === 'hotels' && bookingDetails.totalDueAtHotel
                                    ? 'core.labels.totalDueToday'
                                    : 'core.labels.total'
                            "
                        ></span>
                        <span
                            class="price-total-value bold"
                            [innerHtml]="
                                module === 'cars' && !bookingDetails.prepaid
                                    ? bookingDetails.dueAtArrival
                                    : bookingDetails.payTotalAmount
                            "
                        ></span>
                    </div>

                    <div class="flex-container justify-content-space-between" *ngIf="bookingDetails?.payPointsEarned">
                        <span
                            class="price-label points-value"
                            [attr.data-i18n-code]="'core.labels.pointsEarned'"
                            [attr.data-i18n-params]="siteBranding?.uiConfig?.pointsOptions?.pointsName"
                            [attr.data-i18n-persist]="true"
                        ></span>
                        <span class="price-value points-value"
                            >{{ bookingDetails.payPointsEarned }}
                            <ng-container *ngIf="bookingDetails.earnDelayDate && confirmationDetails?.status !== 'C'">
                                (<span appTranslate [attr.data-i18n-code]="'core.labels.availableAfter'"></span>
                                {{ bookingDetails.earnDelayDate }})
                            </ng-container>
                        </span>
                    </div>

                    <ng-container *ngIf="module === 'hotels' && bookingDetails.hotelFees?.length">
                        <div class="price-separator margin-top-20 margin-bottom-20"></div>

                        <div class="flex-container justify-content-space-between margin-bottom-10">
                            <span
                                class="price-total-label bold"
                                [attr.data-i18n-code]="'hotels.confirmation.dueAtProperty.label'"
                            ></span>
                            <span class="price-total-value bold">{{ bookingDetails.totalDueAtHotel }}</span>
                        </div>

                        <ng-container *ngFor="let fee of hotelFees">
                            <div class="flex-container justify-content-space-between margin-bottom-10">
                                <span class="price-label">{{ fee.label }}</span>
                                <span class="price-value">{{ fee?.value }}</span>
                            </div>
                        </ng-container>

                        <div class="price-separator margin-top-20 margin-bottom-20"></div>

                        <ng-container *ngIf="bookingDetails.totalDueAtHotel">
                            <div class="flex-container justify-content-space-between margin-bottom-10">
                                <span
                                    class="price-total-label bold"
                                    [attr.data-i18n-code]="'core.labels.reservationTotal'"
                                ></span>
                                <span
                                    class="price-total-value bold"
                                    [innerHtml]="bookingDetails.reservationTotal"
                                ></span>
                            </div>
                        </ng-container>
                    </ng-container>
                </ng-container>

                <ng-container *ngIf="module === Modules.CARS">
                    <div class="price-separator margin-top-10 margin-bottom-10"></div>
                    <app-cars-totals [bookPrice]="bookPrice"></app-cars-totals>
                </ng-container>

                <div
                    appTranslate
                    class="tax-disclaimer-wrapper margin-top-10 margin-bottom-20"
                    *ngIf="bookingDetails.taxDisclaimer"
                >
                    <p
                        class="tax-disclaimer-title margin-bottom-5"
                        [attr.data-i18n-code]="'core.labels.taxesFeesDisclaimer'"
                    ></p>

                    <div [innerHtml]="bookingDetails.taxDisclaimer"></div>
                </div>

                <ng-container *ngIf="confirmationDetails?.status === 'C' || cancellation">
                    <div class="price-separator margin-top-20 margin-bottom-20"></div>

                    <div
                        class="flex-container justify-content-space-between"
                        *ngIf="confirmationDetails.cancellationPenaltyFee?.value && penaltyDatePassed"
                    >
                        <span
                            class="price-label penalty-value"
                            [attr.data-i18n-code]="'reservations.cancellation.penaltyFee'"
                        ></span>
                        <span class="price-value penalty-value">{{
                            confirmationDetails.cancellationPenaltyFee.value
                                | formatCurrency: confirmationDetails.cancellationPenaltyFee.code
                        }}</span>
                    </div>

                    <div
                        class="price-separator margin-top-20 margin-bottom-20"
                        *ngIf="confirmationDetails.cancellationPenaltyFee && penaltyDatePassed"
                    ></div>

                    <div
                        class="flex-container justify-content-space-between margin-bottom-10"
                        *ngIf="confirmationDetails?.accountCreditRefundDue?.value"
                    >
                        <span
                            class="bold"
                            [attr.data-i18n-code]="'reservations.cancellation.accountCreditRefundDue'"
                        ></span>
                        <span class="bold">{{
                            confirmationDetails.accountCreditRefundDue.value
                                | formatCurrency: confirmationDetails.accountCreditRefundDue.code
                        }}</span>
                    </div>

                    <div
                        class="flex-container justify-content-space-between"
                        *ngIf="confirmationDetails.paymentRefundDue?.value"
                    >
                        <span class="bold" [attr.data-i18n-code]="'reservations.cancellation.paymentRefundDue'"></span>
                        <span class="bold">{{
                            confirmationDetails.paymentRefundDue?.value
                                | formatCurrency: confirmationDetails.paymentRefundDue?.code
                        }}</span>
                    </div>

                    <div
                        appTranslate
                        *ngIf="
                            siteBranding?.uiConfig?.pointsOptions?.showPoints &&
                            (confirmationDetails.loyaltyPointsEarned?.value > 0 ||
                                confirmationDetails.loyaltyPointsApplied?.value > 0)
                        "
                    >
                        <div
                            class="bold margin-top-40 margin-bottom-20"
                            [attr.data-i18n-code]="'reservations.cancellation.pointsAdjustment'"
                        ></div>

                        <div
                            appTranslate
                            class="flex-container justify-content-space-between margin-bottom-10"
                            *ngIf="confirmationDetails.loyaltyPointsApplied"
                        >
                            <span
                                class="price-label"
                                [attr.data-i18n-code]="'reservations.cancellation.pointsApplied'"
                                [attr.data-i18n-params]="siteBranding?.uiConfig?.pointsOptions?.pointsName"
                                [attr.data-i18n-persist]="true"
                            ></span>
                            <span class="price-value">{{ confirmationDetails.loyaltyPointsApplied?.value }}</span>
                        </div>

                        <div
                            appTranslate
                            class="flex-container justify-content-space-between margin-bottom-10"
                            *ngIf="confirmationDetails.loyaltyPointsRefundDue"
                        >
                            <span
                                class="price-label points-value"
                                [attr.data-i18n-code]="'reservations.cancellation.pointsRefund'"
                                [attr.data-i18n-params]="siteBranding?.uiConfig?.pointsOptions?.pointsName"
                                [attr.data-i18n-persist]="true"
                            ></span>
                            <span class="price-value points-value">{{
                                confirmationDetails.strLoyaltyPointsRefundDue
                            }}</span>
                        </div>

                        <div
                            class="flex-container justify-content-space-between margin-bottom-10"
                            *ngIf="confirmationDetails.loyaltyPointsEarned?.value"
                        >
                            <span
                                class="price-label"
                                [attr.data-i18n-code]="'reservations.cancellation.pointsEarned'"
                                [attr.data-i18n-params]="siteBranding?.uiConfig?.pointsOptions?.pointsName"
                                [attr.data-i18n-persist]="true"
                            ></span>
                            <span class="price-value"
                                >{{ confirmationDetails.loyaltyPointsEarned?.value }}
                                <ng-container *ngIf="bookingDetails.earnDelayDate">
                                    (<span appTranslate [attr.data-i18n-code]="'core.labels.availableAfter'"></span>
                                    {{ bookingDetails.earnDelayDate }})
                                </ng-container>
                            </span>
                        </div>

                        <div
                            appTranslate
                            class="flex-container justify-content-space-between margin-bottom-10"
                            *ngIf="confirmationDetails.loyaltyPointsRefundDue"
                        >
                            <span
                                class="price-label penalty-value"
                                [attr.data-i18n-code]="'reservations.cancellation.pointsReversed'"
                                [attr.data-i18n-params]="siteBranding?.uiConfig?.pointsOptions?.pointsName"
                                [attr.data-i18n-persist]="true"
                            ></span>
                            <span class="price-value penalty-value">{{
                                confirmationDetails.strLoyaltyPointsReversedDue
                            }}</span>
                        </div>

                        <div
                            class="price-separator margin-top-20 margin-bottom-20"
                            *ngIf="confirmationDetails.strLoyaltyPointsTotalAdj"
                        ></div>

                        <div
                            class="flex-container justify-content-space-between"
                            *ngIf="confirmationDetails.strLoyaltyPointsTotalAdj"
                        >
                            <span
                                class="bold"
                                [attr.data-i18n-code]="'reservations.cancellation.totalPointsAdjustment'"
                                [attr.data-i18n-params]="siteBranding?.uiConfig?.pointsOptions?.pointsName"
                                [attr.data-i18n-persist]="true"
                            ></span>
                            <span class="bold">{{ confirmationDetails.strLoyaltyPointsTotalAdj }}</span>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
