import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { NewUserGreetDialogComponent, ResetPasswordDialogComponent } from '@core/components/login/dialogs';
import { SiteBranding } from '@core/models';
import { AuthService } from '@core/services';
import { SharedStorageKeys } from '@shared/models';
import { UrlService } from '@shared/services';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
    sharedStorageKeys = SharedStorageKeys;

    showPage = false;
    target = 1;
    signUpScreen: boolean;
    signUpTempHide = true;
    siteBranding: SiteBranding;

    private unsubscribe: Subject<void> = new Subject();

    constructor(
        private dialog: MatDialog,
        private route: ActivatedRoute,
        private location: Location,
        private url: UrlService,
        private router: Router,
        private authService: AuthService
    ) {}

    ngOnInit(): void {
        this.router.events
            .pipe(
                filter((event: RouterEvent) => event instanceof NavigationEnd),
                takeUntil(this.unsubscribe)
            )
            .subscribe(() => {
                this.checkShowResetModal();
            });

        this.checkShowResetModal();
        this.siteBranding = JSON.parse(localStorage.getItem(this.sharedStorageKeys.SITE_BRANDING)) as SiteBranding;

        if (this.route.snapshot.queryParams['registrationCode']) {
            this.signUpScreen = true;
            this.signUpTempHide = false;
        }

        if (!localStorage.getItem(this.sharedStorageKeys.USER_ID) || this.authService.isGuest()) {
            if (this.siteBranding?.uiConfig?.userOptions?.loginRedirect?.startsWith('http')) {
                window.location.href = this.siteBranding?.uiConfig.userOptions.loginRedirect;
            } else {
                this.showPage = true;
                this.router.navigateByUrl(
                    this.siteBranding?.uiConfig?.userOptions?.loginRedirect || this.url.getUrl('login')
                );
            }
        } else {
            this.showPage = true;
        }

        setTimeout(() => {
            this.signUpTempHide = false;
        }, 700); // This timeout should match $animation-duration in login.component.scss
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    checkShowResetModal(): void {
        if (this.route.snapshot.queryParams['resetp'] && this.route.snapshot.queryParams['token']) {
            const dialogRef = this.dialog.open(ResetPasswordDialogComponent, {
                data: {
                    token: this.route.snapshot.queryParams['token'],
                    username: this.route.snapshot.queryParams['username'],
                },
            });

            dialogRef.afterClosed().subscribe((response: boolean) => {
                this.router.navigate([this.url.getUrl('login')], { queryParams: null });

                if (response) {
                    sessionStorage.setItem(this.sharedStorageKeys.PREVENT_GUEST_LOGIN, 'true');
                    this.location.replaceState(this.url.getUrl('login'));

                    const siteBranding: SiteBranding = JSON.parse(
                        localStorage.getItem(this.sharedStorageKeys.SITE_BRANDING)
                    ) as SiteBranding;
                    this.router.navigateByUrl(this.url.getUrl(siteBranding?.uiConfig?.modules[0]));
                }
            });
        }
    }

    userRegistered(message: string): void {
        this.signUpScreen = false;

        setTimeout(() => {
            this.dialog.open(NewUserGreetDialogComponent, {
                data: {
                    message,
                },
            });
        }, 700); // This timeout should match $animation-duration in login.component.scss
    }
}
