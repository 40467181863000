import { AdobeAnalyticsDetails, AssetValue, GoogleAnalyticsDetails } from '@shared/models';

export class Car {
    public index: number;
    public id: string;
    public imageUrl: string;
    public logoUrl: string;
    public name: string;
    public vehicleClass: string;
    public vendorCode: string;
    public description: string;
    public passengers: number;
    public bags: number;
    public mileage: string;
    public pickupLocation: string;
    public pickupAddress: string;
    public retailPrice: AssetValue;
    public earns: AssetValue;
    public earnDelayDate: string;
    public refundable: boolean;
    public youPay: AssetValue;
    public youSave: AssetValue;
    public youSavePct: number;
    public youSaveVal: number;
    public youSavePercentage: number;
    public redeem: AssetValue;
    public payType: string;
    public cancelPenaltyPolicy: string;
    public ga: GoogleAnalyticsDetails;
    public oa: AdobeAnalyticsDetails;
    public refundPolicy: string;
    public vendorName?: string;
    public className?: string;
    public tax?: AssetValue;
    public taxIncluded?: boolean;
    public dueAtArrival?: AssetValue;
    public transmission?: string;
    public price?: number;
}
