<div appTranslate id="cancellation-dialog">
    <h2 mat-dialog-title [attr.data-i18n-code]="'reservations.cancellation.dialogHeader'"></h2>

    <div mat-dialog-content class="padding-top-20 padding-bottom-20">
        <div
            class="cancellation-penalty-message margin-bottom-20"
            [attr.data-i18n-code]="'reservations.cancellation.penaltyDatePassed'"
            *ngIf="penaltyDatePassed"
        ></div>

        <p class="cancellation-disclaimer bold" [attr.data-i18n-code]="'reservations.cancellation.disclaimer'"></p>

        <form [formGroup]="cancellationFormGroup" class="margin-bottom-20" *ngIf="cancellationFormGroup">
            <app-select
                [label]="'reservations.cancellation.reasonForCancellation'"
                [hint]="'reservations.cancellation.reasonDisclaimer'"
                [options]="cancellationReasons"
                [group]="cancellationFormGroup"
                [errorMessages]="formErrors?.reasonId"
                name="reasonId"
            ></app-select>
        </form>

        <p class="error-message margin-bottom-20 bold" *ngIf="errorMessage">{{ errorMessage }}</p>
    </div>

    <div mat-dialog-actions class="flex-container justify-content-center">
        <div class="margin-right-20">
            <button mat-raised-button [color]="'primary'" [mat-dialog-close]="false">
                <span class="text-uppercase" [attr.data-i18n-code]="'core.labels.cancel'"></span>
            </button>
        </div>

        <button mat-stroked-button [color]="'warn'" (click)="submitCancellation()">
            <span
                class="text-uppercase"
                [attr.data-i18n-code]="'reservations.cancellation.completeCancellation'"
            ></span>
        </button>
    </div>
</div>
