import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import {
    BookRequest,
    BookResponse,
    ComparePriceItem,
    DestinationSearch,
    Hotel,
    HotelDetails,
    RateCancellationPolicy,
    Room,
    RoomPackage,
    SavedSearch,
    SearchRequest,
} from '@hotels/models';
import {
    BestDeal,
    BookPrice,
    CardProfile,
    ComputePriceInput,
    DataTemplate,
    FeaturedDestination,
    GenericSearchResponse,
    PaginatedSearchResponse,
    TravelerProfile,
} from '@shared/models';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { siteOffersGroupTypes } from 'src/app/app.globals';

@Injectable({
    providedIn: 'root',
})
export class HotelsService {
    constructor(private http: HttpClient) {}

    getFeaturedDestinations(): Observable<DataTemplate<GenericSearchResponse<FeaturedDestination>>> {
        const url = `${environment.corePath}/hotels/offers/${siteOffersGroupTypes.FEATURED_DESTINATIONS}`;

        return this.http.get(url) as Observable<DataTemplate<GenericSearchResponse<FeaturedDestination>>>;
    }

    getBestDeals(): Observable<DataTemplate<GenericSearchResponse<BestDeal>>> {
        const url = `${environment.corePath}/hotels/offers/${siteOffersGroupTypes.BEST_DEALS}`;

        return this.http.get(url) as Observable<DataTemplate<GenericSearchResponse<BestDeal>>>;
    }

    search(request: SearchRequest): Observable<DataTemplate<PaginatedSearchResponse<Hotel>>> {
        const url = `${environment.hotelsPath}/search`;

        return this.http.post(url, request) as Observable<DataTemplate<PaginatedSearchResponse<Hotel>>>;
    }

    getSavedSearch(savedSearchCode: string, persist = false): Observable<DataTemplate<SavedSearch>> {
        const url = `${environment.hotelsPath}/search/${savedSearchCode}`;

        if (persist) {
            return this.http.post(url, null).pipe(
                tap((data) => {
                    sessionStorage.setItem(`hotels_saved_search_${savedSearchCode}`, JSON.stringify(data));
                }),
                map((data) => data as DataTemplate<SavedSearch>)
            ) as Observable<DataTemplate<SavedSearch>>;
        } else {
            const savedSearch = JSON.parse(
                sessionStorage.getItem(`hotels_saved_search_${savedSearchCode}`)
            ) as DataTemplate<SavedSearch>;

            if (savedSearch) {
                sessionStorage.removeItem(`hotels_saved_search_${savedSearchCode}`);
                return of(savedSearch as DataTemplate<SavedSearch>);
            } else {
                return this.http.post(url, null) as Observable<DataTemplate<SavedSearch>>;
            }
        }
    }

    getRecentSearches(limit = 3): Observable<DataTemplate<GenericSearchResponse<SearchRequest>>> {
        const url = `${environment.hotelsPath}/recentSearches?limit=${limit}`;

        return this.http.get(url) as Observable<DataTemplate<GenericSearchResponse<SearchRequest>>>;
    }

    searchDestinations(criteria: string): Observable<DataTemplate<DestinationSearch>> {
        const url = `${environment.hotelsPath}/locations`;

        const params = new HttpParams().set('term', criteria);

        return this.http.get(url, { params }) as Observable<DataTemplate<DestinationSearch>>;
    }

    comparePrices(id: string): Observable<DataTemplate<ComparePriceItem[]>> {
        const url = `${environment.hotelsPath}/compare/${id}`;

        return this.http.get(url) as Observable<DataTemplate<ComparePriceItem[]>>;
    }

    getDetails(hotelId: string): Observable<DataTemplate<HotelDetails>> {
        const url = `${environment.hotelsPath}/${hotelId}`;

        return this.http.get(url) as Observable<DataTemplate<HotelDetails>>;
    }

    getRooms(hotelId: string): Observable<DataTemplate<Room[]>> {
        const url = `${environment.hotelsPath}/${hotelId}/rooms`;

        return this.http.get(url) as Observable<DataTemplate<Room[]>>;
    }

    getPackages(hotelId: string): Observable<DataTemplate<RoomPackage[]>> {
        const url = `${environment.hotelsPath}/${hotelId}/packages`;

        return this.http.get(url) as Observable<DataTemplate<RoomPackage[]>>;
    }

    getPolicies(rateIds: string[]): Observable<DataTemplate<RateCancellationPolicy[]>> {
        const url = `${environment.hotelsPath}/packages/policy`;

        return this.http.post(url, { rateIds }) as Observable<DataTemplate<RateCancellationPolicy[]>>;
    }

    getTrustYouUrl(hotelId: string): Observable<DataTemplate<any>> {
        const url = `${environment.hotelsPath}/${hotelId}/trustyou`;

        return this.http.get(url) as Observable<DataTemplate<any>>;
    }

    getRateCancellationPolicy(rateId: string): Observable<DataTemplate<RateCancellationPolicy>> {
        const url = `${environment.hotelsPath}/rates/${rateId}/policy`;

        const policy = JSON.parse(
            sessionStorage.getItem(`hotels_policy_${rateId}`)
        ) as DataTemplate<RateCancellationPolicy>;

        if (policy) {
            return of(policy as DataTemplate<RateCancellationPolicy>);
        } else {
            return this.http.get(url).pipe(
                tap((data) => {
                    sessionStorage.setItem(`hotels_policy_${rateId}`, JSON.stringify(data));
                }),
                map((data) => data as DataTemplate<RateCancellationPolicy>)
            ) as Observable<DataTemplate<RateCancellationPolicy>>;
        }
    }

    getTravelerProfiles(): Observable<DataTemplate<TravelerProfile[]>> {
        const url = `${environment.hotelsPath}/travprofiles`;

        return this.http.get(url) as Observable<DataTemplate<TravelerProfile[]>>;
    }

    getCardProfiles(): Observable<DataTemplate<CardProfile[]>> {
        const url = `${environment.hotelsPath}/ccardprofiles`;

        return this.http.get(url) as Observable<DataTemplate<CardProfile[]>>;
    }

    computePrice(rateId: string, options?: ComputePriceInput): Observable<DataTemplate<BookPrice>> {
        const url = `${environment.hotelsPath}/rates/${rateId}/price`;

        let params = new HttpParams();

        if (options?.payPoints || options?.payPoints === 0) {
            params = params.append('payPoints', options.payPoints.toString());
        }

        if (options?.useCredit) {
            params = params.append('useCredit', 'true');
        }

        return this.http.get(url, { params }) as Observable<DataTemplate<BookPrice>>;
    }

    book(request: BookRequest): Observable<DataTemplate<BookResponse>> {
        const url = `${environment.hotelsPath}/book`;

        return this.http.post(url, request) as Observable<DataTemplate<BookResponse>>;
    }
}
