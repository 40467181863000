import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { LoaderState } from '@core/models';
import { LoaderService } from '@core/services';
import { Subject } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-loader',
    templateUrl: 'loader.component.html',
    styleUrls: ['loader.component.scss'],
})
export class LoaderComponent implements OnInit, OnDestroy {
    @Input()
    loaded: boolean;

    show = true;

    private unsubscribe: Subject<void> = new Subject();

    constructor(private loaderService: LoaderService) {}

    ngOnInit(): void {
        this.loaderService.loaderState.pipe(takeUntil(this.unsubscribe), delay(0)).subscribe((state: LoaderState) => {
            this.show = state.show;
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
