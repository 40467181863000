export class AccountTravelerProfile {
    id: number;
    type: 'PRI' | 'SEC' | 'GST';
    typeName: string;
    title: string;
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    gender: string;
    genderName: string;
    nationalityId: number;
    street: string;
    building: string;
    city: string;
    provinceId: number;
    provinceName: string;
    postalCode: string;
    countryId: number;
    email: string;
    phone: string;
    workPhone: string;
    mobilePhone: string;
    passportCountryId: number;
    passportNumber: string;
    passportIssueDate: string;
    passportExpDate: string;
    redressNumber: number;
    knownTravelerNumber: number;

    active?: boolean;

    constructor(id = 0) {
        this.id = id;
    }
}
