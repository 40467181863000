import { CardProfile, TravelerProfile } from '@shared/models';
import { Entity, Of } from '@utils';

@Entity
export class BookRequest {
    @Of(() => Number)
    offerId: number;

    @Of(() => Number, { nullable: true, optional: true })
    tripId?: number = null;

    @Of(() => Number, { nullable: true, optional: true })
    tpId: number = null;

    @Of(() => TravelerProfile, { nullable: true, optional: true })
    traveler?: TravelerProfile = null;

    @Of(() => Number, { nullable: true, optional: true })
    ppId?: number = null;

    @Of(() => CardProfile, { nullable: true, optional: true })
    card?: CardProfile = null;

    @Of(() => Number, { nullable: true, optional: true })
    payPoints?: number = null;

    @Of(() => Boolean)
    saveTraveler = false;

    @Of(() => Boolean)
    updateTraveler = false;

    @Of(() => Boolean)
    saveCard = false;

    @Of(() => Boolean)
    payByInvoice = false;

    static of = Entity.of<BookRequest>();
}
