import { AccountService } from '@account/services';
import { TravelerAgeBand } from '@activities/models';
import { ActivitiesService, AgeBandsUpdaterService } from '@activities/services';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { Router } from '@angular/router';
import { Options } from '@angular-slider/ngx-slider';
import { CardsService } from '@cards/services';
import { CarsService } from '@cars/services';
import { Balance, SiteBranding, UserInfo } from '@core/models';
import { StorageKeys as CruiseStorageKeys, TableOptions } from '@cruises/models';
import { CruisesService } from '@cruises/services';
import { EscapesService } from '@escapes/services';
import { FlightsService } from '@flights/services';
import { HotelDetails, HotelRate, Room, SearchRequest } from '@hotels/models';
import { HotelsService } from '@hotels/services';
import { OfferDetails } from '@offers/models';
import { OffersService } from '@offers/services';
import { RewardsService } from '@rewards/services';
import { AssetValue, BookPrice, ComputePriceInput, DataTemplate, SharedStorageKeys } from '@shared/models';
import { FormatCurrencyPipe } from '@shared/pipes';
import { I18nService, UrlService } from '@shared/services';
import { MODULES, Modules } from '@utils';
import { WeeksService } from '@weeks/services';
import { defer, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-price-summary',
    templateUrl: './price-summary.component.html',
    styleUrls: ['./price-summary.component.scss'],
    providers: [FormatCurrencyPipe],
})
export class PriceSummaryComponent implements OnInit, OnDestroy {
    @Input()
    rateId: string | number;

    @Input()
    redeem: number;

    @Input()
    target: MODULES;

    @Input()
    isPostPaid: boolean;

    @Input()
    cruiseId: string;

    @Input()
    cabinGradeId: string;

    @Input()
    activityId: string;

    @Input()
    cardAmount: number;

    @Input()
    recurring: AssetValue;

    @Input()
    hotel: HotelDetails;

    @Input()
    room: Room;

    @Input()
    rate: HotelRate;

    @Input()
    searchRequest: SearchRequest;

    @Input()
    ageBands: TravelerAgeBand[] = [];

    @Output()
    priceCharge: EventEmitter<AssetValue> = new EventEmitter<AssetValue>();

    @Output()
    onPriceChange: EventEmitter<BookPrice> = new EventEmitter<BookPrice>();

    @Output()
    membershipFee: EventEmitter<AssetValue> = new EventEmitter<AssetValue>();

    @Output()
    points: EventEmitter<number> = new EventEmitter<number>();

    @Output()
    useAccountCredit: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output()
    togglePaymentProfiles: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output()
    cruiseOptions: EventEmitter<TableOptions> = new EventEmitter<TableOptions>();

    sharedStorageKeys = SharedStorageKeys;
    storageKeys = {
        cruises: CruiseStorageKeys,
    };
    modules = Modules;

    redeemMin: AssetValue;
    redeemMax: AssetValue;

    userInfo: UserInfo;
    pointsAmount: number;
    useCredit = true;
    hasCertificate = false;

    bookPrice: BookPrice;
    perNight: string;
    loaded: boolean;
    siteBranding: SiteBranding;
    showRetailPrice: boolean;
    showPointsSlider: boolean;
    taxBreakdown: AssetValue[] = [];
    totalDueAtProperty: string;
    originalTotalDueAtProperty: string;
    propertyBreakdown: AssetValue[] = [];
    serviceCharge: AssetValue;

    pointsRangeOptions: Options;

    constructor(
        private hotelsService: HotelsService,
        private flightsService: FlightsService,
        private carsService: CarsService,
        private weeksService: WeeksService,
        private cruisesService: CruisesService,
        private activitiesService: ActivitiesService,
        private cardsService: CardsService,
        private rewardsService: RewardsService,
        private offersService: OffersService,
        private escapesService: EscapesService,
        private accountService: AccountService,
        private ageBandsUpdaterService: AgeBandsUpdaterService,
        private url: UrlService,
        private router: Router,
        private i18nService: I18nService,
        private currencyPipe: FormatCurrencyPipe
    ) {}

    private unsubscribe: Subject<void> = new Subject<void>();

    ngOnInit(): void {
        this.siteBranding = JSON.parse(localStorage.getItem(this.sharedStorageKeys.SITE_BRANDING)) as SiteBranding;
        this.userInfo = UserInfo.of(JSON.parse(localStorage.getItem(this.sharedStorageKeys.USER_INFO)));

        if (sessionStorage.getItem(this.storageKeys[this.target]?.SEARCH_USE_CERTIFICATE) === 'true') {
            this.hasCertificate = true;
        }

        let moduleOptionsTarget: string = this.target;

        if (this.target === 'offers') {
            moduleOptionsTarget = (JSON.parse(sessionStorage.getItem('offer_to_book')) as OfferDetails)?.module;
        }

        if (moduleOptionsTarget) {
            this.showRetailPrice = this.siteBranding?.uiConfig?.moduleOptions?.[moduleOptionsTarget]?.showRetailPrice;

            if (this.isPostPaid && moduleOptionsTarget === this.modules.CARS) {
                this.showRetailPrice = false;
            }

            this.showPointsSlider = this.siteBranding?.uiConfig?.moduleOptions?.[moduleOptionsTarget]?.redeemSliderInd;
        } else {
            this.showPointsSlider = false;
        }

        if (this.target === 'rewards') {
            this.showPointsSlider = true;
        }

        if (this.rateId !== 'test') {
            this.computePrice(
                ComputePriceInput.of({
                    payPoints: this.redeem,
                    useCredit:
                        this.userInfo?.balances?.length &&
                        !!this.userInfo.balances.find((balance: Balance) => balance.type === 'credit'),
                })
            );
        } else {
            this.emitTestData();
        }

        this.ageBandsUpdaterService.update.subscribe((data: TravelerAgeBand) => {
            const target = this.ageBands.findIndex((a) => a.index === data.index);

            if (target >= 0) {
                this.ageBands[target].bandId = data.bandId;
            } else {
                this.ageBands.push(data);
            }

            this.computePrice(ComputePriceInput.of({ payPoints: this.redeem }));
        });

        this.ageBandsUpdaterService.remove.subscribe((data: number) => {
            const target = this.ageBands.findIndex((a) => a.index === data);

            if (target >= 0) {
                this.ageBands.splice(target, 1);
            }
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    computePrice(options: ComputePriceInput): void {
        this.pointsAmount = options.payPoints;

        defer(() => {
            this.loaded = false;
            switch (this.target) {
                case 'hotels':
                    return this.hotelsService
                        .computePrice(String(this.rateId), options)
                        .pipe(takeUntil(this.unsubscribe));
                case 'flights':
                    return this.flightsService.computePrice(String(this.rateId), options).pipe(
                        takeUntil(this.unsubscribe),
                        finalize(() => (this.loaded = true))
                    );
                case 'cars':
                    return this.carsService.computePrice(String(this.rateId), options).pipe(
                        takeUntil(this.unsubscribe),
                        finalize(() => (this.loaded = true))
                    );
                case 'weeks':
                    return this.weeksService.computePrice(String(this.rateId), options).pipe(
                        takeUntil(this.unsubscribe),
                        finalize(() => (this.loaded = true))
                    );
                case 'cruises':
                    return this.cruisesService
                        .computePrice(this.cruiseId, this.cabinGradeId, String(this.rateId), options)
                        .pipe(
                            takeUntil(this.unsubscribe),
                            finalize(() => (this.loaded = true))
                        );
                case 'activities':
                    return this.activitiesService.computePrice(String(this.rateId), this.ageBands, options).pipe(
                        takeUntil(this.unsubscribe),
                        finalize(() => (this.loaded = true))
                    );
                case 'ecards':
                    return this.cardsService.computePrice(String(this.rateId), this.cardAmount, options).pipe(
                        takeUntil(this.unsubscribe),
                        finalize(() => (this.loaded = true))
                    );
                case 'rewards':
                    return this.rewardsService.computePrice(String(this.rateId), options).pipe(
                        takeUntil(this.unsubscribe),
                        finalize(() => (this.loaded = true))
                    );
                case 'offers':
                    return this.offersService.computePrice(String(this.rateId), options).pipe(
                        takeUntil(this.unsubscribe),
                        finalize(() => (this.loaded = true))
                    );
                case 'escapes':
                    return this.escapesService.computePrice(String(this.rateId), options).pipe(
                        takeUntil(this.unsubscribe),
                        finalize(() => (this.loaded = true))
                    );
                case 'membership':
                    return this.accountService.computePrice(Number(this.rateId), options).pipe(
                        takeUntil(this.unsubscribe),
                        finalize(() => (this.loaded = true))
                    );
                default:
                    this.emitTestData(options.payPoints);
                    break;
            }
        }).subscribe(
            (response: DataTemplate<BookPrice>) => {
                this.bookPrice = response.data;

                if (this.target === this.modules.HOTELS) {
                    this.totalDueAtProperty =
                        this.rate.totalDueAtHotelValue &&
                        this.currencyPipe.transform(this.rate.totalDueAtHotelValue, this.rate.totalDueAtHotelCode);

                    this.originalTotalDueAtProperty =
                        this.rate.originalTotalDueAtHotelValue &&
                        this.currencyPipe.transform(
                            this.rate.originalTotalDueAtHotelValue,
                            this.rate.originalTotalDueAtHotelCode
                        );

                    if (this.rate) {
                        this.serviceCharge = {
                            ...this.bookPrice.serviceCharge,
                            label: this.i18nService.getKeyValue('core.assetValues.serviceCharge.label'),
                            fmtValue: this.currencyPipe.transform(
                                this.bookPrice.serviceCharge.value,
                                this.bookPrice.serviceCharge.code
                            ),
                        };

                        this.taxBreakdown = [...this.rate.includedFees];
                        this.propertyBreakdown = this.rate.hotelFees;
                    }
                }

                if (this.target === this.modules.CARS) {
                    this.taxBreakdown = (this.bookPrice.fees || []).map((f) => ({
                        ...f,
                        fmtValue: this.currencyPipe.transform(f.value, f.code),
                    }));
                }

                if (this.bookPrice.youPayPerNight?.value) {
                    this.i18nService
                        .getKeys([
                            {
                                code: 'core.assetValues.averagePerNight.label',
                                params: [
                                    this.currencyPipe.transform(
                                        this.bookPrice?.youPayPerNight?.value,
                                        this.bookPrice?.youPayPerNight?.code
                                    ),
                                ],
                            },
                        ])
                        .pipe(
                            takeUntil(this.unsubscribe),
                            finalize(() => (this.loaded = true))
                        )
                        .subscribe((response) => {
                            this.perNight = response.data['core.assetValues.averagePerNight.label'];
                        });
                } else {
                    this.loaded = true;
                }

                if (!this.redeemMax && this.bookPrice.redeemMax) {
                    this.redeemMin = AssetValue.of(this.bookPrice.redeemMin);
                    this.redeemMax = AssetValue.of(this.bookPrice.redeemMax);

                    this.pointsRangeOptions = {
                        ariaLabelledBy: 'redeem-points-label',
                        floor: this.redeemMin.value,
                        ceil: this.redeemMax.value,
                    };
                }

                this.useCredit = options.useCredit;

                if (this.bookPrice?.maxCreditAmt?.value === 0 || !this.bookPrice?.maxCreditAmt) {
                    this.useCredit = false;
                }

                this.useAccountCredit.emit(this.useCredit);

                this.priceCharge.emit(this.bookPrice.totalAmount);
                this.onPriceChange.emit(this.bookPrice);

                if (this.bookPrice.membershipFee) {
                    this.membershipFee.emit(this.bookPrice.membershipFee);
                }

                this.points.emit(this.bookPrice.redeem?.value || 0);
                this.togglePaymentProfiles.emit(this.bookPrice.disablePaymentProfiles);

                if (this.target === 'cruises') {
                    this.cruiseOptions.emit({ seatings: response.data.seatings, tableSizes: response.data.tableSizes });
                }
            },
            (error) => {
                console.error(error);
                this.router.navigateByUrl(this.url.getUrl(this.target));
            }
        );
    }

    emitTestData(points = 25): void {
        this.bookPrice = BookPrice.of({});

        this.redeemMax = AssetValue.of({ code: 'LOYPNT', value: 2000 });
        this.redeemMin = AssetValue.of({ code: 'LOYPNT', value: 0 });

        this.pointsRangeOptions = {
            ariaLabelledBy: 'redeem-points-label',
            floor: this.redeemMin.value,
            ceil: this.redeemMax.value,
        };

        this.priceCharge.emit(this.bookPrice.totalAmount);
        this.points.emit(this.bookPrice.redeem.value);
        this.loaded = true;
    }

    emitAccountCredit(change: MatSelectChange): void {
        this.computePrice(
            ComputePriceInput.of({
                payPoints: this.pointsAmount,
                useCredit: change.value,
            })
        );
    }

    emitCertificate(change: MatSelectChange): void {
        this.computePrice(
            ComputePriceInput.of({
                payPoints: this.pointsAmount,
                useCredit: this.useCredit,
                code: change.value,
            })
        );
    }
}
