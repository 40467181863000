import { Entity, Of } from '@utils';

@Entity
export class Elavon3ds {
    @Of(() => String)
    eci: string;

    @Of(() => String)
    messageVersion: string;

    @Of(() => String)
    acsTransID: string;

    @Of(() => String)
    authenticationValue: string;

    @Of(() => String)
    dsTransID: string;

    @Of(() => String)
    threeDSServerTransID: string;

    @Of(() => String)
    transStatus: string;

    static of = Entity.of<Elavon3ds>();
}
