<div appTranslate *ngIf="receipt === 1">
    <h3 class="subsection-title margin-bottom-10" [attr.data-i18n-code]="'reservations.confirmation.details'"></h3>

    <div
        class="confirmation-cancellation-wrapper flex-container justify-content-space-between padding-20 margin-bottom-20"
    >
        <div class="margin-sm-bottom-10">
            <div
                class="reservation-status-title text-uppercase"
                [attr.data-i18n-code]="'reservations.confirmation.status'"
            ></div>
            <h3
                class="reservation-status {{ bookingStatus }}"
                [attr.data-i18n-code]="'reservations.status.' + bookingStatus"
            ></h3>
        </div>

        <div class="flex-container align-items-center">
            <div class="margin-right-10">
                <button mat-stroked-button [color]="'primary'" onclick="window.print()">
                    <div class="flex-container align-items-center">
                        <mat-icon [svgIcon]="'print'"></mat-icon>
                        <span
                            appTranslate
                            [attr.data-i18n-code]="'reservations.confirmation.print'"
                            class="margin-left-10"
                        ></span>
                    </div>
                </button>
            </div>

            <div appTranslate *ngIf="bookingStatus === 'A'">
                <a class="cancellation-link" [routerLink]="url.getUrl(module + '-book-cancel', { id: reservationId })">
                    <button mat-stroked-button [color]="'warn'">
                        <div class="flex-container align-items-center">
                            <mat-icon [svgIcon]="'cancel-reservation'"></mat-icon>
                            <span
                                [attr.data-i18n-code]="'reservations.beginCancellation.label'"
                                class="margin-left-10"
                            ></span>
                        </div>
                    </button>
                </a>
            </div>
        </div>
    </div>
</div>

<div appTranslate *ngIf="receipt === 2">
    <h3
        appTranslate
        class="subsection-title margin-bottom-10"
        [attr.data-i18n-code]="'core.labels.receipt'"
        *ngIf="prepaid"
    ></h3>
    <h3
        appTranslate
        class="subsection-title margin-bottom-10"
        [attr.data-i18n-code]="'cars.confirmation.estimatedPrice'"
        *ngIf="!prepaid"
    ></h3>

    <div class="confirmation-receipt-wrapper flex-container justify-content-space-between padding-20 margin-bottom-20">
        <div>
            <div
                class="reservation-status-title text-uppercase"
                [attr.data-i18n-code]="'reservations.confirmation.status'"
            ></div>
            <h3
                class="reservation-status {{ bookingStatus }}"
                [attr.data-i18n-code]="'reservations.status.' + bookingStatus"
            ></h3>
        </div>

        <div class="flex-container align-items-center">
            <div class="margin-right-10">
                <button mat-stroked-button [color]="'primary'" onclick="window.print()">
                    <div class="flex-container align-items-center">
                        <mat-icon [svgIcon]="'print'"></mat-icon>
                        <span
                            appTranslate
                            [attr.data-i18n-code]="'reservations.confirmation.print'"
                            class="margin-left-10"
                        ></span>
                    </div>
                </button>
            </div>
        </div>
    </div>
</div>
