import { Component, Input } from '@angular/core';
import { CardProfile } from '@shared/models';

@Component({
    selector: 'app-payment-card',
    templateUrl: './payment-card.component.html',
    styleUrls: ['./payment-card.component.scss'],
})
export class PaymentCardComponent {
    @Input()
    card: CardProfile;

    @Input()
    newCard: boolean;

    @Input()
    backgroundWhite: boolean;
}
