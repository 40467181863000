import {
    ActivityDetails,
    ActivityHotel,
    BookRequest,
    BookResponse,
    LocationAutocompleteItem,
    Review,
    SavedSearch,
    Schedule,
    SearchRequest,
    SearchResponse,
    SearchSchedulesRequest,
    TravelerAgeBand,
} from '@activities/models';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import {
    BestDeal,
    BookPrice,
    CardProfile,
    ComputePriceInput,
    DataTemplate,
    FeaturedDestination,
    GenericListItem,
    GenericSearchResponse,
    PaginatedSearchResponse,
    SimplePagination,
    TravelerProfile,
} from '@shared/models';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { siteOffersGroupTypes } from 'src/app/app.globals';

@Injectable({
    providedIn: 'root',
})
export class ActivitiesService {
    constructor(private http: HttpClient) {}

    getFeaturedDestinations(): Observable<DataTemplate<GenericSearchResponse<FeaturedDestination>>> {
        const url = `${environment.corePath}/activities/offers/${siteOffersGroupTypes.FEATURED_DESTINATIONS}`;

        return this.http.get(url) as Observable<DataTemplate<GenericSearchResponse<FeaturedDestination>>>;
    }

    getBestDeals(): Observable<DataTemplate<GenericSearchResponse<BestDeal>>> {
        const url = `${environment.corePath}/activities/offers/${siteOffersGroupTypes.BEST_DEALS}`;

        return this.http.get(url) as Observable<DataTemplate<GenericSearchResponse<BestDeal>>>;
    }

    searchDestinations(criteria: string): Observable<DataTemplate<GenericSearchResponse<LocationAutocompleteItem>>> {
        const url = `${environment.activitiesPath}/locations`;

        const params = new HttpParams().set('term', criteria);

        return this.http.get(url, { params }) as Observable<
            DataTemplate<GenericSearchResponse<LocationAutocompleteItem>>
        >;
    }

    getCategories(): Observable<DataTemplate<GenericSearchResponse<GenericListItem>>> {
        const url = `${environment.activitiesPath}/categories`;

        return this.http.get(url) as Observable<DataTemplate<GenericSearchResponse<GenericListItem>>>;
    }

    search(request: SearchRequest): Observable<DataTemplate<SearchResponse>> {
        const url = `${environment.activitiesPath}/search`;

        return this.http.post(url, request) as Observable<DataTemplate<SearchResponse>>;
    }

    getSavedSearch(savedSearchCode: string, persist = false): Observable<DataTemplate<SavedSearch>> {
        const url = `${environment.activitiesPath}/search/${savedSearchCode}`;

        if (persist) {
            return this.http.post(url, null).pipe(
                tap((data) => {
                    sessionStorage.setItem(`activities_saved_search_${savedSearchCode}`, JSON.stringify(data));
                }),
                map((data) => data as DataTemplate<SavedSearch>)
            ) as Observable<DataTemplate<SavedSearch>>;
        } else {
            const savedSearch = JSON.parse(
                sessionStorage.getItem(`activities_saved_search_${savedSearchCode}`)
            ) as DataTemplate<SavedSearch>;

            if (savedSearch) {
                sessionStorage.removeItem(`activities_saved_search_${savedSearchCode}`);
                return of(savedSearch as DataTemplate<SavedSearch>);
            } else {
                return this.http.post(url, null) as Observable<DataTemplate<SavedSearch>>;
            }
        }
    }

    getRecentSearches(limit = 3): Observable<DataTemplate<GenericSearchResponse<SearchRequest>>> {
        const url = `${environment.activitiesPath}/recentSearches?limit=${limit}`;

        return this.http.get(url) as Observable<DataTemplate<GenericSearchResponse<SearchRequest>>>;
    }

    getDetails(id: string): Observable<DataTemplate<ActivityDetails>> {
        const url = `${environment.activitiesPath}/${id}`;

        return this.http.get(url) as Observable<DataTemplate<ActivityDetails>>;
    }

    getReviews(id: string, pagination: SimplePagination): Observable<DataTemplate<PaginatedSearchResponse<Review>>> {
        const url = `${environment.activitiesPath}/${id}/reviews`;

        const params = new HttpParams()
            .set('seek', pagination.seek.toString())
            .set('pageSize', pagination.pageSize.toString());

        return this.http.get(url, { params }) as Observable<DataTemplate<PaginatedSearchResponse<Review>>>;
    }

    getAvailability(id: string): Observable<DataTemplate<GenericSearchResponse<string>>> {
        const url = `${environment.activitiesPath}/${id}/availability`;

        return this.http.get(url) as Observable<DataTemplate<GenericSearchResponse<string>>>;
    }

    checkAvailability(
        id: string,
        request: SearchSchedulesRequest
    ): Observable<DataTemplate<GenericSearchResponse<Schedule>>> {
        const url = `${environment.activitiesPath}/${id}/checkAvailability`;

        return this.http.post(url, request) as Observable<DataTemplate<GenericSearchResponse<Schedule>>>;
    }

    getTravelerProfiles(): Observable<DataTemplate<TravelerProfile[]>> {
        const url = `${environment.activitiesPath}/travprofiles`;

        return this.http.get(url) as Observable<DataTemplate<TravelerProfile[]>>;
    }

    getCardProfiles(): Observable<DataTemplate<CardProfile[]>> {
        const url = `${environment.activitiesPath}/ccardprofiles`;

        return this.http.get(url) as Observable<DataTemplate<CardProfile[]>>;
    }

    getHotels(id: string): Observable<DataTemplate<GenericSearchResponse<ActivityHotel>>> {
        const url = `${environment.activitiesPath}/${id}/hotels`;

        return this.http.get(url) as Observable<DataTemplate<GenericSearchResponse<ActivityHotel>>>;
    }

    computePrice(
        scheduleId: string,
        ageBands: TravelerAgeBand[],
        options?: ComputePriceInput
    ): Observable<DataTemplate<BookPrice>> {
        const url = `${environment.activitiesPath}/${scheduleId}/price`;

        let params = new HttpParams();

        if (options?.payPoints || options?.payPoints === 0) {
            params = params.append('payPoints', options.payPoints.toString());
        }

        if (options?.useCredit) {
            params = params.append('useCredit', 'true');
        }

        if (ageBands?.length) {
            params = params.append('ageBands', ageBands.map((a) => a.bandId).join(','));
        }

        return this.http.get(url, { params }) as Observable<DataTemplate<BookPrice>>;
    }

    book(request: BookRequest): Observable<DataTemplate<BookResponse>> {
        const url = `${environment.activitiesPath}/book`;

        return this.http.post(url, request) as Observable<DataTemplate<BookResponse>>;
    }
}
