import { AfterViewInit, Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { LoaderService } from '@core/services';
import { I18nRequest, SharedStorageKeys } from '@shared/models';
import { I18nRefreshService, I18nService, StorageService } from '@shared/services';
import { forkJoin, Observable, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

@Directive({
    selector: '[appTranslate]',
})
export class I18nDirective implements OnInit, OnDestroy, AfterViewInit {
    @Input()
    global = false;

    sharedStorageKeys = SharedStorageKeys;
    showLoader: boolean;

    private unsubscribe: Subject<void> = new Subject();

    constructor(
        private elementRef: ElementRef,
        private renderer: Renderer2,
        private i18nService: I18nService,
        private i18nRefreshService: I18nRefreshService,
        private loader: LoaderService,
        private storage: StorageService
    ) {}

    ngOnInit(): void {
        this.renderer.addClass(this.getElement(), 'opacity-0');
        this.i18nRefreshService
            .getState()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                if (this.global) {
                    this.loader.show();
                    this.changeLanguage(this.getElement(), true);
                }
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    ngAfterViewInit(): void {
        this.changeLanguage(this.getElement(), true);
    }

    getElement(): any {
        return this.elementRef.nativeElement;
    }

    changeLanguage(element: Element, languageChange = false): void {
        if (!this.storage.getItem(this.sharedStorageKeys.CURRENT_LANGUAGE)) {
            this.storage.setItem(this.sharedStorageKeys.CURRENT_LANGUAGE, 'en');
        }

        let keys = this.storage.getItem(
            `${this.storage.getItem(this.sharedStorageKeys.CURRENT_LANGUAGE)}_${this.sharedStorageKeys.I18N_KEYS}`
        );

        if (!keys) {
            keys = {};
        }

        const allChildren: Array<Element> = Array.from(element.querySelectorAll('*[data-i18n-code]'));
        const children = allChildren.filter((child: Element) => !child.getAttribute('data-i18n-withParams'));
        const childrenWithParams = allChildren.filter((child: Element) => child.getAttribute('data-i18n-withParams'));

        const requestObject: I18nRequest[] = [];

        children.forEach((child: Element) => {
            if (!keys.hasOwnProperty(child.getAttribute('data-i18n-code'))) {
                let i18nRequest: I18nRequest = null;

                i18nRequest = {
                    code: child.getAttribute('data-i18n-code'),
                };

                if (child.getAttribute('data-i18n-params')) {
                    // TODO: Is this showLoader a good idea? Does it work properly or does it mess with the loading UX?
                    this.showLoader = true;
                    i18nRequest.params = child.getAttribute('data-i18n-params').split('|');

                    i18nRequest.params.forEach((param: any, index: number) => {
                        if (!isNaN(param)) {
                            i18nRequest.params[index] = parseInt(param, 10);
                        }
                    });
                }

                if (i18nRequest?.code) {
                    requestObject.push(i18nRequest);
                }
            }
        });

        const paramsKeysObservers: Observable<any>[] = [];
        if (languageChange) {
            const paramKeysMap: Record<string, any> = {};

            childrenWithParams.forEach((child: Element) => {
                let i18nRequest: I18nRequest = null;

                i18nRequest = {
                    code: child.getAttribute('data-i18n-code').split('|')[0],
                };

                i18nRequest.params = child.getAttribute('data-i18n-params').split('|');

                i18nRequest.params.forEach((param: any, index: number) => {
                    if (!isNaN(param)) {
                        i18nRequest.params[index] = parseInt(param, 10);
                    }
                });

                if (paramKeysMap.hasOwnProperty(child.getAttribute('data-i18n-withParams'))) {
                    paramKeysMap[child.getAttribute('data-i18n-withParams')].push(i18nRequest);
                } else {
                    paramKeysMap[child.getAttribute('data-i18n-withParams')] = [i18nRequest];
                }
            });

            for (const key in paramKeysMap) {
                if (paramKeysMap.hasOwnProperty(key)) {
                    paramsKeysObservers.push(this.i18nService.preloadWithParamsKeys(paramKeysMap[key], key));
                }
            }

            if (paramsKeysObservers.length) {
                forkJoin(paramsKeysObservers)
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe(() => {
                        this.decideRequestRender(requestObject, element, allChildren, keys);
                    });
            } else {
                this.decideRequestRender(requestObject, element, allChildren, keys);
            }
        } else {
            this.decideRequestRender(requestObject, element, allChildren, keys);
        }
    }

    decideRequestRender(
        requestObject: I18nRequest[],
        element: Element,
        children: Element[],
        keys: Record<string, any>
    ): void {
        if (requestObject.length) {
            this.requestKeys(requestObject, element, children);
        } else {
            children.forEach((child: Element) => {
                this.applyKeyValue(child, keys);
            });

            this.renderer.removeClass(element, 'opacity-0');

            if (this.global) {
                this.loader.hide();
            }
        }
    }

    requestKeys(requestObject: I18nRequest[], element: Element, children: Element[]): void {
        if (this.showLoader && !this.global) {
            this.loader.show();
        }

        this.i18nService
            .getKeys(requestObject)
            .pipe(
                takeUntil(this.unsubscribe),
                finalize(() => {
                    this.renderer.removeClass(element, 'opacity-0');

                    if (this.global || this.showLoader) {
                        this.loader.hide();
                    }

                    this.showLoader = false;
                })
            )
            .subscribe(
                (response: any) => {
                    const tempKeys = this.storage.getItem<Record<string, string>>(
                        `${this.storage.getItem(this.sharedStorageKeys.CURRENT_LANGUAGE)}_${
                            this.sharedStorageKeys.I18N_KEYS
                        }`
                    );

                    const newKeys = { ...tempKeys, ...response.data };

                    children.forEach((child: Element) => {
                        this.applyKeyValue(child, newKeys);
                    });

                    this.storage.setItem(
                        `${this.storage.getItem(this.sharedStorageKeys.CURRENT_LANGUAGE)}_${
                            this.sharedStorageKeys.I18N_KEYS
                        }`,
                        newKeys
                    );
                },
                (error: string) => {
                    console.error(error);
                }
            );
    }

    applyKeyValue(element: Element, keys: Record<string, any>): void {
        const keyValue = keys[element.getAttribute('data-i18n-code')];

        switch (element.getAttribute('data-i18n-target')) {
            case 'placeholder':
                element.setAttribute('placeholder', keyValue);
                break;
            case 'label':
                element.setAttribute('label', keyValue);
                break;
            default:
                element.innerHTML = keyValue;
                break;
        }

        if (
            keys[element.getAttribute('data-i18n-code')] &&
            element.getAttribute('data-i18n-params') &&
            !element.getAttribute('data-i18n-persist')
        ) {
            delete keys[element.getAttribute('data-i18n-code')];
        }
    }
}
