import { ConfirmationDetails } from '@account/models';
import { AccountService } from '@account/services';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { LoaderService } from '@core/services';
import { DataTemplate } from '@shared/models';
import { of, Subject } from 'rxjs';
import { catchError, mergeMap, take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ConfirmationDetailsResolverService implements Resolve<ConfirmationDetails> {
    private unsubscribe: Subject<void> = new Subject<void>();

    constructor(private accountService: AccountService, private loader: LoaderService) {}

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    resolve(route: ActivatedRouteSnapshot): any {
        this.loader.show();

        return this.accountService.getConfirmation(route.params['id']).pipe(
            take(1),
            mergeMap((response: DataTemplate<ConfirmationDetails>) => {
                this.loader.hide();
                return of(response.data);
            }),
            catchError(() => {
                this.loader.hide();
                return of(null);
            })
        );
    }
}
