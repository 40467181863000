<form [formGroup]="memberFormGroup">
    <div class="row">
        <div class="col-sm-6 col-xxxs-12">
            <app-input
                label="core.labels.firstName"
                [inputType]="'text'"
                [group]="memberFormGroup"
                [errorMessages]="formErrors?.firstName"
                name="firstName"
                [autocomplete]="'given-name'"
            ></app-input>
        </div>
        <div class="col-sm-6 col-xxxs-12">
            <app-input
                label="core.labels.lastName"
                [inputType]="'text'"
                [group]="memberFormGroup"
                [errorMessages]="formErrors?.lastName"
                name="lastName"
                [autocomplete]="'family-name'"
            ></app-input>
        </div>
        <div class="col-sm-6 col-xxxs-12">
            <app-input
                label="core.labels.email"
                inputType="email"
                [group]="memberFormGroup"
                [errorMessages]="formErrors?.email"
                name="email"
                [autocomplete]="'email'"
            ></app-input>
        </div>
        <div class="col-sm-6 col-xxxs-12">
            <app-input
                label="core.labels.phone"
                [inputType]="'text'"
                [group]="memberFormGroup"
                [errorMessages]="formErrors?.phone"
                name="phone"
                [autocomplete]="'tel'"
            ></app-input>
        </div>
    </div>
</form>
