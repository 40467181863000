import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';

@Component({
    selector: 'app-toggle-read-more',
    templateUrl: './toggle-read-more.component.html',
    styleUrls: ['./toggle-read-more.component.scss'],
})
export class ToggleReadMoreComponent {
    firstTime = true;
    readMore: boolean;

    @Output()
    readMoreChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild('readMoreEl', { static: false }) set contentReadMore(el: ElementRef) {
        if (this.firstTime) {
            this.firstTime = false;
            return;
        }

        if (el) {
            el.nativeElement.focus();
        }
    }

    toggleReadMore(value: boolean): void {
        this.readMore = value;
        this.readMoreChanged.emit(value);
    }
}
