import { Component, OnInit } from '@angular/core';
import { SiteBranding } from '@core/models';
import { SharedStorageKeys } from '@shared/models';
import { UrlService } from '@shared/services';

@Component({
    selector: 'app-login-footer',
    templateUrl: './login-footer.component.html',
    styleUrls: ['./login-footer.component.scss'],
})
export class LoginFooterComponent implements OnInit {
    sharedStorageKeys = SharedStorageKeys;
    siteBranding: SiteBranding;

    constructor(public url: UrlService) {}

    ngOnInit(): void {
        this.siteBranding = JSON.parse(localStorage.getItem(this.sharedStorageKeys.SITE_BRANDING)) as SiteBranding;
    }
}
