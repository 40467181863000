import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AssetValue, BookPrice } from '@shared/models';
import { EXPEDIA_SUPPLIER_IDS, MODULES } from '@utils';

import { GenericDialogComponent } from '../../../generic-dialog/generic-dialog.component';

@Component({
    selector: 'app-taxes',
    templateUrl: './taxes.component.html',
    styleUrls: ['./taxes.component.scss'],
})
export class TaxesComponent {
    @Input()
    target: MODULES;

    @Input()
    bookPrice: BookPrice;

    @Input()
    invsrcId: number;

    @Input()
    taxBreakdown: AssetValue[];

    @Input()
    serviceCharge: AssetValue;

    showTaxBreakdown: boolean;

    constructor(private dialog: MatDialog) {}

    openTaxInfoDialog(): void {
        const hasExpediaDisclaimer = Boolean(EXPEDIA_SUPPLIER_IDS.includes(this.invsrcId));

        this.dialog.open(GenericDialogComponent, {
            width: '100%',
            maxWidth: '960px',
            data: {
                title: 'core.labels.taxesFeesDisclaimer',
                body: hasExpediaDisclaimer ? 'hotels.taxDisclaimer.expedia.label' : `${this.target}.taxDisclaimer`,
            },
        });
    }
}
