<div class="form-checkbox" [formGroup]="group">
    <mat-checkbox
        appDisableControl
        class="app-form-element"
        [formControlName]="name"
        [color]="color"
        [disableControl]="disabled"
        [attr.aria-describedby]="name + '-error'"
        [attr.aria-invalid]="errorMessages"
    >
        <span [attr.data-i18n-code]="label"></span>
    </mat-checkbox>
    <div>
        <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
    </div>
    <mat-error role="alert" [attr.id]="name + '-error'" *ngIf="errorMessages">
        {{ errorMessages }}
    </mat-error>
</div>
