import { Component } from '@angular/core';
import { SiteBranding } from '@core/models';
import { AuthService } from '@core/services';
import { SharedStorageKeys } from '@shared/models';
import { UrlService } from '@shared/services';

@Component({
    selector: 'app-login-header',
    templateUrl: './login-header.component.html',
    styleUrls: ['./login-header.component.scss'],
})
export class LoginHeaderComponent {
    sharedStorageKeys = SharedStorageKeys;
    siteBranding: SiteBranding;
    logoUrl: string;

    constructor(private url: UrlService, private authService: AuthService) {}

    ngOnInit(): void {
        this.siteBranding = JSON.parse(localStorage.getItem(this.sharedStorageKeys.SITE_BRANDING)) as SiteBranding;

        this.logoUrl =
            this.siteBranding?.uiConfig?.siteOptions?.designOptions?.header?.logoUrl ||
            this.url.getUrl(this.siteBranding?.uiConfig.modules[0]);

        if (this.siteBranding?.uiConfig?.siteOptions?.isHomeEnabled && this.authService.isGuest()) {
            this.logoUrl = this.url.getUrl('home');
        }
    }
}
