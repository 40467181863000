<div appTranslate id="payment-billing-form" *ngIf="loaded">
    <div
        appTranslate
        id="payment-billing-form-cards-wrapper"
        *ngIf="showCards && !cardFormGroup.get('payByInvoice').value"
    >
        <p class="form-group-title" [attr.data-i18n-code]="'core.labels.savedCards'"></p>

        <div appTranslate id="payment-billing-form-cards" class="row margin-bottom-20">
            <ng-container *ngIf="!userInfo?.assisted">
                <ng-container *ngFor="let card of cardProfiles">
                    <div class="col-xl-4 col-sm-6 col-xxxs-12 margin-xl-bottom-10">
                        <app-payment-card [card]="card" (click)="setActiveCard(card)"></app-payment-card>
                    </div>
                </ng-container>
            </ng-container>

            <div class="col-xl-4 col-sm-6 col-xxxs-12">
                <app-payment-card [newCard]="newCard" (click)="setActiveCard(null)"></app-payment-card>
            </div>
        </div>
    </div>

    <form [formGroup]="cardFormGroup">
        <div class="margin-bottom-20" *ngIf="userInfo?.assisted?.allowInvoicePayments">
            <p class="form-group-title" [attr.data-i18n-code]="'core.labels.paymentMethod'"></p>
            <mat-radio-group [formControlName]="'payByInvoice'" (change)="updatePayByInvoice($event)">
                <mat-radio-button [value]="false">
                    <span [attr.data-i18n-code]="'core.labels.cardMethod'"></span>
                </mat-radio-button>
                <mat-radio-button [value]="true">
                    <span [attr.data-i18n-code]="'core.labels.invoiceMethod'"></span>
                </mat-radio-button>
            </mat-radio-group>
        </div>

        <div [class.hide]="cardFormGroup.get('payByInvoice').value">
            <p class="form-group-title" [attr.data-i18n-code]="'core.labels.cardDetails'"></p>
            <div class="row">
                <div class="col-sm-6 col-xxxs-12">
                    <app-select
                        label="core.labels.cardType"
                        [options]="cardOptions"
                        [group]="cardFormGroup"
                        [errorMessages]="formErrors?.ccType"
                        name="ccType"
                        [disabled]="!newCard"
                        [autocomplete]="'cc-type'"
                    ></app-select>
                </div>
                <div class="col-sm-6 col-xxxs-12">
                    <app-input
                        label="core.labels.cardNumber"
                        [inputType]="'text'"
                        [group]="cardFormGroup"
                        [errorMessages]="formErrors?.ccNumber"
                        name="ccNumber"
                        [disabled]="!newCard || !this.cardFormGroup.get('ccType').value"
                        [autocomplete]="'cc-number'"
                    ></app-input>
                </div>
                <div class="col-xl-6 col-xxxs-12">
                    <app-input
                        label="core.labels.cardName"
                        [inputType]="'text'"
                        [group]="cardFormGroup"
                        [errorMessages]="formErrors?.ccNameOnCard"
                        name="ccNameOnCard"
                        [disabled]="!newCard"
                        [autocomplete]="'cc-name'"
                    ></app-input>
                </div>
                <div class="col-xl-2 col-sm-4 col-xxxs-12">
                    <app-select
                        label="core.labels.cardExpMonth"
                        [options]="cardMonths"
                        [group]="cardFormGroup"
                        [errorMessages]="formErrors?.ccExpM"
                        name="ccExpM"
                        [disabled]="!newCard"
                        [autocomplete]="'cc-exp-month'"
                    ></app-select>
                </div>
                <div class="col-xl-2 col-sm-4 col-xxxs-12">
                    <app-select
                        label="core.labels.cardExpYear"
                        [options]="cardYears"
                        [group]="cardFormGroup"
                        [errorMessages]="formErrors?.ccExpY"
                        name="ccExpY"
                        [disabled]="!newCard"
                        [autocomplete]="'cc-exp-year'"
                    ></app-select>
                </div>
                <div class="col-xl-2 col-sm-4 col-xxxs-12">
                    <app-input
                        label="core.labels.cardCvv"
                        [inputType]="'text'"
                        [group]="cardFormGroup"
                        [errorMessages]="formErrors?.ccCvv"
                        name="ccCvv"
                        [disabled]="!newCard"
                        [autocomplete]="'cc-csc'"
                    ></app-input>
                </div>
            </div>
        </div>

        <p class="form-group-title" [attr.data-i18n-code]="'core.labels.billingInformation'"></p>
        <div class="row">
            <div class="col-sm-6 col-xxxs-12" [class.hide]="!cardFormGroup.get('payByInvoice').value">
                <app-input
                    label="core.labels.invoiceCompany"
                    [inputType]="'text'"
                    [group]="cardFormGroup"
                    [errorMessages]="formErrors?.company"
                    name="company"
                ></app-input>
            </div>
            <div class="col-sm-6 col-xxxs-12" [class.hide]="!cardFormGroup.get('payByInvoice').value">
                <app-input
                    label="core.labels.invoiceContactName"
                    [inputType]="'text'"
                    [group]="cardFormGroup"
                    [errorMessages]="formErrors?.contactName"
                    name="contactName"
                ></app-input>
            </div>
            <div
                class="col-sm-6 col-xxxs-12"
                [class.hide]="cardFormGroup.get('payByInvoice').value || hideBillingNames"
            >
                <app-input
                    label="core.labels.firstName"
                    [inputType]="'text'"
                    [group]="cardFormGroup"
                    [errorMessages]="formErrors?.firstName"
                    name="firstName"
                    [disabled]="!newCard"
                    [autocomplete]="'given-name'"
                ></app-input>
            </div>
            <div
                class="col-sm-6 col-xxxs-12"
                [class.hide]="cardFormGroup.get('payByInvoice').value || hideBillingNames"
            >
                <app-input
                    label="core.labels.lastName"
                    [inputType]="'text'"
                    [group]="cardFormGroup"
                    [errorMessages]="formErrors?.lastName"
                    name="lastName"
                    [disabled]="!newCard"
                    [autocomplete]="'family-name'"
                ></app-input>
            </div>
            <div class="col-sm-6 col-xxxs-12">
                <app-input
                    label="core.labels.email"
                    [inputType]="'text'"
                    [group]="cardFormGroup"
                    [errorMessages]="formErrors?.email"
                    name="email"
                    [disabled]="!newCard"
                ></app-input>
            </div>
            <div class="col-sm-6 col-xxxs-12">
                <app-input
                    label="core.labels.userAddress"
                    [inputType]="'text'"
                    [group]="cardFormGroup"
                    [errorMessages]="formErrors?.address1"
                    name="address1"
                    [disabled]="!newCard"
                    [autocomplete]="'address-line1'"
                ></app-input>
            </div>
            <div class="col-sm-6 col-xxxs-12">
                <app-input
                    label="core.labels.city"
                    [inputType]="'text'"
                    [group]="cardFormGroup"
                    [errorMessages]="formErrors?.city"
                    name="city"
                    [disabled]="!newCard"
                    [autocomplete]="'address-level2'"
                ></app-input>
            </div>
            <div class="col-sm-6 col-xxxs-12">
                <app-select
                    label="core.labels.country"
                    [options]="countries"
                    [group]="cardFormGroup"
                    [errorMessages]="formErrors?.countryId"
                    name="countryId"
                    [disabled]="!newCard"
                ></app-select>
            </div>
            <div
                appTranslate
                id="payment-billing-form-provinces"
                class="col-sm-6 col-xxxs-12"
                [class.hide]="!provinces?.length"
            >
                <app-select
                    label="core.labels.state"
                    [options]="provinces"
                    [group]="cardFormGroup"
                    [errorMessages]="formErrors?.provinceId"
                    name="provinceId"
                    [disabled]="!newCard"
                ></app-select>
            </div>
            <div
                appTranslate
                id="payment-billing-form-province"
                class="col-sm-6 col-xxxs-12"
                [class.hide]="provinces?.length"
            >
                <app-input
                    label="core.labels.stateName"
                    [inputType]="'text'"
                    [group]="cardFormGroup"
                    [errorMessages]="formErrors?.provinceName"
                    name="provinceName"
                    [disabled]="!newCard"
                    [autocomplete]="'address-level1'"
                ></app-input>
            </div>
            <div class="col-sm-6 col-xxxs-12">
                <app-input
                    label="core.labels.zip"
                    [inputType]="'text'"
                    [group]="cardFormGroup"
                    [errorMessages]="formErrors?.postalCode"
                    name="postalCode"
                    [disabled]="!newCard"
                    [autocomplete]="'postal-code'"
                ></app-input>
            </div>
        </div>

        <app-checkbox
            label="core.labels.saveCard"
            [color]="'primary'"
            [group]="cardFormGroup"
            name="saveCard"
            [disabled]="!newCard"
            [class.hide]="!newCard || cardFormGroup.get('payByInvoice').value"
            *ngIf="!hideSaveCheckbox"
        ></app-checkbox>
        <app-checkbox
            label="core.labels.hideResPrice"
            [color]="'primary'"
            [group]="cardFormGroup"
            name="hideDetails"
            [class.hide]="!cardFormGroup.get('payByInvoice').value"
        ></app-checkbox>
    </form>
</div>

<div class="flex-container justify-content-center" *ngIf="!loaded">
    <mat-spinner></mat-spinner>
</div>
