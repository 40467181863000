import { Component, Input } from '@angular/core';
import { ModuleDialogContent, SharedStorageKeys } from '@shared/models';

@Component({
    selector: 'app-module-dialog-template',
    templateUrl: './dialog-template.component.html',
    styleUrls: ['./dialog-template.component.scss'],
})
export class DialogTemplateComponent {
    @Input()
    content: ModuleDialogContent;

    sharedStorageKeys = SharedStorageKeys;
    currentLanguage = sessionStorage.getItem(this.sharedStorageKeys.CURRENT_LANGUAGE);
}
