import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MenuLink, SiteBranding, UserInfo } from '@core/models';
import { AuthService } from '@core/services';
import { MenuItem, SharedStorageKeys } from '@shared/models';
import { HeaderService, UrlService } from '@shared/services';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
    ActivitiesDialogComponent,
    CarsDialogComponent,
    CruisesDialogComponent,
    FlightsDialogComponent,
    HotelsDialogComponent,
    WeeksDialogComponent,
} from '../navigation-items/components';
import { RewardsDialogComponent } from '../navigation-locales/rewards-dialog/rewards-dialog.component';
@Component({
    selector: 'app-left-navigation',
    templateUrl: './left-navigation.component.html',
    styleUrls: ['./left-navigation.component.scss'],
})
export class LeftNavigationComponent implements OnInit, OnDestroy {
    @Output()
    closeNavigation: EventEmitter<boolean> = new EventEmitter<boolean>();

    sharedStorageKeys = SharedStorageKeys;
    userInfo: UserInfo;
    userInitials: string;
    menuItems: MenuItem[] = [];
    navTarget: string;
    siteBranding: SiteBranding;
    loggedIn$: Observable<boolean>;
    isModalModules: boolean;

    private unsubscribe: Subject<void> = new Subject();

    constructor(
        private authService: AuthService,
        private headerService: HeaderService,
        public url: UrlService,
        private dialog: MatDialog
    ) {}

    ngOnInit(): void {
        this.siteBranding = JSON.parse(localStorage.getItem(this.sharedStorageKeys.SITE_BRANDING)) as SiteBranding;
        this.navTarget = sessionStorage.getItem(this.sharedStorageKeys.HEADER_TARGET);

        this.isModalModules =
            this.siteBranding?.uiConfig.siteOptions.moduleModalsForGuests && this.authService.isGuest();

        this.headerService.target.pipe(takeUntil(this.unsubscribe)).subscribe((response: string) => {
            this.navTarget = response;
        });

        this.loggedIn$ = this.authService.loggedIn;
        this.checkUserInfo();

        this.headerService.fixed.subscribe(() => {
            this.checkUserInfo();
        });

        if (!this.siteBranding?.uiConfig?.siteOptions?.designOptions?.header?.topMenu?.length) {
            this.siteBranding?.uiConfig?.modules?.forEach((module: string) => {
                this.menuItems.push({
                    code: `nav.${module}.label`,
                    active: false,
                    customUrl: this.url.getUrl(module),
                    module,
                    isModal: this.isModalModules,
                });
            });

            if (this.siteBranding?.uiConfig?.pointsOptions?.rewardsActive) {
                this.menuItems.push({
                    code: 'nav.rewards.label',
                    active: true,
                    module: 'rewards',
                    isModal: this.isModalModules,
                });
            }
        } else {
            this.siteBranding?.uiConfig?.siteOptions?.designOptions?.header?.topMenu.forEach((item: MenuLink) => {
                this.menuItems.push({
                    code: item.name,
                    active: false,
                    customUrl: item.customUrl.split('#')?.[0],
                    fragment: item.customUrl.split('#')?.[1],
                    target: item.target,
                    module: item.module,
                });
            });
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    close(): void {
        this.closeNavigation.emit(true);
    }

    checkUserInfo(): void {
        this.userInfo = JSON.parse(localStorage.getItem(this.sharedStorageKeys.USER_INFO) || null) as UserInfo;
        this.siteBranding = JSON.parse(localStorage.getItem(this.sharedStorageKeys.SITE_BRANDING)) as SiteBranding;

        if (this.userInfo && !this.authService.isGuest()) {
            if (this.userInfo.photo && !this.userInfo.photo.includes('base64')) {
                this.userInfo.photo = 'data:image/jpg;base64,' + this.userInfo.photo;
            } else {
                this.userInitials = `${this.userInfo.firstName[0]}${this.userInfo.lastName[0]}`;
            }
        }
    }

    logout(): void {
        this.userInfo = null;
        this.userInitials = null;
        this.authService.logout();
        this.close();
    }

    openModuleModal(module: string): void {
        let dialog = null;

        switch (module) {
            case 'hotels':
                dialog = HotelsDialogComponent;
                break;
            case 'weeks':
                dialog = WeeksDialogComponent;
                break;
            case 'flights':
                dialog = FlightsDialogComponent;
                break;
            case 'cars':
                dialog = CarsDialogComponent;
                break;
            case 'cruises':
                dialog = CruisesDialogComponent;
                break;
            case 'activities':
                dialog = ActivitiesDialogComponent;
                break;
            case 'rewards':
                dialog = RewardsDialogComponent;
                break;
        }

        if (dialog) {
            this.dialog.open(dialog, {
                width: '100%',
                maxWidth: '640px',
            });
        }
    }
}
