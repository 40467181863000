import { PayPriceBreakdown, TravelerProfile } from '@shared/models';

export class BookResponse {
    flightDescription: string;
    travelers: TravelerProfile[];
    supplierLocatorCodes: string;
    providerLocatorCodes: string;
    departTime: string;
    arriveTime: string;
    txGuid: string;
    outgoingSegments: any[];
    outgoingTotalFlightTime: string;
    outgoingDaysOffset: number;
    outgoingNoOfStops: number;
    incomingSegments: any[];
    incomingTotalFlightTime: string;
    incomingDaysOffset: number;
    incomingNoOfStops: number;
    isOneWay: boolean;
    payCardName: string;
    payCardType: string;
    payCardNumber: string;
    payCardExpDate: string;
    payPriceBreakdown: PayPriceBreakdown[];
    payTotalAmount: string;
    payPointsEarned: string;
}
