export const pageTitles = {
    landing: 'Activities Home',
    search: 'Activities Search Results',
    book: 'Activities Participant & Payment Information',
    bookConfirmation: 'Activities Transaction Completed',
    bookConfirmationProfile: 'Activities Transaction Confirmation',
    bookReceipt: 'Activities Transaction Receipt',
    cancellation: 'Activities Cancellation',
    cancellationConfirmation: 'Activities Cancellation Confirmation',
    details: 'Activities Details & Selection',
};
