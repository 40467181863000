import { Entity, Of } from '@utils';

@Entity
export class I18nContent {
    @Of(() => String, { optional: true })
    type?: 'title' | 'paragraph' = 'title';

    @Of(() => () => ({
        String,
    }))
    value: Record<string, string> = {};

    static of = Entity.of<I18nContent>();
}
