<header class="header variant-{{ headerVariant }}" #header [class.fixed]="isFixed">
    <section class="header-wrapper height-100">
        <nav class="navigation-wrapper height-100">
            <mat-toolbar class="navbar">
                <div class="navigation-elements-wrapper width-100 padding-left-40 padding-right-40 align-items-center">
                    <a
                        *ngIf="!logoUrl.startsWith('http')"
                        class="site-name-link flex-container"
                        [routerLink]="logoUrl"
                        [attr.aria-label]="siteBranding?.uiConfig?.siteOptions?.siteName"
                    >
                        <div class="logo responsive-logo"></div>
                    </a>

                    <a
                        *ngIf="logoUrl.startsWith('http')"
                        class="site-name-link flex-container"
                        [attr.href]="logoUrl"
                        [attr.aria-label]="siteBranding?.uiConfig?.siteOptions?.siteName"
                    >
                        <div class="logo responsive-logo"></div>
                    </a>

                    <div class="position-relative hide-below-sm">
                        <app-navigation-items
                            [headerVariant]="headerVariant"
                            [navTarget]="navTarget"
                            [class.hide-below-md]="
                                siteBranding?.uiConfig?.siteOptions?.designOptions?.userMenu?.includeModules
                            "
                        ></app-navigation-items>

                        <div class="navigation-indicator-rail hide-below-sm"></div>
                    </div>

                    <div class="user-actions-wrapper flex-container justify-content-end align-items-center">
                        <app-navigation-phones></app-navigation-phones>
                        <app-navigation-locales></app-navigation-locales>
                        <app-navigation-user-details
                            [navTarget]="navTarget"
                            class="hide-below-sm"
                            [class.hide-below-md]="userMenuVariant === 'noAvatar'"
                        ></app-navigation-user-details>

                        <div
                            class="no-avatar-menu-wrapper flex-container align-items-center hide-md-up"
                            *ngIf="userMenuVariant === 'noAvatar'"
                        >
                            <img src="/assets/images/ll-menu-icon-white.png" class="user-icon margin-right-10" />

                            <div
                                class="menu-button-icon cursor-pointer flex-container flex-column justify-content-center"
                                (click)="showLeftNavigation = !showLeftNavigation"
                            >
                                <div class="menu-line"></div>
                                <div class="menu-line"></div>
                                <div class="menu-line"></div>
                            </div>
                        </div>

                        <div
                            class="menu-button-icon cursor-pointer flex-container justify-content-center hide-sm-up"
                            (click)="showLeftNavigation = !showLeftNavigation"
                            *ngIf="userMenuVariant === 'default'"
                        >
                            <div class="menu-circle"></div>
                            <div class="menu-circle"></div>
                            <div class="menu-circle"></div>
                        </div>
                    </div>
                </div>
            </mat-toolbar>
        </nav>

        <div
            appTranslate
            [class.hide-md-up]="userMenuVariant === 'noAvatar'"
            [class.hide-sm-up]="userMenuVariant === 'default'"
            *ngIf="showLeftNavigation"
        >
            <app-left-navigation
                [class.left-navigation]="
                    siteBranding?.uiConfig.siteOptions.designOptions.userMenu.variant === 'default'
                "
                [class.left-navigation-noAvatar]="
                    siteBranding?.uiConfig.siteOptions.designOptions.userMenu.variant === 'noAvatar'
                "
                [class.active]="showLeftNavigation"
                (closeNavigation)="showLeftNavigation = false"
            >
            </app-left-navigation>
        </div>
    </section>
</header>

<div class="spacing-helper hide" #spacingHelper [class.force-hide]="isFixed"></div>
