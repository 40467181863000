<mat-icon [svgIcon]="'cruise'" class="module-icon"></mat-icon>
<div>
    <p class="primary-data margin-bottom-5">
        <span>{{ search.destinationLabel }}</span>
    </p>

    <p class="secondary-data margin-bottom-5">
        {{ search.date | date: 'MMMM YYYY' }}
    </p>

    <div class="secondary-data flex-container align-items-center">
        <span>1 <span class="text-capitalize" [attr.data-i18n-code]="'cruises.landing.cabin.label'"></span></span>

        <span class="bullet margin-left-5 margin-right-5"></span>

        <span
            >{{ adults }}
            <span class="text-lowercase">
                <span [class.hide]="adults !== 1" [attr.data-i18n-code]="'core.labels.adult'"></span>
                <span [class.hide]="adults === 1" [attr.data-i18n-code]="'core.labels.adults'"></span>
            </span>
        </span>

        <span appTranslate *ngIf="children > 0"
            >, {{ children }}
            <span class="text-lowercase">
                <span [class.hide]="children !== 1" [attr.data-i18n-code]="'core.labels.child'"></span>
                <span [class.hide]="children === 1" [attr.data-i18n-code]="'core.labels.children'"></span>
            </span>
        </span>
    </div>
</div>
