export enum StorageKeys {
    SEARCH_REQUEST = 'cruises_search_request',
    SEARCH_USE_CERTIFICATE = 'cruises_search_use_certificate',
    SEARCH_RESPONSE = 'cruises',
    SEARCH_FILTERS = 'cruises_filters',
    SEARCH_FILTERS_MIN_PRICE = 'cruises_min_price',
    SEARCH_FILTERS_MAX_PRICE = 'cruises_max_price',
    SEARCH_ITEM = 'cruise_search_item',
    SEARCH_SELECTED_GRADE_TYPE = 'cruise_search_selected_grade_type',
    ITEM_TO_BOOK = 'cruise_to_book',
    BOOK_RESPONSE = 'cruises_book_response',
    GRADE_CATEGORY_TO_BOOK = 'cruises_grade_category_to_book',
    CABIN_GRADE_TO_BOOK = 'cruises_cabin_grade_to_book',
    CABIN_TO_BOOK = 'cruises_cabin_to_book',
    SEARCH_ORIGIN_POINT = 'cruises_search_origin_point',
    SEARCH_START_TIME = 'cruises_search_start_time',
    SEARCH_RESULTS_START_TIME = 'cruises_search_results_start_time',
    BOOK_START_TIME = 'cruises_book_start_time',
    SEARCH_TRIGGERED = 'cruises_search_triggered',
    BOOK_TRIGGERED = 'cruises_book_triggered',
}
