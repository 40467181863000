import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { LoaderService } from '@core/services';
import { EscapeDetails } from '@escapes/models';
import { DataTemplate } from '@shared/models';
import { UrlService } from '@shared/services';
import { of, Subject } from 'rxjs';
import { catchError, finalize, mergeMap, take } from 'rxjs/operators';

import { EscapesService } from './escapes.service';

@Injectable({
    providedIn: 'root',
})
export class EscapeDetailsResolverService implements Resolve<EscapeDetails>, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    constructor(
        private escapesService: EscapesService,
        private loader: LoaderService,
        private url: UrlService,
        private router: Router
    ) {}

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    resolve(route: ActivatedRouteSnapshot): any {
        // const activity = JSON.parse(
        //     sessionStorage.getItem(`escape_${route.params['id']}`)
        // ) as DataTemplate<EscapeDetails>;

        // if (activity) {
        //     return of(activity as DataTemplate<EscapeDetails>);
        // } else {
        this.loader.show();

        return this.escapesService.getDetails(route.params['id']).pipe(
            take(1),
            finalize(() => this.loader.hide()),
            mergeMap((response: DataTemplate<EscapeDetails>) => {
                sessionStorage.setItem(`escape_${route.params['id']}`, JSON.stringify(response.data));

                return of(response.data);
            }),
            catchError(() => {
                this.router.navigateByUrl(this.url.getUrl('escapes'));
                return of(null);
            })
        );
        // }
    }
}
