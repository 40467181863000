import { AbstractControl, ValidatorFn } from '@angular/forms';

export function visaLength(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null =>
        control.value?.length === 13 || control.value?.length === 16 ? null : { invalid: true };
}

export function visaStartsWith(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null =>
        control.value?.startsWith('4') ? null : { invalid: true };
}
