import { Entity, Of } from '@utils';

@Entity
export class MemberName {
    @Of(() => String)
    firstName: string;

    @Of(() => String)
    lastName: string;

    static of = Entity.of<MemberName>();
}
