<mat-icon [svgIcon]="'car'" class="module-icon"></mat-icon>
<div>
    <p class="primary-data margin-bottom-5">
        <span>{{ search.destinationName }}</span>
    </p>

    <p class="secondary-data margin-0">
        {{ search.pickupDateTime | date: 'EE, dd MMM' }} - {{ search.dropoffDateTime | date: 'EE, dd MMM' }}
    </p>
</div>
