export class SearchSchedulesRequest {
    date: string;
    prefferedScheduleId: string;
    adults: number;
    seniors: number;
    youth: number;
    children: number;
    infants: number;
    childrenAges: number[];

    constructor() {
        this.adults = 1;
        this.children = 0;
        this.prefferedScheduleId = '0';
    }
}
