import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MenuLink, SiteBranding } from '@core/models';
import { AuthService } from '@core/services';
import { MenuItem, SharedStorageKeys } from '@shared/models';
import { UrlService } from '@shared/services';

import {
    ActivitiesDialogComponent,
    CarsDialogComponent,
    CruisesDialogComponent,
    FlightsDialogComponent,
    HotelsDialogComponent,
    WeeksDialogComponent,
} from './components';

@Component({
    selector: 'app-navigation-items',
    templateUrl: './navigation-items.component.html',
    styleUrls: ['./navigation-items.component.scss'],
})
export class NavigationItemsComponent implements OnInit {
    @Input()
    target: string;

    @Input()
    navTarget: string;

    @Input()
    headerVariant: string;

    sharedStorageKeys = SharedStorageKeys;
    menuItems: MenuItem[] = [];
    showTabletMenu: boolean;
    isModalModules: boolean;

    constructor(public url: UrlService, private authService: AuthService, private dialog: MatDialog) {}

    ngOnInit(): void {
        const siteBranding: SiteBranding = JSON.parse(
            localStorage.getItem(this.sharedStorageKeys.SITE_BRANDING)
        ) as SiteBranding;

        this.isModalModules = siteBranding?.uiConfig.siteOptions.moduleModalsForGuests && this.authService.isGuest();

        if (!siteBranding?.uiConfig?.siteOptions?.designOptions?.header?.topMenu?.length) {
            siteBranding?.uiConfig?.modules?.forEach((module: string) => {
                this.menuItems.push({
                    code: `nav.${module}.label`,
                    active: false,
                    customUrl: this.url.getUrl(module),
                    module,
                    isModal: this.isModalModules,
                });
            });
        } else {
            siteBranding?.uiConfig?.siteOptions?.designOptions?.header?.topMenu.forEach((item: MenuLink) => {
                this.menuItems.push({
                    code: item.name,
                    active: false,
                    customUrl: item.customUrl.split('#')?.[0],
                    fragment: item.customUrl.split('#')?.[1],
                    target: item.target,
                    module: item.module,
                });
            });
        }
    }

    openModuleModal(module: string): void {
        let dialog = null;

        switch (module) {
            case 'hotels':
                dialog = HotelsDialogComponent;
                break;
            case 'weeks':
                dialog = WeeksDialogComponent;
                break;
            case 'flights':
                dialog = FlightsDialogComponent;
                break;
            case 'cars':
                dialog = CarsDialogComponent;
                break;
            case 'cruises':
                dialog = CruisesDialogComponent;
                break;
            case 'activities':
                dialog = ActivitiesDialogComponent;
                break;
        }

        if (dialog) {
            this.dialog.open(dialog, {
                width: '100%',
                maxWidth: '640px',
            });
        }
    }
}
