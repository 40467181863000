<div appTranslate>
    <h2 mat-dialog-title>{{ content.title.value[currentLanguage] }}</h2>

    <div mat-dialog-content class="padding-top-20 padding-bottom-20">
        <img class="width-100 margin-bottom-15 module-image" [attr.src]="content.image" />

        <ng-container *ngFor="let content of content.content">
            <p class="subsection-title" *ngIf="content.type === 'title'">{{ content.value[currentLanguage] }}</p>
            <p *ngIf="content.type === 'paragraph'">{{ content.value[currentLanguage] }}</p>
        </ng-container>

        <div class="partners-grid" *ngIf="content.partners?.length">
            <img *ngFor="let partner of content.partners" [attr.src]="partner" />
        </div>
    </div>

    <div mat-dialog-actions class="flex-container justify-content-center">
        <button mat-stroked-button [color]="'primary'" [mat-dialog-close]="false">
            <span [attr.data-i18n-code]="'core.labels.close'"></span>
        </button>
    </div>
</div>
