import { Entity, Of } from '@utils';

import { Escape } from './escape';

@Entity
export class EscapeDestination {
    @Of(() => Number)
    id: number;

    @Of(() => String)
    name = '';

    @Of(() => [Escape])
    escapes: Escape[] = [];

    static of = Entity.of<EscapeDestination>();
}
