<div appTranslate class="position-relative">
    <a id="best-deals"></a>

    <h2
        class="section-title margin-bottom-20 margin-xs-bottom-0"
        *ngIf="tiles?.length > 0"
        [attr.data-i18n-code]="title"
    ></h2>

    <div class="deals-wrapper carousel-wrapper position-relative margin-bottom-50 margin-xs-bottom-30">
        <ngu-carousel
            #dealsCarousel
            [inputs]="dealsCarouselTileConfig"
            [dataSource]="tiles"
            class="carousel-items-wrapper"
        >
            <ngu-tile *nguCarouselDef="let item; let i = index; let ani = animate" class="height-100">
                <div class="flex-container flex-column deal-wrapper height-100">
                    <div class="deal-image-wrapper flex-container align-items-center">
                        <img [attr.src]="item.imageUrl" class="deal-image" />
                    </div>

                    <div
                        class="deal-description-wrapper padding-20 flex-container flex-column justify-content-space-between flex-grow-1"
                    >
                        <div>
                            <h3 class="subsection-title bold margin-bottom-10">{{ item.name }}</h3>
                            <p class="deal-destination text-uppercase">{{ item.subtitle }}</p>
                            <p class="deal-description margin-bottom-30" [innerHtml]="item.shortDescription"></p>
                        </div>
                        <div class="flex-container justify-content-space-between align-items-flex-end">
                            <div *ngIf="item.price">
                                <span class="text-uppercase deal-price">{{
                                    item.price.value | formatCurrency: item.price.code
                                }}</span>

                                <div appTranslate class="tax-label" *ngIf="item.showTaxInSearch">
                                    <span *ngIf="item.tax?.value > 0"
                                        >{{ item.tax.value | formatCurrency: item.tax.code }}
                                    </span>
                                    <span
                                        [class.hide]="!item.taxIncluded"
                                        [class.text-lowercase]="item.tax?.value > 0"
                                        [attr.data-i18n-code]="'core.labels.taxesIncluded'"
                                    ></span>
                                    <span
                                        [class.hide]="item.taxIncluded"
                                        [class.text-lowercase]="item.tax?.value > 0"
                                        [attr.data-i18n-code]="'core.labels.taxesNotIncluded'"
                                    ></span>
                                </div>
                            </div>
                            <span *ngIf="!item.price"></span>

                            <a
                                [routerLink]="url.getUrl('offer-details', { id: item.id })"
                                *ngIf="!item.searchUrl?.startsWith('http') && item.typeCode !== 'SS'"
                            >
                                <button mat-raised-button class="text-uppercase" [color]="'primary'">
                                    <span [attr.data-i18n-code]="'core.labels.moreDetails'"></span>
                                </button>
                            </a>

                            <a
                                [routerLink]="item.searchUrl"
                                *ngIf="!item.searchUrl?.startsWith('http') && item.typeCode === 'SS' && !hasDetailsPage"
                            >
                                <button mat-raised-button class="text-uppercase" [color]="'primary'">
                                    <span [attr.data-i18n-code]="'core.labels.moreDetails'"></span>
                                </button>
                            </a>

                            <a [attr.href]="item.searchUrl" *ngIf="item.searchUrl?.startsWith('http')">
                                <button mat-raised-button class="text-uppercase" [color]="'primary'">
                                    <span [attr.data-i18n-code]="'core.labels.moreDetails'"></span>
                                </button>
                            </a>

                            <button
                                mat-raised-button
                                class="text-uppercase"
                                [color]="'primary'"
                                (click)="resolveSavedSearch.emit(item)"
                                *ngIf="item.typeCode === 'SS' && hasDetailsPage"
                            >
                                <span [attr.data-i18n-code]="'core.labels.moreDetails'"></span>
                            </button>
                        </div>
                    </div>
                </div>
            </ngu-tile>

            <div NguCarouselPrev #dealsCarouselPrev></div>
            <div NguCarouselNext #dealsCarouselNext></div>
        </ngu-carousel>

        <mat-icon
            svgIcon="chevron-left"
            class="carousel-prev-icon cursor-pointer"
            [class.hide-lg-up]="tiles.length <= dealsCarouselTileConfig.grid.lg"
            [class.hide-md-up]="tiles.length <= dealsCarouselTileConfig.grid.md"
            [class.hide-sm-up]="tiles.length <= dealsCarouselTileConfig.grid.sm"
            (click)="triggerDealsCarouselNav('prev')"
        ></mat-icon>
        <mat-icon
            svgIcon="chevron-right"
            class="carousel-next-icon cursor-pointer"
            [class.hide-lg-up]="tiles.length <= dealsCarouselTileConfig.grid.lg"
            [class.hide-md-up]="tiles.length <= dealsCarouselTileConfig.grid.md"
            [class.hide-sm-up]="tiles.length <= dealsCarouselTileConfig.grid.sm"
            (click)="triggerDealsCarouselNav('next')"
        ></mat-icon>
    </div>
</div>
