import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    BookRequest,
    BookResponse,
    Cabin,
    CabinPrice,
    CruiseDetails,
    DestinationItem,
    GradeType,
    RequestInformationInput,
    SavedSearch,
    SearchRequest,
    SearchResponse,
    TableOptions,
} from '@cruises/models';
import { environment } from '@env/environment';
import {
    BestDeal,
    BookPrice,
    CardProfile,
    ComputePriceInput,
    DataTemplate,
    FeaturedDestination,
    GenericSearchResponse,
    TravelerProfile,
} from '@shared/models';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { siteOffersGroupTypes } from 'src/app/app.globals';

@Injectable({
    providedIn: 'root',
})
export class CruisesService {
    constructor(private http: HttpClient) {}

    getFeaturedDestinations(): Observable<DataTemplate<GenericSearchResponse<FeaturedDestination>>> {
        const url = `${environment.corePath}/cruises/offers/${siteOffersGroupTypes.FEATURED_DESTINATIONS}`;

        return this.http.get(url) as Observable<
            // eslint-disable-next-line @typescript-eslint/indent
            DataTemplate<GenericSearchResponse<FeaturedDestination>>
        >;
    }

    getBestDeals(): Observable<DataTemplate<GenericSearchResponse<BestDeal>>> {
        const url = `${environment.corePath}/cruises/offers/${siteOffersGroupTypes.BEST_DEALS}`;

        return this.http.get(url) as Observable<
            // eslint-disable-next-line @typescript-eslint/indent
            DataTemplate<GenericSearchResponse<BestDeal>>
        >;
    }

    search(request: SearchRequest): Observable<DataTemplate<SearchResponse>> {
        const url = `${environment.cruisesPath}/search`;

        return this.http.post(url, request) as Observable<
            // eslint-disable-next-line @typescript-eslint/indent
            DataTemplate<SearchResponse>
        >;
    }

    getSavedSearch(savedSearchCode: string, persist = false): Observable<DataTemplate<SavedSearch>> {
        const url = `${environment.cruisesPath}/search/${savedSearchCode}`;

        if (persist) {
            const savedSearch = JSON.parse(
                sessionStorage.getItem(`cruises_saved_search_${savedSearchCode}`)
            ) as DataTemplate<SavedSearch>;

            if (savedSearch) {
                return of(savedSearch as DataTemplate<SavedSearch>);
            }

            return this.http.post(url, null).pipe(
                tap((data) => {
                    sessionStorage.setItem(`cruises_saved_search_${savedSearchCode}`, JSON.stringify(data));
                }),
                map((data) => data as DataTemplate<SavedSearch>)
            ) as Observable<DataTemplate<SavedSearch>>;
        } else {
            return this.http.post(url, null) as Observable<
                // eslint-disable-next-line @typescript-eslint/indent
                DataTemplate<SavedSearch>
            >;
        }
    }

    getRecentSearches(limit = 3): Observable<DataTemplate<GenericSearchResponse<SearchRequest>>> {
        const url = `${environment.cruisesPath}/recentSearches?limit=${limit}`;

        return this.http.get(url) as Observable<DataTemplate<GenericSearchResponse<SearchRequest>>>;
    }

    getDestinations(): Observable<DataTemplate<DestinationItem[]>> {
        const url = `${environment.cruisesPath}/destinations`;

        const destinations = JSON.parse(sessionStorage.getItem(`cruises_destinations`)) as DataTemplate<
            // eslint-disable-next-line @typescript-eslint/indent
            DestinationItem[]
        >;

        if (destinations) {
            return of(destinations as DataTemplate<DestinationItem[]>);
        } else {
            return this.http.get(url).pipe(
                tap((data) => {
                    sessionStorage.setItem(`cruises_destinations`, JSON.stringify(data));
                }),
                map((data) => data as DataTemplate<DestinationItem[]>)
            ) as Observable<DataTemplate<DestinationItem[]>>;
        }
    }

    getDetails(hotelId: string): Observable<DataTemplate<CruiseDetails>> {
        const url = `${environment.cruisesPath}/${hotelId}`;

        return this.http.get(url) as Observable<
            // eslint-disable-next-line @typescript-eslint/indent
            DataTemplate<CruiseDetails>
        >;
    }

    getGradeTypes(cruiseId: string): Observable<DataTemplate<GenericSearchResponse<GradeType>>> {
        const url = `${environment.cruisesPath}/${cruiseId}/gradeTypes`;

        return this.http.get(url) as Observable<
            // eslint-disable-next-line @typescript-eslint/indent
            DataTemplate<GenericSearchResponse<GradeType>>
        >;
    }

    getCabins(cruiseId: string, cabinGradeId: string): Observable<DataTemplate<GenericSearchResponse<Cabin>>> {
        const url = `${environment.cruisesPath}/${cruiseId}/cabinGrade/${cabinGradeId}/cabins`;

        return this.http.get(url) as Observable<
            // eslint-disable-next-line @typescript-eslint/indent
            DataTemplate<GenericSearchResponse<Cabin>>
        >;
    }

    checkLivePricing(
        cruiseId: string,
        cabinGradeId: string,
        cabinId: string,
        code?: string
    ): Observable<DataTemplate<CabinPrice>> {
        const url = `${environment.cruisesPath}/${cruiseId}/cabinGrade/${cabinGradeId}/cabins/${cabinId}/price`;

        let params = new HttpParams();

        if (code && code !== '--nocode--') {
            params = params.append('code', code);
        }

        return this.http.get(url, { params }) as Observable<
            // eslint-disable-next-line @typescript-eslint/indent
            DataTemplate<CabinPrice>
        >;
    }

    getBookingDetails(cruiseId: string, cabinGradeId: string, cabinId: string): Observable<DataTemplate<TableOptions>> {
        const url = `${environment.cruisesPath}/${cruiseId}/cabinGrade/${cabinGradeId}/cabins/${cabinId}/bookingDetails`;

        return this.http.get(url) as Observable<DataTemplate<TableOptions>>;
    }

    getTravelerProfiles(): Observable<DataTemplate<TravelerProfile[]>> {
        const url = `${environment.cruisesPath}/travprofiles`;

        return this.http.get(url) as Observable<
            // eslint-disable-next-line @typescript-eslint/indent
            DataTemplate<TravelerProfile[]>
        >;
    }

    getCardProfiles(): Observable<DataTemplate<CardProfile[]>> {
        const url = `${environment.cruisesPath}/ccardprofiles`;

        return this.http.get(url) as Observable<
            // eslint-disable-next-line @typescript-eslint/indent
            DataTemplate<CardProfile[]>
        >;
    }

    computePrice(
        cruiseId: string,
        cabinGradeId: string,
        cabinId: string,
        options?: ComputePriceInput
    ): Observable<DataTemplate<BookPrice>> {
        const url = `${environment.cruisesPath}/${cruiseId}/cabinGrade/${cabinGradeId}/cabins/${cabinId}/price`;

        let params = new HttpParams();

        if (options?.payPoints || options?.payPoints === 0) {
            params = params.append('payPoints', options.payPoints.toString());
        }

        if (options?.useCredit) {
            params = params.append('useCredit', 'true');
        }

        if (options?.code && options.code !== '--nocode--') {
            params = params.append('code', options.code);
        }

        return this.http.get(url, { params }) as Observable<
            // eslint-disable-next-line @typescript-eslint/indent
            DataTemplate<BookPrice>
        >;
    }

    book(request: BookRequest): Observable<DataTemplate<BookResponse>> {
        const url = `${environment.cruisesPath}/book`;

        return this.http.post(url, request) as Observable<
            // eslint-disable-next-line @typescript-eslint/indent
            DataTemplate<BookResponse>
        >;
    }

    fakeBook(request: BookRequest): Observable<DataTemplate<BookResponse>> {
        const url = `${environment.cruisesPath}/fakeBook`;

        return this.http.post(url, request) as Observable<
            // eslint-disable-next-line @typescript-eslint/indent
            DataTemplate<BookResponse>
        >;
    }

    requestInformation(request: RequestInformationInput): Observable<any> {
        const url = `${environment.cruisesPath}/sendCruiseRequest`;

        return this.http.post(url, request) as Observable<any>;
    }
}
