import { AfterViewInit, Component, Input } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'app-textarea',
    templateUrl: './textarea.component.html',
    styleUrls: ['./textarea.component.scss'],
})
export class TextareaComponent implements AfterViewInit {
    required: boolean;

    @Input()
    public hint: string;

    @Input()
    public group: UntypedFormGroup;

    @Input()
    public label: string;

    @Input()
    public errorMessages: string;

    @Input()
    public placeholder: string;

    @Input()
    public name: string;

    @Input()
    public readonly: boolean;

    @Input()
    public maxChar: number;

    @Input()
    public charCount: boolean;

    @Input()
    public autoSize = true;

    @Input()
    public rows: number;

    ngAfterViewInit(): void {
        if (
            typeof this.group.get(this.name).validator === 'function' &&
            this.group.get(this.name).validator({} as AbstractControl)?.required
        ) {
            this.required = true;
        }
    }
}
