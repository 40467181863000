import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ModuleDialogContent } from '@shared/models';

@Component({
    selector: 'app-weeks-dialog',
    templateUrl: './weeks-dialog.component.html',
})
export class WeeksDialogComponent {
    content: ModuleDialogContent = {
        image: 'https://images.leisureloyalty.com/myholidaypass/images/premium_weeks2.jpg',
        title: {
            value: {
                en: 'PREMIUM WEEKS',
                es: 'SEMANAS PREMIUM',
            },
        },
        content: [
            {
                type: 'paragraph',
                value: {
                    en: 'Raintree Rewards membership allows you to get exclusive access to incredible travel destinations on selected premium resort week stays on different types of units, from a beautiful studio, spacious one bedroom and amazing two-bedroom units, that you can choose from more than 1,100 different resorts and destinations worldwide.',
                    es: 'La membresía de Raintree te permite tener acceso exclusivo a increíbles destinos en selectos resorts Premium por estadías semanales. Ofrece diferentes tipos de piezas como hermosos estudios, espaciosas recámaras e increíbles unidades de dos recámaras. Puedes elegir de entre más de1,100 resorts diferentes a lo largo del mundo.',
                },
            },
        ],
    };

    constructor(public dialogRef: MatDialogRef<WeeksDialogComponent>) {}
}
