<div appTranslate id="forgot-username-form">
    <h1 class="primary-title margin-bottom-10" [attr.data-i18n-code]="'forgotUsername.headline.label'"></h1>
    <p class="margin-bottom-30" [attr.data-i18n-code]="'forgotUsername.subtitle.label'"></p>

    <form class="forgot-username-form width-100 margin-bottom-20" [formGroup]="forgotUsernameFormGroup">
        <app-input
            label="core.labels.forgotPasswordEmail"
            [inputType]="'text'"
            [group]="forgotUsernameFormGroup"
            [errorMessages]="formErrors?.email"
            name="email"
        ></app-input>
        <app-input
            label="core.labels.forgotUsernameLastName"
            [inputType]="'text'"
            [group]="forgotUsernameFormGroup"
            [errorMessages]="formErrors?.lastName"
            name="lastName"
        ></app-input>
        <app-input
            label="core.labels.forgotUsernameMemberId"
            [inputType]="'text'"
            [group]="forgotUsernameFormGroup"
            [errorMessages]="formErrors?.memberId"
            name="memberId"
        ></app-input>
    </form>

    <p class="success-message margin-bottom-20" *ngIf="successMessage" [innerHTML]="successMessage"></p>
    <p class="error-message margin-bottom-20" *ngIf="errorMessage">{{ errorMessage }}</p>

    <button
        mat-raised-button
        type="submit"
        class="action-button submit-button text-uppercase"
        [color]="'primary'"
        (click)="submitForgotUsername()"
    >
        <span [attr.data-i18n-code]="'core.labels.submit'"></span>
    </button>

    <button mat-stroked-button class="action-button text-uppercase" [color]="'primary'" (click)="target.emit(1)">
        <span [attr.data-i18n-code]="'core.labels.goBack'"></span>
    </button>
</div>
