import { SavedSearchSortFilters } from '@shared/models';
export class SearchRequest {
    adults: number;
    children: number;
    childrenAges: string[];
    departDate: string;
    returnDate: string;
    departFrom: string;
    startIn: number;
    stayFor: number;
    arriveTo: string;
    classId: string;
    departOALocation: string;
    arriveOALocation: string;

    departFromName?: string;
    arriveToName?: string;

    sortFilters?: SavedSearchSortFilters;

    constructor() {
        this.adults = 1;
    }
}
