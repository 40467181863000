import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ModuleDialogContent } from '@shared/models';

@Component({
    selector: 'app-concierge-dialog',
    templateUrl: './concierge-dialog.component.html',
})
export class ConciergeDialogComponent {
    content: ModuleDialogContent = {
        title: {
            value: {
                en: 'CONCIERGE SERVICE',
                es: 'SERVICIO DE CONCIERGE',
            },
        },
        image: 'https://images.leisureloyalty.com/raintreerewards/img/club-benefit/concierge-laptop.jpg',
        content: [
            {
                type: 'paragraph',
                value: {
                    en: "Unlike other clubs, Raintree Rewards puts service above everything else - it's our priority.",
                    es: 'A diferencia de otros Clubes, Raintree Rewards otorga un servicio por arriba de todo – es nuestra prioridad.',
                },
            },
            {
                type: 'paragraph',
                value: {
                    en: "Being a member of Raintree Rewards means you're treated as an individual, not a number. From the first moment of contact, our friendly Member Service Specialists are ready to help plan the ideal vacation for you and your family.",
                    es: 'Ser socio de Raintree Rewards significa que será tratado en un sentido de personal y no como en un numero o estadística. Desde el primer momento de contacto, nuestros amistosos especialistas en servicio a socios, están listos para ayudarle a planear las vacaciones ideales para Usted y su Familia.',
                },
            },
            {
                type: 'title',
                value: {
                    en: 'At your service',
                    es: 'A su servicio',
                },
            },
            {
                type: 'paragraph',
                value: {
                    en: 'Our Personal Concierge service gives you the ability to book unique vacations that are not available on the website, including custom and multi-destination travel, sporting events, exotic travel, safari tours, mountain excursions and more.',
                    es: 'Nuestro servicio de Concierge personalizado le otorgar la habilidad de reservar vacaciones únicas que no están en nuestra pagina web, incluyendo viajes a la medida y en varios destinos, eventos deportivos, viajes exóticos, tours de safari, excursiones de montaña y mucho mas.',
                },
            },
            {
                type: 'paragraph',
                value: {
                    en: 'To speak with a Personal Concierge at a time that suits you best, simply submit the online request and we will contact you on the date and time you specify.',
                    es: 'Para hablar con un Concierge personalizado en el momento que mejor le quede, simplemente llene y envíe el formato en línea y de inmediato lo contactaran en la fecha y hora que usted especifique.',
                },
            },
        ],
    };

    constructor(public dialogRef: MatDialogRef<ConciergeDialogComponent>) {}
}
