import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IsoCurrency, SiteBranding } from '@core/models';
import { environment } from '@env/environment';
import {
    AssetValue,
    CardProfile,
    DataTemplate,
    ElavonAuthRequest,
    ElavonAuthResponse,
    ElavonEfsDetails,
    SharedStorageKeys,
} from '@shared/models';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { DynamicScriptLoaderService } from '../dynamic-script/dynamic-script-loader.service';
import { CustomErrorHandler } from '../error-handler/error-handler.service';
import { I18nService } from '../i18n/i18n.service';

@Injectable({
    providedIn: 'root',
})
export class Secure3DService {
    sharedStorageKeys = SharedStorageKeys;

    siteBranding: SiteBranding = JSON.parse(localStorage.getItem(this.sharedStorageKeys.SITE_BRANDING)) as SiteBranding;

    private unsubscribe: Subject<void> = new Subject();

    constructor(
        private http: HttpClient,
        private i18nService: I18nService,
        private dynamicScriptLoaderService: DynamicScriptLoaderService,
        private errorHandler: CustomErrorHandler
    ) {}

    getElavonEfs(): Observable<DataTemplate<ElavonEfsDetails>> {
        const url = `${environment.corePath}/elavon`;

        return this.http.get(url).pipe(map((data) => data as DataTemplate<ElavonEfsDetails>)) as Observable<
            DataTemplate<ElavonEfsDetails>
        >;
    }

    handle3ds(
        cardProfileDetails: CardProfile,
        priceCharge: AssetValue,
        handleError: (error: string, message?: string) => void,
        display3DSError: (message: string) => void,
        handleBooking: (auth?: ElavonAuthResponse) => void
    ): void {
        if (this.siteBranding?.uiConfig.billingOptions.secure3D) {
            this.dynamicScriptLoaderService.loadScript('elavon3DS').then(() => {
                this.getElavonEfs()
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe(
                        (response: DataTemplate<ElavonEfsDetails>) => {
                            const elavonWebSdk = new (
                                window as Window & typeof globalThis & { Elavon3DSWebSDK: any }
                            ).Elavon3DSWebSDK({
                                baseUrl: `${environment.elavon3dsUrl}/3ds2`,
                                token: response.data.efsToken,
                            });

                            const currency: IsoCurrency = this.siteBranding?.uiConfig.localeOptions.isoCurrencies.find(
                                (c) => c.code === sessionStorage.getItem(this.sharedStorageKeys.CURRENT_CURRENCY)
                            );

                            const request = ElavonAuthRequest.of(currency, cardProfileDetails, {
                                purchaseAmount: String(Math.floor(Math.floor(priceCharge.value * 100))).replace(
                                    '.',
                                    ''
                                ),
                            });

                            const logRequest = request;
                            logRequest.acctNumber = `**** **** **** ${logRequest.acctNumber.slice(-4)}`;

                            this.errorHandler
                                .sendErrorToBackend(JSON.stringify({ ELAVON_3DS: logRequest }))
                                .subscribe();

                            elavonWebSdk
                                .web3dsFlow(request)
                                .then((response: ElavonAuthResponse) => {
                                    if (!response.authenticated && response.transStatus !== 'A') {
                                        if (response.cardholderInfo) {
                                            handleError(
                                                `${this.i18nService.getKeyValue('core.errors.3dsCardAuthFailed')}: ${
                                                    response.cardholderInfo
                                                }`
                                            );
                                            this.errorHandler.sendErrorToBackend(JSON.stringify(response)).subscribe();
                                        } else {
                                            display3DSError(response.message);
                                            this.errorHandler.sendErrorToBackend(JSON.stringify(response)).subscribe();
                                        }
                                    } else if (response.transStatus === 'N') {
                                        handleError(this.i18nService.getKeyValue('core.errors.3dsAuthError'));
                                        this.errorHandler.sendErrorToBackend(JSON.stringify(response)).subscribe();
                                    } else {
                                        handleBooking(response);
                                    }
                                })
                                .catch((error: string) => {
                                    console.error(error);
                                    display3DSError(error);

                                    this.errorHandler.sendErrorToBackend(JSON.stringify(error)).subscribe();
                                });
                        },
                        (error: string) => {
                            handleError(error);
                        }
                    );
            });
        }
    }
}
