export class TripOverview {
    id: number;
    name: string;
    startDate: string;
    leadTravelerFirstName: string;
    leadTravelerLastName: string;
    items: TripItemOverview[];

    constructor(id: number, name: string) {
        this.id = id;
        this.name = name;
    }
}

export class TripItemOverview {
    id: number;
    typeCode: string;
    typeName: string;
    startDate: string;
    description: string;
}
