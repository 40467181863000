import { EventEmitter, Injectable } from '@angular/core';
import { CardProfile } from '@shared/models';

@Injectable({
    providedIn: 'root',
})
export class SwitchCardService {
    card: EventEmitter<CardProfile> = new EventEmitter<CardProfile>();
    cardAdded: EventEmitter<CardProfile> = new EventEmitter<CardProfile>();
    cardUpdated: EventEmitter<CardProfile> = new EventEmitter<CardProfile>();
}
