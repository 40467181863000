import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { BookRequest, BookResponse, EscapeDetails, SavedSearch, SearchRequest, SearchResponse } from '@escapes/models';
import {
    BestDeal,
    BookPrice,
    CardProfile,
    ComputePriceInput,
    DataTemplate,
    FeaturedDestination,
    GenericListItem,
    GenericSearchResponse,
    TravelerProfile,
} from '@shared/models';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { siteOffersGroupTypes } from 'src/app/app.globals';

@Injectable({
    providedIn: 'root',
})
export class EscapesService {
    constructor(private http: HttpClient) {}

    getFeaturedDestinations(): Observable<DataTemplate<GenericSearchResponse<FeaturedDestination>>> {
        const url = `${environment.corePath}/escapes/offers/${siteOffersGroupTypes.FEATURED_DESTINATIONS}`;

        return this.http.get(url) as Observable<DataTemplate<GenericSearchResponse<FeaturedDestination>>>;
    }

    getBestDeals(): Observable<DataTemplate<GenericSearchResponse<BestDeal>>> {
        const url = `${environment.corePath}/escapes/offers/${siteOffersGroupTypes.BEST_DEALS}`;

        return this.http.get(url) as Observable<DataTemplate<GenericSearchResponse<BestDeal>>>;
    }

    getDestinations(): Observable<DataTemplate<GenericListItem[]>> {
        const url = `${environment.escapesPath}/destinations`;

        return this.http.get(url) as Observable<DataTemplate<GenericListItem[]>>;
    }

    search(request: SearchRequest): Observable<DataTemplate<SearchResponse>> {
        const url = `${environment.escapesPath}/search`;

        return this.http.post(url, request) as Observable<DataTemplate<SearchResponse>>;
    }

    getSavedSearch(savedSearchCode: string, persist = false): Observable<DataTemplate<SavedSearch>> {
        const url = `${environment.escapesPath}/search/${savedSearchCode}`;

        if (persist) {
            return this.http.post(url, null).pipe(
                tap((data) => {
                    sessionStorage.setItem(`activities_saved_search_${savedSearchCode}`, JSON.stringify(data));
                }),
                map((data) => data as DataTemplate<SavedSearch>)
            ) as Observable<DataTemplate<SavedSearch>>;
        } else {
            const savedSearch = JSON.parse(
                sessionStorage.getItem(`activities_saved_search_${savedSearchCode}`)
            ) as DataTemplate<SavedSearch>;

            if (savedSearch) {
                sessionStorage.removeItem(`activities_saved_search_${savedSearchCode}`);
                return of(savedSearch as DataTemplate<SavedSearch>);
            } else {
                return this.http.post(url, null) as Observable<DataTemplate<SavedSearch>>;
            }
        }
    }

    getDetails(id: string): Observable<DataTemplate<EscapeDetails>> {
        const url = `${environment.escapesPath}/${id}`;

        return this.http.get(url) as Observable<DataTemplate<EscapeDetails>>;
    }

    getTravelerProfiles(): Observable<DataTemplate<TravelerProfile[]>> {
        const url = `${environment.escapesPath}/travprofiles`;

        return this.http.get(url) as Observable<DataTemplate<TravelerProfile[]>>;
    }

    getCardProfiles(): Observable<DataTemplate<CardProfile[]>> {
        const url = `${environment.escapesPath}/ccardprofiles`;

        return this.http.get(url) as Observable<DataTemplate<CardProfile[]>>;
    }

    computePrice(request: string, options?: ComputePriceInput): Observable<DataTemplate<BookPrice>> {
        const url = `${environment.escapesPath}/${request}/price`;

        let params = new HttpParams();

        if (options?.payPoints || options?.payPoints === 0) {
            params = params.append('payPoints', options.payPoints.toString());
        }

        if (options?.useCredit) {
            params = params.append('useCredit', 'true');
        }

        return this.http.get(url, { params }) as Observable<DataTemplate<BookPrice>>;
    }

    book(request: BookRequest): Observable<DataTemplate<BookResponse>> {
        const url = `${environment.escapesPath}/book`;

        return this.http.post(url, request) as Observable<DataTemplate<BookResponse>>;
    }
}
