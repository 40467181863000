import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-confirm-currency-change-dialog',
    templateUrl: './confirm-currency-change-dialog.component.html',
    styleUrls: ['./confirm-currency-change-dialog.component.scss'],
})
export class ConfirmCurrencyChangeDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<ConfirmCurrencyChangeDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { offerDisclaimer: boolean }
    ) {}
}
