import { Component, Input, OnInit } from '@angular/core';
import { SearchRequest } from '@cruises/models';

@Component({
    selector: 'app-cruises-recent-search',
    templateUrl: './cruises-recent-search.component.html',
    styleUrls: ['./cruises-recent-search.component.scss'],
})
export class CruisesRecentSearchComponent implements OnInit {
    @Input()
    search: SearchRequest;

    date: string;
    adults: number;
    children: number;

    ngOnInit(): void {
        this.date = `${this.search.date}-01`;
        this.adults = this.search.adults;
        this.children = this.search.children;
    }
}
