import { HotelsSearchType } from '@hotels/models/request/search-request';
import { Location } from '@shared/models';

export class HotelDestination {
    id: string;
    label: string;
    location: Location;
    type: HotelsSearchType = HotelsSearchType.HOTEL;
    oaLocation: string;
}
