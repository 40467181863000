import { Component, Input, OnInit } from '@angular/core';
import { LocationSearch, SearchRequest } from '@hotels/models';

@Component({
    selector: 'app-hotels-recent-search',
    templateUrl: './hotels-recent-search.component.html',
    styleUrls: ['./hotels-recent-search.component.scss'],
})
export class HotelsRecentSearchComponent implements OnInit {
    @Input()
    search: SearchRequest;

    searchCriteria: LocationSearch;
    rooms: any[];
    adults: number;
    children: number;

    ngOnInit(): void {
        this.searchCriteria = this.search.searchCriteria as LocationSearch;
        this.rooms = this.search.roomGuests;

        this.adults = this.search.roomGuests.map((r) => r.adults).reduce((p, c) => p + c);
        this.children = this.search.roomGuests.map((r) => r.childrenAges?.length || 0).reduce((p, c) => p + c);
    }
}
