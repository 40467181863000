<p class="subsection-title text-center margin-bottom-30" [attr.data-i18n-code]="widget.properties.titleKey"></p>

<div class="options flex-container justify-content-space-between">
    <ng-container *ngFor="let element of widget.elements">
        <div class="option-item flex-container flex-column align-items-center">
            <p class="title text-center" [attr.data-i18n-code]="element.titleKey"></p>
            <p class="description" [attr.data-i18n-code]="element.descriptionKey"></p>
        </div>
    </ng-container>
</div>
