import { TravelerAgeBand } from '@activities/models';
import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class AgeBandsUpdaterService {
    update: EventEmitter<TravelerAgeBand> = new EventEmitter<TravelerAgeBand>();
    remove: EventEmitter<number> = new EventEmitter<number>();
}
