import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, Inject, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Theme } from '@theme/models';
import { ThemeService } from '@theme/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
    selector: '[appTheme]',
})
export class ThemeDirective implements OnInit, OnDestroy {
    @Input()
    scoped = false;

    private unsubscribe: Subject<void> = new Subject();

    constructor(
        private elementRef: ElementRef,
        private renderer: Renderer2,
        @Inject(DOCUMENT) private document: any,
        private themeService: ThemeService
    ) {}

    ngOnInit(): void {
        this.renderer.addClass(this.getElement(), 'hide');
        this.themeService.themeInit.pipe(takeUntil(this.unsubscribe)).subscribe((theme: Theme) => {
            this.updateTheme(theme);
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    updateTheme(theme: Theme): void {
        const element = this.getElement();

        for (const key in theme.properties) {
            if (theme.properties.hasOwnProperty(key)) {
                this.document.body.style.setProperty(key, String(theme.properties[key]));

                if (key === '--color-primary') {
                    (document.querySelector('#main') as HTMLElement).style.setProperty(
                        '--color-primary',
                        theme.properties[key]
                    );
                }
            }
        }

        this.renderer.removeClass(element, 'hide');
    }

    getElement(): any {
        return this.scoped ? this.elementRef.nativeElement : document.querySelector('.app-page-wrapper');
    }
}
