<mat-icon [svgIcon]="'activity'" class="module-icon"></mat-icon>
<div>
    <p class="primary-data margin-0">
        <span>{{ search.destinationLabel }}</span>
    </p>

    <p class="secondary-data margin-top-5 margin-bottom-0" *ngIf="search.date">
        {{ search.date | date: 'EE, dd MMM' }}
    </p>
</div>
