import { HttpClient } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserInfo } from '@core/models';
import { environment } from '@env/environment';
import { SharedStorageKeys } from '@shared/models';
import { Observable, of } from 'rxjs';

@Injectable()
export class CustomErrorHandler implements ErrorHandler {
    sharedStorageKeys = SharedStorageKeys;

    constructor(private http: HttpClient, private route: ActivatedRoute) {}

    handleError(error: any): void {
        console.error(error);

        if (environment.target === 'production') {
            this.sendErrorToBackend(error).subscribe(
                () => {
                    console.log('Error sent to backend');
                },
                (error) => {
                    console.error('Error sending error to backend:', error);
                }
            );
        }
    }

    sendErrorToBackend(error: string, endpoint = ''): Observable<any> {
        if (environment.target !== 'production') {
            return of({});
        }

        const url = `${environment.corePath}/log`;

        const userInfo: UserInfo = JSON.parse(localStorage.getItem(this.sharedStorageKeys.USER_INFO) || '{}');

        const route = this.route.snapshot['_routerState'].url;

        let message = `Route: ${route} ==> MID: ${userInfo.memberId} ==> `;

        if (endpoint) {
            message += `Endpoint: ${endpoint} ==> `;
        }

        message += `Error: ${JSON.stringify(error, Object.getOwnPropertyNames(error))}`;

        return this.http.post(url, {
            error: message,
        }) as Observable<any>;
    }

    sendInfoToBackend(m: string, endpoint = ''): Observable<any> {
        if (environment.target !== 'production') {
            return of({});
        }

        const url = `${environment.corePath}/log`;

        const userInfo: UserInfo = JSON.parse(localStorage.getItem(this.sharedStorageKeys.USER_INFO) || '{}');

        const route = this.route.snapshot['_routerState'].url;

        let message = `Route: ${route} ==> MID: ${userInfo.memberId} ==> `;

        if (endpoint) {
            message += `Endpoint: ${endpoint} ==> `;
        }

        message += `Info: ${JSON.stringify(m, Object.getOwnPropertyNames(m))}`;

        console.log(message);

        return this.http.post(url, {
            error: message,
        }) as Observable<any>;
    }
}
