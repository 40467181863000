<footer id="footer" [class.hide]="hideFooter">
    <ng-container
        *ngIf="
            !siteBranding?.uiConfig?.siteOptions?.designOptions?.footer?.variant ||
            siteBranding?.uiConfig?.siteOptions?.designOptions?.footer?.variant === 'default'
        "
    >
        <app-default-footer
            [siteBranding]="siteBranding"
            [menuItems]="menuItems"
            [logoUrl]="logoUrl"
            [copyright]="copyright"
        ></app-default-footer>
    </ng-container>

    <ng-container *ngIf="siteBranding?.uiConfig?.siteOptions?.designOptions?.footer?.variant === 'spaceBetween'">
        <app-space-between-footer
            [siteBranding]="siteBranding"
            [menuItems]="menuItems"
            [logoUrl]="logoUrl"
            [copyright]="copyright"
        ></app-space-between-footer>
    </ng-container>

    <ng-container *ngIf="siteBranding?.uiConfig?.siteOptions?.designOptions?.footer?.variant === 'ltp'">
        <app-ltp-footer [siteBranding]="siteBranding" [logoUrl]="logoUrl" [copyright]="copyright"></app-ltp-footer>
    </ng-container>
</footer>
