import { Pipe, PipeTransform } from '@angular/core';
import { AssetValue } from '@shared/models';

import { FormatCurrencyPipe } from './format-currency.pipe';

@Pipe({
    name: 'formatAssetValue',
})
export class FormatAssetValuePipe implements PipeTransform {
    constructor(private currencyPipe: FormatCurrencyPipe) {}
    transform(value: AssetValue): any {
        if ((value.value !== 0 && !value.value) || !value.code) {
            if (value.fmtValue) {
                return value.fmtValue;
            }

            return value.value;
        }

        return this.currencyPipe.transform(value.value, value.code);
    }
}
