import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import ls from 'localstorage-slim';

@Injectable({
    providedIn: 'root',
})
export class StorageService {
    constructor(private route: ActivatedRoute) {}

    public getSID(): string {
        return this.route.snapshot.queryParams['sid'];
    }

    public setForcedSIDItem(sid: string, key: string, value: any): void {
        if (!sid) {
            return;
        }

        this.setItem(`${sid}_${key}`, value, 60 * 15);
    }

    public setSIDItem(key: string, value: any): void {
        const sid = this.route.snapshot.queryParams['sid'];

        if (!sid) {
            return;
        }

        this.setItem(`${sid}_${key}`, value, 60 * 15);
    }

    public setItem(key: string, value: any, ttl?: number): void {
        if (ttl) {
            ls.set(key, value, { ttl });
        } else {
            ls.set(key, value);
        }
    }

    public getForcedSIDItem<T>(sid: string, key: string, defaultValue?: T): T | null {
        if (!sid) {
            return defaultValue;
        }

        return this.getItem<T>(`${sid}_${key}`, defaultValue);
    }

    public getSIDItem<T>(key: string, defaultValue?: T): T | null {
        const sid = this.route.snapshot.queryParams['sid'];

        if (!sid) {
            return defaultValue;
        }

        return this.getItem<T>(`${sid}_${key}`, defaultValue);
    }

    public getItem<T>(key: string, defaultValue?: T): T | null {
        let value: T = null;
        const storageItem = ls.get(key);

        if (!storageItem) {
            return defaultValue;
        }

        try {
            value = storageItem as T;
        } catch (e) {
            return null;
        }

        return (value as T) || null;
    }

    public removeSIDModuleItems(keysEnum: Record<string, string>): void {
        const sid = this.route.snapshot.queryParams['sid'];

        if (!sid) {
            return;
        }

        for (const key in keysEnum) {
            this.removeItem(`${sid}_${keysEnum[key]}`);
        }
    }

    public removeSIDItem(key: string): void {
        const sid = this.route.snapshot.queryParams['sid'];

        if (!sid) {
            return;
        }

        this.removeItem(`${sid}_${key}`);
    }

    public removeItem(key: string): void {
        localStorage.removeItem(key);
    }
}
