export class MembershipType {
    id: string;
    category: string;
    free: boolean;
    name: string;
    description: string;
    popular: boolean;
    price: number;
    onbook?: boolean;
}
