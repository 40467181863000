import { SavedSearchSortFilters } from '@shared/models';

export class SearchRequest {
    constructor(
        public locId: string,
        public pickupDateTime: string,
        public dropoffDateTime: string,
        public driverAge?: number,
        public countryId?: number,
        public destinationName?: string,
        public oaLocation?: string,
        public locName?: string,
        public sortFilters?: SavedSearchSortFilters
    ) {}
}
