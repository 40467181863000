<div appTranslate id="register-page" class="register-form-wrapper">
    <h1 class="primary-title margin-bottom-10" [attr.data-i18n-code]="'core.labels.signUp'"></h1>
    <p class="margin-bottom-40" [attr.data-i18n-code]="'signup.subtitle.label'"></p>

    <div class="margin-bottom-40">
        <span
            class="registration-code-link cursor-pointer"
            [attr.data-i18n-code]="'signup.haveCode.label'"
            [class.hide]="showRegistrationCode"
            (click)="showRegistrationCode = true"
        ></span>
    </div>

    <div class="margin-bottom-40" [class.hide]="!showRegistrationCode">
        <p [attr.data-i18n-code]="'signup.enterCodeToStart.label'"></p>

        <div class="registration-code-wrapper flex-container align-items-center">
            <mat-form-field appearance="fill" class="registration-code-input-wrapper no-padding margin-right-20">
                <mat-label [attr.data-i18n-code]="'core.labels.registrationCode'"></mat-label>
                <input matInput [(ngModel)]="registrationCode" />
            </mat-form-field>

            <div class="margin-right-20">
                <button
                    mat-raised-button
                    type="submit"
                    class="text-uppercase"
                    [color]="'primary'"
                    [disabled]="!registrationCode"
                    (click)="retrieveMembershipTypes()"
                >
                    <span [attr.data-i18n-code]="'core.labels.submit'"></span>
                </button>
            </div>

            <div>
                <button
                    mat-stroked-button
                    type="submit"
                    class="text-uppercase"
                    [color]="'primary'"
                    (click)="clearRegistrationCode()"
                >
                    <span [attr.data-i18n-code]="'core.labels.cancel'"></span>
                </button>
            </div>
        </div>
    </div>

    <p
        class="error-message"
        [class.hide]="!showInvalidCodeError"
        [attr.data-i18n-code]="'signup.noMembershipsForCode.label'"
    ></p>

    <div class="flex-container justify-content-center" *ngIf="membershipTypesLoading">
        <mat-spinner></mat-spinner>
    </div>

    <h3
        class="subsection-title margin-bottom-20"
        [attr.data-i18n-code]="'signup.chooseMembership.label'"
        [class.hide]="!membershipTileItems?.length"
    ></h3>
    <div
        appTranslate
        id="register-form-memberships"
        class="memberships-wrapper carousel-wrapper position-relative"
        *ngIf="membershipTileItems?.length"
    >
        <ngu-carousel
            #membershipsCarousel
            [inputs]="membershipCarouselTileConfig"
            [dataSource]="membershipTileItems"
            class="carousel-items-wrapper"
        >
            <ngu-tile *nguCarouselDef="let item; let i = index; let ani = animate" class="height-100">
                <div
                    appTranslate
                    id="register-form-membership-card"
                    class="membership-wrapper flex-container flex-column justify-content-space-between cursor-pointer padding-20 height-100 {{
                        item.category
                    }}-membership"
                    [class.active]="item.active"
                    [class.popular]="item.popular"
                    (click)="selectMembership(i, item)"
                >
                    <div class="popular-banner">
                        <span [attr.data-i18n-code]="'core.labels.popular'"></span>
                    </div>

                    <div>
                        <p class="membership-name text-uppercase margin-bottom-25">{{ item.name }}</p>
                        <p
                            class="membership-description margin-bottom-20"
                            [innerHTML]="item.description"
                            *ngIf="item.description"
                        ></p>
                        <p
                            class="membership-description margin-bottom-20"
                            [innerHTML]="item.paymentDesc"
                            *ngIf="item.paymentDesc"
                        ></p>
                    </div>

                    <div class="text-center">
                        <button
                            mat-raised-button
                            [color]="'primary'"
                            class="color-white text-uppercase width-100"
                            [class.hide]="item.active || !item.free"
                        >
                            <span [attr.data-i18n-code]="'core.labels.select'"></span>
                        </button>

                        <button
                            mat-raised-button
                            [color]="'primary'"
                            class="color-white text-uppercase width-100"
                            [class.hide]="item.active || item.free"
                        >
                            <span [attr.data-i18n-code]="'core.labels.buy'"></span>
                        </button>

                        <span
                            class="membership-selected-message"
                            [class.hide]="!item.active || (item.active && item.free)"
                            [attr.data-i18n-code]="'core.labels.fillPaymentBelow'"
                        ></span>
                        <span
                            class="membership-selected-message"
                            [class.hide]="!item.active || (item.active && !item.free)"
                            [attr.data-i18n-code]="'core.labels.fillMemberInfoBelow'"
                        ></span>
                    </div>
                </div>
            </ngu-tile>

            <div NguCarouselPrev #membershipCarouselPrev></div>
            <div NguCarouselNext #membershipCarouselNext></div>
        </ngu-carousel>

        <mat-icon
            svgIcon="chevron-left"
            class="carousel-prev-icon cursor-pointer"
            [class.hide-lg-up]="membershipTileItems.length <= membershipCarouselTileConfig.grid.lg"
            [class.hide-md-up]="membershipTileItems.length <= membershipCarouselTileConfig.grid.md"
            [class.hide-sm-up]="membershipTileItems.length <= membershipCarouselTileConfig.grid.sm"
            (click)="triggerMembershipCarouselNav('prev')"
        ></mat-icon>
        <mat-icon
            svgIcon="chevron-right"
            class="carousel-next-icon cursor-pointer"
            [class.hide-lg-up]="membershipTileItems.length <= membershipCarouselTileConfig.grid.lg"
            [class.hide-md-up]="membershipTileItems.length <= membershipCarouselTileConfig.grid.md"
            [class.hide-sm-up]="membershipTileItems.length <= membershipCarouselTileConfig.grid.sm"
            (click)="triggerMembershipCarouselNav('next')"
        ></mat-icon>
    </div>

    <div appTranslate id="register-form" *ngIf="selectedMembership">
        <div class="separator padding-top-20 margin-bottom-25"></div>

        <h3
            class="subsection-title margin-bottom-20 booking-panel-title"
            [attr.data-i18n-code]="'core.labels.memberInformation'"
        ></h3>
        <app-register-member-information-form></app-register-member-information-form>

        <div class="separator padding-top-25 margin-bottom-25"></div>

        <h3
            class="subsection-title margin-bottom-20 booking-panel-title"
            [class.hide]="selectedMembership?.free"
            [attr.data-i18n-code]="'hotels.booking.paymentBilling.label'"
        ></h3>
        <app-payment-billing-form
            [countries]="countries"
            [showCards]="false"
            [hideBillingNames]="true"
            [hideSaveCheckbox]="true"
            *ngIf="countries?.length && !selectedMembership?.free && !selectedMembership.onbook"
        ></app-payment-billing-form>

        <div class="separator padding-top-25 margin-bottom-25" [class.hide]="selectedMembership?.free"></div>

        <div class="flex-container flex-column align-items-center justify-content-center margin-bottom-20">
            <mat-checkbox [(ngModel)]="termsAgree" [color]="'primary'" class="margin-bottom-20">
                <p class="margin-bottom-0" [attr.data-i18n-code]="'core.labels.signUpTerms'"></p>
            </mat-checkbox>

            <mat-checkbox [(ngModel)]="newsletter" [color]="'primary'" class="margin-bottom-20">
                <p class="margin-bottom-0" [attr.data-i18n-code]="'core.labels.signUpNewsletter'"></p>
            </mat-checkbox>

            <p class="error-message width-100" *ngIf="errorMessage">{{ errorMessage }}</p>

            <button
                mat-raised-button
                class="sign-up-button text-uppercase"
                [color]="'primary'"
                (click)="submitRegister()"
                [disabled]="!termsAgree"
            >
                <span [attr.data-i18n-code]="'core.labels.signUp'"></span>
            </button>
        </div>
    </div>
</div>
