import { CommonModule } from '@angular/common';
import { InjectionToken, NgModule } from '@angular/core';
import { ThemeDirective } from '@theme/directives';
import { ThemeService } from '@theme/services';

export const THEMES = new InjectionToken('THEMES');
export const ACTIVE_THEME = new InjectionToken('ACTIVE_THEME');

@NgModule({
    imports: [CommonModule],
    providers: [ThemeService],
    declarations: [ThemeDirective],
    exports: [ThemeDirective],
})
export class ThemeModule {}
