import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ActivatedRouteSnapshot } from '@angular/router';
import { SiteBranding } from '@core/models';
import { SharedStorageKeys } from '@shared/models';
import { UrlService } from '@shared/services';
import { Observable } from 'rxjs';

import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root',
})
export class ModuleGuardService implements CanActivate {
    sharedStorageKeys = SharedStorageKeys;

    constructor(private router: Router, private url: UrlService, private authService: AuthService) {}

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
        const siteBranding: SiteBranding = JSON.parse(
            localStorage.getItem(this.sharedStorageKeys.SITE_BRANDING)
        ) as SiteBranding;

        if (this.authService.isGuest() && siteBranding?.uiConfig.siteOptions.moduleModalsForGuests) {
            if (siteBranding?.uiConfig.siteOptions.isHomeEnabled) {
                this.router.navigateByUrl(this.url.getUrl('home'));
            } else {
                this.router.navigateByUrl(this.url.getUrl('login'));
            }

            return false;
        }

        if (route.data.module === 'rewards' && !siteBranding?.uiConfig?.pointsOptions?.rewardsActive) {
            this.router.navigateByUrl(this.url.getUrl(siteBranding?.uiConfig?.modules[0]));
            return false;
        }

        if (
            route.data.module !== 'rewards' &&
            !siteBranding?.uiConfig.modules.map((module: string) => module).includes(route.data.module)
        ) {
            this.router.navigateByUrl(this.url.getUrl(siteBranding?.uiConfig?.modules[0]));
            return false;
        }

        return true;
    }
}
