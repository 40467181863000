import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { LoaderService } from '@core/services';
import { environment } from '@env/environment';
import { SharedStorageKeys } from '@shared/models';
import { I18nService } from '@shared/services/i18n/i18n.service';
import { UrlService } from '@shared/services/url/url.service';
import { of, Subject } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';

import { StorageService } from '../storage';

@Injectable({
    providedIn: 'root',
})
export class I18nModuleResolverService implements Resolve<any>, OnDestroy {
    sharedStorageKeys = SharedStorageKeys;

    private unsubscribe: Subject<void> = new Subject();

    constructor(
        private i18nService: I18nService,
        private url: UrlService,
        private loader: LoaderService,
        private storage: StorageService
    ) {}

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    resolve(route: ActivatedRouteSnapshot): any {
        if (!localStorage.getItem(this.sharedStorageKeys.ACCESS_TOKEN)) {
            return true;
        }

        let keysArr: string[] = [];
        const target: string = route.url[0].path;

        switch (target) {
            case this.url.isLoginUrl(target):
                keysArr = this.i18nService.getLoginKeys();
                break;
            case this.url.isAccountUrl(target):
                keysArr = this.i18nService.getAccountKeys();
                break;
            case this.url.isHotelsUrl(target):
                keysArr = this.i18nService.getHotelsLandingKeys();
                break;
            case this.url.isFlightsUrl(target):
                keysArr = this.i18nService.getFlightsLandingKeys();
                break;
            case this.url.isCarsUrl(target):
                keysArr = this.i18nService.getCarsLandingKeys();
                break;
            case this.url.isCruisesUrl(target):
                keysArr = this.i18nService.getCruisesLandingKeys();
                break;
            case this.url.isWeeksUrl(target):
                keysArr = this.i18nService.getWeeksLandingKeys();
                break;
            case this.url.isActivitiesUrl(target):
                keysArr = this.i18nService.getActivitiesLandingKeys();
                break;
            case this.url.isCardsUrl(target):
                keysArr = this.i18nService.getCardsLandingKeys();
                break;
            case this.url.isRewardsUrl(target):
                keysArr = this.i18nService.getRewardsSearchKeys();
                break;
            case this.url.isEscapesUrl(target):
                keysArr = this.i18nService.getEscapesLandingKeys();
                break;
            default:
                return true;
        }

        if (!this.storage.getItem(this.sharedStorageKeys.GENERAL_KEYS_LOADED)) {
            keysArr = [...this.i18nService.getGeneralKeys(), ...keysArr];

            environment.purchaseStatusCodes.forEach((code: string) => {
                keysArr.push(`purchaseStatus.${code}`);
            });

            this.storage.setItem(this.sharedStorageKeys.GENERAL_KEYS_LOADED, '1');
        }

        this.loader.show();

        return this.i18nService.preloadKeys(keysArr).pipe(
            take(1),
            mergeMap(() => {
                this.loader.hide();
                return of(true);
            })
        );
    }
}
