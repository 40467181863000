import { Component, Input } from '@angular/core';
import { HotelDetails, Room, SearchRequest } from '@hotels/models';
import * as moment from 'moment';

@Component({
    selector: 'app-hotel-summary',
    templateUrl: './hotel-summary.component.html',
    styleUrls: ['./hotel-summary.component.scss'],
})
export class HotelSummaryComponent {
    @Input()
    hotel: HotelDetails;

    @Input()
    room: Room;

    @Input()
    searchRequest: SearchRequest;

    numberOfNights: number;
    adults: number;
    children: number;
    childrenAges: string;
    rooms: number;

    ngOnInit(): void {
        if (this.searchRequest) {
            this.numberOfNights = moment(this.searchRequest.checkOut)
                .startOf('day')
                .diff(moment(this.searchRequest.checkIn).startOf('day'), 'days');

            this.adults = this.searchRequest.roomGuests.map((r) => r.adults).reduce((prev, curr) => prev + curr);
            this.children = this.searchRequest.roomGuests
                .map((r) => r.childrenAges?.length || 0)
                .reduce((prev, curr) => prev + curr);
            this.childrenAges = this.searchRequest.roomGuests
                .map((r) => r.childrenAges?.join(', ') || '')
                .reduce((prev, curr) => (prev ? prev + ', ' + curr : curr), '');
            this.rooms = this.searchRequest.roomGuests?.length;
        }
    }
}
