import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { SelectOption } from '@shared/models';

@Component({
    selector: 'app-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss'],
})
export class SelectComponent implements AfterViewInit {
    required: boolean;

    @Input()
    group: UntypedFormGroup;

    @Input()
    label: string;

    @Input()
    errorMessages: string;

    @Input()
    placeholder: string;

    @Input()
    name: string;

    @Input()
    options: SelectOption[];

    @Input()
    readonly: boolean;

    @Input()
    disabled: boolean;

    @Input()
    clearable: boolean;

    @Input()
    i18n: boolean;

    @Input()
    hint: string;

    @Input()
    autocomplete: string;

    @Input()
    translate = true;

    @Input()
    translateHint = true;

    @Input()
    removeValidationSpace: boolean;

    @Output()
    onSelect: EventEmitter<any> = new EventEmitter<any>();

    ngAfterViewInit(): void {
        if (
            typeof this.group.get(this.name).validator === 'function' &&
            this.group.get(this.name).validator({} as AbstractControl)?.required
        ) {
            this.required = true;
        }
    }
}
