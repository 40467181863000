import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SiteBranding, TokenInfo, UserInfo } from '@core/models';
import { AuthService, LoaderService, UserAuthService } from '@core/services';
import { environment } from '@env/environment';
import { DataTemplate, SharedStorageKeys } from '@shared/models';
import { FormErrorService, I18nRefreshService, I18nService, StorageService, UrlService } from '@shared/services';
import { ThemeService } from '@theme/services';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-login-form',
    templateUrl: './login-form.component.html',
    styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent implements OnInit, OnDestroy {
    @Output()
    target: EventEmitter<number> = new EventEmitter<number>();

    sharedStorageKeys = SharedStorageKeys;
    siteBranding: SiteBranding;
    returnUrl: string;
    guestUrl: string;
    errorMessage: string;
    loginFormGroup: UntypedFormGroup;
    formErrors: {
        [key: string]: string;
    } = {
        username: '',
        password: '',
    };
    validationMessages = {};

    private unsubscribe: Subject<void> = new Subject();

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
        private userAuthService: UserAuthService,
        private formErrorService: FormErrorService,
        public i18nService: I18nService,
        private i18nRefreshService: I18nRefreshService,
        private themeService: ThemeService,
        private loader: LoaderService,
        public url: UrlService,
        private storage: StorageService
    ) {}

    ngOnInit(): void {
        this.siteBranding = this.storage.getItem<SiteBranding>(this.sharedStorageKeys.SITE_BRANDING);
        this.returnUrl =
            this.route.snapshot.queryParams['returnUrl'] ||
            this.url.getUrl(
                this.siteBranding?.uiConfig?.modules?.length ? this.siteBranding?.uiConfig?.modules[0] : 'login'
            );
        this.guestUrl = this.siteBranding?.uiConfig.siteOptions.isHomeEnabled
            ? this.url.getUrl('home')
            : this.url.getUrl(this.siteBranding?.uiConfig?.modules[0]);

        if (!this.authService.isGuest()) {
            this.router.navigateByUrl(this.returnUrl);
        } else {
            this.i18nRefreshService
                .getState()
                .pipe(takeUntil(this.unsubscribe))
                .subscribe(() => {
                    this.getValidationMessages(true);
                });

            this.getValidationMessages();
            this.populateContent();
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    populateContent(): void {
        this.loginFormGroup = new UntypedFormGroup({
            username: new UntypedFormControl(localStorage.getItem(this.sharedStorageKeys.REMEMBER_USERNAME) || null, [
                Validators.required,
            ]),
            password: new UntypedFormControl(null, [Validators.required]),
            rememberMe: new UntypedFormControl(localStorage.getItem(this.sharedStorageKeys.REMEMBER_ME)),
        });

        this.loginFormGroup.valueChanges
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() =>
                this.formErrorService.checkForErrors(this.loginFormGroup, this.formErrors, this.validationMessages)
            );
    }

    submitLogin(): void {
        this.formErrorService.markAsDirty(this.loginFormGroup);
        this.formErrorService.checkForErrors(this.loginFormGroup, this.formErrors, this.validationMessages);

        if (this.loginFormGroup.valid) {
            this.errorMessage = null;
            this.login(this.loginFormGroup.controls.username.value, this.loginFormGroup.controls.password.value);
        }
    }

    login(username: string, password: string): void {
        this.loader.show();
        this.userAuthService
            .login(username, password)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(
                (loginResponse: any) => {
                    if (this.loginFormGroup.controls.rememberMe.value) {
                        localStorage.setItem(
                            this.sharedStorageKeys.REMEMBER_USERNAME,
                            this.loginFormGroup.controls.username.value
                        );
                        localStorage.setItem(this.sharedStorageKeys.REMEMBER_ME, 'true');
                    } else {
                        localStorage.removeItem(this.sharedStorageKeys.REMEMBER_USERNAME);
                        localStorage.removeItem(this.sharedStorageKeys.REMEMBER_ME);
                    }

                    this.authService.login();
                    this.authService.setUsername(username);
                    this.authService.setToken(
                        new TokenInfo(
                            username,
                            `${loginResponse.token_type} ${loginResponse.access_token}`,
                            loginResponse.expires_in
                        )
                    );

                    this.themeService.unloadTheme();
                    this.storage.removeItem(this.sharedStorageKeys.SITE_BRANDING);

                    forkJoin([this.userAuthService.getUserInfo(), this.userAuthService.getSiteBranding()])
                        .pipe(takeUntil(this.unsubscribe))
                        .subscribe(
                            (response: [DataTemplate<UserInfo>, DataTemplate<SiteBranding>]) => {
                                if (response[0].data.tempPwd) {
                                    localStorage.setItem(
                                        this.sharedStorageKeys.USER_ID,
                                        environment.guestCredentials.username
                                    );
                                    sessionStorage.setItem(this.sharedStorageKeys.PREVENT_GUEST_LOGIN, 'true');

                                    this.loginFormGroup.get('password').setValue(null);
                                    this.loader.hide();

                                    this.router.navigate([this.url.getUrl('login')], {
                                        queryParams: {
                                            resetp: true,
                                            token: response[0].data.signupToken,
                                            username: this.loginFormGroup.get('username').value,
                                        },
                                    });
                                } else {
                                    this.storage.setItem(this.sharedStorageKeys.USER_INFO, response[0].data);

                                    response[1].data.uiConfig.localeOptions.languages.forEach((language: string) => {
                                        this.storage.removeItem(`${language}_${this.sharedStorageKeys.I18N_KEYS}`);
                                    });
                                    this.storage.removeItem(this.sharedStorageKeys.GENERAL_KEYS_LOADED);

                                    this.siteBranding = response[1].data;
                                    this.returnUrl =
                                        this.route.snapshot.queryParams['returnUrl'] ||
                                        this.url.getUrl(
                                            this.siteBranding?.uiConfig?.modules?.length
                                                ? this.siteBranding?.uiConfig?.modules[0]
                                                : 'login'
                                        );

                                    this.i18nService
                                        .preloadBrandingKeys(response[1].data)
                                        .pipe(takeUntil(this.unsubscribe))
                                        .subscribe(() => {
                                            this.router.navigateByUrl(this.returnUrl);
                                        });
                                }
                            },
                            (error: string) => {
                                console.error(error);
                                this.loader.hide();
                            }
                        );
                },
                (error: string) => {
                    console.error(error);
                    this.errorMessage =
                        error.toLocaleLowerCase() !== 'bad credentials' ? error : 'core.errors.invalidCredentials';
                    this.loader.hide();
                }
            );
    }

    getValidationMessages(swapValidationMessages = false): void {
        this.i18nService
            .preloadKeys(['core.errors.usernameRequired', 'core.errors.passwordRequired'])
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                const keys = this.i18nService.getSavedKeys();

                this.validationMessages = {
                    username: {
                        required: keys['core.errors.usernameRequired'],
                    },
                    password: {
                        required: keys['core.errors.passwordRequired'],
                    },
                };

                if (swapValidationMessages) {
                    this.loginFormGroup.clearValidators();
                    this.loginFormGroup.updateValueAndValidity();
                }
            });
    }
}
