import { AssetValue, Location } from '@shared/models';

export class OfferDetails {
    id: number;
    name: string;
    location: string;
    price: AssetValue;
    endDate: string;
    includes: string;
    imageUrl: string;
    images: string[];
    content: string;
    restrictions: string;
    bookingInstructions: string;
    coordinates: Location;
    typeCode: string;
    bypassDetails: boolean;
    module: string;
    tax: AssetValue;
    taxIncluded: boolean;
    showTaxInSearch: boolean;
    soldOut: boolean;
}
