<div class="details-page-navigation position-relative" #navigationElement>
    <div class="main-content-wrapper navigation-wrapper padding-top-20 padding-bottom-20">
        <ul class="navigation-items-wrapper no-styles">
            <ng-container *ngFor="let item of navigationItems">
                <li class="navigation-link" [class.active]="activeNavigation == item.code">
                    <button
                        appTranslate
                        [attr.id]="item.code"
                        class="text-uppercase navigation-link ada-button"
                        [class.active]="activeNavigation == item.code"
                        (click)="scrollTo.emit(item.code)"
                    >
                        <span
                            class="navigation-text"
                            [class.active]="activeNavigation === item.code"
                            [attr.data-i18n-code]="item.i18nKeyCode"
                        ></span>
                        <mat-icon
                            [svgIcon]="'chevron-down'"
                            class="navigation-icon margin-left-10 hide-md-up"
                        ></mat-icon>
                    </button>
                </li>
            </ng-container>

            <div class="action-button-wrapper height-100 hide-below-xl" *ngIf="actionButtonLabel">
                <button
                    mat-raised-button
                    class="width-100 height-100 text-uppercase bold no-radius"
                    [color]="'primary'"
                    (click)="actionButton.emit()"
                >
                    <span [attr.data-i18n-code]="actionButtonLabel"></span>
                </button>
            </div>

            <div class="mobile-navigation width-100 height-100 hide-md-up">
                <mat-form-field appearance="outline">
                    <mat-select>
                        <ng-container *ngFor="let item of navigationItems">
                            <mat-option
                                appTranslate
                                [attr.id]="'details-page-navigation-' + item.code + '-mobile'"
                                [value]="item.code"
                                (click)="triggerNavClick(item.code)"
                            >
                                <span [attr.data-i18n-code]="item.i18nKeyCode"></span>
                            </mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
            </div>
        </ul>
    </div>

    <div class="action-button-wrapper height-100 hide-md-up" *ngIf="actionButtonLabel">
        <button
            mat-raised-button
            class="width-100 height-100 text-uppercase bold no-radius"
            [color]="'primary'"
            (click)="actionButton.emit()"
        >
            <span [attr.data-i18n-code]="actionButtonLabel"></span>
        </button>
    </div>
</div>
