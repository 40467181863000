import {
    AccountDetails,
    AccountPurchase,
    AccountSettings,
    AccountTravelerProfile,
    BillingHistoryItem,
    CancellationResponse,
    ConfirmationDetails,
    GuaranteedPriceRequest,
    PasswordUpdateRequest,
    PointsHistoryItem,
    PrimaryMemberRequest,
    TierDetails,
    Timezone,
    Trip,
    TripItemOverview,
    TripOverview,
    TripUpdateRequest,
} from '@account/models';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MembershipBookRequest } from '@core/models';
import { AppHttpClient } from '@core/services';
import { environment } from '@env/environment';
import {
    BookPrice,
    CardProfile,
    ComputePriceInput,
    DataTemplate,
    GenericListItem,
    GenericSearchResponse,
    PaginatedSearchResponse,
} from '@shared/models';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class AccountService {
    constructor(private http: HttpClient, private http2: AppHttpClient) {}

    getDetails(): Observable<DataTemplate<AccountDetails>> {
        const url = `${environment.accountPath}/details`;

        return this.http2.get({ url, mapper: AccountDetails.of });
    }

    getBillingHistory(seek = 0, pageSize = 5): Observable<DataTemplate<PaginatedSearchResponse<BillingHistoryItem>>> {
        const url = `${environment.accountPath}/billingHistory`;

        const params = new HttpParams().set('seek', seek.toString()).set('pageSize', pageSize.toString());

        return this.http.get(url, { params }) as Observable<DataTemplate<PaginatedSearchResponse<BillingHistoryItem>>>;
    }

    getPointsHistory(seek = 0, pageSize = 5): Observable<DataTemplate<PaginatedSearchResponse<PointsHistoryItem>>> {
        const url = `${environment.accountPath}/pointsHistory`;

        const params = new HttpParams().set('seek', seek.toString()).set('pageSize', pageSize.toString());

        return this.http.get(url, { params }) as Observable<DataTemplate<PaginatedSearchResponse<PointsHistoryItem>>>;
    }

    updateDetails(request: PrimaryMemberRequest): Observable<any> {
        const url = `${environment.accountPath}/details`;

        return this.http.put(url, PrimaryMemberRequest.of(request)) as Observable<any>;
    }

    updatePhoto(request: { image: string }): Observable<any> {
        const url = `${environment.accountPath}/profilePicture`;

        return this.http.put(url, request) as Observable<any>;
    }

    getTrips(
        seek = 0,
        pageSize = 3,
        pastTrips = false
    ): Observable<DataTemplate<PaginatedSearchResponse<TripOverview>>> {
        const url = `${environment.accountPath}/trips`;

        let params = new HttpParams().set('seek', seek.toString()).set('pageSize', pageSize.toString());

        if (pastTrips) {
            params = params.append('pastTrips', 'true');
        } else {
            params = params.append('pastTrips', 'false');
        }

        return this.http.get(url, { params }) as Observable<DataTemplate<PaginatedSearchResponse<TripOverview>>>;
    }

    getTripDetails(id: number): Observable<DataTemplate<Trip>> {
        const url = `${environment.accountPath}/trips/${id}`;

        return this.http.get(url) as Observable<DataTemplate<Trip>>;
    }

    getUnassignedTripItems(
        seek = 0,
        pageSize = 6
    ): Observable<DataTemplate<PaginatedSearchResponse<TripItemOverview>>> {
        const url = `${environment.accountPath}/unassignedTripSegments`;

        const params = new HttpParams().set('seek', seek.toString()).set('pageSize', pageSize.toString());

        return this.http.get(url, { params }) as Observable<DataTemplate<PaginatedSearchResponse<TripItemOverview>>>;
    }

    createTrip(request: Record<string, any>): Observable<DataTemplate<{ id: number }>> {
        const url = `${environment.accountPath}/trips`;

        return this.http.post(url, request) as Observable<DataTemplate<{ id: number }>>;
    }

    updateTrip(id: number, request: TripUpdateRequest): Observable<any> {
        const url = `${environment.accountPath}/trips/${id}`;

        return this.http.put(url, TripUpdateRequest.of(request)) as Observable<DataTemplate<any>>;
    }

    deleteTrip(id: number): Observable<any> {
        const url = `${environment.accountPath}/trips/${id}`;

        return this.http.delete(url) as Observable<any>;
    }

    getTrip(id: number): Observable<DataTemplate<Trip>> {
        const url = `${environment.accountPath}/trips/${id}`;

        return this.http.get(url) as Observable<any>;
    }

    getTravelers(seek = 0, pageSize = 10): Observable<DataTemplate<PaginatedSearchResponse<AccountTravelerProfile>>> {
        const url = `${environment.accountPath}/travelerProfiles`;

        const params = new HttpParams().set('seek', seek.toString()).set('pageSize', pageSize.toString());

        return this.http.get(url, { params }) as Observable<
            DataTemplate<PaginatedSearchResponse<AccountTravelerProfile>>
        >;
    }

    createTraveler(request: AccountTravelerProfile): Observable<DataTemplate<{ id: number }>> {
        const url = `${environment.accountPath}/travelerProfiles`;

        return this.http.post(url, request) as Observable<DataTemplate<{ id: number }>>;
    }

    updateTraveler(id: number, request: AccountTravelerProfile): Observable<any> {
        const url = `${environment.accountPath}/travelerProfiles/${id}`;

        return this.http.put(url, request) as Observable<DataTemplate<any>>;
    }

    deleteTraveler(id: number): Observable<any> {
        const url = `${environment.accountPath}/travelerProfiles/${id}`;

        return this.http.delete(url) as Observable<DataTemplate<any>>;
    }

    getCards(): Observable<DataTemplate<CardProfile[]>> {
        const url = `${environment.accountPath}/paymentProfiles`;

        return this.http.get(url) as Observable<DataTemplate<CardProfile[]>>;
    }

    createCard(request: CardProfile): Observable<DataTemplate<{ id: number }>> {
        const url = `${environment.accountPath}/paymentProfiles`;

        return this.http.post(url, request) as Observable<DataTemplate<{ id: number }>>;
    }

    updateCard(id: number, request: CardProfile): Observable<any> {
        const url = `${environment.accountPath}/paymentProfiles/${id}`;

        return this.http.put(url, request) as Observable<DataTemplate<any>>;
    }

    deleteCard(id: number): Observable<any> {
        const url = `${environment.accountPath}/paymentProfiles/${id}`;

        return this.http.delete(url) as Observable<DataTemplate<any>>;
    }

    getPurchases(seek = 0, pageSize = 5): Observable<DataTemplate<PaginatedSearchResponse<AccountPurchase>>> {
        const url = `${environment.accountPath}/purchases`;

        const params = new HttpParams().set('seek', seek.toString()).set('pageSize', pageSize.toString());

        return this.http2.get({ url, params, mapper: PaginatedSearchResponse.of<AccountPurchase>(AccountPurchase.of) });
    }

    getConfirmation(id: number): Observable<DataTemplate<ConfirmationDetails>> {
        const url = `${environment.accountPath}/purchases/${id}/confirmation`;

        const confirmationDetails = JSON.parse(
            sessionStorage.getItem(`confirmation_details_${id}`)
        ) as DataTemplate<ConfirmationDetails>;

        if (confirmationDetails) {
            return of(confirmationDetails as DataTemplate<ConfirmationDetails>);
        } else {
            return this.http.get(url).pipe(
                tap((data) => {
                    sessionStorage.setItem(`confirmation_details_${id}`, JSON.stringify(data));
                }),
                map((data) => data as DataTemplate<ConfirmationDetails>)
            ) as Observable<DataTemplate<ConfirmationDetails>>;
        }
    }

    getCancellationReasons(): Observable<DataTemplate<GenericSearchResponse<GenericListItem>>> {
        const url = `${environment.accountPath}/purchases/cancellation/reasons`;

        return this.http.get(url) as Observable<DataTemplate<GenericSearchResponse<GenericListItem>>>;
    }

    cancelReservation(id: number, request: { reasonId: number }): Observable<DataTemplate<CancellationResponse>> {
        const url = `${environment.accountPath}/purchases/${id}/cancel`;

        return this.http.post(url, request) as Observable<DataTemplate<CancellationResponse>>;
    }

    sharePurchaseEmail(id: number, email: string): Observable<any> {
        const url = `${environment.accountPath}/purchases/${id}/share`;

        return this.http.post(url, { email }) as Observable<DataTemplate<{ email: string }>>;
    }

    getTimezones(): Observable<DataTemplate<Timezone[]>> {
        const url = `${environment.accountPath}/timezones`;

        const timezones = JSON.parse(sessionStorage.getItem(`timezones`)) as DataTemplate<Timezone[]>;

        if (timezones) {
            return of(timezones as DataTemplate<Timezone[]>);
        } else {
            return this.http.get(url).pipe(
                tap((data) => {
                    sessionStorage.setItem(`timezones`, JSON.stringify(data));
                }),
                map((data) => data as DataTemplate<Timezone[]>)
            ) as Observable<DataTemplate<Timezone[]>>;
        }
    }

    getSettings(): Observable<DataTemplate<AccountSettings>> {
        const url = `${environment.accountPath}/settings`;

        return this.http.get(url) as Observable<DataTemplate<AccountSettings>>;
    }

    updateSettings(request: AccountSettings): Observable<any> {
        const url = `${environment.accountPath}/settings`;

        return this.http.put(url, request) as Observable<DataTemplate<AccountSettings>>;
    }

    updatePassword(request: PasswordUpdateRequest): Observable<any> {
        const url = `${environment.accountPath}/changePassword`;

        return this.http.put(url, PasswordUpdateRequest.of(request)) as Observable<DataTemplate<AccountSettings>>;
    }

    getEligibleGuaranteedPriceReservations(): Observable<DataTemplate<GenericSearchResponse<GenericListItem>>> {
        const url = `${environment.accountPath}/guaranteedPriceReservations`;

        return this.http.get(url) as Observable<DataTemplate<GenericSearchResponse<GenericListItem>>>;
    }

    submitGuaranteedPriceForm(request: GuaranteedPriceRequest): Observable<any> {
        const url = `${environment.accountPath}/submitGuaranteedPriceReservation`;

        return this.http.post(url, request) as Observable<any>;
    }

    getMembershipCardProfiles(): Observable<DataTemplate<CardProfile[]>> {
        const url = `${environment.membershipPath}/ccardprofiles`;

        return this.http.get(url) as Observable<DataTemplate<CardProfile[]>>;
    }

    computePrice(membershipId: number, options?: ComputePriceInput): Observable<DataTemplate<BookPrice>> {
        const url = `${environment.membershipPath}/${membershipId}/price`;

        let params = new HttpParams();

        if (options?.payPoints || options?.payPoints === 0) {
            params = params.append('payPoints', options.payPoints.toString());
        }

        if (options?.useCredit) {
            params = params.append('useCredit', 'true');
        }

        return this.http.get(url, { params }) as Observable<DataTemplate<BookPrice>>;
    }

    upgradeMembership(request: MembershipBookRequest): Observable<DataTemplate<any>> {
        const url = `${environment.membershipPath}/upgrade`;

        return this.http.post(url, request) as Observable<DataTemplate<any>>;
    }

    getTierDetails(tierId: number): Observable<DataTemplate<TierDetails>> {
        const url = `${environment.membershipPath}/tiers/${tierId}`;

        return this.http.get(url) as Observable<DataTemplate<TierDetails>>;
    }
}
