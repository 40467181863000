export const pageTitles = {
    landing: 'Escapes Home',
    search: 'Escapes Search Results',
    book: 'Escapes Booking',
    bookConfirmation: 'Escapes Booking Confirmation',
    bookConfirmationProfile: 'Escapes Transaction Confirmation',
    bookReceipt: 'Escape Transaction Receipt',
    cancellation: 'Escapes Cancellation',
    cancellationConfirmation: 'Escapes Cancellation Confirmation',
    details: 'Escapes Details & Selection',
};
