import { Injectable } from '@angular/core';

export interface ScriptAttribute {
    name: string;
    value: string;
}

interface Script {
    name: string;
    src: string;
    charset?: string;
    data?: ScriptAttribute[];
    loaded?: boolean;
    location?: 'head' | 'body';
}

export const ScriptStore: Script[] = [
    { name: 'kountListen', src: '../../../../assets/js/kount.js' },
    { name: 'ga', src: '../../../../assets/js/ga.js' },
    { name: 'gtm', src: '../../../../assets/js/gtm.js' },
    { name: 'adobeAnalyticsAccount', src: '../../../../assets/js/adobe-analytics-account.js' },
    { name: 'adobeAnalyticsTrackingServer', src: '../../../../assets/js/adobe-analytics-tracking-server.js' },
    { name: 'elavon3DS', src: 'https://libs.fraud.elavon.com/sdk-web-js/1.2.0/3ds2-web-sdk.min.js' },
];

// eslint-disable-next-line init-declarations
declare const document: any;

@Injectable()
export class DynamicScriptLoaderService {
    private scripts: Record<string, Script> = {};

    constructor() {
        ScriptStore.forEach((script: Script) => {
            this.scripts[script.name] = {
                name: script.name,
                src: script.src,
                loaded: false,
            };
        });
    }

    prepareScript(script: Script): void {
        this.scripts[script.name] = {
            name: script.name,
            src: script.src,
            loaded: false,
            charset: script.charset,
            data: script.data,
            location: script.location,
        };
    }

    load(...scripts: string[]): any {
        const promises: any[] = [];
        scripts.forEach((script) => promises.push(this.loadScript(script)));
        return Promise.all(promises);
    }

    loadScript(name: string, noscript = false): any {
        return new Promise((resolve) => {
            if (!this.scripts[name].loaded) {
                const script = document.createElement(noscript ? 'noscript' : 'script');
                script.type = 'text/javascript';
                script.src = this.scripts[name].src;

                if (this.scripts[name].charset) {
                    script.charset = this.scripts[name].charset;
                }

                this.scripts[name].data?.forEach((d) => {
                    script.setAttribute(d.name, d.value);
                });

                if (script.readyState) {
                    // IE
                    script.onreadystatechange = () => {
                        if (script.readyState === 'loaded' || script.readyState === 'complete') {
                            script.onreadystatechange = null;
                            this.scripts[name].loaded = true;
                            resolve({ script: name, loaded: true, status: 'Loaded' });
                        }
                    };
                } else {
                    script.onload = () => {
                        this.scripts[name].loaded = true;
                        resolve({ script: name, loaded: true, status: 'Loaded' });
                    };
                }

                script.onerror = () => resolve({ script: name, loaded: false, status: 'Loaded' });

                if (this.scripts[name].location === 'body') {
                    document.getElementsByTagName('body')[0].appendChild(script);
                } else {
                    document.getElementsByTagName('head')[0].appendChild(script);
                }
            } else {
                resolve({ script: name, loaded: true, status: 'Already Loaded' });
            }
        });
    }
}
