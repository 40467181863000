import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { NguCarouselModule } from '@ngu/carousel';
import {
    ActivitiesDialogComponent,
    ActivitiesRecentSearchComponent,
    BestDealsComponent,
    BookingFooterComponent,
    BookingPaymentDetailsComponent,
    BookingTripItineraryComponent,
    CancellationConfirmationComponent,
    CancellationDialogComponent,
    CarsDialogComponent,
    CarsPricesComponent,
    CarsRecentSearchComponent,
    CarsTotalsComponent,
    CheckboxComponent,
    ConciergeDialogComponent,
    ConfirmationPageHeaderComponent,
    ConfirmCurrencyChangeDialogComponent,
    CruisesDialogComponent,
    CruisesRecentSearchComponent,
    DefaultFooterComponent,
    DetailsPageHeaderComponent,
    DetailsPageNavigationComponent,
    DialogTemplateComponent,
    FeaturedDestinationsComponent,
    FlightsDialogComponent,
    FlightsRecentSearchComponent,
    FooterComponent,
    GenericDialogComponent,
    GuestsCounterAgeBandsComponent,
    GuestsCounterComponent,
    HeaderComponent,
    HotelsDialogComponent,
    HotelsRecentSearchComponent,
    HotelSummaryComponent,
    IframeDialogComponent,
    InputComponent,
    LastMinuteBookingDialogComponent,
    LeftNavigationComponent,
    LoginFooterComponent,
    LoginHeaderComponent,
    LTPFooterComponent,
    MasonryPhotosCarouselComponent,
    NavigationItemsComponent,
    NavigationLocalesComponent,
    NavigationPhonesComponent,
    NavigationUserDetailsComponent,
    NotFoundComponent,
    PaymentBillingFormComponent,
    PaymentCardComponent,
    PointsEarnedComponent,
    PointsSummaryComponent,
    PriceSummaryComponent,
    ProductLoaderComponent,
    PropertyTaxesComponent,
    RecentSearchesComponent,
    RedirectHandlerComponent,
    RewardsDialogComponent,
    RoomsSelectorComponent,
    SelectComponent,
    ShoppingDialogComponent,
    SpaceBetweenFooterComponent,
    SpinnerComponent,
    TaxesComponent,
    TextareaComponent,
    ToggleReadMoreComponent,
    TravelerProfilesComponent,
    TripItineraryFormComponent,
    UpgradeLoginButtonComponent,
    WeeksDialogComponent,
    WeeksRecentSearchComponent,
} from '@shared/components';
import {
    AutoFocusDirective,
    ClickStopPropagation,
    DisableControlDirective,
    I18nDirective,
    ImageFallbackDirective,
    TrapFocusDirective,
} from '@shared/directives';
import {
    FormatAssetValuePipe,
    FormatCardLabelPipe,
    FormatCurrencyPipe,
    FormatTripItemTypePipe,
    SanitizeHtmlPipe,
    SortByPipe,
} from '@shared/pipes';
import { ClickOutsideModule } from 'ng-click-outside';
import { NgxPaginationModule } from 'ngx-pagination';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule,
        RouterModule,

        MatButtonModule,
        MatCheckboxModule,
        MatCardModule,
        MatDialogModule,
        MatDividerModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        MatToolbarModule,
        MatTabsModule,
        MatTooltipModule,
        MatTableModule,
        MatRadioModule,

        ClickOutsideModule,
        NgxSliderModule,
        NguCarouselModule,
        NgxPaginationModule,
    ],
    declarations: [
        I18nDirective,
        ImageFallbackDirective,
        DisableControlDirective,
        AutoFocusDirective,
        TrapFocusDirective,
        ClickStopPropagation,

        SanitizeHtmlPipe,
        SortByPipe,
        FormatCardLabelPipe,
        FormatCurrencyPipe,
        FormatTripItemTypePipe,
        FormatAssetValuePipe,

        CheckboxComponent,
        InputComponent,
        SelectComponent,
        TextareaComponent,

        HeaderComponent,
        LoginHeaderComponent,
        FooterComponent,
        DefaultFooterComponent,
        LTPFooterComponent,
        SpaceBetweenFooterComponent,
        DetailsPageHeaderComponent,
        LoginFooterComponent,
        LeftNavigationComponent,
        NotFoundComponent,
        NavigationItemsComponent,
        NavigationLocalesComponent,
        NavigationPhonesComponent,
        ConfirmCurrencyChangeDialogComponent,
        NavigationUserDetailsComponent,
        PaymentBillingFormComponent,
        PaymentCardComponent,
        ProductLoaderComponent,
        GuestsCounterComponent,
        RoomsSelectorComponent,
        HotelSummaryComponent,
        TaxesComponent,
        PropertyTaxesComponent,
        PriceSummaryComponent,
        PointsSummaryComponent,
        TravelerProfilesComponent,
        BookingTripItineraryComponent,
        TripItineraryFormComponent,
        BookingPaymentDetailsComponent,
        BookingFooterComponent,
        ConfirmationPageHeaderComponent,
        CancellationDialogComponent,
        CancellationConfirmationComponent,
        MasonryPhotosCarouselComponent,
        DetailsPageHeaderComponent,
        DetailsPageNavigationComponent,
        FeaturedDestinationsComponent,
        BestDealsComponent,
        ToggleReadMoreComponent,
        GenericDialogComponent,
        UpgradeLoginButtonComponent,
        HotelsDialogComponent,
        CarsDialogComponent,
        CruisesDialogComponent,
        WeeksDialogComponent,
        FlightsDialogComponent,
        ActivitiesDialogComponent,
        RewardsDialogComponent,
        ConciergeDialogComponent,
        LastMinuteBookingDialogComponent,
        ShoppingDialogComponent,
        DialogTemplateComponent,
        RedirectHandlerComponent,
        RecentSearchesComponent,
        HotelsRecentSearchComponent,
        FlightsRecentSearchComponent,
        CarsRecentSearchComponent,
        WeeksRecentSearchComponent,
        CruisesRecentSearchComponent,
        ActivitiesRecentSearchComponent,
        GuestsCounterAgeBandsComponent,
        SpinnerComponent,
        IframeDialogComponent,
        CarsPricesComponent,
        PointsEarnedComponent,
        CarsTotalsComponent,
    ],
    exports: [
        CommonModule,
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule,
        RouterModule,

        MatButtonModule,
        MatCheckboxModule,
        MatCardModule,
        MatDialogModule,
        MatDividerModule,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        MatToolbarModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        MatTabsModule,
        MatTooltipModule,
        MatTableModule,
        MatRadioModule,

        ClickOutsideModule,
        NgxSliderModule,
        NguCarouselModule,
        NgxPaginationModule,

        I18nDirective,
        ImageFallbackDirective,
        DisableControlDirective,
        AutoFocusDirective,
        TrapFocusDirective,
        ClickStopPropagation,

        SanitizeHtmlPipe,
        SortByPipe,
        FormatCardLabelPipe,
        FormatTripItemTypePipe,
        FormatCurrencyPipe,
        FormatAssetValuePipe,

        CheckboxComponent,
        InputComponent,
        SelectComponent,
        TextareaComponent,

        HeaderComponent,
        LoginHeaderComponent,
        FooterComponent,
        DefaultFooterComponent,
        LTPFooterComponent,
        SpaceBetweenFooterComponent,
        LoginFooterComponent,
        LeftNavigationComponent,
        NotFoundComponent,
        NavigationItemsComponent,
        NavigationLocalesComponent,
        NavigationPhonesComponent,
        NavigationUserDetailsComponent,
        PaymentBillingFormComponent,
        PaymentCardComponent,
        ProductLoaderComponent,
        GuestsCounterComponent,
        RoomsSelectorComponent,
        HotelSummaryComponent,
        TaxesComponent,
        PropertyTaxesComponent,
        PriceSummaryComponent,
        PointsSummaryComponent,
        TravelerProfilesComponent,
        BookingTripItineraryComponent,
        TripItineraryFormComponent,
        BookingPaymentDetailsComponent,
        BookingFooterComponent,
        ConfirmationPageHeaderComponent,
        CancellationDialogComponent,
        CancellationConfirmationComponent,
        MasonryPhotosCarouselComponent,
        DetailsPageHeaderComponent,
        DetailsPageNavigationComponent,
        FeaturedDestinationsComponent,
        BestDealsComponent,
        ToggleReadMoreComponent,
        GenericDialogComponent,
        UpgradeLoginButtonComponent,
        RecentSearchesComponent,
        HotelsRecentSearchComponent,
        FlightsRecentSearchComponent,
        CarsRecentSearchComponent,
        WeeksRecentSearchComponent,
        CruisesRecentSearchComponent,
        ActivitiesRecentSearchComponent,
        GuestsCounterAgeBandsComponent,
        SpinnerComponent,
        IframeDialogComponent,
        CarsPricesComponent,
        PointsEarnedComponent,
        CarsTotalsComponent,
    ],
    providers: [FormatCardLabelPipe],
})
export class SharedModule {}
