<section appTranslate id="hotels-landing" class="hotels-landing-wrapper">
    <ng-container *ngFor="let widget of widgets">
        <app-hero-image-widget [widget]="widget" *ngIf="widget.code === 'hero_image'"></app-hero-image-widget>

        <app-banner-widget [widget]="widget" *ngIf="widget.code === 'simple_banner'"></app-banner-widget>
    </ng-container>

    <div class="main-content-wrapper landing-content-wrapper padding-top-30 padding-bottom-30">
        <div class="margin-bottom-50" *ngFor="let widget of widgets">
            <app-module-widget
                [widget]="getModuleWidget(widget)"
                *ngIf="widget.code === 'modules_preview_1'"
            ></app-module-widget>

            <app-background-module-widget
                [widget]="getModuleWidget(widget)"
                *ngIf="widget.code === 'modules_preview_2'"
            ></app-background-module-widget>

            <app-statistics-widget [widget]="widget" *ngIf="widget.code === 'statistics_1'"></app-statistics-widget>
        </div>
    </div>
</section>
