import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { CruiseDetails } from '@cruises/models';
import { CruisesService } from '@cruises/services/cruises.service';
import { DataTemplate } from '@shared/models';
import { UrlService } from '@shared/services';
import { of, Subject } from 'rxjs';
import { catchError, finalize, mergeMap, take } from 'rxjs/operators';

import { ProductLoaderService } from './product-loader.service';

@Injectable({
    providedIn: 'root',
})
export class CruiseDetailsResolverService implements Resolve<any>, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    constructor(
        private cruisesService: CruisesService,
        private productLoaderService: ProductLoaderService,
        private url: UrlService,
        private router: Router
    ) {}

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    resolve(route: ActivatedRouteSnapshot): any {
        const cruise = JSON.parse(
            sessionStorage.getItem(`cruise_${route.params['id']}`)
        ) as DataTemplate<CruiseDetails>;

        if (cruise) {
            return of(cruise as DataTemplate<CruiseDetails>);
        } else {
            this.productLoaderService.showLoader.emit(true);

            return this.cruisesService.getDetails(route.params['id']).pipe(
                take(1),
                finalize(() => this.productLoaderService.forceCompleteLoader.emit(true)),
                mergeMap((response: DataTemplate<CruiseDetails>) => {
                    response.data.goodStars = Array(response.data.rating);
                    response.data.badStars = Array(6 - response.data.rating);

                    if (response.data.shipDetails?.facilityGroups?.length) {
                        response.data.shipDetails.facilityGroups.sort(
                            (a, b) => a.facilities.length - b.facilities.length
                        );
                    }

                    sessionStorage.setItem(`cruise_${route.params['id']}`, JSON.stringify(response.data));

                    return of(response.data);
                }),
                catchError(() => {
                    return of(null);
                })
            );
        }
    }
}
