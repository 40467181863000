export class RegisterResponse {
    memberCreated: boolean;
    message: string;
    ecommerce: RegisterEcommerceData;
}

class RegisterEcommerceData {
    sbId: string;
    sbPlanName: string;
    siteName: string;
    initialAmount: number;
    refCode: string;
    txSubGuid: string;
}
