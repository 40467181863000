import { TripOverview } from '@account/models';
import { AccountService } from '@account/services';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LoaderService } from '@core/services';
import { CreateTripDialogResult, DataTemplate, SharedStorageKeys } from '@shared/models';
import { FormErrorService, I18nRefreshService, I18nService } from '@shared/services';
import { defer, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-trip-itinerary-form',
    templateUrl: './trip-itinerary-form.component.html',
    styleUrls: ['./trip-itinerary-form.component.scss'],
})
export class TripItineraryFormComponent implements OnInit, OnDestroy {
    sharedStorageKeys = SharedStorageKeys;
    createTripError: string;

    tripFormGroup: FormGroup;
    formErrors = {
        name: '',
    };
    validationMessages = {};

    private unsubscribe: Subject<void> = new Subject<void>();

    constructor(
        private accountService: AccountService,
        private formErrorService: FormErrorService,
        public i18nService: I18nService,
        private i18nRefreshService: I18nRefreshService,
        private loader: LoaderService,
        public dialogRef: MatDialogRef<TripItineraryFormComponent, CreateTripDialogResult>,
        @Inject(MAT_DIALOG_DATA) public data: { trips: TripOverview[] }
    ) {}

    ngOnInit(): void {
        this.i18nRefreshService
            .getState()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.getValidationMessages(true);
            });

        this.getValidationMessages();
        this.createTripForm();
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    createTripForm(): void {
        this.tripFormGroup = new FormGroup({
            name: new FormControl('', [
                Validators.required,
                Validators.maxLength(128),
                this.tripNameValidator.bind(this),
            ]),
        });

        this.tripFormGroup.valueChanges
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() =>
                this.formErrorService.checkForErrors(this.tripFormGroup, this.formErrors, this.validationMessages)
            );
    }

    submitCreateTrip(): void {
        this.formErrorService.markAsDirty(this.tripFormGroup);
        this.formErrorService.checkForErrors(this.tripFormGroup, this.formErrors, this.validationMessages);

        if (this.tripFormGroup.valid) {
            defer(() => {
                this.loader.show();
                this.createTripError = null;

                return this.accountService.createTrip(this.tripFormGroup.value).pipe(
                    takeUntil(this.unsubscribe),
                    finalize(() => this.loader.hide())
                );
            }).subscribe(
                (response: DataTemplate<{ id: number }>) => {
                    this.dialogRef.close({
                        id: response.data.id,
                        name: this.tripFormGroup.get('name').value,
                    });
                },
                (error: string) => {
                    console.error(error);
                    this.createTripError = error;
                }
            );
        }
    }

    getValidationMessages(swapValidationMessages = false): void {
        this.i18nService
            .preloadKeys([
                'core.errors.tripNameRequired',
                'core.errors.tripNameMaxLength',
                'core.errors.tripNameUnique',
            ])
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                const keys = this.i18nService.getSavedKeys();

                this.validationMessages = {
                    name: {
                        required: keys['core.errors.tripNameRequired'],
                        maxlength: keys['core.errors.tripNameMaxLength'],
                        tripNameUnique: keys['core.errors.tripNameUnique'],
                    },
                };

                if (swapValidationMessages) {
                    this.tripFormGroup.clearValidators();
                    this.tripFormGroup.updateValueAndValidity();
                }
            });
    }

    tripNameValidator(control: AbstractControl): ValidationErrors {
        return this.data.trips?.some((trip) => {
            if (trip.name === control.value) {
                return true;
            }
        })
            ? { tripNameUnique: true }
            : null;
    }
}
