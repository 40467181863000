import { AbstractControl, ValidatorFn } from '@angular/forms';

export function discoverLength(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null =>
        control.value?.length === 16 ? null : { invalid: true };
}

export function discoverStartsWith(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null =>
        control.value?.startsWith('6011') ? null : { invalid: true };
}
