export enum SharedStorageKeys {
    BASIC_AUTH = 'basic_auth',
    SITE_BRANDING = 'site_branding',

    USER_INFO = 'user_info',
    USER_ID = 'user_id',
    REMEMBER_USERNAME = 'remember_username',
    REMEMBER_ME = 'remember_me',
    PREVENT_GUEST_LOGIN = 'prevent_guest_login',
    LOGIN_REDIRECT = 'login_redirect',
    PREVENT_SESSION_TIMEOUT_TOAST = 'prevent_session_timeout_toast',

    HEADER_FIXED = 'header_fixed',
    HEADER_TARGET = 'header_target',
    GENERAL_KEYS_LOADED = 'general_keys_loaded',
    CURRENT_LANGUAGE = 'current_language',
    CURRENT_CURRENCY = 'current_currency',
    I18N_KEYS = 'i18n_keys',

    ACCESS_TOKEN = 'access_token',
    EXPIRES_IN = 'expires_in',

    SHOW_CURRENCY_ALERT = 'show_currency_alert',
    CURRENCY_CHANGED = 'currency_changed',

    TIER_TO_UPGRADE = 'tier_to_upgrade',
}
