import { Location, SavedSearchSortFilters } from '@shared/models';

export class SearchRequest {
    adults: number;
    prefferedCheckInDate: string;
    within = 30;
    children: number;
    childrenAges: number[];
    searchCriteria: LocationSearch | string[];
    searchType: string;
    distanceUnit?: string;
    startIn?: number;
    stayFor?: number;
    startDate?: string;
    endDate?: string;
    sortFilters?: WeeksSortFilters;

    constructor(
        prefferedCheckInDate?: string,
        adults = 2,
        children?: number,
        childrenAges?: number[],
        searchCriteria?: LocationSearch | string[],
        searchType?: string
    ) {
        this.adults = adults;
        this.prefferedCheckInDate = prefferedCheckInDate;
        this.children = children;
        this.childrenAges = childrenAges;
        this.searchCriteria = searchCriteria;
        this.searchType = searchType;
    }
}

export class LocationSearch {
    locId: string;
    locName: string;
    location: Location;
    radius: number;
    type?: string;

    constructor(locId: string, location: Location, radius = 30000, locName: string, type: string) {
        this.locId = locId;
        this.location = location;
        this.radius = radius;
        this.locName = locName;
        this.type = type;
    }
}

export enum HotelsSearchType {
    HOTEL = 'hotel',
    CITY = 'city',
}

export interface WeeksSortFilters extends SavedSearchSortFilters {
    guestRating: number;
    priceMin: number;
    priceMax: number;
}
