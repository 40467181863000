import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import {
    AboutComponent,
    AssistMemberComponent,
    AutoLoginComponent,
    BackgroundModuleWidgetComponent,
    BannerWidgetComponent,
    ContactComponent,
    HeroImageWidgetComponent,
    HomeComponent,
    LoaderComponent,
    LoginComponent,
    MembershipBookingComponent,
    MembershipUserFormComponent,
    ModuleWidgetComponent,
    PageComponent,
    PrivacyPolicyComponent,
    SnackbarComponent,
    StatisticsWidgetComponent,
    TermsAndConditionsComponent,
    UpgradeMembershipComponent,
} from '@core/components';
import { NewUserGreetDialogComponent, ResetPasswordDialogComponent } from '@core/components/login/dialogs';
import {
    ForgotPasswordFormComponent,
    ForgotUsernameFormComponent,
    LoginFormComponent,
    RegisterFormComponent,
    RegisterMemberInformationFormComponent,
} from '@core/components/login/forms';
import { CoreRoutes } from '@core/core.routing';
import { HttpInterceptorService } from '@core/services';
import { NguCarouselModule } from '@ngu/carousel';
import { SharedModule } from '@shared/shared.module';

@NgModule({
    imports: [SharedModule, CoreRoutes, NguCarouselModule],
    exports: [LoaderComponent, AboutComponent, ContactComponent, PrivacyPolicyComponent, TermsAndConditionsComponent],
    declarations: [
        AssistMemberComponent,
        AutoLoginComponent,
        LoaderComponent,
        LoginComponent,
        LoginFormComponent,
        RegisterFormComponent,
        ForgotPasswordFormComponent,
        ForgotUsernameFormComponent,
        RegisterMemberInformationFormComponent,
        ResetPasswordDialogComponent,
        NewUserGreetDialogComponent,
        AboutComponent,
        ContactComponent,
        PrivacyPolicyComponent,
        TermsAndConditionsComponent,
        UpgradeMembershipComponent,
        MembershipBookingComponent,
        SnackbarComponent,
        PageComponent,
        MembershipUserFormComponent,
        HomeComponent,
        ModuleWidgetComponent,
        BackgroundModuleWidgetComponent,
        StatisticsWidgetComponent,
        BannerWidgetComponent,
        HeroImageWidgetComponent,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpInterceptorService,
            multi: true,
        },
    ],
})
export class CoreModule {}
