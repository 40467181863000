import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { SiteBranding } from '@core/models';
import { LoaderService } from '@core/services';
import { OfferDetails } from '@offers/models';
import { OffersService } from '@offers/services/offers.service';
import { DataTemplate, SharedStorageKeys } from '@shared/models';
import { UrlService } from '@shared/services';
import { of, Subject } from 'rxjs';
import { catchError, mergeMap, take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class OfferDetailsResolverService implements Resolve<OfferDetails>, OnInit, OnDestroy {
    sharedStorageKeys = SharedStorageKeys;
    siteBranding: SiteBranding;

    private unsubscribe: Subject<void> = new Subject();

    constructor(
        private offersService: OffersService,
        private loader: LoaderService,
        private url: UrlService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.siteBranding = JSON.parse(localStorage.getItem(this.sharedStorageKeys.SITE_BRANDING)) as SiteBranding;
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    resolve(route: ActivatedRouteSnapshot): any {
        this.loader.show();

        return this.offersService.getDetails(route.params['id']).pipe(
            take(1),
            mergeMap((response: DataTemplate<OfferDetails>) => {
                this.loader.hide();

                if (response.data.images.length === 1) {
                    response.data.images = [response.data.images[0], response.data.images[0]];
                }

                return of(response.data);
            }),
            catchError(() => {
                this.loader.hide();
                this.router.navigateByUrl(this.url.getUrl(this.siteBranding?.uiConfig?.modules[0]));
                return of(null);
            })
        );
    }
}
