<div>
    <button
        appTranslate
        tabindex="-1"
        mat-raised-button
        [color]="'primary'"
        class="width-100 text-uppercase"
        *ngIf="upgradeRequired && !loginRequired"
        (click)="handleUpgradeRedirect()"
    >
        <span [attr.data-i18n-code]="'core.labels.upgradeToBook'"></span>
    </button>

    <button
        appTranslate
        tabindex="-1"
        mat-raised-button
        [color]="'primary'"
        class="width-100 text-uppercase"
        *ngIf="loginRequired"
        (click)="handleLoginRedirect()"
    >
        <span [attr.data-i18n-code]="'core.labels.loginToBook'"></span>
    </button>

    <ng-content *ngIf="!upgradeRequired && !loginRequired"></ng-content>
</div>
