import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ModuleDialogContent } from '@shared/models';

@Component({
    selector: 'app-last-minute-booking-dialog',
    templateUrl: './last-minute-booking-dialog.component.html',
})
export class LastMinuteBookingDialogComponent {
    content: ModuleDialogContent = {
        title: {
            value: {
                en: 'LAST MINUTE BOOKING',
                es: 'RESERVAS DE ULTIMO MINUTO',
            },
        },
        image: 'https://images.leisureloyalty.com/raintreerewards/img/club-benefit/LAST_MINUTE_WEEKLY_STAYS.jpg',
        content: [
            {
                type: 'paragraph',
                value: {
                    en: "Sometimes when a vacation calls, there's no time to wait. Take advantage of last minute bookings offered at exceptional rates exclusively to Raintree Rewards members.",
                    es: 'A veces, cuando las vacaciones llaman, no hay tiempo para esperar. Aprovecha las reservas de última hora ofrecidas a tarifas excepcionales exclusivamente para los miembros de Raintree Rewards.',
                },
            },
            {
                type: 'title',
                value: {
                    en: 'Shorter booking time, bigger savings',
                    es: 'Menos tiempo de reserva, mayores ahorros',
                },
            },
            {
                type: 'paragraph',
                value: {
                    en: "With last minute bookings, your vacation time becomes even more affordable. It's another way Raintree Rewards members can save when they book with our international partners.",
                    es: 'Con las reservas de última hora, el tiempo de tus vacaciones se vuelve aún más asequible. Es otra forma en que los miembros de Raintree Rewards pueden ahorrar al reservar con nuestros socios internacionales.',
                },
            },
            {
                type: 'title',
                value: {
                    en: 'More vacations, more often',
                    es: 'Más vacaciones, más frecuentes',
                },
            },
            {
                type: 'paragraph',
                value: {
                    en: 'Like to travel often and have the freedom and flexibility to choose when you can get away? Last minute bookings are the perfect way to make the most of your Raintree Rewards membership.',
                    es: '¿Te gusta viajar con frecuencia y tener la libertad y flexibilidad de elegir cuándo puedes escaparte? Las reservas de última hora son la forma perfecta de aprovechar al máximo tu membresía de Raintree Rewards.',
                },
            },
        ],
        partners: [],
    };

    constructor(public dialogRef: MatDialogRef<LastMinuteBookingDialogComponent>) {}
}
