import { Component, Input } from '@angular/core';
import { SearchRequest } from '@cars/models';

@Component({
    selector: 'app-cars-recent-search',
    templateUrl: './cars-recent-search.component.html',
    styleUrls: ['./cars-recent-search.component.scss'],
})
export class CarsRecentSearchComponent {
    @Input()
    search: SearchRequest;
}
