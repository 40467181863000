import { Component, OnDestroy, OnInit } from '@angular/core';
import { SiteBranding } from '@core/models';
import { SharedStorageKeys } from '@shared/models';
import { FooterService, I18nService, UrlService } from '@shared/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnDestroy {
    sharedStorageKeys = SharedStorageKeys;
    siteBranding: SiteBranding;
    menuItems: any[] = [];
    logoUrl: string;

    hideFooter: boolean;
    currentYear: number = new Date().getFullYear();
    copyright: string;

    private unsubscribe: Subject<void> = new Subject();

    constructor(private footerService: FooterService, public url: UrlService, private i18nService: I18nService) {}

    ngOnInit(): void {
        this.siteBranding = JSON.parse(localStorage.getItem(this.sharedStorageKeys.SITE_BRANDING)) as SiteBranding;

        this.logoUrl =
            this.siteBranding?.uiConfig?.siteOptions?.designOptions?.header?.logoUrl ||
            this.url.getUrl(this.siteBranding?.uiConfig.modules[0]);

        this.siteBranding?.uiConfig?.modules?.forEach((module: string) => {
            this.menuItems.push({
                code: module,
            });
        });

        this.copyright =
            this.i18nService.getKeyValue('core.labels.copyright') || this.siteBranding?.uiConfig.siteOptions.siteName;

        this.footerService.hide.pipe(takeUntil(this.unsubscribe)).subscribe((response: boolean) => {
            this.hideFooter = response;
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
