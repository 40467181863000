import { SavedSearchSortFilters } from '@shared/models';

export class SearchRequest {
    destination: number | number[];
    destinationLabel: string;
    date: string;
    adults: number;
    children: number;
    childrenAges?: number[];
    oaLocation?: string;
    sortFilters: SavedSearchSortFilters;
    useDiscount?: boolean;

    constructor(destination?: number, date?: string, adults = 2, children?: number, childrenAges?: number[]) {
        this.adults = adults;
        this.date = date;
        this.destination = destination;
        this.children = children;
        this.childrenAges = childrenAges;
    }
}
