<div class="main-content-wrapper">
    <div
        class="footer-links-wrapper padding-top-40 padding-bottom-40 flex-container justify-content-space-between align-items-center"
    >
        <a
            *ngIf="!logoUrl.startsWith('http')"
            class="logo-wrapper flex-container margin-right-25"
            [attr.aria-label]="siteBranding?.uiConfig?.siteOptions?.siteName"
            [routerLink]="logoUrl"
        >
            <div class="logo responsive-logo"></div>
        </a>

        <a *ngIf="logoUrl.startsWith('http')" class="logo-wrapper flex-container margin-right-25" [attr.href]="logoUrl">
            <div class="logo responsive-logo"></div>
        </a>

        <ul
            class="flex-container flex-wrap hide-below-sm ada-list"
            *ngIf="menuItems && !siteBranding?.uiConfig?.siteOptions?.designOptions?.footer?.hideModules"
        >
            <ng-container *ngFor="let item of menuItems">
                <li class="flex-50 margin-bottom-5">
                    <a class="link footer-link" [routerLink]="url.getUrl(item.code)">
                        <span [attr.data-i18n-code]="'nav.' + item.code + '.label'"></span>
                    </a>
                </li>
            </ng-container>
        </ul>

        <ul class="footer-static-menu-wrapper flex-container flex-wrap ada-list">
            <ng-container *ngFor="let link of siteBranding?.uiConfig?.siteOptions?.designOptions?.footer?.links">
                <li class="flex-100 margin-bottom-5">
                    <a
                        class="link footer-link {{ link.cssClass }}"
                        *ngIf="link.customUrl?.startsWith('http')"
                        [attr.target]="link.target"
                        [attr.href]="link.customUrl"
                    >
                        <span [attr.data-i18n-code]="link.name"></span>
                    </a>

                    <a
                        class="link footer-link {{ link.cssClass }}"
                        *ngIf="link.customUrl && !link.customUrl?.startsWith('http')"
                        [routerLink]="link.customUrl || url.getUrl(link.name)"
                    >
                        <span [attr.data-i18n-code]="link.name"></span>
                    </a>

                    <div *ngIf="!link.customUrl" class="link footer-link {{ link.cssClass }}">
                        <span [attr.data-i18n-code]="link.name"></span>
                    </div>
                </li>
            </ng-container>

            <ng-container *ngIf="!siteBranding?.uiConfig?.siteOptions?.designOptions?.footer?.links?.length">
                <li class="flex-100 margin-bottom-5">
                    <a class="link footer-link" [routerLink]="url.getUrl('about')">
                        <span [attr.data-i18n-code]="'nav.about.label'"></span>
                    </a>
                </li>

                <li class="flex-100 margin-bottom-5">
                    <a class="link footer-link" [routerLink]="url.getUrl('contact')">
                        <span [attr.data-i18n-code]="'nav.contact.label'"></span>
                    </a>
                </li>

                <li class="flex-100 margin-bottom-5">
                    <a class="link footer-link" [routerLink]="url.getUrl('privacy')">
                        <span [attr.data-i18n-code]="'nav.privacy.label'"></span>
                    </a>
                </li>

                <li class="flex-100 margin-bottom-5">
                    <a class="link footer-link" [routerLink]="url.getUrl('terms')">
                        <span [attr.data-i18n-code]="'nav.terms.label'"></span>
                    </a>
                </li>
            </ng-container>
        </ul>
    </div>

    <mat-divider role="" class="divider"></mat-divider>

    <div
        class="footer-social-wrapper padding-top-20 padding-bottom-20 flex-container justify-content-space-between align-items-center"
    >
        <span appTranslate class="link cursor-default copyright">
            <span>&#169; {{ currentYear }} {{ copyright }}</span>
        </span>

        <div
            class="footer-social-icons-wrapper flex-container align-items-center"
            [class.justify-content-center]="
                siteBranding?.uiConfig?.siteOptions?.designOptions?.footer?.accreditations?.length
            "
            *ngIf="siteBranding?.uiConfig?.social?.length"
        >
            <ng-container *ngFor="let social of siteBranding?.uiConfig?.social; index as i">
                <a
                    href="{{ social.url }}"
                    target="_blank"
                    class="footer-icon"
                    [class.margin-right-30]="i < siteBranding?.uiConfig?.social.length - 1"
                >
                    <mat-icon svgIcon="{{ social.target }}" class="social-icon"></mat-icon>
                </a>
            </ng-container>
        </div>

        <div
            class="accreditations-wrapper margin-sm-bottom-20"
            *ngIf="siteBranding?.uiConfig?.siteOptions?.designOptions?.footer?.accreditations?.length"
        >
            <p
                class="link accreditations-title ursor-default margin-top-0 margin-bottom-10"
                [attr.data-i18n-code]="'core.labels.travelAccreditations'"
            ></p>

            <div class="accreditation-images-wrapper flex-container align-items-center">
                <img
                    *ngFor="let accr of siteBranding?.uiConfig?.siteOptions?.designOptions?.footer?.accreditations"
                    class="accreditation-image"
                    [attr.src]="accr.imageUrl"
                    [attr.alt]="accr.name"
                />
            </div>
        </div>
    </div>
</div>
