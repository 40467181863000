import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ModuleDialogContent } from '@shared/models';

@Component({
    selector: 'app-flights-dialog',
    templateUrl: './flights-dialog.component.html',
})
export class FlightsDialogComponent {
    content: ModuleDialogContent = {
        title: {
            value: {
                en: 'FLIGHTS',
                es: 'VUELOS',
            },
        },
        image: 'https://images.leisureloyalty.com/raintreerewards/img/club-benefit/flights.jpg',
        content: [
            {
                type: 'paragraph',
                value: {
                    en: 'Fly high with domestic and international flights thanks to our global network of airline partners. Wherever you need to go, you can get there with Raintree Rewards.',
                    es: 'Vuele alto de manera local o internacional en nuestra red global de aerolíneas como socios comerciales. A donde usted necesite ir, puede llegar ahí con Raintree Rewards.',
                },
            },
            {
                type: 'title',
                value: {
                    en: 'Search, book and fly',
                    es: 'Busque, reserve y vuele',
                },
            },
            {
                type: 'paragraph',
                value: {
                    en: 'Take the hassle out of booking airline tickets with our simple online search and book facilities. Enter your travel dates, compare airlines and book the most suitable flights for your trip.',
                    es: 'Reserve de forma fácil sus boletos con una simple búsqueda. Inserte sus fechas de viaje, compare aerolíneas y reserve los vuelos acordes a las necesidades de su viaje.',
                },
            },
            {
                type: 'title',
                value: {
                    en: 'Go around the globe',
                    es: 'Viaje alrededor del mundo',
                },
            },
            {
                type: 'paragraph',
                value: {
                    en: "No matter where you're heading, Raintree Rewards can take you around the globe with major airline partners in our network. Don't forget that you can also book discounted hotels and car hire with Raintree Rewards to go with your flight.",
                    es: 'Recuerde que usted también puede reservar junto con su vuelo, hoteles y autos con Raintree Rewards recibiendo recompensas por cada reservación que haga.',
                },
            },
        ],
        partners: [
            'https://images.leisureloyalty.com/raintreerewards/img/suppliers/flights/air-canada.png',
            'https://images.leisureloyalty.com/raintreerewards/img/suppliers/flights/jetblue.png',
            'https://images.leisureloyalty.com/raintreerewards/img/suppliers/flights/united.png',
            'https://images.leisureloyalty.com/raintreerewards/img/suppliers/flights/air-new-zealand.png',
            'https://images.leisureloyalty.com/raintreerewards/img/suppliers/flights/qantas.png',
            'https://images.leisureloyalty.com/raintreerewards/img/suppliers/flights/virgin-america.png',
            'https://images.leisureloyalty.com/raintreerewards/img/suppliers/flights/delta.png',
            'https://images.leisureloyalty.com/raintreerewards/img/suppliers/flights/southwest.png',
        ],
    };

    constructor(public dialogRef: MatDialogRef<FlightsDialogComponent>) {}
}
