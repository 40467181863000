<div appTranslate>
    <h2 mat-dialog-title [attr.data-i18n-code]="'account.trips.createTrip.label'"></h2>

    <div mat-dialog-content class="padding-top-20 padding-bottom-20">
        <div class="flex-container trip-form-wrapper">
            <form class="flex-grow-1 padding-right-20 trip-form" [formGroup]="tripFormGroup">
                <app-input
                    [label]="'account.trips.tripName.label'"
                    [inputType]="'text'"
                    [group]="tripFormGroup"
                    [errorMessages]="formErrors?.name"
                    name="name"
                ></app-input>
            </form>

            <button
                mat-raised-button
                class="create-trip-button text-uppercase"
                [color]="'primary'"
                (click)="submitCreateTrip()"
            >
                <span [attr.data-i18n-code]="'account.trips.create.label'"></span>
            </button>
        </div>

        <div appTranslate *ngIf="createTripError">
            <div role="alert" class="create-trip-message error flex-container align-items-center">
                {{ createTripError }}
            </div>
        </div>
    </div>

    <div mat-dialog-actions class="flex-container justify-content-center">
        <button mat-stroked-button [color]="'primary'" [mat-dialog-close]="false">
            <span [attr.data-i18n-code]="'core.labels.close'"></span>
        </button>
    </div>
</div>
