<div class="main-content-wrapper">
    <div
        class="footer-links-wrapper padding-top-40 padding-bottom-40 flex-container justify-content-space-between align-items-center"
    >
        <div class="logo-accreditations-wrapper">
            <a
                *ngIf="!logoUrl.startsWith('http')"
                class="logo-wrapper flex-container margin-right-25"
                [attr.aria-label]="siteBranding?.uiConfig?.siteOptions?.siteName"
                [routerLink]="logoUrl"
            >
                <div class="logo responsive-logo"></div>
            </a>

            <a
                *ngIf="logoUrl.startsWith('http')"
                class="logo-wrapper flex-container margin-right-25"
                [attr.href]="logoUrl"
            >
                <div class="logo responsive-logo"></div>
            </a>

            <div
                class="accreditations-wrapper margin-top-20 margin-sm-top-0"
                *ngIf="siteBranding?.uiConfig?.siteOptions?.designOptions?.footer?.accreditations?.length"
            >
                <p
                    class="link accreditations-title ursor-default margin-top-0 margin-bottom-10"
                    [attr.data-i18n-code]="'core.labels.travelAccreditations'"
                ></p>

                <div class="accreditation-images-wrapper flex-container align-items-center">
                    <img
                        *ngFor="let accr of siteBranding?.uiConfig?.siteOptions?.designOptions?.footer?.accreditations"
                        class="accreditation-image"
                        [attr.src]="accr.imageUrl"
                        [attr.alt]="accr.name"
                    />
                </div>
            </div>
        </div>

        <!-- <ul
            class="flex-container flex-wrap hide-below-sm ada-list"
            *ngIf="menuItems && !siteBranding?.uiConfig?.siteOptions?.designOptions?.footer?.hideModules"
        >
            <ng-container *ngFor="let item of menuItems">
                <li class="flex-50 margin-bottom-5">
                    <a class="link footer-link" [routerLink]="url.getUrl(item.code)">
                        <span [attr.data-i18n-code]="'nav.' + item.code + '.label'"></span>
                    </a>
                </li>
            </ng-container>
        </ul> -->

        <div class="footer-links-column-wrapper">
            <div *ngIf="footerOptions.linksColumn_1">
                <p class="links-column-title" [attr.data-i18n-code]="footerOptions.linksColumn_1.title"></p>

                <ul class="footer-static-menu-wrapper flex-container flex-wrap ada-list">
                    <ng-container *ngFor="let link of footerOptions.linksColumn_1?.links">
                        <li class="flex-100 margin-bottom-5">
                            <a
                                class="link footer-link {{ link.cssClass }}"
                                *ngIf="link.customUrl?.startsWith('http')"
                                [attr.target]="link.target"
                                [attr.href]="link.customUrl"
                            >
                                <span [attr.data-i18n-code]="link.name"></span>
                            </a>

                            <a
                                class="link footer-link {{ link.cssClass }}"
                                *ngIf="link.customUrl && !link.customUrl?.startsWith('http')"
                                [routerLink]="link.customUrl || url.getUrl(link.name)"
                            >
                                <span [attr.data-i18n-code]="link.name"></span>
                            </a>

                            <div *ngIf="!link.customUrl" class="link footer-link {{ link.cssClass }}">
                                <span [attr.data-i18n-code]="link.name"></span>
                            </div>
                        </li>
                    </ng-container>
                </ul>
            </div>

            <div *ngIf="footerOptions.linksColumn_2">
                <p class="links-column-title" [attr.data-i18n-code]="footerOptions.linksColumn_2.title"></p>

                <ul class="footer-static-menu-wrapper flex-container flex-wrap ada-list">
                    <ng-container *ngFor="let link of footerOptions.linksColumn_2?.links">
                        <li class="flex-100 margin-bottom-5">
                            <a
                                class="link footer-link {{ link.cssClass }}"
                                *ngIf="link.customUrl?.startsWith('http')"
                                [attr.target]="link.target"
                                [attr.href]="link.customUrl"
                            >
                                <span [attr.data-i18n-code]="link.name"></span>
                            </a>

                            <a
                                class="link footer-link {{ link.cssClass }}"
                                *ngIf="link.customUrl && !link.customUrl?.startsWith('http')"
                                [routerLink]="link.customUrl || url.getUrl(link.name)"
                            >
                                <span [attr.data-i18n-code]="link.name"></span>
                            </a>

                            <div *ngIf="!link.customUrl" class="link footer-link {{ link.cssClass }}">
                                <span [attr.data-i18n-code]="link.name"></span>
                            </div>
                        </li>
                    </ng-container>
                </ul>
            </div>
        </div>
    </div>

    <mat-divider role="" class="divider"></mat-divider>

    <div
        class="footer-social-wrapper padding-top-20 padding-bottom-20 flex-container justify-content-space-between align-items-center"
    >
        <span appTranslate class="link cursor-default copyright">
            <span>&#169; {{ currentYear }} {{ copyright }}</span>
        </span>

        <div
            class="footer-social-icons-wrapper flex-container align-items-center"
            [class.justify-content-center]="
                siteBranding?.uiConfig?.siteOptions?.designOptions?.footer?.accreditations?.length
            "
            *ngIf="siteBranding?.uiConfig?.social?.length"
        >
            <ng-container *ngFor="let social of siteBranding?.uiConfig?.social; index as i">
                <a
                    href="{{ social.url }}"
                    target="_blank"
                    class="footer-icon"
                    [class.margin-right-30]="i < siteBranding?.uiConfig?.social.length - 1"
                >
                    <mat-icon svgIcon="{{ social.target }}" class="social-icon"></mat-icon>
                </a>
            </ng-container>
        </div>

        <ul class="flex-container flex-wrap ada-list" *ngIf="footerOptions.subfooterLinks?.length">
            <ng-container *ngFor="let link of footerOptions.subfooterLinks">
                <li class="margin-left-5">
                    <a
                        class="link footer-link {{ link.cssClass }}"
                        *ngIf="link.customUrl?.startsWith('http')"
                        [attr.target]="link.target"
                        [attr.href]="link.customUrl"
                    >
                        <span [attr.data-i18n-code]="link.name"></span>
                    </a>

                    <a
                        class="link footer-link {{ link.cssClass }}"
                        *ngIf="link.customUrl && !link.customUrl?.startsWith('http')"
                        [routerLink]="link.customUrl || url.getUrl(link.name)"
                    >
                        <span [attr.data-i18n-code]="link.name"></span>
                    </a>

                    <div *ngIf="!link.customUrl" class="link footer-link {{ link.cssClass }}">
                        <span [attr.data-i18n-code]="link.name"></span>
                    </div>
                </li>
            </ng-container>
        </ul>
    </div>
</div>
