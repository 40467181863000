import { AfterViewInit, ContentChild, Directive, ElementRef, EventEmitter, Input, Output } from '@angular/core';

@Directive({
    selector: '[appTrapFocus]',
})
export class TrapFocusDirective implements AfterViewInit {
    @Input()
    focusOnFirst = true;

    @Input()
    findFirstInput = false;

    @Input()
    findFirstClass = false;

    @Input()
    findLastClass = false;

    @Output()
    onEscape: EventEmitter<void> = new EventEmitter<void>();

    constructor(private elementRef: ElementRef) {}

    firstElement: ElementRef;
    @ContentChild('firstFocusableElement', { static: false, read: ElementRef }) set contentFirst(content: ElementRef) {
        if (content) {
            this.firstElement = content;

            if (this.findFirstInput) {
                this.firstElement = {
                    nativeElement: content.nativeElement.querySelector('input'),
                };
            }

            if (this.focusOnFirst) {
                this.firstElement.nativeElement.focus();
            }
        }
    }

    lastElement: ElementRef;
    @ContentChild('lastFocusableElement', { static: false, read: ElementRef }) set contentLast(content: ElementRef) {
        this.lastElement = content;
    }

    ngAfterViewInit(): void {
        if (this.findFirstClass) {
            this.firstElement = {
                nativeElement: this.elementRef.nativeElement.querySelectorAll('.first-focusable-element')[0],
            };
        }

        if (this.findLastClass) {
            const els = this.elementRef.nativeElement.querySelectorAll('.last-focusable-element');
            this.lastElement = {
                nativeElement: els[els.length - 1],
            };
        }

        this.elementRef.nativeElement.addEventListener('keydown', (e) => {
            const isTabPressed = e.key === 'Tab' || e.keyCode === 9;

            if (e.key === 'Escape') {
                this.onEscape.emit();

                return;
            }

            if (!isTabPressed) {
                return;
            }

            if (e.shiftKey) {
                if (document.activeElement === this.firstElement.nativeElement) {
                    this.lastElement.nativeElement.focus();
                    e.preventDefault();
                }
            } else {
                if (document.activeElement === this.lastElement.nativeElement) {
                    this.firstElement.nativeElement.focus();
                    e.preventDefault();
                }
            }
        });
    }
}
