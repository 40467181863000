export const pageTitles = {
    landing: 'Air',
    search: 'Air Search Results',
    book: 'Air Passenger & Payment Information',
    bookConfirmation: 'Air Transaction Completed',
    bookConfirmationProfile: 'Air Transaction Confirmation',
    bookReceipt: 'Air Transaction Receipt',
    cancellation: 'Air Cancellation',
    cancellationConfirmation: 'Air Cancellation Confirmation',
};
