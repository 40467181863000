import { AssetValue } from '../asset-value';

export class BaseOffer {
    id: number;
    name: string;
    typeCode: string;
    bypassDetails: boolean;
    searchUrl: string;
    price: AssetValue;
    showTaxInSearch: boolean;
    soldOut: boolean;
    taxIncluded: boolean;
    tax: AssetValue;
}
