import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModuleWidgetDetails } from '@core/models';
import {
    ActivitiesDialogComponent,
    CarsDialogComponent,
    ConciergeDialogComponent,
    CruisesDialogComponent,
    FlightsDialogComponent,
    HotelsDialogComponent,
    LastMinuteBookingDialogComponent,
    RewardsDialogComponent,
    ShoppingDialogComponent,
    WeeksDialogComponent,
} from '@shared/components';

@Component({
    selector: 'app-module-widget',
    templateUrl: './module-widget.component.html',
    styleUrls: ['./module-widget.component.scss'],
})
export class ModuleWidgetComponent {
    @Input()
    widget: ModuleWidgetDetails;

    constructor(private dialog: MatDialog) {}

    openModal(module: string): void {
        let dialog = null;

        switch (module) {
            case 'hotels':
                dialog = HotelsDialogComponent;
                break;
            case 'weeks':
                dialog = WeeksDialogComponent;
                break;
            case 'flights':
                dialog = FlightsDialogComponent;
                break;
            case 'cars':
                dialog = CarsDialogComponent;
                break;
            case 'cruises':
                dialog = CruisesDialogComponent;
                break;
            case 'activities':
                dialog = ActivitiesDialogComponent;
                break;
            case 'rewards':
                dialog = RewardsDialogComponent;
                break;
            case 'shopping':
                dialog = ShoppingDialogComponent;
                break;
            case 'concierge':
                dialog = ConciergeDialogComponent;
                break;
            case 'last-minute-booking':
                dialog = LastMinuteBookingDialogComponent;
                break;
        }

        if (dialog) {
            this.dialog.open(dialog, {
                width: '100%',
                maxWidth: '640px',
            });
        }
    }
}
