import { TripOverview } from '@account/models';
import { AccountService } from '@account/services';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CreateTripDialogResult, DataTemplate, PaginatedSearchResponse } from '@shared/models';
import { I18nService } from '@shared/services';
import { defer, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

import { TripItineraryFormComponent } from './trip-itinerary-form/trip-itinerary-form.component';

@Component({
    selector: 'app-booking-trip-itinerary',
    templateUrl: './booking-trip-itinerary.component.html',
    styleUrls: ['./booking-trip-itinerary.component.scss'],
})
export class BookingTripItineraryComponent implements OnInit, OnDestroy {
    @Output()
    tripId: EventEmitter<number> = new EventEmitter<number>();

    tripsLoading: boolean;
    trips: TripOverview[];
    tripName: string;
    selectedTripId: number;
    showCreateTripMessage: boolean;
    createTripError: string;

    private unsubscribe: Subject<void> = new Subject<void>();

    constructor(private accountService: AccountService, private dialog: MatDialog, public i18nService: I18nService) {}

    ngOnInit(): void {
        defer(() => {
            this.tripsLoading = true;
            // TODO: The "100" here is not very friendly.
            return this.accountService.getTrips(0, 100).pipe(
                takeUntil(this.unsubscribe),
                finalize(() => {
                    this.tripsLoading = false;
                })
            );
        }).subscribe(
            (response: DataTemplate<PaginatedSearchResponse<TripOverview>>) => {
                this.trips = response.data.items;
            },
            (error: string) => {
                console.error(error);
            }
        );
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    triggerTripCreate(): void {
        this.showCreateTripMessage = false;

        this.dialog
            .open(TripItineraryFormComponent, {
                data: {
                    trips: this.trips,
                },
                minWidth: '60rem',
            })
            .afterClosed()
            .subscribe((result: CreateTripDialogResult) => {
                if (result) {
                    this.showCreateTripMessage = true;
                    this.trips.push(new TripOverview(result.id, result.name));
                    this.selectedTripId = result.id;
                }
            });
    }
}
