import { AssetValue, GoogleAnalyticsDetails, Location } from '@shared/models';

export class WeekDetails {
    id: string;
    adultsOnly: boolean;
    address: string;
    fullAddress: string;
    amenities: string[];
    guestRating: number;
    distance: number;
    hasRooms: boolean;
    images: string[];
    imageUrl: string;
    description: string;
    allInclusiveCode: string;
    allInclusiveDesc: string;
    allInclusiveInformation: string;
    allInclusiveFees: FeeItem[];
    urgentInfo: UrgentInfoItem[];
    location: Location;
    name: string;
    noOfNights: number;
    rating: number;
    retailPrice: AssetValue;
    type: string;
    youPay: AssetValue;
    youSave: AssetValue;
    youSavePct: number;

    index: number;
    youSavePercentage: number;
    stars: number[];
    distanceLabel: string;
    price: number;
    guestRatingValue: number;
    mapInfoActive: boolean;
    awayCity: string;
    ga: GoogleAnalyticsDetails;
}

export class FeeItem {
    startDate: string;
    endDate: string;
    unitType: string;
    fee: string;
}

export class UrgentInfoItem {
    type: string;
    name: string;
    enum: EnumItem[];
    table: { header: string[]; rows: RowItem[] };
    text: string;
}

export class RowItem {
    column: string[];
}

class EnumItem {
    id: string;
    name: string;
}
