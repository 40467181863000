import { CardProfile, Elavon3ds, TravelerProfile } from '@shared/models';
import { Entity, Of } from '@utils';

@Entity
export class BookRequest {
    @Of(() => Number, { nullable: true, optional: true })
    tripId?: number = null;

    @Of(() => Number, { nullable: true, optional: true })
    ppId?: number = null;

    @Of(() => CardProfile, { nullable: true, optional: true })
    card?: CardProfile = null;

    @Of(() => Number, { nullable: true, optional: true })
    payPoints?: number = null;

    @Of(() => Number, { nullable: true, optional: true })
    tpId?: number = null;

    @Of(() => TravelerProfile, { nullable: true, optional: true })
    traveler?: TravelerProfile = null;

    @Of(() => [TravelerProfile], { nullable: true, optional: true })
    travelers?: TravelerProfile[] = [];

    @Of(() => Boolean, { nullable: true, optional: true })
    saveTraveler = false;

    @Of(() => Boolean, { nullable: true, optional: true })
    updateTraveler = false;

    @Of(() => Boolean, { nullable: true, optional: true })
    saveCard = false;

    @Of(() => Boolean, { nullable: true, optional: true })
    useCredit = false;

    @Of(() => Elavon3ds, { nullable: true, optional: true })
    elavon3ds?: Elavon3ds = null;

    @Of(() => String)
    rateId: string;

    @Of(() => String)
    specialRequests: string;

    @Of(() => Boolean)
    payByInvoice = false;

    static of = Entity.of<BookRequest>();
}
