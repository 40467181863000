import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { SiteBranding, UserInfo } from '@core/models';
import {
    americanExpressLength,
    americanExpressStartsWith,
    discoverLength,
    discoverStartsWith,
    isNumerical,
    mastercardLength,
    mastercardStartsWith,
    visaLength,
    visaStartsWith,
} from '@shared/form-validators';
import { CardProfile, DataTemplate, SelectOption, SharedStorageKeys } from '@shared/models';
import { FormatCardLabelPipe } from '@shared/pipes';
import {
    CheckDataService,
    FormErrorService,
    I18nRefreshService,
    I18nService,
    LocationsService,
} from '@shared/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

const today = new Date();

@Component({
    selector: 'app-payment-billing-form',
    templateUrl: './payment-billing-form.component.html',
    styleUrls: ['./payment-billing-form.component.scss'],
})
export class PaymentBillingFormComponent implements OnInit, OnDestroy {
    @Input()
    countries: SelectOption[] = [];

    @Input()
    showCards: boolean;

    @Input()
    hideSaveCheckbox: boolean;

    @Input()
    hideBillingNames: boolean;

    @Input()
    card: CardProfile;

    @Input()
    cardProfiles: CardProfile[] = [];

    @Output()
    invoiceChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

    sharedStorageKeys = SharedStorageKeys;
    siteBranding: SiteBranding;
    userInfo: UserInfo;

    loaded: boolean;
    provinces: SelectOption[] = [];
    cardOptions: SelectOption[] = [];
    originalCard: CardProfile;

    cardMonths: SelectOption[] = [];
    cardYears: SelectOption[] = [];

    newCard = true;
    activeCard: CardProfile;
    activeCardId: number;
    freshCardSwitch: boolean;

    cardFormGroup: UntypedFormGroup;
    formErrors = {
        ccType: null,
        ccNumber: null,
        ccNameOnCard: null,
        ccCvv: null,
        ccExpM: null,
        ccExpY: null,
        firstName: null,
        lastName: null,
        email: null,
        address1: null,
        city: null,
        countryId: null,
        provinceId: null,
        provinceName: null,
        postalCode: null,
        company: null,
        contactName: null,
    };
    validationMessages = {};

    private unsubscribe: Subject<void> = new Subject();

    constructor(
        private locationsService: LocationsService,
        private formErrorService: FormErrorService,
        private checkDataService: CheckDataService,
        private formatCardLabelPipe: FormatCardLabelPipe,
        private i18nService: I18nService,
        private i18nRefreshService: I18nRefreshService
    ) {}

    ngOnInit(): void {
        this.userInfo = JSON.parse(localStorage.getItem(this.sharedStorageKeys.USER_INFO) || null) as UserInfo;
        this.siteBranding = JSON.parse(localStorage.getItem(this.sharedStorageKeys.SITE_BRANDING)) as SiteBranding;

        this.i18nRefreshService
            .getState()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.getValidationMessages(true);
            });

        if (this.siteBranding) {
            this.siteBranding?.uiConfig?.localeOptions?.cardTypes.forEach((card) => {
                this.cardOptions.push({
                    value: card,
                    viewValue: this.formatCardLabelPipe.transform(card),
                });
            });
        }

        for (let i = 1; i <= 12; i++) {
            this.cardMonths.push({
                value: i,
                viewValue: i.toString(),
            });
        }

        for (let i = 0; i <= 9; i++) {
            this.cardYears.push({
                value: today.getFullYear() + i,
                viewValue: (today.getFullYear() + i).toString(),
            });
        }

        this.getValidationMessages();
        this.populateContent();
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    populateContent(): void {
        this.loaded = true;
        this.createCardForm();
    }

    setActiveCard(card: CardProfile): void {
        this.cardProfiles.forEach((t) => (t.active = false));

        if (card) {
            this.newCard = false;
            card.active = true;

            this.activeCard = card;
            this.activeCardId = card.id;
            this.freshCardSwitch = true;

            this.cardFormGroup.reset();
            this.cardFormGroup.patchValue(card);

            if (!card.ccNumber) {
                this.cardFormGroup.get('ccNumber').setValue(`**** **** **** ${card.ccLast4}`);
                this.cardFormGroup.get('ccCvv').setValue(`****`);
            }
        } else {
            this.newCard = true;
            this.cardFormGroup.reset();
        }

        this.checkDataService.cardProfile.emit(this.newCard ? this.cardFormGroup : this.activeCardId);
    }

    createCardForm(): void {
        this.checkDataService.checkData.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
            this.checkData();
        });

        this.cardFormGroup = new UntypedFormGroup({
            ccType: new UntypedFormControl(null, [Validators.required]),
            ccNumber: new UntypedFormControl(null, []),
            ccNameOnCard: new UntypedFormControl(null, [Validators.required]),
            ccCvv: new UntypedFormControl(null, [isNumerical(), Validators.minLength(3), Validators.maxLength(4)]),
            ccExpM: new UntypedFormControl(null, [Validators.required]),
            ccExpY: new UntypedFormControl(null, [Validators.required]),
            company: new UntypedFormControl(null, []),
            contactName: new UntypedFormControl(null, []),
            firstName: new UntypedFormControl(null, [Validators.required]),
            lastName: new UntypedFormControl(null, [Validators.required]),
            email: new UntypedFormControl(null, []),
            address1: new UntypedFormControl(null, [Validators.required]),
            city: new UntypedFormControl(null, [Validators.required]),
            countryId: new UntypedFormControl(null, [Validators.required]),
            provinceId: new UntypedFormControl(null, [Validators.required]),
            provinceName: new UntypedFormControl(null, [Validators.required]),
            postalCode: new UntypedFormControl(null, [Validators.required]),
            saveCard: new UntypedFormControl(false),
            payByInvoice: new UntypedFormControl(false),
            hideDetails: new UntypedFormControl(false),
        });

        this.cardFormGroup.valueChanges
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() =>
                this.formErrorService.checkForErrors(this.cardFormGroup, this.formErrors, this.validationMessages)
            );

        this.cardFormGroup.get('ccType').valueChanges.subscribe((ccType) => {
            switch (ccType) {
                case 'MAST':
                    this.cardFormGroup
                        .get('ccNumber')
                        .setValidators([
                            Validators.required,
                            mastercardStartsWith(),
                            mastercardLength(),
                            isNumerical(),
                        ]);
                    break;
                case 'AMEX':
                    this.cardFormGroup
                        .get('ccNumber')
                        .setValidators([
                            Validators.required,
                            americanExpressLength(),
                            americanExpressStartsWith(),
                            isNumerical(),
                        ]);
                    break;
                case 'VISA':
                    this.cardFormGroup
                        .get('ccNumber')
                        .setValidators([Validators.required, visaLength(), visaStartsWith(), isNumerical()]);
                    break;

                case 'DISC':
                    this.cardFormGroup
                        .get('ccNumber')
                        .setValidators([Validators.required, discoverLength(), discoverStartsWith(), isNumerical()]);
                    break;
            }

            this.cardFormGroup.controls['ccNumber'].updateValueAndValidity();
            this.cardFormGroup.updateValueAndValidity();
        });

        let prevCountry = null;
        this.cardFormGroup.get('countryId').valueChanges.subscribe((val: number) => {
            if (val !== prevCountry) {
                prevCountry = val;

                // if (!this.cardFormGroup.get('payByInvoice').value) {
                this.loadProvinces(val);
                // }
            }
        });

        this.cardFormGroup.get('ccExpY').valueChanges.subscribe((val: number) => {
            this.cardMonths = [];
            for (let i = 1; i <= 12; i++) {
                if (val === today.getFullYear() && i <= today.getMonth()) {
                    continue;
                }

                this.cardMonths.push({
                    value: i,
                    viewValue: i.toString(),
                });
            }
        });
    }

    loadProvinces(countryId: number): void {
        this.provinces = [];

        if (this.newCard) {
            this.cardFormGroup.get('provinceName').setValidators(Validators.required);
        }

        if (countryId) {
            this.locationsService
                .getProvinces(countryId)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe(
                    (response: DataTemplate<{ [key: number]: string }>) => {
                        for (const key in response.data) {
                            if (response.data.hasOwnProperty(parseInt(key, 10))) {
                                this.provinces.push({
                                    value: parseInt(key, 10),
                                    viewValue: response.data[key],
                                });
                            }
                        }

                        if (!this.provinces.length) {
                            this.cardFormGroup.get('provinceId').setValidators(null);
                            this.cardFormGroup.get('provinceId').setValue(null);
                            this.cardFormGroup.get('provinceName').setValidators(Validators.required);

                            if (!this.newCard && this.freshCardSwitch) {
                                this.freshCardSwitch = false;
                                this.cardFormGroup.get('provinceName').setValue(this.activeCard?.provinceName);
                            }
                        } else {
                            this.cardFormGroup.get('provinceId').setValidators(Validators.required);
                            this.cardFormGroup.get('provinceId').setValue(null);
                            this.cardFormGroup.get('provinceName').setValidators(null);
                            this.cardFormGroup.get('provinceName').setValue(null);

                            if (!this.newCard && this.freshCardSwitch) {
                                this.freshCardSwitch = false;
                                this.cardFormGroup.get('provinceId').setValue(this.activeCard?.provinceId);
                            }
                        }
                    },
                    (error: string) => {
                        console.error(error);
                    }
                );
        }
    }

    updatePayByInvoice(event: MatRadioChange): void {
        this.invoiceChanged.emit(event.value);

        this.cardFormGroup.clearValidators();

        if (event.value) {
            ['ccType', 'ccNumber', 'ccNameOnCard', 'ccCvv', 'ccExpM', 'ccExpY', 'firstName', 'lastName'].forEach(
                (field: string) => {
                    this.cardFormGroup.get(field).setValidators([]);
                    this.cardFormGroup.get(field).setErrors(null);
                    this.cardFormGroup.get(field).clearValidators();
                    this.cardFormGroup.get(field).updateValueAndValidity();
                }
            );

            this.cardFormGroup.get('company').setValidators([Validators.required]);
            this.cardFormGroup.get('contactName').setValidators([Validators.required]);

            this.cardFormGroup
                .get('company')
                .setValue(this.siteBranding?.uiConfig?.billingOptions?.invoiceBillingContact?.companyName);
            this.cardFormGroup
                .get('contactName')
                .setValue(this.siteBranding?.uiConfig?.billingOptions?.invoiceBillingContact?.contactName);
            this.cardFormGroup
                .get('email')
                .setValue(this.siteBranding?.uiConfig?.billingOptions?.invoiceBillingContact?.email);
            this.cardFormGroup
                .get('address1')
                .setValue(this.siteBranding?.uiConfig?.billingOptions?.invoiceBillingContact?.billingAddress);
            this.cardFormGroup
                .get('city')
                .setValue(this.siteBranding?.uiConfig?.billingOptions?.invoiceBillingContact?.billingCity);
            this.cardFormGroup
                .get('countryId')
                .setValue(this.siteBranding?.uiConfig?.billingOptions?.invoiceBillingContact?.billingCountry);
            this.cardFormGroup
                .get('provinceId')
                .setValue(this.siteBranding?.uiConfig?.billingOptions?.invoiceBillingContact?.billingStateProvince);
            this.cardFormGroup
                .get('provinceName')
                .setValue(this.siteBranding?.uiConfig?.billingOptions?.invoiceBillingContact?.billingStateProvince);
            this.cardFormGroup
                .get('postalCode')
                .setValue(this.siteBranding?.uiConfig?.billingOptions?.invoiceBillingContact?.billingPostalCode);

            this.cardFormGroup.get('hideDetails').setValue(true);
        } else {
            ['ccType', 'ccNameOnCard', 'ccCvv', 'ccExpM', 'ccExpY', 'firstName', 'lastName'].forEach(
                (field: string) => {
                    this.cardFormGroup.get(field).setValidators([Validators.required]);
                    this.cardFormGroup.get(field).updateValueAndValidity();
                }
            );
            this.cardFormGroup.get('ccNumber').setValidators([Validators.required, Validators.maxLength(16)]);
            this.cardFormGroup.get('ccNumber').updateValueAndValidity();

            ['company', 'contactName'].forEach((field: string) => {
                this.cardFormGroup.get(field).setValidators([]);
                this.cardFormGroup.get(field).setErrors(null);
                this.cardFormGroup.get(field).clearValidators();
                this.cardFormGroup.get(field).updateValueAndValidity();
            });

            [
                'firstName',
                'lastName',
                'address1',
                'city',
                'countryId',
                'provinceId',
                'provinceName',
                'postalCode',
            ].forEach((field: string) => {
                this.cardFormGroup.get(field).setValue(null);
            });

            this.cardFormGroup.get('hideDetails').setValue(false);
        }

        this.cardFormGroup.updateValueAndValidity();
    }

    checkData(): void {
        if (this.hideBillingNames) {
            const namesArr = this.cardFormGroup.get('ccNameOnCard').value?.split(' ');

            this.cardFormGroup.get('firstName').setValue(namesArr?.length ? namesArr[0] : '');
            this.cardFormGroup
                .get('lastName')
                .setValue(
                    namesArr?.length
                        ? namesArr.length > 0
                            ? namesArr.map((n: string, i: number) => (i > 0 ? n : '')).join(' ')
                            : namesArr[0]
                        : ''
                );
        }

        this.formErrorService.markAsDirty(this.cardFormGroup);
        this.formErrorService.checkForErrors(this.cardFormGroup, this.formErrors, this.validationMessages);

        if (this.newCard) {
            if (this.cardFormGroup.valid) {
                this.checkDataService.cardProfile.emit(this.cardFormGroup);
            } else {
                this.checkDataService.cardProfile.emit(false);
            }
        } else {
            this.checkDataService.cardProfile.emit(this.activeCardId);
        }
    }

    getValidationMessages(swapValidationMessages = false): void {
        this.i18nService
            .preloadKeys(this.i18nService.getBillingFormValidationKeys())
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                const keys = this.i18nService.getSavedKeys();

                this.validationMessages = {
                    ccType: {
                        required: keys['core.errors.ccTypeRequired'],
                    },
                    ccNumber: {
                        required: keys['core.errors.ccNumberRequired'],
                        invalid: keys['core.errors.ccNumberInvalid'],
                    },
                    ccNameOnCard: {
                        required: keys['core.errors.ccNameOnCardRequired'],
                    },
                    ccCvv: {
                        required: keys['core.errors.ccCvvRequired'],
                        maxlength: keys['core.errors.ccCvvMaxLength'],
                        minlength: keys['core.errors.ccCvvMinLength'],
                        invalid: keys['core.errors.ccCvvInvalid'],
                    },
                    ccExpM: {
                        required: keys['core.errors.ccExpMRequired'],
                    },
                    ccExpY: {
                        required: keys['core.errors.ccExpYRequired'],
                    },
                    firstName: {
                        required: keys['core.errors.firstNameRequired'],
                    },
                    lastName: {
                        required: keys['core.errors.lastNameRequired'],
                    },
                    address1: {
                        required: keys['core.errors.address1Required'],
                    },
                    city: {
                        required: keys['core.errors.cityRequired'],
                    },
                    countryId: {
                        required: keys['core.errors.countryIdRequired'],
                    },
                    provinceId: {
                        required: keys['core.errors.provinceIdRequired'],
                    },
                    provinceName: {
                        required: keys['core.errors.provinceNameRequired'],
                    },
                    postalCode: {
                        required: keys['core.errors.postalCodeRequired'],
                    },
                    company: {
                        required: keys['core.errors.invoiceCompanyRequired'],
                    },
                    contactName: {
                        required: keys['core.errors.invoiceContactNameRequired'],
                    },
                };

                if (swapValidationMessages) {
                    this.cardFormGroup.clearValidators();
                    this.cardFormGroup.updateValueAndValidity();
                }
            });
    }
}
