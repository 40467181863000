<div
    class="margin-right-30"
    (clickOutside)="showPhones = false"
    *ngIf="siteBranding?.uiConfig?.siteOptions?.helpPhones?.length > 0"
>
    <button
        class="position-relative phones-dropdown cursor-pointer ada-button width-100"
        aria-label="Change language"
        [class.on-login]="onLogin"
        (click)="showPhones = !showPhones"
    >
        <mat-icon svgIcon="phone" class="select-icon" [class.on-login]="onLogin"></mat-icon>

        <div class="phones pop-up-menu" [class.hide]="!showPhones">
            <ul class="phones-list ada-list">
                <ng-container *ngFor="let phone of siteBranding?.uiConfig?.siteOptions?.helpPhones">
                    <li>
                        <a href="tel:{{ phone.phoneNumber }}">
                            <button class="phone text-capitalize text-left cursor-pointer ada-button width-100">
                                {{ phone.label[activeLanguage] }}
                            </button>
                        </a>
                    </li>
                </ng-container>
            </ul>
        </div>
    </button>
</div>
