import { Injectable, OnDestroy } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { SnackbarComponent } from '@core/components/snackbar/snackbar.component';
import { SnackbarAction } from '@core/models';
import { Subject } from 'rxjs';

const snackBarPriority = {
    low: 5000,
    medium: 10000,
    long: 20000,
    forever: Infinity,
};

@Injectable()
export class SnackBarService implements OnDestroy {
    private messageQueue: any[] = [];
    private isInstanceVisible = false;
    private unsubscribe: Subject<void> = new Subject();

    constructor(private snackBar: MatSnackBar, private router: Router) {}

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    error(message: string, actionText = 'Dismiss', config?: MatSnackBarConfig, action?: SnackbarAction): void {
        let conf: MatSnackBarConfig = new MatSnackBarConfig();
        conf.duration = snackBarPriority.medium;
        conf.verticalPosition = 'top';
        conf.horizontalPosition = 'center';

        conf = Object.assign(conf, config || {});

        conf.data = { message, actionText, action };

        this.messageQueue.push({ config: conf, action });

        if (!this.isInstanceVisible) {
            this.showNext();
        }
    }

    /**
     * Creates an instance of MatSnackBar
     * @param message
     * The text content of the snackbar
     * @param actionText
     * The text of the action button. If left empty, a close icon is displayed.
     * @param config
     * The MatSnackBar config object. If no configuration is passed, the default options are applied.
     * @param action
     * An object of type SnackbarAction with 2 properties: `action` & `url`.
     * `action` should be a method that gets executed if the snackbar's button is clicked.
     * `url` is used to navigate the page after the snackbar closes.
     * This object will be ignored if no `actionText` is passed in.
     * @see showNext()
     */
    open(message: string, actionText?: string, config?: MatSnackBarConfig, action?: SnackbarAction): void {
        if (!config) {
            config = new MatSnackBarConfig();
            config.duration = snackBarPriority.low;
            config.verticalPosition = 'bottom';
            config.horizontalPosition = 'center';
        }

        config.data = { message, actionText, action };

        this.messageQueue.push({ config, action });

        if (!this.isInstanceVisible) {
            this.showNext();
        }
    }

    /**
     * Manages the snackbar message queue
     */
    private showNext() {
        if (this.messageQueue.length !== 0) {
            const message = this.messageQueue.shift();
            this.isInstanceVisible = true;

            this.snackBar
                .openFromComponent(SnackbarComponent, message.config)
                .afterDismissed()
                .subscribe(() => {
                    if (message.action && message.action.url) {
                        this.router.navigateByUrl(message.action.url);
                    }
                    this.isInstanceVisible = false;
                    this.showNext();
                });
        }
    }
}
