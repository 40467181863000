import { Entity, Of } from '@utils';

@Entity
export class TripUpdateRequest {
    @Of(() => String, { optional: true })
    name?: string;

    @Of(() => [Number], { optional: true })
    items?: number[];

    static of = Entity.of<TripUpdateRequest>();
}
