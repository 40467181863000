<ul class="flex-container align-items-center ada-list">
    <li
        appTranslate
        (clickOutside)="showLanguages = false"
        [class.margin-right-30]="!onLogin"
        [class.margin-sm-right-15]="!onLogin"
        [class.on-login]="onLogin"
        *ngIf="
            siteBranding?.uiConfig?.siteOptions.designOptions.header.showLanguages &&
            siteBranding?.uiConfig?.localeOptions?.languages?.length > 1
        "
    >
        <button
            class="position-relative select-language-dropdown cursor-pointer ada-button width-100"
            aria-label="Change language"
            [class.on-login]="onLogin"
            (click)="showLanguages = !showLanguages"
        >
            <span class="text-uppercase">{{ activeLanguage }}</span>
            <mat-icon svgIcon="chevron-down" class="select-icon margin-left-5 margin-top-5"></mat-icon>

            <div class="languages pop-up-menu" [class.hide]="!showLanguages">
                <ul class="locales-list ada-list">
                    <ng-container *ngFor="let language of siteBranding?.uiConfig?.localeOptions?.languages">
                        <li>
                            <button
                                class="language text-capitalize cursor-pointer ada-button width-100"
                                [class.active]="language == activeLanguage"
                                (click)="switchLanguage(language)"
                                [attr.data-i18n-code]="'core.languages.' + language"
                            ></button>
                        </li>
                    </ng-container>
                </ul>
            </div>
        </button>
    </li>

    <li
        class="margin-right-30 margin-sm-right-15"
        [attr.aria-expanded]="showCurrencies"
        (clickOutside)="showCurrencies = false"
        *ngIf="
            !onLogin &&
            siteBranding?.uiConfig.siteOptions.designOptions.header.showCurrencies &&
            siteBranding?.uiConfig?.localeOptions?.currencies?.length > 1
        "
    >
        <button
            class="position-relative select-currency-dropdown cursor-pointer ada-button width-100"
            aria-label="Change currency"
            [class.on-login]="onLogin"
            (click)="showCurrencies = !showCurrencies"
        >
            <span class="text-uppercase">{{ activeCurrency }}</span>
            <mat-icon svgIcon="chevron-down" class="select-icon margin-left-5 margin-top-5"></mat-icon>
            <div class="currencies pop-up-menu" [class.hide]="!showCurrencies">
                <ul class="locales-list ada-list">
                    <ng-container *ngFor="let currency of siteBranding?.uiConfig?.localeOptions?.currencies">
                        <li>
                            <button
                                class="currency cursor-pointer ada-button width-100"
                                [class.active]="currency == activeCurrency"
                                (click)="switchCurrency(currency)"
                                [attr.data-i18n-code]="'core.currencies.' + currency"
                            ></button>
                        </li>
                    </ng-container>
                </ul>
            </div>
        </button>
    </li>

    <li
        class="position-relative margin-right-30 hide-below-xs"
        *ngIf="!onLogin && siteBranding?.uiConfig?.pointsOptions?.rewardsActive"
    >
        <a
            [routerLink]="url.getUrl('rewards')"
            class="link header-link"
            [class.active]="navTarget === 'rewards'"
            *ngIf="!isModalModules"
        >
            <span [attr.data-i18n-code]="'nav.rewards.label'"></span>
        </a>

        <a class="link header-link" (click)="openRewardsDialog()" *ngIf="isModalModules">
            <span [attr.data-i18n-code]="'nav.rewards.label'"></span>
        </a>

        <div class="active-marker" [class.show]="navTarget === 'rewards'"></div>
    </li>
</ul>
