import { SearchRequest } from '../request/search-request';

import { EscapeDestination } from './escape-destination';
import { SearchFilters } from './search-filters';

export class SavedSearch {
    items: EscapeDestination[];
    searchParams: SearchRequest;
    filters: SearchFilters;
}
