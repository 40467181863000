<section appTranslate id="upgrade" class="upgrade-wrapper">
    <div class="upgrade-header-wrapper position-relative flex-container">
        <div class="overlay-shadow"></div>
        <div
            class="main-content-wrapper flex-container align-items-center flex-direction-column justify-content-center"
        >
            <h1 class="text text-xl" [attr.data-i18n-codeE]="'upgrade.landing.header.heroText'">
                Sly otter guides me,
            </h1>

            <h1 class="text text-xl" [attr.data-i18n-codeE]="'upgrade.landing.header.heroText'">
                Frugal paths through lands afar,
            </h1>

            <h1 class="text text-xl" [attr.data-i18n-codeE]="'upgrade.landing.header.heroText'">
                Coins saved, journeys flow.
            </h1>

            <div class="margin-top-90">
                <button mat-raised-button [color]="'primary'" (click)="navigateToUpgradeTable()">Upgrade Now</button>
            </div>

            <div class="text text-s margin-top-90" [attr.data-i18n-codeE]="'upgrade.landing.header.heroSubtext'">
                Explore the Benefits of Traveling with The Otter
            </div>
        </div>
    </div>

    <section class="upgrade-content-wrapper">
        <section class="dashed-content">
            <div class="dashed-line-wrapper flex-container flex-direction-column align-items-center">
                <div class="dash-line" *ngFor="let number of [1, 2, 3]"></div>
            </div>
            <div
                class="flex-container align-items-center flex-direction-column justify-content-center margin-bottom-50"
            >
                <!-- <div
                    class="text text-s margin-bottom-30 margin-top-100"
                    [attr.data-i18n-codeE]="'upgrade.landing.content.colored.subtitle'"
                >
                    It is faster than a check-in!
                </div> -->

                <div class="text text-l margin-top-50" [attr.data-i18n-codeE]="'upgrade.landing.content.colored.title'">
                    As a member of the Traveling Otter you gain access to exclusive travel deals that can save you 50%
                    or more on travel.
                </div>
            </div>
        </section>

        <section class="how-it-works-content">
            <div
                class="margin-bottom-30 text text-xl-bold"
                [attr.data-i18n-codeE]="'upgrade.landing.content.howItWorks.title'"
            >
                How it works
            </div>
            <div
                class="text text-s-normal margin-bottom-100"
                [attr.data-i18n-codeE]="'upgrade.landing.content.howItWorks.subtitle'"
            >
                The Traveling Otter is a membership to a top-tier wholesale travel network with access to deals you will
                not find anywhere else. On average, the discounts on travel, airfare, hotels, and cars can be as high as
                50% off. In some cases they are as high as 81% off.
            </div>

            <div class="benefits-content">
                <div class="benefit-content">
                    <mat-icon class="benefit-icon" [svgIcon]="'adult'"></mat-icon>
                    <div class="text-m" [attr.data-i18n-codeE]="'upgrade.landing.content.benefits.benefit1'">
                        By joining The Traveling Otter, you spend just a little with recurring membership to get
                        dramatic deals on your travel. In many cases the membership pays for itself in one vacation.
                    </div>
                </div>

                <div class="benefit-content">
                    <mat-icon class="benefit-icon" [svgIcon]="'ticket'"></mat-icon>
                    <div class="text-m" [attr.data-i18n-codeE]="'upgrade.landing.content.benefits.benefit2'">
                        By booking through The Traveling Otter, we give you a percent back in credit to your account
                        that can be redeemed later for airfare, hotels and rental cars.
                    </div>
                </div>

                <div class="benefit-content">
                    <mat-icon class="benefit-icon" [svgIcon]="'hotel'"></mat-icon>
                    <div class="text-m" [attr.data-i18n-codeE]="'upgrade.landing.content.benefits.benefit3'">
                        We have over two million properties in our database and you’re welcome to browse the website
                        before purchasing. It also works for airfare, rental cars, and more.
                    </div>
                </div>

                <!-- <div class="benefit-content">
                    <mat-icon class="benefit-icon" [svgIcon]="'car'"></mat-icon>
                    <div class="text-m" [attr.data-i18n-codeE]="'upgrade.landing.content.benefits.benefit3'">
                        Everything can be adapted to yours needs
                    </div>
                </div> -->
            </div>

            <div class="page-anchor" id="upgrade-table"></div>

            <div class="table-content">
                <div
                    class="margin-bottom-30 text text-xl-bold"
                    [attr.data-i18n-codeE]="'upgrade.landing.content.table.title'"
                >
                    Match your membership to your needs
                </div>
                <div
                    class="text text-s-normal margin-bottom-50"
                    [attr.data-i18n-codeE]="'upgrade.landing.content.table.subtitle'"
                >
                    Find our benefits breakdown, by tier
                </div>

                <div class="benefits-wrapper">
                    <div class="benefits-table">
                        <div>
                            <div
                                class="title text-s-normal"
                                [attr.data-i18n-codeE]="'upgrade.landing.content.table.header.item1'"
                            >
                                Benefits
                            </div>

                            <div
                                class="cell text-xs-thin"
                                [attr.data-i18n-codeE]="'upgrade.landing.content.table.row1.item1'"
                            >
                                Sign-Up Credit Bonus
                            </div>

                            <div
                                class="cell text-xs-thin"
                                [attr.data-i18n-codeE]="'upgrade.landing.content.table.row1.item1'"
                            >
                                Cash Back on Dues
                            </div>

                            <div
                                class="cell text-xs-thin"
                                [attr.data-i18n-codeE]="'upgrade.landing.content.table.row2.item1'"
                            >
                                Cash Back on Bookings
                            </div>

                            <div
                                class="cell text-xs-thin"
                                [attr.data-i18n-codeE]="'upgrade.landing.content.table.row3.item1'"
                            >
                                Free Hotel Stay
                            </div>

                            <div
                                class="cell text-xs-thin"
                                [attr.data-i18n-codeE]="'upgrade.landing.content.table.row4.item1'"
                            >
                                VIP Concierge Service
                            </div>

                            <div
                                class="cell text-xs-thin"
                                [attr.data-i18n-codeE]="'upgrade.landing.content.table.row5.item1'"
                            >
                                Sign Up Fee
                            </div>

                            <div
                                class="cell text-xs-thin"
                                [attr.data-i18n-codeE]="'upgrade.landing.content.table.row6.item1'"
                            >
                                Cost Per Year
                            </div>
                        </div>

                        <div class="silver has-divider">
                            <div
                                class="title text-s-normal"
                                [attr.data-i18n-codeE]="'upgrade.landing.content.table.header.item2'"
                            >
                                River Otter
                            </div>

                            <div
                                class="cell text-xs-normal"
                                [attr.data-i18n-codeE]="'upgrade.landing.content.table.row1.item2'"
                            >
                                $100 USD
                            </div>

                            <div
                                class="cell text-xs-normal"
                                [attr.data-i18n-codeE]="'upgrade.landing.content.table.row2.item2'"
                            >
                                2%
                            </div>

                            <div
                                class="cell text-xs-normal"
                                [attr.data-i18n-codeE]="'upgrade.landing.content.table.row2.item2'"
                            >
                                --
                            </div>

                            <div
                                class="cell text-xs-normal"
                                [attr.data-i18n-codeE]="'upgrade.landing.content.table.row3.item2'"
                            >
                                --
                            </div>

                            <div
                                class="cell text-xs-normal"
                                [attr.data-i18n-codeE]="'upgrade.landing.content.table.row4.item2'"
                            >
                                --
                            </div>

                            <div
                                class="cell text-xs-normal"
                                [attr.data-i18n-codeE]="'upgrade.landing.content.table.row5.item2'"
                            >
                                <p class="margin-0">$299 USD</p>
                                <p class="margin-0 disclaimer-text">(one-time fee)</p>
                            </div>

                            <div
                                class="cell text-xs-normal"
                                [attr.data-i18n-codeE]="'upgrade.landing.content.table.row6.item2'"
                            >
                                <p class="margin-0">$99 USD</p>
                                <p class="margin-0 disclaimer-text">(billed annually)</p>
                            </div>

                            <button
                                mat-raised-button
                                [color]="'primary'"
                                class="tier-button silver-button"
                                [attr.data-i18n-codeE]="'core.labels.upgradeNow'"
                                (click)="handleCheckout('silver')"
                            >
                                Upgrade now
                            </button>
                        </div>

                        <div class="gold">
                            <div
                                class="title text-s-normal"
                                [attr.data-i18n-codeE]="'upgrade.landing.content.table.header.item3'"
                            >
                                Sea Otter
                            </div>

                            <div
                                class="cell text-xs-normal"
                                [attr.data-i18n-codeE]="'upgrade.landing.content.table.row1.item3'"
                            >
                                $150 USD
                            </div>

                            <div
                                class="cell text-xs-normal"
                                [attr.data-i18n-codeE]="'upgrade.landing.content.table.row2.item3'"
                            >
                                4%
                            </div>

                            <div
                                class="cell text-xs-normal"
                                [attr.data-i18n-codeE]="'upgrade.landing.content.table.row2.item3'"
                            >
                                --
                            </div>

                            <div class="cell" [attr.data-i18n-codeE]="'upgrade.landing.content.table.row3.item3'">
                                <p class="text-xs-normal margin-bottom-5">One 3 Day, 2 Night Stay</p>
                                <a [routerLink]="'/terms'" class="restrictions-link">*restrictions apply</a>
                            </div>

                            <div
                                class="cell text-xs-normal"
                                [attr.data-i18n-codeE]="'upgrade.landing.content.table.row4.item3'"
                            >
                                Included
                            </div>

                            <div
                                class="cell text-xs-normal"
                                [attr.data-i18n-codeE]="'upgrade.landing.content.table.row5.item3'"
                            >
                                <p class="margin-0">$599 USD</p>
                                <p class="margin-0 disclaimer-text">(one-time fee)</p>
                            </div>

                            <div
                                class="cell text-xs-normal"
                                [attr.data-i18n-codeE]="'upgrade.landing.content.table.row6.item3'"
                            >
                                <p class="margin-0">$29.99 USD</p>
                                <p class="margin-0 disclaimer-text">(billed monthly)</p>
                            </div>

                            <button
                                mat-raised-button
                                [color]="'primary'"
                                class="tier-button gold-button"
                                [attr.data-i18n-codeE]="'core.labels.upgradeNow'"
                                (click)="handleCheckout('gold')"
                            >
                                Upgrade now
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="questions-content">
                <div
                    class="margin-bottom-30 text text-xl-bold"
                    [attr.data-i18n-codeE]="'upgrade.landing.content.questions.title'"
                >
                    Got any questions?
                </div>
                <div
                    class="text text-s-normal margin-bottom-50"
                    [attr.data-i18n-codeE]="'upgrade.landing.content.questions.subtitle'"
                >
                    We are here for you!
                </div>

                <div>
                    <div
                        class="margin-bottom-15 text text-m question"
                        [attr.data-i18n-codeE]="'upgrade.landing.content.questions.question1'"
                    >
                        How do I receive my birthday offer?
                    </div>
                    <div
                        class="text text-xs-normal margin-bottom-50 margin-xs-bottom-15 answear"
                        [attr.data-i18n-codeE]="'upgrade.landing.content.questions.answear1'"
                    >
                        You will get an email with a special offer available for you.
                    </div>

                    <div
                        class="margin-bottom-15 text text-m question"
                        [attr.data-i18n-codeE]="'upgrade.landing.content.questions.question2'"
                    >
                        Do all bookings count for coins?
                    </div>
                    <div
                        class="text text-xs-normal margin-bottom-50 margin-xs-bottom-15 answear"
                        [attr.data-i18n-codeE]="'upgrade.landing.content.questions.answear2'"
                    >
                        Yes, you collect coins with every purchase made on the platform.
                    </div>

                    <div
                        class="margin-bottom-15 text text-m question"
                        [attr.data-i18n-codeE]="'upgrade.landing.content.questions.question3'"
                    >
                        What happens to the coins when I cancel a booking?
                    </div>
                    <div
                        class="text text-xs-normal margin-bottom-50 margin-xs-bottom-15 answear"
                        [attr.data-i18n-codeE]="'upgrade.landing.content.questions.answear3'"
                    >
                        The coins will be automatically withdrawn from your wallet when cancelling a booking.
                    </div>

                    <div
                        class="margin-bottom-15 text text-m question"
                        [attr.data-i18n-codeE]="'upgrade.landing.content.questions.question4'"
                    >
                        Do my coins ever expire?
                    </div>
                    <div
                        class="text text-xs-normal margin-bottom-50 margin-xs-bottom-15 answear"
                        [attr.data-i18n-codeE]="'upgrade.landing.content.questions.answear4'"
                    >
                        No, as long as your account is active.
                    </div>
                </div>
            </div> -->
        </section>
    </section>
</section>
