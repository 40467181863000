import { Cruise } from './cruise';

interface Destination {
    id: number;
    name: string;
}

export class SearchResponse {
    items: Cruise[];
    filters: SearchFilters;
}

export class SearchFilters {
    nights: number[];
    lengthOfStay: LengthOfCruise[];
    cruiseLines: CruiseLine[];
    destinations?: Destination[];
    departurePorts?: DeparturePort[];
}

export class CruiseLine {
    constructor(public id: string, public name: string, public active?: boolean) {}
}

export class LengthOfCruise {
    constructor(public id: string, public name: string, public active?: boolean) {}
}

export class DeparturePort {
    constructor(public id: string, public name: string, public active?: boolean) {}
}
