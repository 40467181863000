import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'formatCurrency',
})
export class FormatCurrencyPipe extends CurrencyPipe implements PipeTransform {
    transform(
        value: number | string | null | undefined,
        currencyCode?: string,
        display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean
    ): any {
        const formatedByCurrencyPipe = super.transform(value, currencyCode, display, null);

        if (currencyCode && !formatedByCurrencyPipe.includes(currencyCode)) {
            return `${formatedByCurrencyPipe} ${currencyCode}`;
        }

        return formatedByCurrencyPipe;
    }
}
