<div
    appTranslate
    class="product-loader-wrapper flex-container flex-column justify-content-center align-items-center"
    [class.full-screen]="fullScreen"
>
    <div role="alert" aria-live="polite" aria-label="Performing search - please wait"></div>
    <div class="loader-balls-wrapper flex-container justify-content-space-between align-items-center margin-bottom-30">
        <div class="loader-ball-wrapper flex-container justify-content-center" *ngFor="let ball of loaderBalls">
            <div
                class="loader-ball flex-container justify-content-center align-items-center"
                [class.active]="ball.active"
                [class.transition]="ball.transition"
            >
                <mat-icon [svgIcon]="icon" class="product-icon"></mat-icon>
            </div>
        </div>
    </div>

    <div class="flex-container flex-column align-items-center padding-left-5 padding-right-5">
        <div
            class="loader-text text-center"
            *ngFor="let text of loaderTexts"
            [class.active]="text.active"
            [class.loading]="text.loading"
        >
            <span [attr.data-i18n-code]="text.value"></span>
            <mat-icon [svgIcon]="'check'" class="check-icon margin-left-10"></mat-icon>
        </div>
    </div>
</div>
