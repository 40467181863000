import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SiteBranding } from '@core/models';
import { SharedStorageKeys } from '@shared/models';
import { I18nRefreshService } from '@shared/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-navigation-phones',
    templateUrl: './navigation-phones.component.html',
    styleUrls: ['./navigation-phones.component.scss'],
})
export class NavigationPhonesComponent implements OnInit, OnDestroy {
    @Input()
    onLogin: boolean;

    sharedStorageKeys = SharedStorageKeys;

    siteBranding: SiteBranding;
    showPhones: boolean;
    activeLanguage: string;
    currentLanguage = sessionStorage.getItem(this.sharedStorageKeys.CURRENT_LANGUAGE);

    private unsubscribe: Subject<void> = new Subject();

    constructor(private i18nRefreshService: I18nRefreshService) {}

    ngOnInit(): void {
        this.siteBranding = JSON.parse(localStorage.getItem(this.sharedStorageKeys.SITE_BRANDING));

        if (this.siteBranding?.uiConfig.siteOptions.helpPhones) {
            const keys = Object.keys(this.siteBranding?.uiConfig.siteOptions.helpPhones?.[0].label);

            this.activeLanguage = keys.includes(this.currentLanguage) ? this.currentLanguage : keys?.[0];

            this.i18nRefreshService
                .getState()
                .pipe(takeUntil(this.unsubscribe))
                .subscribe(() => {
                    this.currentLanguage = sessionStorage.getItem(this.sharedStorageKeys.CURRENT_LANGUAGE);
                    this.activeLanguage = keys.includes(this.currentLanguage) ? this.currentLanguage : keys?.[0];
                });
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
