export enum StorageKeys {
    SEARCH_REQUEST = 'weeks_search_request',
    SEARCH_RESPONSE = 'weeks',
    SEARCH_FILTERS = 'weeks_filters',
    SEARCH_FILTERS_MIN_PRICE = 'weeks_min_price',
    SEARCH_FILTERS_MAX_PRICE = 'weeks_max_price',
    SEARCH_ITEM = 'week_search_item',
    ITEM_TO_BOOK = 'week_to_book',
    BOOK_RESPONSE = 'weeks_book_response',
    ROOM_TO_BOOK = 'weeks_room_to_book',
    RATE_TO_BOOK = 'weeks_rate_to_book',
}
