<div
    appTranslate
    id="payment-card"
    class="card-profile cursor-pointer flex-container height-100"
    [class.primary]="card.isPrimary"
    [class.background-white]="backgroundWhite"
    [class.active]="card.active"
    *ngIf="card; else cardPlaceholder"
>
    <div class="card-grid width-100">
        <div></div>
        <div class="flex-container justify-content-end">
            <img
                [attr.src]="'/assets/images/' + card.ccType + '.svg'"
                class="card-image margin-bottom-30 {{ card.ccType }}"
            />
        </div>

        <div class="flex-container flex-column justify-content-space-between">
            <div class="card-number flex-container margin-bottom-20">
                <span class="bold padding-top-5 margin-right-10">****</span>
                <span class="bold padding-top-5 margin-right-10">****</span>
                <span class="bold padding-top-5 margin-right-10">****</span>
                <div class="bold">{{ card.ccLast4 }}</div>
            </div>
            <div class="text-left">
                <div class="card-label text-uppercase" [attr.data-i18n-code]="'core.labels.cardHolder'"></div>
                <div class="card-value">{{ card.ccNameOnCard }}</div>
            </div>
        </div>
        <div class="flex-container flex-column justify-content-end">
            <div class="card-label text-uppercase" [attr.data-i18n-code]="'core.labels.cardExpires'"></div>
            <div class="card-value">{{ card.ccExpM }}/{{ card.ccExpY }}</div>
        </div>
    </div>
</div>

<ng-template #cardPlaceholder>
    <div
        appTranslate
        id="payment-card-placeholder"
        class="card-profile cursor-pointer flex-container flex-column justify-content-center height-100"
        [class.background-white]="backgroundWhite"
        [class.active]="newCard"
    >
        <div class="card-grid height-100">
            <div></div>
            <div class="flex-container align-items-flex-start">
                <div class="card-image-placeholder margin-bottom-40"></div>
            </div>

            <div class="flex-container flex-column justify-content-space-between">
                <p class="card-number margin-bottom-20">
                    + <span [attr.data-i18n-code]="'core.labels.newCard'"></span>
                </p>
                <div>
                    <div class="card-label-placeholder margin-bottom-5"></div>
                    <div class="card-value-placeholder name-placeholder"></div>
                </div>
            </div>
            <div class="flex-container flex-column justify-content-end">
                <div class="card-label-placeholder margin-bottom-5"></div>
                <div class="card-value-placeholder expiry-placeholder"></div>
            </div>
        </div>
    </div>
</ng-template>
