import * as _ from 'lodash';

export enum FilterTypes {
    SUBSTRING = 'substring',
    RANGE = 'range',
    GROUP = 'group',
    EQUAL = 'equal',
}

export class SortFilters {
    sort?: SortObject;
    filters: { [key: string]: SubstringFilterObject | GroupFilterObject | RangeFilterObject | EqualFilterObject } = {};

    filterBy(collection: any[]): any {
        if (!collection || !this.filters) {
            return collection;
        }

        let result = collection;

        for (const key in this.filters) {
            if (this.filters.hasOwnProperty(key)) {
                switch (this.filters[key].type) {
                    case FilterTypes.SUBSTRING:
                        result = this.filterBySubstring(result, key, this.filters[key] as SubstringFilterObject);
                        break;
                    case FilterTypes.RANGE:
                        result = this.filterByRange(result, key, this.filters[key] as RangeFilterObject);
                        break;
                    case FilterTypes.GROUP:
                        result = this.filterByGroup(result, key, this.filters[key] as GroupFilterObject);
                        break;
                    case FilterTypes.EQUAL:
                        result = this.filterByEqual(result, key, this.filters[key] as EqualFilterObject);
                        break;
                    default:
                        break;
                }
            }
        }

        return result;
    }

    private filterBySubstring(collection: any, column: string, filter: SubstringFilterObject): any[] {
        if (!filter?.value) {
            return collection;
        }

        return _.filter(collection, (item) => {
            const prop = this.getNestedPropertyValue(item, column) as string;

            if (!prop) {
                return false;
            }

            return prop.toLocaleLowerCase().indexOf(filter.value.toLocaleLowerCase()) > -1;
        });
    }

    private filterByRange(collection: any, column: string, filter: RangeFilterObject): any[] {
        if (!filter || (!filter.rangeStart && filter.rangeStart !== 0) || (!filter.rangeEnd && filter.rangeEnd !== 0)) {
            return collection;
        }

        return _.filter(collection, (item) => {
            const prop = this.getNestedPropertyValue(item, column) as number;

            return prop >= filter.rangeStart && prop <= filter.rangeEnd;
        });
    }

    private filterByGroup(collection: any, column: string, filter: GroupFilterObject): any[] {
        if (!filter || !filter.value) {
            return collection;
        }

        const filterGroup = JSON.parse(filter.value);

        if (!filterGroup.length) {
            return collection;
        }

        return _.filter(collection, (item) => {
            const prop = this.getNestedPropertyValue(item, column) as any[];
            let isMatching = true;

            if (!prop) {
                return false;
            }

            if (Array.isArray(prop)) {
                if (filter.isRootValue) {
                    if (!filterGroup.includes(prop)) {
                        isMatching = false;
                    }
                } else {
                    if (
                        !filterGroup.filter((i: any) => {
                            return prop.some((t: any) => {
                                if (i[filter.groupIdentifier] === t[filter.groupIdentifier]) {
                                    return true;
                                }
                            });
                        }).length
                    ) {
                        isMatching = false;
                    }
                }
            } else {
                if (filter.isRootValue) {
                    if (!filterGroup.filter((i: any) => i === prop).length) {
                        isMatching = false;
                    }
                } else {
                    if (
                        !filterGroup.filter((i: any) => i[filter.groupIdentifier] === prop[filter.groupIdentifier])
                            .length
                    ) {
                        isMatching = false;
                    }
                }
            }

            return isMatching;
        });
    }

    private filterByEqual(collection: any, column: string, filter: EqualFilterObject): any[] {
        if (filter?.value === null) {
            return collection;
        }

        return _.filter(collection, (item) => {
            const prop = this.getNestedPropertyValue(item, column) as string;
            return prop === filter.value;
        });
    }

    private getNestedPropertyValue(item: any, column: any): any {
        if (column.includes('.')) {
            const columnArr: any[] = column.split('.');
            return this.getNestedPropertyValue(
                item[columnArr[0]],
                columnArr.length > 2 ? columnArr.slice(1, columnArr.length - 1).join('.') : columnArr[1]
            );
        } else {
            return item?.[column];
        }
    }
}

export class SortObject {
    direction: 'asc' | 'desc';
    column: string;
}

export class FilterObject {
    type: FilterTypes;
    value: string | number;

    constructor(type: FilterTypes) {
        this.type = type;
    }
}

export class SubstringFilterObject extends FilterObject {
    value: string;

    constructor(value: string = null) {
        super(FilterTypes.SUBSTRING);
        this.value = value;
    }
}

export class GroupFilterObject extends FilterObject {
    value: string;
    groupIdentifier: string;
    isRootValue = false;

    constructor(value: string = null, groupIdentifier: string = null, isRootValue = false) {
        super(FilterTypes.GROUP);
        this.value = value;
        this.groupIdentifier = groupIdentifier;
        this.isRootValue = isRootValue;
    }
}

export class RangeFilterObject extends FilterObject {
    rangeStart: number;
    rangeEnd: number;

    constructor(rangeStart = 0, rangeEnd = Infinity) {
        super(FilterTypes.RANGE);
        this.rangeStart = rangeStart;
        this.rangeEnd = rangeEnd;
    }
}

export class EqualFilterObject extends FilterObject {
    value: string | number;

    constructor(value: string | number = null) {
        super(FilterTypes.EQUAL);
        this.value = value;
    }
}

export class SortFiltersEvent {
    sortFilters: SortFilters;
    isReset: boolean;
}
