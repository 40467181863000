export enum StorageKeys {
    SEARCH_REQUEST = 'cars_search_request',
    SEARCH_RESPONSE = 'cars',
    SEARCH_FILTERS = 'cars_filters',
    SEARCH_FILTERS_MIN_PRICE = 'cars_min_price',
    SEARCH_FILTERS_MAX_PRICE = 'cars_max_price',
    ITEM_TO_BOOK = 'car_to_book',
    BOOK_RESPONSE = 'cars_book_response',
    SEARCH_ORIGIN_POINT = 'cars_search_origin_point',
    SEARCH_START_TIME = 'cars_search_start_time',
    SEARCH_RESULTS_START_TIME = 'cars_search_results_start_time',
    BOOK_START_TIME = 'cars_book_start_time',
    SEARCH_TRIGGERED = 'cars_search_triggered',
    BOOK_TRIGGERED = 'cars_book_triggered',
}
