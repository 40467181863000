import { AdobeAnalyticsDetails, AssetValue, GoogleAnalyticsDetails, Location } from '@shared/models';

import { CabinPrice } from './cabin-price';

export class CruiseDetails {
    constructor(
        public id: string,
        public name: string,
        public images: string[],
        public rating: number,
        public noOfNights: number,
        public portsOfCall: string[],
        public sailDate: string,
        public returnDate: string,
        public lineName: string,
        public shipDetails: ShipDetails,
        public itineraryItems: ItineraryItem[],
        public mapLocations: Location[],
        public gradeTypes: GradeType[],
        public ga: GoogleAnalyticsDetails,
        public oa?: AdobeAnalyticsDetails,
        public goodStars?: number[],
        public badStars?: number[]
    ) {}
}

export class ShipDetails {
    constructor(
        public name: string,
        public launchedDate: string,
        public totalCrew: number,
        public tonnage: number,
        public length: number,
        public occupancy: number,
        public description: string,
        public facilityGroups: FacilityGroup[],
        public decks: Deck[]
    ) {}
}

export class ItineraryItem {
    constructor(
        public day: string,
        public port: string,
        public date: string,
        public arriveDate: string,
        public arriveTime: string,
        public departDate: string,
        public departTime: string,
        public coords: Location
    ) {}
}

export class FacilityGroup {
    constructor(public name: string, public facilities: string[]) {}
}

export class GradeType {
    constructor(public code: string, public gradeCategories: GradeCategory[]) {}
}

export class GradeCategory {
    constructor(
        public id: string,
        public name: string,
        public imageUrl: string,
        public description: string,
        public cabinGrades: CabinGrade[]
    ) {}
}

export class CabinGrade {
    constructor(
        public id: string,
        public departure: string,
        public type: string,
        public averagePrice: AssetValue,
        public cabins?: Cabin[],
        public active: boolean = false,
        public loading: boolean = false
    ) {}
}

export class Cabin {
    constructor(
        public id: string,
        public number: string,
        public location: string,
        public sleeps: number,
        public bath: string,
        public adjoining: number,
        public deckId: string,
        public deckName: string,
        public bed: string,
        public xAxis: number,
        public yAxis: number,
        public length: number,
        public height: number,
        public priceDetails?: CabinPrice,
        public priceDetailsLoading?: boolean,
        public pricingError?: string,
        public tax?: AssetValue,
        public taxIncluded?: boolean
    ) {}
}

export class Deck {
    constructor(public id: string, public name: string, public imageUrl: string, public active: boolean = false) {}
}
