import pick from 'lodash/pick';

type Constructor<T> = {
    new (): T;
    fields?: string[];
};

export const mapObjectToEntityFallback = <T extends object>(object: Partial<T>, Entity: Constructor<T>): T => {
    const targetEntity = new Entity();
    const validProperties = Entity.fields || Object.keys(targetEntity);

    return Object.assign(targetEntity, pick(object, validProperties));
};
