import { Entity, Of } from '@utils';

@Entity
export class RoomSelection {
    @Of(() => Number)
    adults = 1;

    @Of(() => Number)
    children = 0;

    @Of(() => Number)
    infants = 0;

    static of = Entity.of<RoomSelection>();
}
