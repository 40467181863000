<div id="main">
    <app-loader [loaded]="isLoaded"></app-loader>

    <div appTheme class="app-page-wrapper">
        <ng-container *ngIf="!onLoginRegister">
            <div
                appTranslate
                id="modules-global-wrapper"
                [global]="true"
                class="flex-container flex-column height-100"
                *ngIf="isLoaded"
            >
                <a
                    [routerLink]="[currentUrl]"
                    [fragment]="'main-content'"
                    class="skip-content-link"
                    [attr.data-i18n-code]="'nav.skipContent.label'"
                ></a>

                <app-header></app-header>
                <div id="main-content" tabindex="0" aria-label="Main content" class="flex-grow-1">
                    <router-outlet #bodyContent></router-outlet>
                </div>
                <app-footer class="flex-fixed"></app-footer>
            </div>
        </ng-container>

        <ng-container *ngIf="onLoginRegister">
            <div appTranslate id="login-global-wrapper" class="flex-container flex-column height-100" *ngIf="isLoaded">
                <router-outlet #bodyContent></router-outlet>
            </div>
        </ng-container>
    </div>
</div>

<!-- NEEDED IN ORDER TO PRELOAD THE TABLE STYLES FOR PAGES THAT ARE USING EXPLICIT MAT-TABLE CLASSES -->
<mat-table class="hide">
    <mat-header-row *matHeaderRowDef="[]"></mat-header-row>
</mat-table>
<!-- NEEDED IN ORDER TO PRELOAD THE TABLE STYLES FOR PAGES THAT ARE USING EXPLICIT MAT-TABLE CLASSES -->
