export enum BOOKING_QUESTIONS {
    AGEBAND = 'AGEBAND',
    FULL_NAMES_LAST = 'FULL_NAMES_LAST',
    FULL_NAMES_FIRST = 'FULL_NAMES_FIRST',
    WEIGHT = 'WEIGHT',
    HEIGHT = 'HEIGHT',

    SPECIAL_REQUIREMENTS = 'SPECIAL_REQUIREMENTS',

    PICKUP_POINT = 'PICKUP_POINT',

    TRANSFER_ARRIVAL_MODE = 'TRANSFER_ARRIVAL_MODE',
    TRANSFER_ARRIVAL_TIME = 'TRANSFER_ARRIVAL_TIME',
    TRANSFER_ARRIVAL_DROP_OFF = 'TRANSFER_ARRIVAL_DROP_OFF',

    TRANSFER_DEPARTURE_PICKUP = 'TRANSFER_DEPARTURE_PICKUP',
    TRANSFER_DEPARTURE_MODE = 'TRANSFER_DEPARTURE_MODE',
    TRANSFER_DEPARTURE_DATE = 'TRANSFER_DEPARTURE_DATE',
    TRANSFER_DEPARTURE_TIME = 'TRANSFER_DEPARTURE_TIME',

    TRANSFER_AIR_ARRIVAL_AIRLINE = 'TRANSFER_AIR_ARRIVAL_AIRLINE',
    TRANSFER_AIR_ARRIVAL_FLIGHT_NO = 'TRANSFER_AIR_ARRIVAL_FLIGHT_NO',
    TRANSFER_AIR_DEPARTURE_AIRLINE = 'TRANSFER_AIR_DEPARTURE_AIRLINE',
    TRANSFER_AIR_DEPARTURE_FLIGHT_NO = 'TRANSFER_AIR_DEPARTURE_FLIGHT_NO',

    TRANSFER_PORT_ARRIVAL_TIME = 'TRANSFER_PORT_ARRIVAL_TIME',
    TRANSFER_PORT_CRUISE_SHIP = 'TRANSFER_PORT_CRUISE_SHIP',
    TRANSFER_PORT_DEPARTURE_TIME = 'TRANSFER_PORT_DEPARTURE_TIME',

    TRANSFER_RAIL_ARRIVAL_LINE = 'TRANSFER_RAIL_ARRIVAL_LINE',
    TRANSFER_RAIL_ARRIVAL_STATION = 'TRANSFER_RAIL_ARRIVAL_STATION',
    TRANSFER_RAIL_DEPARTURE_LINE = 'TRANSFER_RAIL_DEPARTURE_LINE',
    TRANSFER_RAIL_DEPARTURE_STATION = 'TRANSFER_RAIL_DEPARTURE_STATION',
}

export const BOOKING_QUESTIONS_ORDER: Record<BOOKING_QUESTIONS, number> = {
    AGEBAND: 100,
    FULL_NAMES_LAST: 100,
    FULL_NAMES_FIRST: 100,
    WEIGHT: 100,
    HEIGHT: 100,

    SPECIAL_REQUIREMENTS: 100,

    TRANSFER_ARRIVAL_MODE: 0,
    PICKUP_POINT: 1,
    TRANSFER_ARRIVAL_TIME: 2,
    TRANSFER_ARRIVAL_DROP_OFF: 3,

    TRANSFER_DEPARTURE_MODE: 5,
    TRANSFER_DEPARTURE_DATE: 6,
    TRANSFER_DEPARTURE_TIME: 7,
    TRANSFER_DEPARTURE_PICKUP: 8,

    TRANSFER_AIR_ARRIVAL_AIRLINE: 4,
    TRANSFER_AIR_ARRIVAL_FLIGHT_NO: 4,
    TRANSFER_AIR_DEPARTURE_AIRLINE: 9,
    TRANSFER_AIR_DEPARTURE_FLIGHT_NO: 9,

    TRANSFER_PORT_ARRIVAL_TIME: 4,
    TRANSFER_PORT_CRUISE_SHIP: 9,
    TRANSFER_PORT_DEPARTURE_TIME: 9,

    TRANSFER_RAIL_ARRIVAL_LINE: 4,
    TRANSFER_RAIL_ARRIVAL_STATION: 1,
    TRANSFER_RAIL_DEPARTURE_LINE: 9,
    TRANSFER_RAIL_DEPARTURE_STATION: 9,
};

export const BASE_QUESTIONS = [BOOKING_QUESTIONS.PICKUP_POINT, BOOKING_QUESTIONS.SPECIAL_REQUIREMENTS];

export const ARRIVAL_QUESTIONS = [BOOKING_QUESTIONS.TRANSFER_ARRIVAL_MODE];

export const DEPARTURE_QUESTIONS = [
    BOOKING_QUESTIONS.TRANSFER_DEPARTURE_MODE,
    BOOKING_QUESTIONS.TRANSFER_DEPARTURE_DATE,
    BOOKING_QUESTIONS.TRANSFER_DEPARTURE_TIME,
    BOOKING_QUESTIONS.TRANSFER_DEPARTURE_PICKUP,
];

export const AIR_QUESTIONS_ARRIVE = [
    BOOKING_QUESTIONS.TRANSFER_AIR_ARRIVAL_AIRLINE,
    BOOKING_QUESTIONS.TRANSFER_AIR_ARRIVAL_FLIGHT_NO,
    BOOKING_QUESTIONS.TRANSFER_ARRIVAL_TIME,
    BOOKING_QUESTIONS.TRANSFER_ARRIVAL_DROP_OFF,
    BOOKING_QUESTIONS.PICKUP_POINT,
];

export const MANDATORY_AIR_QUESTIONS_ARRIVE = [
    BOOKING_QUESTIONS.TRANSFER_AIR_ARRIVAL_AIRLINE,
    BOOKING_QUESTIONS.TRANSFER_AIR_ARRIVAL_FLIGHT_NO,
    BOOKING_QUESTIONS.TRANSFER_ARRIVAL_TIME,
];

export const AIR_QUESTIONS_DEPART = [
    BOOKING_QUESTIONS.TRANSFER_AIR_DEPARTURE_AIRLINE,
    BOOKING_QUESTIONS.TRANSFER_AIR_DEPARTURE_FLIGHT_NO,
    BOOKING_QUESTIONS.TRANSFER_DEPARTURE_DATE,
    BOOKING_QUESTIONS.TRANSFER_DEPARTURE_TIME,
    BOOKING_QUESTIONS.TRANSFER_DEPARTURE_PICKUP,
];

export const MANDATORY_AIR_QUESTIONS_DEPART = [
    BOOKING_QUESTIONS.TRANSFER_AIR_DEPARTURE_AIRLINE,
    BOOKING_QUESTIONS.TRANSFER_AIR_DEPARTURE_FLIGHT_NO,
    BOOKING_QUESTIONS.TRANSFER_DEPARTURE_DATE,
    BOOKING_QUESTIONS.TRANSFER_DEPARTURE_TIME,
    BOOKING_QUESTIONS.TRANSFER_DEPARTURE_PICKUP,
];

export const SEA_QUESTIONS_ARRIVE = [
    BOOKING_QUESTIONS.TRANSFER_PORT_ARRIVAL_TIME,
    BOOKING_QUESTIONS.TRANSFER_ARRIVAL_DROP_OFF,
    BOOKING_QUESTIONS.TRANSFER_PORT_CRUISE_SHIP,
    BOOKING_QUESTIONS.PICKUP_POINT,
];

export const SEA_QUESTIONS_ARRIVE_NO_SHIP = [
    BOOKING_QUESTIONS.TRANSFER_PORT_ARRIVAL_TIME,
    BOOKING_QUESTIONS.TRANSFER_ARRIVAL_DROP_OFF,
    BOOKING_QUESTIONS.PICKUP_POINT,
];

export const MANDATORY_SEA_QUESTIONS_ARRIVE = [
    BOOKING_QUESTIONS.TRANSFER_PORT_ARRIVAL_TIME,
    BOOKING_QUESTIONS.TRANSFER_PORT_CRUISE_SHIP,
];

export const SEA_QUESTIONS_DEPART = [
    BOOKING_QUESTIONS.TRANSFER_PORT_CRUISE_SHIP,
    BOOKING_QUESTIONS.TRANSFER_DEPARTURE_DATE,
    BOOKING_QUESTIONS.TRANSFER_PORT_DEPARTURE_TIME,
    BOOKING_QUESTIONS.TRANSFER_DEPARTURE_PICKUP,
];

export const SEA_QUESTIONS_DEPART_NO_SHIP = [
    BOOKING_QUESTIONS.TRANSFER_DEPARTURE_DATE,
    BOOKING_QUESTIONS.TRANSFER_PORT_DEPARTURE_TIME,
    BOOKING_QUESTIONS.TRANSFER_DEPARTURE_PICKUP,
];

export const MANDATORY_SEA_QUESTIONS_DEPART = [BOOKING_QUESTIONS.TRANSFER_PORT_DEPARTURE_TIME];

export const RAIL_QUESTIONS_ARRIVE = [
    BOOKING_QUESTIONS.TRANSFER_RAIL_ARRIVAL_LINE,
    BOOKING_QUESTIONS.TRANSFER_RAIL_ARRIVAL_STATION,
    BOOKING_QUESTIONS.TRANSFER_ARRIVAL_TIME,
    BOOKING_QUESTIONS.TRANSFER_ARRIVAL_DROP_OFF,
];

export const MANDATORY_RAIL_QUESTIONS_ARRIVE = [
    BOOKING_QUESTIONS.TRANSFER_RAIL_ARRIVAL_LINE,
    BOOKING_QUESTIONS.TRANSFER_RAIL_ARRIVAL_STATION,
    BOOKING_QUESTIONS.TRANSFER_ARRIVAL_TIME,
    BOOKING_QUESTIONS.TRANSFER_ARRIVAL_DROP_OFF,
];

export const RAIL_QUESTIONS_DEPART = [
    BOOKING_QUESTIONS.TRANSFER_RAIL_DEPARTURE_LINE,
    BOOKING_QUESTIONS.TRANSFER_RAIL_DEPARTURE_STATION,
    BOOKING_QUESTIONS.TRANSFER_DEPARTURE_DATE,
    BOOKING_QUESTIONS.TRANSFER_DEPARTURE_TIME,
    BOOKING_QUESTIONS.TRANSFER_DEPARTURE_PICKUP,
];

export const MANDATORY_RAIL_QUESTIONS_DEPART = [
    BOOKING_QUESTIONS.TRANSFER_RAIL_DEPARTURE_LINE,
    BOOKING_QUESTIONS.TRANSFER_RAIL_DEPARTURE_STATION,
    BOOKING_QUESTIONS.TRANSFER_DEPARTURE_DATE,
    BOOKING_QUESTIONS.TRANSFER_DEPARTURE_TIME,
    BOOKING_QUESTIONS.TRANSFER_DEPARTURE_PICKUP,
];
