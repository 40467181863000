import { ConfirmationDetails } from '@account/models';
import { AccountService } from '@account/services';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SiteBranding, TokenInfo, UserInfo } from '@core/models';
import { AuthService, LoaderService, UserAuthService } from '@core/services';
import { DataTemplate, SharedStorageKeys } from '@shared/models';
import { I18nService, StorageService, UrlService, UserInfoUpdateService } from '@shared/services';
import { ThemeService } from '@theme/services';
import { defer, forkJoin, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-assist-member',
    templateUrl: './assist-member.component.html',
    styleUrls: ['assist-member.component.scss'],
})
export class AssistMemberComponent implements OnInit, OnDestroy {
    sharedStorageKeys = SharedStorageKeys;
    cancelRedirect: number;

    private unsubscribe: Subject<void> = new Subject<void>();

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private url: UrlService,
        private loader: LoaderService,
        private userAuthService: UserAuthService,
        private authService: AuthService,
        private themeService: ThemeService,
        private i18nService: I18nService,
        private userInfoUpdateService: UserInfoUpdateService,
        private accountService: AccountService,
        private storage: StorageService
    ) {}

    ngOnInit(): void {
        const siteBranding: SiteBranding = JSON.parse(
            localStorage.getItem(this.sharedStorageKeys.SITE_BRANDING)
        ) as SiteBranding;

        if (this.route.snapshot.paramMap.get('token') && this.route.snapshot.paramMap.get('mid')) {
            localStorage.removeItem(this.sharedStorageKeys.USER_INFO);

            defer(() => {
                this.loader.show();
                return this.userAuthService
                    .login(
                        `${this.route.snapshot.paramMap.get('token')}:${this.route.snapshot.paramMap.get('mid')}`,
                        'assist'
                    )
                    .pipe(
                        takeUntil(this.unsubscribe),
                        finalize(() => this.loader.hide())
                    );
            }).subscribe(
                (response: any) => {
                    this.authService.login();
                    this.authService.setUsername('Admin');
                    this.authService.setToken(
                        new TokenInfo('Admin', `${response.token_type} ${response.access_token}`, response.expires_in)
                    );

                    this.themeService.unloadTheme();
                    this.storage.removeItem(this.sharedStorageKeys.SITE_BRANDING);

                    forkJoin([this.userAuthService.getUserInfo(), this.userAuthService.getSiteBranding()])
                        .pipe(
                            takeUntil(this.unsubscribe),
                            finalize(() => this.loader.hide())
                        )
                        .subscribe(
                            (response: [DataTemplate<UserInfo>, DataTemplate<SiteBranding>]) => {
                                localStorage.setItem(
                                    this.sharedStorageKeys.USER_INFO,
                                    JSON.stringify(response[0].data)
                                );

                                response[1].data.uiConfig.localeOptions?.languages.forEach((language: string) => {
                                    this.storage.removeItem(`${language}_${this.sharedStorageKeys.I18N_KEYS}`);
                                });
                                this.storage.removeItem(this.sharedStorageKeys.GENERAL_KEYS_LOADED);

                                this.i18nService
                                    .preloadBrandingKeys(response[1].data)
                                    .pipe(takeUntil(this.unsubscribe))
                                    .subscribe(() => {
                                        this.userInfoUpdateService.update.emit();
                                        this.decideRedirect(response[1].data);
                                    });
                            },
                            (error: string) => {
                                console.error(error);
                                this.decideRedirect(siteBranding);
                            }
                        );
                },
                (error: string) => {
                    console.error(error);
                    this.decideRedirect(siteBranding);
                }
            );

            this.route.queryParams.subscribe((params: any) => {
                this.cancelRedirect = parseInt(params['cancel_res']);
            });
        } else {
            this.decideRedirect(siteBranding);
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    decideRedirect(siteBranding: SiteBranding): void {
        if (this.cancelRedirect) {
            defer(() => {
                this.loader.show();
                return this.accountService.getConfirmation(this.cancelRedirect).pipe(
                    takeUntil(this.unsubscribe),
                    finalize(() => this.loader.hide())
                );
            }).subscribe(
                (response: DataTemplate<ConfirmationDetails>) => {
                    this.router.navigateByUrl(
                        this.url.getUrl(`${response.data.module}-book-cancel`, { id: this.cancelRedirect })
                    );
                },
                (error: string) => {
                    console.error(error);
                    if (siteBranding?.uiConfig?.modules?.length) {
                        this.router.navigateByUrl(this.url.getUrl(siteBranding?.uiConfig.modules[0]));
                    } else {
                        this.router.navigateByUrl(
                            siteBranding?.uiConfig?.userOptions?.loginRedirect || this.url.getUrl('login')
                        );
                    }
                }
            );
        } else {
            if (siteBranding?.uiConfig?.modules?.length) {
                this.router.navigateByUrl(this.url.getUrl(siteBranding?.uiConfig.modules[0]));
            } else {
                if (siteBranding?.uiConfig?.userOptions?.loginRedirect?.startsWith('http')) {
                    window.location.href = siteBranding?.uiConfig.userOptions?.loginRedirect;
                } else {
                    this.router.navigateByUrl(
                        siteBranding?.uiConfig?.userOptions?.loginRedirect || this.url.getUrl('login')
                    );
                }
            }
        }
    }
}
