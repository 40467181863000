import { AdobeAnalyticsDetails, GoogleAnalyticsDetails } from '@shared/models';

import { DenominationOption } from './card-details';

export class Card {
    id: string;
    typeId: string;
    categoryId: string;
    name: string;
    imageUrl: string;
    isDigital: boolean;
    currencyCode: string;
    denominationOptions?: DenominationOption[];
    denominationMin?: number;
    denominationMax?: number;
    description?: string;
    termsConditions?: string;
    cancellationPolicy?: string;
    refundable?: boolean;
    redemptionInstructions?: string;
    selectedDenomination?: number;
    ga?: GoogleAnalyticsDetails;
    oa?: AdobeAnalyticsDetails;
}
