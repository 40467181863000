import { AdobeAnalyticsDetails } from '@shared/models';

import { AssetValue } from './asset-value';
import { GoogleAnalyticsDetails } from './google-analytics-details';

export class Rate {
    id: string;
    redeem: AssetValue;
    redeemMin: AssetValue;
    redeemMax: AssetValue;
    refundable: boolean;
    ga: GoogleAnalyticsDetails;
    oa: AdobeAnalyticsDetails;
    refundableUntil?: string;
}
