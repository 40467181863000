<div
    appTranslate
    id="cancel-booking-confirmation"
    class="main-content-wrapper cancel-booking-confirmation-wrapper"
    *ngIf="cancellationResponse"
>
    <h3
        class="subsection-title text-center margin-bottom-10"
        [attr.data-i18n-code]="'reservations.cancellation.confirmationTitle'"
    ></h3>
    <div class="text-center margin-bottom-50">
        <span [attr.data-i18n-code]="'reservations.cancellation.confirmationSubtitle'"></span>
    </div>

    <div class="details-wrapper">
        <h3
            class="subsection-title margin-bottom-20"
            [attr.data-i18n-code]="'reservations.cancellation.priceSummary'"
        ></h3>

        <ng-container *ngFor="let price of cancellationResponse.payPriceBreakdown">
            <div class="flex-container justify-content-space-between margin-bottom-10">
                <span class="price-label">{{ price.label }}</span>
                <span
                    class="price-value"
                    [class.line-through]="price.type === 'retail'"
                    [innerHTML]="price?.value"
                ></span>
            </div>
        </ng-container>

        <div
            class="price-separator margin-top-20 margin-bottom-20"
            *ngIf="cancellationResponse.payTotalAmt?.value || cancellationResponse.accountCredit?.value"
        ></div>

        <div
            class="flex-container justify-content-space-between margin-bottom-10"
            *ngIf="cancellationResponse.payTotalAmt?.value"
        >
            <span class="price-total-label bold" [attr.data-i18n-code]="'core.labels.total'"></span>
            <span class="price-total-value bold">{{
                cancellationResponse.payTotalAmt.value | formatCurrency: cancellationResponse.payTotalAmt.code
            }}</span>
        </div>

        <div
            class="flex-container justify-content-space-between margin-bottom-10"
            *ngIf="cancellationResponse.accountCredit?.value"
        >
            <span class="price-label" [attr.data-i18n-code]="'core.labels.accountCredit'"></span>
            <span class="price-value">{{
                cancellationResponse.accountCredit.value | formatCurrency: cancellationResponse.accountCredit.code
            }}</span>
        </div>

        <div class="price-separator margin-top-20 margin-bottom-20"></div>

        <div class="flex-container justify-content-space-between" *ngIf="cancellationResponse.cancelPenaltyFee?.value">
            <span
                class="price-label penalty-value"
                [attr.data-i18n-code]="'reservations.cancellation.penaltyFee'"
            ></span>
            <span class="price-value penalty-value">{{
                cancellationResponse.cancelPenaltyFee.value | formatCurrency: cancellationResponse.cancelPenaltyFee.code
            }}</span>
        </div>

        <div
            class="price-separator margin-top-20 margin-bottom-20"
            *ngIf="cancellationResponse.cancelPenaltyFee?.value"
        ></div>

        <div
            class="flex-container justify-content-space-between margin-bottom-10"
            *ngIf="cancellationResponse.totalRefund?.value"
        >
            <span class="price-total-label bold" [attr.data-i18n-code]="'reservations.cancellation.totalRefund'"></span>
            <span class="price-total-value bold">{{
                cancellationResponse.totalRefund.value | formatCurrency: cancellationResponse.totalRefund.code
            }}</span>
        </div>

        <div
            class="flex-container justify-content-space-between margin-bottom-10"
            *ngIf="cancellationResponse.accountCreditRefund?.value"
        >
            <span class="price-label" [attr.data-i18n-code]="'reservations.cancellation.accountCreditRefund'"></span>
            <span class="price-value">{{
                cancellationResponse.accountCreditRefund.value
                    | formatCurrency: cancellationResponse.accountCreditRefund.code
            }}</span>
        </div>

        <div
            class="flex-container justify-content-space-between margin-bottom-10"
            *ngIf="cancellationResponse.creditCardRefund?.value"
        >
            <span
                class="price-label"
                [attr.data-i18n-code]="'reservations.cancellation.ccRefund'"
                [attr.data-i18n-params]="cancellationResponse.ccLast4"
            ></span>
            <span class="price-value">{{
                cancellationResponse.creditCardRefund.value | formatCurrency: cancellationResponse.creditCardRefund.code
            }}</span>
        </div>

        <div
            class="margin-top-40 margin-bottom-20 bold"
            [attr.data-i18n-code]="'reservations.cancellation.pointsAdjustment'"
            *ngIf="cancellationResponse.loyaltyPointsTotalAdj"
        ></div>

        <div
            class="flex-container justify-content-space-between margin-bottom-10"
            *ngIf="cancellationResponse.loyaltyPointsApplied"
        >
            <span
                class="price-label"
                [attr.data-i18n-code]="'reservations.cancellation.pointsApplied'"
                [attr.data-i18n-params]="siteBranding?.uiConfig?.pointsOptions?.pointsName"
                [attr.data-i18n-persist]="true"
            ></span>
            <span class="price-value">{{ cancellationResponse.loyaltyPointsApplied }}</span>
        </div>

        <div
            class="flex-container justify-content-space-between margin-bottom-10"
            *ngIf="cancellationResponse.loyaltyPointsRefunded"
        >
            <span
                class="price-label points-value"
                [attr.data-i18n-code]="'reservations.cancellation.pointsRefund'"
                [attr.data-i18n-params]="siteBranding?.uiConfig?.pointsOptions?.pointsName"
                [attr.data-i18n-persist]="true"
            ></span>
            <span class="price-value points-value">{{ cancellationResponse.strLoyaltyPointsRefunded }}</span>
        </div>

        <div
            class="flex-container justify-content-space-between margin-bottom-10"
            *ngIf="cancellationResponse.loyaltyPointsEarned"
        >
            <span
                class="price-label"
                [attr.data-i18n-code]="'reservations.cancellation.pointsEarned'"
                [attr.data-i18n-params]="siteBranding?.uiConfig?.pointsOptions?.pointsName"
                [attr.data-i18n-persist]="true"
            ></span>
            <span class="price-value">{{ cancellationResponse.loyaltyPointsEarned }}</span>
        </div>

        <div
            class="flex-container justify-content-space-between margin-bottom-10"
            *ngIf="cancellationResponse.loyaltyPointsReversed"
        >
            <span
                class="price-label penalty-value"
                [attr.data-i18n-code]="'reservations.cancellation.pointsReversed'"
                [attr.data-i18n-params]="siteBranding?.uiConfig?.pointsOptions?.pointsName"
                [attr.data-i18n-persist]="true"
            ></span>
            <span class="price-value penalty-value">{{ cancellationResponse.strLoyaltyPointsReversed }}</span>
        </div>

        <div
            class="price-separator margin-top-20 margin-bottom-20"
            *ngIf="cancellationResponse.strLoyaltyPointsTotalAdj"
        ></div>

        <div class="flex-container justify-content-space-between" *ngIf="cancellationResponse.strLoyaltyPointsTotalAdj">
            <span
                class="bold"
                [attr.data-i18n-code]="'reservations.cancellation.totalPointsAdjustment'"
                [attr.data-i18n-params]="siteBranding?.uiConfig?.pointsOptions?.pointsName"
                [attr.data-i18n-persist]="true"
            ></span>
            <span class="bold">{{ cancellationResponse.strLoyaltyPointsTotalAdj }}</span>
        </div>

        <div class="confirmation-footer margin-top-100 flex-container flex-column align-items-center">
            <div class="confirmation-logo margin-bottom-10"></div>
            <div class="confirmation-footer-message" [attr.data-i18n-code]="'core.labels.confirmationFooter'"></div>
        </div>
    </div>
</div>
