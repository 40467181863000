import { AssetValue, PayPriceBreakdown } from '@shared/models';

export class BookResponse {
    bookingRef: string;
    checkIn: string;
    checkInInstructions: string;
    checkOut: string;
    confirmCode: string;
    earnDelayDate: string;
    guestFirstName: string;
    guestLastName: string;
    hotelAddress: string;
    hotelCity: string;
    hotelEmail: string;
    hotelImage: string;
    hotelName: string;
    hotelPhone: string;
    numNights: number;
    numAdults: number;
    numChildren: number;
    numRooms: number;
    payCardName: string;
    payCardType: string;
    payCardNumber: string;
    payCardExpDate: string;
    payPriceBreakdown: PayPriceBreakdown[];
    payTotalAmount: string;
    payPointsEarned: string;
    roomName: string;
    rateBoardText: string;
    refundable: boolean;
    refundableUntil: string;
    txGuid: string;

    includedFees: AssetValue[];
    hotelFees: AssetValue[];
    totalDueAtHotel: string;

    rooms: ConfirmationRoom[];
}

export class ConfirmationRoom {
    adults: number;
    childrenAges: number[];
    name: string;
    rateBoardText: string;
    bedType: string;
}
