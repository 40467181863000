import { SearchRequest } from '../request/search-request';

import { Flight } from './flight';
import { SearchFilters } from './search-response';

export class SavedSearch {
    items: Flight[];
    searchParams: SearchRequest;
    filters: SearchFilters;
}
