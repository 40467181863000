import { InjectionToken } from '@angular/core';

export const THEMES = new InjectionToken('THEMES');
export const ACTIVE_THEME = new InjectionToken('ACTIVE_THEME');

export interface ThemeOptions {
    themes: Theme[];
    active: string;
}

export class Theme {
    name: string;
    properties: object;

    constructor(name = 'primary', properties: object = {}) {
        this.name = name;
        this.properties = properties;
    }
}
