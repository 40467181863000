<div class="flex-container align-items-center variant-{{ headerVariant }}">
    <ul class="no-styles flex-container align-items-center">
        <ng-container *ngFor="let item of menuItems; index as i">
            <li class="header-link-wrapper" [class.hide-below-xl]="i > 3" [class.hide-below-md]="i > 1">
                <div class="position-relative">
                    <a
                        class="link header-link"
                        [class.active]="navTarget === item.code"
                        *ngIf="item.customUrl?.startsWith('http') && !item.isModal"
                        [attr.href]="item.customUrl"
                        [attr.target]="item.target"
                    >
                        <span [attr.data-i18n-code]="item.code"></span>
                    </a>

                    <a
                        class="link header-link"
                        [class.active]="navTarget === item.module"
                        [attr.aria-current]="navTarget === item.code ? 'page' : ''"
                        *ngIf="!item.customUrl?.startsWith('http') && !item.isModal"
                        [routerLink]="item.customUrl || url.getUrl(item.code)"
                        [fragment]="item.fragment"
                    >
                        <span [attr.data-i18n-code]="item.code"></span>
                    </a>

                    <a class="link header-link" *ngIf="item.isModal" (click)="openModuleModal(item.module)">
                        <span [attr.data-i18n-code]="item.code"></span>
                    </a>

                    <div class="active-marker" [class.show]="navTarget === item.module"></div>
                </div>
            </li>
        </ng-container>
    </ul>

    <button
        *ngIf="menuItems.length > 2"
        aria-label="Extra navigation items"
        class="tablet-menu-button position-relative ada-button"
        [class.hide-md-up]="menuItems.length > 2 && menuItems.length <= 4"
        [class.hide-xl-up]="menuItems.length > 4"
        (click)="showTabletMenu = true"
        (clickOutside)="showTabletMenu = false"
    >
        <div class="menu-button-icon cursor-pointer flex-container justify-content-center align-items-center">
            <div class="menu-circle"></div>
            <div class="menu-circle"></div>
            <div class="menu-circle"></div>
        </div>

        <div class="links-menu pop-up-menu" [class.hide]="!showTabletMenu">
            <div class="padding-10">
                <ul class="menu-list ada-list">
                    <ng-container *ngFor="let item of menuItems; index as i">
                        <li
                            class="flex-container justify-content-center"
                            [class.hide-sm-up]="i < 2"
                            [class.hide-md-up]="i < 4"
                        >
                            <a
                                class="link header-link tablet-link width-100 padding-10"
                                *ngIf="item.customUrl?.startsWith('http') && !item.isModal"
                                [attr.href]="item.customUrl"
                                [attr.target]="item.target"
                            >
                                <span [attr.data-i18n-code]="item.code"></span>
                            </a>

                            <a
                                appClickStopPropagation
                                class="link header-link tablet-link width-100 padding-10"
                                [class.active]="navTarget === item.module"
                                [attr.aria-current]="navTarget === item.code ? 'page' : ''"
                                *ngIf="!item.customUrl?.startsWith('http') && !item.isModal"
                                [routerLink]="item.customUrl || url.getUrl(item.code)"
                                [fragment]="item.fragment"
                                (click)="showTabletMenu = false"
                            >
                                <span [attr.data-i18n-code]="item.code"></span>
                            </a>

                            <a
                                class="link header-link tablet-link width-100 padding-10"
                                *ngIf="item.isModal"
                                (click)="openModuleModal(item.module)"
                            >
                                <span [attr.data-i18n-code]="item.code"></span>
                            </a>
                        </li>
                    </ng-container>
                </ul>
            </div>
        </div>
    </button>
</div>
