import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { LoaderService, UserAuthService } from '@core/services';
import { DataTemplate, SharedStorageKeys } from '@shared/models';
import { FormErrorService, I18nRefreshService, I18nService } from '@shared/services';
import { defer, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-forgot-password-form',
    templateUrl: './forgot-password-form.component.html',
    styleUrls: ['./forgot-password-form.component.scss'],
})
export class ForgotPasswordFormComponent implements OnInit, OnDestroy {
    @Output()
    target: EventEmitter<number> = new EventEmitter<number>();

    sharedStorageKeys = SharedStorageKeys;
    successMessage: string;
    errorMessage: string;

    forgotPasswordFormGroup: UntypedFormGroup;
    formErrors = {
        username: '',
        lastName: '',
    };
    validationMessages = {};

    private unsubscribe: Subject<void> = new Subject();

    constructor(
        private userAuthService: UserAuthService,
        private formErrorService: FormErrorService,
        public i18nService: I18nService,
        private i18nRefreshService: I18nRefreshService,
        private loader: LoaderService
    ) {}

    ngOnInit(): void {
        this.i18nRefreshService
            .getState()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.getValidationMessages(true);
            });

        this.getValidationMessages();
        this.populateContent();
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    populateContent(): void {
        this.forgotPasswordFormGroup = new UntypedFormGroup({
            username: new UntypedFormControl(null, [Validators.required]),
            lastName: new UntypedFormControl(null, [Validators.required]),
        });

        this.forgotPasswordFormGroup.valueChanges
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() =>
                this.formErrorService.checkForErrors(
                    this.forgotPasswordFormGroup,
                    this.formErrors,
                    this.validationMessages
                )
            );
    }

    submitForgotPassword(): void {
        this.formErrorService.markAsDirty(this.forgotPasswordFormGroup);
        this.formErrorService.checkForErrors(this.forgotPasswordFormGroup, this.formErrors, this.validationMessages);

        if (this.forgotPasswordFormGroup.valid) {
            defer(() => {
                this.loader.show();
                this.successMessage = null;
                this.errorMessage = null;

                return this.userAuthService.forgotPassword(this.forgotPasswordFormGroup.value).pipe(
                    takeUntil(this.unsubscribe),
                    finalize(() => this.loader.hide())
                );
            }).subscribe(
                (response: DataTemplate<{ message: string }>) => {
                    this.successMessage = response.data.message;
                },
                (error: string) => {
                    console.error(error);
                    this.errorMessage = error;
                }
            );
        }
    }

    getValidationMessages(swapValidationMessages = false): void {
        this.i18nService
            .preloadKeys(['core.errors.usernameRequired', 'core.errors.lastNameRequired'])
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                const keys = this.i18nService.getSavedKeys();

                this.validationMessages = {
                    username: {
                        required: keys['core.errors.usernameRequired'],
                    },
                    lastName: {
                        required: keys['core.errors.lastNameRequired'],
                    },
                };

                if (swapValidationMessages) {
                    this.forgotPasswordFormGroup.clearValidators();
                    this.forgotPasswordFormGroup.updateValueAndValidity();
                }
            });
    }
}
