import { AssetValue } from '@shared/models';

export class Schedule {
    id: string;
    name: string;
    description?: string;
    startTimes: ScheduleStartTime[];
}

export class ScheduleStartTime {
    id: string;
    name: string;
    optionName: string;
    description?: string;
    retailPrice?: AssetValue;
    youPay?: AssetValue;
    earns?: AssetValue;
    earnDelayDate: string;
    langServices: LangService[];
    tax?: AssetValue;
    taxIncluded?: boolean;
    refundable?: boolean;
    refundableUntil?: string;
}

export class LangService {
    id: string;
    name: string;
}
