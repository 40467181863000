import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SiteBranding, TokenInfo, UserInfo } from '@core/models';
import { AuthService, LoaderService, UserAuthService } from '@core/services';
import { DataTemplate, SharedStorageKeys } from '@shared/models';
import { I18nRefreshService, I18nService, StorageService, UrlService, UserInfoUpdateService } from '@shared/services';
import { ThemeService } from '@theme/services';
import { defer, forkJoin, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-auto-login',
    templateUrl: './auto-login.component.html',
    styleUrls: ['./auto-login.component.scss'],
})
export class AutoLoginComponent implements OnInit, OnDestroy {
    sharedStorageKeys = SharedStorageKeys;

    siteBranding: SiteBranding;

    private unsubscribe: Subject<void> = new Subject<void>();

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private url: UrlService,
        private loader: LoaderService,
        private userAuthService: UserAuthService,
        private authService: AuthService,
        private themeService: ThemeService,
        private i18nService: I18nService,
        private userInfoUpdateService: UserInfoUpdateService,
        private i18nRefreshService: I18nRefreshService,
        private storage: StorageService
    ) {}

    ngOnInit(): void {
        this.siteBranding = JSON.parse(localStorage.getItem(this.sharedStorageKeys.SITE_BRANDING)) as SiteBranding;

        if (this.route.snapshot.paramMap.get('jwt')) {
            defer(() => {
                this.loader.show();
                return this.userAuthService.login(this.route.snapshot.paramMap.get('jwt'), null, true).pipe(
                    takeUntil(this.unsubscribe),
                    finalize(() => this.loader.hide())
                );
            }).subscribe(
                (response: Record<string, any>) => {
                    this.authService.setUsername('SSO');
                    this.authService.setToken(
                        new TokenInfo('SSO', `${response.token_type} ${response.access_token}`, response.expires_in)
                    );

                    this.getInfo();
                },
                (error: string) => {
                    console.error(error);
                    this.decideRedirect(this.siteBranding);
                }
            );
        } else {
            this.decideRedirect(this.siteBranding);
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    getInfo(): void {
        this.authService.login();

        this.themeService.unloadTheme();
        this.storage.removeItem(this.sharedStorageKeys.SITE_BRANDING);

        const langCode: string = this.route.snapshot.queryParams['languageCode'];
        if (langCode && this.siteBranding?.uiConfig?.localeOptions?.languages.includes(langCode)) {
            this.storage.setItem(this.sharedStorageKeys.CURRENT_LANGUAGE, langCode);

            this.i18nService
                .changeLanguage(langCode)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe(
                    () => {
                        this.loadUserData();
                    },
                    (error: string) => {
                        console.error(error);
                    }
                );
        } else {
            this.loadUserData();
        }
    }

    loadUserData(): void {
        forkJoin([
            this.userAuthService.getUserInfo(this.route.snapshot.queryParams['languageCode']),
            this.userAuthService.getSiteBranding(),
        ])
            .pipe(
                takeUntil(this.unsubscribe),
                finalize(() => this.loader.hide())
            )
            .subscribe(
                (response: [DataTemplate<UserInfo>, DataTemplate<SiteBranding>]) => {
                    this.siteBranding = response[1].data;

                    if (response[0].data?.isGuest) {
                        this.storage.setItem(this.sharedStorageKeys.USER_ID, 'guest');
                    }

                    localStorage.setItem(this.sharedStorageKeys.USER_INFO, JSON.stringify(response[0].data));

                    response[1].data.uiConfig?.localeOptions?.languages.forEach((language: string) => {
                        this.storage.removeItem(`${language}_${this.sharedStorageKeys.I18N_KEYS}`);
                    });
                    this.storage.removeItem(this.sharedStorageKeys.GENERAL_KEYS_LOADED);

                    this.preloadKeys();
                },
                (error: string) => {
                    console.error(error);
                    this.decideRedirect(this.siteBranding);
                }
            );
    }

    preloadKeys(): void {
        this.i18nService
            .preloadBrandingKeys(this.siteBranding)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.userInfoUpdateService.update.emit();
                this.i18nRefreshService.updateState();
                this.decideRedirect(this.siteBranding);
            });
    }

    decideRedirect(siteBranding: SiteBranding): void {
        if (this.route.snapshot.queryParams['returnUrl']) {
            this.router.navigateByUrl(this.route.snapshot.queryParams['returnUrl']);
        } else if (siteBranding?.uiConfig?.modules?.length) {
            this.router.navigateByUrl(this.url.getUrl(siteBranding?.uiConfig.modules[0]));
        } else {
            this.router.navigateByUrl('/');
        }
    }
}
