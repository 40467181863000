import { SavedSearchSortFilters } from '@shared/models';

export class SearchRequest {
    destination: string;
    destinationLabel: string;
    categoryId = '0';
    date: string;
    startIn: number;
    sortFilters?: SavedSearchSortFilters;
    oaLocation?: string;
    searchMode: 'paginated' | 'full' = 'full';
    page = 1;
    pageSize = 12;
}
