export const pageTitles = {
    landing: 'Cruises',
    search: 'Cruise Search Results',
    book: 'Cruise Passenger & Payment Information',
    bookConfirmation: 'Cruise Transaction Completed',
    bookConfirmationProfile: 'Cruise Transaction Confirmation',
    bookReceipt: 'Cruise Transaction Receipt',
    cancellation: 'Cruise Cancellation',
    cancellationConfirmation: 'Cruise Cancellation Confirmation',
    details: 'Cabin Details & Selection',
};
