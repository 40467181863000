import { PayPriceBreakdown } from '@shared/models';

export class BookResponse {
    txGuid: string;
    payCardName: string;
    payCardType: string;
    payCardNumber: string;
    payCardExpDate: string;
    payPriceBreakdown: PayPriceBreakdown[];
    payTotalAmount: string;
    payPointsEarned: string;
    refundable: boolean;

    recipientName: string;
    recipientEmail: string;
    cardName: string;
    isDigital: boolean;
    cardDescription: string;
    cardImageUrl: string;
    cardAmount: string;
    cardCurrency: string;
    cardTermsConditions: string;
    cardCancellationPolicy: string;
    cardRedemptionInstructions: string;
}
