import { Component, Input } from '@angular/core';
import { BaseWidgetDetails } from '@core/models';

@Component({
    selector: 'app-statistics-widget',
    templateUrl: './statistics-widget.component.html',
    styleUrls: ['./statistics-widget.component.scss'],
})
export class StatisticsWidgetComponent {
    @Input()
    widget: BaseWidgetDetails;
}
