import { AssetValue } from '@shared/models';

export class CancellationResponse {
    payTotalAmt: AssetValue;
    payPriceBreakdown: AssetValue[];
    accountCredit: AssetValue;
    ccLast4: string;
    cancelPenaltyFee: AssetValue;
    totalRefund: AssetValue;
    accountCreditRefund: AssetValue;
    creditCardRefund: AssetValue;
    loyaltyPointsApplied: number;
    loyaltyPointsRefunded: number;
    strLoyaltyPointsRefunded: string;
    loyaltyPointsEarned: number;
    loyaltyPointsReversed: number;
    strLoyaltyPointsReversed: string;
    loyaltyPointsTotalAdj: number;
    strLoyaltyPointsTotalAdj: string;
}
