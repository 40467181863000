<div appTranslate id="lead-traveler-form" *ngIf="loaded">
    <form [formGroup]="travelerFormGroup">
        <p class="form-group-title" [attr.data-i18n-code]="'hotels.booking.traveler.informationLabel'"></p>
        <div class="row">
            <div class="col-sm-6 col-xxxs-12">
                <app-input
                    label="core.labels.firstName"
                    [inputType]="'text'"
                    [group]="travelerFormGroup"
                    [errorMessages]="formErrors?.firstName"
                    name="firstName"
                    [autocomplete]="'given-name'"
                    [disabled]="restrictTraveler"
                ></app-input>
            </div>
            <div class="col-sm-6 col-xxxs-12">
                <app-input
                    label="core.labels.lastName"
                    [inputType]="'text'"
                    [group]="travelerFormGroup"
                    [errorMessages]="formErrors?.lastName"
                    name="lastName"
                    [autocomplete]="'family-name'"
                    [disabled]="restrictTraveler"
                ></app-input>
            </div>
            <div class="col-sm-6 col-xxxs-12">
                <app-input
                    label="core.labels.email"
                    inputType="email"
                    [group]="travelerFormGroup"
                    [errorMessages]="formErrors?.email"
                    name="email"
                    [autocomplete]="'email'"
                ></app-input>
            </div>
            <div class="col-sm-6 col-xxxs-12">
                <app-input
                    label="core.labels.phone"
                    [inputType]="'text'"
                    [group]="travelerFormGroup"
                    [errorMessages]="formErrors?.phone"
                    name="phone"
                    [autocomplete]="'tel'"
                ></app-input>
            </div>
        </div>

        <p class="form-group-title" [attr.data-i18n-code]="'hotels.booking.traveler.addressLabel'"></p>
        <div class="row">
            <div class="col-sm-6 col-xxxs-12">
                <app-input
                    label="core.labels.userAddress"
                    [inputType]="'text'"
                    [group]="travelerFormGroup"
                    [errorMessages]="formErrors?.address1"
                    name="address1"
                    [autocomplete]="'address-line1'"
                ></app-input>
            </div>
            <div class="col-sm-6 col-xxxs-12">
                <app-input
                    label="core.labels.city"
                    [inputType]="'text'"
                    [group]="travelerFormGroup"
                    [errorMessages]="formErrors?.city"
                    name="city"
                    [autocomplete]="'address-level2'"
                ></app-input>
            </div>
            <div class="col-md-6 col-xxxs-12">
                <app-select
                    label="core.labels.country"
                    [options]="countries"
                    [group]="travelerFormGroup"
                    [errorMessages]="formErrors?.countryId"
                    name="countryId"
                ></app-select>
            </div>
            <div
                appTranslate
                id="lead-traveler-form-provinces"
                class="col-md-3 col-sm-6 col-xxxs-12"
                *ngIf="provinces?.length"
            >
                <app-select
                    label="core.labels.state"
                    [options]="provinces"
                    [group]="travelerFormGroup"
                    [errorMessages]="formErrors?.provinceId"
                    name="provinceId"
                ></app-select>
            </div>
            <div
                appTranslate
                id="lead-traveler-form-province"
                class="col-md-3 col-sm-6 col-xxxs-12"
                *ngIf="!provinces || !provinces?.length"
            >
                <app-input
                    label="core.labels.stateName"
                    [inputType]="'text'"
                    [group]="travelerFormGroup"
                    [errorMessages]="formErrors?.provinceName"
                    name="provinceName"
                    [autocomplete]="'address-level1'"
                ></app-input>
            </div>
            <div class="col-md-3 col-sm-6 col-xxxs-12">
                <app-input
                    label="core.labels.zip"
                    [inputType]="'text'"
                    [group]="travelerFormGroup"
                    [errorMessages]="formErrors?.postalCode"
                    name="postalCode"
                    [autocomplete]="'postal-code'"
                ></app-input>
            </div>
        </div>

        <!-- <app-checkbox
            label="core.labels.saveTravelerLabel"
            [color]="'primary'"
            [group]="travelerFormGroup"
            name="saveTraveler"
            [class.hide]="!newTraveler"
        ></app-checkbox>
        <app-checkbox
            label="core.labels.updateTravelerLabel"
            [color]="'primary'"
            [group]="travelerFormGroup"
            name="updateTraveler"
            [class.hide]="newTraveler"
        ></app-checkbox>

        <p class="margin-top-5 text-italic" [attr.data-i18n-code]="'core.labels.newTravelerDisclaimer'"></p> -->
    </form>
</div>

<div class="flex-container justify-content-center" *ngIf="!loaded">
    <mat-spinner></mat-spinner>
</div>
