import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SharedStorageKeys } from '@shared/models';
import { CheckDataService, FormErrorService, I18nRefreshService, I18nService } from '@shared/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-register-member-information-form',
    templateUrl: './register-member-information-form.component.html',
    styleUrls: ['./register-member-information-form.component.scss'],
})
export class RegisterMemberInformationFormComponent implements OnInit, OnDestroy {
    sharedStorageKeys = SharedStorageKeys;
    memberFormGroup: UntypedFormGroup;
    formErrors = {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
    };
    validationMessages = {};

    private unsubscribe: Subject<void> = new Subject();

    constructor(
        private formErrorService: FormErrorService,
        private i18nService: I18nService,
        private i18nRefreshService: I18nRefreshService,
        private checkDataService: CheckDataService
    ) {}

    ngOnInit(): void {
        this.i18nRefreshService
            .getState()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.getValidationMessages(true);
            });

        this.getValidationMessages();
        this.populateContent();
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    populateContent(): void {
        this.checkDataService.checkData.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
            this.checkData();
        });

        this.memberFormGroup = new UntypedFormGroup({
            firstName: new UntypedFormControl('', [Validators.required]),
            lastName: new UntypedFormControl('', [Validators.required]),
            email: new UntypedFormControl('', [Validators.required, Validators.email]),
            phone: new UntypedFormControl('', [Validators.required]),
        });

        this.memberFormGroup.valueChanges
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() =>
                this.formErrorService.checkForErrors(this.memberFormGroup, this.formErrors, this.validationMessages)
            );
    }

    checkData(): void {
        this.formErrorService.markAsDirty(this.memberFormGroup);
        this.formErrorService.checkForErrors(this.memberFormGroup, this.formErrors, this.validationMessages);

        if (this.memberFormGroup.valid) {
            this.checkDataService.memberInformation.emit(this.memberFormGroup);
        } else {
            this.checkDataService.memberInformation.emit(false);
        }
    }

    getValidationMessages(swapValidationMessages = false): void {
        this.i18nService
            .preloadKeys(this.i18nService.getHotelTravelerFormValidationKeys())
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                const keys = this.i18nService.getSavedKeys();

                this.validationMessages = {
                    firstName: {
                        required: keys['core.errors.firstNameRequired'],
                    },
                    lastName: {
                        required: keys['core.errors.lastNameRequired'],
                    },
                    email: {
                        required: keys['core.errors.emailRequired'],
                        email: keys['core.errors.emailEmail'],
                    },
                    phone: {
                        required: keys['core.errors.phoneRequired'],
                    },
                };

                if (swapValidationMessages) {
                    this.memberFormGroup.clearValidators();
                    this.memberFormGroup.updateValueAndValidity();
                }
            });
    }
}
