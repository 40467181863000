import { Injectable } from '@angular/core';
import { TokenInfo } from '@core/models';
import { SharedStorageKeys } from '@shared/models';

@Injectable({
    providedIn: 'root',
})
export class TokenInteractionService {
    sharedStorageKeys = SharedStorageKeys;

    tokenExists(): boolean {
        if (
            localStorage.getItem(this.sharedStorageKeys.ACCESS_TOKEN) === null ||
            localStorage.getItem(this.sharedStorageKeys.EXPIRES_IN) == null
        ) {
            return false;
        }

        return true;
    }

    isValidToken(): boolean {
        const now = new Date();

        if (
            !this.tokenExists() ||
            parseInt(localStorage.getItem(this.sharedStorageKeys.EXPIRES_IN), 10) < now.getTime()
        ) {
            return false;
        }

        return true;
    }

    create(userInfo: TokenInfo): void {
        localStorage.setItem(this.sharedStorageKeys.ACCESS_TOKEN, userInfo.accessToken);
        localStorage.setItem(this.sharedStorageKeys.USER_ID, userInfo.userId);

        const expiresIn = new Date();
        localStorage.setItem(
            this.sharedStorageKeys.EXPIRES_IN,
            (expiresIn.getTime() + userInfo.expiresIn * 1000).toString()
        );
    }

    clear(): void {
        localStorage.removeItem(this.sharedStorageKeys.ACCESS_TOKEN);
        localStorage.removeItem(this.sharedStorageKeys.USER_ID);
        localStorage.removeItem(this.sharedStorageKeys.EXPIRES_IN);
        localStorage.removeItem(this.sharedStorageKeys.USER_INFO);
    }
}
