import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BookRequest, BookResponse, CardDetails } from '@cards/models';
import { environment } from '@env/environment';
import {
    BookPrice,
    CardProfile,
    ComputePriceInput,
    DataTemplate,
    GenericListItem,
    GenericSearchResponse,
    TravelerProfile,
} from '@shared/models';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class CardsService {
    constructor(private http: HttpClient) {}

    getCategories(): Observable<DataTemplate<GenericSearchResponse<GenericListItem>>> {
        const url = `${environment.cardsPath}/categories`;

        return this.http.get(url) as Observable<DataTemplate<GenericSearchResponse<GenericListItem>>>;
    }

    getBrands(): Observable<DataTemplate<GenericSearchResponse<GenericListItem>>> {
        const url = `${environment.cardsPath}/brands`;

        return this.http.get(url) as Observable<DataTemplate<GenericSearchResponse<GenericListItem>>>;
    }

    getCards(): Observable<DataTemplate<GenericSearchResponse<CardDetails>>> {
        const url = `${environment.cardsPath}/search`;

        return this.http.post(url, {}) as Observable<DataTemplate<GenericSearchResponse<CardDetails>>>;
    }

    getDetails(id: string): Observable<DataTemplate<CardDetails>> {
        const url = `${environment.cardsPath}/${id}`;

        return this.http.get(url) as Observable<DataTemplate<CardDetails>>;
    }

    getTravelerProfiles(): Observable<DataTemplate<TravelerProfile[]>> {
        const url = `${environment.cardsPath}/travprofiles`;

        return this.http.get(url) as Observable<DataTemplate<TravelerProfile[]>>;
    }

    getCardProfiles(): Observable<DataTemplate<CardProfile[]>> {
        const url = `${environment.cardsPath}/ccardprofiles`;

        return this.http.get(url) as Observable<DataTemplate<CardProfile[]>>;
    }

    computePrice(cardId: string, cardAmount: number, options?: ComputePriceInput): Observable<DataTemplate<BookPrice>> {
        const url = `${environment.cardsPath}/${cardId}/price`;

        let params = new HttpParams().set('cardAmount', cardAmount.toString());

        if (options?.payPoints || options?.payPoints === 0) {
            params = params.append('payPoints', options.payPoints.toString());
        }

        if (options?.useCredit) {
            params = params.append('useCredit', 'true');
        }

        return this.http.get(url, { params }) as Observable<DataTemplate<BookPrice>>;
    }

    book(request: BookRequest): Observable<DataTemplate<BookResponse>> {
        const url = `${environment.cardsPath}/book`;

        return this.http.post(url, request) as Observable<DataTemplate<BookResponse>>;
    }
}
