import { EventEmitter, Injectable } from '@angular/core';
import { Theme } from '@theme/models';

@Injectable()
export class ThemeService {
    themeInit: EventEmitter<Theme> = new EventEmitter<Theme>();
    themeLoaded = false;

    setTheme(theme: Theme): void {
        this.themeLoaded = true;
        this.themeInit.emit(theme);
    }

    unloadTheme(): void {
        this.themeLoaded = false;
    }

    isThemeLoaded(): boolean {
        return this.themeLoaded;
    }
}
