<div appTranslate>
    <div class="flex-container justify-content-space-between margin-bottom-10">
        <span class="price-total-label bold" [attr.data-i18n-code]="'core.labels.reservationTotal'"></span>
        <span class="price-total-value bold">{{ bookPrice?.reservationTotal | formatAssetValue }}</span>
    </div>

    <div class="margin-bottom-10"><app-points-earned [bookPrice]="bookPrice"></app-points-earned></div>

    <div *ngIf="bookPrice?.dueAtArrival?.value || bookPrice?.dueAtArrival?.fmtValue">
        <div class="price-separator margin-bottom-10"></div>

        <div class="flex-container justify-content-space-between margin-bottom-10">
            <span class="price-total-label bold" [attr.data-i18n-code]="'core.labels.totalDueToday'"></span>
            <span class="price-total-value bold">{{ bookPrice?.totalAmount | formatAssetValue }}</span>
        </div>

        <div class="price-separator margin-bottom-10"></div>

        <div class="flex-container justify-content-space-between margin-bottom-10">
            <span class="price-total-label bold" [attr.data-i18n-code]="'cars.book.dueAtPickup.label'"></span>
            <span class="price-total-value bold">{{ bookPrice?.dueAtArrival | formatAssetValue }}</span>
        </div>

        <p
            class="price-disclaimer margin-0"
            [attr.data-i18n-code]="'cars.booking.dueAtPickupDisclaimer.label'"
            [attr.data-i18n-params]="currentCurrency + '|' + (bookPrice?.dueAtArrivalLocal | formatAssetValue)"
        ></p>
    </div>
</div>
