import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ResetPasswordRequest, SiteBranding, TokenInfo, UserInfo } from '@core/models';
import { AuthService, LoaderService, UserAuthService } from '@core/services';
import { DataTemplate, SharedStorageKeys } from '@shared/models';
import { FormErrorService, I18nRefreshService, I18nService, StorageService } from '@shared/services';
import { ThemeService } from '@theme/services';
import { defer, forkJoin, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-reset-password-dialog',
    templateUrl: './reset-password-dialog.component.html',
})
export class ResetPasswordDialogComponent implements OnInit, OnDestroy {
    sharedStorageKeys = SharedStorageKeys;
    successMessage: string;
    errorMessage: string;

    passwordFormGroup: UntypedFormGroup;
    formErrors = {
        newPassword: '',
        newPassword2: '',
    };
    validationMessages = {};

    private unsubscribe: Subject<void> = new Subject<void>();

    constructor(
        public dialogRef: MatDialogRef<ResetPasswordDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { token: string; username: string },
        private userAuthService: UserAuthService,
        private i18nService: I18nService,
        private i18nRefreshService: I18nRefreshService,
        private formErrorService: FormErrorService,
        private loader: LoaderService,
        private authService: AuthService,
        private themeService: ThemeService,
        private storage: StorageService
    ) {}

    ngOnInit(): void {
        this.i18nRefreshService
            .getState()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.getValidationMessages(true);
            });

        this.getValidationMessages();
        this.createPasswordForm();
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    createPasswordForm(): void {
        this.passwordFormGroup = new UntypedFormGroup({
            newPassword: new UntypedFormControl('', [Validators.required]),
            newPassword2: new UntypedFormControl('', [Validators.required, this.passwordMatchValidator.bind(this)]),
        });

        this.passwordFormGroup.valueChanges
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() =>
                this.formErrorService.checkForErrors(this.passwordFormGroup, this.formErrors, this.validationMessages)
            );
    }

    submitPassword(): void {
        this.formErrorService.markAsDirty(this.passwordFormGroup);
        this.formErrorService.checkForErrors(this.passwordFormGroup, this.formErrors, this.validationMessages);

        if (this.passwordFormGroup.valid) {
            defer(() => {
                this.loader.show();
                this.successMessage = null;
                this.errorMessage = null;

                return this.userAuthService
                    .resetPassword(
                        new ResetPasswordRequest(this.passwordFormGroup.get('newPassword').value, this.data.token)
                    )
                    .pipe(takeUntil(this.unsubscribe));
            }).subscribe(
                (response: DataTemplate<{ message: string }>) => {
                    if (this.data.username) {
                        this.login(this.data.username, this.passwordFormGroup.get('newPassword').value);
                    } else {
                        this.loader.hide();
                        this.successMessage = response.data.message;
                    }
                },
                (error: string) => {
                    console.error(error);
                    this.errorMessage = error;
                    this.loader.hide();
                }
            );
        }
    }

    login(username: string, password: string): void {
        this.userAuthService
            .login(username, password)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(
                (loginResponse: any) => {
                    this.authService.login();
                    this.authService.setUsername(username);
                    this.authService.setToken(
                        new TokenInfo(
                            username,
                            `${loginResponse.token_type} ${loginResponse.access_token}`,
                            loginResponse.expires_in
                        )
                    );

                    this.themeService.unloadTheme();
                    this.storage.removeItem(this.sharedStorageKeys.SITE_BRANDING);

                    forkJoin([this.userAuthService.getUserInfo(), this.userAuthService.getSiteBranding()])
                        .pipe(takeUntil(this.unsubscribe))
                        .subscribe(
                            (response: [DataTemplate<UserInfo>, DataTemplate<SiteBranding>]) => {
                                localStorage.setItem(
                                    this.sharedStorageKeys.USER_INFO,
                                    JSON.stringify(response[0].data)
                                );

                                response[1].data.uiConfig?.localeOptions?.languages.forEach((language: string) => {
                                    this.storage.removeItem(`${language}_${this.sharedStorageKeys.I18N_KEYS}`);
                                });
                                this.storage.removeItem(this.sharedStorageKeys.GENERAL_KEYS_LOADED);

                                this.i18nService
                                    .preloadBrandingKeys(response[1].data)
                                    .pipe(
                                        takeUntil(this.unsubscribe),
                                        finalize(() => this.loader.hide())
                                    )
                                    .subscribe(() => {
                                        this.dialogRef.close(true);
                                    });
                            },
                            (error: string) => {
                                console.error(error);
                                this.loader.hide();
                            }
                        );
                },
                (error) => {
                    console.error(error);
                    this.errorMessage = error;
                    this.loader.hide();
                }
            );
    }

    passwordMatchValidator(control: AbstractControl): ValidationErrors {
        if (!this.passwordFormGroup) {
            return null;
        }

        return control.value !== this.passwordFormGroup.get('newPassword').value ? { passwordDiff: true } : null;
    }

    getValidationMessages(swapValidationMessages = false): void {
        this.i18nService
            .preloadKeys(this.i18nService.getAccountSettingsFormValidationKeys())
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                const keys = this.i18nService.getSavedKeys();

                this.validationMessages = {
                    newPassword: {
                        required: keys['core.errors.newPasswordRequired'],
                        passwordDiff: keys['core.errors.passwordDiff'],
                    },
                    newPassword2: {
                        required: keys['core.errors.newPassword2Required'],
                        passwordDiff: keys['core.errors.passwordDiff'],
                    },
                };

                if (swapValidationMessages) {
                    this.passwordFormGroup.clearValidators();
                    this.passwordFormGroup.updateValueAndValidity();
                }
            });
    }
}
