import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ModuleDialogContent } from '@shared/models';

@Component({
    selector: 'app-activities-dialog',
    templateUrl: './activities-dialog.component.html',
})
export class ActivitiesDialogComponent {
    content: ModuleDialogContent = {
        title: {
            value: {
                en: 'ACTIVITIES & TOURS',
                es: 'ACTIVIDADES Y TOURS',
            },
        },
        image: 'https://images.leisureloyalty.com/raintreerewards/img/club-benefit/tours.jpg',
        content: [
            {
                type: 'paragraph',
                value: {
                    en: "Activities and tours are fantastic ways to experience and get the most out of a destination - whether it's your first time in a new area, or you have traveled there countless times, there is always something new to discover. Raintree Rewards members can book a huge range of activities and attractions online, and all at discounted prices.",
                    es: 'Las actividades y tours son experiencias fantásticas y proveen lo mejor de un destino vacacional- ya sea su primera visita al lugar o sea un destino ya conocido, siempre hay nuevas cosas que descubrir. Los socios de Raintree Rewards pueden reservar una enorme variedad de actividades y atracciones en línea, y todas con tarifas y precios con descuento.',
                },
            },
            {
                type: 'title',
                value: {
                    en: 'Any activity, any time',
                    es: 'Cualquier actividad en cualquier momento',
                },
            },
            {
                type: 'paragraph',
                value: {
                    en: 'Fancy biking through a wine region, or seeing the ancient sites of the world up close and personal? See and do more when you take a vacation. As a Raintree Rewards member, you can book any number of activities and attractions without the hefty price tag.',
                    es: 'Desde visitar un parque de diversiones hasta ver una se las siete maravillas del mundo. Nos hemos aliado con operadores de tour de renombre, asegurando a nuestros socios que recibirán la mejor calidad y servicio. Nuestro objetivo es brindarle la mejor experiencia posible en sus viajes.',
                },
            },
            {
                type: 'title',
                value: {
                    en: 'Trusted names in touring',
                    es: '',
                },
            },
            {
                type: 'paragraph',
                value: {
                    en: "We partner with some of the world's leading tour companies and operators, ensuring our members receive the best service and quality. We use only trusted providers to maintain a high standard of activities and attractions for the best possible experience on your vacation.",
                    es: '',
                },
            },
            {
                type: 'paragraph',
                value: {
                    en: 'Remember that you will receive Raintree Rewards for every booking you make, and can also pay down the price using your points.',
                    es: 'Recuerde que usted recibirá recompensas por cada reservación que haga y podrá pagar con sus puntos.',
                },
            },
        ],
    };

    constructor(public dialogRef: MatDialogRef<ActivitiesDialogComponent>) {}
}
