import { Entity, Of } from '@utils';

@Entity
export class TravelerAgeBand {
    @Of(() => Number)
    index = 0;

    @Of(() => String)
    bandId = '';

    static of = Entity.of<TravelerAgeBand>();
}
