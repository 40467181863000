import { MemberName } from '@account/models/response/member-name';
import { PointsSummary } from '@account/models/response/points-summary';
import { PrimaryMember } from '@account/models/response/primary-member';
import { AssetValue } from '@shared/models/response/asset-value';
import { Entity, Of } from '@utils';

@Entity
export class AccountDetails {
    @Of(() => String)
    id: string;

    @Of(() => String)
    membershipCategory: string;

    @Of(() => String)
    membershipExpires: string;

    @Of(() => String)
    memberSince: string;

    @Of(() => String)
    membershipStatus: string;

    @Of(() => AssetValue)
    recurringAmount: AssetValue;

    @Of(() => String)
    billingCycle: string;

    @Of(() => PointsSummary)
    pointsSummary: PointsSummary;

    @Of(() => String)
    nextBillingDate: string;

    @Of(() => AssetValue)
    nextAmount: AssetValue;

    @Of(() => String)
    nextCardType: string;

    @Of(() => String)
    nextCardLast4: string;

    @Of(() => AssetValue)
    outstandingBalance: AssetValue;

    @Of(() => String)
    photo: string;

    @Of(() => PrimaryMember)
    primaryMember: PrimaryMember;

    @Of(() => MemberName, { nullable: true, optional: true })
    secondaryMember?: MemberName = null;

    @Of(() => CreditBalance)
    creditBalance: CreditBalance;

    @Of(() => Number, { optional: true })
    tierId?: number;

    @Of(() => [Number])
    availableTiers: number[] = [];

    static of = Entity.of<AccountDetails>();
}

@Entity
class CreditBalance {
    @Of(() => String)
    code: string;

    @Of(() => Number, { optional: true })
    balance?: number;

    @Of(() => String)
    type: string;

    @Of(() => Number)
    value: number;

    static of = Entity.of<CreditBalance>();
}
