import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-new-user-greet-dialog',
    templateUrl: './new-user-greet-dialog.component.html',
})
export class NewUserGreetDialogComponent implements OnInit {
    userGreetMessage: string;

    constructor(
        public dialogRef: MatDialogRef<NewUserGreetDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { message: string }
    ) {}

    ngOnInit(): void {
        this.userGreetMessage = this.data.message;
    }
}
