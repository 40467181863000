import { PayPriceBreakdown, TravelerProfile } from '@shared/models';

import {
    BookingQuestion,
    BookingRequirements,
    Itinerary,
    PickupLocation,
    ScheduleDetails,
    TicketInfo,
    TravelerPickup,
} from './activity-details';
import { LangService } from './schedule';

export class BookResponse {
    confirmCode: string;
    txGuid: string;
    payCardName: string;
    payCardType: string;
    payCardNumber: string;
    payCardExpDate: string;
    payPriceBreakdown: PayPriceBreakdown[];
    payTotalAmount: string;
    payPointsEarned: string;
    refundable: boolean;
    refundableUntil: string;

    activityDuration: string;
    activityImage: string;
    activityInformation: string;
    activityLocation: string;
    activityName: string;
    additionalInfo: string[];
    inclusions: string[];
    adults: number;
    bookingDate: string;
    travelDate: string;
    children: number;
    scheduleDescription: string;
    scheduleName: string;
    scheduleDetails: ScheduleDetails;
    travelers: TravelerProfile[];
    voucherURL: string;
    totalTravelers: number;
    langServices: LangService[] = [];
    scheduleLangServices: LangService[] = [];

    exclusions: string[];
    itinerary: Itinerary;
    ticketInfo: TicketInfo;
    bookingQuestions?: BookingQuestion[];
    bookingRequirements?: BookingRequirements;
    travelerPickup?: TravelerPickup;
    logisticsStart: PickupLocation[] = [];
    logisticsEnd: PickupLocation[] = [];

    pickupPoint?: string;
    hotelName?: string;
    languageOptionName?: string;
    languageOptionCode: string;
}
