export enum StorageKeys {
    SEARCH_REQUEST = 'flights_search_request',
    SEARCH_RESPONSE = 'flights',
    SEARCH_FILTERS = 'flights_filters',
    SEARCH_FILTERS_MIN_PRICE = 'flights_min_price',
    SEARCH_FILTERS_MAX_PRICE = 'flights_max_price',
    ITEM_TO_BOOK = 'flight_to_book',
    BOOK_RESPONSE = 'flights_book_response',
    SEARCH_ORIGIN_POINT = 'flights_search_origin_point',
    SEARCH_START_TIME = 'flights_search_start_time',
    SEARCH_RESULTS_START_TIME = 'flights_search_results_start_time',
    BOOK_START_TIME = 'flights_book_start_time',
    SEARCH_TRIGGERED = 'flights_search_triggered',
    BOOK_TRIGGERED = 'flights_book_triggered',
}
