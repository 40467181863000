import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { SiteBranding } from '@core/models';
import { environment } from '@env/environment';
import { HotelTypes } from '@hotels/models';
import { AllowedCardTypes, DataTemplate, I18nRequest, SharedStorageKeys } from '@shared/models';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { StorageService } from '../storage';

@Injectable({
    providedIn: 'root',
})
export class I18nService implements OnInit, OnDestroy {
    sharedStorageKeys = SharedStorageKeys;

    siteBranding: SiteBranding;

    private unsubscribe: Subject<void> = new Subject();

    constructor(private http: HttpClient, private storage: StorageService) {}

    ngOnInit(): void {
        this.siteBranding = JSON.parse(localStorage.getItem(this.sharedStorageKeys.SITE_BRANDING));
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    getKeys(request: I18nRequest[]): Observable<any> {
        const url = `${environment.corePath}/getI18n`;

        return this.http.post(url, request) as Observable<any>;
    }

    changeLanguage(languageCode: string): Observable<any> {
        const url = `${environment.corePath}/changeLanguage`;

        return this.http.post(url, { languageCode }) as Observable<any>;
    }

    changeCurrency(currencyCode: string): Observable<DataTemplate<AllowedCardTypes>> {
        const url = `${environment.corePath}/changeCurrency`;

        return this.http.post(url, { currencyCode }) as Observable<
            // eslint-disable-next-line @typescript-eslint/indent
            DataTemplate<AllowedCardTypes>
        >;
    }

    getSavedKeys(): Record<string, any> {
        if (!this.storage.getItem(this.sharedStorageKeys.CURRENT_LANGUAGE)) {
            this.storage.setItem(this.sharedStorageKeys.CURRENT_LANGUAGE, 'en');
        }

        let keys = this.storage.getItem<Record<string, string>>(
            `${this.storage.getItem(this.sharedStorageKeys.CURRENT_LANGUAGE)}_${this.sharedStorageKeys.I18N_KEYS}`
        );

        if (!keys) {
            keys = {};
        }

        return keys;
    }

    getKeyValue(key: string): string {
        return this.getSavedKeys()[key];
    }

    preloadKeys(
        keysArr: string[],
        paramsArr: {
            [key: string]: string;
        } = null,
        saveKeys = true
    ): Observable<any> {
        const keys = this.getSavedKeys();

        const requestObject: I18nRequest[] = [];

        keysArr.forEach((key: string) => {
            if (!keys.hasOwnProperty(key)) {
                const i18nRequest: I18nRequest = {
                    code: key,
                };

                if (paramsArr && key.split(':').length && paramsArr.hasOwnProperty(key)) {
                    i18nRequest.params = paramsArr[key].split('-');
                }

                requestObject.push(i18nRequest);
            }
        });

        if (requestObject.length) {
            return this.preloadKeysRequest(requestObject, paramsArr, saveKeys);
        }

        return new Observable((observer) => {
            observer.next();
            observer.complete();
        });
    }

    preloadBrandingKeys(siteBranding: SiteBranding, preloadLogin?: boolean): Observable<any> {
        const keysArr = [];

        siteBranding?.uiConfig?.modules?.forEach((module: string) => {
            keysArr.push(`nav.${module}.label`);
        });

        siteBranding?.uiConfig?.localeOptions?.currencies?.forEach((currency: string) => {
            keysArr.push(`core.currencies.${currency}`);
        });

        siteBranding?.uiConfig?.localeOptions?.languages?.forEach((language: string) => {
            keysArr.push(`core.languages.${language}`);
        });

        Object.keys(siteBranding?.uiConfig?.moduleOptions).forEach((module: string) => {
            if (siteBranding?.uiConfig?.moduleOptions[module].interstitialTexts) {
                siteBranding?.uiConfig?.moduleOptions[module].interstitialTexts.forEach((text: string) => {
                    keysArr.push(text);
                });
            }

            if (siteBranding?.uiConfig?.moduleOptions[module].interstitialTextsRooms) {
                siteBranding?.uiConfig?.moduleOptions[module].interstitialTextsRooms.forEach((text: string) => {
                    keysArr.push(text);
                });
            }
        });

        if (preloadLogin) {
            keysArr.push(...this.getLoginKeys());
        }

        return this.preloadKeys(keysArr);
    }

    preloadWithParamsKeys(requests: I18nRequest[], paramsKeysTarget: string): Observable<any> {
        const keys = this.getSavedKeys();
        const requestObject: I18nRequest[] = [];

        requests.forEach((request: I18nRequest) => {
            if (!keys.hasOwnProperty(`${request.code}|${paramsKeysTarget}_${request.params.join('_')}`)) {
                requestObject.push(request);
            }
        });

        if (requestObject.length) {
            return this.preloadKeysRequest(requestObject, null, true, paramsKeysTarget);
        }

        return new Observable((observer) => {
            observer.next();
            observer.complete();
        });
    }

    preloadKeysRequest(
        requestObject: I18nRequest[],
        paramsArr: {
            [key: string]: string;
        } = null,
        saveKeys = true,
        paramsKeysTarget: string = null
    ): Observable<any> {
        return new Observable((observer) => {
            this.getKeys(requestObject)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe(
                    (response: any) => {
                        if (saveKeys) {
                            const tempKeys = this.storage.getItem<Record<string, string>>(
                                `${this.storage.getItem(this.sharedStorageKeys.CURRENT_LANGUAGE)}_${
                                    this.sharedStorageKeys.I18N_KEYS
                                }`
                            );

                            let newKeys = null;

                            if (!paramsKeysTarget) {
                                newKeys = { ...tempKeys, ...response.data };

                                if (paramsArr) {
                                    for (const key in paramsArr) {
                                        if (paramsArr.hasOwnProperty(key)) {
                                            delete newKeys[key];
                                        }
                                    }
                                }
                            } else {
                                newKeys = { ...tempKeys };
                                requestObject.forEach((request: I18nRequest, index) => {
                                    newKeys[`${request.code}|${paramsKeysTarget}_${request.params.join('_')}`] =
                                        response.data[request.code] instanceof Array
                                            ? response.data[request.code][index]
                                            : response.data[request.code];
                                });
                            }

                            this.storage.setItem(
                                `${this.storage.getItem(this.sharedStorageKeys.CURRENT_LANGUAGE)}_${
                                    this.sharedStorageKeys.I18N_KEYS
                                }`,
                                newKeys
                            );
                        }

                        observer.next(response.data);
                        observer.complete();
                    },
                    (error: string) => {
                        console.error(error);
                        observer.next();
                        observer.complete();
                    }
                );
        });
    }

    getGeneralKeys(): string[] {
        return [
            'nav.about.label',
            'nav.contact.label',
            'nav.privacy.label',
            'nav.skipContent.label',
            'nav.terms.label',
            'nav.help.label',
            'core.labels.close',
            'core.pages.about',
            'core.pages.contact',
            'core.pages.privacyPolicy',
            'core.pages.termsAndConditions',
            'core.pages.cancelInstructions',
            'nav.user.account',
            'nav.user.myRewards',
            'nav.rewards.label',
            'nav.user.logout',
            'nav.user.login',
            'core.labels.cancel',
            'core.labels.clear',
            'core.labels.allFieldsRequired',
            'core.labels.loginToBook',
            'core.labels.upgradeToBook',
            'core.labels.noResults',
            'core.labels.credit',
            'core.labels.currencyChangeDialog.title',
            'core.labels.currencyChangeDialog.body',
            'core.labels.currencyChangeDialog.bodyOffer',
            'core.labels.currencyChangeDialog.confirm',
            'core.labels.taxesFeesDisclaimer',
            'core.labels.close',
            'core.labels.newTravelerDisclaimer',
            'core.labels.success',
            'core.labels.error',
            'core.labels.copyright',
            'core.currencies.LOYPNT',
            'core.labels.taxesIncluded',
            'core.labels.taxesNotIncluded',
            'core.labels.3dsErrorDisclaimer',
            'core.labels.yourRecentSearches',
            'core.labels.searchSessionExpired',
            'core.errors.3dsAuthError',
            'core.errors.3dsCardAuthFailed',
            'errorMsg.viator.bookingError',
            'core.assetValues.serviceCharge.label',
        ];
    }

    getLoginKeys(): string[] {
        return [
            'nav.about.label',
            'nav.contact.label',
            'nav.privacy.label',
            'nav.terms.label',
            'core.pages.about',
            'core.pages.contact',
            'core.pages.privacyPolicy',
            'core.pages.termsAndConditions',
            'core.labels.sessionExpired',
            'core.labels.buy',
            'core.labels.cancel',
            'core.labels.close',
            'core.labels.cardDetails',
            'core.labels.fillPaymentBelow',
            'core.labels.fillMemberInfoBelow',
            'core.labels.forgotPassword',
            'core.labels.core.labels.forgotPasswordUsername',
            'core.labels.forgotUsername',
            'core.labels.forgotUsernameLastName',
            'core.labels.forgotUsernameMemberId',
            'core.labels.goBack',
            'core.labels.memberInformation',
            'core.labels.password',
            'core.labels.registrationCode',
            'core.labels.select',
            'core.labels.signIn',
            'core.labels.signUp',
            'core.labels.signUpTerms',
            'core.labels.signUpNewsletter',
            'core.labels.submit',
            'core.labels.username',
            'core.labels.rememberMe',
            'core.labels.stateName',
            'core.labels.state',
            'core.labels.cardHolder',
            'core.labels.cardExpires',
            'core.labels.cardType',
            'core.labels.cardNumber',
            'core.labels.cardName',
            'core.labels.cardExpMonth',
            'core.labels.cardExpYear',
            'core.labels.cardCvv',
            'core.labels.billingInformation',
            'core.labels.firstName',
            'core.labels.lastName',
            'core.labels.userAddress',
            'core.labels.city',
            'core.labels.country',
            'core.labels.zip',
            'core.labels.saveCard',
            'core.labels.email',
            'core.labels.phone',
            'core.labels.popular',
            'core.labels.membershipFee',
            'core.labels.membershipFeeDisclaimer',
            'core.errors.usernameRequired',
            'core.errors.passwordRequired',
            'core.errors.emailRequired',
            'core.errors.emailEmail',
            'core.errors.lastNameRequired',
            'core.errors.ccTypeRequired',
            'core.errors.ccNumberRequired',
            'core.errors.ccNumberMaxLength',
            'core.errors.ccNameOnCardRequired',
            'core.errors.ccCvvRequired',
            'core.errors.ccExpMRequired',
            'core.errors.ccExpYRequired',
            'core.errors.firstNameRequired',
            'core.errors.lastNameRequired',
            'core.errors.emailRequired',
            'core.errors.emailEmail',
            'core.errors.phoneRequired',
            'core.errors.address1Required',
            'core.errors.cityRequired',
            'core.errors.countryIdRequired',
            'core.errors.provinceIdRequired',
            'core.errors.provinceNameRequired',
            'core.errors.postalCodeRequired',
            'core.errors.oldPasswordRequired',
            'core.errors.newPasswordRequired',
            'core.errors.newPassword2Required',
            'core.errors.passwordDiff',
            'forgotPassword.headline.label',
            'forgotPassword.info.label',
            'forgotPassword.subtitle.label',
            'forgotUsername.headline.label',
            'forgotUsername.subtitle.label',
            'hotels.booking.paymentBilling.label',
            'login.asGuest.label',
            'login.headline.label',
            'login.imageHeadline.label',
            'login.imageSubtitle.label',
            'login.subtitle.label',
            'login.resetPassword.title.label',
            'login.resetPassword.expired.label',
            'login.newUserWelcome.label',
            'signup.chooseMembership.label',
            'signup.haveCode.label',
            'signup.imageHeadline.label',
            'signup.imageSubtitle.label',
            'signup.subtitle.label',
            'signup.noMembershipsForCode.label',
            'signup.enterCodeToStart.label',
            'account.settings.newPassword.label',
            'account.settings.verifyNewPassword.label',
            'member.register.choosePasswordRequirements',
            'core.errors.invalidCredentials',
            'nav.user.account',
            'nav.skipContent.label',
            'nav.help.label',
            'core.pages.cancelInstructions',
            'nav.user.account',
            'nav.user.myRewards',
            'nav.user.logout',
            'nav.user.login',
            'core.labels.allFieldsRequired',
            'core.labels.loginToBook',
            'core.labels.noResults',
            'core.labels.credit',
            'core.labels.currencyChangeDialog.title',
            'core.labels.currencyChangeDialog.body',
            'core.labels.currencyChangeDialog.bodyOffer',
            'core.labels.currencyChangeDialog.confirm',
            'core.labels.taxesFeesDisclaimer',
            'core.labels.success',
            'core.labels.error',
            'core.labels.copyright',
            'core.currencies.LOYPNT',
            'purchaseStatus.A',
            'purchaseStatus.C',
            'purchaseStatus.P',
        ];
    }

    getForgotUsernameValidationKeys(): string[] {
        return [
            'core.errors.emailRequired',
            'core.errors.emailEmail',
            'core.errors.lastNameRequired',
            'core.errors.memberIdRequired',
        ];
    }

    getAccountKeys(): string[] {
        const keysArr = [
            'core.labels.dashboard',
            'core.labels.backDashboard',
            'core.labels.membership',
            'core.labels.trips',
            'core.labels.travelers',
            'core.labels.payments',
            'core.labels.purchases',
            'core.labels.settings',
            'core.labels.state',
            'core.labels.stateName',
            'core.labels.membershipSummary',
            'core.labels.membershipPlan',
            'core.labels.membershipStatus',
            'core.labels.membershipExpires',
            'core.labels.memberSince',
            'core.labels.pointsSummary',
            'core.labels.available',
            'core.labels.redeemed',
            'core.labels.savings',
            'core.labels.accountCredit',
            'core.labels.secondaryMember',
            'core.labels.editProfile',
            'core.labels.upcomingTrips',
            'core.labels.secondaryMember',
            'core.labels.id',
            'core.labels.backDashboard',
            'core.labels.dashboard',
            'core.labels.noExpirationDate',
            'core.labels.formResetSuccess',
            'core.labels.moreInfo',
        ];

        environment.accountNavigationItems.forEach((code: string) => {
            keysArr.push(`core.labels.${code}`);
        });

        return keysArr;
    }

    getAccountMembershipKeys(): string[] {
        return [
            'core.labels.firstName',
            'core.labels.lastName',
            'core.labels.firstName',
            'core.labels.lastName',
            'core.labels.userAddress',
            'core.labels.city',
            'core.labels.country',
            'core.labels.zip',
            'core.labels.email',
            'core.labels.phone',
            'core.labels.mobilePhone',
            'core.labels.workPhone',
            'core.labels.reset',
            'core.labels.save',
            'core.labels.updateMemberInfo',
            'core.labels.viewBillingHistory',
            'core.labels.viewPointsHistory',
            'core.labels.membershipBilling',
            'core.labels.pointsHistory',
            'core.labels.billingHistory',
            'core.labels.primaryMember',
            'core.labels.contactInfo',
            'core.labels.updatePrimaryMember',
            'core.labels.nextBilling',
            'core.labels.outstandingBalance',
            'core.labels.update',
            'core.labels.changePlan',
            'core.labels.upgrade',
            'core.labels.transactionId',
            'core.labels.date',
            'core.labels.amount',
            'core.labels.creditCard',
            'core.labels.card',
            'core.labels.status',
            'core.labels.showMore',
            'core.labels.ref',
            'core.labels.transactionType',
            'core.labels.type',
            'core.labels.descr',
            'core.labels.edit',
            'core.labels.cancel',
            'core.labels.submit',
            'core.errors.firstNameRequired',
            'core.errors.lastNameRequired',
            'core.errors.emailRequired',
            'core.errors.emailEmail',
            'core.errors.phoneRequired',
            'core.errors.address1Required',
            'core.errors.cityRequired',
            'core.errors.countryIdRequired',
            'core.errors.provinceIdRequired',
            'core.errors.provinceNameRequired',
            'core.errors.postalCodeRequired',
            'core.errors.reservationRequired',
            'core.errors.websiteRequired',
            'core.errors.priceRequired',
            'hotels.search.results.description',
            'hotels.details.address.label',
            'account.membership.profileUpdated.label',
            'account.membership.savePhoto.label',
            'account.membership.savePhotoSuccess.toastMessage',
            'core.labels.memberPriceGuaranteed',
            'core.labels.memberPriceGuaranteedDisclaimer',
            'core.labels.priceGuaranteedTerms',
            'core.labels.noPriceGuaranteedReservations',
            'account.membership.submitGuaranteedPriceSuccess.toastMessage',
        ];
    }

    getAccountPriceGuaranteedValidationKeys(): string[] {
        return ['core.errors.reservationRequired', 'core.errors.websiteRequired', 'core.errors.priceRequired'];
    }

    getAccountTripsKeys(): string[] {
        const keysArr = [
            'core.labels.upcomingTrips',
            'core.labels.pastTrips',
            'core.labels.showMore',
            'core.labels.submit',
            'core.labels.delete',
            'core.labels.cancel',
            'core.labels.date',
            'core.labels.update',
            'core.labels.leadTraveler',
            'core.labels.viewDetails',
            'account.trips.createTrip.label',
            'account.trips.createTripSuccess.toastMessage',
            'account.trips.updateTripSuccess.toastMessage',
            'account.trips.selectTripSegments.label',
            'account.trips.noResults.label',
            'account.trips.noSegmentResults.label',
            'account.trips.tripName.label',
            'core.errors.tripNameRequired',
            'core.errors.tripNameUnique',
            'core.errors.tripNameMaxLength',
            'account.trips.tripCreated.label',
            'account.trips.deleteTrip.label',
            'account.trips.confirmDeleteTrip.label',
            'account.trips.moveSegmentTitle.label',
            'account.trips.selectTrip.label',
            'account.trips.deleteItem.label',
            'account.trips.deleteTripSuccess.toastMessage',
            'account.trips.confirmDeleteItem.label',
            'account.trips.tripDetails.label',
            'account.trips.tripSegments.label',
            'account.trips.tripUpdated.label',
            'account.trips.myTrips.label',
        ];

        environment.segmentTypes.forEach((code: string) => {
            keysArr.push(`core.labels.startDate.${code}`);
            keysArr.push(`core.labels.endDate.${code}`);
        });

        return keysArr;
    }

    getAccountTravelersKeys(): string[] {
        return [
            'core.labels.savedTravelers',
            'core.labels.updateProfile',
            'core.labels.cancel',
            'core.labels.delete',
            'core.labels.newTraveler',
            'core.labels.reset',
            'core.labels.save',
            'core.labels.showMore',
            'core.labels.address',
            'core.labels.contact',
            'core.labels.userTitle',
            'core.labels.firstName',
            'core.labels.lastName',
            'core.labels.dateOfBirth',
            'core.labels.gender',
            'core.labels.nationality',
            'core.labels.streetNumber',
            'core.labels.building',
            'core.labels.city',
            'core.labels.country',
            'core.labels.state',
            'core.labels.stateName',
            'core.labels.zip',
            'core.labels.email',
            'core.labels.phone',
            'core.labels.workPhone',
            'core.labels.mobilePhone',
            'core.labels.passportCountry',
            'core.labels.passportNumber',
            'core.labels.passportIssueDate',
            'core.labels.passportExpirationDate',
            'core.labels.redressNumber',
            'core.labels.travelerNumber',
            'core.labels.travelingInformation',
            'core.labels.titleMr',
            'core.labels.titleMrs',
            'core.labels.titleMs',
            'gender.M',
            'gender.F',
            'core.errors.firstNameRequired',
            'core.errors.lastNameRequired',
            'core.errors.emailRequired',
            'core.errors.emailEmail',
            'core.errors.phoneRequired',
            'core.errors.redressNumberMaxLength',
            'core.errors.travelerNumberMaxLength',
            'core.errors.validNumber',
            'core.labels.adult',
            'core.labels.child',
            'account.travelers.profileDetails.label',
            'account.travelers.deleteTraveler.label',
            'account.travelers.confirmDeleteTraveler.label',
            'account.travelers.deleteTravelerSuccess.toastMessage',
            'travelerProfileType.GST',
            'travelerProfileType.PRI',
            'travelerProfileType.SEC',
            'account.travelers.createSuccess.label',
            'account.travelers.updateSuccess.label',
        ];
    }

    getAccountTravelerFormValidationKeys(): string[] {
        return [
            'core.errors.firstNameRequired',
            'core.errors.lastNameRequired',
            'core.errors.emailRequired',
            'core.errors.emailEmail',
            'core.errors.phoneRequired',
            'core.errors.redressNumberMaxLength',
            'core.errors.travelerNumberMaxLength',
            'core.errors.validNumber',
        ];
    }

    getAccountPaymentsKeys(): string[] {
        return [
            'core.labels.setCardDefault',
            'core.labels.delete',
            'core.labels.cancel',
            'core.labels.cardType',
            'core.labels.cardNumber',
            'core.labels.cardName',
            'core.labels.cardExpMonth',
            'core.labels.cardExpYear',
            'core.labels.cardCvv',
            'core.labels.billingInformation',
            'core.labels.firstName',
            'core.labels.lastName',
            'core.labels.email',
            'core.labels.userAddress',
            'core.labels.city',
            'core.labels.country',
            'core.labels.stateName',
            'core.labels.state',
            'core.labels.zip',
            'core.labels.setCardDefault',
            'core.labels.cardHolder',
            'core.labels.cardExpires',
            'core.labels.newCard',
            'core.labels.savedCards',
            'core.labels.reset',
            'core.labels.submit',
            'core.errors.ccTypeRequired',
            'core.errors.ccNumberRequired',
            'core.errors.ccNumberMaxLength',
            'core.errors.ccNameOnCardRequired',
            'core.errors.ccCvvRequired',
            'core.errors.ccExpMRequired',
            'core.errors.ccExpYRequired',
            'core.errors.firstNameRequired',
            'core.errors.lastNameRequired',
            'core.errors.emailRequired',
            'core.errors.emailEmail',
            'core.errors.address1Required',
            'core.errors.cityRequired',
            'core.errors.countryIdRequired',
            'core.errors.provinceIdRequired',
            'core.errors.provinceNameRequired',
            'core.errors.postalCodeRequired',
            'account.payments.profileDetails.label',
            'account.payments.updateProfile.label',
            'account.payments.deleteCard.label',
            'account.payments.confirmDeleteCard.label',
            'account.payments.deleteCardSuccess.toastMessage',
            'account.payments.createCardSuccess.toastMessage',
            'account.payments.updateCardSuccess.toastMessage',
        ];
    }

    getAccountPurchasesKeys(): string[] {
        const keysArr = [
            'core.labels.confirmationEmail',
            'core.labels.close',
            'core.labels.cancel',
            'core.labels.viewEmail',
            'core.labels.viewConfirmation',
            'core.labels.viewReceipt',
            'core.labels.viewEstimatedPrice',
            'core.labels.share',
            'core.labels.showMore',
            'core.labels.itinerary',
            'core.labels.associateToItinerary',
            'core.errors.emailRequired',
            'core.errors.emailEmail',
            'core.labels.bookNow',
            'account.purchases.shareEmail.label',
            'account.purchases.shareEmailSuccess.toastMessage',
            'account.purchases.invoiceNo.label',
            'account.purchases.confirmationNo.label',
            'account.purchases.travelDate.label',
            'account.purchases.pointsRedeemed.label',
            'account.purchases.pointsEarned.label',
            'account.purchases.noResults.label',
            'core.labels.viewDetails',
            'cards.confirmation.resendEmail',
            'core.labels.resend',
            'core.labels.email',
        ];

        return keysArr;
    }

    getAccountSettingsKeys(): string[] {
        return [
            'core.labels.language',
            'core.labels.currency',
            'core.labels.timezone',
            'core.labels.reset',
            'core.labels.save',
            'core.labels.submit',
            'core.errors.oldPasswordRequired',
            'core.errors.newPasswordRequired',
            'core.errors.newPassword2Required',
            'core.errors.passwordDiff',
            'account.settings.defaultSettings.label',
            'account.settings.changePassword.label',
            'account.settings.currentPassword.label',
            'account.settings.newPassword.label',
            'account.settings.verifyNewPassword.label',
            'account.settings.defaultsUpdated.label',
            'account.settings.passwordUpdated.label',
            'member.register.choosePasswordRequirements',
        ];
    }

    getAccountSettingsFormValidationKeys(): string[] {
        return [
            'core.errors.oldPasswordRequired',
            'core.errors.newPasswordRequired',
            'core.errors.newPassword2Required',
            'core.errors.passwordDiff',
        ];
    }

    getBillingFormValidationKeys(): string[] {
        return [
            'core.errors.ccTypeRequired',
            'core.errors.ccNumberRequired',
            'core.errors.ccNumberMaxLength',
            'core.errors.ccNumberInvalid',
            'core.errors.ccNameOnCardRequired',
            'core.errors.ccCvvRequired',
            'core.errors.ccCvvMinLength',
            'core.errors.ccCvvMaxLength',
            'core.errors.ccCvvInvalid',
            'core.errors.ccExpMRequired',
            'core.errors.ccExpYRequired',
            'core.errors.firstNameRequired',
            'core.errors.lastNameRequired',
            'core.errors.emailRequired',
            'core.errors.emailEmail',
            'core.errors.address1Required',
            'core.errors.cityRequired',
            'core.errors.countryIdRequired',
            'core.errors.provinceIdRequired',
            'core.errors.provinceNameRequired',
            'core.errors.postalCodeRequired',
            'core.errors.invoiceCompanyRequired',
            'core.errors.invoiceContactNameRequired',
        ];
    }

    getHotelsLandingKeys(): string[] {
        const keysArr = [
            'hotels.widgets.search.destinationCity',
            'hotels.widgets.search.destinationHotel',
            'core.labels.adult',
            'core.labels.adults',
            'core.labels.child',
            'core.labels.children',
            'core.labels.age',
            'core.labels.room',
            'core.labels.rooms',
            'core.labels.perRoom',
            'hotels.landing.header.heroText',
            'hotels.widgets.search.destination',
            'hotels.widgets.search.checkIn',
            'hotels.widgets.search.checkOut',
            'hotels.widgets.search.actionLabel',
            'hotels.landing.featuredDestinations.label',
            'hotels.landing.bestDeals.label',
            'hotels.landing.recent_search_hotel.label',
            'hotels.landing.recent_search_city.label',
            'hotels.general.moreDetails.label',
            'hotels.search.sort.bestMatch',
            'hotels.search.sort.distance',
            'hotels.search.sort.priceAsc',
            'hotels.search.sort.priceDesc',
            'hotels.search.sort.nameAsc',
            'hotels.search.sort.qualityAsc',
            'hotels.search.sort.qualityDesc',
            'hotels.search.sort.guestRatingAsc',
            'hotels.search.sort.guestRatingDesc',
            'hotels.search.filters.label',
            'hotels.search.filters.guestRating.noRating',
            'hotels.search.results.save',
            'hotels.search.results.awayHotel',
            'hotels.search.results.fromPrice',
            'hotels.search.results.youSave',
            'hotels.compare.button.compare',
            'hotels.compare.label.title',
            'hotels.compare.label.subtitle',
            'hotels.compare.label.disclaimer',
            'hotels.compare.vendorName.label',
            'core.assetValues.youPay.label',
            'hotels.search.results.description',
            'hotels.search.results.selectRoom',
            'hotels.search.results.label',
            'hotels.search.noResults.label',
            'hotels.details.checkAvailability.label',
            'hotels.search.map.label',
            'hotels.search.filters.propertyName',
            'hotels.search.filters.propertyType',
            'hotels.search.filters.distance',
            'hotels.search.filters.quality',
            'hotels.search.filters.guestRating',
            'hotels.search.filters.amenities',
            'hotels.search.filters.pricePerNight',
            'hotels.search.filters.reset',
            'hotels.search.filters.apply',
            'hotels.search.resultsH1.label',
            'core.labels.moreDetails',
            'core.labels.after',
        ];

        Object.keys(HotelTypes).forEach((key) => {
            if (HotelTypes.hasOwnProperty(key)) {
                keysArr.push(`hotels.types.${HotelTypes[key]}`);
            }
        });

        [
            ...(this.siteBranding?.uiConfig.moduleOptions.hotels?.interstitialTexts || []),
            ...(this.siteBranding?.uiConfig.moduleOptions.hotels?.interstitialTextsRooms || []),
        ].forEach((text: string) => {
            keysArr.push(text);
        });

        return keysArr;
    }

    getHotelDetailsKeys(): string[] {
        return [
            'hotels.details.nav.overview',
            'hotels.details.nav.photos',
            'hotels.details.nav.amenities',
            'hotels.details.nav.location',
            'hotels.details.nav.rooms',
            'hotels.details.nav.reviews',
            'hotels.details.address.label',
            'hotels.details.type.label',
            'hotels.details.address.label',
            'core.labels.readMore',
            'core.labels.readLess',
            'hotels.details.noAmenities.label',
            'hotels.details.yelp.nearby',
            'hotels.details.yelp.inputLabel',
            'hotels.details.yelp.searchLabel',
            'hotels.details.yelp.noResults',
            'hotels.details.yelp.showLabel',
            'hotels.details.yelp.reviews',
            'hotels.details.yelp.totalReviews',
            'hotels.details.noRooms.label',
            'hotels.details.noReviews.label',
            'hotels.booking.cancellationPolicy.label',
            'hotels.rate.noInformationAvailable.label',
            'hotels.taxDisclaimer',
            'core.errors.intervalNotAvailable.toastTitle',
            'core.errors.intervalNotAvailable.toastMessage',
            'hotels.details.checkRooms.toastMessage',
            'hotels.bedtype.disclaimer.label',
            'hotels.taxDisclaimer.expedia.label',
        ];
    }

    getHotelRoomKeys(): string[] {
        return [
            'hotels.widgets.search.guest',
            'hotels.widgets.search.guests',
            'hotels.details.rate.refundable',
            'hotels.details.rate.nonRefundable',
            'hotels.rate.refundableUntil.label',
            'hotels.rate.nonGuaranteedRefundable.label',
            'hotels.details.information.label',
            'hotels.details.boardType.RO',
            'hotels.details.boardType.BB',
            'hotels.details.boardType.AI',
            'hotels.details.boardType.CB',
            'hotels.details.boardType.FB',
            'hotels.details.boardType.HB',
            'hotels.rate.retail.label',
            'hotels.rate.youSave.label',
            'hotels.rate.whenRedeem.label',
            'hotels.rate.member.label',
            'hotels.rate.taxesIncluded.label',
            'hotels.rate.taxesNotIncluded.label',
            'hotels.rate.youEarn.label',
            'hotels.rate.includes.RO',
            'hotels.rate.includes.BB',
            'hotels.rate.includes.CB',
            'hotels.rate.includes.HB',
            'hotels.rate.includes.FB',
            'hotels.rate.includes.AI',
            'core.labels.bookNow',
            'core.assetValues.tax.label',
            'hotels.confirmation.dueAtProperty.label',
        ];
    }

    getHotelBookingKeys(): string[] {
        return [
            'core.restrictTravelerPrimarySecondary.label',
            'core.restrictTravelerPrimarySecondaryMultiple.label',
            'core.labels.night',
            'core.labels.nights',
            'core.labels.priceSummary',
            'core.labels.total',
            'core.labels.specialRequests',
            'core.labels.specialRequestsHint',
            'core.labels.stateName',
            'core.labels.state',
            'core.labels.savedCards',
            'core.labels.cardHolder',
            'core.labels.cardExpires',
            'core.labels.newCard',
            'core.labels.cardDetails',
            'core.labels.cardType',
            'core.labels.cardNumber',
            'core.labels.cardName',
            'core.labels.cardExpMonth',
            'core.labels.cardExpYear',
            'core.labels.cardCvv',
            'core.labels.billingInformation',
            'core.labels.firstName',
            'core.labels.lastName',
            'core.labels.userAddress',
            'core.labels.city',
            'core.labels.country',
            'core.labels.zip',
            'core.labels.saveCard',
            'core.labels.email',
            'core.labels.phone',
            'core.labels.savedTravelers',
            'core.labels.newTraveler',
            'core.labels.saveTravelerLabel',
            'core.labels.updateTravelerLabel',
            'core.errors.ccTypeRequired',
            'core.errors.ccNumberRequired',
            'core.errors.ccNumberMaxLength',
            'core.errors.ccNameOnCardRequired',
            'core.errors.ccCvvRequired',
            'core.errors.ccExpMRequired',
            'core.errors.ccExpYRequired',
            'core.errors.firstNameRequired',
            'core.errors.lastNameRequired',
            'core.errors.emailRequired',
            'core.errors.emailEmail',
            'core.errors.phoneRequired',
            'core.errors.address1Required',
            'core.errors.cityRequired',
            'core.errors.countryIdRequired',
            'core.errors.provinceIdRequired',
            'core.errors.provinceNameRequired',
            'core.errors.postalCodeRequired',
            'core.assetValues.retailPrice.label',
            'core.assetValues.youPay.label',
            'core.assetValues.tax.label',
            'hotels.booking.headline.label',
            'hotels.booking.traveler.informationLabel',
            'hotels.booking.traveler.addressLabel',
            'hotels.booking.nonRefundable.label',
            'hotels.confirmation.refNumber.label',
            'hotels.booking.leadTraveler.label',
            'hotels.booking.specialRequests.label',
            'hotels.booking.paymentBilling.label',
            'hotels.booking.rules.label',
            'hotels.details.address.label',
            'hotels.details.email.label',
            'hotels.details.phone.label',
            'hotels.rate.nonRefundable.label',
            'hotels.booking.bookHotel.label',
            'core.labels.tripItinerary',
            'core.labels.selectTripItinerary',
            'account.trips.createTrip.label',
            'account.trips.create.label',
            'account.trips.tripName.label',
            'core.errors.tripNameRequired',
            'core.errors.tripNameMaxLength',
            'core.errors.tripNameUnique',
            'account.trips.tripCreated.label',
            'core.labels.none',
            'core.labels.add',
            'core.labels.paymentMethod',
            'core.labels.hideResPrice',
            'core.labels.cardMethod',
            'core.labels.invoiceMethod',
            'core.labels.invoiceCompany',
            'core.labels.invoiceContactName',
            'core.labels.useAccountCredit',
            'core.labels.excludeCreditAmount',
            'hotels.expedia.disclaimer1.label',
            'hotels.expedia.disclaimer3.label',
            'hotels.expedia.disclaimer4.label',
            'hotels.expedia.taxDisclaimer.label',
            'hotels.taxDisclaimer',
            'hotels.booking.noLongerAvailable.label',
            'hotels.booking.nonRefundable.expedia',
            'hotels.booking.expedia.agentTerms',
            'core.labels.supplierTerms',
            'core.labels.supplierAgentTerms',
            'core.labels.refNumber',
            'hotels.booking.additionalPropertyFees.label',
            'core.labels.optionalFees',
            'core.label.ages',
            'core.labels.totalDueToday',
        ];
    }

    getHotelTravelerFormValidationKeys(): string[] {
        return [
            'core.errors.firstNameRequired',
            'core.errors.lastNameRequired',
            'core.errors.emailRequired',
            'core.errors.emailEmail',
            'core.errors.phoneRequired',
            'core.errors.address1Required',
            'core.errors.cityRequired',
            'core.errors.countryIdRequired',
            'core.errors.provinceIdRequired',
            'core.errors.provinceNameRequired',
            'core.errors.postalCodeRequired',
        ];
    }

    getHotelBookingConfirmationKeys(): string[] {
        return [
            'reservations.beginCancellation.label',
            'reservations.confirmation.print',
            'reservations.status.A',
            'reservations.status.C',
            'reservations.status.T',
            'core.labels.receipt',
            'core.labels.confirmationNumber',
            'core.labels.invoiceNumber',
            'core.labels.paymentInformation',
            'core.labels.cardHolder',
            'core.labels.cardType',
            'core.labels.cardNumber',
            'core.labels.cardExpirationDate',
            'core.labels.priceSummary',
            'core.labels.total',
            'core.labels.confirmationFooter',
            'core.labels.includes',
            'hotels.confirmation.title',
            'hotels.confirmation.subtitle',
            'hotels.confirmation.print',
            'hotels.booking.leadTraveler.label',
            'core.labels.availableAfter',
        ];
    }

    getHotelBookingCancelKeys(): string[] {
        return this.getHotelBookingConfirmationKeys().concat(this.getGenericCancelKeys());
    }

    getFlightsLandingKeys(): string[] {
        const keysArr = [
            'flights.landing.header.heroText',
            'flights.widgets.search.from',
            'flights.widgets.search.to',
            'flights.widgets.search.depart',
            'flights.widgets.search.return',
            'flights.widgets.search.class',
            'flights.landing.featuredDestinations.label',
            'cars.landing.bestDeals.label',
            'flights.search.sort.bestMatch',
            'flights.search.sort.priceAsc',
            'flights.search.sort.priceDesc',
            'flights.search.sort.departureAsc',
            'flights.search.sort.departureDesc',
            'flights.search.sort.arrivalAsc',
            'flights.search.sort.arrivalDesc',
            'flights.search.sort.stopsAsc',
            'flights.search.sort.stopsDesc',
            'flights.search.sort.durationAsc',
            'flights.search.sort.durationDesc',
            'flights.assetValues.youPay.label',
            'flights.rate.youEarn.label',
            'flights.search.results.label',
            'flights.search.filters.label',
            'flights.search.filters.noOfStops',
            'flights.search.filters.airlines',
            'flights.search.filters.reset',
            'flights.search.filters.apply',
            'flights.search.results.label',
            'flights.search.oneway.label',
            'flights.search.roundTrip.label',
            'core.labels.bookNow',
            'flight.widgets.search.infantWithSeat',
            'flight.widgets.search.infantWithoutSeat',
            'flights.leg.details.label',
            'flights.leg.rules.label',
            'flights.search.noResults.label',
            'flights.details.layover.label',
            'flight.search.andBack.label',
            'flights.search.noOfBags.label',
            'flights.widgets.search.actionLabel',
            'flights.landing.bestDeals.label',
            'core.labels.adult',
            'core.labels.adults',
            'core.labels.child',
            'core.labels.children',
            'core.labels.age',
            'flights.leg.daysOffset.label',
            'flights.search.oneWay.label',
            'flights.search.filters.cabinClasses',
            'flights.search.filters.price',
            'flights.search.resultsH1.label',
            'core.labels.moreDetails',
            'core.labels.after',
            'flights.taxDisclaimer',
            'core.labels.from',
            'core.labels.to',
        ];

        (this.siteBranding?.uiConfig.moduleOptions.flights?.interstitialTexts || []).forEach((text: string) => {
            keysArr.push(text);
        });

        return keysArr;
    }

    getFlightBookingKeys(): string[] {
        return [
            'core.restrictTravelerPrimarySecondary.label',
            'core.restrictTravelerPrimarySecondaryMultiple.label',
            'core.labels.priceSummary',
            'core.labels.total',
            'core.labels.stateName',
            'core.labels.state',
            'core.labels.savedCards',
            'core.labels.cardHolder',
            'core.labels.cardExpires',
            'core.labels.newCard',
            'core.labels.cardDetails',
            'core.labels.cardType',
            'core.labels.cardNumber',
            'core.labels.cardName',
            'core.labels.cardExpMonth',
            'core.labels.cardExpYear',
            'core.labels.cardCvv',
            'core.labels.billingInformation',
            'core.labels.firstName',
            'core.labels.lastName',
            'core.labels.userAddress',
            'core.labels.city',
            'core.labels.country',
            'core.labels.zip',
            'core.labels.saveCard',
            'core.labels.email',
            'core.labels.newTraveler',
            'core.labels.middleName',
            'core.labels.gender',
            'core.labels.dateOfBirth',
            'core.labels.passengerType',
            'core.labels.redressNumber',
            'core.labels.travelerNumber',
            'core.labels.frequentFlyerProgram',
            'core.labels.frequentFlyerNumber',
            'core.labels.saveTravelerLabel',
            'core.labels.updateTravelerLabel',
            'core.errors.ccTypeRequired',
            'core.errors.ccNumberRequired',
            'core.errors.ccNumberMaxLength',
            'core.errors.ccNameOnCardRequired',
            'core.errors.ccCvvRequired',
            'core.errors.ccExpMRequired',
            'core.errors.ccExpYRequired',
            'core.errors.firstNameRequired',
            'core.errors.lastNameRequired',
            'core.errors.emailRequired',
            'core.errors.emailEmail',
            'core.errors.address1Required',
            'core.errors.cityRequired',
            'core.errors.countryIdRequired',
            'core.errors.provinceIdRequired',
            'core.errors.provinceNameRequired',
            'core.errors.postalCodeRequired',
            'core.assetValues.retailPrice.label',
            'core.assetValues.youPay.label',
            'core.assetValues.tax.label',
            'flight.booking.headline.label',
            'flights.booking.travelers.label',
            'flights.booking.paymentBilling.label',
            'flight.booking.traveler.informationLabel',
            'cruises.booking.prevTraveler.label',
            'flights.booking.nextTraveler.label',
            'flights.booking.rules.label',
            'flight.booking.bookFlight.label',
            'flights.passengerTypes.ADT',
            'flights.passengerTypes.SNR',
            'flights.passengerTypes.childAge.C2',
            'flights.passengerTypes.childAge.C3',
            'flights.passengerTypes.childAge.C4',
            'flights.passengerTypes.childAge.C5',
            'flights.passengerTypes.childAge.C6',
            'flights.passengerTypes.childAge.C7',
            'flights.passengerTypes.childAge.C8',
            'flights.passengerTypes.childAge.C9',
            'flights.passengerTypes.childAge.C10',
            'flights.passengerTypes.childAge.C11',
            'flights.passengerTypes.childAge.C12',
            'flights.passengerTypes.childAge.C13',
            'flights.passengerTypes.childAge.C14',
            'flights.passengerTypes.childAge.C15',
            'flights.passengerTypes.childAge.C16',
            'flights.passengerTypes.childAge.C17',
            'flights.booking.restrictions.label',
            'flights.booking.cancellationPolicy.label',
            'flights.nonRefundable.label',
            'flights.booking.nonRefundable.label',
            'core.labels.tripItinerary',
            'core.labels.selectTripItinerary',
            'account.trips.createTrip.label',
            'account.trips.create.label',
            'account.trips.tripName.label',
            'core.errors.tripNameRequired',
            'core.errors.tripNameMaxLength',
            'core.errors.tripNameUnique',
            'account.trips.tripCreated.label',
            'core.labels.none',
            'core.labels.add',
            'gender.M',
            'gender.F',
            'core.labels.paymentMethod',
            'core.labels.hideResPrice',
            'core.labels.cardMethod',
            'core.labels.invoiceMethod',
            'core.labels.invoiceCompany',
            'core.labels.invoiceContactName',
            'core.labels.useAccountCredit',
            'core.labels.excludeCreditAmount',
            'flights.taxDisclaimer',
            'flights.booking.primaryTraveler.label',
            'core.labels.participant',
            'core.labels.refNumber',
        ];
    }

    getFlightTravelerFormValidationKeys(): string[] {
        return [
            'core.errors.firstNameRequired',
            'core.errors.lastNameRequired',
            'core.errors.emailRequired',
            'core.errors.emailEmail',
            'core.errors.phoneRequired',
            'core.errors.genderRequired',
            'core.errors.dateOfBirthRequired',
            'core.errors.passengerTypeRequired',
            'core.errors.redressNumberMaxLength',
            'core.errors.validNumber',
            'core.errors.travelerNumberMaxLength',
            'core.errors.validNumber',
        ];
    }

    getFlightBookingConfirmationKeys(): string[] {
        return [
            'reservations.beginCancellation.label',
            'reservations.confirmation.print',
            'reservations.status.A',
            'reservations.status.C',
            'reservations.status.T',
            'core.labels.receipt',
            'core.labels.travelers',
            'core.labels.confirmationNumber',
            'core.labels.invoiceNumber',
            'core.labels.pnrNumber',
            'core.labels.paymentInformation',
            'core.labels.cardHolder',
            'core.labels.cardType',
            'core.labels.cardNumber',
            'core.labels.cardExpirationDate',
            'core.labels.priceSummary',
            'core.labels.total',
            'core.labels.confirmationFooter',
            'flights.confirmation.title',
            'flights.confirmation.subtitle',
            'flights.confirmation.print',
            'flights.confirmation.ticketNumbers.label',
            'flights.confirmation.supplierLocatorCode.label',
            'core.labels.availableAfter',
        ];
    }

    getFlightBookingCancelKeys(): string[] {
        return this.getFlightBookingConfirmationKeys().concat(this.getGenericCancelKeys());
    }

    getCarsLandingKeys(): string[] {
        const keysArr = [
            'cars.landing.header.heroText',
            'cars.landing.featuredRentals.label',
            'cars.landing.bestDeals.label',
            'cars.widgets.search.destination',
            'cars.widgets.search.pickup',
            'cars.widgets.search.dropoff',
            'cars.widgets.search.driverAge',
            'cars.widgets.search.driverCountry',
            'cars.widgets.search.actionLabel',
            'cars.widgets.search.destinationAirports',
            'cars.widgets.search.destinationStations',
            'cars.widgets.search.destinationLocations',
            'cars.search.sort.bestMatch',
            'cars.search.sort.priceAsc',
            'cars.search.sort.priceDesc',
            'cars.search.sort.classAsc',
            'cars.search.sort.companyAsc',
            'cars.search.filters.label',
            'cars.search.filters.vehicleClasses',
            'cars.search.filters.companies',
            'cars.search.filters.reset',
            'cars.search.filters.apply',
            'cars.search.passengers.label',
            'cars.search.bags.label',
            'cars.search.description.label',
            'cars.search.company.label',
            'cars.search.pickupInfo.label',
            'cars.search.retailPrice.label',
            'cars.details.refundable',
            'cars.details.nonRefundable',
            'cars.details.cancelPolicy.label',
            'cars.search.rentalConditions.label',
            'cars.search.postpaidPrice.label',
            'cars.search.postpaidBook.label',
            'cars.search.prepaidPrice.label',
            'cars.search.prepaidBook.label',
            'core.labels.savedTravelers',
            'hotels.booking.traveler.informationLabel',
            'hotels.rate.taxesIncluded.label',
            'hotels.rate.taxesNotIncluded.label',
            'cars.search.results.label',
            'cars.search.mileage.label',
            'cars.search.noResults.label',
            'cars.search.mileage.unlimited',
            'cars.rate.youEarn.label',
            'cars.rate.whenRedeem.label',
            'cars.search.cancellationPolicy.label',
            'cars.search.results.save',
            'cars.search.results.youSave',
            'cars.search.filters.payType',
            'cars.search.filters.price',
            'cars.search.resultsH1.label',
            'core.labels.moreDetails',
            'core.labels.after',
            'cars.taxDisclaimer',
            'cars.search.transmission.label',
        ];

        (this.siteBranding?.uiConfig.moduleOptions.cars?.interstitialTexts || []).forEach((text: string) => {
            keysArr.push(text);
        });

        return keysArr;
    }

    getCarBookingKeys(): string[] {
        return [
            'core.restrictTravelerPrimarySecondary.label',
            'core.restrictTravelerPrimarySecondaryMultiple.label',
            'cars.booking.headline.label',
            'cars.booking.driver.label',
            'core.labels.savedDrivers',
            'core.labels.newDriver',
            'hotels.booking.traveler.informationLabel',
            'core.labels.userTitle',
            'core.labels.firstName',
            'core.labels.lastName',
            'core.labels.dateOfBirth',
            'core.labels.email',
            'core.labels.phone',
            'core.labels.stateName',
            'core.labels.state',
            'core.labels.savedCards',
            'core.labels.cardHolder',
            'core.labels.cardExpires',
            'core.labels.newCard',
            'core.labels.cardDetails',
            'core.labels.cardType',
            'core.labels.cardNumber',
            'core.labels.cardName',
            'core.labels.cardExpMonth',
            'core.labels.cardExpYear',
            'core.labels.cardCvv',
            'core.labels.billingInformation',
            'core.labels.firstName',
            'core.labels.lastName',
            'core.labels.userAddress',
            'core.labels.city',
            'core.labels.country',
            'core.labels.zip',
            'core.labels.saveCard',
            'core.labels.email',
            'core.labels.phone',
            'cars.booking.rules.label',
            'hotels.booking.nonRefundable.label',
            'cars.booking.bookCar.label',
            'core.assetValues.retailPrice.label',
            'core.assetValues.youPay.label',
            'core.assetValues.tax.label',
            'core.labels.total',
            'cars.booking.paymentBilling.label',
            'core.labels.priceSummary',
            'cars.booking.traveler.saveDriverLabel',
            'cars.booking.prepaidCar.label',
            'cars.booking.postpaidCar.label',
            'cars.booking.postPaidCharge.label',
            'cars.booking.termsAgree.label',
            'core.labels.saveTravelerLabel',
            'core.labels.updateTravelerLabel',
            'core.labels.tripItinerary',
            'core.labels.readMore',
            'core.labels.readLess',
            'core.labels.none',
            'core.labels.add',
            'core.labels.selectTripItinerary',
            'account.trips.createTrip.label',
            'account.trips.create.label',
            'account.trips.tripCreated.label',
            'account.trips.tripName.label',
            'cars.book.dueToday.label',
            'cars.book.dueAtPickup.label',
            'core.labels.paymentMethod',
            'core.labels.hideResPrice',
            'core.labels.cardMethod',
            'core.labels.invoiceMethod',
            'core.labels.invoiceCompany',
            'core.labels.invoiceContactName',
            'core.labels.useAccountCredit',
            'core.labels.excludeCreditAmount',
            'cars.taxDisclaimer',
            'core.labels.refNumber',
        ];
    }

    getCarDriverFormValidationKeys(): string[] {
        return [
            'core.errors.firstNameRequired',
            'core.errors.lastNameRequired',
            'core.errors.titleRequired',
            'core.errors.dateOfBirthRequired',
            'core.errors.emailRequired',
            'core.errors.emailEmail',
            'core.errors.phoneRequired',
        ];
    }

    getCarBookingConfirmationKeys(): string[] {
        return [
            'reservations.beginCancellation.label',
            'reservations.confirmation.print',
            'reservations.status.A',
            'reservations.status.C',
            'reservations.status.T',
            'core.labels.receipt',
            'cars.confirmation.estimatedPrice',
            'cars.confirmation.title',
            'cars.confirmation.subtitle',
            'cars.confirmation.print',
            'cars.confirmation.postpaidCancellationDisclaimer',
            'core.labels.confirmationNumber',
            'cars.search.company.label',
            'cars.booking.confirmation.driver',
            'core.labels.invoiceNumber',
            'core.labels.paymentInformation',
            'core.labels.cardHolder',
            'core.labels.cardType',
            'core.labels.cardNumber',
            'core.labels.cardExpirationDate',
            'core.labels.priceSummary',
            'core.labels.total',
            'core.labels.confirmationFooter',
            'core.labels.availableAfter',
        ];
    }

    getCarBookingCancelKeys(): string[] {
        return this.getCarBookingConfirmationKeys().concat(this.getGenericCancelKeys());
    }

    getCruisesLandingKeys(): string[] {
        const keysArr = [
            'cruises.landing.header.heroText',
            'cruises.landing.featuredDestinations.label',
            'cruises.landing.bestDeals.label',
            'cruises.widgets.search.destination',
            'cruises.widgets.search.date',
            'core.labels.adult',
            'core.labels.adults',
            'core.labels.child',
            'core.labels.children',
            'core.labels.age',
            'cruises.widgets.search.actionLabel',
            'core.labels.january',
            'core.labels.february',
            'core.labels.march',
            'core.labels.april',
            'core.labels.may',
            'core.labels.june',
            'core.labels.july',
            'core.labels.august',
            'core.labels.september',
            'core.labels.october',
            'core.labels.november',
            'core.labels.december',
            'cruises.search.results.label',
            'cruises.search.rating.label',
            'cruises.search.length.label',
            'cruises.search.ship.label',
            'cruises.search.line.label',
            'cruises.search.ports.label',
            'cruises.search.pricing.label',
            'cruises.search.sailDate.label',
            'cruises.search.cabinType.interior',
            'cruises.search.cabinType.oceanView',
            'cruises.search.cabinType.balcony',
            'cruises.search.cabinType.suite',
            'cruises.search.sort.bestMatch',
            'cruises.search.sort.priceAsc',
            'cruises.search.sort.priceDesc',
            'cruises.search.sort.departureAsc',
            'cruises.search.sort.departureDesc',
            'cruises.search.sort.itineraryAsc',
            'cruises.search.sort.itineraryDesc',
            'cruises.search.filters.nights',
            'cruises.search.filters.cruiseLines',
            'cruises.search.filters.destinations',
            'cruises.search.filters.label',
            'cruises.search.filters.reset',
            'cruises.search.filters.apply',
            'cruises.search.noResults.label',
            'cruises.search.details.label',
            'cruises.search.resultsH1.label',
            'hotels.rate.taxesIncluded.label',
            'hotels.rate.taxesNotIncluded.label',
            'core.labels.moreDetails',
            'core.labels.after',
            'cruises.booking.requestInformationSuccess.toastMessage',
            'core.labels.requestInformation',
            'core.labels.travelers',
            'cruises.details.departurePort.label',
            'core.labels.noOfDays',
            'cruises.cabinGrades.type.label',
            'cruises.confirmation.additionalInfo.label',
            'core.errors.destinationRequired',
            'core.errors.travelersRequired',
            'core.labels.ages',
            'cruises.landing.cabin.label',
        ];

        (this.siteBranding?.uiConfig.moduleOptions.cruises?.interstitialTexts || []).forEach((text: string) => {
            keysArr.push(text);
        });

        return keysArr;
    }

    getCruiseDetailsKeys(): string[] {
        return [
            'cruises.details.nav.overview',
            'cruises.details.nav.photos',
            'cruises.details.nav.itinerary',
            'cruises.details.nav.cabins',
            'cruises.details.name.label',
            'cruises.details.launched.label',
            'cruises.details.totalCrew.label',
            'cruises.details.tonnage.label',
            'cruises.details.length.label',
            'cruises.details.occupancy.label',
            'cruises.details.description.label',
            'cruises.details.facilities.label',
            'cruises.itinerary.day.label',
            'cruises.itinerary.ports.label',
            'cruises.itinerary.date.label',
            'cruises.itinerary.arrive.label',
            'cruises.itinerary.depart.label',
            'core.labels.startDate.CR',
            'core.labels.endDate.CR',
            'cruises.cabinGrades.departure.label',
            'cruises.cabinGrades.type.label',
            'cruises.cabinGrades.averagePrice.label',
            'cruises.cabinGrades.select.label',
            'cruises.cabinGrades.deselect.label',
            'cruises.details.noCabins.label',
            'cruises.details.grades.outside',
            'cruises.details.grades.balcony',
            'cruises.details.grades.inside',
            'cruises.details.grades.suite',
            'cruises.details.cabinNumber.label',
            'cruises.details.location.label',
            'cruises.details.sleeps.label',
            'cruises.details.bath.label',
            'cruises.details.deck.label',
            'cruises.details.bed.label',
            'cruises.details.livePricing.label',
            'core.assetValues.retailPrice.label',
            'core.assetValues.youPay.label',
            'core.labels.bookNow',
            'cruises.details.youEarn.label',
            'cruises.taxDisclaimer',
            'core.labels.noInformationAvailable',
        ];
    }

    getCruiseBookingKeys(): string[] {
        return [
            'core.restrictTravelerPrimarySecondary.label',
            'core.restrictTravelerPrimarySecondaryMultiple.label',
            'core.labels.paymentMethod',
            'core.labels.hideResPrice',
            'core.labels.cardMethod',
            'core.labels.invoiceMethod',
            'core.labels.invoiceCompany',
            'core.labels.invoiceContactName',
            'cruises.booking.nonRefundable.label',
            'cruises.booking.headline.label',
            'core.labels.night',
            'core.labels.nights',
            'cruises.booking.traveler.label',
            'core.labels.tripItinerary',
            'cruises.booking.paymentBilling.label',
            'cruises.booking.rules.label',
            'hotels.booking.nonRefundable.label',
            'cruises.booking.bookCruise.label',
            'cruises.policy.nonRefundable.label',
            'core.labels.priceSummary',
            'core.labels.savedCards',
            'core.labels.cardHolder',
            'core.labels.cardExpires',
            'core.labels.newCard',
            'core.labels.cardDetails',
            'core.labels.cardType',
            'core.labels.cardNumber',
            'core.labels.cardName',
            'core.labels.cardExpMonth',
            'core.labels.cardExpYear',
            'core.labels.cardCvv',
            'core.labels.billingInformation',
            'core.labels.firstName',
            'core.labels.lastName',
            'core.labels.userAddress',
            'core.labels.city',
            'core.labels.country',
            'core.labels.zip',
            'core.labels.saveCard',
            'core.labels.email',
            'core.labels.phone',
            'core.labels.savedTravelers',
            'core.labels.newTraveler',
            'core.labels.saveTravelerLabel',
            'core.labels.updateTravelerLabel',
            'core.errors.ccTypeRequired',
            'core.errors.ccNumberRequired',
            'core.errors.ccNumberMaxLength',
            'core.errors.ccNameOnCardRequired',
            'core.errors.ccCvvRequired',
            'core.errors.ccExpMRequired',
            'core.errors.ccExpYRequired',
            'core.errors.firstNameRequired',
            'core.errors.lastNameRequired',
            'core.errors.emailRequired',
            'core.errors.emailEmail',
            'core.errors.phoneRequired',
            'core.errors.address1Required',
            'core.errors.cityRequired',
            'core.errors.countryIdRequired',
            'core.errors.provinceIdRequired',
            'core.errors.provinceNameRequired',
            'core.errors.postalCodeRequired',
            'cruises.passengerTypes.ADT',
            'cruises.passengerTypes.CHD',
            'cruises.passengerTypes.ADT',
            'core.assetValues.tax.label',
            'core.labels.total',
            'core.labels.none',
            'core.labels.newTraveler',
            'cruises.booking.traveler.informationLabel',
            'core.labels.firstName',
            'core.labels.middleName',
            'core.labels.lastName',
            'core.labels.userTitle',
            'core.labels.passengerType',
            'core.labels.dateOfBirth',
            'core.labels.nationality',
            'core.labels.passportCountry',
            'core.labels.passportNumber',
            'core.labels.passportIssueDate',
            'core.labels.passportExpirationDate',
            'core.labels.saveTravelerLabel',
            'core.labels.updateTravelerLabel',
            'cruises.booking.nextTraveler.label',
            'cruises.booking.prevTraveler.label',
            'cruises.booking.options.label',
            'cruises.booking.seatings.label',
            'cruises.booking.tableSizes.label',
            'core.labels.selectTripItinerary',
            'account.trips.tripName.label',
            'core.labels.add',
            'account.trips.tripCreated.label',
            'account.trips.createTrip.label',
            'account.trips.create.label',
            'cruise.errors.seatingRequired',
            'cruise.errors.tableSizeRequired',
            'core.errors.firstNameRequired',
            'core.errors.lastNameRequired',
            'core.errors.genderRequired',
            'core.errors.dateOfBirthRequired',
            'core.errors.passengerTypeRequired',
            'core.errors.titleRequired',
            'core.errors.nationalityRequired',
            'core.errors.passportCountryRequired',
            'core.errors.passportNumberRequired',
            'core.errors.passportIssueDateRequired',
            'core.errors.passportExpirationDateRequired',
            'core.errors.tripNameRequired',
            'core.errors.tripNameMaxLength',
            'core.errors.tripNameUnique',
            'core.errors.invoiceCompanyRequired',
            'core.errors.invoiceContactNameRequired',
            'core.labels.state',
            'core.labels.stateName',
            'core.labels.useAccountCredit',
            'core.labels.excludeCreditAmount',
            'cruises.booking.guests.label',
            'cruises.taxDisclaimer',
            'cruises.booking.certificateApplied.label',
            'cruises.booking.primaryTraveler.label',
            'core.labels.participant',
        ];
    }

    getCruiseTravelerFormValidationKeys(): string[] {
        return [
            'core.errors.firstNameRequired',
            'core.errors.lastNameRequired',
            'core.errors.emailRequired',
            'core.errors.emailEmail',
            'core.errors.phoneRequired',
            'core.errors.genderRequired',
            'core.errors.dateOfBirthRequired',
            'core.errors.passengerTypeRequired',
            'core.errors.titleRequired',
            'core.errors.nationalityRequired',
            'core.errors.passportCountryRequired',
            'core.errors.passportNumberRequired',
            'core.errors.passportIssueDateRequired',
            'core.errors.passportExpirationDateRequired',
        ];
    }

    getCruiseBookingConfirmationKeys(): string[] {
        return [
            'reservations.beginCancellation.label',
            'reservations.confirmation.print',
            'reservations.status.A',
            'reservations.status.C',
            'reservations.status.T',
            'core.labels.receipt',
            'cruises.confirmation.title',
            'cruises.confirmation.subtitle',
            'cruises.confirmation.print',
            'cruises.confirmation.dining.label',
            'cruises.booking.tableSize.label',
            'cruises.confirmation.cabinCategory.label',
            'cruises.confirmation.cabinLocation.label',
            'cruises.confirmation.cabinDeck.label',
            'cruises.confirmation.cabinBed.label',
            'cruises.confirmation.additionalInfo.label',
            'core.labels.refNumber',
            'core.labels.confirmationNumber',
            'core.labels.travelers',
            'core.labels.invoiceNumber',
            'core.labels.paymentInformation',
            'core.labels.cruisedType',
            'core.labels.cruisedNumber',
            'core.labels.cruisedExpirationDate',
            'core.labels.confirmationFooter',
            'core.labels.availableAfter',
        ];
    }

    getCruiseBookingCancelKeys(): string[] {
        return this.getCruiseBookingConfirmationKeys().concat(this.getGenericCancelKeys());
    }

    getOfferDetailsKeys(): string[] {
        return [
            'core.labels.soldOut',
            'offers.details.buyNow.label',
            'offers.details.nav.includes',
            'offers.details.nav.photos',
            'offers.details.nav.details',
            'offers.details.nav.restrictions',
            'offers.details.nav.instructions',
            'offers.details.nav.location',
            'offers.details.ends.label',
            'offers.details.includes.label',
            'offers.details.requestReservation.label',
        ];
    }

    getOfferRequestReservationKeys(): string[] {
        return [
            'core.labels.startDate',
            'core.labels.endDate',
            'core.labels.roomType',
            'core.labels.adults',
            'core.labels.children',
            'core.labels.firstName',
            'core.labels.lastName',
            'core.labels.email',
            'core.labels.phone',
            'core.labels.close',
            'offers.booking.data.label',
            'offers.booking.traveler.label',
            'offers.request.theOffer.label',
            'offers.request.reservationDetails.label',
            'offers.details.ends.label',
            'offers.details.requestReservation.label',
            'offers.details.includes.label',
            'offers.details.nav.details',
            'offers.details.nav.restrictions',
            'offers.details.nav.instructions',
            'offers.details.requestReservation.label',
            'offers.request.confirmation.label',
        ];
    }

    getOfferRequestFormValidationKeys(): string[] {
        return [
            'core.errors.startDateRequired',
            'core.errors.endDateRequired',
            'core.errors.roomTypeRequired',
            'core.errors.adultsRequired',
            'core.errors.firstNameRequired',
            'core.errors.lastNameRequired',
            'core.errors.emailRequired',
            'core.errors.emailEmail',
            'core.errors.phoneRequired',
        ];
    }

    getOfferBookingKeys(): string[] {
        return [
            'offers.booking.headline.label',
            'offers.booking.leadTraveler.label',
            'offers.booking.paymentBilling.label',
            'offers.booking.rules.label',
            'offers.booking.cancellationAgree.label',
            'offers.booking.bookOffer.label',
        ];
    }

    getOfferTravelerFormValidationKeys(): string[] {
        return [
            'core.errors.firstNameRequired',
            'core.errors.lastNameRequired',
            'core.errors.emailRequired',
            'core.errors.emailEmail',
            'core.errors.phoneRequired',
            'core.errors.address1Required',
            'core.errors.cityRequired',
            'core.errors.countryIdRequired',
            'core.errors.provinceIdRequired',
            'core.errors.provinceNameRequired',
            'core.errors.postalCodeRequired',
        ];
    }

    getOfferBookingConfirmationKeys(): string[] {
        return [
            'core.labels.confirmationNumber',
            'core.labels.invoiceNumber',
            'core.labels.paymentInformation',
            'core.labels.cardHolder',
            'core.labels.cardType',
            'core.labels.cardNumber',
            'core.labels.cardExpirationDate',
            'core.labels.priceSummary',
            'core.labels.total',
            'core.labels.confirmationFooter',
            'offers.booking.confirmation.title',
            'offers.booking.confirmation.subtitle',
            'core.labels.confirmationPrint',
            'offers.booking.leadTraveler.label',
            'offers.booking.voucher.label',
            'core.labels.availableAfter',
        ];
    }

    getWeeksLandingKeys(): string[] {
        const keysArr = [
            'weeks.landing.header.heroText',
            'weeks.widgets.search.destination',
            'weeks.widgets.search.destinationCity',
            'weeks.widgets.search.destinationHotel',
            'weeks.widgets.search.preferredCheckIn',
            'weeks.widgets.search.within',
            'core.labels.adult',
            'core.labels.adults',
            'core.labels.child',
            'core.labels.children',
            'weeks.landing.featuredDestinations.label',
            'weeks.landing.bestDeals.label',
            'weeks.search.results.save',
            'weeks.types.R',
            'weeks.search.results.fromPrice',
            'weeks.search.results.youSave',
            'weeks.search.results.description',
            'weeks.search.results.selectRoom',
            'weeks.search.results.label',
            'weeks.search.noResults.label',
            'weeks.widgets.search.actionLabel',
            'weeks.details.checkAvailability.label',
            'weeks.search.sort.bestMatch',
            'weeks.search.sort.distance',
            'weeks.search.sort.priceAsc',
            'weeks.search.sort.priceDesc',
            'weeks.search.sort.nameAsc',
            'weeks.search.sort.guestRatingAsc',
            'weeks.search.sort.guestRatingDesc',
            'weeks.search.filters.label',
            'weeks.search.map.label',
            'weeks.search.filters.propertyName',
            'weeks.search.filters.distance',
            'weeks.search.filters.lengthOfStay',
            'weeks.search.filters.noOfNights.all',
            'weeks.search.filters.noOfNights.weekly',
            'weeks.search.filters.noOfNights.short',
            'weeks.search.filters.allInclusiveType',
            'weeks.search.filters.guestRating',
            'weeks.search.filters.guestRating.noRating',
            'weeks.search.filters.price',
            'weeks.search.filters.reset',
            'weeks.search.filters.apply',
            'weeks.search.allInclusiveType.N',
            'weeks.search.allInclusiveType.M',
            'weeks.search.allInclusiveType.O',
            'weeks.search.allInclusiveType.P',
            'weeks.search.allInclusiveType.S',
            'weeks.search.allInclusiveDesc.M',
            'weeks.search.allInclusiveDesc.O',
            'weeks.search.allInclusiveDesc.P',
            'weeks.search.allInclusiveDesc.S',
            'weeks.search.allInclusive.label',
            'weeks.search.results.adultsOnly',
            'weeks.search.resultsH1.label',
            'core.labels.after',
            'core.labels.daysOf',
        ];

        [
            ...(this.siteBranding?.uiConfig.moduleOptions.weeks?.interstitialTexts || []),
            ...(this.siteBranding?.uiConfig.moduleOptions.weeks?.interstitialTextsRooms || []),
        ].forEach((text: string) => {
            keysArr.push(text);
        });

        return keysArr;
    }

    getWeekDetailsKeys(): string[] {
        return [
            'weeks.details.address.label',
            'weeks.details.checkAvailability.label',
            'weeks.details.nav.overview',
            'weeks.details.nav.photos',
            'weeks.details.nav.amenities',
            'weeks.details.nav.location',
            'weeks.details.nav.rooms',
            'weeks.details.nav.reviews',
            'weeks.details.type.label',
            'weeks.search.results.description',
            'weeks.details.noAmenities.label',
            'weeks.details.allInclusiveInfo.label',
            'weeks.details.allInclusiveFees.label',
            'weeks.details.allInclusiveFeesDisclaimer.label',
            'weeks.details.dateOfStay.label',
            'weeks.details.unitType.label',
            'weeks.details.optionalFees.label',
            'weeks.details.mandatoryFees.label',
            'weeks.details.urgentInfo.label',
            'weeks.details.noRooms.label',
            'weeks.details.noReviews.label',
            'weeks.details.rooms.bedrooms',
            'weeks.details.rooms.bathrooms',
            'weeks.details.rooms.maxOccupancy',
            'weeks.details.rooms.kitchen',
            'weeks.details.rooms.noBedroom',
            'weeks.details.rooms.accomodationOnly',
            'weeks.details.kitchenType.F',
            'weeks.details.kitchenType.P',
            'weeks.details.kitchenType.M',
            'weeks.details.kitchenType.N',
            'weeks.rate.refundableUntil.label',
            'weeks.rate.nonRefundable.label',
            'weeks.rate.nonGuaranteedRefundable.label',
            'weeks.search.results.youSave',
            'weeks.rate.retail.label',
            'weeks.details.whenRedeem.label',
            'weeks.rate.member.label',
            'weeks.rate.taxesIncluded.label',
            'weeks.rate.taxesNotIncluded.label',
            'weeks.rate.youEarn.label',
            'weeks.rate.unavailable.label',
            'weeks.rate.unavailable.toastTitle',
            'weeks.rate.unavailable.toastMessage',
            'weeks.details.yelp.nearby',
            'weeks.details.yelp.inputLabel',
            'weeks.details.yelp.searchLabel',
            'weeks.details.yelp.noResults',
            'weeks.details.yelp.showLabel',
            'weeks.details.yelp.reviews',
            'weeks.details.yelp.totalReviews',
            'weeks.search.explicitAllInclusiveType.M',
            'weeks.search.explicitAllInclusiveType.O',
            'weeks.search.explicitAllInclusiveType.S',
            'weeks.search.explicitAllInclusiveType.P',
            'core.labels.bookNow',
            'core.labels.nights',
            'core.labels.readMore',
            'core.labels.readLess',
            'core.labels.showMore',
            'core.labels.showLess',
            'core.errors.intervalNotAvailable.toastTitle',
            'core.errors.intervalNotAvailable.toastMessage',
            'weeks.details.checkRooms.toastMessage',
        ];
    }

    getWeekBookingKeys(): string[] {
        return [
            'core.restrictTravelerPrimarySecondary.label',
            'core.restrictTravelerPrimarySecondaryMultiple.label',
            'core.labels.night',
            'core.labels.nights',
            'core.labels.adult',
            'core.labels.adults',
            'core.labels.child',
            'core.labels.children',
            'core.labels.priceSummary',
            'core.labels.total',
            'core.labels.specialRequests',
            'core.labels.specialRequestsHint',
            'core.labels.stateName',
            'core.labels.state',
            'core.labels.savedCards',
            'core.labels.cardHolder',
            'core.labels.cardExpires',
            'core.labels.newCard',
            'core.labels.cardDetails',
            'core.labels.cardType',
            'core.labels.cardNumber',
            'core.labels.cardName',
            'core.labels.cardExpMonth',
            'core.labels.cardExpYear',
            'core.labels.cardCvv',
            'core.labels.billingInformation',
            'core.labels.firstName',
            'core.labels.lastName',
            'core.labels.userAddress',
            'core.labels.city',
            'core.labels.country',
            'core.labels.zip',
            'core.labels.saveCard',
            'core.labels.email',
            'core.labels.phone',
            'core.labels.savedTravelers',
            'core.labels.newTraveler',
            'core.labels.saveTravelerLabel',
            'core.labels.updateTravelerLabel',
            'core.errors.ccTypeRequired',
            'core.errors.ccNumberRequired',
            'core.errors.ccNumberMaxLength',
            'core.errors.ccNameOnCardRequired',
            'core.errors.ccCvvRequired',
            'core.errors.ccExpMRequired',
            'core.errors.ccExpYRequired',
            'core.errors.firstNameRequired',
            'core.errors.lastNameRequired',
            'core.errors.emailRequired',
            'core.errors.emailEmail',
            'core.errors.phoneRequired',
            'core.errors.address1Required',
            'core.errors.cityRequired',
            'core.errors.countryIdRequired',
            'core.errors.provinceIdRequired',
            'core.errors.provinceNameRequired',
            'core.errors.postalCodeRequired',
            'core.assetValues.retailPrice.label',
            'core.assetValues.youPay.label',
            'core.assetValues.tax.label',
            'core.errors.tripNameRequired',
            'core.errors.tripNameMaxLength',
            'core.errors.tripNameUnique',
            'core.labels.none',
            'core.labels.add',
            'core.labels.tripItinerary',
            'core.labels.selectTripItinerary',
            'account.trips.createTrip.label',
            'account.trips.create.label',
            'account.trips.tripName.label',
            'account.trips.tripCreated.label',
            'weeks.booking.headline.label',
            'weeks.widgets.search.checkIn',
            'weeks.widgets.search.checkOut',
            'weeks.widgets.search.guests',
            'weeks.booking.leadTraveler.label',
            'weeks.booking.traveler.informationLabel',
            'weeks.booking.traveler.addressLabel',
            'weeks.booking.nonRefundable.label',
            'weeks.confirmation.refNumber.label',
            'weeks.booking.paymentBilling.label',
            'weeks.booking.rules.label',
            'weeks.details.urgentInfo.label',
            'weeks.booking.infoAgree.label',
            'weeks.booking.feesAgree.label',
            'weeks.booking.bookWeek.label',
            'core.labels.paymentMethod',
            'core.labels.hideResPrice',
            'core.labels.cardMethod',
            'core.labels.invoiceMethod',
            'core.labels.invoiceCompany',
            'core.labels.invoiceContactName',
            'core.labels.useAccountCredit',
            'core.labels.excludeCreditAmount',
            'weeks.taxDisclaimer',
            'core.labels.refNumber',
        ];
    }

    getWeekBookingConfirmationKeys(): string[] {
        return [
            'reservations.beginCancellation.label',
            'reservations.confirmation.print',
            'reservations.status.A',
            'reservations.status.C',
            'reservations.status.T',
            'core.labels.receipt',
            'core.labels.confirmationNumber',
            'core.labels.invoiceNumber',
            'core.labels.paymentInformation',
            'core.labels.cardHolder',
            'core.labels.cardType',
            'core.labels.cardNumber',
            'core.labels.cardExpirationDate',
            'core.labels.priceSummary',
            'core.labels.total',
            'core.labels.confirmationFooter',
            'weeks.confirmation.title',
            'weeks.confirmation.subtitle',
            'weeks.confirmation.print',
            'weeks.booking.leadTraveler.label',
            'core.labels.availableAfter',
        ];
    }

    getWeekBookingCancelKeys(): string[] {
        return this.getWeekBookingConfirmationKeys().concat(this.getGenericCancelKeys());
    }

    getActivitiesLandingKeys(): string[] {
        const keysArr = [
            'activities.landing.header.heroText',
            'activities.landing.featuredActivities.label',
            'activities.landing.featuredDestinations.label',
            'activities.landing.bestDeals.label',
            'activities.widgets.search.destination',
            'activities.widgets.search.date',
            'activities.widgets.search.category',
            'activities.widgets.search.actionLabel',
            'activities.search.results.moreInfo',
            'activities.search.results.from',
            'activities.search.sort.bestMatch',
            'activities.search.sort.priceAsc',
            'activities.search.sort.priceDesc',
            'activities.search.sort.ratingAsc',
            'activities.search.sort.ratingDesc',
            'activities.search.filters.label',
            'activities.search.filters.reset',
            'activities.search.filters.apply',
            'activities.search.filters.name',
            'activities.search.resultsH1.label',
            'core.labels.after',
        ];

        (this.siteBranding?.uiConfig.moduleOptions.activities?.interstitialTexts || []).forEach((text: string) => {
            keysArr.push(text);
        });

        return keysArr;
    }

    getActivityDetailsKeys(): string[] {
        return [
            'core.labels.adult',
            'core.labels.adults',
            'core.labels.child',
            'core.labels.children',
            'core.labels.senior',
            'core.labels.seniors',
            'core.labels.infant',
            'core.labels.infants',
            'core.labels.youth',
            'core.labels.ages',
            'core.labels.min',
            'core.labels.max',
            'core.labels.bookNow',
            'core.labels.whatsIncluded',
            'core.labels.pickupPoint',
            'core.labels.dropoffPoint',
            'core.labels.pickupDetails',
            'core.labels.unit',
            'activities.details.nav.overview',
            'activities.details.nav.schedule',
            'activities.details.nav.availability',
            'activities.details.nav.reviews',
            'activities.details.description.label',
            'activities.details.additionalInfo.label',
            'activities.details.inclusions.label',
            'activities.details.exclusions.label',
            'activities.details.schedule.departurePoint',
            'activities.details.schedule.endPoint',
            'activities.details.schedule.duration',
            'activities.details.schedule.departureTime',
            'activities.details.schedule.returnDetails',
            'activities.details.schedule.cancellationPolicy',
            'activities.details.noSchedules.label',
            'activities.details.retail.label',
            'activities.details.member.label',
            'activities.details.youEarn.label',
            'activities.details.noReviews.label',
            'activities.widgets.availability.date',
            'activities.widgets.availability.schedule',
            'activities.details.useWidget.label',
            'activities.details.checkAvailability.label',
            'activities.details.loadReviews.label',
            'activities.details.reviewsDisclaimer.label',
            'activities.widgets.availability.allSchedules',
            'activities.details.itineraryTitle.label',
            'activities.details.ticketType.label',
            'activities.details.ticketType.MOBILE_ONLY',
            'activities.details.ticketType.PAPER',
            'activities.details.meeting.label',
            'activities.details.meetingAndPickup',
            'activities.details.schedule.meetingPoint',
            'activities.details.MEET_EVERYONE_AT_START_POINT.label',
            'activities.details.PICKUP_AND_MEET_AT_START_POINT.label',
            'activities.details.PICKUP_EVERYONE.label',
        ];
    }

    getActivityBookingKeys(): string[] {
        return [
            'core.restrictTravelerPrimarySecondary.label',
            'core.restrictTravelerPrimarySecondaryMultiple.label',
            'activities.booking.headline.label',
            'activities.booking.travelers.label',
            'activities.booking.ageBand.label',
            'activities.booking.questions.label',
            'activities.booking.specialRequirements.label',
            'activities.booking.paymentBilling.label',
            'activities.booking.rules.label',
            'activities.booking.refundable.label',
            'activities.booking.nonRefundable.label',
            'activities.booking.bookActivity.label',
            'cruises.booking.prevTraveler.label',
            'activities.booking.nextTraveler.label',
            'activities.booking.participants.label',
            'activities.confirmation.printVoucher.label',
            'activities.booking.specialRequestsHint.label',
            'activities.booking.information.label',
            'activities.booking.traveler.informationLabel',
            'activities.booking.otherInfo.label',
            'activities.booking.pickupHotel.label',
            'activities.booking.pickupPoint.label',
            'activities.booking.language.label',
            'activities.details.arrivalDetails.label',
            'activities.details.departureDetails.label',
            'core.labels.date',
            'core.labels.priceSummary',
            'core.labels.total',
            'core.labels.specialRequests',
            'core.labels.stateName',
            'core.labels.state',
            'core.labels.savedCards',
            'core.labels.cardHolder',
            'core.labels.cardExpires',
            'core.labels.newCard',
            'core.labels.cardDetails',
            'core.labels.cardType',
            'core.labels.cardNumber',
            'core.labels.cardName',
            'core.labels.cardExpMonth',
            'core.labels.cardExpYear',
            'core.labels.cardCvv',
            'core.labels.invoiceCompany',
            'core.labels.invoiceContactName',
            'care.labels.hideResPrice',
            'core.labels.billingInformation',
            'core.labels.ADT',
            'core.labels.CHD',
            'core.labels.userTitle',
            'core.labels.firstName',
            'core.labels.lastName',
            'core.labels.userAddress',
            'core.labels.city',
            'core.labels.country',
            'core.labels.zip',
            'core.labels.saveCard',
            'core.labels.email',
            'core.labels.phone',
            'core.labels.savedTravelers',
            'core.labels.newTraveler',
            'core.labels.saveTravelerLabel',
            'core.labels.updateTravelerLabel',
            'core.errors.ccTypeRequired',
            'core.errors.ccNumberRequired',
            'core.errors.ccNumberMaxLength',
            'core.errors.ccNameOnCardRequired',
            'core.errors.ccCvvRequired',
            'core.errors.ccExpMRequired',
            'core.errors.ccExpYRequired',
            'core.errors.firstNameRequired',
            'core.errors.lastNameRequired',
            'core.errors.emailRequired',
            'core.errors.emailEmail',
            'core.errors.phoneRequired',
            'core.errors.address1Required',
            'core.errors.cityRequired',
            'core.errors.countryIdRequired',
            'core.errors.provinceIdRequired',
            'core.errors.provinceNameRequired',
            'core.errors.postalCodeRequired',
            'core.errors.invoiceCompanyRequired',
            'core.errors.invoiceContactNameRequired',
            'core.assetValues.retailPrice.label',
            'core.assetValues.youPay.label',
            'core.assetValues.tax.label',
            'core.errors.tripNameRequired',
            'core.errors.tripNameMaxLength',
            'core.errors.tripNameUnique',
            'core.labels.none',
            'core.labels.add',
            'core.labels.tripItinerary',
            'core.labels.selectTripItinerary',
            'account.trips.createTrip.label',
            'account.trips.create.label',
            'account.trips.tripName.label',
            'account.trips.tripCreated.label',
            'activities.booking.cancellationPolicy.label',
            'core.labels.useAccountCredit',
            'core.labels.excludeCreditAmount',
            'activities.taxDisclaimer',
            'activities.taxDisclaimer',
            'activities.booking.primaryTraveler.label',
            'activities.booking.iDontSeeMyLocation.label',
            'core.labels.participant',
            'core.labels.airport',
            'core.labels.rail',
            'core.labels.sea',
            'core.labels.hotel',
            'core.labels.refNumber',
        ];
    }

    getActivityTravelerFormValidationKeys(): string[] {
        return [
            'core.errors.titleRequired',
            'core.errors.firstNameRequired',
            'core.errors.lastNameRequired',
            'core.errors.emailRequired',
            'core.errors.emailEmail',
            'core.errors.phoneRequired',
            'activities.booking.errors.ageBandRequired',
        ];
    }

    getActivityQuestionsFormValidationKeys(): string[] {
        return ['core.errors.questionRequired', 'core.errors.maxLengthExceeded'];
    }

    getActivityBookingConfirmationKeys(): string[] {
        return [
            'reservations.beginCancellation.label',
            'reservations.confirmation.print',
            'reservations.status.A',
            'reservations.status.C',
            'reservations.status.T',
            'core.labels.receipt',
            'core.labels.confirmationNumber',
            'core.labels.invoiceNumber',
            'core.labels.paymentInformation',
            'core.labels.cardHolder',
            'core.labels.cardType',
            'core.labels.cardNumber',
            'core.labels.cardExpirationDate',
            'core.labels.priceSummary',
            'core.labels.total',
            'core.labels.confirmationFooter',
            'activities.confirmation.title',
            'activities.confirmation.subtitle',
            'activities.confirmation.print',
            'activities.booking.confirmation.participants',
            'activities.confirmation.viewVoucher.label',
            'core.labels.availableAfter',
        ];
    }

    getActivityBookingCancelKeys(): string[] {
        return this.getActivityBookingConfirmationKeys().concat(this.getGenericCancelKeys());
    }

    getCardsLandingKeys(): string[] {
        const keysArr = [
            'cards.landing.header.heroText',
            'cards.widgets.search.cardType',
            'cards.widgets.search.category',
            'cards.widgets.search.actionLabel',
            'cards.search.results.label',
            'cards.search.noResults.label',
            'cards.search.results.digital',
            'cards.search.results.physical',
            'cards.search.results.chooseDenomination',
            'cards.search.results.inputDenomination',
            'cards.search.results.purchase',
            'cards.search.details.termsConditions',
            'cards.search.details.cancellationPolicy',
            'cards.search.nonRefundable.label',
            'cards.search.details.redemptionInstructions',
            'core.errors.denominationRequired',
            'core.errors.denominationInvalid',
            'core.labels.after',
        ];

        (this.siteBranding?.uiConfig.moduleOptions.ecards?.interstitialTexts || []).forEach((text: string) => {
            keysArr.push(text);
        });

        return keysArr;
    }

    getCardDenominationFormValidationKeys(): string[] {
        return ['core.errors.denominationRequired', 'core.errors.denominationInvalid'];
    }

    getCardBookingKeys(): string[] {
        return [
            'cards.booking.headline.label',
            'cards.booking.cardAmount.label',
            'cards.booking.travelers.label',
            'cards.booking.personalMessage.label',
            'cards.booking.personalMessageHint.label',
            'cards.booking.mailingAddress.label',
            'cards.booking.paymentBilling.label',
            'cards.booking.rules.label',
            'cards.booking.terms.label',
            'cards.booking.purchaseCard.label',
            'core.labels.priceSummary',
            'core.labels.total',
            'core.labels.stateName',
            'core.labels.state',
            'core.labels.savedCards',
            'core.labels.cardHolder',
            'core.labels.cardExpires',
            'core.labels.newCard',
            'core.labels.cardDetails',
            'core.labels.cardType',
            'core.labels.cardNumber',
            'core.labels.cardName',
            'core.labels.cardExpMonth',
            'core.labels.cardExpYear',
            'core.labels.cardCvv',
            'core.labels.invoiceCompany',
            'core.labels.invoiceContactName',
            'care.labels.hideResPrice',
            'core.labels.billingInformation',
            'core.labels.firstName',
            'core.labels.lastName',
            'core.labels.userAddress',
            'core.labels.city',
            'core.labels.country',
            'core.labels.zip',
            'core.labels.saveCard',
            'core.labels.email',
            'core.labels.phone',
            'core.labels.savedTravelers',
            'core.labels.newTraveler',
            'core.labels.saveTravelerLabel',
            'core.labels.updateTravelerLabel',
            'core.errors.ccTypeRequired',
            'core.errors.ccNumberRequired',
            'core.errors.ccNumberMaxLength',
            'core.errors.ccNameOnCardRequired',
            'core.errors.ccCvvRequired',
            'core.errors.ccExpMRequired',
            'core.errors.ccExpYRequired',
            'core.errors.firstNameRequired',
            'core.errors.lastNameRequired',
            'core.errors.emailRequired',
            'core.errors.emailEmail',
            'core.errors.phoneRequired',
            'core.errors.address1Required',
            'core.errors.cityRequired',
            'core.errors.countryIdRequired',
            'core.errors.provinceIdRequired',
            'core.errors.provinceNameRequired',
            'core.errors.postalCodeRequired',
            'core.errors.invoiceCompanyRequired',
            'core.errors.invoiceContactNameRequired',
            'core.assetValues.retailPrice.label',
            'core.assetValues.youPay.label',
            'core.assetValues.tax.label',
            'core.labels.useAccountCredit',
            'core.labels.excludeCreditAmount',
            'cards.taxDisclaimer',
        ];
    }

    getCardBookingConfirmationKeys(): string[] {
        return [
            'reservations.beginCancellation.label',
            'reservations.confirmation.print',
            'reservations.status.A',
            'reservations.status.C',
            'reservations.status.T',
            'core.labels.receipt',
            'core.labels.confirmationNumber',
            'core.labels.invoiceNumber',
            'core.labels.paymentInformation',
            'core.labels.cardHolder',
            'core.labels.cardType',
            'core.labels.cardNumber',
            'core.labels.cardExpirationDate',
            'core.labels.priceSummary',
            'core.labels.total',
            'core.labels.confirmationFooter',
            'cards.confirmation.title',
            'cards.confirmation.subtitle',
            'cards.confirmation.print',
            'cards.confirmation.recipientName',
            'cards.confirmation.recipientEmail',
            'cards.search.details.termsConditions',
            'cards.search.details.cancellationPolicy',
            'cards.search.details.redemptionInstructions',
            'core.labels.availableAfter',
        ];
    }

    getGenericCancelKeys(): string[] {
        return [
            'reservations.cancellation.title',
            'reservations.cancellation.penaltyDatePassed',
            'reservations.cancellation.abortCancellation',
            'reservations.cancellation.abortCancellationMobile',
            'reservations.cancellation.priceSummary',
            'reservations.cancellation.penaltyFee',
            'reservations.cancellation.accountCreditRefundDue',
            'reservations.cancellation.paymentRefundDue',
            'reservations.cancellation.pointsAdjustment',
            'reservations.cancellation.cancelReservation',
            'reservations.cancellation.dialogHeader',
            'reservations.cancellation.completeCancellation',
            'reservations.cancellation.disclaimer',
            'reservations.cancellation.reasonForCancellation',
            'reservations.cancellation.reasonDisclaimer',
        ];
    }

    getBookingCancelConfirmationKeys(): string[] {
        return [
            'reservations.cancellation.confirmationTitle',
            'reservations.cancellation.confirmationSubtitle',
            'reservations.cancellation.paidByCredit',
            'reservations.cancellation.totalRefund',
            'reservations.cancellation.accountCreditRefund',
        ];
    }

    getRewardsSearchKeys(): string[] {
        const keysArr = [
            'core.labels.cancel',
            'core.labels.pointsSummary',
            'core.labels.viewPointsHistory',
            'core.labels.selectSortOption',
            'rewards.browseRewards.label',
            'rewards.myRewards.label',
            'rewards.search.filters.categories',
            'rewards.search.results.label',
            'rewards.search.noResults.label',
            'rewards.search.sort.priceAsc',
            'rewards.search.sort.priceDesc',
            'rewards.search.sort.categories',
            'rewards.search.filters.sortBy',
            'rewards.search.details.label',
            'rewards.details.expiration.label',
            'rewards.details.expirationDisclaimer.label',
            'rewards.details.redemptionInstructions.label',
            'rewards.details.backToRewards.label',
            'rewards.details.addToMyRewards.label',
            'rewards.purchase.redeemWarning.label',
            'rewards.purchase.addToMyRewards.label',
            'rewards.purchase.expiresOn.label',
            'rewards.purchase.successfullyAdded.label',
        ];

        (this.siteBranding?.uiConfig.moduleOptions.rewards?.interstitialTexts || []).forEach((text: string) => {
            keysArr.push(text);
        });

        return keysArr;
    }

    getRewardsPurchaseKeys(): string[] {
        return [
            'rewards.booking.headline.label',
            'rewards.booking.paymentBilling.label',
            'rewards.booking.importantInformation.label',
            'rewards.booking.termsAgree.label',
            'rewards.booking.purchaseReward.label',
            'core.labels.priceSummary',
            'core.labels.total',
            'core.labels.stateName',
            'core.labels.state',
            'core.labels.savedCards',
            'core.labels.cardHolder',
            'core.labels.cardExpires',
            'core.labels.newCard',
            'core.labels.cardDetails',
            'core.labels.cardType',
            'core.labels.cardNumber',
            'core.labels.cardName',
            'core.labels.cardExpMonth',
            'core.labels.cardExpYear',
            'core.labels.cardCvv',
            'core.labels.invoiceCompany',
            'core.labels.invoiceContactName',
            'care.labels.hideResPrice',
            'core.labels.billingInformation',
            'core.labels.firstName',
            'core.labels.lastName',
            'core.labels.userAddress',
            'core.labels.city',
            'core.labels.country',
            'core.labels.zip',
            'core.labels.saveCard',
            'core.errors.ccTypeRequired',
            'core.errors.ccNumberRequired',
            'core.errors.ccNumberMaxLength',
            'core.errors.ccNameOnCardRequired',
            'core.errors.ccCvvRequired',
            'core.errors.ccExpMRequired',
            'core.errors.ccExpYRequired',
            'core.errors.firstNameRequired',
            'core.errors.lastNameRequired',
            'core.errors.address1Required',
            'core.errors.cityRequired',
            'core.errors.countryIdRequired',
            'core.errors.provinceIdRequired',
            'core.errors.provinceNameRequired',
            'core.errors.postalCodeRequired',
            'core.errors.invoiceCompanyRequired',
            'core.errors.invoiceContactNameRequired',
            'core.assetValues.retailPrice.label',
            'core.assetValues.youPay.label',
            'core.assetValues.tax.label',
            'core.labels.useAccountCredit',
            'core.labels.excludeCreditAmount',
        ];
    }

    getRewardsUserKeys(): string[] {
        return [
            'core.labels.hide',
            'rewards.myRewards.current.label',
            'rewards.myRewards.info.label',
            'rewards.myRewards.redeem.label',
            'rewards.myRewards.validThrough.label',
            'rewards.myRewards.youEarned.label',
            'rewards.myRewards.fulfilled.label',
            'rewards.myRewards.expired.label',
            'rewards.myRewards.pastRewards.label',
            'rewards.details.expiration.label',
            'rewards.details.expirationDisclaimer.label',
            'rewards.details.redemptionInstructions.label',
            'rewards.details.backToRewards.label',
            'rewards.details.addToMyRewards.label',
            'rewards.myRewards.viewPastRewards.label',
            'rewards.myRewards.pastRewards.label',
            'rewards.myRewards.expiredOn.label',
            'rewards.myRewards.redeemedOn.label',
        ];
    }

    getRewardsRedeemCodeKeys(): string[] {
        return [
            'core.errors.codeRequired',
            'core.labels.code',
            'core.labels.pointsSummary',
            'core.labels.viewPointsHistory',
            'rewards.browseRewards.label',
            'rewards.myRewards.label',
            'rewards.redeemCode.label',
            'rewards.redeemCode.enterCode.label',
            'rewards.details.addToMyRewards.label',
        ];
    }

    getEscapesLandingKeys(): string[] {
        const keysArr = [
            'core.labels.january',
            'core.labels.february',
            'core.labels.march',
            'core.labels.april',
            'core.labels.may',
            'core.labels.june',
            'core.labels.july',
            'core.labels.august',
            'core.labels.september',
            'core.labels.october',
            'core.labels.november',
            'core.labels.december',
            'core.labels.from',
            'core.labels.available',
            'core.labels.notAvailable',
            'escapes.widgets.search.destination',
            'escapes.landing.header.heroText',
            'escapes.widgets.search.date',
            'escapes.widgets.search.actionLabel',
            'escapes.landing.featuredDestinations.label',
            'escapes.landing.featuredEscapes.label',
            'escapes.widgets.search.allRegions',
            'escapes.search.viewEscape.label',
            'escapes.search.externalEscapeBadge.label',
            'escapes.search.noResults.label',
            'escapes.search.perNightDouble.label',
            'escapes.search.perStayDouble.label',
            'escapes.search.perNightSingle.label',
            'escapes.search.perStaySingle.label',
        ];

        (this.siteBranding?.uiConfig.moduleOptions.escapes?.interstitialTexts || []).forEach((text: string) => {
            keysArr.push(text);
        });

        return keysArr;
    }

    getEscapeDetailsKeys(): string[] {
        return [
            'core.labels.readMore',
            'core.labels.readLess',
            'core.labels.days',
            'core.labels.nights',
            'core.labels.gallery',
            'core.labels.whatsIncluded',
            'core.labels.arrival',
            'core.labels.departure',
            'core.labels.readMore',
            'core.labels.roomSize',
            'core.labels.bedding',
            'core.labels.maxOccupancy',
            'core.labels.add',
            'core.labels.readMore',
            'core.labels.roomSize',
            'core.labels.bedding',
            'core.labels.maxOccupancy',
            'core.labels.add',
            'core.labels.roomSize',
            'core.labels.bedding',
            'core.labels.maxOccupancy',
            'core.labels.add',
            'core.labels.itinerary',
            'core.labels.day',
            'core.labels.day',
            'core.labels.location',
            'core.labels.daysPrior',
            'core.labels.days',
            'core.labels.daysOrLess',
            'core.labels.charges',
            'core.labels.cancellationAndPenalty',
            'core.labels.perPersonPerTime',
            'core.labels.nameChangeFees',
            'core.labels.perPersonPerTime',
            'core.labels.bookNow',
            'core.labels.room',
            'core.labels.age12plus',
            'core.labels.ages3to11',
            'core.labels.infant',
            'core.labels.ages0to2',
            'core.labels.taxesIncluded',
            'core.assetValues.tax.label',
            'escapes.details.nav.about',
            'escapes.details.nav.accommodations',
            'escapes.details.nav.itinerary',
            'escapes.details.nav.policies',
            'escapes.details.nav.itinerary',
            'escapes.details.nav.policies',
            'escapes.details.roomSelection.label',
            'escapes.details.roomSelection.label',
            'escapes.details.about.label',
            'escapes.details.estimatedGroup.label',
            'escapes.details.closestAirport.label',
            'escapes.details.transferService.label',
            'escapes.details.accommodations.label',
            'escapes.details.remainingRooms.label',
            'escapes.details.remainingRooms.label',
            'escapes.details.remainingRooms.label',
            'escapes.details.factsToKnow.label',
            'escapes.details.thingsToNote.label',
            'escapes.details.policies.label',
            'escapes.details.bookingPolicies.label',
            'escapes.details.bookingPoliciesDisclaimer.label',
        ];
    }

    getEscapeBookingKeys(): string[] {
        return [
            'core.labels.state',
            'core.labels.stateName',
            'core.labels.savedCards',
            'core.labels.cardHolder',
            'core.labels.cardExpires',
            'core.labels.newCard',
            'core.labels.cardDetails',
            'core.labels.cardType',
            'core.labels.cardNumber',
            'core.labels.cardName',
            'core.labels.cardExpMonth',
            'core.labels.cardExpYear',
            'core.labels.cardCvv',
            'core.labels.billingInformation',
            'core.labels.invoiceCompany',
            'core.labels.invoiceContactName',
            'core.labels.userTitle',
            'core.labels.firstName',
            'core.labels.lastName',
            'core.labels.email',
            'core.labels.phone',
            'core.labels.userAddress',
            'core.labels.city',
            'core.labels.country',
            'core.labels.state',
            'core.labels.stateName',
            'core.labels.zip',
            'core.labels.saveCard',
            'core.labels.hideResPrice',
            'core.labels.tripItinerary',
            'core.labels.paymentBilling',
            'core.labels.newTraveler',
            'core.labels.information',
            'core.labels.saveTravelerLabel',
            'core.labels.updateTravelerLabel',
            'core.labels.none',
            'core.labels.priceSummary',
            'core.labels.return',
            'core.labels.guests',
            'core.labels.selectTripItinerary',
            'core.assetValues.retailPrice.label',
            'core.assetValues.tax.label',
            'core.labels.accountCredit',
            'core.labels.total',
            'hotels.rate.taxesIncluded.label',
            'account.trips.create.label',
            'core.errors.titleRequired',
            'core.errors.firstNameRequired',
            'core.errors.lastNameRequired',
            'core.errors.emailRequired',
            'core.errors.emailEmail',
            'core.errors.phoneRequired',
            'core.errors.ccTypeRequired',
            'core.errors.ccNumberRequired',
            'core.errors.ccNumberMaxLength',
            'core.errors.ccNameOnCardRequired',
            'core.errors.ccCvvRequired',
            'core.errors.ccExpMRequired',
            'core.errors.ccExpYRequired',
            'core.errors.firstNameRequired',
            'core.errors.lastNameRequired',
            'core.errors.emailRequired',
            'core.errors.emailEmail',
            'core.errors.phoneRequired',
            'core.errors.address1Required',
            'core.errors.cityRequired',
            'core.errors.countryIdRequired',
            'core.errors.provinceIdRequired',
            'core.errors.provinceNameRequired',
            'core.errors.postalCodeRequired',
            'core.errors.invoiceCompanyRequired',
            'core.errors.invoiceContactNameRequired',
            'escapes.booking.headline.label',
            'escapes.booking.terms.label',
            'escapes.booking.travelers.label',
            'escapes.booking.rules.label',
            'escapes.booking.bookThisEscape.label',
        ];
    }

    getEscapeTravelerFormValidationKeys(): string[] {
        return [
            'core.errors.titleRequired',
            'core.errors.firstNameRequired',
            'core.errors.lastNameRequired',
            'core.errors.emailRequired',
            'core.errors.emailEmail',
            'core.errors.phoneRequired',
        ];
    }

    getEscapeBookingConfirmationKeys(): string[] {
        return [
            'core.labels.paymentInformation',
            'core.labels.cardExpirationDate',
            'core.labels.confirmationNumber',
            'core.labels.leadTraveler',
            'core.labels.confirmationFooter',
            'core.labels.availableAfter',
            'core.labels.invoiceNumber',
            'escapes.confirmation.title',
            'escapes.confirmation.subtitle',
            'escapes.confirmation.print',
        ];
    }

    getEscapeBookingCancelKeys(): string[] {
        return this.getEscapeBookingConfirmationKeys().concat(this.getGenericCancelKeys());
    }

    getMembershipBookingKeys(): string[] {
        return [
            'core.labels.recurring',
            'core.labels.upgradeMembership',
            'core.labels.membershipInformation',
            'core.labels.memberInformation',
            'membership.booking.termsAgree.label',
            'account.membership.upgradeSuccess.toastMessage',
            'core.restrictTravelerPrimarySecondary.label',
            'core.restrictTravelerPrimarySecondaryMultiple.label',
            'core.labels.night',
            'core.labels.nights',
            'core.labels.priceSummary',
            'core.labels.total',
            'core.labels.specialRequests',
            'core.labels.specialRequestsHint',
            'core.labels.stateName',
            'core.labels.state',
            'core.labels.savedCards',
            'core.labels.cardHolder',
            'core.labels.cardExpires',
            'core.labels.newCard',
            'core.labels.cardDetails',
            'core.labels.cardType',
            'core.labels.cardNumber',
            'core.labels.cardName',
            'core.labels.cardExpMonth',
            'core.labels.cardExpYear',
            'core.labels.cardCvv',
            'core.labels.billingInformation',
            'core.labels.firstName',
            'core.labels.lastName',
            'core.labels.userAddress',
            'core.labels.city',
            'core.labels.country',
            'core.labels.zip',
            'core.labels.saveCard',
            'core.labels.email',
            'core.labels.phone',
            'core.labels.savedTravelers',
            'core.labels.newTraveler',
            'core.labels.saveTravelerLabel',
            'core.labels.updateTravelerLabel',
            'core.errors.ccTypeRequired',
            'core.errors.ccNumberRequired',
            'core.errors.ccNumberMaxLength',
            'core.errors.ccNameOnCardRequired',
            'core.errors.ccCvvRequired',
            'core.errors.ccExpMRequired',
            'core.errors.ccExpYRequired',
            'core.errors.firstNameRequired',
            'core.errors.lastNameRequired',
            'core.errors.emailRequired',
            'core.errors.emailEmail',
            'core.errors.phoneRequired',
            'core.errors.address1Required',
            'core.errors.cityRequired',
            'core.errors.countryIdRequired',
            'core.errors.provinceIdRequired',
            'core.errors.provinceNameRequired',
            'core.errors.postalCodeRequired',
            'core.assetValues.retailPrice.label',
            'core.assetValues.youPay.label',
            'core.assetValues.tax.label',
            'hotels.booking.headline.label',
            'hotels.booking.traveler.informationLabel',
            'hotels.booking.traveler.addressLabel',
            'hotels.booking.nonRefundable.label',
            'hotels.confirmation.refNumber.label',
            'hotels.booking.leadTraveler.label',
            'hotels.booking.specialRequests.label',
            'hotels.booking.paymentBilling.label',
            'hotels.booking.rules.label',
            'hotels.details.address.label',
            'hotels.details.email.label',
            'hotels.details.phone.label',
            'hotels.rate.taxesIncluded.label',
            'hotels.rate.nonRefundable.label',
            'account.trips.tripCreated.label',
            'core.labels.accountCredit',
            'core.labels.none',
            'core.labels.add',
            'core.labels.paymentMethod',
            'core.labels.hideResPrice',
            'core.labels.cardMethod',
            'core.labels.invoiceMethod',
            'core.labels.invoiceCompany',
            'core.labels.invoiceContactName',
            'core.labels.useAccountCredit',
            'core.labels.excludeCreditAmount',
            'hotels.taxDisclaimer',
        ];
    }
}
