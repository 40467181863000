export enum Modules {
    HOTELS = 'hotels',
    FLIGHTS = 'air',
    CARS = 'cars',
    CAR = 'car',
    CRUISES = 'cruises',
    WEEKS = 'weeks',
    ACTIVITIES = 'activities',
    ESCAPES = 'escapes',
}

export type MODULES =
    | 'hotels'
    | 'cars'
    | 'cruises'
    | 'flights'
    | 'activities'
    | 'weeks'
    | 'ecards'
    | 'offers'
    | 'rewards'
    | 'escapes'
    | 'membership';

export const EXPEDIA_SUPPLIER_IDS = [65, 66];
