<div class="row">
    <ng-container *ngFor="let traveler of travelerProfiles">
        <div
            class="col-md-3 col-sm-4 col-xs-6 col-xxxs-12 margin-bottom-20 margin-xs-bottom-10"
            *ngIf="!traveler.hidden"
        >
            <button
                class="traveler-profile cursor-pointer flex-container flex-column justify-content-center height-100 width-100 ada-button"
                [class.active]="traveler.active"
                [class.disabled]="traveler.disabled || !traveler.available"
                (click)="emitTravelerProfile(traveler)"
            >
                <p class="traveler-name margin-bottom-0">{{ traveler.firstName }} {{ traveler.lastName }}</p>
                <p class="traveler-email text-ellipsis margin-bottom-0" matTooltip="{{ traveler.email }}">
                    {{ traveler.email }}
                </p>
            </button>
        </div>
    </ng-container>

    <div
        appTranslate
        class="col-md-3 col-sm-4 col-xs-6 col-xxxs-12 margin-bottom-20 margin-xs-bottom-10"
        *ngIf="multipleTravelerIndex > 0 || (!restrictedTraveler && travelerProfiles?.length)"
    >
        <button
            class="traveler-profile cursor-pointer flex-container flex-column justify-content-center height-100 width-100 ada-button"
            [class.active]="newTraveler"
            (click)="emitTravelerProfile(null)"
        >
            <p class="traveler-name margin-bottom-5">+ <span [attr.data-i18n-code]="newTravelerLabel"></span></p>
            <p class="traveler-email-placeholder margin-bottom-0"></p>
        </button>
    </div>
</div>

<div
    appTranslate
    class="restrict-traveler-info"
    *ngIf="travelerProfiles.length && restrictedTraveler && !multipleTravelers && multipleTravelerIndex === 0"
>
    <span [attr.data-i18n-code]="'core.restrictTravelerPrimarySecondary.label'"></span>
</div>

<div
    appTranslate
    class="restrict-traveler-info"
    *ngIf="travelerProfiles.length && restrictedTraveler && multipleTravelers && multipleTravelerIndex === 0"
>
    <span [attr.data-i18n-code]="'core.restrictTravelerPrimarySecondaryMultiple.label'"></span>
</div>
