import { TierDetails } from '@account/models';
import { AccountService } from '@account/services';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MembershipBookRequest, SiteBranding, UserInfo } from '@core/models';
import { LoaderService, UserAuthService } from '@core/services';
import { BookResponse, StorageKeys } from '@offers/models';
import { AssetValue, CardProfile, Country, DataTemplate, SelectOption, SharedStorageKeys } from '@shared/models';
import { CheckDataService, I18nService, LocationsService, ToastService, UrlService } from '@shared/services';
import { defer, forkJoin, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-membership-booking',
    templateUrl: './booking.component.html',
    styleUrls: ['./booking.component.scss'],
})
export class MembershipBookingComponent implements OnInit, OnDestroy {
    sharedStorageKeys = SharedStorageKeys;
    storageKeys = StorageKeys;
    siteBranding: SiteBranding;

    userInfo: UserInfo;
    membershipId = Number(sessionStorage.getItem(this.sharedStorageKeys.TIER_TO_UPGRADE));
    currentTier: TierDetails = TierDetails.of({});
    upgradeTier: TierDetails = TierDetails.of({});

    loaded: boolean;
    cancellationAgree: boolean;
    priceCharge: AssetValue;

    termsAgree: boolean;
    cardAgree: boolean;
    cardProfiles: CardProfile[];
    countries: SelectOption[] = [];

    bookRequest: MembershipBookRequest;

    errorMessage: string;

    @ViewChild('errorElement') errorEl: ElementRef;

    private unsubscribe: Subject<void> = new Subject<void>();

    constructor(
        private url: UrlService,
        private router: Router,
        private loader: LoaderService,
        private accountService: AccountService,
        private locationsService: LocationsService,
        private checkDataService: CheckDataService,
        private userAuthService: UserAuthService,
        private toast: ToastService,
        private i18nService: I18nService
    ) {}

    ngOnInit(): void {
        sessionStorage.setItem(this.sharedStorageKeys.SHOW_CURRENCY_ALERT, 'membership');
        this.userInfo = JSON.parse(localStorage.getItem(this.sharedStorageKeys.USER_INFO) || null) as UserInfo;

        this.siteBranding = JSON.parse(localStorage.getItem(this.sharedStorageKeys.SITE_BRANDING)) as SiteBranding;

        this.loaded = true;
        this.populateContent();
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    populateContent(): void {
        this.bookRequest = MembershipBookRequest.of({
            membershipId: this.membershipId,
        });

        forkJoin({
            countries: this.locationsService.getCountries(),
            paymentProfiles: this.accountService.getMembershipCardProfiles(),
            currentTierDetails: this.accountService.getTierDetails(this.userInfo.tierId),
            upgradeTierDetails: this.accountService.getTierDetails(this.membershipId),
        })
            .pipe(
                takeUntil(this.unsubscribe),
                finalize(() => (this.loaded = true))
            )
            .subscribe(
                (response: {
                    countries: DataTemplate<Country[]>;
                    paymentProfiles: DataTemplate<CardProfile[]>;
                    currentTierDetails: DataTemplate<TierDetails>;
                    upgradeTierDetails: DataTemplate<TierDetails>;
                }) => {
                    response.countries.data.forEach((country) => {
                        this.countries.push({
                            value: country.id,
                            viewValue: country.label,
                        });
                    });

                    this.cardProfiles = response.paymentProfiles.data;
                    this.currentTier = TierDetails.of(response.currentTierDetails.data);
                    this.upgradeTier = TierDetails.of(response.upgradeTierDetails.data);
                },
                (error: string) => {
                    console.error(error);
                }
            );

        this.checkDataService.cardProfile
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((response: UntypedFormGroup | number) => {
                if (response) {
                    if (typeof response === 'number') {
                        this.bookRequest.ppId = response || null;
                        this.bookRequest.card = null;
                        this.bookRequest.saveCard = null;
                    } else {
                        this.bookRequest.card = response ? response.value || null : null;
                        this.bookRequest.saveCard = response ? response.value.saveCard : null;
                        this.bookRequest.ppId = null;
                    }
                } else {
                    this.bookRequest.card = null;
                    this.bookRequest.ppId = null;
                }
            });
    }

    updatePriceCharge(charge: AssetValue): void {
        this.priceCharge = null;

        setTimeout(() => {
            this.priceCharge = AssetValue.of(charge);
        }, 0);
    }

    submitBooking(): void {
        this.checkDataService.checkData.emit();

        if (this.validBookRequest(MembershipBookRequest.of(this.bookRequest))) {
            defer(() => {
                this.loader.show();
                return this.accountService
                    .upgradeMembership(MembershipBookRequest.of(this.bookRequest))
                    .pipe(takeUntil(this.unsubscribe));
            }).subscribe(
                (response: DataTemplate<BookResponse>) => {
                    this.userAuthService
                        .refreshUserInfo()
                        .pipe(
                            takeUntil(this.unsubscribe),
                            finalize(() => this.loader.hide(true))
                        )
                        .subscribe(
                            () => {
                                sessionStorage.setItem(this.storageKeys.BOOK_RESPONSE, JSON.stringify(response.data));

                                this.toast.show({
                                    variant: 'success',
                                    message: this.i18nService.getKeyValue(
                                        'account.membership.upgradeSuccess.toastMessage'
                                    ),
                                });

                                this.router.navigateByUrl(this.url.getUrl('account-membership'));
                            },
                            (error: string) => {
                                console.error(error);
                                this.errorMessage = error;
                                this.errorEl.nativeElement.scrollIntoView({ behavior: 'smooth' });
                            }
                        );
                },
                (error: string) => {
                    console.error(error);
                    this.errorMessage = error;
                    this.errorEl.nativeElement.scrollIntoView({ behavior: 'smooth' });
                }
            );
        } else {
            setTimeout(() => {
                const element: Element = document.querySelectorAll('.app-form-element.ng-invalid.ng-dirty')[0];
                element.scrollIntoView({ behavior: 'smooth' });
                document.getElementById(element.id).focus();
            }, 100);
        }
    }

    validBookRequest(request: MembershipBookRequest): boolean {
        return request.ppId !== null || (request.card !== null && request.card?.ccNumber !== undefined);
    }
}
