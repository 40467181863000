import { StorageKeys as ActivitiesStorageKeys } from '@activities/models';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { StorageKeys as CardsStorageKeys } from '@cards/models';
import { StorageKeys as CarsStorageKeys } from '@cars/models';
import { StorageKeys as CruisesStorageKeys } from '@cruises/models';
import { StorageKeys as EscapesStorageKeys } from '@escapes/models';
import { StorageKeys as FlightsStorageKeys } from '@flights/models';
import { StorageKeys as HotelsStorageKeys } from '@hotels/models';
import { NguCarouselConfig } from '@ngu/carousel';
import { FeaturedDestination } from '@shared/models';
import { AdobeAnalyticsEventsService } from '@shared/services';
import { StorageKeys as WeeksStorageKeys } from '@weeks/models';

@Component({
    selector: 'app-featured-destinations',
    templateUrl: './featured-destinations.component.html',
    styleUrls: ['./featured-destinations.component.scss'],
})
export class FeaturedDestinationsComponent implements OnInit {
    @Input()
    tiles: FeaturedDestination[];

    @Input()
    title: string;

    @Input()
    module: string;

    @Input()
    originPointLabel: string;

    destCarouselTileConfig: NguCarouselConfig = {
        grid: { xs: 1, sm: 2, md: 3, lg: 5, all: 0 },
        speed: 250,
        point: {
            visible: true,
        },
        touch: false,
        loop: true,
        animation: 'lazy',
    };

    modulesVar52 = {
        hotels: 'Hotel Discounts: Learn More',
        cruises: 'Cruise Offer: Learn More',
    };

    @ViewChild('destCarouselPrev') destCarouselPrev: ElementRef;
    @ViewChild('destCarouselNext') destCarouselNext: ElementRef;

    constructor(private adobeAnalyticsEventsService: AdobeAnalyticsEventsService) {}

    ngOnInit(): void {
        this.tiles.forEach((t) => {
            if (t.searchUrl.startsWith('a')) {
                t.searchUrl = '/' + t.searchUrl;
            }
        });
    }

    triggerDestCarouselNav(direction: string): void {
        switch (direction) {
            case 'prev':
                this.destCarouselPrev.nativeElement.click();
                break;
            case 'next':
                this.destCarouselNext.nativeElement.click();
                break;
        }
    }

    setOriginPoint(item: FeaturedDestination): void {
        if (this.getModuleKeys()) {
            sessionStorage.setItem(this.getModuleKeys().SEARCH_START_TIME, new Date().getTime().toString());
            sessionStorage.setItem(this.getModuleKeys().SEARCH_ORIGIN_POINT, this.originPointLabel);
            sessionStorage.setItem(this.getModuleKeys().SEARCH_TRIGGERED, 'true');
        }

        const eventData = {
            events: 'event65',
            linkTrackVars: 'events,eVar34',
            linkTrackEvents: 'event65',
            eVar34: item.name,
        };

        if (this.modulesVar52[this.module]) {
            eventData.linkTrackVars += ',eVar51,eVar52';
            eventData['eVar51'] = 'Getaway Home';
            eventData['eVar52'] = this.modulesVar52[this.module];
        }

        this.adobeAnalyticsEventsService.sendEvent('Event 65', eventData);
    }

    private getModuleKeys(): any {
        switch (this.module) {
            case 'hotels':
                return HotelsStorageKeys;
            case 'flights':
                return FlightsStorageKeys;
            case 'cars':
                return CarsStorageKeys;
            case 'cruises':
                return CruisesStorageKeys;
            case 'weeks':
                return WeeksStorageKeys;
            case 'activities':
                return ActivitiesStorageKeys;
            case 'cards':
                return CardsStorageKeys;
            case 'escapes':
                return EscapesStorageKeys;
        }
    }
}
