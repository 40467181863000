import { Component, Input, OnInit } from '@angular/core';
import { FooterOptions, SiteBranding } from '@core/models';
import { UrlService } from '@shared/services';

@Component({
    selector: 'app-ltp-footer',
    templateUrl: './ltp-footer.component.html',
    styleUrls: ['./ltp-footer.component.scss'],
})
export class LTPFooterComponent implements OnInit {
    @Input()
    siteBranding: SiteBranding;

    @Input()
    logoUrl: string;

    @Input()
    copyright: string;

    footerOptions: FooterOptions;

    currentYear: number = new Date().getFullYear();

    constructor(public url: UrlService) {}

    ngOnInit(): void {
        this.footerOptions = this.siteBranding?.uiConfig.siteOptions.designOptions.footer;
    }
}
