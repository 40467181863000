import { Flight } from './flight';

export class SearchResponse {
    items: Flight[];
    filters: SearchFilters;
}

export class SearchFilters {
    cabinClasses: string[];
    noOfStops: number[];
    companies: FlightCompany[];
}

export class FlightCompany {
    constructor(public id: string, public name: string, public active?: boolean) {}
}

export class FlightCabinClass {
    constructor(public code: string) {}
}
