import { AssetValue } from '@shared/models';

export class CabinPrice {
    constructor(
        public retailPrice: AssetValue,
        public youPay: AssetValue,
        public earns: AssetValue,
        public earnDelayDate?: string
    ) {}
}
