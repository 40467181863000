import { Amenity } from '@hotels/models/response/amenity';
import { AdobeAnalyticsDetails, AssetValue, GoogleAnalyticsDetails, Location } from '@shared/models';

export class Hotel {
    address: string;
    amenities: Amenity[];
    guestRating: GuestRating;
    distance: number;
    hasRooms: boolean;
    id: string;
    imageUrl: string;
    location: Location;
    name: string;
    rating: number;
    retailPrice: AssetValue;
    type: string;
    youPay: AssetValue;
    youSave: AssetValue;
    youSavePct: number;

    index: number;
    youSavePercentage: number;
    stars: number[];
    distanceLabel: string;
    price: number;
    guestRatingValue: number;
    mapInfoActive: boolean;
    awayCity: string;
    ga: GoogleAnalyticsDetails;
    oa: AdobeAnalyticsDetails;
}

class GuestRating {
    rating: number;
    imageUrl: string;
    count: number;
}

export class HotelDetails extends Hotel {
    city: string;
    description: string;
    images: string[];
    type: string;
    phone: string;
    email: string;
}

export enum HotelTypes {
    HOTEL = 'Hotel',
    APARTMENT = 'Apartment',
    HOSTEL = 'Hostel',
    OTHER = 'Accomodation',
}
