import { Component, Input, OnInit } from '@angular/core';
import { SearchRequest } from '@flights/models';

@Component({
    selector: 'app-flights-recent-search',
    templateUrl: './flights-recent-search.component.html',
    styleUrls: ['./flights-recent-search.component.scss'],
})
export class FlightsRecentSearchComponent implements OnInit {
    @Input()
    search: SearchRequest;

    adults: number;
    children: number;

    ngOnInit(): void {
        this.adults = this.search.adults;
        this.children = this.search.children;
    }
}
