export class SelectedGuests {
    adults: number;
    children: {
        value: number;
        ages: { value: number }[];
    };
}

export class SelectedGuestsAgeBands {
    adults: number;
    seniors?: number;
    youth?: number;
    children: {
        value: number;
        ages: { value: number }[];
    };
    infants?: number;
}
