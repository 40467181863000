import { AssetValue } from '@shared/models';

export class ConfirmationDetails {
    details: any;
    email: string;
    module: string;
    status: string;
    cancelPenaltyDate: string;
    cancellationPolicy: string;
    cancellationPenaltyFee: AssetValue;
    invoice: boolean;
    accountCreditUsed: AssetValue;
    accountCreditRefundDue: AssetValue;
    paymentRefundDue: AssetValue;
    loyaltyPointsApplied: AssetValue;
    loyaltyPointsEarned: AssetValue;
    loyaltyPointsRefundDue: AssetValue;
    loyaltyPointsReverseDue: AssetValue;
    loyaltyPointsReversed: AssetValue;
    earnDelayDate: string;
    strLoyaltyPointsRefundDue: string;
    strLoyaltyPointsReversedDue: string;
    strLoyaltyPointsTotalAdj: string;
}
