import { AgeBand } from '@activities/models';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectedGuestsAgeBands } from '@shared/models';

@Component({
    selector: 'app-guests-counter-age-bands',
    templateUrl: './guests-counter-age-bands.component.html',
    styleUrls: ['./guests-counter-age-bands.component.scss'],
})
export class GuestsCounterAgeBandsComponent implements OnInit {
    @Input()
    isFixed: boolean;

    @Input()
    isActive: boolean;

    @Input()
    hotelDetailsPage: boolean;

    @Input()
    activityDetailsPage: boolean;

    @Input()
    defaultChildAge = 0;

    @Input()
    adultsCounter = 1;

    @Input()
    seniorsCounter = 0;

    @Input()
    youthCounter = 0;

    @Input()
    childrenCounter = 0;

    @Input()
    infantsCounter = 0;

    @Input()
    ageBands: AgeBand[] = [];

    @Input()
    minGuests = 1;

    @Input()
    maxGuests = 9;

    minAdults = 1;
    minSeniors = 0;
    minYouth = 0;
    minChildren = 0;
    minInfants = 0;

    @Output()
    updatedGuests: EventEmitter<SelectedGuestsAgeBands> = new EventEmitter<SelectedGuestsAgeBands>();

    showCounters: boolean;
    disableAdultsIncrement: boolean;
    disableSeniorsIncrement: boolean;
    disableYouthIncrement: boolean;
    disableChildrenIncrement: boolean;
    disableInfantsIncrement: boolean;
    selectedAge: boolean;

    travelerAgeBand: AgeBand;
    adultAgeBand: AgeBand;
    seniorAgeBand: AgeBand;
    youthAgeBand: AgeBand;
    childAgeBand: AgeBand;
    infantAgeBand: AgeBand;

    ngOnInit(): void {
        this.ageBands.forEach((ageBand) => {
            if (ageBand.bandId === 'TRAVELER') {
                this.travelerAgeBand = ageBand;
                this.minAdults = this.travelerAgeBand.minTravelersPerBooking;
                this.adultsCounter = this.travelerAgeBand.minTravelersPerBooking || 1;
            }

            if (ageBand.bandId === 'ADULT') {
                this.adultAgeBand = ageBand;
                this.minAdults = this.adultAgeBand.minTravelersPerBooking;
                this.adultsCounter = this.adultAgeBand.minTravelersPerBooking || 1;
            }

            if (ageBand.bandId === 'SENIOR' && ageBand.maxTravelersPerBooking > 0) {
                this.seniorAgeBand = ageBand;
                this.seniorsCounter = ageBand.minTravelersPerBooking;
            }

            if (ageBand.bandId === 'YOUTH' && ageBand.maxTravelersPerBooking > 0) {
                this.youthAgeBand = ageBand;
                this.youthCounter = ageBand.minTravelersPerBooking;
            }

            if (ageBand.bandId === 'CHILD' && ageBand.maxTravelersPerBooking > 0) {
                this.childAgeBand = ageBand;
                this.childrenCounter = ageBand.minTravelersPerBooking;
            }

            if (ageBand.bandId === 'INFANT' && ageBand.maxTravelersPerBooking > 0) {
                this.infantAgeBand = ageBand;
                this.infantsCounter = ageBand.minTravelersPerBooking;
            }
        });

        this.updateGuests(true);
    }

    computeGuests(): void {
        const childrenAges = [];

        for (let i = 0; i < this.childrenCounter; i++) {
            childrenAges.push({ value: this.ageBands.find((ageBand) => ageBand.bandId === 'CHILD').startAge });
        }

        for (let i = 0; i < this.youthCounter; i++) {
            childrenAges.push({ value: this.ageBands.find((ageBand) => ageBand.bandId === 'YOUTH').startAge });
        }

        for (let i = 0; i < this.infantsCounter; i++) {
            childrenAges.push({ value: this.ageBands.find((ageBand) => ageBand.bandId === 'INFANT').startAge });
        }

        this.updatedGuests.emit({
            adults: this.adultsCounter + this.seniorsCounter,
            youth: this.youthCounter,
            children: {
                value: this.childrenCounter + this.infantsCounter + this.youthCounter,
                ages: childrenAges,
            },
            seniors: this.seniorsCounter,
            infants: this.infantsCounter,
        });

        this.showCounters = false;
    }

    updateGuests(force = false): void {
        if (force) {
            this.computeGuests();
        } else if (this.selectedAge) {
            this.selectedAge = false;
        } else if (this.showCounters) {
            this.computeGuests();
        }
    }

    updateAdults(amount: number): void {
        this.adultsCounter += this.adultsCounter > 0 ? amount : amount < 0 ? 0 : amount;

        const maxAdults = this.adultAgeBand?.maxTravelersPerBooking || this.travelerAgeBand?.maxTravelersPerBooking;

        if (this.adultsCounter >= maxAdults) {
            this.disableAdultsIncrement = true;
        } else {
            this.disableAdultsIncrement = false;
        }

        if (this.adultsCounter === 1) {
            document.getElementById('increment-adults').focus();
        }

        this.checkMaxOccupancy();
    }

    updateSeniors(amount: number): void {
        this.seniorsCounter += this.seniorsCounter > 0 ? amount : amount < 0 ? 0 : amount;

        if (this.seniorsCounter >= this.seniorAgeBand?.maxTravelersPerBooking) {
            this.disableSeniorsIncrement = true;
        } else {
            this.disableSeniorsIncrement = false;
        }

        if (this.seniorsCounter === 1) {
            document.getElementById('increment-seniors').focus();
        }

        this.checkMaxOccupancy();
    }

    updateYouth(amount: number): void {
        this.youthCounter += this.youthCounter > 0 ? amount : amount < 0 ? 0 : amount;

        if (this.youthCounter >= this.youthAgeBand?.maxTravelersPerBooking) {
            this.disableYouthIncrement = true;
        } else {
            this.disableYouthIncrement = false;
        }

        if (this.youthCounter === 1) {
            document.getElementById('increment-youth').focus();
        }

        this.checkMaxOccupancy();
    }

    updateChildren(amount: number): void {
        this.childrenCounter += this.childrenCounter > 0 ? amount : amount < 0 ? 0 : amount;

        if (this.childrenCounter >= this.childAgeBand?.maxTravelersPerBooking) {
            this.disableChildrenIncrement = true;
        } else {
            this.disableChildrenIncrement = false;
        }

        if (this.childrenCounter === 1) {
            document.getElementById('increment-children').focus();
        }

        this.checkMaxOccupancy();
    }

    updateInfants(amount: number): void {
        this.infantsCounter += this.infantsCounter > 0 ? amount : amount < 0 ? 0 : amount;

        if (this.infantsCounter >= this.infantAgeBand?.maxTravelersPerBooking) {
            this.disableInfantsIncrement = true;
        } else {
            this.disableInfantsIncrement = false;
        }

        if (this.infantsCounter === 1) {
            document.getElementById('increment-infants').focus();
        }

        this.checkMaxOccupancy();
    }

    checkMaxOccupancy(): void {
        const totalTravelers =
            this.adultsCounter + this.seniorsCounter + this.youthCounter + this.childrenCounter + this.infantsCounter;

        if (totalTravelers - this.minAdults < this.maxGuests - this.minAdults && totalTravelers < this.maxGuests) {
            const maxAdults = this.adultAgeBand?.maxTravelersPerBooking || this.travelerAgeBand?.maxTravelersPerBooking;

            if (this.adultsCounter < maxAdults) {
                this.disableAdultsIncrement = false;
            }

            if (this.seniorsCounter < this.seniorAgeBand?.maxTravelersPerBooking) {
                this.disableSeniorsIncrement = false;
            }

            if (this.youthCounter < this.youthAgeBand?.maxTravelersPerBooking) {
                this.disableYouthIncrement = false;
            }

            if (this.childrenCounter < this.childAgeBand?.maxTravelersPerBooking) {
                this.disableChildrenIncrement = false;
            }

            if (this.infantsCounter < this.infantAgeBand?.maxTravelersPerBooking) {
                this.disableInfantsIncrement = false;
            }
        } else {
            this.disableAdultsIncrement = true;
            this.disableSeniorsIncrement = true;
            this.disableYouthIncrement = true;
            this.disableChildrenIncrement = true;
            this.disableInfantsIncrement = true;
        }
    }

    addListeners(): void {
        setTimeout(() => {
            document.querySelectorAll('.cdk-overlay-container').forEach((el: Element) => {
                el.addEventListener('click', () => {
                    this.selectedAge = true;
                });
            });
        }, 0);
    }

    removeListeners(): void {
        document.querySelectorAll('.cdk-overlay-container').forEach((el: Element) => {
            el.removeEventListener('click', null);
        });
    }
}
