import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SharedStorageKeys } from '@shared/models';
import { UrlService } from '@shared/services';

@Component({
    selector: 'app-redirect-handler',
    templateUrl: './redirect-handler.component.html',
})
export class RedirectHandlerComponent {
    sharedStorageKeys = SharedStorageKeys;

    constructor(private router: Router, private url: UrlService) {}

    ngOnInit() {
        const siteBranding = JSON.parse(localStorage.getItem(this.sharedStorageKeys.SITE_BRANDING));

        if (!siteBranding?.uiConfig.siteOptions.isHomeEnabled) {
            const loginRedirect = siteBranding?.uiConfig?.userOptions?.loginRedirect;

            if (loginRedirect?.startsWith('http')) {
                window.location.href = loginRedirect;
            } else {
                this.router.navigateByUrl(loginRedirect || this.url.getUrl('login'));
            }
        } else {
            this.router.navigateByUrl(this.url.getUrl('home'));
        }
    }
}
