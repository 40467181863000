import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Balance, MenuLink, SiteBranding, UserInfo } from '@core/models';
import { AuthService } from '@core/services';
import { MenuItem, SharedStorageKeys } from '@shared/models';
import { UrlService, UserInfoUpdateService } from '@shared/services';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-navigation-user-details',
    templateUrl: './navigation-user-details.component.html',
    styleUrls: ['./navigation-user-details.component.scss'],
})
export class NavigationUserDetailsComponent implements OnInit, OnDestroy {
    @Input()
    navTarget: string;

    sharedStorageKeys = SharedStorageKeys;
    userInfo: UserInfo;
    userInitials: string;
    siteBranding: SiteBranding;
    loggedIn$: Observable<boolean>;
    isGuest: boolean;

    showUserMenu: boolean;
    menuItems: MenuItem[] = [];

    private unsubscribe: Subject<void> = new Subject();

    constructor(
        public url: UrlService,
        private authService: AuthService,
        private userInfoUpdateService: UserInfoUpdateService
    ) {}

    ngOnInit(): void {
        this.loggedIn$ = this.authService.loggedIn;
        this.isGuest = this.authService.isGuest();

        this.siteBranding = JSON.parse(localStorage.getItem(this.sharedStorageKeys.SITE_BRANDING)) as SiteBranding;

        if (!this.siteBranding?.uiConfig?.siteOptions?.designOptions?.header?.topMenu?.length) {
            this.siteBranding?.uiConfig?.modules?.forEach((module: string) => {
                this.menuItems.push({
                    code: `nav.${module}.label`,
                    active: false,
                    customUrl: this.url.getUrl(module),
                });
            });
        } else {
            this.siteBranding?.uiConfig?.siteOptions?.designOptions?.header?.topMenu.forEach((item: MenuLink) => {
                this.menuItems.push({
                    code: item.name,
                    active: false,
                    customUrl: this.url.getUrl(item.customUrl),
                    target: item.target,
                });
            });
        }

        this.checkUserInfo();

        this.loggedIn$.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
            this.checkUserInfo();
        });

        this.userInfoUpdateService.update.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
            this.checkUserInfo();
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    checkUserInfo(): void {
        this.userInfo = JSON.parse(localStorage.getItem(this.sharedStorageKeys.USER_INFO) || null) as UserInfo;

        if (this.userInfo && !this.authService.isGuest()) {
            this.userInitials = `${this.userInfo.firstName[0]}${this.userInfo.lastName[0]}`;

            if (this.userInfo.photo && !this.userInfo.photo.includes('base64')) {
                this.userInfo.photo = 'data:image/jpg;base64,' + this.userInfo.photo;
            }

            if (this.userInfo.balances?.length > 1) {
                this.userInfo.balances = this.userInfo.balances.sort((a: Balance, b: Balance) =>
                    a.type === 'points' && b.type !== 'points' ? -1 : 1
                );
            }
        }
    }

    logout(): void {
        this.userInfo = null;
        this.userInitials = null;
        this.authService.logout();
        sessionStorage.setItem(this.sharedStorageKeys.PREVENT_SESSION_TIMEOUT_TOAST, 'true');
    }
}
