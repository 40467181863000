import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class UrlService {
    getUrl(forPage: string, data?: Record<string, any>): string {
        let url: string = null;

        switch (forPage) {
            case 'login':
                url = '/login';
                break;
            case 'home':
                url = '/home';
                break;
            case 'account':
                url = '/account';
                break;
            case 'account-membership':
                url = '/account/membership';
                break;
            case 'account-trips':
                url = '/account/trips';
                break;
            case 'account-trip':
                url = `/account/trips/${data.id}`;
                break;
            case 'account-travelers':
                url = '/account/travelers';
                break;
            case 'account-payments':
                url = '/account/payments';
                break;
            case 'account-purchases':
                url = '/account/purchases';
                break;
            case 'account-settings':
                url = '/account/settings';
                break;
            case 'about':
                url = '/about';
                break;
            case 'contact':
                url = '/contact';
                break;
            case 'privacy':
                url = '/privacy';
                break;
            case 'terms':
                url = '/terms';
                break;
            case 'upgrade':
                url = '/upgrade';
                break;
            case 'upgrade-booking':
                url = '/upgrade/checkout';
                break;
            case 'pages':
                url = `/pages/${data.pageId}`;
                break;
            case 'hotels':
                url = '/hotels';
                break;
            case 'hotel-details':
                url = `/hotels/${data.id}`;
                break;
            case 'hotels-search':
                url = '/hotels/search';
                break;
            case 'hotels-saved-search':
                url = `/hotels/search/${data.savedSearchUrl}`;
                break;
            case 'hotel-book':
                url = `/hotels/book`;
                break;
            case 'hotel-book-confirmation':
                url = `/hotels/book/confirmation`;
                break;
            case 'hotels-book-cancel':
                url = `/hotels/book/cancel/${data.id}`;
                break;
            case 'hotels-confirmation':
                url = `/hotels/book/confirmation/${data.id}`;
                break;
            case 'hotels-receipt':
                url = `/hotels/book/receipt/${data.id}`;
                break;
            case 'flights':
                url = '/flights';
                break;
            case 'flights-search':
                url = '/flights/search';
                break;
            case 'flight-book':
                url = `/flights/book`;
                break;
            case 'flight-book-confirmation':
                url = `/flights/book/confirmation`;
                break;
            case 'flights-book-cancel':
                url = `/flights/book/cancel/${data.id}`;
                break;
            case 'flights-confirmation':
                url = `/flights/book/confirmation/${data.id}`;
                break;
            case 'flights-receipt':
                url = `/flights/book/receipt/${data.id}`;
                break;
            case 'cars':
                url = '/cars';
                break;
            case 'cars-search':
                url = '/cars/search';
                break;
            case 'car-book':
                url = `/cars/book`;
                break;
            case 'car-book-confirmation':
                url = `/cars/book/confirmation`;
                break;
            case 'cars-book-cancel':
                url = `/cars/book/cancel/${data.id}`;
                break;
            case 'cars-confirmation':
                url = `/cars/book/confirmation/${data.id}`;
                break;
            case 'cars-receipt':
                url = `/cars/book/receipt/${data.id}`;
                break;
            case 'cars-cancel':
                url = `/cars/book/cancel/${data.id}`;
                break;
            case 'weeks':
                url = '/weeks';
                break;
            case 'week-details':
                url = `/weeks/${data.id}`;
                break;
            case 'weeks-search':
                url = '/weeks/search';
                break;
            case 'weeks-saved-search':
                url = `/weeks/search/${data.savedSearchUrl}`;
                break;
            case 'week-book':
                url = `/weeks/book`;
                break;
            case 'week-book-confirmation':
                url = `/weeks/book/confirmation`;
                break;
            case 'weeks-book-cancel':
                url = `/weeks/book/cancel/${data.id}`;
                break;
            case 'weeks-confirmation':
                url = `/weeks/book/confirmation/${data.id}`;
                break;
            case 'weeks-receipt':
                url = `/weeks/book/receipt/${data.id}`;
                break;
            case 'cruises':
                url = '/cruises';
                break;
            case 'cruises-search':
                url = '/cruises/search';
                break;
            case 'cruises-details':
                url = `/cruises/${data.id}`;
                break;
            case 'cruise-book':
                url = `/cruises/book`;
                break;
            case 'cruise-book-confirmation':
                url = `/cruises/book/confirmation`;
                break;
            case 'cruises-book-cancel':
                url = `/cruises/book/cancel/${data.id}`;
                break;
            case 'cruises-confirmation':
                url = `/cruises/book/confirmation/${data.id}`;
                break;
            case 'cruises-receipt':
                url = `/cruises/book/receipt/${data.id}`;
                break;
            case 'activities':
                url = '/activities';
                break;
            case 'activity-details':
                url = `/activities/${data.id}`;
                break;
            case 'activities-search':
                url = '/activities/search';
                break;
            case 'activities-saved-search':
                url = `/activities/search/${data.savedSearchUrl}`;
                break;
            case 'activity-book':
                url = `/activities/book`;
                break;
            case 'activity-book-confirmation':
                url = `/activities/book/confirmation`;
                break;
            case 'activities-book-cancel':
                url = `/activities/book/cancel/${data.id}`;
                break;
            case 'activities-confirmation':
                url = `/activities/book/confirmation/${data.id}`;
                break;
            case 'activities-receipt':
                url = `/activities/book/receipt/${data.id}`;
                break;
            case 'ecards':
                url = '/cards';
                break;
            case 'card-book':
                url = `/cards/book`;
                break;
            case 'card-book-confirmation':
                url = `/cards/book/confirmation`;
                break;
            case 'cards-book-cancel':
                url = `/cards/book/cancel/${data.id}`;
                break;
            case 'cards-confirmation':
                url = `/cards/book/confirmation/${data.id}`;
                break;
            case 'cards-receipt':
                url = `/cards/book/receipt/${data.id}`;
                break;
            case 'ecards-confirmation':
                url = `/cards/book/confirmation/${data.id}`;
                break;
            case 'ecards-receipt':
                url = `/cards/book/receipt/${data.id}`;
                break;
            case 'offer-details':
                url = `/offers/${data.id}`;
                break;
            case 'offer-request':
                url = `/offers/request`;
                break;
            case 'offer-book':
                url = `/offers/book`;
                break;
            case 'offer-book-confirmation':
                url = `/offers/book/confirmation`;
                break;
            case 'cancellation-confirmation':
                url = `${data.module}/book/cancel/${data.id}/confirmation`;
                break;
            case 'rewards':
                url = '/rewards';
                break;
            case 'rewards-user':
                url = '/rewards/user';
                break;
            case 'reward-purchase':
                url = '/rewards/purchase';
                break;
            case 'rewards-redeem-code':
                url = '/rewards/redeem';
                break;
            case 'escapes':
                url = '/escapes';
                break;
            case 'escapes-search':
                url = '/escapes/search';
                break;
            case 'escape-details':
                url = `/escapes/${data.id}`;
                break;
            case 'escape-book':
                url = `/escapes/book`;
                break;
            case 'escape-book-confirmation':
                url = `/escapes/book/confirmation`;
                break;
            case 'escapes-book-cancel':
                url = `/escapes/book/cancel/${data.id}`;
                break;
            case 'escapes-confirmation':
                url = `/escapes/book/confirmation/${data.id}`;
                break;
            case 'escapes-receipt':
                url = `/escapes/book/receipt/${data.id}`;
                break;
            case 'escapes-cancel':
                url = `/escapes/book/cancel/${data.id}`;
                break;
            default:
                return '';
        }

        return url;
    }

    isLoginUrl(url: string): string | boolean {
        return url.includes('/login') || url === 'login' ? 'login' : false;
    }

    isAccountUrl(url: string): string | boolean {
        return url.includes('/account') || url === 'account' ? 'account' : false;
    }

    isHotelsUrl(url: string): string | boolean {
        return url.includes('/hotels') || url === 'hotels' ? 'hotels' : false;
    }

    isFlightsUrl(url: string): string | boolean {
        return url.includes('/flights') || url === 'flights' ? 'flights' : false;
    }

    isCarsUrl(url: string): string | boolean {
        return url.includes('/cars') || url === 'cars' ? 'cars' : false;
    }

    isCruisesUrl(url: string): string | boolean {
        return url.includes('/cruises') || url === 'cruises' ? 'cruises' : false;
    }

    isWeeksUrl(url: string): string | boolean {
        return url.includes('/weeks') || url === 'weeks' ? 'weeks' : false;
    }

    isActivitiesUrl(url: string): string | boolean {
        return url.includes('/activities') || url === 'activities' ? 'activities' : false;
    }

    isCardsUrl(url: string): string | boolean {
        return url.includes('/cards') || url === 'cards' ? 'cards' : false;
    }

    isRewardsUrl(url: string): string | boolean {
        return url.includes('/rewards') || url === 'rewards' ? 'rewards' : false;
    }

    isEscapesUrl(url: string): string | boolean {
        return url.includes('/escapes') || url === 'escapes' ? 'escapes' : false;
    }
}
