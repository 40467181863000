import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ModuleDialogContent } from '@shared/models';

@Component({
    selector: 'app-cars-dialog',
    templateUrl: './cars-dialog.component.html',
})
export class CarsDialogComponent {
    content: ModuleDialogContent = {
        title: {
            value: {
                en: 'CARS',
                es: 'AUTOS',
            },
        },
        image: 'https://images.leisureloyalty.com/raintreerewards/img/club-benefit/car-hire.jpg',
        content: [
            {
                type: 'paragraph',
                value: {
                    en: 'Hiring a car can be an essential component of a smooth vacation experience. But car hire can often blow the budget due to soaring daily rates.',
                    es: 'Rentar un auto puede ser un componente esencial para unas vacaciones tranquilas, pero frecuentemente puede elevar mucho nuestro presupuesto debido al constante cambio de tarifas diarias.',
                },
            },
            {
                type: 'paragraph',
                value: {
                    en: 'With Raintree Rewards, you can secure car hire for your vacation at the lowest available rate, bookable online 24/7, ensuring you never pay too much and have peace of mind at the time of booking.',
                    es: 'Con Raintree Rewards, usted asegura para sus vacaciones la renta de auto a la tarifa más baja disponible, reservable en línea 24/7, asegurando su tranquilidad.',
                },
            },
            {
                type: 'paragraph',
                value: {
                    en: 'Remember that you will receive Raintree Rewards for every booking you make, and can also pay down the price using your reward points.',
                    es: 'Recuerde que usted recibirá recompensas por cada reservación que haga y podrá pagar con sus puntos.',
                },
            },
            {
                type: 'title',
                value: {
                    en: 'Trusted partners',
                    es: 'Socios comerciales leales',
                },
            },
            {
                type: 'paragraph',
                value: {
                    en: 'The Raintree Rewards network of trusted car hire companies includes:',
                    es: 'La red de socios comerciales de Raintree Rewards incluye:',
                },
            },
        ],
        partners: [
            'https://images.leisureloyalty.com/raintreerewards/img/suppliers/cars/avis.png',
            'https://images.leisureloyalty.com/raintreerewards/img/suppliers/cars/budget.png',
            'https://images.leisureloyalty.com/raintreerewards/img/suppliers/cars/thrifty.png',
            'https://images.leisureloyalty.com/raintreerewards/img/suppliers/cars/europcar.png',
            'https://images.leisureloyalty.com/raintreerewards/img/suppliers/cars/hertz.png',
            'https://images.leisureloyalty.com/raintreerewards/img/suppliers/cars/alamo.png',
            'https://images.leisureloyalty.com/raintreerewards/img/suppliers/cars/dollar.png',
        ],
    };

    constructor(public dialogRef: MatDialogRef<CarsDialogComponent>) {}
}
