export class BookingTravelerInfo {
    id: number;
    firstName: string;
    lastName: string;
    address: string;
    city?: string;
    country?: string;
    state?: string;
    zip?: string;
    email?: string;
    mobilePhone?: string;
    homePhone?: string;
    workPhone?: string;
}
