import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
    IsoCurrency,
    MembershipType,
    RegisterMeta,
    RegisterRequest,
    RegisterResponse,
    SiteBranding,
} from '@core/models';
import { LoaderService, UserAuthService } from '@core/services';
import { environment } from '@env/environment';
import { NguCarouselConfig } from '@ngu/carousel';
import {
    Country,
    DataTemplate,
    Elavon3ds,
    ElavonAuthResponse,
    ElavonEfsDetails,
    SelectOption,
    SharedStorageKeys,
} from '@shared/models';
import {
    CheckDataService,
    CustomErrorHandler,
    DynamicScriptLoaderService,
    GoogleAnalyticsEventsService,
    I18nService,
    LocationsService,
    Secure3DService,
} from '@shared/services';
import { defer, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-register-form',
    templateUrl: './register-form.component.html',
    styleUrls: ['./register-form.component.scss'],
})
export class RegisterFormComponent implements OnInit, OnDestroy {
    @Output()
    userRegistered: EventEmitter<string> = new EventEmitter<string>();

    sharedStorageKeys = SharedStorageKeys;
    countries: SelectOption[] = [];
    showRegistrationCode: boolean;
    registrationCode: string;
    showInvalidCodeError: boolean;
    membershipTypesLoading: boolean;
    termsAgree: boolean;
    newsletter: boolean;
    siteBranding: SiteBranding;
    elavonWebSdk: any = undefined;

    registerRequest: RegisterRequest = new RegisterRequest();

    selectedMembership: MembershipType;
    membershipTileItems: MembershipType[] = [];
    membershipCarouselTileConfig: NguCarouselConfig = {
        grid: { xs: 1, sm: 2, md: 2, lg: 2, all: 0 },
        speed: 250,
        point: {
            visible: true,
        },
        touch: false,
        loop: true,
        animation: 'lazy',
    };

    @ViewChild('membershipCarouselPrev') membershipCarouselPrev: ElementRef;
    @ViewChild('membershipCarouselNext') membershipCarouselNext: ElementRef;

    errorMessage: string;

    private unsubscribe: Subject<void> = new Subject();

    constructor(
        private route: ActivatedRoute,
        private loader: LoaderService,
        private i18nService: I18nService,
        private userAuthService: UserAuthService,
        private secure3DService: Secure3DService,
        private locationsService: LocationsService,
        private checkDataService: CheckDataService,
        private dynamicScriptLoaderService: DynamicScriptLoaderService,
        private googleAnalyticsEventsService: GoogleAnalyticsEventsService,
        private errorHandler: CustomErrorHandler
    ) {}

    ngOnInit(): void {
        this.locationsService
            .getCountries()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(
                (response: DataTemplate<Country[]>) => {
                    response.data.forEach((country) => {
                        this.countries.push({
                            value: country.id,
                            viewValue: country.label,
                        });
                    });
                },
                (error: string) => {
                    console.error(error);
                }
            );

        this.checkWidth();
        this.populateContent();
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    populateContent(): void {
        this.siteBranding = JSON.parse(localStorage.getItem(this.sharedStorageKeys.SITE_BRANDING)) as SiteBranding;

        if (this.route.snapshot.queryParams['registrationCode']) {
            this.registrationCode = this.route.snapshot.queryParams['registrationCode'];
        }

        this.retrieveMembershipTypes(true);

        if (this.siteBranding?.uiConfig.billingOptions.secure3D) {
            this.dynamicScriptLoaderService.loadScript('elavon3DS').then(() => {
                this.secure3DService
                    .getElavonEfs()
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe(
                        (response: DataTemplate<ElavonEfsDetails>) => {
                            this.elavonWebSdk = new (
                                window as Window & typeof globalThis & { Elavon3DSWebSDK: any }
                            ).Elavon3DSWebSDK({
                                baseUrl: `${environment.elavon3dsUrl}/3ds2`,
                                token: response.data.efsToken,
                            });
                        },
                        (error: string) => {
                            console.error(error);
                            this.errorMessage = error;
                        }
                    );
            });
        }

        this.checkDataService.memberInformation
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((response: UntypedFormGroup) => {
                if (response) {
                    if (!this.registerRequest) {
                        this.registerRequest = new RegisterRequest();
                    }

                    this.registerRequest.setMemberInformation(response.value);
                } else {
                    this.registerRequest = null;
                }
            });

        this.checkDataService.cardProfile.pipe(takeUntil(this.unsubscribe)).subscribe((response: UntypedFormGroup) => {
            if (response) {
                if (!this.registerRequest) {
                    this.registerRequest = new RegisterRequest();
                }

                this.registerRequest.card = response.value;
            } else if (!this.selectedMembership.free || !this.selectedMembership.onbook) {
                this.registerRequest = null;
            }
        });
    }

    retrieveMembershipTypes(initialCall = false): void {
        this.membershipTypesLoading = true;
        this.showInvalidCodeError = false;
        this.selectedMembership = null;
        this.membershipTileItems = [];

        defer(() => {
            return this.userAuthService.getMembershipTypes(this.registrationCode).pipe(
                takeUntil(this.unsubscribe),
                finalize(() => {
                    this.membershipTypesLoading = false;
                })
            );
        }).subscribe(
            (response: DataTemplate<MembershipType[]>) => {
                if (initialCall && !response.data?.length) {
                    this.showRegistrationCode = true;
                }

                if (response.data && response.data.length) {
                    this.membershipTileItems = response.data.sort((a: MembershipType, b: MembershipType) => {
                        return a.free ? -1 : b.free ? 1 : 0;
                    });
                } else if (!initialCall) {
                    this.showInvalidCodeError = true;
                }
            },
            (error: string) => {
                console.error(error);
            }
        );
    }

    clearRegistrationCode(): void {
        this.registrationCode = null;
        this.showRegistrationCode = false;
        this.showInvalidCodeError = false;
        this.selectedMembership = null;
        this.retrieveMembershipTypes();
    }

    selectMembership(targetIndex: number, target: MembershipType): void {
        this.errorMessage = '';
        this.selectedMembership = target;
        this.membershipTileItems.forEach((item: any, index: number) => {
            if (targetIndex === index) {
                item.active = true;
            } else {
                item.active = false;
            }
        });
    }

    display3DSError(message: string): void {
        this.loader.hide();
        this.errorMessage = `${this.i18nService.getKeyValue('core.labels.3dsErrorDisclaimer')} - ${message}`;
    }

    submitRegister(): void {
        this.errorMessage = null;
        this.checkDataService.checkData.emit();

        if (this.registerRequest) {
            this.loader.show();

            if (this.selectedMembership.free || this.selectedMembership.onbook) {
                this.registerRequest.card = null;
            }

            if (this.siteBranding?.uiConfig.billingOptions.secure3D && this.registerRequest.card) {
                //TODO: Currency should be from selected membership
                const currency: IsoCurrency = this.siteBranding?.uiConfig.localeOptions.isoCurrencies.find(
                    (c) => c.code === sessionStorage.getItem(this.sharedStorageKeys.CURRENT_CURRENCY)
                );

                const request = {
                    purchaseAmount: String(this.selectedMembership.price * 100).replace('.', ''),
                    purchaseCurrency: currency.isoCode,
                    purchaseExponent: currency.minorUnits,
                    acctNumber: this.registerRequest.card.ccNumber,
                    cardExpiryDate: `${
                        this.registerRequest.card.ccExpM < 10
                            ? '0' + this.registerRequest.card.ccExpM
                            : this.registerRequest.card.ccExpM
                    }${this.registerRequest.card.ccExpY.toString().substring(2, 4)}`,
                    messageCategory: '01',
                    transType: '01',
                    threeDSRequestorAuthenticationInd: '01',
                    challengeWindowSize: '04',
                    meta: {
                        displayMode: 'lightbox',
                        challengeIframeElement: null,
                    },
                };

                this.elavonWebSdk
                    .web3dsFlow(request)
                    .then((response: ElavonAuthResponse) => {
                        if (!response.authenticated) {
                            this.display3DSError(response.message);
                            this.errorHandler.sendErrorToBackend(JSON.stringify(response)).subscribe();
                        } else if (response.transStatus === 'N') {
                            this.errorMessage = this.i18nService.getKeyValue('core.errors.3dsAuthError');
                            this.errorHandler.sendErrorToBackend(JSON.stringify(response)).subscribe();
                        } else {
                            this.handleRegister(response);
                        }
                    })
                    .catch((error: string) => {
                        console.error(error);
                        this.display3DSError(error);

                        this.errorHandler.sendErrorToBackend(JSON.stringify(error)).subscribe();
                    });
            } else {
                this.handleRegister();
            }
        }
    }

    handleRegister(auth?: ElavonAuthResponse): void {
        if (auth) {
            this.registerRequest.elavon3ds = Elavon3ds.of(auth);
        }

        defer(() => {
            this.registerRequest.membershipId = this.selectedMembership.id;

            if (this.newsletter) {
                this.registerRequest.metaValues = [new RegisterMeta('LUOPTIN', 'on')];
            }

            if (this.registrationCode) {
                this.registerRequest.authCode = this.registrationCode;
            }

            return this.userAuthService.register(this.registerRequest).pipe(
                takeUntil(this.unsubscribe),
                finalize(() => this.loader.hide())
            );
        }).subscribe(
            (response: DataTemplate<RegisterResponse>) => {
                this.googleAnalyticsEventsService.sendEvent('begin_checkout', null, null, null, null, {
                    currency: sessionStorage.getItem('current_currency'),
                    value: this.selectedMembership.price,
                    items: [
                        {
                            item_id: this.selectedMembership.id,
                            item_name: this.selectedMembership.name,
                            item_brand: this.siteBranding?.uiConfig?.siteOptions?.siteName,
                            item_category: 'Registration',
                            price: this.selectedMembership.price,
                            coupon: this.registrationCode,
                            currency: sessionStorage.getItem('current_currency'),
                            quantity: 1,
                        },
                    ],
                });

                this.googleAnalyticsEventsService.sendEvent('purchase', null, null, null, null, {
                    currency: sessionStorage.getItem('current_currency'),
                    value: response.data.ecommerce.initialAmount,
                    items: [
                        {
                            item_id: response.data.ecommerce.txSubGuid,
                            item_name: response.data.ecommerce.sbPlanName,
                            item_brand: response.data.ecommerce.siteName,
                            item_category: 'Registration',
                            price: response.data.ecommerce.initialAmount,
                            coupon: this.registrationCode,
                            currency: sessionStorage.getItem('current_currency'),
                            quantity: 1,
                        },
                    ],
                });

                this.selectedMembership = null;
                this.userRegistered.emit(response.data.message);
            },
            (error: string) => {
                console.error(error);
                this.errorMessage = error;
            }
        );
    }

    triggerMembershipCarouselNav(direction: string): void {
        switch (direction) {
            case 'prev':
                this.membershipCarouselPrev.nativeElement.click();
                break;
            case 'next':
                this.membershipCarouselNext.nativeElement.click();
                break;
        }
    }

    checkWidth(): void {
        if (window.innerWidth < 1600) {
            this.membershipCarouselTileConfig.grid.lg = 2;
        }
    }
}
