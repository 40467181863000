import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

interface ToastOptions {
    variant: 'success' | 'error' | 'warning' | 'info';
    title: string;
    message: string;
    closeButton: boolean;
    timeOut: number;
    extendedTimeOut: number;
    preventDuplicates: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class ToastService {
    constructor(private toastr: ToastrService) {}

    show(options: Partial<ToastOptions>): void {
        const { variant, title, message, ...toastOptions } = options;

        switch (variant) {
            case 'success':
                this.toastr.success(message, title, { timeOut: 10000, ...toastOptions });
                break;
            case 'error':
                this.toastr.error(message, title, { timeOut: 10000, preventDuplicates: true, ...toastOptions });
                break;
            case 'warning':
                this.toastr.warning(message, title, { timeOut: 10000, ...toastOptions });
                break;
            case 'info':
                this.toastr.info(message, title, { timeOut: 10000, ...toastOptions });
                break;
        }
    }
}
