import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ModuleDialogContent } from '@shared/models';

@Component({
    selector: 'app-hotels-dialog',
    templateUrl: './hotels-dialog.component.html',
})
export class HotelsDialogComponent {
    content: ModuleDialogContent = {
        image: 'https://images.leisureloyalty.com/raintreerewards/img/club-benefit/resorts.jpg',
        title: {
            value: {
                en: 'WORLDWIDE HOTELS & RESORTS',
                es: 'HOTELES & RESORTS EN EL MUNDO',
            },
        },
        content: [
            {
                type: 'paragraph',
                value: {
                    en: "As a member of Raintree Rewards, you'll enjoy online booking access to over 2 million hotels in all parts of the globe, including the world's leading hotels and resorts - all at rates lower than other online travel agents. Perfect for your weekends away, short breaks, even business trips - saving you money every time you book. And remember, you'll also earn Raintree Rewards as well.",
                    es: 'Como socio de Raintree Rewards, usted tendrá acceso en línea a más de 2 millones hoteles en todos los rincones del mundo, incluyendo las marcas líderes mundiales de hoteles y resorts- encontrará tarifas más bajas que las demás agencias de viaje en línea. Ideales para sus escapes de fin de semana, vacaciones e incluso viajes de negocios- cada vez que reserve ahorrará dinero. Y recuerde, usted también gana recompensas de Raintree Rewards al mismo tiempo.',
                },
            },
            {
                type: 'title',
                value: {
                    en: 'Book online 24/7',
                    es: 'Reserve 24/7',
                },
            },
            {
                type: 'paragraph',
                value: {
                    en: 'Search availability in real time and book directly 24 hours a day, 7 days a week with the simple, online booking platform exclusive to Raintree Rewards members. You can also book airfares, car hire and activities - the complete package! Remember that you will earn Raintree Rewards on every booking - and if you have some reward points in your account, you can use them to pay down on your booking, giving you even more savings.',
                    es: 'Busque disponibilidad en tiempo real y reserve directamente las 24 horas del día, 7 días a la semana en la plataforma de reservaciones en línea exclusiva para socios de Raintree Rewards. Podrá reservar vuelos, autos y actividades. Si tiene puntos de recompensas en su cuenta, puede utilizarlos para pagar su reserva.',
                },
            },
        ],
    };

    constructor(public dialogRef: MatDialogRef<HotelsDialogComponent>) {}
}
