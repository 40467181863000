<div appTranslate>
    <h2 mat-dialog-title [attr.data-i18n-code]="data.title"></h2>

    <div mat-dialog-content class="padding-top-20 padding-bottom-20">
        <p *ngIf="data.extraBodyTop" [attr.data-i18n-code]="data.extraBodyTop"></p>

        <p [attr.data-i18n-code]="data.body"></p>

        <p *ngIf="data.extraBodyBottom" [attr.data-i18n-code]="data.extraBodyBottom"></p>
    </div>

    <div mat-dialog-actions class="flex-container justify-content-center">
        <button mat-stroked-button [color]="'primary'" [mat-dialog-close]="false">
            <span [attr.data-i18n-code]="'core.labels.close'"></span>
        </button>
    </div>
</div>
