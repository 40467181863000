export enum StorageKeys {
    SEARCH_REQUEST = 'hotels_search_request',
    SEARCH_RESPONSE = 'hotels',
    SEARCH_FILTERS = 'hotels_filters',
    SEARCH_FILTERS_MIN_PRICE = 'hotels_min_price',
    SEARCH_FILTERS_MAX_PRICE = 'hotels_max_price',
    SEARCH_ITEM = 'hotel_search_item',
    ITEM_TO_BOOK = 'hotel_to_book',
    BOOK_RESPONSE = 'hotels_book_response',
    ROOM_TO_BOOK = 'hotels_room_to_book',
    RATE_TO_BOOK = 'hotels_rate_to_book',
    SEARCH_ORIGIN_POINT = 'hotels_search_origin_point',
    SEARCH_START_TIME = 'hotels_search_start_time',
    SEARCH_RESULTS_START_TIME = 'hotels_search_results_start_time',
    BOOK_START_TIME = 'hotels_book_start_time',
    SEARCH_TRIGGERED = 'hotels_search_triggered',
    BOOK_TRIGGERED = 'hotels_book_triggered',
}
