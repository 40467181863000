import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@core/services/auth/auth.service';
import { environment } from '@env/environment';
import { SharedStorageKeys } from '@shared/models';
import { CustomErrorHandler, ToastService } from '@shared/services';
import { Observable, of, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
    sharedStorageKeys = SharedStorageKeys;

    constructor(
        private authService: AuthService,
        private toast: ToastService,
        private errorHandler: CustomErrorHandler
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.url.startsWith('http') || request.url.indexOf('.svg') > 0 || request.url.indexOf('papi') > 0) {
            return next.handle(request);
        }

        const headers = {};

        if (!request.headers.has('Accept')) {
            headers['Accept'] = 'application/json';
        }

        if (!request.headers.has('Authorization')) {
            headers['Authorization'] = localStorage.getItem(this.sharedStorageKeys.ACCESS_TOKEN) || '';
        }

        const clonedRequest = request.clone({
            setHeaders: headers,
            withCredentials: environment.target !== 'dev',
            url: this.updateUrl(request.url),
        });

        return next.handle(clonedRequest).pipe(
            retry(clonedRequest.method.toLocaleUpperCase() === 'GET' || clonedRequest.url.includes('getI18n') ? 1 : 0),
            catchError((err: HttpErrorResponse) => {
                if (err.status === 401) {
                    this.authService.logout();
                    return of(err);
                }

                return this.handleError(err, clonedRequest.url);
            })
        ) as Observable<HttpEvent<any>>;
    }

    private updateUrl(req: string) {
        return environment.baseEndpoint + req;
    }

    handleError(errorResponse: HttpErrorResponse | any, url: string): any {
        let errorMessage = '';

        if (errorResponse instanceof HttpErrorResponse) {
            errorMessage =
                errorResponse?.error?.error_description || errorResponse?.error?.error || errorResponse?.error?.message;
        } else {
            errorMessage = errorResponse.message
                ? errorResponse?.message
                : errorResponse?.error
                ? errorResponse?.error
                : errorResponse?.toString();
        }

        // console.error(errorMessage);

        if (!url.includes('book') && !errorMessage.includes('E4302')) {
            this.toast.show({
                variant: 'error',
                title: 'Error',
                message: errorMessage,
            });
        }

        if (!url.includes('core/log')) {
            this.errorHandler.sendErrorToBackend(JSON.stringify(errorResponse), url).subscribe();
        }

        return throwError(errorMessage ?? 'Something went wrong. Please contact our support team.');
    }
}
