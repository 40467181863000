import { AdobeAnalyticsDetails, AssetValue, GoogleAnalyticsDetails } from '@shared/models';

import { RateCancellationPolicy } from './rate-cancellation-policy';

export class RoomPackage {
    bookingFee: AssetValue;
    cancellationPolicy: RateCancellationPolicy;
    earns: AssetValue;
    earnDelayDate: string;
    ga: GoogleAnalyticsDetails;
    hotelFees: AssetValue[];
    includedFees: AssetValue[];
    optionalFees: AssetValue[];
    id: string;
    images: string[];
    oa: AdobeAnalyticsDetails;
    packageId: string;
    policyIndex: number;
    providerTax: AssetValue;
    redeem: AssetValue;
    redeemMax: AssetValue;
    redeemMin: AssetValue;
    refundable: boolean;
    refundableUntil: string;
    retailPrice: AssetValue;
    rooms: PackageRoom[];
    tax: AssetValue;
    taxIncluded: boolean;
    totalAmount: AssetValue;
    totalDueAtHotelCode: string;
    totalDueAtHotelValue: number;
    originalTotalDueAtHotelCode: string;
    originalTotalDueAtHotelValue: number;
    youPay: AssetValue;
    youSave: AssetValue;
    youSavePct: number;
    invsrcId: number;
}

export class PackageRoom {
    boardCode: string;
    id: string;
    name: string;
    sleeps: number;
    images: string[];
    description: string;
    amenities: string;
    invsrcId: number;
}
