import { ActivityDetails } from '@activities/models';
import { ActivitiesService } from '@activities/services/activities.service';
import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { LoaderService } from '@core/services';
import { DataTemplate } from '@shared/models';
import { UrlService } from '@shared/services';
import { of, Subject } from 'rxjs';
import { catchError, finalize, mergeMap, take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ActivityDetailsResolverService implements Resolve<ActivityDetails>, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    constructor(
        private activitiesService: ActivitiesService,
        private loader: LoaderService,
        private url: UrlService,
        private router: Router
    ) {}

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    resolve(route: ActivatedRouteSnapshot): any {
        const activity = JSON.parse(
            sessionStorage.getItem(`activity_${route.params['id']}`)
        ) as DataTemplate<ActivityDetails>;

        if (activity) {
            return of(activity as DataTemplate<ActivityDetails>);
        } else {
            this.loader.show();

            return this.activitiesService.getDetails(route.params['id']).pipe(
                take(1),
                finalize(() => this.loader.hide()),
                mergeMap((response: DataTemplate<ActivityDetails>) => {
                    sessionStorage.setItem(`activity_${route.params['id']}`, JSON.stringify(response.data));

                    return of(response.data);
                }),
                catchError(() => {
                    this.router.navigateByUrl(this.url.getUrl('activities'));
                    return of(null);
                })
            );
        }
    }
}
