import { AssetValue, GoogleAnalyticsDetails, Location } from '@shared/models';

export class Week {
    adultsOnly: boolean;
    address: string;
    amenities: string[];
    guestRating: number;
    distance: number;
    hasRooms: boolean;
    id: string;
    imageUrl: string;
    allInclusiveCode: string;
    allInclusiveDesc: string;
    location: Location;
    name: string;
    noOfNights: number;
    rating: number;
    retailPrice: AssetValue;
    type: string;
    youPay: AssetValue;
    youSave: AssetValue;
    youSavePct: number;

    index: number;
    youSavePercentage: number;
    stars: number[];
    distanceLabel: string;
    price: number;
    guestRatingValue: number;
    mapInfoActive: boolean;
    awayCity: string;
    ga: GoogleAnalyticsDetails;
}
