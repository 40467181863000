import { Injectable, OnDestroy } from '@angular/core';
import { SiteBranding, TokenInfo, UserInfo } from '@core/models';
import { GuestLoginService, TokenInteractionService } from '@core/services';
import { environment } from '@env/environment';
import { SharedStorageKeys } from '@shared/models';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { UserAuthService } from './user-auth.service';

@Injectable({
    providedIn: 'root',
})
export class AuthService implements OnDestroy {
    sharedStorageKeys = SharedStorageKeys;

    private loggedIn$ = new BehaviorSubject<boolean>(false);
    private username$ = new BehaviorSubject<string>(localStorage.getItem(this.sharedStorageKeys.USER_ID));

    private unsubscribe: Subject<void> = new Subject();

    constructor(
        private tokenService: TokenInteractionService,
        private guestLoginService: GuestLoginService,
        private userAuthService: UserAuthService
    ) {}

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    get loggedIn(): any {
        return this.loggedIn$.asObservable();
    }

    get username(): any {
        return this.username$.asObservable();
    }

    setToken(user: TokenInfo): void {
        this.tokenService.create(user);
    }

    setUsername(username: string): void {
        this.username$.next(username);
    }

    login(): void {
        this.loggedIn$.next(true);
    }

    isGuest(): boolean {
        const userInfo = UserInfo.of(JSON.parse(localStorage.getItem(this.sharedStorageKeys.USER_INFO) || '{}'));
        const userId = localStorage.getItem(this.sharedStorageKeys.USER_ID);

        return (
            userInfo.isGuest ||
            environment.guestCredentials.allowedUsernames.includes(userId) ||
            userId === environment.guestCredentials.username
        );
    }

    logout(doGuestLogin = true): void {
        const siteBranding: SiteBranding = JSON.parse(localStorage.getItem(this.sharedStorageKeys.SITE_BRANDING));

        if (siteBranding?.uiConfig?.userOptions?.loginRedirect) {
            localStorage.setItem(
                this.sharedStorageKeys.LOGIN_REDIRECT,
                siteBranding?.uiConfig?.userOptions?.loginRedirect
            );
        }

        if (this.isGuest() || !localStorage.getItem(this.sharedStorageKeys.USER_ID)) {
            this.userAuthService
                .getSiteBasic()
                .pipe(takeUntil(this.unsubscribe))
                .subscribe(
                    (basicResponse: any) => {
                        localStorage.setItem(this.sharedStorageKeys.BASIC_AUTH, JSON.stringify(basicResponse));
                        this.doLogout(doGuestLogin);
                    },
                    (error: string) => {
                        console.error(error);
                    }
                );
        } else {
            this.doLogout(doGuestLogin);
        }
    }

    doLogout(doGuestLogin: boolean): void {
        const localStoragePersistedKeys: string[] = [
            this.sharedStorageKeys.BASIC_AUTH,
            this.sharedStorageKeys.REMEMBER_ME,
            this.sharedStorageKeys.REMEMBER_USERNAME,
        ];

        for (const key in localStorage) {
            if (!localStoragePersistedKeys.includes(key)) {
                localStorage.removeItem(key);
            }
        }

        if (doGuestLogin) {
            this.guestLoginService.triggerGuestLogin.emit(true);
        } else {
            sessionStorage.clear();
        }

        this.tokenService.clear();
        this.loggedIn$.next(false);
        this.username$.next(null);
    }
}
