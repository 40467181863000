import { Component, Input, OnInit } from '@angular/core';
import { LocationSearch, SearchRequest } from '@weeks/models';

@Component({
    selector: 'app-weeks-recent-search',
    templateUrl: './weeks-recent-search.component.html',
    styleUrls: ['./weeks-recent-search.component.scss'],
})
export class WeeksRecentSearchComponent implements OnInit {
    @Input()
    search: SearchRequest;

    searchCriteria: LocationSearch;
    adults: number;
    children: number;

    ngOnInit(): void {
        this.searchCriteria = this.search.searchCriteria as LocationSearch;
        this.adults = this.search.adults;
        this.children = this.search.children;
    }
}
