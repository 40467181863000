export const pageTitles = {
    landing: 'Car Rental',
    search: 'Car Rental Search Results',
    book: 'Car Rental Driver & Payment Information',
    bookConfirmation: 'Car Rental Transaction Completed',
    bookConfirmationProfile: 'Car Rental Transaction Confirmation',
    bookReceipt: 'Car Rental Transaction Receipt',
    cancellation: 'Car Rental Cancellation',
    cancellationConfirmation: 'Car Rental Cancellation Confirmation',
};
