<div appTranslate id="login-form" *ngIf="loginFormGroup">
    <h1 class="primary-title margin-bottom-10" [attr.data-i18n-code]="'login.headline.label'"></h1>
    <p class="margin-bottom-30" [attr.data-i18n-code]="'login.subtitle.label'"></p>

    <form class="login-form width-100" [formGroup]="loginFormGroup">
        <app-input
            label="core.labels.username"
            [inputType]="'text'"
            [group]="loginFormGroup"
            [errorMessages]="formErrors?.username"
            name="username"
        ></app-input>
        <mat-form-field appearance="fill">
            <mat-label class="mat-label" [attr.data-i18n-code]="'core.labels.password'"></mat-label>

            <input matInput label="Password" [type]="'password'" formControlName="password" required />

            <mat-error *ngIf="formErrors?.password">{{ formErrors?.password }}</mat-error>
        </mat-form-field>

        <div class="padding-bottom-25">
            <app-checkbox
                class="remember-me"
                label="core.labels.rememberMe"
                [color]="'primary'"
                [group]="loginFormGroup"
                name="rememberMe"
            ></app-checkbox>
        </div>

        <div
            appTranslate
            class="error-message margin-bottom-10"
            *ngIf="errorMessage === 'core.errors.invalidCredentials'"
        >
            <span [attr.data-i18n-code]="errorMessage">{{ errorMessage }}</span>
        </div>

        <div
            class="error-message margin-bottom-10"
            *ngIf="errorMessage && errorMessage !== 'core.errors.invalidCredentials'"
        >
            {{ errorMessage }}
        </div>

        <div class="margin-bottom-10">
            <button
                mat-raised-button
                type="submit"
                class="login-button text-uppercase"
                [color]="'primary'"
                (click)="submitLogin()"
            >
                <span [attr.data-i18n-code]="'core.labels.signIn'"></span>
            </button>
            <a
                [routerLink]="guestUrl"
                class="link guest-link margin-left-10"
                [attr.data-i18n-code]="'login.asGuest.label'"
                *ngIf="
                    !siteBranding?.uiConfig?.userOptions?.loginRequired &&
                    (siteBranding?.uiConfig?.modules?.length || siteBranding?.uiConfig?.siteOptions?.isHomeEnabled)
                "
            ></a>
        </div>
    </form>

    <div class="flex-container align-items-center">
        <span
            class="forgot-link cursor-pointer"
            [attr.data-i18n-code]="'core.labels.forgotPassword'"
            (click)="target.emit(2)"
        ></span>
        <span class="forgot-separator margin-left-5 margin-right-5"></span>
        <span
            class="forgot-link cursor-pointer"
            [attr.data-i18n-code]="'core.labels.forgotUsername'"
            (click)="target.emit(3)"
        ></span>
    </div>
</div>
