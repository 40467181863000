<div appTranslate id="price-summary" *ngIf="bookPrice?.totalAmount; else loader">
    <div class="position-relative">
        <app-spinner [isLoading]="!loaded">
            <div appTranslate>
                <div
                    class="flex-container justify-content-space-between margin-bottom-10"
                    *ngIf="bookPrice?.retailPrice?.value > bookPrice?.youPay?.value && showRetailPrice"
                >
                    <span class="price-label" [attr.data-i18n-code]="'core.assetValues.retailPrice.label'"></span>
                    <span class="price-value line-through">{{ bookPrice?.retailPrice | formatAssetValue }}</span>
                </div>

                <div class="flex-container justify-content-space-between margin-bottom-10" *ngIf="bookPrice?.youPay">
                    <div>
                        <span class="price-label" [attr.data-i18n-code]="'core.assetValues.youPay.label'"></span>
                    </div>

                    <span class="price-value" *ngIf="!isPostPaid">{{ bookPrice?.youPay | formatAssetValue }}</span>

                    <span class="price-value" *ngIf="isPostPaid">{{ bookPrice?.dueAtArrival | formatAssetValue }}</span>
                </div>

                <div
                    class="flex-container justify-content-space-between margin-bottom-10"
                    *ngIf="bookPrice?.redeem?.value && siteBranding?.uiConfig?.pointsOptions?.showPoints"
                >
                    <span
                        class="price-label"
                        [attr.data-i18n-code]="'core.assetValues.redeem.label'"
                        [attr.data-i18n-params]="siteBranding?.uiConfig?.pointsOptions?.pointsName"
                        [attr.data-i18n-persist]="true"
                    ></span>
                    <span class="price-value points-value">{{ bookPrice?.redeem?.value }}</span>
                </div>

                <app-taxes [target]="modules.CARS" [bookPrice]="bookPrice" [taxBreakdown]="taxBreakdown"></app-taxes>

                <div
                    appTranslate
                    class="flex-container justify-content-space-between margin-bottom-10"
                    *ngIf="useCredit && bookPrice?.maxCreditAmt?.value"
                >
                    <span class="credit-decrease-label" [attr.data-i18n-code]="'core.labels.accountCredit'"></span>
                    <span class="credit-decrease-value">-{{ bookPrice?.maxCreditAmt | formatAssetValue }}</span>
                </div>

                <div
                    class="price-separator margin-bottom-10"
                    *ngIf="bookPrice?.retailPrice || bookPrice?.youPay || bookPrice?.redeem || bookPrice?.tax"
                ></div>

                <app-cars-totals [bookPrice]="bookPrice"></app-cars-totals>
            </div>
        </app-spinner>
    </div>
</div>

<ng-template #loader>
    <div class="flex-container align-items-center justify-content-center">
        <mat-spinner [class.hide]="loaded"></mat-spinner>
    </div>
</ng-template>
