<div
    class="carousel-wrapper full-width flex-container position-relative carousel-wrapper"
    [class.no-carousel]="!images?.length"
>
    <ng-container *ngIf="images?.length">
        <ngu-carousel
            #headerCarousel
            [inputs]="headerCarouselTileConfig"
            [dataSource]="images"
            class="carousel-items-wrapper width-100"
        >
            <ngu-tile *nguCarouselDef="let item; let i = index; let ani = animate">
                <figure class="carousel-image-wrapper flex-container align-items-center width-100">
                    <img [attr.src]="item" class="carousel-image" alt="" />
                </figure>
            </ngu-tile>

            <div NguCarouselPrev #headerCarouselPrev></div>
            <div NguCarouselNext #headerCarouselNext></div>
        </ngu-carousel>

        <button
            aria-label="Previous image"
            class="carousel-icon-background flex-container justify-content-center align-items-center carousel-prev-icon cursor-pointer ada-button"
            (click)="triggerHeaderCarouselNav('prev')"
        >
            <mat-icon svgIcon="chevron-left" class="carousel-nav-icon"></mat-icon>
        </button>

        <button
            appAutofocus
            aria-label="Next image"
            class="carousel-icon-background flex-container justify-content-center align-items-center carousel-next-icon cursor-pointer ada-button"
            (click)="triggerHeaderCarouselNav('next')"
        >
            <mat-icon svgIcon="chevron-right" class="carousel-nav-icon"></mat-icon>
        </button>
    </ng-container>

    <div class="header-info-wrapper padding-top-20 padding-bottom-20 padding-sm-bottom-0 width-100">
        <div class="main-content-wrapper content" [class.simple]="simpleContent">
            <div class="header-info">
                <ng-content select=".info-content"></ng-content>
            </div>

            <div class="overview-wrapper hide-below-xl padding-20 height-100" *ngIf="showOverview">
                <ng-content select=".overview-content"></ng-content>
            </div>
        </div>
    </div>
</div>
