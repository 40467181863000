<div appTranslate id="reset-password-dialog" class="padding-right-30 padding-bottom-20 padding-left-30">
    <h2 mat-dialog-title [attr.data-i18n-code]="'login.resetPassword.title.label'"></h2>

    <div mat-dialog-content>
        <div [class.hide]="successMessage">
            <p [attr.data-i18n-code]="'login.resetPassword.expired.label'"></p>

            <form [formGroup]="passwordFormGroup">
                <div class="row">
                    <div class="col-md-6 col-xxxs-12">
                        <app-input
                            label="account.settings.newPassword.label"
                            [inputType]="'password'"
                            [group]="passwordFormGroup"
                            [errorMessages]="formErrors?.newPassword"
                            name="newPassword"
                        ></app-input>
                    </div>

                    <div class="col-md-6 col-xxxs-12">
                        <app-input
                            label="account.settings.verifyNewPassword.label"
                            [inputType]="'password'"
                            [group]="passwordFormGroup"
                            [errorMessages]="formErrors?.newPassword2"
                            name="newPassword2"
                        ></app-input>
                    </div>
                </div>
            </form>

            <div class="margin-bottom-20">
                <mat-hint>
                    <span [attr.data-i18n-code]="'member.register.choosePasswordRequirements'"></span>
                </mat-hint>
            </div>

            <div class="error-message margin-top-10" *ngIf="errorMessage">{{ errorMessage }}</div>
        </div>

        <div [class.hide]="!successMessage">
            <div class="success-message margin-bottom-20" *ngIf="successMessage">{{ successMessage }}</div>
        </div>
    </div>

    <div mat-dialog-actions class="flex-container justify-content-center">
        <button mat-raised-button [color]="'primary'" (click)="submitPassword()" [class.hide]="successMessage">
            <span class="text-uppercase" [attr.data-i18n-code]="'core.labels.submit'"></span>
        </button>

        <button mat-stroked-button [color]="'primary'" [mat-dialog-close]="true" [class.hide]="!successMessage">
            <span class="text-uppercase" [attr.data-i18n-code]="'core.labels.close'"></span>
        </button>
    </div>
</div>
