<div class="margin-top-25 margin-bottom-15">
    <p class="overview-value margin-bottom-10">{{ hotel.name }}</p>
    <p class="overview-value margin-bottom-10">
        {{ searchRequest.checkIn | date: 'mediumDate' }} - {{ searchRequest.checkOut | date: 'mediumDate' }} -
        <span>{{ numberOfNights }} </span>
        <span [class.hide]="numberOfNights > 1" [attr.data-i18n-code]="'core.labels.night'"></span>
        <span [class.hide]="numberOfNights == 1" [attr.data-i18n-code]="'core.labels.nights'"></span>
    </p>

    <div class="overview-value margin-bottom-10">
        <span
            >{{ adults }}
            <span class="adults-label text-lowercase">
                <span [class.hide]="adults !== 1" [attr.data-i18n-code]="'core.labels.adult'"></span>
                <span [class.hide]="adults === 1" [attr.data-i18n-code]="'core.labels.adults'"></span>
            </span>
        </span>

        <span [class.hide]="!children"
            >, {{ children }}
            <span class="children-label text-lowercase">
                <span [class.hide]="children !== 1" [attr.data-i18n-code]="'core.labels.child'"></span>
                <span [class.hide]="children === 1" [attr.data-i18n-code]="'core.labels.children'"></span>
                (<span [attr.data-i18n-code]="children === 1 ? 'core.labels.age' : 'core.labels.ages'"></span> {{ ' '
                }}{{ childrenAges }})
            </span>
        </span>
    </div>

    <p class="overview-value">{{ room.name }} x {{ rooms }}</p>

    <div class="price-separator"></div>
</div>
