<div class="align-items-center flex-container">
    <span class="flex-grow-1">{{ data.message }}</span>
    <button mat-icon-button *ngIf="!data.actionText; else actionText" (click)="snackBarRef.dismiss()">
        <mat-icon svgIcon="close"></mat-icon>
    </button>
    <ng-template #actionText>
        <button mat-button (click)="snackBarRef.dismiss()" *ngIf="!data.action">{{ data.actionText }}</button>
        <button mat-button (click)="data.action.action()" *ngIf="data.action">{{ data.actionText }}</button>
    </ng-template>
</div>
