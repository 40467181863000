import { AssetValue } from '@shared/models';
import { Entity, Of } from '@utils';

@Entity
export class Escape {
    @Of(() => Number)
    id: number;

    @Of(() => Number)
    destinationId: number;

    @Of(() => String)
    name = '';

    @Of(() => String)
    imageUrl = '';

    @Of(() => String)
    address = '';

    @Of(() => String)
    location = '';

    @Of(() => String)
    startDate = '';

    @Of(() => String)
    endDate = '';

    @Of(() => AssetValue)
    price: AssetValue;

    @Of(() => Boolean)
    isAvailable = false;

    @Of(() => String)
    externalBookingUrl = '';

    @Of(() => Number)
    priceBasis = 1;

    @Of(() => Number)
    defaultOcc = 2;

    static of = Entity.of<Escape>();
}
