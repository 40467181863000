export class SelectedRoom {
    adults: number;
    children: number;
    childrenAges?: number[];
}

export class RoomGuests {
    adults: number;
    childrenAges?: number[];
}
