import { AssetValue } from '@shared/models';

export class EscapeDetails {
    id: number;
    name: string;
    images: string[];
    location: string;
    regionName: string;
    startDate: string;
    endDate: string;
    fmtStartDate: string;
    fmtEndDate: string;
    days: number;
    nights: number;
    summary: string;
    about: string;
    groupSize: string;
    amenities: string[];
    includedFeatures: string[];
    closestArrivalAirport: EscapeAirportDetails;
    closestDepartureAirport: EscapeAirportDetails;
    transferService: string;
    accommodations: EscapeAccommodation[];
    itinerary: EscapeItineraryItem[];
    facts: string[];
    notes: string[];
    policies: EscapePolicy[];
    bookingPolicy: string;
    bookByDate: string;
    fmtBookByDate: string;
    pointsEarned: number;
    currencyCode: string;
    currencyId: number;
    externalBookingUrl: string;
    price: AssetValue;
    defaultOcc: number;
}

export class EscapeAccommodation {
    id: number;
    imageUrl: string;
    name: string;
    address: string;
    about: string;
    rooms: EscapeRoom[];
}

export class EscapeRoom {
    id: number;
    name: string;
    imageUrl: string;
    size: string;
    bedding: string;
    maxOccTotal: number;
    maxOccAdult: number;
    maxOccChildren: number;
    description: string;
    minPrice: AssetValue;
    quantity: number;
    availabilities: EscapeRoomAvailability[];
    isDefault: boolean;
    readMore?: boolean;
}

export class EscapeRoomAvailability {
    id: number;
    sglPrice: AssetValue;
    dblPrice: AssetValue;
    adultPrice?: AssetValue;
    childPrice?: AssetValue;
    infantPrice?: AssetValue;
}

export class EscapeAirportDetails {
    name: string;
    code: string;
}

export class EscapeItineraryItem {
    latitude: number;
    longitude: number;
    day: number;
    name: string;
    imageUrl: string;
    description: string;
    isOpen?: boolean;
}

export class EscapePolicy {
    id: number;
    from: number;
    to: number;
    fee: number;
    nameChange: AssetValue;
}
