import { AssetValue, Rate } from '@shared/models';

export class Room {
    name: string;
    sleeps: number;
    bedrooms: number;
    bathrooms: number;
    kitchen: string;
    rates: WeekRate[];
    unitType: string;
}

export class WeekRate extends Rate {
    daysDiff: number;
    startDate: string;
    endDate: string;
    noOfNights: number;
    earns: AssetValue;
    retailPrice: AssetValue;
    tax: AssetValue;
    taxIncluded: boolean;
    youPay: AssetValue;
    youSave: AssetValue;
    youSavePct: number;

    youSavePercentage?: number;
    isUnavailable?: boolean;
    policyLoaded?: boolean;
    earnDelayDate?: string;

    constructor() {
        super();
    }
}
