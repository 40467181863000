import { CardProfile, Elavon3ds } from '@shared/models';

export class RegisterRequest {
    membershipId: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    card: CardProfile;
    metaValues: RegisterMeta[];
    authCode: string;

    elavon3ds?: Elavon3ds;

    public setMemberInformation(memberInfo: Record<string, string>): void {
        this.firstName = memberInfo.firstName;
        this.lastName = memberInfo.lastName;
        this.email = memberInfo.email;
        this.phone = memberInfo.phone;
    }
}

export class RegisterMeta {
    code: string;
    value: string;

    constructor(code: string, value: string) {
        this.code = code;
        this.value = value;
    }
}
