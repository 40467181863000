import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { SiteBranding } from '@core/models';
import { AuthService, TokenInteractionService } from '@core/services';
import { SharedStorageKeys } from '@shared/models';
import { HeaderService, UrlService } from '@shared/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewInit {
    sharedStorageKeys = SharedStorageKeys;
    siteBranding: SiteBranding;
    hide: boolean;
    isLoggedIn: boolean;
    navTarget: string;
    onLogin: boolean;
    isFixed: boolean;
    showLeftNavigation: boolean;
    logoUrl: string;
    headerVariant: string;
    userMenuVariant: string;

    @ViewChild('header', { static: true }) headerEl: ElementRef;
    @ViewChild('spacingHelper', { static: true }) spacingHelperEl: ElementRef;

    private unsubscribe: Subject<void> = new Subject();

    constructor(
        private token: TokenInteractionService,
        private headerService: HeaderService,
        public url: UrlService,
        private renderer: Renderer2,
        private authService: AuthService
    ) {}

    ngOnInit(): void {
        this.hide = false;
        this.isLoggedIn = this.token.isValidToken();
        this.isFixed = sessionStorage.getItem(this.sharedStorageKeys.HEADER_FIXED) !== null;
        this.navTarget = sessionStorage.getItem(this.sharedStorageKeys.HEADER_TARGET);
        this.siteBranding = JSON.parse(localStorage.getItem(this.sharedStorageKeys.SITE_BRANDING)) as SiteBranding;
        this.headerVariant = this.siteBranding?.uiConfig?.siteOptions?.designOptions?.header?.variant || 'default';
        this.userMenuVariant = this.siteBranding?.uiConfig?.siteOptions?.designOptions?.userMenu?.variant || 'default';

        this.logoUrl =
            this.siteBranding?.uiConfig?.siteOptions?.designOptions?.header?.logoUrl ||
            this.url.getUrl(this.siteBranding?.uiConfig.modules[0]);

        if (this.siteBranding?.uiConfig?.siteOptions?.isHomeEnabled && this.authService.isGuest()) {
            this.logoUrl = this.url.getUrl('home');
        }

        this.headerService.target.pipe(takeUntil(this.unsubscribe)).subscribe((response: string) => {
            this.navTarget = response;
        });

        this.headerService.fixed.pipe(takeUntil(this.unsubscribe)).subscribe((response: boolean) => {
            this.isFixed = response;

            if (!this.isFixed) {
                this.renderer.addClass(this.spacingHelperEl.nativeElement, 'hide');
            }
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();

        window.removeEventListener('scroll', undefined, true);
    }

    ngAfterViewInit(): void {
        window.addEventListener('scroll', this.onScroll.bind(this), true);
    }

    onScroll(): void {
        if (this.headerEl && !this.isFixed) {
            if (window.scrollY > this.headerEl.nativeElement.offsetHeight) {
                this.renderer.addClass(this.headerEl.nativeElement, 'fixed');
                this.renderer.removeClass(this.spacingHelperEl.nativeElement, 'hide');
            } else {
                this.renderer.removeClass(this.headerEl.nativeElement, 'fixed');
                this.renderer.addClass(this.spacingHelperEl.nativeElement, 'hide');
            }
        }
    }
}
