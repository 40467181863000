export class SearchFilters {
    vehicleClasses: VehicleClass[];
    vendors: VehicleVendor[];
    payTypes: PayType[];
    transmissions: VehicleTransmission[];
}

export class VehicleClass {
    id: string;
    name: string;
    active?: boolean;
}

export class VehicleVendor {
    id: string;
    name: string;
    active?: boolean;
}

export class VehicleTransmission {
    id: string;
    name: string;
    active?: boolean;
}

export class PayType {
    id: string;
    name: string;
}
