import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Country, DataTemplate } from '@shared/models';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class LocationsService {
    constructor(private http: HttpClient) {}

    getCountries(): Observable<DataTemplate<Country[]>> {
        const url = `${environment.corePath}/countries`;

        const countries = JSON.parse(sessionStorage.getItem(`countries`)) as DataTemplate<Country[]>;

        if (countries) {
            return of(countries as DataTemplate<Country[]>);
        } else {
            return this.http.get(url).pipe(
                tap((data) => {
                    sessionStorage.setItem(`countries`, JSON.stringify(data));
                }),
                map((data) => data as DataTemplate<Country[]>)
            ) as Observable<DataTemplate<Country[]>>;
        }
    }

    getProvinces(countryId: number): Observable<DataTemplate<{ [key: number]: string }>> {
        const url = `${environment.corePath}/provinces`;

        const provinces = JSON.parse(sessionStorage.getItem(`provinces_${countryId}`)) as DataTemplate<{
            [key: number]: string;
        }>;

        if (provinces) {
            return of(provinces as DataTemplate<{ [key: number]: string }>);
        } else {
            const params = new HttpParams().set('countryId', countryId.toString());

            return this.http.get(url, { params }).pipe(
                tap((data: DataTemplate<{ [key: number]: string }>) => {
                    if (data && data.data && Object.keys(data.data).length) {
                        sessionStorage.setItem(`provinces_${countryId}`, JSON.stringify(data));
                    }
                }),
                map((data) => data as DataTemplate<{ [key: number]: string }>)
            ) as Observable<DataTemplate<{ [key: number]: string }>>;
        }
    }
}
