import { AccountDetails } from '@account/models';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-points-summary',
    templateUrl: './points-summary.component.html',
    styleUrls: ['./points-summary.component.scss'],
})
export class PointsSummaryComponent {
    @Input()
    accountDetails: AccountDetails;
}
