import { BookingBillingInfo } from './booking-billing-info';
import { BookingCardInfo } from './booking-card-info';
import { BookingFeesDetails } from './booking-fees-details';
import { BookingReservationDetails } from './booking-reservation-details';
import { BookingRoomDetails } from './booking-room-details';
import { BookingTravelerInfo } from './booking-traveler-info';

export class BookingInfo {
    images: string[];
    name: string;
    ratingStars: string;
    showSeparator?: boolean;
    location?: string;
    contentTitle?: string;
    bookingDetails: BookingRoomDetails;
    bookingFees: BookingFeesDetails;
    totalPrice: string;
    pointsEarned: string;
    pointsRedeemed: string;
    reservationDetails?: BookingReservationDetails;
    travelersInfo?: BookingTravelerInfo[];
    travelerInfo?: BookingTravelerInfo;
    billingInfo?: BookingBillingInfo;
    cardInfo?: BookingCardInfo;
}
