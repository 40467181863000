<div
    appTranslate
    class="left-navigation-wrapper flex-container flex-column height-100 position-relative padding-left-50 padding-top-70 padding-right-50 padding-bottom-50"
    *ngIf="siteBranding?.uiConfig.siteOptions.designOptions.userMenu.variant === 'default'"
>
    <mat-icon svgIcon="remove" class="close-menu-icon cursor-pointer" (click)="close()"></mat-icon>
    <div>
        <div class="logo margin-bottom-40"></div>
    </div>

    <div class="flex-container flex-wrap lef-nav-menu-wrapper" *ngIf="menuItems">
        <ng-container *ngFor="let item of menuItems">
            <div class="flex-50 margin-bottom-35">
                <div class="position-relative left-nav-link-wrapper">
                    <a
                        class="link left-nav-link"
                        *ngIf="item.customUrl?.startsWith('http') && !item.isModal"
                        [attr.href]="item.customUrl"
                        [attr.target]="item.target"
                    >
                        <span [attr.data-i18n-code]="item.code"></span>
                    </a>

                    <a
                        class="link left-nav-link"
                        *ngIf="!item.customUrl?.startsWith('http') && !item.isModal"
                        [class.active]="navTarget == item.code"
                        [routerLink]="item.customUrl || url.getUrl(item.code)"
                        [fragment]="item.fragment"
                        (click)="close()"
                    >
                        <span [attr.data-i18n-code]="item.code"></span>
                    </a>

                    <a class="link left-nav-link" *ngIf="item.isModal" (click)="openModuleModal(item.module)">
                        <span [attr.data-i18n-code]="item.code"></span>
                    </a>

                    <div class="active-marker" [class.show]="navTarget == item.code"></div>
                </div>
            </div>
        </ng-container>
    </div>

    <div class="left-nav-user-wrapper">
        <div
            appTranslate
            id="left-navigation-user-button"
            [class.hide]="!userInfo && !userInitials && !(loggedIn$ | async)"
        >
            <div class="margin-bottom-15 flex-container align-items-center">
                <figure class="user-image figure-wrapper" [class.hide]="!userInfo?.photo">
                    <img [attr.src]="userInfo?.photo" class="figure-image width-100" />
                </figure>

                <div
                    class="user-initials flex-container justify-content-center align-items-center"
                    [class.hide]="userInfo?.photo"
                >
                    <span>{{ userInitials }}</span>
                </div>

                <div class="margin-left-15">
                    <div class="username margin-bottom-5">{{ userInfo?.firstName }} {{ userInfo?.lastName }}</div>
                    <ng-container *ngFor="let balance of userInfo?.balances">
                        <p
                            appTranslate
                            class="user-balance margin-bottom-5 text-uppercase"
                            *ngIf="balance.type === 'points' && siteBranding?.uiConfig?.pointsOptions?.showPoints"
                        >
                            <span
                                [attr.data-i18n-code]="
                                    'core.currencies.explicit.' + balance.code + '|value_' + balance.value
                                "
                                [attr.data-i18n-params]="balance.value"
                                [attr.data-i18n-persist]="'true'"
                                [attr.data-i18n-withParams]="'value'"
                                *ngIf="balance.code !== 'LOYPNT'"
                            >
                            </span>

                            <ng-container *ngIf="balance.code === 'LOYPNT'">
                                <span>{{ balance.value }} </span>
                                <span [attr.data-i18n-code]="'core.currencies.LOYPNT'"></span>
                            </ng-container>
                        </p>

                        <p
                            appTranslate
                            class="user-credit margin-bottom-5 text-uppercase"
                            *ngIf="balance.type === 'credit'"
                        >
                            <span [attr.data-i18n-code]="'core.labels.credit'"></span>:
                            {{ balance.value | formatCurrency: balance.code }}
                        </p>
                    </ng-container>
                </div>
            </div>

            <div class="margin-bottom-15">
                <mat-divider role="" class="divider"></mat-divider>
            </div>

            <div>
                <a
                    class="link user-info-link margin-right-10"
                    [routerLink]="url.getUrl('rewards-user')"
                    (click)="close()"
                    [attr.data-i18n-code]="'nav.user.myRewards'"
                ></a>
                <a
                    class="link user-info-link margin-right-10"
                    [routerLink]="url.getUrl('account')"
                    (click)="close()"
                    [attr.data-i18n-code]="'nav.user.account'"
                ></a>
                <button
                    class="link user-info-link text-left ada-button"
                    (click)="logout()"
                    [attr.data-i18n-code]="'nav.user.logout'"
                ></button>
            </div>
        </div>

        <a
            [routerLink]="url.getUrl('login')"
            class="link user-info-link login-link"
            (click)="close()"
            [attr.data-i18n-code]="'nav.user.login'"
            [class.hide]="loggedIn$ | async"
        ></a>
    </div>
</div>

<div
    class="left-navigation-wrapper-noAvatar"
    *ngIf="siteBranding?.uiConfig.siteOptions.designOptions.userMenu.variant === 'noAvatar'"
>
    <div
        class="padding-top-20 padding-right-40 padding-bottom-20 padding-left-40 padding-sm-left-15 padding-sm-right-15"
    >
        <div class="username margin-bottom-15">{{ userInfo?.firstName }} {{ userInfo?.lastName }}</div>

        <ng-container *ngFor="let balance of userInfo?.balances">
            <p
                appTranslate
                class="user-balance margin-bottom-5 text-uppercase"
                *ngIf="balance.type === 'points' && siteBranding?.uiConfig?.pointsOptions?.showPoints"
            >
                <span
                    [attr.data-i18n-code]="'core.currencies.explicit.' + balance.code + '|value_' + balance.value"
                    [attr.data-i18n-params]="balance.value"
                    [attr.data-i18n-persist]="'true'"
                    [attr.data-i18n-withParams]="'value'"
                    *ngIf="balance.code !== 'LOYPNT'"
                >
                </span>

                <ng-container *ngIf="balance.code === 'LOYPNT'">
                    <span>{{ balance.value }} </span>
                    <span [attr.data-i18n-code]="'core.currencies.LOYPNT'"></span>
                </ng-container>
            </p>

            <p appTranslate class="user-credit margin-bottom-5 text-uppercase" *ngIf="balance.type === 'credit'">
                <span [attr.data-i18n-code]="'core.labels.credit'"></span>:
                {{ balance.value | formatCurrency: balance.code }}
            </p>
        </ng-container>
    </div>

    <mat-divider role="" class="divider"></mat-divider>

    <div
        class="flex-container flex-column padding-top-20 padding-right-40 padding-bottom-20 padding-left-40 padding-sm-left-15 padding-sm-right-15"
    >
        <div *ngIf="!siteBranding?.uiConfig?.siteOptions?.designOptions?.userMenu?.groups?.length">
            <a
                class="link user-info-link margin-bottom-10"
                [routerLink]="url.getUrl('rewards-user')"
                (click)="close()"
                [attr.data-i18n-code]="'nav.user.myRewards'"
            ></a>
            <a
                class="link user-info-link margin-bottom-10"
                [routerLink]="url.getUrl('account')"
                (click)="close()"
                [attr.data-i18n-code]="'nav.user.account'"
            ></a>
        </div>

        <div class="flex-container flex-column hide-md-up">
            <ng-container *ngFor="let item of menuItems; index as i">
                <a
                    class="link user-info-link margin-bottom-15"
                    *ngIf="item.customUrl?.startsWith('http') && !item.isModal"
                    [attr.href]="item.customUrl"
                    [attr.target]="item.target"
                    (click)="close()"
                >
                    <span [attr.data-i18n-code]="item.code"></span>
                </a>

                <a
                    class="link user-info-link margin-bottom-15"
                    *ngIf="!item.customUrl?.startsWith('http') && !item.isModal"
                    [routerLink]="item.customUrl || url.getUrl(item.code)"
                    (click)="close()"
                >
                    <span [attr.data-i18n-code]="item.code"></span>
                </a>

                <a
                    class="link user-info-link margin-bottom-15"
                    *ngIf="item.isModal"
                    (click)="openModuleModal(item.module)"
                >
                    <span [attr.data-i18n-code]="item.code"></span>
                </a>
            </ng-container>

            <div class="margin-bottom-15">
                <mat-divider role="" class="divider"></mat-divider>
            </div>
        </div>

        <div appTranslate *ngFor="let group of siteBranding?.uiConfig?.siteOptions?.designOptions?.userMenu?.groups">
            <div
                class="group-header-wrapper flex-container justify-content-space-between margin-bottom-10 cursor-pointer"
                (click)="group.active = !group.active"
            >
                <p class="group-header link user-info-link margin-0" [attr.data-i18n-code]="group.name"></p>
                <mat-icon
                    [svgIcon]="'chevron-down'"
                    class="user-icon group-menu-icon hide-md-up"
                    [class.active]="group.active"
                ></mat-icon>
            </div>

            <div
                class="group-item-wrapper margin-bottom-10"
                *ngFor="let link of group.links"
                [class.hide]="!group.active"
            >
                <a
                    appTranslate
                    class="link user-info-link"
                    *ngIf="link.customUrl?.startsWith('http')"
                    [attr.href]="link.customUrl"
                    [attr.target]="link.target"
                    (click)="close()"
                    [attr.data-i18n-code]="link.name"
                >
                </a>

                <a
                    appTranslate
                    class="link user-info-link"
                    *ngIf="!link.customUrl?.startsWith('http')"
                    [routerLink]="link.customUrl || url.getUrl(link.name)"
                    (click)="close()"
                    [attr.data-i18n-code]="link.name"
                >
                </a>
            </div>

            <div class="margin-bottom-10">
                <mat-divider role="" class="divider"></mat-divider>
            </div>
        </div>

        <button
            class="link user-info-link text-left ada-button"
            (click)="logout(); close()"
            [attr.data-i18n-code]="'nav.user.logout'"
        ></button>
    </div>
</div>
