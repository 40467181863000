<div appTranslate id="login-page" class="login-wrapper flex-container" *ngIf="showPage">
    <div
        class="login-page-wrapper flex-container flex-column justify-content-space-between"
        [class.hide-login]="signUpScreen"
    >
        <app-login-header class="width-100"></app-login-header>
        <div class="login-form-wrapper flex-container flex-column">
            <app-login-form class="form-container" *ngIf="target === 1" (target)="target = $event"></app-login-form>
            <app-forgot-password-form
                class="form-container"
                *ngIf="target === 2"
                (target)="target = $event"
            ></app-forgot-password-form>
            <app-forgot-username-form
                class="form-container"
                *ngIf="target === 3"
                (target)="target = $event"
            ></app-forgot-username-form>
        </div>
        <app-login-footer class="width-100"></app-login-footer>
    </div>

    <div class="login-sidebar">
        <div class="sign-in-sidebar flex-container" [class.sign-up-sidebar]="signUpScreen">
            <div class="sidebar-image"></div>
            <div class="login-sidebar-wrapper">
                <div class="overlay-helper flex-container justify-content-center height-100">
                    <div
                        class="content flex-container flex-column align-items-center justify-content-center height-100"
                    >
                        <div [class.hide]="!siteBranding?.uiConfig?.userOptions?.selfSignup">
                            <h1
                                class="sidebar-title primary-title margin-bottom-10 text-center"
                                [attr.data-i18n-code]="'login.imageHeadline.label'"
                            ></h1>
                            <p
                                class="sidebar-subtitle margin-bottom-50 margin-lg-bottom-0 text-center"
                                [attr.data-i18n-code]="'login.imageSubtitle.label'"
                            ></p>
                        </div>

                        <div [class.hide]="siteBranding?.uiConfig?.userOptions?.selfSignup">
                            <h1
                                class="sidebar-title primary-title margin-bottom-10 text-center"
                                [attr.data-i18n-code]="'signup.imageHeadline.label'"
                            ></h1>
                            <p
                                class="sidebar-subtitle margin-bottom-50 margin-lg-bottom-0 text-center"
                                [attr.data-i18n-code]="'signup.imageSubtitle.label'"
                            ></p>
                        </div>

                        <button
                            mat-stroked-button
                            [color]="'accent'"
                            class="sign-up-button color-white text-uppercase"
                            (click)="signUpScreen = true"
                            [class.hide]="!siteBranding?.uiConfig?.userOptions?.selfSignup"
                        >
                            <span [attr.data-i18n-code]="'core.labels.signUp'"></span>
                        </button>
                    </div>
                </div>
            </div>

            <div class="sign-up-sidebar-wrapper">
                <div class="overlay-helper flex-container justify-content-center height-100">
                    <div
                        class="content flex-container flex-column align-items-center justify-content-center height-100"
                    >
                        <div>
                            <h1
                                class="sidebar-title primary-title margin-bottom-10 text-center"
                                [attr.data-i18n-code]="'signup.imageHeadline.label'"
                            ></h1>
                            <p
                                class="sidebar-subtitle margin-bottom-50 margin-lg-bottom-0 text-center"
                                [attr.data-i18n-code]="'signup.imageSubtitle.label'"
                            ></p>
                        </div>

                        <button
                            mat-stroked-button
                            [color]="'accent'"
                            class="sign-in-button color-white text-uppercase"
                            (click)="signUpScreen = false"
                        >
                            <span [attr.data-i18n-code]="'core.labels.signIn'"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div
        class="sign-up-page-wrapper flex-container flex-column justify-content-space-between"
        [class.hide]="signUpTempHide"
        [class.hide-register]="!signUpScreen"
    >
        <app-login-header class="width-100"></app-login-header>
        <div class="sign-up-form-wrapper flex-container flex-column">
            <app-register-form (userRegistered)="userRegistered($event)"></app-register-form>
        </div>
        <app-login-footer class="width-100"></app-login-footer>
    </div>
</div>
