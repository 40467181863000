import { AdobeAnalyticsDetails, AssetValue, GoogleAnalyticsDetails } from '@shared/models';

import { FlightCabinClass, FlightCompany } from './search-response';

export interface Flight {
    index: number;
    id: string;
    youPay: AssetValue;
    youEarn: AssetValue;
    departureFlightLeg: FlightLeg;
    departureTime: number;
    arrivalTime: number;
    totalFlightTime: number;
    ga: GoogleAnalyticsDetails;
    oa: AdobeAnalyticsDetails;
    returnFlightLeg?: FlightLeg;
    rules?: string[];
    unifiedAirlines?: FlightCompany[];
    unifiedCabinClasses?: FlightCabinClass[];
    tax?: AssetValue;
    taxIncluded?: boolean;
}

export interface FlightLeg {
    segments: FlightSegment[];
    numStops: number;
    totalFlightTime: string;
    daysOffset: number;
}

export class FlightSegment {
    constructor(
        public airlineLogo: string,
        public airlineCode: string,
        public airlineName: string,
        public arrivalCity: string,
        public arrivalFull: string,
        public arrivalAirport: string,
        public arrivalDate: string,
        public arrivalTime: string,
        public baggabeAllowance: number,
        public departureCity: string,
        public departureFull: string,
        public departureAirport: string,
        public departureDate: string,
        public departureTime: string,
        public equipment: string,
        public equipmentCode: string,
        public equipmentName: string,
        public flightTime: string,
        public flightNumber: string,
        public flightCabin: string,
        public iataCode: string,
        public iataName: string,
        public layover: string,
        public noOfStops: number,
        public refundable: boolean,
        public baggageAllowance: number
    ) {}
}

export class FlightStop {
    constructor(public city: string, public airport: string) {}
}
