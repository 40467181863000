import { PayPriceBreakdown } from '@shared/models';

import { RentalConditions } from './rental-conditions';

export class BookResponse {
    bags: number;
    carImage: string;
    carClass: string;
    carName: string;
    carVendorName: string;
    carCancelPenaltyPolicy: string;
    carRefundPolicy: string;
    pickupDateTime: string;
    dropoffDateTime: string;
    pickupAddress: string;
    dropoffAddress: string;
    passengers: number;
    confirmCode: string;
    txGuid: string;
    payCardName: string;
    payCardType: string;
    payCardNumber: string;
    payCardExpDate: string;
    payPriceBreakdown: PayPriceBreakdown[];
    payTotalAmount: string;
    payPointsEarned: string;
    driverFirstName: string;
    driverLastName: string;
    rentalConditions: RentalConditions;
    refundable: boolean;
    prepaid: boolean;
    transmission: string;
}
