import { Location } from '@shared/models';

export interface Destinations {
    countries: Country[];
}

interface Country extends BaseDestination {
    provinces?: Province[];
}

interface Province extends BaseDestination {
    locations: BaseDestination[];
}

export interface BaseDestination {
    id: string;
    name: string;
    location: Location;
}
