import { Injectable } from '@angular/core';
import { DistanceData } from '@shared/models';

@Injectable({
    providedIn: 'root',
})
export class GlobalHelpersService {
    getArrayOfNumbers(int: number): Array<number> {
        return Array(int);
    }

    calculateDistanceBetweenCoords(
        unit: string,
        lat1: number,
        lon1: number,
        lat2: number,
        lon2: number,
        r = 6371
    ): DistanceData {
        const dLat = ((lat2 - lat1) * Math.PI) / 180;
        const dLon = ((lon2 - lon1) * Math.PI) / 180;
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos((lat1 * Math.PI) / 180) *
                Math.cos((lat2 * Math.PI) / 180) *
                Math.sin(dLon / 2) *
                Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const d = r * c;

        const value =
            unit === 'km'
                ? parseFloat(((d * 100) / 100).toFixed(1))
                : parseFloat((parseFloat(((d * 100) / 100).toFixed(1)) * 0.6214).toFixed(1));

        if (d > 1) {
            return {
                value,
                label: `${value} ${unit}`,
            };
        } else if (d <= 1) {
            return {
                value,
                label: unit === 'km' ? parseFloat(((d * 1000 * 100) / 100).toFixed(1)) + ' m' : value + ' mi',
            };
        }

        return {
            value: 0,
            label: `0 ${unit === 'km' ? 'm' : 'mi'}`,
        };
    }
}
