export enum StorageKeys {
    SEARCH_RESPONSE = 'cards',
    SEARCH_FILTERS = 'cards_filters',
    SEARCH_ITEM = 'card_search_item',
    ITEM_TO_BOOK = 'card_to_book',
    BOOK_RESPONSE = 'cards_book_response',
    SEARCH_START_TIME = 'cards_search_start_time',
    BOOK_START_TIME = 'cards_book_start_time',
    BOOK_TRIGGERED = 'cards_book_triggered',
}
