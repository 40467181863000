import { SearchRequest } from '../request/search-request';

import { Activity } from './activity';
import { SearchFilters } from './search-filters';

export class SavedSearch {
    items: Activity[];
    searchParams: SearchRequest;
    filters: SearchFilters;
}
