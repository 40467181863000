import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UrlService } from '@shared/services';

@Component({
    selector: 'app-confirmation-page-header',
    templateUrl: './confirmation-page-header.component.html',
    styleUrls: ['./confirmation-page-header.component.scss'],
})
export class ConfirmationPageHeaderComponent implements OnInit {
    @Input()
    receipt: number;

    @Input()
    bookingStatus: string;

    @Input()
    prepaid = true;

    @Input()
    module: string;

    reservationId: number;

    constructor(public url: UrlService, private route: ActivatedRoute) {}

    ngOnInit(): void {
        this.reservationId = this.route.snapshot.params['id'];
    }
}
