import { CancellationResponse, ConfirmationDetails } from '@account/models';
import { AccountService } from '@account/services';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LoaderService, UserAuthService } from '@core/services';
import { DataTemplate, GenericListItem, GenericSearchResponse, SelectOption, SharedStorageKeys } from '@shared/models';
import { FormErrorService, I18nRefreshService, I18nService, UrlService } from '@shared/services';
import * as moment from 'moment';
import { defer, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-cancellation-dialog',
    templateUrl: './cancellation-dialog.component.html',
    styleUrls: ['./cancellation-dialog.component.scss'],
})
export class CancellationDialogComponent implements OnInit, OnDestroy {
    sharedStorageKeys = SharedStorageKeys;
    penaltyDatePassed: boolean;
    cancellationReasons: SelectOption[];

    errorMessage: string;

    cancellationFormGroup: UntypedFormGroup;
    formErrors: Record<string, string> = {
        reasonId: '',
    };
    validationMessages: Record<string, any>;

    private unsubscribe: Subject<void> = new Subject<void>();

    constructor(
        public dialogRef: MatDialogRef<CancellationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { id: number; confirmationDetails: ConfirmationDetails },
        private router: Router,
        private url: UrlService,
        private loader: LoaderService,
        private i18nService: I18nService,
        private accountService: AccountService,
        private userAuthService: UserAuthService,
        private formErrorService: FormErrorService,
        private i18nRefreshService: I18nRefreshService
    ) {}

    ngOnInit(): void {
        this.penaltyDatePassed = moment().isAfter(moment(this.data.confirmationDetails.cancelPenaltyDate));

        this.i18nRefreshService
            .getState()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.getValidationMessages(true);
            });

        this.accountService
            .getCancellationReasons()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(
                (response: DataTemplate<GenericSearchResponse<GenericListItem>>) => {
                    this.cancellationReasons = response.data.items.map(
                        (item: GenericListItem) => new SelectOption(item.id, item.name)
                    );
                    this.createCancellationForm();
                },
                (error: string) => {
                    console.error(error);
                }
            );

        this.getValidationMessages();
        this.createCancellationForm();
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    createCancellationForm(): void {
        this.cancellationFormGroup = new UntypedFormGroup({
            reasonId: new UntypedFormControl(null, [Validators.required]),
        });

        this.cancellationFormGroup.valueChanges
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() =>
                this.formErrorService.checkForErrors(
                    this.cancellationFormGroup,
                    this.formErrors,
                    this.validationMessages
                )
            );
    }

    submitCancellation(): void {
        this.formErrorService.markAsDirty(this.cancellationFormGroup);
        this.formErrorService.checkForErrors(this.cancellationFormGroup, this.formErrors, this.validationMessages);

        if (this.cancellationFormGroup.valid) {
            defer(() => {
                this.loader.show();
                return this.accountService
                    .cancelReservation(this.data.id, this.cancellationFormGroup.value)
                    .pipe(takeUntil(this.unsubscribe));
            }).subscribe(
                (response: DataTemplate<CancellationResponse>) => {
                    this.userAuthService
                        .refreshUserInfo()
                        .pipe(
                            takeUntil(this.unsubscribe),
                            finalize(() => this.loader.hide(true))
                        )
                        .subscribe(
                            () => {
                                sessionStorage.setItem(`cancellation_response`, JSON.stringify(response.data));
                                sessionStorage.removeItem(`confirmation_details_${this.data.id}`);
                                this.dialogRef.close();
                                this.router.navigateByUrl(
                                    this.url.getUrl('cancellation-confirmation', {
                                        module: this.data.confirmationDetails.module,
                                        id: this.data.id,
                                    })
                                );
                            },
                            (error: string) => {
                                console.error(error);
                                this.errorMessage = error;
                            }
                        );
                },
                (error: string) => {
                    console.error(error);
                    this.errorMessage = error;
                }
            );
        }
    }

    getValidationMessages(swapValidationMessages = false): void {
        this.i18nService
            .preloadKeys(['core.errors.reasonRequired'])
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                const keys = this.i18nService.getSavedKeys();

                this.validationMessages = {
                    reasonId: {
                        required: keys['core.errors.reasonRequired'],
                    },
                };

                if (swapValidationMessages) {
                    this.cancellationFormGroup.clearValidators();
                    this.cancellationFormGroup.updateValueAndValidity();
                }
            });
    }
}
