<div
    appTranslate
    id="navigation-user-details"
    class="position-relative"
    [class.hide]="!(loggedIn$ | async) || isGuest"
    (clickOutside)="showUserMenu = false"
>
    <div class="position-relative">
        <button
            role="button"
            aria-label="User Menu"
            class="user-button-wrapper cursor-pointer"
            (click)="showUserMenu = !showUserMenu"
        >
            <ng-container *ngIf="siteBranding?.uiConfig?.siteOptions?.designOptions?.userMenu?.variant === 'default'">
                <figure class="user-image figure-wrapper" [class.hide]="!userInfo?.photo">
                    <img [attr.src]="userInfo?.photo" class="figure-image width-100" />
                </figure>

                <div
                    class="user-info-button flex-container justify-content-center align-items-center"
                    [class.hide]="userInfo?.photo"
                >
                    <span>{{ userInitials }}</span>
                </div>
            </ng-container>

            <div
                class="no-avatar-menu-wrapper flex-container align-items-center"
                *ngIf="siteBranding?.uiConfig?.siteOptions?.designOptions?.userMenu?.variant === 'noAvatar'"
            >
                <img src="/assets/images/ll-menu-icon-white.png" class="user-icon margin-right-5" />
                <mat-icon [svgIcon]="'caret-down'" class="user-icon"></mat-icon>
            </div>
        </button>

        <div class="active-marker" [class.show]="navTarget === 'account'"></div>
    </div>

    <div appTranslate class="user-info pop-up-menu" *ngIf="showUserMenu">
        <div class="padding-top-20 padding-right-40 padding-bottom-20 padding-left-20">
            <div class="username" [class.margin-bottom-15]="userInfo?.balances?.length">
                {{ userInfo?.firstName }} {{ userInfo?.lastName }}
            </div>

            <ng-container *ngFor="let balance of userInfo?.balances">
                <p
                    appTranslate
                    class="user-balance margin-bottom-5 text-uppercase"
                    *ngIf="balance.type === 'points' && siteBranding?.uiConfig?.pointsOptions?.showPoints"
                >
                    <span
                        [attr.data-i18n-code]="'core.currencies.explicit.' + balance.code + '|value_' + balance.value"
                        [attr.data-i18n-params]="balance.value"
                        [attr.data-i18n-persist]="'true'"
                        [attr.data-i18n-withParams]="'value'"
                        *ngIf="balance.code !== 'LOYPNT'"
                    >
                    </span>

                    <ng-container *ngIf="balance.code === 'LOYPNT'">
                        <span>{{ balance.value }} </span>
                        <span [attr.data-i18n-code]="'core.currencies.LOYPNT'"></span>
                    </ng-container>
                </p>

                <p appTranslate class="user-credit margin-bottom-5 text-uppercase" *ngIf="balance.type === 'credit'">
                    <span [attr.data-i18n-code]="'core.labels.credit'"></span>:
                    {{ balance.value | formatCurrency: balance.code }}
                </p>
            </ng-container>
        </div>

        <div class="margin-bottom-5">
            <mat-divider role=""></mat-divider>
        </div>

        <ng-container *ngIf="siteBranding?.uiConfig?.siteOptions?.designOptions?.userMenu?.includeModules">
            <div class="menu-section-wrapper hide-md-up">
                <ul class="flex-container flex-column ada-list">
                    <ng-container *ngFor="let item of menuItems; index as i">
                        <li>
                            <a
                                class="link user-info-link"
                                *ngIf="item.customUrl?.startsWith('http')"
                                [attr.href]="item.customUrl"
                                [attr.target]="item.target"
                            >
                                <span [attr.data-i18n-code]="item.code"></span>
                            </a>

                            <a
                                class="link user-info-link"
                                *ngIf="!item.customUrl?.startsWith('http')"
                                [routerLink]="item.customUrl || url.getUrl(item.code)"
                            >
                                <span [attr.data-i18n-code]="item.code"></span>
                            </a>
                        </li>
                    </ng-container>
                </ul>
            </div>

            <div class="margin-bottom-5 hide-md-up">
                <mat-divider role=""></mat-divider>
            </div>
        </ng-container>

        <div
            class="menu-section-wrapper"
            *ngIf="
                !siteBranding?.uiConfig?.siteOptions?.designOptions?.userMenu?.groups?.length ||
                siteBranding?.uiConfig?.siteOptions?.designOptions?.userMenu?.groups[0]?.links[0]?.name === 'My Rewards'
            "
        >
            <ul class="flex-container flex-column ada-list">
                <li *ngIf="siteBranding?.uiConfig?.pointsOptions?.rewardsActive">
                    <a
                        class="link user-info-link"
                        [routerLink]="url.getUrl('rewards-user')"
                        (click)="showUserMenu = false"
                        [attr.data-i18n-code]="'nav.user.myRewards'"
                    ></a>
                </li>

                <li>
                    <a
                        class="link user-info-link"
                        [routerLink]="url.getUrl('account')"
                        (click)="showUserMenu = false"
                        [attr.data-i18n-code]="'nav.user.account'"
                    ></a>
                </li>

                <li>
                    <button
                        class="link user-info-link ada-button text-left"
                        (click)="logout(); showUserMenu = false"
                        [attr.data-i18n-code]="'nav.user.logout'"
                    ></button>
                </li>
            </ul>
        </div>

        <ng-container
            *ngIf="
                siteBranding?.uiConfig?.siteOptions?.designOptions?.userMenu?.groups[0]?.links[0]?.name !== 'My Rewards'
            "
        >
            <ng-container *ngFor="let group of siteBranding?.uiConfig?.siteOptions?.designOptions?.userMenu?.groups">
                <div class="menu-section-wrapper">
                    <div
                        class="group-header-wrapper flex-container justify-content-space-between"
                        (click)="group.active = !group.active"
                    >
                        <p class="group-header margin-0" [attr.data-i18n-code]="group.name"></p>
                        <mat-icon
                            [svgIcon]="'chevron-down'"
                            class="user-icon group-menu-icon hide-md-up"
                            [class.active]="group.active"
                        ></mat-icon>
                    </div>

                    <ul class="ada-list">
                        <ng-container *ngFor="let link of group.links" [class.hide]="!group.active">
                            <li>
                                <a
                                    class="link user-info-link"
                                    *ngIf="link.customUrl?.startsWith('http')"
                                    [attr.href]="link.customUrl"
                                    [attr.target]="link.target"
                                    [attr.data-i18n-code]="link.name"
                                ></a>

                                <a
                                    class="link user-info-link"
                                    *ngIf="!link.customUrl?.startsWith('http')"
                                    [routerLink]="link.customUrl || url.getUrl(link.name)"
                                    (click)="showUserMenu = false"
                                    [attr.data-i18n-code]="link.name"
                                ></a>
                            </li>
                        </ng-container>
                    </ul>
                </div>

                <mat-divider role=""></mat-divider>
            </ng-container>

            <div class="menu-section-wrapper">
                <button
                    class="link user-info-link text-left ada-button"
                    [attr.data-i18n-code]="'nav.user.logout'"
                    (click)="logout(); showUserMenu = false"
                ></button>
            </div>
        </ng-container>
    </div>
</div>

<a
    class="link login-link"
    [class.hide]="(loggedIn$ | async) && !isGuest"
    [routerLink]="url.getUrl('login')"
    [attr.data-i18n-code]="'nav.user.login'"
></a>

<div appTranslate id="assist-member-banner" class="assisting-banner text-center" *ngIf="userInfo?.assisted">
    <span
        [attr.data-i18n-code]="'core.labels.adminAssisting'"
        [attr.data-i18n-params]="
            userInfo?.assisted?.assistAdminFirstName + '|' + userInfo?.assisted?.assistAdminLastName
        "
    ></span>
</div>
