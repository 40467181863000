import { EventEmitter, Injectable } from '@angular/core';
import { LeftNavigationData } from '@shared/models';

@Injectable({
    providedIn: 'root',
})
export class NavigationService {
    elements: EventEmitter<LeftNavigationData> = new EventEmitter<LeftNavigationData>();
    hide: EventEmitter<boolean> = new EventEmitter<boolean>();
}
