import { SearchRequest } from '@activities/models';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-activities-recent-search',
    templateUrl: './activities-recent-search.component.html',
    styleUrls: ['./activities-recent-search.component.scss'],
})
export class ActivitiesRecentSearchComponent {
    @Input()
    search: SearchRequest;
}
