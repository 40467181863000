import { PayPriceBreakdown } from '@shared/models';

import { EscapeAccommodation, EscapePolicy } from './escape-details';

export class BookResponse {
    confirmCode: string;
    txGuid: string;
    payCardName: string;
    payCardType: string;
    payCardnumber: string;
    payCardExpDate: string;
    payPriceBreakdown: PayPriceBreakdown[];
    payTotalAmount: string;
    payPointsEarned: string;
    refundable: boolean;
    // travelers: TravelerProfile;

    escapeName: string;
    escapeImage: string;
    address: string;
    location: string;
    departDate: string;
    returnDate: string;
    days: number;
    nights: number;
    adults: number;
    children: number;
    infants: number;
    leadTraveler: string;
    travelers: string;
    policies: EscapePolicy[];
    accommodations: EscapeAccommodation[];
}
