import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
    selector: 'img[appImgFallback]',
})
export class ImageFallbackDirective {
    @Input()
    fallbackUrl: string;

    constructor(private renderer: Renderer2, private eRef: ElementRef) {}

    @HostListener('error')
    loadFallbackOnError(): void {
        const element: HTMLImageElement = this.eRef.nativeElement as HTMLImageElement;
        element.src = this.fallbackUrl.trim() || '/assets/images/hotel.svg';
        this.renderer.addClass(element, 'fallback-image');
        this.renderer.addClass(element.parentElement.parentElement, 'fallback-image-wrapper');
    }
}
