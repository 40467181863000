import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    ViewChild,
} from '@angular/core';
import { NavigationItem } from '@shared/models';

@Component({
    selector: 'app-details-page-navigation',
    templateUrl: './details-page-navigation.component.html',
    styleUrls: ['./details-page-navigation.component.scss'],
})
export class DetailsPageNavigationComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input()
    navigationItems: NavigationItem[];

    @Input()
    actionButtonLabel: string;

    @Output()
    scrollTo: EventEmitter<string> = new EventEmitter<string>();

    @Output()
    actionButton: EventEmitter<void> = new EventEmitter<void>();

    bindScroll: any;
    lastScrollTop: number;
    activeNavigation: string;

    navigationEl: ElementRef;
    @ViewChild('navigationElement') set navigationContent(content: ElementRef) {
        this.navigationEl = content;
    }

    constructor(private renderer: Renderer2) {}

    ngOnInit(): void {
        setTimeout(() => {
            this.activeNavigation = this.navigationItems[0]?.code;
        }, 0);
    }

    ngAfterViewInit(): void {
        this.bindScroll = this.onScroll.bind(this);
        window.addEventListener('scroll', this.bindScroll, true);
    }

    ngOnDestroy(): void {
        window.removeEventListener('scroll', this.bindScroll, true);
    }

    onScroll(): void {
        if (this.navigationEl) {
            if (this.lastScrollTop === parseInt(this.navigationEl.nativeElement.getBoundingClientRect().top, 10)) {
                this.renderer.addClass(this.navigationEl.nativeElement, 'sticky');
            } else {
                this.renderer.removeClass(this.navigationEl.nativeElement, 'sticky');
            }

            this.lastScrollTop = parseInt(this.navigationEl.nativeElement.getBoundingClientRect().top, 10);
        }

        let navigationTarget = null;
        let closestElementTop = 1;
        let prevKey = null;

        this.navigationItems.forEach((item: NavigationItem) => {
            const elTop = item.element.nativeElement.getBoundingClientRect().top;

            if (elTop < 30) {
                if (elTop >= closestElementTop) {
                    closestElementTop = elTop;
                    navigationTarget = item.code;
                }
            } else if (!navigationTarget) {
                navigationTarget = prevKey;
            }

            prevKey = item.code;
        });

        if (navigationTarget) {
            this.activeNavigation = navigationTarget;
        }
    }

    triggerNavClick(target: string): void {
        const el = document.querySelector(`#${target}`);

        if (el) {
            el.dispatchEvent(new Event('click'));
        }
    }
}
