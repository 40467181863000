import { Location } from '@shared/models/response/location';

export class Trip {
    id: number;
    name: string;
    startDate: string;
    endDate: string;
    leadTravelerFirstName: string;
    leadTravelerLastName: string;
    items: TripItem[];
}

export class TripItem {
    id: number;
    typeCode: string;
    typeName: string;
    startDate: string;
    endDate: string;
    description: string;
    leadTravelerFirstName: string;
    leadTravelerLastName: string;
    address: string;
    location: Location;
}
