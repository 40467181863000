import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { LoaderService } from '@core/services';
import { DataTemplate } from '@shared/models';
import { UrlService } from '@shared/services';
import { RowItem, UrgentInfoItem, WeekDetails } from '@weeks/models';
import * as moment from 'moment';
import { of, Subject } from 'rxjs';
import { catchError, finalize, mergeMap, take } from 'rxjs/operators';

import { WeeksService } from './weeks.service';

@Injectable({
    providedIn: 'root',
})
export class WeekDetailsResolverService implements Resolve<WeekDetails>, OnDestroy {
    private unsubscribe: Subject<void> = new Subject();

    constructor(
        private weeksService: WeeksService,
        private loader: LoaderService,
        private url: UrlService,
        private router: Router
    ) {}

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    resolve(route: ActivatedRouteSnapshot): any {
        const hotel = JSON.parse(sessionStorage.getItem(`week_${route.params['id']}`)) as DataTemplate<WeekDetails>;

        if (hotel) {
            return of(hotel as DataTemplate<WeekDetails>);
        } else {
            this.loader.show();

            return this.weeksService.getDetails(route.params['id']).pipe(
                take(1),
                finalize(() => this.loader.hide()),
                mergeMap((response: DataTemplate<WeekDetails>) => {
                    const week: WeekDetails = JSON.parse(JSON.stringify(response.data));

                    if (!week.images && week.imageUrl) {
                        week.images = [week.imageUrl, week.imageUrl];
                    }

                    if (week.urgentInfo?.length > 0) {
                        week.urgentInfo.forEach((item: UrgentInfoItem) => {
                            if (item.type === 'table' && item.table?.rows?.length > 0) {
                                return (item.table.rows = item.table.rows.map((rowItem: RowItem) => {
                                    if (rowItem.column?.length > 0) {
                                        rowItem.column = rowItem.column.map((columnItem: string) => {
                                            if (
                                                new Date(columnItem) instanceof Date &&
                                                !isNaN(new Date(columnItem).getTime())
                                            ) {
                                                columnItem = moment(new Date(columnItem)).format('MM/DD/YYYY');
                                            }

                                            return columnItem;
                                        });
                                    }

                                    return rowItem;
                                }));
                            }
                        });
                    }

                    sessionStorage.setItem(`week_${route.params['id']}`, JSON.stringify(week));

                    return of(week);
                }),
                catchError((error: string) => {
                    console.error(error);
                    this.router.navigateByUrl(this.url.getUrl('weeks'));
                    return of(null);
                })
            );
        }
    }
}
