<button
    [attr.aria-label]="
        'Travelers: ' +
        adultsCounter +
        ' adult' +
        (adultsCounter > 1 ? 's' : '') +
        ', ' +
        childrenCounter +
        (childrenCounter === 1 ? ' child' : ' children')
    "
    [attr.aria-expanded]="showCounters"
    role="group"
    class="ada-button width-100 height-100 padding-0"
    (click)="showCounters = true"
    (clickOutside)="updateGuests()"
>
    <div
        class="flex-container counters-wrapper height-100 position-relative"
        [class.cursor-pointer]="!showCounters"
        [class.fixed]="isFixed"
        [class.active]="isActive"
        [class.counters-active]="showCounters"
        [class.hotel-details-page]="hotelDetailsPage"
        [class.activity-details-page]="activityDetailsPage"
    >
        <div class="global-wrapper flex-container width-100" [class.overlay-active]="showCounters">
            <div class="adults-select-wrapper widget-element" *ngIf="adultAgeBand || travelerAgeBand">
                <div
                    class="position-relative height-100 flex-container align-items-center justify-content-space-between"
                >
                    <div
                        class="adults-value-wrapper flex-inline-container width-100"
                        [class.justify-content-space-between]="showCounters"
                    >
                        <mat-icon [svgIcon]="'adult'" class="widget-icon" *ngIf="!showCounters"></mat-icon>

                        <button
                            aria-label="Decrement adults"
                            class="ada-button control-icon"
                            (click)="updateAdults(-1)"
                            *ngIf="showCounters"
                            [disabled]="adultsCounter === minAdults"
                        >
                            <mat-icon
                                [svgIcon]="'subtract'"
                                class="control-icon"
                                [class.disabled]="adultsCounter === minAdults"
                            ></mat-icon>
                        </button>

                        <div>
                            <span
                                >{{ adultsCounter }}
                                <span class="adults-label text-lowercase">
                                    <span
                                        [class.hide]="adultsCounter !== 1"
                                        [attr.data-i18n-code]="'core.labels.adult'"
                                    ></span>
                                    <span
                                        [class.hide]="adultsCounter === 1"
                                        [attr.data-i18n-code]="'core.labels.adults'"
                                    ></span>
                                    <span *ngIf="adultAgeBand"
                                        >{{ ' ' }}(<span [attr.data-i18n-code]="'core.labels.ages'"></span>
                                        {{ adultAgeBand.startAge }} - {{ adultAgeBand.endAge }})</span
                                    >
                                </span>
                            </span>

                            <div appTranslate *ngIf="showCounters && travelerAgeBand">
                                <span [attr.data-i18n-code]="'core.labels.min'"></span>:
                                {{ travelerAgeBand.minTravelersPerBooking || 1 }},
                                <span [attr.data-i18n-code]="'core.labels.max'"></span>:
                                {{ travelerAgeBand.maxTravelersPerBooking }}
                            </div>

                            <div appTranslate *ngIf="showCounters && adultAgeBand">
                                <span [attr.data-i18n-code]="'core.labels.min'"></span>:
                                {{ adultAgeBand.minTravelersPerBooking || 1 }},
                                <span [attr.data-i18n-code]="'core.labels.max'"></span>:
                                {{ adultAgeBand.maxTravelersPerBooking }}
                            </div>
                        </div>

                        <button
                            aria-label="Increment adults"
                            id="increment-adults"
                            class="ada-button control-icon margin-right-10"
                            (click)="updateAdults(1)"
                            *ngIf="showCounters"
                            [disabled]="disableAdultsIncrement"
                        >
                            <mat-icon
                                [svgIcon]="'add'"
                                class="control-icon"
                                [class.disabled]="disableAdultsIncrement"
                                [class.cursor-pointer]="showCounters"
                            ></mat-icon>
                        </button>
                    </div>
                    <mat-divider role="" [vertical]="true" class="widget-element-separator"></mat-divider>
                </div>
            </div>

            <div class="adults-select-wrapper widget-element position-relative" *ngIf="seniorAgeBand">
                <div class="position-relative height-100 flex-container align-items-center">
                    <div
                        class="children-value-wrapper flex-inline-container width-100"
                        [class.justify-content-space-between]="showCounters"
                    >
                        <mat-icon [svgIcon]="'adult'" class="widget-icon" *ngIf="!showCounters"></mat-icon>

                        <button
                            aria-label="Decrement seniors"
                            class="ada-button control-icon"
                            (click)="updateSeniors(-1)"
                            *ngIf="showCounters"
                            [disabled]="seniorsCounter === minSeniors"
                        >
                            <mat-icon
                                [svgIcon]="'subtract'"
                                class="control-icon"
                                [class.disabled]="seniorsCounter === minSeniors"
                            ></mat-icon>
                        </button>

                        <div>
                            <span
                                >{{ seniorsCounter }}
                                <span class="adults-label text-lowercase">
                                    <span
                                        [class.hide]="seniorsCounter !== 1"
                                        [attr.data-i18n-code]="'core.labels.senior'"
                                    ></span>
                                    <span
                                        [class.hide]="seniorsCounter === 1"
                                        [attr.data-i18n-code]="'core.labels.seniors'"
                                    ></span>
                                    <span
                                        >{{ ' ' }}(<span [attr.data-i18n-code]="'core.labels.ages'"></span>
                                        {{ seniorAgeBand.startAge }} - {{ seniorAgeBand.endAge }})</span
                                    >
                                </span>
                            </span>

                            <div appTranslate *ngIf="showCounters && seniorAgeBand">
                                <span [attr.data-i18n-code]="'core.labels.min'"></span>:
                                {{ seniorAgeBand.minTravelersPerBooking }},
                                <span [attr.data-i18n-code]="'core.labels.max'"></span>:
                                {{ seniorAgeBand.maxTravelersPerBooking }}
                            </div>
                        </div>

                        <button
                            aria-label="Increment seniors"
                            id="increment-seniors"
                            class="ada-button control-icon"
                            (click)="updateSeniors(1)"
                            *ngIf="showCounters"
                            [class.disabled]="disableSeniorsIncrement"
                        >
                            <mat-icon
                                [svgIcon]="'add'"
                                class="control-icon margin-right-10"
                                [class.disabled]="disableSeniorsIncrement"
                                [class.cursor-pointer]="showCounters"
                            ></mat-icon>
                        </button>
                    </div>
                </div>
            </div>

            <div class="adults-select-wrapper widget-element position-relative" *ngIf="youthAgeBand">
                <div class="position-relative height-100 flex-container align-items-center">
                    <div
                        class="children-value-wrapper flex-inline-container width-100"
                        [class.justify-content-space-between]="showCounters"
                    >
                        <mat-icon
                            [svgIcon]="'children'"
                            class="widget-icon children-icon"
                            *ngIf="!showCounters"
                        ></mat-icon>

                        <button
                            aria-label="Decrement youth"
                            class="ada-button control-icon"
                            (click)="updateYouth(-1)"
                            *ngIf="showCounters"
                            [disabled]="youthCounter === minYouth"
                        >
                            <mat-icon
                                [svgIcon]="'subtract'"
                                class="control-icon"
                                [class.disabled]="youthCounter === minYouth"
                            ></mat-icon>
                        </button>

                        <div>
                            <span
                                >{{ youthCounter }}
                                <span class="children-label text-lowercase">
                                    <span [attr.data-i18n-code]="'core.labels.youth'"></span>
                                    <span
                                        >{{ ' ' }}(<span [attr.data-i18n-code]="'core.labels.ages'"></span>
                                        {{ youthAgeBand.startAge }} - {{ youthAgeBand.endAge }})</span
                                    >
                                </span>
                            </span>

                            <div appTranslate *ngIf="showCounters && youthAgeBand">
                                <span [attr.data-i18n-code]="'core.labels.min'"></span>:
                                {{ youthAgeBand.minTravelersPerBooking }},
                                <span [attr.data-i18n-code]="'core.labels.max'"></span>:
                                {{ youthAgeBand.maxTravelersPerBooking }}
                            </div>
                        </div>

                        <button
                            aria-label="Increment youth"
                            id="increment-youth"
                            class="ada-button control-icon"
                            (click)="updateYouth(1)"
                            *ngIf="showCounters"
                            [disabled]="disableYouthIncrement"
                        >
                            <mat-icon
                                [svgIcon]="'add'"
                                class="control-icon margin-right-20 margin-lg-right-10"
                                [class.disabled]="disableYouthIncrement"
                                [class.cursor-pointer]="showCounters"
                            ></mat-icon>
                        </button>
                    </div>
                </div>
            </div>

            <div class="adults-select-wrapper widget-element position-relative" *ngIf="childAgeBand">
                <div class="position-relative height-100 flex-container align-items-center">
                    <div
                        class="children-value-wrapper flex-inline-container width-100"
                        [class.justify-content-space-between]="showCounters"
                    >
                        <mat-icon
                            [svgIcon]="'children'"
                            class="widget-icon children-icon"
                            *ngIf="!showCounters"
                        ></mat-icon>

                        <button
                            aria-label="Decrement children"
                            class="ada-button control-icon"
                            (click)="updateChildren(-1)"
                            *ngIf="showCounters"
                            [disabled]="childrenCounter === minChildren"
                        >
                            <mat-icon
                                [svgIcon]="'subtract'"
                                class="control-icon"
                                [class.disabled]="childrenCounter === minChildren"
                            ></mat-icon>
                        </button>

                        <div>
                            <span
                                >{{ childrenCounter }}
                                <span class="children-label text-lowercase">
                                    <span
                                        [class.hide]="childrenCounter !== 1"
                                        [attr.data-i18n-code]="'core.labels.child'"
                                    ></span>
                                    <span
                                        [class.hide]="childrenCounter === 1"
                                        [attr.data-i18n-code]="'core.labels.children'"
                                    ></span>
                                    <span
                                        >{{ ' ' }}(<span [attr.data-i18n-code]="'core.labels.ages'"></span>
                                        {{ childAgeBand.startAge }} - {{ childAgeBand.endAge }})</span
                                    >
                                </span>
                            </span>

                            <div appTranslate *ngIf="showCounters && childAgeBand">
                                <span [attr.data-i18n-code]="'core.labels.min'"></span>:
                                {{ childAgeBand.minTravelersPerBooking }},
                                <span [attr.data-i18n-code]="'core.labels.max'"></span>:
                                {{ childAgeBand.maxTravelersPerBooking }}
                            </div>
                        </div>

                        <button
                            aria-label="Increment children"
                            id="increment-children"
                            class="ada-button control-icon"
                            (click)="updateChildren(1)"
                            *ngIf="showCounters"
                            [disabled]="disableChildrenIncrement"
                        >
                            <mat-icon
                                [svgIcon]="'add'"
                                class="control-icon margin-right-20 margin-lg-right-10"
                                [class.disabled]="disableChildrenIncrement"
                                [class.cursor-pointer]="showCounters"
                            ></mat-icon>
                        </button>
                    </div>
                </div>
            </div>

            <div class="adults-select-wrapper widget-element position-relative" *ngIf="infantAgeBand">
                <div class="position-relative height-100 flex-container align-items-center">
                    <div
                        class="children-value-wrapper flex-inline-container width-100"
                        [class.justify-content-space-between]="showCounters"
                    >
                        <mat-icon
                            [svgIcon]="'children'"
                            class="widget-icon children-icon"
                            *ngIf="!showCounters"
                        ></mat-icon>

                        <button
                            aria-label="Decrement infants"
                            class="ada-button control-icon"
                            (click)="updateInfants(-1)"
                            *ngIf="showCounters"
                            [disabled]="infantsCounter === minInfants"
                        >
                            <mat-icon
                                [svgIcon]="'subtract'"
                                class="control-icon"
                                [class.disabled]="infantsCounter === minInfants"
                            ></mat-icon>
                        </button>

                        <div>
                            <span
                                >{{ infantsCounter }}
                                <span class="children-label text-lowercase">
                                    <span
                                        [class.hide]="infantsCounter !== 1"
                                        [attr.data-i18n-code]="'core.labels.infant'"
                                    ></span>
                                    <span
                                        [class.hide]="infantsCounter === 1"
                                        [attr.data-i18n-code]="'core.labels.infants'"
                                    ></span>
                                    <span
                                        >{{ ' ' }}(<span [attr.data-i18n-code]="'core.labels.ages'"></span>
                                        {{ infantAgeBand.startAge }} - {{ infantAgeBand.endAge }})</span
                                    >
                                </span>
                            </span>

                            <div appTranslate *ngIf="showCounters && infantAgeBand">
                                <span [attr.data-i18n-code]="'core.labels.min'"></span>:
                                {{ infantAgeBand.minTravelersPerBooking }},
                                <span [attr.data-i18n-code]="'core.labels.max'"></span>:
                                {{ infantAgeBand.maxTravelersPerBooking }}
                            </div>
                        </div>

                        <button
                            aria-label="Increment infants"
                            id="increment-infants"
                            class="ada-button control-icon"
                            (click)="updateInfants(1)"
                            *ngIf="showCounters"
                            [disabled]="disableInfantsIncrement"
                        >
                            <mat-icon
                                [svgIcon]="'add'"
                                class="control-icon margin-right-20 margin-lg-right-10"
                                [class.disabled]="disableInfantsIncrement"
                                [class.cursor-pointer]="showCounters"
                            ></mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</button>
