<div [formGroup]="group">
    <mat-form-field appearance="fill" [class.remove-validation-space]="removeValidationSpace">
        <mat-label *ngIf="label && translate" [attr.data-i18n-code]="label"></mat-label>
        <mat-label *ngIf="label && !translate">{{ label }}</mat-label>

        <mat-select
            *ngIf="!required"
            appDisableControl
            class="app-form-element"
            [placeholder]="placeholder"
            [formControlName]="name"
            [disableControl]="disabled"
            [attr.aria-describedby]="name + '-error'"
            [attr.aria-invalid]="errorMessages"
            [attr.autocomplete]="autocomplete"
            (selectionChange)="onSelect.emit($event.value)"
        >
            <ng-container *ngIf="clearable">
                <mat-option appTranslate>
                    <span [attr.data-i18n-code]="'core.labels.clear'"></span>
                </mat-option>
            </ng-container>

            <ng-container *ngIf="!i18n">
                <mat-option *ngFor="let option of options" [value]="option.value">
                    {{ option.viewValue }}
                </mat-option>
            </ng-container>

            <ng-container *ngIf="i18n">
                <mat-option appTranslate *ngFor="let option of options" [value]="option.value">
                    <span [attr.data-i18n-code]="option.viewValue"></span>
                </mat-option>
            </ng-container>
        </mat-select>

        <mat-select
            *ngIf="required"
            appDisableControl
            required
            class="app-form-element"
            [placeholder]="placeholder"
            [formControlName]="name"
            [disableControl]="disabled"
            [attr.aria-describedby]="name + '-error'"
            [attr.aria-invalid]="errorMessages"
            [attr.autocomplete]="autocomplete"
            (selectionChange)="onSelect.emit($event.value)"
        >
            <ng-container *ngIf="clearable">
                <mat-option appTranslate>
                    <span [attr.data-i18n-code]="'core.labels.clear'"></span>
                </mat-option>
            </ng-container>

            <ng-container *ngIf="!i18n">
                <mat-option *ngFor="let option of options" [value]="option.value">
                    {{ option.viewValue }}
                </mat-option>
            </ng-container>

            <ng-container *ngIf="i18n">
                <mat-option appTranslate *ngFor="let option of options" [value]="option.value">
                    <span [attr.data-i18n-code]="option.viewValue"></span>
                </mat-option>
            </ng-container>
        </mat-select>

        <mat-hint *ngIf="hint && !translateHint">
            <span>{{ hint }}</span>
        </mat-hint>
        <mat-hint *ngIf="hint && translateHint">
            <span [attr.data-i18n-code]="hint"></span>
        </mat-hint>
        <mat-error role="alert" [attr.id]="name + '-error'" *ngIf="errorMessages">
            {{ errorMessages }}
        </mat-error>
    </mat-form-field>
</div>
