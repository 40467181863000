export class LeftNavigationData {
    navigationHeader?: NavigationHeader;
    navigationSiblings: NavigationSibling[];
}

class NavigationHeader {
    title: string;
    subtitle: string;
    url?: string;
}

class NavigationSibling {
    title?: string;
    elements: SubNavigationSibling[];
}

class SubNavigationSibling {
    name: string;
    url: string;
    icon?: string;
    active?: boolean;
    selector?: string;
}
