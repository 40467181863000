import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { NguCarouselConfig } from '@ngu/carousel';

@Component({
    selector: 'app-masonry-photos-carousel',
    templateUrl: './masonry-photos-carousel.component.html',
    styleUrls: ['./masonry-photos-carousel.component.scss'],
})
export class MasonryPhotosCarouselComponent {
    @Input()
    images: string[];

    firstSix: string[];

    fullScreenCarousel: boolean;
    // hideCarousel: boolean;
    targetPhotoIndex: number;

    carouselTileConfig: NguCarouselConfig = {
        grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
        speed: 250,
        point: {
            visible: true,
        },
        touch: false,
        loop: true,
        animation: 'lazy',
    };

    carouselPrev: ElementRef;
    @ViewChild('carouselPrev') set contentPrev(content: ElementRef) {
        this.carouselPrev = content;
    }

    carouselNext: ElementRef;
    @ViewChild('carouselNext') set contentNext(content: ElementRef) {
        this.carouselNext = content;
    }

    ngOnInit(): void {
        this.firstSix = this.images.slice(0, 6);
    }

    triggerCarouselNav(direction: string): void {
        switch (direction) {
            case 'prev':
                this.carouselPrev.nativeElement.click();
                break;
            case 'next':
                this.carouselNext.nativeElement.click();
                break;
        }
    }

    openFullScreenCarousel(target: number): void {
        this.targetPhotoIndex = target;
        // this.hideCarousel = true;
        this.fullScreenCarousel = true;

        setTimeout(() => {
            for (let i = 0; i < target; i++) {
                this.carouselNext.nativeElement.click();
            }

            // this.hideCarousel = false;
        }, 0);
    }

    handleCarouselEscaped(): void {
        this.fullScreenCarousel = false;

        document.getElementById(`masonry-${this.targetPhotoIndex}`)?.focus();
    }
}
