import { Directive, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[appDisableControl]',
})
export class DisableControlDirective {
    @Input()
    set disableControl(condition: boolean) {
        // TODO: This setTimeout should not be here
        setTimeout(() => {
            const action = condition ? 'disable' : 'enable';
            if (this.ngControl?.control) {
                this.ngControl.control[action]();
            }
        }, 0);
    }

    constructor(private ngControl: NgControl) {}
}
