<mat-icon [svgIcon]="'week'" class="module-icon"></mat-icon>
<div>
    <p class="primary-data margin-bottom-5">
        <span>{{ searchCriteria.locName }}</span>
    </p>

    <p class="secondary-data margin-bottom-5">
        <span [attr.data-i18n-code]="'weeks.widgets.search.within'"></span>{{ ' ' }}<span>{{ search.within }}</span
        >{{ ' ' }}<span [attr.data-i18n-code]="'core.labels.daysOf'"></span>
        {{ search.prefferedCheckInDate | date: 'EE, dd MMM' }}
    </p>

    <div class="secondary-data flex-container align-items-center">
        <span
            >{{ adults }}
            <span class="text-lowercase">
                <span [class.hide]="adults !== 1" [attr.data-i18n-code]="'core.labels.adult'"></span>
                <span [class.hide]="adults === 1" [attr.data-i18n-code]="'core.labels.adults'"></span>
            </span>
        </span>

        <span appTranslate *ngIf="children > 0"
            >, {{ children }}
            <span class="text-lowercase">
                <span [class.hide]="children !== 1" [attr.data-i18n-code]="'core.labels.child'"></span>
                <span [class.hide]="children === 1" [attr.data-i18n-code]="'core.labels.children'"></span>
            </span>
        </span>
    </div>
</div>
