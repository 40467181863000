<div appTranslate id="offer-booking" class="main-content-wrapper booking-wrapper" *ngIf="loaded; else loader">
    <h3 class="subsection-title margin-bottom-20 bold" [attr.data-i18n-code]="'core.labels.upgradeMembership'"></h3>

    <div #errorElement class="error-message-anchor opacity-0"></div>
    <p class="error-message margin-bottom-20 bold" *ngIf="errorMessage">{{ errorMessage }}</p>

    <div class="flex-container booking-elements-container">
        <div class="booking-forms-wrapper flex-grow-1 padding-right-20">
            <div class="membership-info content-panel margin-bottom-20">
                <h3
                    class="subsection-title margin-bottom-20 content-panel-title"
                    [attr.data-i18n-code]="'core.labels.membershipInformation'"
                ></h3>

                <p appTranslate class="margin-0" *ngIf="upgradeTier?.name">
                    <span
                        [attr.data-i18n-code]="'membership.booking.upgradeInfo1.label'"
                        [attr.data-i18n-params]="
                            upgradeTier.name + '|' + (upgradeTier.membershipValidUntil | date: 'longDate')
                        "
                    ></span>

                    <span *ngIf="upgradeTier.pointsBonus?.value"
                        >{{ ' ' }}
                        <span
                            [attr.data-i18n-code]="'membership.booking.upgradeInfo2.label'"
                            [attr.data-i18n-params]="
                                upgradeTier.pointsBonus.value + '|' + siteBranding?.uiConfig.pointsOptions.pointsName
                            "
                        ></span>
                    </span>
                </p>
            </div>

            <div class="lead-traveler content-panel margin-bottom-20">
                <h3
                    class="subsection-title margin-bottom-20 content-panel-title"
                    [attr.data-i18n-code]="'core.labels.memberInformation'"
                ></h3>
                <app-membership-user-form [countries]="countries" *ngIf="countries?.length"></app-membership-user-form>
            </div>

            <div class="payment-billing content-panel margin-bottom-20">
                <h3
                    class="subsection-title margin-bottom-20 content-panel-title"
                    [attr.data-i18n-code]="'hotels.booking.paymentBilling.label'"
                ></h3>
                <app-payment-billing-form
                    [countries]="countries"
                    [cardProfiles]="cardProfiles"
                    [showCards]="true"
                ></app-payment-billing-form>
            </div>

            <div class="flex-container flex-column">
                <mat-checkbox [(ngModel)]="termsAgree" [color]="'primary'" class="margin-bottom-20">
                    <p
                        appTranslate
                        id="hotel-booking-terms-agree"
                        class="margin-bottom-0 agree-text"
                        [attr.data-i18n-code]="'membership.booking.termsAgree.label'"
                    ></p>
                </mat-checkbox>

                <mat-checkbox
                    appTranslate
                    id="hotel-booking-cardholder-agree"
                    [(ngModel)]="cardAgree"
                    [color]="'primary'"
                    class="margin-bottom-20"
                    *ngIf="siteBranding && priceCharge?.value && upgradeTier?.name"
                >
                    <p class="margin-bottom-0 agree-text">
                        <span
                            [attr.data-i18n-code]="'membership.booking.cardholderAgree.label'"
                            [attr.data-i18n-params]="
                                (priceCharge.value | formatCurrency: priceCharge.code) + '|' + upgradeTier.name + '|'
                            "
                        ></span>

                        <span appTranslate *ngIf="upgradeTier.recurringPrice?.value">
                            {{ ' ' }}
                            <span
                                [attr.data-i18n-code]="'membership.booking.cardholderAgreeRecurring.label'"
                                [attr.data-i18n-params]="
                                    'yearly' +
                                    '|' +
                                    (upgradeTier.recurringPrice.value
                                        | formatCurrency: upgradeTier.recurringPrice.code) +
                                    '|' +
                                    (upgradeTier.membershipValidUntil | date: 'longDate')
                                "
                            ></span
                        ></span>
                    </p>
                </mat-checkbox>

                <div class="flex-container flex-column align-items-center justify-content-center">
                    <div class="book-button-holder margin-bottom-10">
                        <button
                            mat-raised-button
                            class="width-100 text-uppercase"
                            [color]="'primary'"
                            (click)="submitBooking()"
                            [disabled]="!termsAgree || !cardAgree"
                        >
                            <span [attr.data-i18n-code]="'core.labels.upgradeMembership'"></span>
                        </button>
                    </div>

                    <p
                        appTranslate
                        id="hotel-booking-card-charge"
                        class="charge-value margin-bottom-0"
                        *ngIf="priceCharge"
                    >
                        <span
                            [attr.data-i18n-code]="'core.labels.cardCharge'"
                            [attr.data-i18n-params]="priceCharge.value | formatCurrency: priceCharge.code"
                        ></span>
                    </p>
                </div>
            </div>
        </div>

        <div class="price-summary-wrapper">
            <div class="booking-panel price-summary content-panel">
                <app-price-summary
                    [rateId]="membershipId"
                    [recurring]="upgradeTier.recurringPrice"
                    [redeem]="0"
                    [target]="'membership'"
                    (priceCharge)="updatePriceCharge($event)"
                >
                </app-price-summary>
            </div>
        </div>
    </div>
</div>

<ng-template #loader>
    <div class="flex-container flex-column align-items-center justify-content-center width-100 height-100">
        <mat-spinner></mat-spinner>
    </div>
</ng-template>
