import { Component, Input, OnInit } from '@angular/core';
import { BookPrice, SharedStorageKeys } from '@shared/models';
import { FormatCurrencyPipe } from '@shared/pipes';

@Component({
    selector: 'app-cars-totals',
    templateUrl: './cars-totals.component.html',
    styleUrls: ['./cars-totals.component.scss'],
    providers: [FormatCurrencyPipe],
})
export class CarsTotalsComponent implements OnInit {
    @Input()
    bookPrice: BookPrice;

    sharedStorageKeys = SharedStorageKeys;

    currentCurrency: string;

    ngOnInit(): void {
        this.currentCurrency = sessionStorage.getItem(this.sharedStorageKeys.CURRENT_CURRENCY);
    }
}
