import { PageTemplateWidget } from './template-widget';

export class SiteBranding {
    uiAssets: UiAssets;
    uiConfig: UiConfig;
    templates: PageTemplates;
}

interface UiAssets {
    logoUrl: string;
    footerLogoUrl: string;
    faviconUrl: string;
    cssVariables: string;
    head: string;
    bodyEndScript: string;
}

interface UiConfig {
    analyticsOptions: AnalyticsOptions;
    billingOptions: BillingOptions;
    localeOptions: LocaleOptions;
    modules: string[];
    moduleOptions: ModuleOptions;
    pointsOptions: PointsOptions;
    siteOptions: SiteOptions;
    userOptions: UserOptions;
    social: SocialElement[];
}

interface PageTemplates {
    home: PageTemplate;
    upgrade: PageTemplate;
}

interface PageTemplate {
    templateId: string;
    widgets: PageTemplateWidget[];
}

// ########## Analytics ##########

interface AnalyticsOptions {
    ga: GoogleAnalytics;
    oa: AdobeAnalytics;
}

interface GoogleAnalytics {
    clientWebPropertyId: string;
    tagManagerId: string;
    webPropertyId: string;
}

interface AdobeAnalytics {
    account: string;
    scriptUrl: string;
    trackingServer: string;
}

// ########## Billing ##########

interface BillingOptions {
    ccDescriptorName: string;
    invoiceBillingContact: InvoiceBillingContact;
    kountScriptUrl: string;
    secure3D: boolean;
}

interface InvoiceBillingContact {
    billingAddress: string;
    billingCity: string;
    billingCountry: number;
    billingStateProvince: string;
    billingPostalCode: string;
    companyName: string;
    contactName: string;
    email: string;
}

// ########## Locales ##########

interface LocaleOptions {
    cardTypes: string[];
    currencies: string[];
    isoCurrencies: IsoCurrency[];
    languages: string[];
}

export interface IsoCurrency {
    code: string;
    isoCode: string;
    minorUnits: number;
}

// ########## Modules ##########

interface ModuleOptions {
    account: AccountOptions;
    hotels: HotelsOptions;
    flights: BaseModuleOptions;
    cars: BaseModuleOptions;
    weeks: WeeksOptions;
    cruises: CruisesOptions;
    activities: BaseModuleOptions;
    ecards: BaseModuleOptions;
    rewards: RewardsOptions;
    escapes: BaseModuleOptions;
}

interface AccountOptions {
    allowSelfPasswordChange: boolean;
    allowSelfContactInfoChange: boolean;
    editProfileLink: string;
    membershipShowEdit: boolean;
    membershipShowMemberInfo: boolean;
    membershipShowMemberSummary: boolean;
    membershipShowPointsSummary: boolean;
    settingsVisible: boolean;
    showBillingInfo: boolean;
    showMemberAccount: boolean;
    showMembershipSummary: boolean;
    travelerProfilesActive: boolean;
    travelerProfilesEditable: boolean;
    restrictTraveler: boolean;
    guaranteedPriceReservations: boolean;
}

interface BaseModuleOptions {
    interstitialDelay: number;
    interstitialTexts: string[];
    minDaysToStart: number;
    showRetailPrice: boolean;
    showTaxInSearch: boolean;
    redeemSliderInd: boolean;
    restrictTraveler: boolean;
}

interface HotelsOptions extends BaseModuleOptions {
    displayHotelCompareLink: boolean;
    interstitialDelayRooms: number;
    interstitialTextsRooms: string[];
    minLOS: number;
    showTaxBreakdown: boolean;
    distanceUnit: string;
}

// interface FlightsOptions extends BaseModuleOptions {}

// interface CarsOptions extends BaseModuleOptions {}

interface WeeksOptions extends BaseModuleOptions {
    destinationSelVer: string;
    interstitialDelayRooms: number;
    interstitialTextsRooms: string[];
    distanceUnit: string;
}

interface CruisesOptions extends BaseModuleOptions {
    cruiseInfoForm: boolean;
    cruisePhone: string;
}

// interface ActivitiesOptions extends BaseModuleOptions {}

// interface CardsOptions {}

interface RewardsOptions extends BaseModuleOptions {
    allowRewardsCode: boolean;
}

// ########## Site ##########

interface SiteOptions {
    copyright: string;
    designOptions: DesignOptions;
    siteName: string;
    isHomeEnabled: boolean;
    moduleModalsForGuests: boolean;
    helpPhones: PhoneDetails[];
}

interface PhoneDetails {
    label: Record<string, string>;
    phoneNumber: string;
}

interface DesignOptions {
    footer: FooterOptions;
    header: HeaderOptions;
    userMenu: UserMenuOptions;
}

export interface FooterOptions {
    hideModules: boolean;
    variant: string; // default, spaceBetween
    accreditations: Accreditation[];
    links: MenuLink[];
    linksColumn_1: LinksColumn;
    linksColumn_2: LinksColumn;
    subfooterLinks: MenuLink[];
    logoUrl: string;
}

interface LinksColumn {
    title: string;
    links: MenuLink[];
}

interface Accreditation {
    name: string;
    imageUrl: string;
    url: string;
}

interface HeaderOptions {
    showLanguages: boolean;
    showCurrencies: boolean;
    variant: string; // default, occupySpace
    topMenu: MenuLink[];
    logoUrl: string;
}

interface UserMenuOptions {
    includeModules: boolean;
    groups: MenuGroup[];
    variant: string; // default, noAvatar
}

interface MenuGroup {
    name: string;
    links: MenuLink[];
    active?: boolean;
}

export interface MenuLink {
    name: string;
    customUrl?: string;
    target?: string;
    cssClass?: string;
    module?: string;
}

interface PointsOptions {
    pointsName: string;
    rewardsActive: boolean;
    showPoints: boolean;
}

interface UserOptions {
    loginRedirect: string;
    loginRequired: boolean;
    selfSignup: boolean;
}

// ########## Social ##########

interface SocialElement {
    target: string;
    url: string;
}
