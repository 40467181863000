export class RequestReservationRequest {
    offerId: number;
    startDate: string;
    endDate: string;
    roomType: string;
    adults: number;
    children: number;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;

    start?: string;
    end?: string;
}
