import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PageContent } from '@core/models';
import { PagesService } from '@core/services';
import { DataTemplate } from '@shared/models';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-page',
    templateUrl: './page.component.html',
    styleUrls: ['./page.component.scss'],
})
export class PageComponent implements OnInit {
    loading = true;
    content: string;

    private unsubscribe: Subject<void> = new Subject();

    constructor(private route: ActivatedRoute, private pagesService: PagesService) {}

    ngOnInit(): void {
        this.pagesService
            .getPage(this.route.snapshot.params['code'])
            .pipe(
                takeUntil(this.unsubscribe),
                finalize(() => (this.loading = false))
            )
            .subscribe((response: DataTemplate<PageContent>) => {
                this.content = response.data.content;
            });
    }
}
