import { Entity, Of } from '@utils';

@Entity
export class PasswordUpdateRequest {
    @Of(() => String)
    oldPassword: string;

    @Of(() => String)
    newPassword: string;

    static of = Entity.of<PasswordUpdateRequest>();
}
