<button
    [attr.aria-label]="
        'Travelers: ' +
        adultsCounter +
        ' adult' +
        (adultsCounter > 1 ? 's' : '') +
        ', ' +
        childrenCounter +
        (childrenCounter === 1 ? ' child' : ' children')
    "
    [attr.aria-expanded]="showCounters"
    role="group"
    class="ada-button width-100 height-100 padding-0"
    (click)="showCounters = true"
    (clickOutside)="updateGuests()"
>
    <div
        class="flex-container counters-wrapper height-100 position-relative"
        [class.cursor-pointer]="!showCounters"
        [class.fixed]="isFixed"
        [class.active]="isActive"
        [class.counters-active]="showCounters"
        [class.hotel-details-page]="hotelDetailsPage"
    >
        <div class="global-wrapper flex-container width-100" [class.overlay-active]="showCounters">
            <div class="adults-select-wrapper widget-element">
                <div
                    class="position-relative height-100 flex-container align-items-center justify-content-space-between"
                >
                    <div class="adults-value-wrapper flex-inline-container align-items-center width-100">
                        <mat-icon [svgIcon]="'bed2'" class="widget-icon bed-icon"></mat-icon>

                        <span
                            >{{ adultsCounter }}
                            <span class="adults-label text-lowercase">
                                <span
                                    [class.hide]="adultsCounter !== 1"
                                    [attr.data-i18n-code]="'core.labels.adult'"
                                ></span>
                                <span
                                    [class.hide]="adultsCounter === 1"
                                    [attr.data-i18n-code]="'core.labels.adults'"
                                ></span>
                            </span>
                        </span>

                        <span appTranslate *ngIf="childrenCounter > 0"
                            >, {{ childrenCounter }}
                            <span class="children-label text-lowercase">
                                <span
                                    [class.hide]="childrenCounter !== 1"
                                    [attr.data-i18n-code]="'core.labels.child'"
                                ></span>
                                <span
                                    [class.hide]="childrenCounter === 1"
                                    [attr.data-i18n-code]="'core.labels.children'"
                                ></span>
                            </span>
                        </span>

                        <span
                            >, {{ rooms?.length }}
                            <span class="children-label text-lowercase">
                                <span
                                    [class.hide]="rooms?.length !== 1"
                                    [attr.data-i18n-code]="'core.labels.room'"
                                ></span>
                                <span
                                    [class.hide]="rooms?.length === 1"
                                    [attr.data-i18n-code]="'core.labels.rooms'"
                                ></span>
                            </span>
                        </span>
                    </div>
                </div>
            </div>

            <div id="guests-counter-children-ages" class="rooms-wrapper widget-overlay-element" *ngIf="showCounters">
                <ng-container *ngFor="let room of rooms; let i = index">
                    <div appTranslate class="room-wrapper" [class.margin-bottom-20]="i < rooms.length - 1">
                        <div class="flex-container justify-content-space-between align-items-center margin-bottom-15">
                            <p class="room-title margin-0">Room {{ i + 1 }}</p>

                            <button class="ada-button room-remove" *ngIf="rooms.length > 1" (click)="removeRoom(i)">
                                remove room
                            </button>
                        </div>

                        <div class="flex-container justify-content-space-between align-items-center">
                            <p class="room-guests-label margin-0" [attr.data-i18n-code]="'core.labels.adults'"></p>

                            <div class="flex-container align-items-center">
                                <button
                                    aria-label="Decrement adults"
                                    class="ada-button control-icon"
                                    (click)="updateAdults(-1, i)"
                                    *ngIf="showCounters"
                                    [disabled]="room.adults === 1"
                                >
                                    <mat-icon
                                        [svgIcon]="'subtract'"
                                        class="control-icon minus-icon"
                                        [class.disabled]="room.adults === 1"
                                    ></mat-icon>
                                </button>

                                <span class="counter-text margin-left-10 margin-right-10">{{ room.adults }} </span>

                                <button
                                    aria-label="Increment adults"
                                    id="increment-adults"
                                    class="ada-button control-icon"
                                    (click)="updateAdults(1, i)"
                                    *ngIf="showCounters"
                                    [disabled]="checkMaxAdults(i) || checkMaxOccupancy(i)"
                                >
                                    <mat-icon
                                        [svgIcon]="'add'"
                                        class="control-icon"
                                        [class.disabled]="checkMaxAdults(i) || checkMaxOccupancy(i)"
                                    ></mat-icon>
                                </button>
                            </div>
                        </div>

                        <div class="flex-container justify-content-space-between align-items-center margin-bottom-10">
                            <p class="room-guests-label margin-0" [attr.data-i18n-code]="'core.labels.children'"></p>

                            <div class="flex-container align-items-center">
                                <button
                                    aria-label="Decrement children"
                                    class="ada-button control-icon"
                                    (click)="updateChildren(-1, i)"
                                    *ngIf="showCounters"
                                    [disabled]="room.children === 0"
                                >
                                    <mat-icon
                                        [svgIcon]="'subtract'"
                                        class="control-icon minus-icon"
                                        [class.disabled]="room.children === 0"
                                    ></mat-icon>
                                </button>

                                <span class="counter-text margin-left-10 margin-right-10">{{ room.children }} </span>

                                <button
                                    aria-label="Increment children"
                                    id="increment-children"
                                    class="ada-button control-icon"
                                    (click)="updateChildren(1, i)"
                                    *ngIf="showCounters"
                                    [disabled]="checkMaxChildren(i) || checkMaxOccupancy(i)"
                                >
                                    <mat-icon
                                        [svgIcon]="'add'"
                                        class="control-icon margin-right-20 margin-lg-right-10"
                                        [class.disabled]="checkMaxChildren(i) || checkMaxOccupancy(i)"
                                    ></mat-icon>
                                </button>
                            </div>
                        </div>

                        <div
                            id="guests-counter-children-ages"
                            class="children-ages-wrapper"
                            *ngIf="showCounters && room.children > 0"
                        >
                            <ng-container *ngFor="let age of room.childrenAges; let i = index">
                                <mat-form-field appearance="outline" class="child-age-select no-padding">
                                    <mat-label appTranslate>
                                        <span [attr.data-i18n-code]="'core.labels.age'"></span>
                                    </mat-label>
                                    <mat-select
                                        [(ngModel)]="room.childrenAges[i]"
                                        (openedChange)="addListeners()"
                                        (selectionChange)="removeListeners()"
                                    >
                                        <ng-container *ngFor="let value of ageValues">
                                            <mat-option [attr.aria-label]="value + ' years old'" [value]="value">{{
                                                value
                                            }}</mat-option>
                                        </ng-container>
                                    </mat-select>
                                </mat-form-field>
                            </ng-container>
                        </div>

                        <mat-divider *ngIf="i < rooms.length - 1"></mat-divider>
                    </div>
                </ng-container>

                <ng-container *ngIf="rooms?.length < 4">
                    <mat-divider></mat-divider>
                    <button class="ada-button" (click)="addRoom()">
                        <div
                            class="flex-container align-items-center justify-content-center margin-top-10 margin-bottom-5"
                        >
                            <mat-icon [svgIcon]="'add'" class="add-another-room-icon margin-right-10"></mat-icon>
                            <p class="margin-0">Add another room</p>
                        </div>
                    </button>
                </ng-container>
            </div>
        </div>
    </div>
</button>
