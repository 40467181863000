import { AdobeAnalyticsDetails, AssetValue, GoogleAnalyticsDetails } from '@shared/models';

export class Cruise {
    constructor(
        public index: number,
        public id: string,
        public image: string,
        public companyLogo: string,
        public lineId: string,
        public lineName: string,
        public destination: string,
        public name: string,
        public rating: number,
        public noOfNights: number,
        public shipName: string,
        public portsOfCall: string[],
        public sails: SailDate[],
        public ga: GoogleAnalyticsDetails,
        public oa: AdobeAnalyticsDetails,
        public goodStars?: number[],
        public badStars?: number[],
        public earliestDepartue?: number,
        public youPay?: AssetValue
    ) {}
}

export class SailDate {
    constructor(
        public id: string,
        public sailDate: string,
        public balconyPrice: SailPrice,
        public insidePrice: SailPrice,
        public outsidePrice: SailPrice,
        public suitePrice: SailPrice
    ) {}
}

export class SailPrice {
    constructor(public id: string, public price: AssetValue) {}
}
