import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BaseWidgetDetails, ModuleWidgetDetails, PageTemplateWidget, SiteBranding } from '@core/models';
import {
    ActivitiesDialogComponent,
    CarsDialogComponent,
    ConciergeDialogComponent,
    CruisesDialogComponent,
    FlightsDialogComponent,
    HotelsDialogComponent,
    LastMinuteBookingDialogComponent,
    RewardsDialogComponent,
    ShoppingDialogComponent,
    WeeksDialogComponent,
} from '@shared/components';
import { SharedStorageKeys } from '@shared/models';
import { HeaderService, UrlService } from '@shared/services';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
    sharedStorageKeys = SharedStorageKeys;

    siteBranding: SiteBranding;

    widgets: PageTemplateWidget[] = [];

    private unsubscribe: Subject<void> = new Subject();

    constructor(
        private url: UrlService,
        private router: Router,
        private headerService: HeaderService,
        private dialog: MatDialog
    ) {}

    ngOnInit(): void {
        this.headerService.fixed.emit(false);
        sessionStorage.setItem(this.sharedStorageKeys.HEADER_FIXED, '0');
        this.siteBranding = JSON.parse(localStorage.getItem(this.sharedStorageKeys.SITE_BRANDING));

        if (!this.siteBranding?.uiConfig.siteOptions.isHomeEnabled) {
            this.router.navigateByUrl(this.url.getUrl('login'));
        }

        this.widgets = this.siteBranding.templates.home.widgets.sort((a, b) => a.order - b.order);
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    getBaseWidget(widget: PageTemplateWidget): BaseWidgetDetails {
        return widget as BaseWidgetDetails;
    }

    getModuleWidget(widget: PageTemplateWidget): ModuleWidgetDetails {
        return widget as ModuleWidgetDetails;
    }

    openModal(module: string): void {
        let dialog = null;

        switch (module) {
            case 'hotels':
                dialog = HotelsDialogComponent;
                break;
            case 'weeks':
                dialog = WeeksDialogComponent;
                break;
            case 'flights':
                dialog = FlightsDialogComponent;
                break;
            case 'cars':
                dialog = CarsDialogComponent;
                break;
            case 'cruises':
                dialog = CruisesDialogComponent;
                break;
            case 'activities':
                dialog = ActivitiesDialogComponent;
                break;
            case 'rewards':
                dialog = RewardsDialogComponent;
                break;
            case 'shopping':
                dialog = ShoppingDialogComponent;
                break;
            case 'concierge':
                dialog = ConciergeDialogComponent;
                break;
            case 'last-minute-booking':
                dialog = LastMinuteBookingDialogComponent;
                break;
        }

        if (dialog) {
            this.dialog.open(dialog, {
                width: '100%',
                maxWidth: '640px',
            });
        }
    }
}
