import { AdobeAnalyticsDetails, AssetValue, GoogleAnalyticsDetails } from '@shared/models';

export class Activity {
    id: string;
    imageUrl: string;
    name: string;
    location: string;
    duration: string;
    rating: number;
    shortDescription: string;
    youPay: AssetValue;
    ga: GoogleAnalyticsDetails;
    oa: AdobeAnalyticsDetails;
    categoryIds: number[];
    categories: string;

    index: number;
    goodStars: number[];
    badStars: number[];
}
