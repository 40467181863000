<button
    [attr.aria-label]="
        'Travelers: ' +
        adultsCounter +
        ' adult' +
        (adultsCounter > 1 ? 's' : '') +
        ', ' +
        childrenCounter +
        (childrenCounter === 1 ? ' child' : ' children')
    "
    [attr.aria-expanded]="showCounters"
    role="group"
    class="ada-button width-100 height-100 padding-0"
    (click)="showCounters = true"
    (clickOutside)="updateGuests()"
>
    <div
        class="flex-container counters-wrapper height-100 position-relative"
        [class.cursor-pointer]="!showCounters"
        [class.fixed]="isFixed"
        [class.active]="isActive"
        [class.counters-active]="showCounters && childrenAges?.length"
        [class.hotel-details-page]="hotelDetailsPage"
        [class.activity-details-page]="activityDetailsPage"
    >
        <div class="global-wrapper flex-container width-100" [class.overlay-active]="showCounters">
            <div class="adults-select-wrapper widget-element">
                <div
                    class="position-relative height-100 flex-container align-items-center justify-content-space-between"
                >
                    <div
                        class="adults-value-wrapper flex-inline-container width-100"
                        [class.justify-content-space-between]="showCounters"
                    >
                        <mat-icon [svgIcon]="'adult'" class="widget-icon" *ngIf="!showCounters"></mat-icon>

                        <button
                            aria-label="Decrement adults"
                            class="ada-button control-icon"
                            (click)="updateAdults(-1)"
                            *ngIf="showCounters"
                            [disabled]="adultsCounter === 1"
                        >
                            <mat-icon
                                [svgIcon]="'subtract'"
                                class="control-icon"
                                [class.disabled]="adultsCounter === 1"
                            ></mat-icon>
                        </button>

                        <span
                            >{{ adultsCounter }}
                            <span class="adults-label text-lowercase" [class.hide-below-xs]="!showCounters">
                                <span
                                    [class.hide]="adultsCounter !== 1"
                                    [attr.data-i18n-code]="'core.labels.adult'"
                                ></span>
                                <span
                                    [class.hide]="adultsCounter === 1"
                                    [attr.data-i18n-code]="'core.labels.adults'"
                                ></span>
                            </span>
                        </span>

                        <button
                            aria-label="Increment adults"
                            id="increment-adults"
                            class="ada-button control-icon margin-right-20 margin-lg-right-10"
                            (click)="updateAdults(1)"
                            *ngIf="showCounters"
                            [disabled]="disableAdultsIncrement"
                        >
                            <mat-icon
                                [svgIcon]="'add'"
                                class="control-icon"
                                [class.disabled]="disableAdultsIncrement"
                                [class.cursor-pointer]="showCounters"
                            ></mat-icon>
                        </button>
                    </div>
                    <mat-divider role="" [vertical]="true" class="widget-element-separator"></mat-divider>
                </div>
            </div>

            <div class="children-select-wrapper widget-element position-relative">
                <div class="position-relative height-100 flex-container align-items-center">
                    <div
                        class="children-value-wrapper flex-inline-container width-100"
                        [class.justify-content-space-between]="showCounters"
                    >
                        <mat-icon
                            [svgIcon]="'children'"
                            class="widget-icon children-icon"
                            *ngIf="!showCounters"
                        ></mat-icon>

                        <button
                            aria-label="Decrement children"
                            class="ada-button control-icon"
                            (click)="updateChildren(-1)"
                            *ngIf="showCounters"
                            [disabled]="childrenCounter === 0"
                        >
                            <mat-icon
                                [svgIcon]="'subtract'"
                                class="control-icon"
                                [class.disabled]="childrenCounter === 0"
                            ></mat-icon>
                        </button>

                        <span
                            >{{ childrenCounter }}
                            <span class="children-label text-lowercase" [class.hide-below-xs]="!showCounters">
                                <span
                                    [class.hide]="childrenCounter !== 1"
                                    [attr.data-i18n-code]="'core.labels.child'"
                                ></span>
                                <span
                                    [class.hide]="childrenCounter === 1"
                                    [attr.data-i18n-code]="'core.labels.children'"
                                ></span>
                            </span>
                        </span>

                        <button
                            aria-label="Increment children"
                            id="increment-children"
                            class="ada-button control-icon"
                            (click)="updateChildren(1)"
                            *ngIf="showCounters"
                            [disabled]="disableChildrenIncrement"
                        >
                            <mat-icon
                                [svgIcon]="'add'"
                                class="control-icon margin-right-20 margin-lg-right-10"
                                [class.disabled]="disableChildrenIncrement"
                                [class.cursor-pointer]="showCounters"
                            ></mat-icon>
                        </button>
                    </div>
                </div>

                <div
                    id="guests-counter-children-ages"
                    class="children-ages-wrapper widget-overlay-element"
                    *ngIf="showCounters && childrenAges?.length"
                >
                    <ng-container *ngFor="let age of childrenAges; let i = index">
                        <mat-form-field appearance="outline" class="no-padding">
                            <mat-label appTranslate>
                                <span [attr.data-i18n-code]="'core.labels.age'"></span>
                            </mat-label>
                            <mat-select
                                [(ngModel)]="age.value"
                                (openedChange)="addListeners()"
                                (selectionChange)="removeListeners()"
                            >
                                <ng-container *ngIf="isFlights">
                                    <mat-option appTranslate [value]="'INF'"
                                        ><span [attr.data-i18n-code]="'flight.widgets.search.infantWithSeat'"></span
                                    ></mat-option>
                                    <mat-option appTranslate [value]="'INS'"
                                        ><span [attr.data-i18n-code]="'flight.widgets.search.infantWithoutSeat'"></span
                                    ></mat-option>
                                    <ng-container *ngFor="let value of ageValues">
                                        <mat-option
                                            [attr.aria-label]="value + ' years old'"
                                            [value]="'C' + (value < 10 ? '0' : '') + value"
                                            >{{ value }}</mat-option
                                        >
                                    </ng-container>
                                </ng-container>

                                <ng-container *ngIf="!isFlights">
                                    <ng-container *ngFor="let value of ageValues">
                                        <mat-option [attr.aria-label]="value + ' years old'" [value]="value">{{
                                            value
                                        }}</mat-option>
                                    </ng-container>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</button>
