<mat-icon [svgIcon]="'hotel'" class="module-icon"></mat-icon>
<div>
    <p class="primary-data margin-bottom-5">
        <span *ngIf="searchCriteria.type === 'city'">
            <span [attr.data-i18n-code]="'hotels.landing.recent_search_city.label'"></span>{{ ' ' }}
        </span>
        <span>{{ searchCriteria.locName }}</span>
        <span *ngIf="searchCriteria.type === 'hotel'">
            {{ ' ' }}<span [attr.data-i18n-code]="'hotels.landing.recent_search_hotel.label'"></span>
        </span>
    </p>

    <p class="secondary-data margin-bottom-5">
        {{ search.checkIn | date: 'EE, dd MMM' }} - {{ search.checkOut | date: 'EE, dd MMM' }}
    </p>

    <div class="secondary-data flex-container align-items-center">
        <span
            >{{ rooms?.length }}
            <span class="text-lowercase">
                <span [class.hide]="rooms?.length !== 1" [attr.data-i18n-code]="'core.labels.room'"></span>
                <span [class.hide]="rooms?.length === 1" [attr.data-i18n-code]="'core.labels.rooms'"></span>
            </span>
        </span>
        <span class="bullet margin-left-5 margin-right-5"></span>
        <span
            >{{ adults }}
            <span class="text-lowercase">
                <span [class.hide]="adults !== 1" [attr.data-i18n-code]="'core.labels.adult'"></span>
                <span [class.hide]="adults === 1" [attr.data-i18n-code]="'core.labels.adults'"></span>
            </span>
        </span>

        <span appTranslate *ngIf="children > 0"
            >, {{ children }}
            <span class="text-lowercase">
                <span [class.hide]="children !== 1" [attr.data-i18n-code]="'core.labels.child'"></span>
                <span [class.hide]="children === 1" [attr.data-i18n-code]="'core.labels.children'"></span>
            </span>
        </span>
    </div>
</div>
