<div
    class="flex-container justify-content-space-between"
    *ngIf="(bookPrice?.earns?.value || bookPrice?.earns?.fmtValue) && siteBranding?.uiConfig?.pointsOptions?.showPoints"
>
    <span
        class="price-label points-value"
        [attr.data-i18n-code]="'core.labels.pointsEarned'"
        [attr.data-i18n-params]="siteBranding?.uiConfig?.pointsOptions?.pointsName"
        [attr.data-i18n-persist]="true"
    ></span>

    <div class="text-right">
        <span class="price-value points-value">{{ bookPrice?.earns?.value || bookPrice?.earns?.fmtValue }}</span>
    </div>
</div>

<div
    appTranslate
    class="earn-delay"
    *ngIf="
        siteBranding?.uiConfig?.pointsOptions?.showPoints &&
        (bookPrice?.earns?.value || bookPrice?.earns?.fmtValue) &&
        bookPrice?.earnDelayDate
    "
>
    *<span [attr.data-i18n-code]="'core.labels.after'"></span>
    {{ bookPrice?.earnDelayDate | date: 'longDate' }}
</div>
