import { Component, Input, OnInit } from '@angular/core';
import { SiteBranding } from '@core/models';
import { AssetValue, BookPrice, SharedStorageKeys } from '@shared/models';
import { Modules } from '@utils';

@Component({
    selector: 'app-cars-prices',
    templateUrl: './cars-prices.component.html',
    styleUrls: ['./cars-prices.component.scss'],
})
export class CarsPricesComponent implements OnInit {
    @Input()
    bookPrice: BookPrice;

    @Input()
    loaded: boolean;

    @Input()
    showRetailPrice: boolean;

    @Input()
    useCredit: boolean;

    @Input()
    taxBreakdown: AssetValue[];

    @Input()
    isPostPaid: boolean;

    sharedStorageKeys = SharedStorageKeys;
    siteBranding: SiteBranding;
    modules = Modules;

    currentCurrency: string;

    ngOnInit(): void {
        this.siteBranding = JSON.parse(localStorage.getItem(this.sharedStorageKeys.SITE_BRANDING)) as SiteBranding;
        this.currentCurrency = sessionStorage.getItem(this.sharedStorageKeys.CURRENT_CURRENCY);
    }
}
