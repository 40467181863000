export class GenericSearchResponse<T> {
    items: T[];

    constructor(items: T[]) {
        this.items = items;
    }
}

export class GenericListItem {
    id: number;
    name: string;
}
