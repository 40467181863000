import { EventEmitter, Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MailingAddress } from '@cards/models';
import { TravelerProfile } from '@shared/models';

export interface MultipleTravelersData {
    index: number;
    traveler: TravelerProfile;
}

@Injectable({
    providedIn: 'root',
})
export class CheckDataService {
    multipleTravelers: EventEmitter<MultipleTravelersData> = new EventEmitter<MultipleTravelersData>();
    leadTraveler: EventEmitter<TravelerProfile | number | boolean> = new EventEmitter<
        // eslint-disable-next-line @typescript-eslint/indent
        TravelerProfile | number | boolean
    >();
    cardProfile: EventEmitter<UntypedFormGroup | number | boolean> = new EventEmitter<
        // eslint-disable-next-line @typescript-eslint/indent
        UntypedFormGroup | number | boolean
    >();
    memberInformation: EventEmitter<UntypedFormGroup | boolean> = new EventEmitter<UntypedFormGroup | boolean>();
    mailingAddress: EventEmitter<MailingAddress | boolean> = new EventEmitter<MailingAddress | boolean>();
    bookingQuestions: EventEmitter<Record<string, string> | boolean> = new EventEmitter<
        // eslint-disable-next-line @typescript-eslint/indent
        Record<string, string> | boolean
    >();
    checkData: EventEmitter<any> = new EventEmitter<any>();
}
