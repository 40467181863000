export const environment = {
    target: 'staging',
    baseEndpoint: 'https://apiv19.leisureloyalty.com',
    corePath: '/fapi/v19/core',
    accountPath: '/fapi/v19/account',
    offersPath: '/fapi/v19/offers',
    hotelsPath: '/fapi/v19/hotels',
    flightsPath: '/fapi/v19/flights',
    carsPath: '/fapi/v19/cars',
    weeksPath: '/fapi/v19/weeks',
    cruisesPath: '/fapi/v19/cruises',
    activitiesPath: '/fapi/v19/activities',
    cardsPath: '/fapi/v19/cards',
    rewardsPath: '/fapi/v19/rewards',
    escapesPath: '/fapi/v19/escapes',
    pagesPath: '/fapi/v19/pages',
    membershipPath: '/fapi/v19/membership',
    guestCredentials: {
        username: 'guest',
        password: 'guest',
        allowedUsernames: ['guest'],
    },
    accountNavigationItems: ['membership', 'trips', 'travelers', 'payments', 'purchases', 'settings'],
    segmentTypes: ['H', 'V', 'CR', 'AIR', 'ACT', 'TOUR', 'RES', 'OTH', 'SHOP', 'EC'],
    purchaseStatusCodes: ['A', 'C', 'P'],
    elavon3dsUrl: 'https://uat.gw.fraud.eu.elavonaws.com',
};
