<div appTranslate id="update-trip-dialog" class="padding-right-30 padding-bottom-20 padding-left-30">
    <h2 mat-dialog-title [attr.data-i18n-code]="'core.labels.currencyChangeDialog.title'"></h2>

    <div mat-dialog-content>
        <p *ngIf="!data.offerDisclaimer" [attr.data-i18n-code]="'core.labels.currencyChangeDialog.body'"></p>
        <p *ngIf="data.offerDisclaimer" [attr.data-i18n-code]="'core.labels.currencyChangeDialog.bodyOffer'"></p>
    </div>

    <div mat-dialog-actions class="flex-container justify-content-center">
        <div class="margin-right-20">
            <button mat-stroked-button [color]="'primary'" [mat-dialog-close]="false">
                <span class="text-uppercase" [attr.data-i18n-code]="'core.labels.cancel'"></span>
            </button>
        </div>

        <button mat-raised-button [color]="'primary'" [mat-dialog-close]="true">
            <span class="text-uppercase" [attr.data-i18n-code]="'core.labels.currencyChangeDialog.confirm'"></span>
        </button>
    </div>
</div>
