import { Component, Input, OnInit } from '@angular/core';
import { SiteBranding } from '@core/models';
import { BookPrice, SharedStorageKeys } from '@shared/models';

@Component({
    selector: 'app-points-earned',
    templateUrl: './points-earned.component.html',
    styleUrls: ['./points-earned.component.scss'],
})
export class PointsEarnedComponent implements OnInit {
    @Input()
    bookPrice: BookPrice;

    sharedStorageKeys = SharedStorageKeys;
    siteBranding: SiteBranding;

    ngOnInit(): void {
        this.siteBranding = JSON.parse(localStorage.getItem(this.sharedStorageKeys.SITE_BRANDING)) as SiteBranding;
    }
}
