import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
    ActivatedRoute,
    Event,
    NavigationEnd,
    RouteConfigLoadEnd,
    RouteConfigLoadStart,
    Router,
} from '@angular/router';
import { SiteBranding, TokenInfo, UserInfo } from '@core/models';
import {
    AuthService,
    GuestLoginService,
    LoaderService,
    TokenInteractionService,
    UserAuthService,
} from '@core/services';
import { environment } from '@env/environment';
import { DataTemplate, SharedStorageKeys } from '@shared/models';
import {
    AdobeAnalyticsEventsService,
    DynamicScriptLoaderService,
    HeaderService,
    I18nService,
    ScriptAttribute,
    SendQueuedAnalyticsEventsService,
    StorageService,
    ToastService,
    UrlService,
} from '@shared/services';
import { Theme } from '@theme/models';
import { ThemeService } from '@theme/services';
import { forkJoin, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

import { createEntityStore } from './utils/entity-mapper-core';

// eslint-disable-next-line init-declarations
declare let gtag: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    sharedStorageKeys = SharedStorageKeys;
    searchPage: boolean;
    searchBarBackColor: string;
    basicAuth: any;
    siteBranding: SiteBranding;
    pageTitle: string;
    pageUrl: string;
    initialSettings = true;
    currentUrl: string;

    adobeFirstPageName: string;
    adobeFirstPageUrl: string;

    isLoaded: boolean;
    onLoginRegister: boolean;
    brandingScriptsLoaded: boolean;
    guestLoginInProgress: boolean;

    @ViewChild('bodyContent') bodyContent: ElementRef;

    private unsubscribe: Subject<void> = new Subject<void>();

    constructor(
        private router: Router,
        private themeService: ThemeService,
        private authService: AuthService,
        private tokenService: TokenInteractionService,
        private userAuthService: UserAuthService,
        private i18nService: I18nService,
        private url: UrlService,
        private headerService: HeaderService,
        private dynamicScriptLoaderService: DynamicScriptLoaderService,
        private guestLoginService: GuestLoginService,
        private loader: LoaderService,
        private titleService: Title,
        private route: ActivatedRoute,
        private sendQueuedAnalyticsEventsService: SendQueuedAnalyticsEventsService,
        private adobeAnalytics: AdobeAnalyticsEventsService,
        private toast: ToastService,
        private storage: StorageService
    ) {}

    ngOnInit(): void {
        createEntityStore();

        (document.querySelector('#main') as HTMLElement).style.setProperty('--color-primary', '#8E8E8E');

        //TODO: Make this more fancy
        if (!this.brandingScriptsLoaded) {
            this.siteBranding = this.storage.getItem<SiteBranding>(this.sharedStorageKeys.SITE_BRANDING);

            if (this.siteBranding) {
                this.loadBrandingScripts(this.siteBranding);
            }
        }
        //TODO: Make this more fancy

        this.router.events.pipe(takeUntil(this.unsubscribe)).subscribe((event: Event) => {
            if (event instanceof RouteConfigLoadStart) {
                this.loader.show();
            } else if (event instanceof RouteConfigLoadEnd) {
                this.loader.hide();
            }

            if (event instanceof NavigationEnd) {
                if (
                    !sessionStorage.getItem(this.sharedStorageKeys.CURRENT_CURRENCY) &&
                    localStorage.getItem(this.sharedStorageKeys.USER_INFO)
                ) {
                    sessionStorage.setItem(
                        this.sharedStorageKeys.CURRENT_CURRENCY,
                        (JSON.parse(localStorage.getItem(this.sharedStorageKeys.USER_INFO)) as UserInfo)
                            .preferredCurrency
                    );
                }

                if (event.url != '') {
                    this.currentUrl = event.url;
                } else {
                    this.currentUrl = '';
                }

                let child = this.route.firstChild;

                while (child.firstChild) {
                    child = child.firstChild;
                }

                if (this.siteBranding?.uiConfig?.siteOptions?.siteName && child.snapshot.data['title']) {
                    this.titleService.setTitle(
                        `${this.siteBranding?.uiConfig?.siteOptions?.siteName} - ${child.snapshot.data['title']}`
                    );
                } else if (this.initialSettings) {
                    this.pageTitle = child.snapshot.data['title'];
                    this.pageUrl = event.urlAfterRedirects;
                    this.initialSettings = false;
                } else {
                    this.pageTitle = null;
                    this.pageUrl = null;
                }

                if (
                    this.siteBranding?.uiConfig?.analyticsOptions?.ga?.clientWebPropertyId &&
                    typeof gtag !== 'undefined'
                ) {
                    gtag('config', this.siteBranding?.uiConfig?.analyticsOptions?.ga?.clientWebPropertyId, {
                        page_path: event.urlAfterRedirects,
                    });
                }

                if (this.siteBranding?.uiConfig?.analyticsOptions?.ga?.webPropertyId && typeof gtag !== 'undefined') {
                    gtag('config', this.siteBranding?.uiConfig?.analyticsOptions?.ga?.webPropertyId, {
                        page_path: event.urlAfterRedirects,
                    });
                }

                this.adobeFirstPageName = child.snapshot.data['title'];
                this.adobeFirstPageUrl = event.urlAfterRedirects;
                if (this.siteBranding?.uiConfig?.analyticsOptions?.oa?.account && child.snapshot.data['title']) {
                    this.adobeAnalytics.sendPageView(event.urlAfterRedirects, {
                        events: 'event20',
                        linkTrackVars: 'events,eVar44',
                        linkTrackEvents: 'event20',
                        pageName: child.snapshot.data['title'],
                        eVar44: child.snapshot.data['title'],
                    });
                }

                if (
                    event.urlAfterRedirects.includes(this.url.getUrl('login')) ||
                    event.urlAfterRedirects.includes('assist') ||
                    event.urlAfterRedirects.includes('sso')
                ) {
                    this.onLoginRegister = true;
                } else {
                    this.onLoginRegister = false;
                }

                sessionStorage.removeItem(this.sharedStorageKeys.HEADER_FIXED);
                sessionStorage.removeItem(this.sharedStorageKeys.HEADER_TARGET);
                sessionStorage.removeItem(this.sharedStorageKeys.PREVENT_GUEST_LOGIN);

                this.switchHeaderTarget(event.urlAfterRedirects);

                this.basicAuth = JSON.parse(localStorage.getItem(this.sharedStorageKeys.BASIC_AUTH));

                if (!this.basicAuth) {
                    this.getBasicAuth();
                } else {
                    if (
                        (!localStorage.getItem(this.sharedStorageKeys.USER_ID) && !this.guestLoginInProgress) ||
                        !this.tokenService.isValidToken()
                    ) {
                        this.guestLogin();
                    } else {
                        if (!event.url.includes('assist') && !event.url.includes('sso')) {
                            if (!this.authService.isGuest()) {
                                this.authService.login();
                            }

                            this.checkTheme();
                        } else {
                            this.guestLogin();
                        }
                    }
                }
            }
        });

        this.guestLoginService.triggerGuestLogin.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
            if (!this.guestLoginInProgress) {
                this.guestLogin(true);
            }
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    loadBrandingScripts(siteBranding: SiteBranding): void {
        const headScripts = siteBranding?.uiAssets?.head?.match(/\bhttps?:\/\/\S+/gi);
        const headData: ScriptAttribute[] = siteBranding?.uiAssets?.head?.match(/\bdata\S+/gi)?.map((d) => {
            return {
                name: d.split('=')[0],
                value: d.split('=')[1].replaceAll('"', ''),
            };
        });

        headScripts?.forEach((s: string, index: number) => {
            this.dynamicScriptLoaderService.prepareScript({
                name: `headScript_${index}`,
                src: `${s.split('.js')?.[0]}.js`,
                charset: s.includes('otSDKStub.js') ? 'UTF-8' : null,
                data: s.includes('otSDKStub.js') ? headData : null,
            });

            this.dynamicScriptLoaderService
                .loadScript(`headScript_${index}`)
                .catch((error: string) => console.error(error));
        });

        const bodyScripts = siteBranding?.uiAssets?.bodyEndScript?.match(
            /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi
        );

        bodyScripts?.forEach((s: string, index: number) => {
            const regex = /(\w+)\s*=\s*["']([^"']*)["']/g;
            const attributes: ScriptAttribute[] = [];
            let src = '';
            let match: string[] = [];

            while ((match = regex.exec(s))) {
                if (match[1] !== 'src') {
                    attributes.push({ name: match[1], value: match[2] });
                } else {
                    src = match[2];
                }
            }

            this.dynamicScriptLoaderService.prepareScript({
                name: `bodyScript_${index}`,
                src,
                location: 'body',
                data: attributes,
            });

            this.dynamicScriptLoaderService
                .loadScript(`bodyScript_${index}`)
                .catch((error: string) => console.error(error));
        });

        this.brandingScriptsLoaded = true;
    }

    getBasicAuth(): void {
        this.userAuthService
            .getSiteBasic()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(
                (basicResponse: any) => {
                    // TODO: Show error message if basic init is empty or null/undefined
                    this.basicAuth = basicResponse;
                    localStorage.setItem(this.sharedStorageKeys.BASIC_AUTH, JSON.stringify(basicResponse));

                    this.guestLogin();
                },
                (error: string) => {
                    console.error(error);
                }
            );
    }

    guestLogin(navigateToLogin = false): void {
        this.guestLoginInProgress = true;

        if (!sessionStorage.getItem(this.sharedStorageKeys.PREVENT_GUEST_LOGIN)) {
            this.themeService.unloadTheme();

            if (!this.siteBranding) {
                this.siteBranding = this.storage.getItem<SiteBranding>(this.sharedStorageKeys.SITE_BRANDING);
            }

            if (this.siteBranding) {
                this.siteBranding?.uiConfig.localeOptions.languages.forEach((language: string) => {
                    this.storage.removeItem(`${language}_${this.sharedStorageKeys.I18N_KEYS}`);
                });
            }

            this.storage.removeItem(this.sharedStorageKeys.SITE_BRANDING);
            this.storage.removeItem(this.sharedStorageKeys.GENERAL_KEYS_LOADED);

            if (!this.basicAuth) {
                this.basicAuth = JSON.parse(localStorage.getItem(this.sharedStorageKeys.BASIC_AUTH));
            }

            this.loader.show();

            this.userAuthService
                .login(environment.guestCredentials.username, environment.guestCredentials.password)
                .pipe(takeUntil(this.unsubscribe))
                .subscribe(
                    (loginResponse: any) => {
                        this.authService.setUsername(environment.guestCredentials.username);
                        this.authService.setToken(
                            new TokenInfo(
                                environment.guestCredentials.username,
                                `${loginResponse.token_type} ${loginResponse.access_token}`,
                                loginResponse.expires_in
                            )
                        );

                        if (!this.themeService.isThemeLoaded()) {
                            forkJoin([this.userAuthService.getUserInfo(), this.userAuthService.getSiteBranding()])
                                .pipe(takeUntil(this.unsubscribe))
                                .subscribe(
                                    (response: [DataTemplate<UserInfo>, DataTemplate<SiteBranding>]) => {
                                        this.storage.setItem(this.sharedStorageKeys.SITE_BRANDING, response[1].data);
                                        this.setUserInfo(response[0].data);
                                        this.setTheme(response[1].data, navigateToLogin);
                                        this.guestLoginInProgress = false;
                                    },
                                    (error: string) => {
                                        console.error(error);
                                    }
                                );
                        } else {
                            this.userAuthService
                                .getUserInfo()
                                .pipe(
                                    takeUntil(this.unsubscribe),
                                    finalize(() => this.loader.hide())
                                )
                                .subscribe(
                                    (response: DataTemplate<UserInfo>) => {
                                        this.setUserInfo(response.data);
                                        this.guestLoginInProgress = false;

                                        if (navigateToLogin) {
                                            if (
                                                !sessionStorage.getItem(
                                                    this.sharedStorageKeys.PREVENT_SESSION_TIMEOUT_TOAST
                                                )
                                            ) {
                                                this.toast.show({
                                                    variant: 'info',
                                                    message: this.i18nService.getKeyValue('core.labels.sessionExpired'),
                                                });
                                            }

                                            sessionStorage.removeItem(
                                                this.sharedStorageKeys.PREVENT_SESSION_TIMEOUT_TOAST
                                            );

                                            const loginRedirect =
                                                localStorage.getItem(this.sharedStorageKeys.LOGIN_REDIRECT) ||
                                                this.siteBranding?.uiConfig?.userOptions?.loginRedirect;
                                            localStorage.removeItem(this.sharedStorageKeys.LOGIN_REDIRECT);

                                            if (this.siteBranding?.uiConfig?.siteOptions?.isHomeEnabled) {
                                                this.router.navigateByUrl(this.url.getUrl('home'));
                                            } else if (loginRedirect.startsWith('http')) {
                                                window.location.href = loginRedirect;
                                            } else {
                                                this.router.navigateByUrl(loginRedirect || this.url.getUrl('login'));
                                            }
                                        }
                                    },
                                    (error: string) => {
                                        console.error(error);
                                    }
                                );
                        }
                    },
                    (error: string) => {
                        console.error(error);
                    }
                );
        } else {
            sessionStorage.removeItem(this.sharedStorageKeys.PREVENT_GUEST_LOGIN);
        }
    }

    setUserInfo(userInfo: UserInfo): void {
        localStorage.setItem(this.sharedStorageKeys.USER_INFO, JSON.stringify(userInfo));

        if (!sessionStorage.getItem(this.sharedStorageKeys.CURRENT_LANGUAGE)) {
            sessionStorage.setItem(this.sharedStorageKeys.CURRENT_LANGUAGE, userInfo.preferredLanguage);
        }
    }

    checkTheme(siteBranding?: SiteBranding): void {
        if (!siteBranding) {
            if (!this.themeService.isThemeLoaded()) {
                this.userAuthService
                    .getSiteBranding()
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe(
                        (response: DataTemplate<SiteBranding>) => {
                            this.setTheme(response.data);
                        },
                        (error: string) => {
                            console.error(error);
                        }
                    );

                this.themeService.setTheme(new Theme('primary', {}));
            } else {
                this.isLoaded = true;
            }
        } else {
            this.setTheme(siteBranding);
        }
    }

    setTheme(siteBranding: SiteBranding, preloadLogin?: boolean): void {
        //TODO: Make this more fancy
        if ((siteBranding?.uiAssets?.head || siteBranding?.uiAssets?.bodyEndScript) && !this.brandingScriptsLoaded) {
            this.loadBrandingScripts(siteBranding);
        }
        //TODO: Make this more fancy

        this.siteBranding = siteBranding;

        if (this.pageTitle) {
            this.titleService.setTitle(`${this.siteBranding?.uiConfig?.siteOptions?.siteName} - ${this.pageTitle}`);
        }

        if (this.siteBranding?.uiAssets.faviconUrl) {
            document.querySelector('#favicon').setAttribute('href', this.siteBranding?.uiAssets.faviconUrl);
        }

        this.themeService.setTheme(new Theme('primary', JSON.parse(siteBranding?.uiAssets.cssVariables)));
        this.isLoaded = true;
        this.preloadI18nKeys(preloadLogin);
        this.loadKount();
        this.loadGoogleAnalytics();
        this.loadAdobeAnalytics();
    }

    preloadI18nKeys(preloadLogin: boolean): void {
        this.loader.show();
        this.i18nService
            .preloadBrandingKeys(this.siteBranding, preloadLogin)
            .pipe(
                takeUntil(this.unsubscribe),
                finalize(() => this.loader.hide(true))
            )
            .subscribe(
                () => {
                    if (preloadLogin) {
                        if (!sessionStorage.getItem(this.sharedStorageKeys.PREVENT_SESSION_TIMEOUT_TOAST)) {
                            this.toast.show({
                                variant: 'info',
                                message: this.i18nService.getKeyValue('core.labels.sessionExpired'),
                            });
                        }

                        sessionStorage.removeItem(this.sharedStorageKeys.PREVENT_SESSION_TIMEOUT_TOAST);

                        const loginRedirect =
                            localStorage.getItem(this.sharedStorageKeys.LOGIN_REDIRECT) ||
                            this.siteBranding?.uiConfig?.userOptions?.loginRedirect;
                        localStorage.removeItem(this.sharedStorageKeys.LOGIN_REDIRECT);

                        if (this.siteBranding?.uiConfig?.siteOptions?.isHomeEnabled) {
                            this.router.navigateByUrl(this.url.getUrl('home'));
                        } else if (loginRedirect?.startsWith('http')) {
                            window.location.href = loginRedirect;
                        } else {
                            this.router.navigateByUrl(loginRedirect || this.url.getUrl('login'));
                        }
                    }

                    this.isLoaded = true;
                },
                (error: string) => {
                    console.error(error);
                }
            );
    }

    loadKount(): void {
        this.dynamicScriptLoaderService.prepareScript({
            name: 'kount',
            src: this.siteBranding?.uiConfig?.billingOptions?.kountScriptUrl,
        });

        this.dynamicScriptLoaderService
            .loadScript('kount')
            .then(() => {
                this.dynamicScriptLoaderService
                    .loadScript('kountListen')
                    .catch((error: string) => console.error(error));
            })
            .catch((error: string) => console.error(error));
    }

    loadAdobeAnalytics(): void {
        if (
            this.siteBranding?.uiConfig?.analyticsOptions?.oa?.scriptUrl &&
            this.siteBranding?.uiConfig.analyticsOptions.oa?.account &&
            this.siteBranding?.uiConfig.analyticsOptions.oa?.trackingServer
        ) {
            this.dynamicScriptLoaderService.prepareScript({
                name: 'adobeAnalytics',
                src: this.siteBranding?.uiConfig.analyticsOptions.oa.scriptUrl,
            });

            sessionStorage.setItem('adobe_analytics_account', this.siteBranding?.uiConfig.analyticsOptions.oa.account);
            sessionStorage.setItem(
                'adobe_analytics_tracking_server',
                `${this.siteBranding?.uiConfig.analyticsOptions.oa.trackingServer}`
            );
            sessionStorage.setItem(
                'adobe_analytics_tracking_server_secure',
                `${this.siteBranding?.uiConfig.analyticsOptions.oa.trackingServer}`
            );

            this.dynamicScriptLoaderService
                .loadScript('adobeAnalyticsAccount')
                .then(() => {
                    this.dynamicScriptLoaderService
                        .loadScript('adobeAnalytics')
                        .then(() => {
                            this.dynamicScriptLoaderService
                                .loadScript('adobeAnalyticsTrackingServer')
                                .then(() => {
                                    this.adobeAnalytics.sendPageView(this.adobeFirstPageUrl, {
                                        events: 'event20',
                                        linkTrackVars: 'events,pageName,eVar44',
                                        linkTrackEvents: 'event20',
                                        pageName: this.adobeFirstPageName,
                                        eVar44: this.adobeFirstPageName,
                                    });

                                    this.sendQueuedAnalyticsEventsService.sendEvents.emit('adobe');
                                })
                                .catch((error: string) => console.error(error));
                        })
                        .catch((error: string) => console.error(error));
                })
                .catch((error: string) => console.error(error));
        }
    }

    loadGoogleAnalytics(): void {
        if (this.siteBranding?.uiConfig?.analyticsOptions?.ga?.webPropertyId) {
            this.loadLLAnalytics();
        } else if (this.siteBranding?.uiConfig?.analyticsOptions?.ga?.clientWebPropertyId) {
            this.loadClientAnalytics();
        }

        if (this.siteBranding?.uiConfig?.analyticsOptions?.ga?.tagManagerId) {
            sessionStorage.setItem(
                'google_tag_manager_id',
                this.siteBranding?.uiConfig.analyticsOptions.ga.tagManagerId
            );

            this.dynamicScriptLoaderService.loadScript('gtm').catch((error: string) => console.error(error));
        }
    }

    loadLLAnalytics(): void {
        this.dynamicScriptLoaderService.prepareScript({
            name: 'llGoogleAnalytics',
            src: `https://www.googletagmanager.com/gtag/js`,
        });

        this.dynamicScriptLoaderService
            .loadScript('llGoogleAnalytics')
            .then(() => {
                this.dynamicScriptLoaderService
                    .loadScript('ga')
                    .then(() => {
                        if (this.pageTitle) {
                            gtag('config', this.siteBranding?.uiConfig?.analyticsOptions?.ga?.webPropertyId, {
                                page_path: this.pageUrl,
                            });
                        }

                        if (this.siteBranding?.uiConfig?.analyticsOptions?.ga?.clientWebPropertyId) {
                            this.loadClientAnalytics();
                        } else {
                            this.sendQueuedAnalyticsEventsService.sendEvents.emit('google');
                        }
                    })
                    .catch((error: string) => console.error(error));
            })
            .catch((error: string) => console.error(error));
    }

    loadClientAnalytics(): void {
        this.dynamicScriptLoaderService.prepareScript({
            name: 'clientGoogleAnalytics',
            src: `https://www.googletagmanager.com/gtag/js`,
        });

        this.dynamicScriptLoaderService
            .loadScript('clientGoogleAnalytics')
            .then(() => {
                this.dynamicScriptLoaderService
                    .loadScript('ga')
                    .then(() => {
                        if (this.pageTitle) {
                            gtag('config', this.siteBranding?.uiConfig?.analyticsOptions?.ga?.clientWebPropertyId, {
                                page_path: this.pageUrl,
                            });
                        }

                        this.sendQueuedAnalyticsEventsService.sendEvents.emit('google');
                    })
                    .catch((error: string) => console.error(error));
            })
            .catch((error: string) => console.error(error));
    }

    switchHeaderTarget(url: string): void {
        if (this.url.isAccountUrl(url)) {
            this.computeHeader(url, 'account', true);
            return;
        }

        if (this.url.isHotelsUrl(url)) {
            this.computeHeader(url, 'hotels');
            return;
        }

        if (this.url.isFlightsUrl(url)) {
            this.computeHeader(url, 'flights');
            return;
        }

        if (this.url.isCarsUrl(url)) {
            this.computeHeader(url, 'cars');
            return;
        }

        if (this.url.isCruisesUrl(url)) {
            this.computeHeader(url, 'cruises');
            return;
        }

        if (this.url.isWeeksUrl(url)) {
            this.computeHeader(url, 'weeks');
            return;
        }

        if (this.url.isActivitiesUrl(url)) {
            this.computeHeader(url, 'activities');
            return;
        }

        if (this.url.isCardsUrl(url)) {
            this.computeHeader(url, 'ecards');
            return;
        }

        if (this.url.isRewardsUrl(url)) {
            this.computeHeader(url, 'rewards', true);
            return;
        }

        if (this.url.isEscapesUrl(url)) {
            this.computeHeader(url, 'escapes');
            return;
        }

        this.headerService.fixed.emit(true);
        sessionStorage.setItem(this.sharedStorageKeys.HEADER_FIXED, '1');
        this.headerService.target.emit(null);
        sessionStorage.setItem(this.sharedStorageKeys.HEADER_TARGET, '');
    }

    private computeHeader(url: string, target: string, isFixed = false): void {
        this.headerService.target.emit(target);
        sessionStorage.setItem(this.sharedStorageKeys.HEADER_TARGET, target);

        if (url?.split('#')?.[0] !== this.url.getUrl(target) || isFixed) {
            sessionStorage.setItem(this.sharedStorageKeys.HEADER_FIXED, '1');
            this.headerService.fixed.emit(true);
        } else {
            this.headerService.fixed.emit(false);
        }
    }
}
