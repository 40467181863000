import { PayPriceBreakdown } from '@shared/models';

import { UrgentInfoItem } from './week-details';

export class BookResponse {
    allInclusiveCode: string;
    allInclusiveInformation: string;
    checkIn: string;
    checkOut: string;
    confirmCode: string;
    guestFirstName: string;
    guestLastName: string;
    numAdults: number;
    numChildren: number;
    numNights: number;
    payCardName: string;
    payCardType: string;
    payCardNumber: string;
    payCardExpDate: string;
    payPointsEarned: string;
    payPriceBreakdown: PayPriceBreakdown[];
    payTotalAmount: string;
    refundable: boolean;
    refundableUntil: string;
    roomBedrooms: number;
    roomBathrooms: number;
    roomSleeps: number;
    roomKitchen: string;
    roomName: string;
    txGuid: string;
    urgentInfo: UrgentInfoItem[];
    weekAddress: string;
    weekCity: string;
    weekEmail: string;
    weekFullAddress: string;
    weekImage: string;
    weekName: string;
}
