import { CardProfile, Elavon3ds, TravelerProfile } from '@shared/models';
import { Entity, Of } from '@utils';

@Entity
export class BookRequest {
    @Of(() => Number, { nullable: true, optional: true })
    tripId?: number = null;

    @Of(() => Number, { nullable: true, optional: true })
    ppId?: number = null;

    @Of(() => CardProfile, { nullable: true, optional: true })
    card?: CardProfile = null;

    @Of(() => Number, { nullable: true, optional: true })
    payPoints?: number = null;

    @Of(() => Number, { nullable: true, optional: true })
    tpId?: number = null;

    @Of(() => TravelerProfile, { nullable: true, optional: true })
    traveler?: TravelerProfile = null;

    @Of(() => [TravelerProfile], { nullable: true, optional: true })
    travelers?: TravelerProfile[] = [];

    @Of(() => Boolean, { nullable: true, optional: true })
    saveTraveler = false;

    @Of(() => Boolean, { nullable: true, optional: true })
    updateTraveler = false;

    @Of(() => Boolean, { nullable: true, optional: true })
    saveCard = false;

    @Of(() => Boolean, { nullable: true, optional: true })
    useCredit = false;

    @Of(() => Elavon3ds, { nullable: true, optional: true })
    elavon3ds?: Elavon3ds = null;

    @Of(() => String)
    activityId: string;

    @Of(() => String)
    scheduleId: string;

    @Of(() => String, { nullable: true, optional: true })
    specialRequests: string;

    @Of(() => [BookingQuestionAnswer])
    bookingQuestions?: BookingQuestionAnswer[] = [];

    @Of(() => String, { nullable: true, optional: true })
    languageOptionCode: string = null;

    @Of(() => String, { nullable: true, optional: true })
    languageOptionName: string = null;

    @Of(() => Boolean)
    payByInvoice = false;

    @Of(() => String)
    hotelName = '';

    static of = Entity.of<BookRequest>();
}

@Entity
class BookingQuestionAnswer {
    @Of(() => String)
    question: string;

    @Of(() => String)
    answer: string;

    @Of(() => String, { nullable: true, optional: true })
    unit?: string;

    @Of(() => Number, { nullable: true, optional: true })
    travelerNum?: number;

    static of = Entity.of<BookingQuestionAnswer>();
}
