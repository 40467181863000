import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SharedStorageKeys } from '@shared/models';
import { UrlService } from '@shared/services';

@Component({
    selector: 'app-membership-upgrade',
    templateUrl: './upgrade.component.html',
    styleUrls: ['./upgrade.component.scss'],
})
export class UpgradeMembershipComponent {
    sharedStorageKeys = SharedStorageKeys;

    constructor(public url: UrlService, private router: Router) {}

    handleCheckout(tier: string): void {
        switch (tier) {
            case 'silver':
                sessionStorage.setItem(this.sharedStorageKeys.TIER_TO_UPGRADE, '9');
                break;
            case 'gold':
                sessionStorage.setItem(this.sharedStorageKeys.TIER_TO_UPGRADE, '2378');
                break;
        }
        this.router.navigate([this.url.getUrl('upgrade-booking')]);
    }

    navigateToUpgradeTable(): void {
        document.querySelector('#upgrade-table').scrollIntoView({ behavior: 'smooth' });
    }
}
