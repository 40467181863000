import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    ForgotPasswordRequest,
    ForgotUsernameRequest,
    MembershipType,
    RegisterRequest,
    RegisterResponse,
    ResetPasswordRequest,
    SiteBranding,
    UserInfo,
} from '@core/models';
import { environment } from '@env/environment';
import { DataTemplate, SharedStorageKeys } from '@shared/models';
import { StorageService, UserInfoUpdateService } from '@shared/services';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class UserAuthService {
    sharedStorageKeys = SharedStorageKeys;

    constructor(
        private http: HttpClient,
        private storage: StorageService,
        private userInfoUpdateService: UserInfoUpdateService
    ) {}

    getSiteBasic(): Observable<any> {
        const url = `${environment.baseEndpoint}/papi/v19/core/init`;

        return this.http.get(url, { withCredentials: environment.target !== 'dev' }) as Observable<any>;
    }

    getSiteBranding(): Observable<DataTemplate<SiteBranding>> {
        const url = `${environment.corePath}/brandingv2`;

        const siteBranding = this.storage.getItem<SiteBranding>(this.sharedStorageKeys.SITE_BRANDING);

        if (siteBranding) {
            return of({ data: siteBranding } as DataTemplate<SiteBranding>);
        } else {
            return this.http.get(url).pipe(
                tap((response: DataTemplate<SiteBranding>) => {
                    this.storage.setItem(this.sharedStorageKeys.SITE_BRANDING, response.data);
                }),
                map((data) => data as DataTemplate<SiteBranding>)
            ) as Observable<DataTemplate<SiteBranding>>;
        }
    }

    getUserInfo(overrideLanguage: string = null): Observable<DataTemplate<UserInfo>> {
        const url = `${environment.corePath}/users/details`;

        return this.http.get(url).pipe(
            tap((response: DataTemplate<UserInfo>) => {
                if (overrideLanguage) {
                    response.data.preferredLanguage = overrideLanguage;
                }

                sessionStorage.setItem(this.sharedStorageKeys.CURRENT_LANGUAGE, response.data.preferredLanguage);
                sessionStorage.setItem(this.sharedStorageKeys.CURRENT_CURRENCY, response.data.preferredCurrency);
            }),
            map((data) => data as DataTemplate<UserInfo>)
        ) as Observable<DataTemplate<UserInfo>>;
    }

    refreshUserInfo(): Observable<DataTemplate<UserInfo>> {
        const url = `${environment.corePath}/users/details`;

        return this.http.get(url).pipe(
            tap((response: DataTemplate<UserInfo>) => {
                localStorage.setItem(this.sharedStorageKeys.USER_INFO, JSON.stringify(response.data));
                this.userInfoUpdateService.update.emit();
            }),
            map((data) => data as DataTemplate<UserInfo>)
        ) as Observable<DataTemplate<UserInfo>>;
    }

    login(username: string, password: string, sso = false): Observable<any> {
        const basicAuth = JSON.parse(localStorage.getItem(this.sharedStorageKeys.BASIC_AUTH));
        const headerOptions = {
            Authorization: `Basic ${btoa(basicAuth.u + ':' + basicAuth.p)}`,
            'Content-Type': 'application/x-www-form-urlencoded',
        };

        let data = null;

        if (sso) {
            data = new HttpParams()
                .set('grant_type', 'jwt-bearer')
                .set('client_id', basicAuth.u)
                .set('assertion', username);
        } else {
            data = new HttpParams()
                .set('grant_type', 'password')
                .set('client_id', basicAuth.u)
                .set('username', username)
                .set('password', password);
        }

        const requestOptions = {
            headers: new HttpHeaders(headerOptions),
        };

        const url = `/oauth/token`;

        return this.http.post(url, data.toString(), requestOptions) as Observable<any>;
    }

    forgotUsername(request: ForgotUsernameRequest): Observable<DataTemplate<{ message: string }>> {
        const url = `${environment.corePath}/forgotUsername`;

        return this.http.post(url, request) as Observable<DataTemplate<{ message: string }>>;
    }

    forgotPassword(request: ForgotPasswordRequest): Observable<DataTemplate<{ message: string }>> {
        const url = `${environment.corePath}/forgotPassword`;

        return this.http.post(url, request) as Observable<DataTemplate<{ message: string }>>;
    }

    resetPassword(request: ResetPasswordRequest): Observable<DataTemplate<{ message: string }>> {
        const url = `${environment.accountPath}/resetPassword`;

        return this.http.post(url, request) as Observable<DataTemplate<{ message: string }>>;
    }

    getMembershipTypes(code: string): Observable<DataTemplate<MembershipType[]>> {
        const url = `${environment.corePath}/memberships`;
        let params = null;

        if (code) {
            params = new HttpParams().set('code', code);
        }

        return this.http.get(url, { params }) as Observable<DataTemplate<MembershipType[]>>;
    }

    register(request: RegisterRequest): Observable<DataTemplate<RegisterResponse>> {
        const url = `${environment.corePath}/signup`;

        return this.http.post(url, request) as Observable<DataTemplate<RegisterResponse>>;
    }
}
