import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { createEntityStore } from '@utils';
import * as _dayjs from 'dayjs';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

const dayjs = _dayjs;
const languageCode = sessionStorage.getItem('current_language');
(() => import(`dayjs/locale/${languageCode}`))()
    .then(() => dayjs.locale(languageCode))
    .catch(() => dayjs.locale('en'));

if (environment.target === 'production') {
    enableProdMode();
} else {
    createEntityStore({ enableWarnings: true });
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
