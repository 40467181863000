import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ModuleDialogContent } from '@shared/models';

@Component({
    selector: 'app-cruises-dialog',
    templateUrl: './cruises-dialog.component.html',
})
export class CruisesDialogComponent {
    content: ModuleDialogContent = {
        title: {
            value: {
                en: 'CRUISES',
                es: 'CRUCEROS',
            },
        },
        image: 'https://images.leisureloyalty.com/raintreerewards/img/club-benefit/cruises.jpg',
        content: [
            {
                type: 'paragraph',
                value: {
                    en: "Cruising is fast becoming one of the most popular ways to spend a family vacation, offering variety, great value for money and year round availability. Raintree Rewards members can cruise the seas in style and comfort with discounted cruises on the world's leading cruise lines.",
                    es: 'Navegar en crucero se ha convertido rápidamente en una de las vacaciones familiares más populares, ofreciendo variedad, gran valor por el costo y disponibilidad durante todo el año. Los socios de Raintree Rewards pueden navegar a través de los mares con estilo y con descuentos de navieras líderes del mundo.',
                },
            },
            {
                type: 'title',
                value: {
                    en: 'Spoiled for choice',
                    es: 'Consentido por el gusto',
                },
            },
            {
                type: 'paragraph',
                value: {
                    en: "With exclusive member prices and access to premier cruise lines in a range of global destinations, there's a cruise to suit everyone. From ocean liners to river cruises, cruising is a great way to experience a destination in a new light. Remember that you will receive Raintree Rewards for every booking you make, and can also pay down the price using your points.",
                    es: 'Nuestros socios tienen exclusivo acceso a líneas de cruceros premier en todos los destinos en un rango global para encontrar su viaje ideal. Desde cruceros por los mares hasta viajes por ríos, navegar es una gran experiencia. Recuerde que usted recibirá recompensas por cada reservación que haga y podrá pagar con sus puntos.',
                },
            },
            {
                type: 'title',
                value: {
                    en: 'Cruise Partners',
                    es: 'Navieras socios comerciales',
                },
            },
            {
                type: 'paragraph',
                value: {
                    en: "Raintree Rewards is partnered with the world's leading cruise lines",
                    es: 'Raintree Rewards tiene alianzas comerciales con las líneas de cruceros lideres del mundo.',
                },
            },
        ],
        partners: [
            'https://images.leisureloyalty.com/raintreerewards/img/suppliers/cruises/celebrity-cruises.png',
            'https://images.leisureloyalty.com/raintreerewards/img/suppliers/cruises/princess-cruises.png',
            'https://images.leisureloyalty.com/raintreerewards/img/suppliers/cruises/ncl.png',
            'https://images.leisureloyalty.com/raintreerewards/img/suppliers/cruises/msc.png',
            'https://images.leisureloyalty.com/raintreerewards/img/suppliers/cruises/holland-america.png',
            'https://images.leisureloyalty.com/raintreerewards/img/suppliers/cruises/disney-cruise-line.png',
            'https://images.leisureloyalty.com/raintreerewards/img/suppliers/cruises/cunard.png',
        ],
    };

    constructor(public dialogRef: MatDialogRef<CruisesDialogComponent>) {}
}
