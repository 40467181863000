<div appTranslate>
    <h2 class="section-title margin-bottom-20 margin-xs-bottom-0" [attr.data-i18n-code]="title"></h2>

    <div class="deals-wrapper carousel-wrapper position-relative margin-bottom-50 margin-xs-bottom-30">
        <ngu-carousel
            #destCarousel
            [inputs]="destCarouselTileConfig"
            [dataSource]="tiles"
            class="carousel-items-wrapper"
        >
            <ngu-tile class="item height-100" *nguCarouselDef="let item; let i = index; let ani = animate">
                <a
                    class="text-decoration-none"
                    [routerLink]="item.searchUrl"
                    (click)="setOriginPoint(item)"
                    *ngIf="!item.searchUrl.startsWith('http')"
                >
                    <div class="destination-wrapper flex-container flex-column height-100">
                        <div class="dest-image-wrapper flex-container justify-content-center position-relative">
                            <img [attr.src]="item.imageUrl" class="dest-image position-relative" />
                            <div
                                class="dest-tile-overlay flex-container align-items-center justify-content-center text-uppercase text-center"
                            >
                                {{ item.name }}
                            </div>
                        </div>

                        <div
                            class="dest-description-wrapper flex-container flex-column flex-grow-1 justify-content-space-between padding-15"
                            *ngIf="item.shortDescription || item.price"
                        >
                            <p
                                class="dest-description"
                                *ngIf="item.shortDescription"
                                [innerHtml]="item.shortDescription"
                            ></p>
                            <div class="dest-price margin-bottom-10 text-center" *ngIf="item.price">
                                {{ item.price.value | formatCurrency: item.price.code }}
                            </div>
                        </div>
                    </div>
                </a>

                <a
                    class="text-decoration-none"
                    [attr.href]="item.searchUrl"
                    (click)="setOriginPoint(item)"
                    *ngIf="item.searchUrl.startsWith('http')"
                >
                    <div class="destination-wrapper flex-container flex-column height-100">
                        <div class="dest-image-wrapper flex-container justify-content-center position-relative">
                            <img [attr.src]="item.imageUrl" class="dest-image position-relative" />
                            <div
                                class="dest-tile-overlay flex-container align-items-center justify-content-center text-uppercase text-center"
                            >
                                {{ item.name }}
                            </div>
                        </div>

                        <div
                            class="dest-description-wrapper flex-container flex-column flex-grow-1 justify-content-space-between padding-15"
                            *ngIf="item.shortDescription || item.price"
                        >
                            <p class="dest-description" *ngIf="item.shortDescription">{{ item.shortDescription }}</p>
                            <div class="dest-price margin-bottom-10 text-center" *ngIf="item.price">
                                {{ item.price.value | formatCurrency: item.price.code }}
                            </div>
                        </div>
                    </div>
                </a>
            </ngu-tile>

            <div NguCarouselPrev #destCarouselPrev></div>
            <div NguCarouselNext #destCarouselNext></div>
        </ngu-carousel>

        <mat-icon
            svgIcon="chevron-left"
            class="carousel-prev-icon cursor-pointer"
            [class.hide-lg-up]="tiles.length <= destCarouselTileConfig.grid.lg"
            [class.hide-md-up]="tiles.length <= destCarouselTileConfig.grid.md"
            [class.hide-sm-up]="tiles.length <= destCarouselTileConfig.grid.sm"
            (click)="triggerDestCarouselNav('prev')"
        ></mat-icon>
        <mat-icon
            svgIcon="chevron-right"
            class="carousel-next-icon cursor-pointer"
            [class.hide-lg-up]="tiles.length <= destCarouselTileConfig.grid.lg"
            [class.hide-md-up]="tiles.length <= destCarouselTileConfig.grid.md"
            [class.hide-sm-up]="tiles.length <= destCarouselTileConfig.grid.sm"
            (click)="triggerDestCarouselNav('next')"
        ></mat-icon>
    </div>
</div>
