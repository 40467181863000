import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class I18nRefreshService {
    private state = new Subject<any>();

    getState(): Observable<any> {
        return this.state.asObservable();
    }

    updateState(): void {
        this.state.next();
    }
}
