import { ErrorHandler, NgModule } from '@angular/core';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from '@core/core.module';
import { SnackBarService } from '@core/services';
import { CustomErrorHandler, DynamicScriptLoaderService, LanguageChangeService } from '@shared/services';
import { SharedModule } from '@shared/shared.module';
import { ThemeModule } from '@theme/theme.module';
import * as _dayjs from 'dayjs';
import { ToastrModule } from 'ngx-toastr';

import { AppComponent } from './app.component';
import { AppRoutes } from './app.routing';

const dayjs = _dayjs;

@NgModule({
    imports: [
        AppRoutes,
        CoreModule,
        SharedModule,
        BrowserModule,
        BrowserAnimationsModule,
        ThemeModule,
        ToastrModule.forRoot(),
    ],
    providers: [
        CustomErrorHandler,
        LanguageChangeService,
        SnackBarService,
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: {
                hasBackdrop: true,
                autoFocus: false,
                restoreFocus: false,
            },
        },
        { provide: ErrorHandler, useClass: CustomErrorHandler },
        DynamicScriptLoaderService,
    ],
    declarations: [AppComponent],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(
        matIconRegistry: MatIconRegistry,
        domSanitizer: DomSanitizer,
        private languageChange: LanguageChangeService
    ) {
        this.languageChange.update.subscribe((languageCode) => {
            (() => import(`dayjs/locale/${languageCode}`))()
                .then(() => dayjs.locale(languageCode))
                .catch(() => dayjs.locale('en'));
        });
        matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons-v2.svg'));
    }
}
