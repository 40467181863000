import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectedGuests } from '@shared/models';

@Component({
    selector: 'app-guests-counter',
    templateUrl: './guests-counter.component.html',
    styleUrls: ['./guests-counter.component.scss'],
})
export class GuestsCounterComponent implements OnInit {
    @Input()
    isFixed: boolean;

    @Input()
    isActive: boolean;

    @Input()
    hotelDetailsPage: boolean;

    @Input()
    activityDetailsPage: boolean;

    @Input()
    defaultChildAge = 0;

    @Input()
    adultsCounter: number;

    @Input()
    childrenCounter: number;

    @Input()
    childrenAges: any[];

    @Input()
    isFlights: boolean;

    @Input()
    maxGuests = 10;

    @Input()
    maxAgeValue = 17;

    @Input()
    ageValues: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17];

    @Output()
    updatedGuests: EventEmitter<SelectedGuests> = new EventEmitter<SelectedGuests>();

    showCounters: boolean;
    disableAdultsIncrement: boolean;
    disableChildrenIncrement: boolean;
    selectedAge: boolean;

    ngOnInit(): void {
        this.ageValues = this.ageValues.filter((age) => age <= this.maxAgeValue);

        if (!this.adultsCounter) {
            this.adultsCounter = 2;
        }

        if (!this.childrenCounter) {
            this.childrenCounter = 0;
        }

        if (!this.childrenAges) {
            this.childrenAges = [];
        }

        if (this.isFlights) {
            this.ageValues = this.ageValues.splice(2, this.ageValues.length);
        }
    }

    updateGuests(): void {
        if (this.selectedAge) {
            this.selectedAge = false;
        } else if (this.showCounters) {
            this.updatedGuests.emit({
                adults: this.adultsCounter,
                children: {
                    value: this.childrenCounter,
                    ages: this.childrenAges,
                },
            });
            this.showCounters = false;
        }
    }

    updateAdults(amount: number): void {
        this.adultsCounter += this.adultsCounter > 1 ? amount : amount < 0 ? 0 : amount;

        if (this.adultsCounter >= 8) {
            this.disableAdultsIncrement = true;
        } else if (!this.disableAdultsIncrement) {
            this.disableAdultsIncrement = false;
        }

        if (this.adultsCounter === 1) {
            document.getElementById('increment-adults').focus();
        }

        this.checkMaxOccupancy();
    }

    updateChildren(amount: number): void {
        this.childrenCounter += this.childrenCounter > 0 ? amount : amount < 0 ? 0 : amount;

        if (this.childrenCounter > this.childrenAges.length) {
            this.childrenAges.push({ value: this.defaultChildAge });
        } else {
            this.childrenAges.splice(this.childrenAges.length - 1, 1);
        }

        if (this.childrenCounter >= 6) {
            this.disableChildrenIncrement = true;
        } else {
            this.disableChildrenIncrement = false;
        }

        if (this.childrenCounter === 1) {
            document.getElementById('increment-children').focus();
        }

        this.checkMaxOccupancy();
    }

    checkMaxOccupancy(): void {
        if (
            this.adultsCounter + this.childrenCounter - 2 < this.maxGuests - 2 &&
            this.adultsCounter + this.childrenCounter < this.maxGuests
        ) {
            if (this.adultsCounter < 8) {
                this.disableAdultsIncrement = false;
            }

            if (this.childrenCounter < 6) {
                this.disableChildrenIncrement = false;
            }
        } else {
            this.disableAdultsIncrement = true;
            this.disableChildrenIncrement = true;
        }
    }

    addListeners(): void {
        setTimeout(() => {
            document.querySelectorAll('.cdk-overlay-container').forEach((el: Element) => {
                el.addEventListener('click', () => {
                    this.selectedAge = true;
                });
            });
        }, 0);
    }

    removeListeners(): void {
        document.querySelectorAll('.cdk-overlay-container').forEach((el: Element) => {
            el.removeEventListener('click', null);
        });
    }
}
