import { Component, Input } from '@angular/core';
import { BaseWidgetDetails } from '@core/models';

@Component({
    selector: 'app-banner-widget',
    templateUrl: './banner-widget.component.html',
    styleUrls: ['./banner-widget.component.scss'],
})
export class BannerWidgetComponent {
    @Input()
    widget: BaseWidgetDetails;
}
