import { ConfirmationDetails } from '@account/models';
import { Component, Input } from '@angular/core';
import { SiteBranding } from '@core/models';
import { AssetValue, BookPrice, SharedStorageKeys } from '@shared/models';
import { Modules } from '@utils';

@Component({
    selector: 'app-booking-payment-details',
    templateUrl: './booking-payment-details.component.html',
    styleUrls: ['./booking-payment-details.component.scss'],
})
export class BookingPaymentDetailsComponent {
    @Input()
    bookingDetails: any;

    @Input()
    receipt: boolean;

    @Input()
    cancellation: boolean;

    @Input()
    module: string;

    @Input()
    confirmationDetails: ConfirmationDetails;

    sharedStorageKeys = SharedStorageKeys;
    siteBranding: SiteBranding;
    Modules = Modules;

    bookPrice: BookPrice;

    penaltyDatePassed = false;
    serviceCharge: AssetValue;
    includedFees: any[] = [];
    hotelFees: AssetValue[] = [];

    ngOnInit(): void {
        this.siteBranding = JSON.parse(localStorage.getItem(this.sharedStorageKeys.SITE_BRANDING)) as SiteBranding;

        if (this.confirmationDetails?.cancelPenaltyDate) {
            this.penaltyDatePassed =
                new Date().getTime() > new Date(this.confirmationDetails.cancelPenaltyDate).getTime();
        }

        if (this.module === this.Modules.HOTELS) {
            if (!this.bookingDetails.payPriceBreakdown?.length) {
                this.bookingDetails.payPriceBreakdown = [];

                if (this.bookingDetails.retailPrice) {
                    this.bookingDetails.payPriceBreakdown.push({
                        value: this.bookingDetails.retailPrice.value,
                        label: this.bookingDetails.retailPrice.label,
                        type: 'retail',
                    });
                }

                if (this.bookingDetails.youPay) {
                    this.bookingDetails.payPriceBreakdown.push({
                        value: this.bookingDetails.youPay.value,
                        label: this.bookingDetails.youPay.label,
                        type: 'price',
                    });

                    if (this.bookingDetails.youPayPerNight) {
                        this.bookingDetails.payPriceBreakdown.push({
                            label: this.bookingDetails.youPayPerNight.label,
                            isItalic: true,
                        });
                    }
                }

                if (this.bookingDetails.redeem) {
                    this.bookingDetails.payPriceBreakdown.push(this.bookingDetails.redeem);
                }
            }

            this.serviceCharge = this.bookingDetails.serviceCharge;
            this.includedFees = [...(this.bookingDetails.includedFees || [])];
            this.hotelFees = this.bookingDetails.hotelFees || [];
        }

        if (this.module === this.Modules.CARS) {
            this.includedFees = [...(this.bookingDetails.fees || [])];

            this.bookPrice = BookPrice.of({
                reservationTotal: {
                    fmtValue: this.bookingDetails.reservationTotal,
                },
                totalAmount: {
                    fmtValue: this.bookingDetails.payTotalAmount,
                },
                dueAtArrival: {
                    fmtValue: this.bookingDetails.dueAtArrival,
                },
                dueAtArrivalLocal: {
                    fmtValue: this.bookingDetails.dueAtArrivalLocal,
                },
                earnDelayDate: this.bookingDetails.earnDelayDate,
                earns: {
                    fmtValue: this.bookingDetails.payPointsEarned,
                },
            });
        }
    }
}
