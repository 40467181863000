import { Entity, Of } from '@utils';

@Entity
export class UserInfo {
    @Of(() => [Balance])
    balances: Balance[];

    @Of(() => Certificates)
    certificates: Certificates;

    @Of(() => String)
    firstName: string;

    @Of(() => String)
    lastName: string;

    @Of(() => String)
    memberId: string;

    @Of(() => String)
    photo: string;

    @Of(() => ({ Boolean }), { isCustom: true })
    permissions: Record<string, boolean> = {};

    @Of(() => String)
    preferredCurrency: string;

    @Of(() => String)
    preferredLanguage: string;

    @Of(() => AssistedDetails, { nullable: true, optional: true })
    assisted?: AssistedDetails = null;

    @Of(() => String, { nullable: true, optional: true })
    signupToken?: string = null;

    @Of(() => Boolean, { nullable: true, optional: true })
    tempPwd?: boolean = undefined;

    @Of(() => Number)
    tierId: number;

    @Of(() => [Number])
    availableTiers: number[] = [];

    @Of(() => ModuleTierUpgrades)
    upgrade: ModuleTierUpgrades;

    @Of(() => Boolean)
    isGuest: boolean;

    public canSearchHotels(): boolean {
        return this.permissions['hotels:search'];
    }

    public canBookHotels(): boolean {
        return this.permissions['hotels:book'];
    }

    public canSearchFlights(): boolean {
        return this.permissions['air:search'];
    }

    public canBookFlights(): boolean {
        return this.permissions['air:book'];
    }

    public canSearchCars(): boolean {
        return this.permissions['car:search'];
    }

    public canBookCars(): boolean {
        return this.permissions['car:book'];
    }

    public canSearchCruises(): boolean {
        return this.permissions['cruises:search'];
    }

    public canBookCruises(): boolean {
        return this.permissions['cruises:book'];
    }

    public canSearchWeeks(): boolean {
        return this.permissions['weeks:search'];
    }

    public canBookWeeks(): boolean {
        return this.permissions['weeks:book'];
    }

    public canSearchActivities(): boolean {
        return this.permissions['activities:search'];
    }

    public canBookActivities(): boolean {
        return this.permissions['activities:book'];
    }

    public canSearchEscapes(): boolean {
        return true || this.permissions['escapes:search'];
    }

    public canBookEscapes(): boolean {
        return true || this.permissions['escapes:book'];
    }

    static of = Entity.of<UserInfo>();
}

@Entity
export class Balance {
    @Of(() => String)
    type: string;

    @Of(() => Number)
    value: number;

    @Of(() => String)
    code: string;

    static of = Entity.of<Balance>();
}

@Entity
export class AssistedDetails {
    @Of(() => Boolean)
    allowInvoicePayments: boolean;

    @Of(() => String)
    assistAdminFirstName: string;

    @Of(() => String)
    assistAdminLastName: string;

    @Of(() => Number)
    assistAdminId: number;

    static of = Entity.of<AssistedDetails>();
}

@Entity
export class Certificates {
    @Of(() => [Certificate])
    cruises: Certificate[];

    static of = Entity.of<Certificates>();
}

@Entity
export class Certificate {
    @Of(() => String)
    id: string;

    @Of(() => String)
    name: string;

    static of = Entity.of<Certificate>();
}

@Entity
export class ModuleTierUpgrades {
    @Of(() => [Number])
    'hotels:book': number[] = [];

    @Of(() => [Number])
    'air:book': number[] = [];

    @Of(() => [Number])
    'car:book': number[] = [];

    @Of(() => [Number])
    'cruises:book': number[] = [];

    @Of(() => [Number])
    'weeks:book': number[] = [];

    @Of(() => [Number])
    'activities:book': number[] = [];

    @Of(() => [Number])
    'escapes:book': number[] = [];
}
