<div
    class="flex-container justify-content-space-between align-items-baseline margin-bottom-10"
    *ngIf="!bookPrice?.taxIncluded && bookPrice?.tax?.value"
>
    <button
        class="ada-button"
        [class.cursor-default]="!bookPrice?.tax?.value || bookPrice.taxIncluded || !taxBreakdown.length"
        (click)="showTaxBreakdown = !showTaxBreakdown"
        aria-label="Show Taxes and Fees breakdown"
    >
        <div class="flex-container align-items-center">
            <span class="price-label" [attr.data-i18n-code]="'core.assetValues.tax.label'"></span>
            <button
                class="ada-button tax-info-button"
                (click)="openTaxInfoDialog()"
                aria-label="Show Taxes and Fees disclaimer"
                *ngIf="bookPrice?.tax?.value"
            >
                <mat-icon class="tax-info-icon" [svgIcon]="'taxinfo'"></mat-icon>
            </button>

            <mat-icon
                class="tax-breakdown-icon"
                [class.is-open]="showTaxBreakdown"
                [svgIcon]="'chevron-down'"
                *ngIf="bookPrice?.tax?.value && !bookPrice.taxIncluded && taxBreakdown.length > 0"
            ></mat-icon>
        </div>
    </button>

    <span class="price-value">{{ bookPrice?.tax | formatAssetValue }}</span>
</div>

<div class="margin-bottom-10" *ngIf="showTaxBreakdown">
    <div class="flex-container justify-content-space-between margin-bottom-5" *ngFor="let tax of taxBreakdown">
        <span class="price-secondary-label">{{ tax.label }}</span>
        <span class="price-secondary-value">{{ tax.fmtValue }}</span>
    </div>
</div>

<div
    class="flex-container justify-content-space-between align-items-baseline margin-bottom-10"
    *ngIf="serviceCharge?.value"
>
    <div class="flex-container align-items-center">
        <span class="price-label">{{ serviceCharge.label }}</span>
    </div>

    <span class="price-value">{{ serviceCharge | formatAssetValue }}</span>
</div>
