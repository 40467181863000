export class BaseWidgetDetails {
    id: number;
    code: string;
    order: number;
    name: string;
    properties: BaseWidgetProperties;
    elements: BaseWidgetElement[];
}

export class BaseWidgetProperties {
    titleKey: string;
    image: string;
}

export class BaseWidgetElement {
    titleKey: string;
    descriptionKey: string;
}

export class ModuleWidgetDetails extends BaseWidgetDetails {
    elements: ModuleWidgetElement[];
}

export class ModuleWidgetElement extends BaseWidgetElement {
    module: string;
    icon: string;
    image: string;
    actionKey: string;
}

export type PageTemplateWidget = BaseWidgetDetails | ModuleWidgetDetails;
