<div appTranslate id="booking-trip-itinerary" *ngIf="!tripsLoading">
    <div class="flex-container trip-form-wrapper">
        <mat-form-field appearance="fill" class="trip-select-wrapper flex-grow-1 padding-right-20">
            <mat-label>
                <span [attr.data-i18n-code]="'core.labels.selectTripItinerary'"></span>
            </mat-label>
            <mat-select [(ngModel)]="selectedTripId" (selectionChange)="tripId.emit($event.value || null)">
                <mat-option appTranslate id="trips-select-none">
                    <span [attr.data-i18n-code]="'core.labels.none'"></span>
                </mat-option>
                <ng-container *ngFor="let trip of trips">
                    <mat-option [value]="trip.id">{{ trip.name }}</mat-option>
                </ng-container>
            </mat-select>
        </mat-form-field>

        <button
            mat-raised-button
            class="create-trip-button text-uppercase"
            [color]="'primary'"
            (click)="triggerTripCreate()"
        >
            <span [attr.data-i18n-code]="'account.trips.create.label'"></span>
        </button>
    </div>

    <div class="flex-container justify-content-center">
        <div appTranslate *ngIf="showCreateTripMessage">
            <div
                role="success"
                aria-live="polite"
                class="create-trip-message success flex-container align-items-center"
                [attr.data-i18n-code]="'account.trips.tripCreated.label'"
            ></div>
        </div>
    </div>
</div>

<div class="flex-container justify-content-center" *ngIf="tripsLoading">
    <mat-spinner></mat-spinner>
</div>
