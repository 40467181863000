<button
    appTranslate
    #readMoreEl
    *ngIf="!readMore"
    class="ada-button read-more-less-button"
    (click)="toggleReadMore(true)"
>
    <div class="flex-container align-items-center">
        <span [attr.data-i18n-code]="'core.labels.readMore'"></span>
        <mat-icon svgIcon="chevron-down" class="read-more-icon margin-left-5"></mat-icon>
    </div>
</button>

<button
    appTranslate
    #readLessEl
    *ngIf="readMore"
    class="ada-button read-more-less-button"
    (click)="toggleReadMore(false)"
>
    <div class="flex-container align-items-center">
        <span [attr.data-i18n-code]="'core.labels.readLess'"></span>
        <mat-icon svgIcon="chevron-down" class="read-more-icon margin-left-5 rotate-180"></mat-icon>
    </div>
</button>
