import { Injectable, OnDestroy } from '@angular/core';
import { SiteBranding, UserInfo } from '@core/models';
import { AuthService } from '@core/services';
import { SharedStorageKeys } from '@shared/models';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SendQueuedAnalyticsEventsService } from '../event-emitters/send-queued-analytics-events.service';

// eslint-disable-next-line init-declarations
declare let s: any;

@Injectable({
    providedIn: 'root',
})
export class AdobeAnalyticsEventsService implements OnDestroy {
    lastPageViewEvent: QueuedEvent;
    queuedEvents: QueuedEvent[] = [];
    sharedStorageKeys = SharedStorageKeys;
    siteBranding: SiteBranding;
    pageViewData: EventParams;

    private unsubscribe: Subject<void> = new Subject<void>();

    constructor(
        private sendQueuedAnalyticsEventsService: SendQueuedAnalyticsEventsService,
        private authService: AuthService
    ) {
        this.sendQueuedAnalyticsEventsService.sendEvents
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((tracker: string) => {
                if (tracker === 'adobe' && typeof s !== 'undefined') {
                    this.queuedEvents.forEach((event: QueuedEvent) => {
                        s.clearVars();
                        this.prepareParams(event.params);

                        if (event.eventName === 'pageView') {
                            // console.log('Send Adobe queued page view', event);
                            s.t();
                        } else {
                            if (this.pageViewData) {
                                this.prepareParams(this.pageViewData);
                                // console.log(`Send Adobe queued event and pageview: ${event.eventName}`, {
                                //     ...event.params,
                                //     ...this.pageViewData,
                                // });
                                s.t();
                                this.pageViewData = null;
                            } else {
                                // console.log(`Send Adobe queued event: ${event.eventName}`, event);
                                s.tl(this, 'o', event.eventName);
                            }
                        }
                    });

                    this.queuedEvents = [];
                }
            });

        this.siteBranding = JSON.parse(localStorage.getItem(this.sharedStorageKeys.SITE_BRANDING)) as SiteBranding;
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    setPageViewData(data: EventParams): void {
        this.pageViewData = data;
    }

    sendPageView(page: string, params: EventParams): void {
        if (!this.siteBranding) {
            this.siteBranding = JSON.parse(localStorage.getItem(this.sharedStorageKeys.SITE_BRANDING)) as SiteBranding;
        }

        if (this.siteBranding?.uiConfig?.analyticsOptions?.oa?.scriptUrl) {
            if (params.pageName === this.lastPageViewEvent?.params.pageName) {
                return;
            }

            if (typeof s !== 'undefined') {
                s.clearVars();
                this.prepareParams(params);

                if (!this.checkDoubleEvent(page)) {
                    // console.log('Send Adobe page view', params);
                    s.t();
                }

                this.lastPageViewEvent = { eventName: 'pageView', params };
            } else {
                this.queuedEvents.push({ eventName: 'pageView', params });
            }
        }
    }

    sendEvent(eventName: string, params: EventParams): void {
        if (!this.siteBranding) {
            this.siteBranding = JSON.parse(localStorage.getItem(this.sharedStorageKeys.SITE_BRANDING)) as SiteBranding;
        }

        if (this.siteBranding?.uiConfig?.analyticsOptions?.oa?.scriptUrl) {
            if (typeof s !== 'undefined') {
                s.clearVars();
                this.prepareParams(params);

                if (this.pageViewData) {
                    this.prepareParams(this.pageViewData);
                    // console.log(`Send Adobe event and pageview: ${eventName}`, { ...params, ...this.pageViewData });
                    s.t();
                    this.pageViewData = null;
                } else {
                    // console.log(`Send Adobe event: ${eventName}`, params);
                    s.tl(this, 'o', eventName);
                }
            } else {
                this.queuedEvents.push({ eventName, params });
            }
        }
    }

    private getTimeProp(t: number): string {
        return t < 60
            ? `00:${t < 9 ? '0' + t : t}`
            : `${t > 600 ? Math.floor(t / 60) : '0' + Math.floor(t / 60)}:${t % 60 > 9 ? t % 60 : `0${t % 60}`}`;
    }

    private prepareParams(params: Record<string, any>): void {
        if (typeof s !== 'undefined') {
            for (const prop in params) {
                if (prop === 'eVar81' || prop === 'eVar86' || prop === 'eVar88') {
                    const t = params[prop];
                    s[prop] = this.getTimeProp(t);
                } else {
                    s[prop] = params[prop];
                }
            }

            const userInfo = JSON.parse(localStorage.getItem(this.sharedStorageKeys.USER_INFO) || null) as UserInfo;

            if (userInfo && !this.authService.isGuest()) {
                s['linkTrackVars'] = `${s['linkTrackVars']},eVar1`;
                s['eVar1'] = userInfo.memberId;
            }

            s['linkTrackVars'] = `${s['linkTrackVars']},eVar28,eVar8,cc`;
            s['eVar28'] = sessionStorage.getItem(this.sharedStorageKeys.CURRENT_LANGUAGE);
            s['currencyCode'] = sessionStorage.getItem(this.sharedStorageKeys.CURRENT_CURRENCY);
        }
    }

    private checkDoubleEvent(page: string): boolean {
        const splitArr = page.split('/') || [];

        if (
            page.includes('search') ||
            (page.includes('book') && splitArr.length <= 4) ||
            (splitArr.length === 3 && splitArr[2].length > 10)
        ) {
            return true;
        }

        return false;
    }
}

interface QueuedEvent {
    eventName: string;
    params: EventParams;
}

export interface EventParams {
    events: string;
    linkTrackVars: string;
    linkTrackEvents: string;

    pageName?: string;

    list1?: string;
    list2?: string;
    eVar17?: string;
    eVar19?: string;
    eVar20?: string;
    eVar23?: string;
    eVar25?: string;
    eVar29?: string;
    eVar34?: string;
    eVar41?: string;
    eVar44?: string;
    eVar51?: string;
    eVar52?: string;
    eVar61?: string;
    eVar62?: string;
    eVar77?: string;
    eVar81?: number;
    eVar86?: number;
    eVar88?: number;

    products?: string;
}
