import { PayPriceBreakdown } from '@shared/models';

export class BookResponse {
    bookingRef: string;
    checkIn: string;
    checkInInstructions: string;
    checkOut: string;
    confirmCode: string;
    guestFirstName: string;
    guestLastName: string;
    hotelAddress: string;
    hotelCity: string;
    hotelEmail: string;
    hotelImage: string;
    hotelName: string;
    hotelPhone: string;
    numNights: number;
    numAdults: number;
    numChildren: number;
    payCardName: string;
    payCardType: string;
    payCardNumber: string;
    payCardExpDate: string;
    payPriceBreakdown: PayPriceBreakdown[];
    payTotalAmount: string;
    payPointsEarned: string;
    roomName: string;
    rateBoardText: string;
    refundable: boolean;
    refundableUntil: string;
    txGuid: string;
    voucherId: string;
}
