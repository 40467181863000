import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SiteBranding } from '@core/models';
import { SharedStorageKeys } from '@shared/models';

@Component({
    selector: 'app-product-loader',
    templateUrl: './product-loader.component.html',
    styleUrls: ['./product-loader.component.scss'],
})
export class ProductLoaderComponent implements OnInit {
    @Input()
    icon: string;

    @Input()
    module: string;

    @Input()
    fullScreen: boolean;

    @Input()
    set forceComplete(value: boolean) {
        if (value) {
            let threshold = 10;
            let counter = 0;

            if (!this.loaderTexts.length) {
                this.loaderCompleted.emit(true);
            }

            for (let i = 0; i <= this.loaderTexts.length - 1; i++) {
                this.loaderTexts[i].forced = true;

                if (this.loaderTexts[i].loading && threshold === 10) {
                    threshold = i;
                }

                if (i >= threshold) {
                    setTimeout(() => {
                        this.loaderTexts[i].loading = false;
                        this.loaderTexts[i].active = true;

                        if (i < this.loaderTexts.length - 2) {
                            this.loaderTexts[i + 1].loading = true;
                        } else {
                            setTimeout(() => {
                                this.loaderCompleted.emit(true);
                            }, 1500);
                        }
                    }, (counter + 1) * 1000);

                    counter++;
                }
            }
        }
    }

    @Output()
    loaderCompleted: EventEmitter<boolean> = new EventEmitter<boolean>();

    sharedStorageKeys = SharedStorageKeys;
    loaderBalls: LoaderBall[] = [new LoaderBall(true), new LoaderBall(false), new LoaderBall(false)];
    loaderTexts: LoaderText[] = [];
    siteBranding: SiteBranding;

    ngOnInit(): void {
        this.siteBranding = JSON.parse(localStorage.getItem(this.sharedStorageKeys.SITE_BRANDING)) as SiteBranding;

        let counter = 0;

        setInterval(() => {
            this.loaderBalls[counter % 3].active = false;
            this.loaderBalls[counter % 3].transition = true;
            counter++;
            this.loaderBalls[counter % 3].active = true;
            this.loaderBalls[(counter + 1) % 3].transition = false;
        }, 750);

        if (this.module === 'hotelsRooms') {
            this.loadTexts(
                this.siteBranding?.uiConfig?.moduleOptions.hotels.interstitialTextsRooms,
                this.siteBranding?.uiConfig?.moduleOptions.hotels.interstitialDelayRooms
            );
        } else if (this.module === 'weeksRooms') {
            this.loadTexts(
                this.siteBranding?.uiConfig?.moduleOptions.weeks.interstitialTextsRooms,
                this.siteBranding?.uiConfig?.moduleOptions.weeks.interstitialDelayRooms
            );
        } else {
            this.loadTexts(
                this.siteBranding?.uiConfig?.moduleOptions[this.module].interstitialTexts,
                this.siteBranding?.uiConfig?.moduleOptions[this.module].interstitialDelay
            );
        }
    }

    private loadTexts(texts: string[], delay = 3) {
        this.loaderTexts = texts?.map((text: string) => new LoaderText(text)) || [new LoaderText('Loading...')];
        this.loaderTexts[0].loading = true;

        for (let i = 0; i <= this.loaderTexts.length - 1; i++) {
            setTimeout(() => {
                if (i < this.loaderTexts.length - 1 && !this.loaderTexts[i].forced && !this.loaderTexts[i + 1].forced) {
                    this.loaderTexts[i].loading = false;
                    this.loaderTexts[i].active = true;
                    this.loaderTexts[i + 1].loading = true;
                }
            }, delay * (i + 1) * 1000);
        }
    }
}

class LoaderBall {
    constructor(public active: boolean, public transition: boolean = false) {}
}

class LoaderText {
    constructor(
        public value: string,
        public active: boolean = false,
        public loading: boolean = false,
        public forced: boolean = false
    ) {}
}
