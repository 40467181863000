import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Injectable({
    providedIn: 'root',
})
export class FormErrorService {
    public checkForErrors(group: UntypedFormGroup, formErrors: any, validationMessages: any): void {
        if (!group) {
            return;
        }

        for (const field in formErrors) {
            if (formErrors.hasOwnProperty(field)) {
                if (group.get(field)) {
                    if (formErrors[field] instanceof Object) {
                        this.checkForErrors(
                            group.get(field) as UntypedFormGroup,
                            formErrors[field],
                            validationMessages
                        );
                    } else if (formErrors[field] instanceof Array) {
                        formErrors[field] = [];
                        const controls = (group.get(field) as UntypedFormArray).controls;
                        for (let i = 0; i < controls.length; i++) {
                            formErrors[field][i] = this.checkControlForErrors(
                                controls[i].get(Object.keys((controls[i] as UntypedFormGroup).controls)[0]),
                                validationMessages,
                                field
                            );
                        }
                    } else {
                        formErrors[field] = this.checkControlForErrors(group.get(field), validationMessages, field);
                    }
                }
            }
        }
    }

    private checkControlForErrors(control: AbstractControl, validationMessages: any, field: string): string {
        let formErrorsField = '';
        if (control && control.dirty && !control.valid) {
            const messages = validationMessages[field];

            for (const key in control.errors) {
                if (control.errors.hasOwnProperty(key)) {
                    formErrorsField += messages[key] + ' ';
                }
            }
        }

        return formErrorsField;
    }

    public markAsDirty(group: UntypedFormGroup): void {
        group.markAsTouched();
        group.markAsDirty();

        for (const i in group.controls) {
            if (group.controls[i] instanceof UntypedFormControl) {
                group.controls[i].markAsTouched();
                group.controls[i].markAsDirty();
            } else if (group.controls[i] instanceof UntypedFormArray) {
                (group.controls[i] as UntypedFormArray).controls.forEach((control) => {
                    this.markAsDirty(control as UntypedFormGroup);
                });
            } else if (group.controls[i] instanceof UntypedFormGroup) {
                this.markAsDirty(group.controls[i] as UntypedFormGroup);
            } else {
                group.markAsDirty();
            }
        }
    }
}
