import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SendQueuedAnalyticsEventsService } from '../event-emitters/send-queued-analytics-events.service';

// eslint-disable-next-line init-declarations
declare let gtag: any;

@Injectable({
    providedIn: 'root',
})
export class GoogleAnalyticsEventsService implements OnDestroy {
    queuedEvents: QueuedEvent[] = [];

    private unsubscribe: Subject<void> = new Subject<void>();

    constructor(private sendQueuedAnalyticsEventsService: SendQueuedAnalyticsEventsService) {
        this.sendQueuedAnalyticsEventsService.sendEvents
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((tracker: string) => {
                if (tracker === 'google') {
                    this.queuedEvents.forEach((event: QueuedEvent) => {
                        gtag('event', event.eventName, event.params);
                    });

                    this.queuedEvents = [];
                }
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    sendEvent(
        eventName: string,
        eventCategory: string,
        eventAction: string,
        eventLabel: string = null,
        eventValue: string = null,
        customDefinitions: Record<string, any> = null
    ): void {
        let params: Record<string, any> = {
            eventCategory,
            eventLabel,
            eventAction,
            eventValue,
        };

        if (customDefinitions) {
            params = Object.assign(params, customDefinitions);
        }

        if (typeof gtag !== 'undefined') {
            gtag('event', eventName, params);
        } else {
            this.queuedEvents.push({ eventName, params });
        }
    }
}

class QueuedEvent {
    eventName: string;
    params: Record<string, any>;
}
