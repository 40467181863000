<ul class="masonry no-styles">
    <li class="masonry-brick" *ngFor="let image of firstSix; index as i">
        <figure class="height-100">
            <button
                aria-label="Open full-screen image slider"
                [attr.id]="'masonry-' + i"
                class="ada-button height-100 width-100"
                (click)="openFullScreenCarousel(i)"
            >
                <img [attr.src]="image" class="masonry-img" alt="" />
            </button>
        </figure>
    </li>
</ul>

<div
    appTrapFocus
    #fullScreenCarouselEl
    class="full-screen-carousel-wrapper carousel-wrapper full-width width-100 height-100 flex-container align-items-center justify-content-center"
    role="dialog"
    aria-modal="true"
    *ngIf="fullScreenCarousel"
    (onEscape)="handleCarouselEscaped()"
>
    <button
        appAutofocus
        #firstFocusableElement
        class="carousel-close-icon-wrapper ada-button"
        aria-label="Close slideshow"
        (click)="fullScreenCarousel = false"
    >
        <mat-icon svgIcon="remove" class="carousel-close-icon"></mat-icon>
    </button>

    <div class="full-screen-carousel position-relative">
        <ngu-carousel
            #imagesCarousel
            [inputs]="carouselTileConfig"
            [dataSource]="images"
            class="carousel-items-wrapper"
        >
            <ngu-tile *nguCarouselDef="let item; let i = index; let ani = animate">
                <div class="width-100 full-screen-image-wrapper">
                    <img [attr.src]="item" alt="" />
                </div>
            </ngu-tile>

            <div NguCarouselPrev #carouselPrev></div>
            <div NguCarouselNext #carouselNext></div>
        </ngu-carousel>

        <button
            aria-label="Previous image"
            class="carousel-icon-background flex-container justify-content-center align-items-center carousel-prev-icon cursor-pointer ada-button"
            (click)="triggerCarouselNav('prev')"
        >
            <mat-icon svgIcon="chevron-left" class="carousel-nav-icon"></mat-icon>
        </button>

        <button
            #lastFocusableElement
            aria-label="Next image"
            class="carousel-icon-background flex-container justify-content-center align-items-center carousel-next-icon cursor-pointer ada-button"
            (click)="triggerCarouselNav('next')"
        >
            <mat-icon svgIcon="chevron-right" class="carousel-nav-icon"></mat-icon>
        </button>
    </div>
</div>
