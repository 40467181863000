import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { EarnedReward, PurchaseRequest, Reward, RewardDetails, SearchRewards } from '@rewards/models';
import { BookPrice, CardProfile, ComputePriceInput, DataTemplate } from '@shared/models';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class RewardsService {
    constructor(private http: HttpClient) {}

    search(): Observable<DataTemplate<SearchRewards>> {
        const url = `${environment.rewardsPath}/search`;

        return this.http.post(url, {}) as Observable<DataTemplate<SearchRewards>>;
    }

    getDetails(id: number): Observable<DataTemplate<RewardDetails>> {
        const url = `${environment.rewardsPath}/${id}`;

        return this.http.get(url) as Observable<DataTemplate<RewardDetails>>;
    }

    purchase(request: PurchaseRequest): Observable<DataTemplate<any>> {
        const url = `${environment.rewardsPath}/purchase`;

        return this.http.post(url, request) as Observable<DataTemplate<SearchRewards>>;
    }

    getCardProfiles(): Observable<DataTemplate<CardProfile[]>> {
        const url = `${environment.rewardsPath}/ccardprofiles`;

        return this.http.get(url) as Observable<DataTemplate<CardProfile[]>>;
    }

    computePrice(id: string, options?: ComputePriceInput): Observable<DataTemplate<BookPrice>> {
        const url = `${environment.rewardsPath}/${id}/price`;

        let params = new HttpParams();

        if (options?.payPoints || options?.payPoints === 0) {
            params = params.append('payPoints', options.payPoints.toString());
        }

        if (options?.useCredit) {
            params = params.append('useCredit', 'true');
        }

        return this.http.get(url, { params }) as Observable<DataTemplate<BookPrice>>;
    }

    getUserRewards(pastRewards: boolean = null): Observable<DataTemplate<EarnedReward[]>> {
        const url = `${environment.rewardsPath}/earned`;

        let params = new HttpParams();

        if (pastRewards) {
            params = params.append('pastRewards', 'true');
        }

        return this.http.post(url, {}, { params }) as Observable<DataTemplate<EarnedReward[]>>;
    }

    redeemCode(code: string): Observable<DataTemplate<Reward>> {
        const url = `${environment.rewardsPath}/authcode/${code.trim()}`;

        return this.http.get(url) as Observable<DataTemplate<Reward>>;
    }
}
