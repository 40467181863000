export class AvailableRoom {
    sliderValue: number;
    sliderMin: number;
    sliderMax: number;
    icon: string;
    name: string;
    amenitiesInfo: string;
    memberPrice: string;
    pointsCondition: string;
    retailPrice: string;
    taxesIncluded: boolean;
}
