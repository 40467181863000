import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ModuleDialogContent } from '@shared/models';

@Component({
    selector: 'app-shopping-dialog',
    templateUrl: './shopping-dialog.component.html',
})
export class ShoppingDialogComponent {
    content: ModuleDialogContent = {
        title: {
            value: {
                en: 'SHOPPING',
                es: 'COMPRAS',
            },
        },
        image: 'https://images.leisureloyalty.com/raintreerewards/img/club-benefit/shopping.jpg',
        content: [
            {
                type: 'paragraph',
                value: {
                    en: 'Everybody loves to shop - particularly when you get to save!',
                    es: 'Todo el mundo ama las compras - ¡sobretodo cuando puedes ahorrar!',
                },
            },
            {
                type: 'paragraph',
                value: {
                    en: 'And you can make amazing savings when you shop online with Raintree Rewards.',
                    es: 'CCuando compras en línea con Raintree Rewards puedes ahorrar de manera increíble. Encontrarás un extensivo catálogo lleno de opciones.',
                },
            },
            {
                type: 'paragraph',
                value: {
                    en: "Just click on the Shopping button, and you'll enter our extensive online catalogue teeming with opportunity.",
                    es: 'Utiliza Raintree Rewards para ahorrar aún más, tanto en objetos cotidianos como en lujosos regalos.',
                },
            },
            {
                type: 'paragraph',
                value: {
                    en: "Use your Raintree Rewards to slash the price of your shopping - whether it's for everyday items or a luxury gift.",
                    es: 'Encontrarás las mejores marcas en nuestro catálogo, y lo que compres será enviado directamente a la puerta de tu casa. Cada que realices una compra ganarás más con Raintree Rewards.',
                },
            },
            {
                type: 'paragraph',
                value: {
                    en: "You'll find all the major brands there - and it will all get shipped straight to your door.",
                    es: '',
                },
            },
            {
                type: 'paragraph',
                value: {
                    en: "What's more, you'll earn Raintree Rewards every time you shop.",
                    es: '',
                },
            },
            {
                type: 'title',
                value: {
                    en: 'Prefer a gift card?',
                    es: '¿Prefieres una tarjeta de regalo?',
                },
            },
            {
                type: 'paragraph',
                value: {
                    en: "If you prefer to go to the store, or wish to give someone you love a shopping experience of their own, simply purchase an online gift card - and you'll earn more Raintree Rewards!",
                    es: 'Si prefieres ir a la tienda en físico o regalarles a tus seres queridos una experiencia de compras solo pide la tarjeta de regalo en línea - ¡así ganarás todavía más recompensas!',
                },
            },
            {
                type: 'paragraph',
                value: {
                    en: "All the major retailers are featured, from Home Depot to Nordstrom, from iTunes to Target. There's something for everyone.",
                    es: 'Ofrecemos las mejores tiendas, desde Home Depot hasta Nordstrom, de iTunes a Target ¡Hay algo para todos!',
                },
            },
        ],
        partners: [
            'https://images.leisureloyalty.com/raintreerewards/img/suppliers/shopping/macys.png',
            'https://images.leisureloyalty.com/raintreerewards/img/suppliers/shopping/sears.png',
            'https://images.leisureloyalty.com/raintreerewards/img/suppliers/shopping/sephora.png',
            'https://images.leisureloyalty.com/raintreerewards/img/suppliers/shopping/bloomingdales.png',
            'https://images.leisureloyalty.com/raintreerewards/img/suppliers/shopping/nordstrom.png',
            'https://images.leisureloyalty.com/raintreerewards/img/suppliers/shopping/target.png',
            'https://images.leisureloyalty.com/raintreerewards/img/suppliers/shopping/neiman-marcus.png',
            'https://images.leisureloyalty.com/raintreerewards/img/suppliers/shopping/barneys-new-york.png',
        ],
    };

    constructor(public dialogRef: MatDialogRef<ShoppingDialogComponent>) {}
}
