<div class="price-separator margin-top-10 margin-bottom-10"></div>

<div
    class="flex-container justify-content-space-between align-items-baseline margin-bottom-10"
    *ngIf="totalDueAtProperty"
>
    <button
        class="ada-button"
        (click)="showPropertyBreakdown = !showPropertyBreakdown"
        aria-label="Show Taxes and Fees breakdown"
    >
        <div class="flex-container align-items-center">
            <span
                class="price-total-label bold"
                [attr.data-i18n-code]="'hotels.confirmation.dueAtProperty.label'"
            ></span>

            <mat-icon
                class="tax-breakdown-icon"
                [class.is-open]="showPropertyBreakdown"
                [svgIcon]="'chevron-down'"
                *ngIf="propertyBreakdown.length > 0"
            ></mat-icon>
        </div>
    </button>

    <span class="price-total-value bold">{{ totalDueAtProperty }}</span>
</div>

<ng-container *ngIf="showPropertyBreakdown">
    <div class="flex-container justify-content-space-between margin-bottom-5" *ngFor="let tax of propertyBreakdown">
        <span class="price-secondary-label">{{ tax.label }}</span>
        <span class="price-secondary-value">{{ tax.fmtValue }}</span>
    </div>
</ng-container>

<div class="price-separator margin-top-10 margin-bottom-10"></div>

<ng-container *ngIf="totalDueAtProperty">
    <div class="flex-container justify-content-space-between" [class.margin-bottom-10]="!bookPrice?.taxIncluded">
        <span class="price-total-label bold" [attr.data-i18n-code]="'core.labels.reservationTotal'"></span>
        <span class="price-total-value bold">{{ bookPrice?.reservationTotal | formatAssetValue }}</span>
    </div>
</ng-container>

<div appTranslate *ngIf="totalDueAtProperty">
    <p
        class="price-disclaimer"
        [attr.data-i18n-code]="'hotels.booking.dueAtPropertyDisclaimer.label'"
        [attr.data-i18n-params]="
            currentCurrency +
            '|' +
            totalDueAtProperty +
            '|' +
            originalTotalDueAtHotelCode +
            '|' +
            originalTotalDueAtProperty
        "
    ></p>
</div>

<p class="price-disclaimer" [attr.data-i18n-code]="'hotels.booking.additionalPropertyFees.label'"></p>
