import { Component, Input, OnInit } from '@angular/core';
import { AssetValue, BookPrice, SharedStorageKeys } from '@shared/models';

@Component({
    selector: 'app-property-taxes',
    templateUrl: './property-taxes.component.html',
    styleUrls: ['./property-taxes.component.scss'],
})
export class PropertyTaxesComponent implements OnInit {
    @Input()
    bookPrice: BookPrice;

    @Input()
    totalDueAtProperty: string;

    @Input()
    originalTotalDueAtProperty: string;

    @Input()
    originalTotalDueAtHotelCode: string;

    @Input()
    propertyBreakdown: AssetValue[];

    sharedStorageKeys = SharedStorageKeys;
    currentCurrency: string;
    showPropertyBreakdown: boolean;

    ngOnInit(): void {
        this.currentCurrency = sessionStorage.getItem(this.sharedStorageKeys.CURRENT_CURRENCY);
    }
}
