export enum StorageKeys {
    SEARCH_REQUEST = 'activities_search_request',
    SEARCH_SCHEDULES_REQUEST = 'activities_search_schedules_request',
    SEARCH_RESPONSE = 'activities',
    SEARCH_TOTAL_ITEMS = 'activities_total_items',
    SEARCH_FILTERS = 'activities_filters',
    SEARCH_FILTERS_MIN_PRICE = 'activities_min_price',
    SEARCH_FILTERS_MAX_PRICE = 'activities_max_price',
    SEARCH_ITEM = 'activity_search_item',
    ITEM_TO_BOOK = 'activity_to_book',
    SCHEDULE_TO_BOOK = 'activity_schedule_to_book',
    PICKUP = 'activities_pickup',
    DROP_OFF = 'activities_drop_off',
    BOOK_RESPONSE = 'activities_book_response',
    SEARCH_ORIGIN_POINT = 'activities_search_origin_point',
    SEARCH_START_TIME = 'activities_search_start_time',
    SEARCH_RESULTS_START_TIME = 'activities_search_results_start_time',
    BOOK_START_TIME = 'activities_book_start_time',
    SEARCH_TRIGGERED = 'activities_search_triggered',
    BOOK_TRIGGERED = 'activities_book_triggered',
}
