import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { DataTemplate, Location } from '@shared/models';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class YelpService {
    constructor(private http: HttpClient) {}

    searchYelp(location: Location, term: string, module = 'hotels'): Observable<DataTemplate<any>> {
        let url = `${environment.hotelsPath}/yelp`;

        if (module === 'weeks') {
            url = `${environment.weeksPath}/yelp`;
        }

        const params = new HttpParams()
            .set('lat', location.lat.toString())
            .set('lng', location.lng.toString())
            .set('term', term);

        return this.http.get(url, { params }) as Observable<DataTemplate<any>>;
    }
}
