import { Location, RoomGuests, SavedSearchSortFilters } from '@shared/models';

export class SearchRequest {
    checkIn: string;
    checkOut: string;
    adults: number;
    children: number;
    childrenAges: number[];
    searchCriteria: LocationSearch | string[];
    searchType: string;
    distanceUnit?: string;
    startIn?: number;
    stayFor?: number;
    sortFilters?: HotelsSortFilters;
    propertyName?: string;
    roomGuests?: RoomGuests[];

    constructor(
        roomGuests = [{ adults: 2 }],
        checkIn?: string,
        checkOut?: string,
        adults?: number,
        children?: number,
        childrenAges?: number[],
        searchCriteria?: LocationSearch | string[],
        searchType?: string
    ) {
        this.roomGuests = roomGuests;
        this.adults = adults;
        this.checkIn = checkIn;
        this.checkOut = checkOut;
        this.children = children;
        this.childrenAges = childrenAges;
        this.searchCriteria = searchCriteria;
        this.searchType = searchType;
    }
}

export class LocationSearch {
    locId: string;
    locName: string;
    location: Location;
    radius: number;
    type?: HotelsSearchType;
    oaLocation: string;

    constructor(
        locId: string,
        location: Location,
        radius = 30000,
        locName: string,
        type: HotelsSearchType,
        oaLocation: string
    ) {
        this.locId = locId;
        this.location = location;
        this.radius = radius;
        this.locName = locName;
        this.type = type;
        this.oaLocation = oaLocation;
    }
}

export enum HotelsSearchType {
    HOTEL = 'hotel',
    CITY = 'city',
}

export interface HotelsSortFilters extends SavedSearchSortFilters {
    guestRating: number;
    priceMin: number;
    priceMax: number;
    starRating: number;
}
