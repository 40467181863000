<h3
    class="subsection-title margin-bottom-20 booking-panel-title"
    [attr.data-i18n-code]="'core.labels.priceSummary'"
></h3>

<div appTranslate id="price-summary" *ngIf="bookPrice?.totalAmount; else loader">
    <app-hotel-summary
        *ngIf="target === modules.HOTELS && searchRequest"
        [searchRequest]="searchRequest"
        [hotel]="hotel"
        [room]="room"
    ></app-hotel-summary>

    <div class="position-relative" *ngIf="target !== modules.CARS">
        <app-spinner [isLoading]="!loaded">
            <div appTranslate>
                <div
                    class="flex-container justify-content-space-between margin-bottom-10"
                    *ngIf="bookPrice?.retailPrice?.value > bookPrice?.youPay?.value && showRetailPrice"
                >
                    <span class="price-label" [attr.data-i18n-code]="'core.assetValues.retailPrice.label'"></span>
                    <span class="price-value line-through">{{ bookPrice?.retailPrice | formatAssetValue }}</span>
                </div>

                <div class="flex-container justify-content-space-between margin-bottom-10" *ngIf="bookPrice?.youPay">
                    <div>
                        <span class="price-label" [attr.data-i18n-code]="'core.assetValues.youPay.label'"></span>

                        <div *ngIf="perNight">
                            <span class="price-secondary-label">{{ perNight }}</span>
                        </div>
                    </div>

                    <span class="price-value" *ngIf="!bookPrice?.dueAtArrival?.value">{{
                        bookPrice?.youPay | formatAssetValue
                    }}</span>

                    <span class="price-value" *ngIf="bookPrice?.dueAtArrival?.value">{{
                        bookPrice?.dueAtArrival | formatAssetValue
                    }}</span>
                </div>

                <div class="flex-container justify-content-space-between margin-bottom-10" *ngIf="recurring?.value">
                    <span class="price-label" [attr.data-i18n-code]="'core.labels.recurring'"></span>
                    <span class="price-value">{{ recurring | formatAssetValue }}</span>
                </div>

                <div
                    appTranslate
                    class="flex-container justify-content-space-between margin-bottom-10"
                    *ngIf="hasCertificate"
                >
                    <span
                        class="credit-decrease-label"
                        [attr.data-i18n-code]="'cruises.booking.certificateApplied.label'"
                    ></span>
                </div>

                <div
                    class="flex-container justify-content-space-between margin-bottom-10"
                    *ngIf="bookPrice?.redeem?.value && siteBranding?.uiConfig?.pointsOptions?.showPoints"
                >
                    <span
                        class="price-label"
                        [attr.data-i18n-code]="'core.assetValues.redeem.label'"
                        [attr.data-i18n-params]="siteBranding?.uiConfig?.pointsOptions?.pointsName"
                        [attr.data-i18n-persist]="true"
                    ></span>
                    <span class="price-value points-value">{{ bookPrice?.redeem?.value }}</span>
                </div>

                <app-taxes
                    [target]="target"
                    [bookPrice]="bookPrice"
                    [invsrcId]="room?.invsrcId"
                    [serviceCharge]="serviceCharge"
                    [taxBreakdown]="taxBreakdown"
                ></app-taxes>

                <div
                    appTranslate
                    class="flex-container justify-content-space-between margin-bottom-10"
                    *ngIf="useCredit && bookPrice?.maxCreditAmt?.value"
                >
                    <span class="credit-decrease-label" [attr.data-i18n-code]="'core.labels.accountCredit'"></span>
                    <span class="credit-decrease-value">-{{ bookPrice?.maxCreditAmt | formatAssetValue }}</span>
                </div>

                <div
                    class="price-separator margin-bottom-10"
                    *ngIf="bookPrice?.retailPrice || bookPrice?.youPay || bookPrice?.redeem || bookPrice?.tax"
                ></div>

                <div
                    class="flex-container justify-content-space-between"
                    [class.margin-bottom-10]="!bookPrice?.taxIncluded"
                >
                    <span
                        class="price-total-label bold"
                        [attr.data-i18n-code]="totalDueAtProperty ? 'core.labels.totalDueToday' : 'core.labels.total'"
                    ></span>
                    <span class="price-total-value bold" *ngIf="!bookPrice?.dueAtArrival?.value">{{
                        bookPrice?.totalAmount | formatAssetValue
                    }}</span>
                    <span class="price-total-value bold" *ngIf="bookPrice?.dueAtArrival?.value">{{
                        bookPrice?.dueAtArrival?.value | formatCurrency: bookPrice?.dueAtArrival?.code
                    }}</span>
                </div>

                <div
                    *ngIf="bookPrice?.taxIncluded"
                    class="price-label text-italic margin-bottom-10"
                    [attr.data-i18n-code]="'hotels.rate.taxesIncluded.label'"
                ></div>

                <app-points-earned [bookPrice]="bookPrice"></app-points-earned>

                <app-property-taxes
                    [bookPrice]="bookPrice"
                    [totalDueAtProperty]="totalDueAtProperty"
                    [originalTotalDueAtProperty]="originalTotalDueAtProperty"
                    [originalTotalDueAtHotelCode]="rate.originalTotalDueAtHotelCode"
                    [propertyBreakdown]="propertyBreakdown"
                    *ngIf="target === 'hotels' && totalDueAtProperty"
                ></app-property-taxes>
            </div>
        </app-spinner>
    </div>

    <app-cars-prices
        *ngIf="target === modules.CARS"
        [bookPrice]="bookPrice"
        [loaded]="loaded"
        [showRetailPrice]="showRetailPrice"
        [useCredit]="useCredit"
        [taxBreakdown]="taxBreakdown"
        [isPostPaid]="isPostPaid"
    ></app-cars-prices>

    <div
        class="position-relative margin-top-20 padding-top-20"
        *ngIf="
            showPointsSlider &&
            pointsRangeOptions &&
            pointsRangeOptions?.ceil > 0 &&
            siteBranding?.uiConfig?.pointsOptions?.showPoints
        "
    >
        <div class="points-separator"></div>

        <h4
            id="redeem-points-label"
            class="margin-bottom-15"
            [attr.data-i18n-code]="'core.labels.redeemPoints'"
            [attr.data-i18n-params]="siteBranding?.uiConfig?.pointsOptions?.pointsName"
            [attr.data-i18n-persist]="true"
        ></h4>
        <p
            [attr.data-i18n-code]="'core.labels.pointsRedeem'"
            [attr.data-i18n-params]="redeemMax?.value + '|' + siteBranding?.uiConfig?.pointsOptions?.pointsName"
        ></p>

        <ng-container *ngIf="pointsRangeOptions && bookPrice?.redeemSliderInd !== false">
            <ngx-slider
                class="points-slider"
                [(value)]="bookPrice.redeem.value"
                [options]="pointsRangeOptions"
                (userChangeEnd)="computePrice({ payPoints: $event.value, useCredit: useCredit })"
            ></ngx-slider>
        </ng-container>
    </div>

    <div class="position-relative margin-top-20 padding-top-20" *ngIf="bookPrice.membershipFee?.value">
        <div class="points-separator"></div>

        <h4 id="redeem-points-label" class="margin-bottom-15" [attr.data-i18n-code]="'core.labels.membershipFee'"></h4>

        <p class="margin-bottom-10" [attr.data-i18n-code]="'core.labels.membershipFeeDisclaimer'"></p>

        <div class="price-separator margin-bottom-10"></div>

        <div class="flex-container justify-content-space-between">
            <span class="price-total-label bold" [attr.data-i18n-code]="'core.labels.total'"></span>
            <span class="price-total-value bold">{{ bookPrice?.membershipFee | formatAssetValue }}</span>
        </div>
    </div>

    <div
        class="position-relative margin-top-20 padding-top-20"
        *ngIf="
            bookPrice?.creditBalance?.value &&
            (useCredit || (!useCredit && bookPrice?.totalAmount?.value)) &&
            !isPostPaid
        "
    >
        <div class="points-separator"></div>

        <h4 class="margin-bottom-15" [attr.data-i18n-code]="'core.labels.useAccountCredit'"></h4>
        <p
            [attr.data-i18n-code]="'core.labels.accountCreditAmount'"
            [attr.data-i18n-params]="bookPrice?.creditBalance | formatAssetValue"
        ></p>

        <div class="flex-inline-container width-100" *ngIf="loaded; else loader">
            <mat-form-field class="no-padding custom-select">
                <mat-select [ngModel]="useCredit" (selectionChange)="emitAccountCredit($event)" class="text-uppercase">
                    <mat-option appTranslate id="use-account-credit" [value]="true"
                        ><span
                            [attr.data-i18n-code]="'core.labels.useCreditAmount'"
                            [attr.data-i18n-params]="bookPrice?.maxCreditAmt | formatAssetValue"
                        ></span
                    ></mat-option>
                    <mat-option appTranslate id="dont-use-account-credit" [value]="false"
                        ><span [attr.data-i18n-code]="'core.labels.excludeCreditAmount'"></span
                    ></mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</div>

<ng-template #loader>
    <div class="flex-container align-items-center justify-content-center">
        <mat-spinner [class.hide]="loaded"></mat-spinner>
    </div>
</ng-template>
