import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'formatTripItemType',
})
export class FormatTripItemTypePipe implements PipeTransform {
    transform(value: string): any {
        switch (value) {
            case 'H':
                return 'hotel';
            case 'V':
                return 'car';
            case 'CR':
                return 'cruise';
            case 'AIR':
                return 'flight';
            case 'ACT':
                return 'activity';
            case 'TOUR':
                return 'tour';
            case 'RES':
                return 'week';
            case 'OTH':
                return 'rewards';
            case 'SHOP':
                return 'rewards';
            case 'EC':
                return 'gift-card';
            case 'ESC':
                return 'escape';
            default:
                return value;
        }
    }
}
