import { SavedSearchSortFilters } from '@shared/models';
import { Entity, Of } from '@utils';

@Entity
export class SearchRequest {
    @Of(() => Number, { nullable: true })
    destinationId: number | null = -1;

    @Of(() => String, { nullable: true })
    date: string | null = null;

    @Of(() => Boolean)
    featured = false;

    sortFilters?: SavedSearchSortFilters;

    static of = Entity.of<SearchRequest>();
}
