import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SiteBranding } from '@core/models';
import { AuthService } from '@core/services/auth/auth.service';
import { TokenInteractionService } from '@core/services/auth/token-interaction.service';
import { SharedStorageKeys } from '@shared/models';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
    sharedStorageKeys = SharedStorageKeys;
    siteBranding: SiteBranding;

    constructor(
        private tokenService: TokenInteractionService,
        private router: Router,
        private authService: AuthService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        this.siteBranding = JSON.parse(localStorage.getItem(this.sharedStorageKeys.SITE_BRANDING)) as SiteBranding;

        if (
            !this.tokenService.isValidToken() ||
            !this.siteBranding ||
            (this.siteBranding?.uiConfig.userOptions?.loginRequired && this.authService.isGuest())
        ) {
            this.authService.logout();
            this.router.navigate(['login'], { queryParams: { returnUrl: state.url } });
            return false;
        }

        return true;
    }
}
