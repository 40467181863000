import { Entity, Of } from '@utils';

@Entity
export class GuaranteedPriceRequest {
    @Of(() => String)
    memberId = '';

    @Of(() => String)
    memberName = '';

    @Of(() => String)
    memberEmail = '';

    @Of(() => Number)
    reservationId = 0;

    @Of(() => String)
    competitorWebsite = '';

    @Of(() => Number)
    competitorPrice = 0;

    static of = Entity.of<GuaranteedPriceRequest>();
}
