import { Entity, Of } from '@utils';

@Entity
export class CardProfile {
    @Of(() => Number, { nullable: true, optional: true })
    id?: number = null;

    @Of(() => String, { nullable: true, optional: true })
    ccType?: string = null;

    @Of(() => String, { nullable: true, optional: true })
    ccLast4?: string = null;

    @Of(() => String, { nullable: true, optional: true })
    ccNumber?: string = null;

    @Of(() => Number, { nullable: true, optional: true })
    ccExpM?: number = null;

    @Of(() => Number, { nullable: true, optional: true })
    ccExpY?: number = null;

    @Of(() => String, { nullable: true, optional: true })
    ccCvv?: string = null;

    @Of(() => String, { nullable: true, optional: true })
    ccNameOnCard?: string = null;

    @Of(() => String)
    firstName: string;

    @Of(() => String)
    lastName: string;

    @Of(() => String)
    email: string;

    @Of(() => String)
    address1: string;

    @Of(() => String)
    city: string;

    @Of(() => String)
    postalCode: string;

    @Of(() => Number, { nullable: true, optional: true })
    provinceId?: number = null;

    @Of(() => String, { nullable: true, optional: true })
    provinceName?: string = null;

    @Of(() => Number, { nullable: true, optional: true })
    countryId?: number = null;

    @Of(() => String, { nullable: true, optional: true })
    company?: string = null;

    @Of(() => String, { nullable: true, optional: true })
    contactName?: string = null;

    @Of(() => Boolean)
    active = false;

    @Of(() => Boolean)
    isPrimary = false;

    @Of(() => Boolean)
    setPrimary = false;

    @Of(() => Boolean)
    hideDetails = false;

    static of = Entity.of<CardProfile>();
}
