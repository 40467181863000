import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserInfo } from '@core/models';
import { SharedStorageKeys } from '@shared/models';
import { UrlService } from '@shared/services';
import { Modules } from '@utils';

@Component({
    selector: 'app-upgrade-login-button',
    templateUrl: './upgrade-login-button.component.html',
    styleUrls: ['./upgrade-login-button.component.scss'],
})
export class UpgradeLoginButtonComponent implements OnInit {
    sharedStorageKeys = SharedStorageKeys;
    userInfo: UserInfo;

    @Input()
    module: Modules;

    upgradeRequired: boolean;
    loginRequired: boolean;

    constructor(private url: UrlService, private router: Router) {}

    ngOnInit(): void {
        this.userInfo = UserInfo.of(JSON.parse(localStorage.getItem(this.sharedStorageKeys.USER_INFO) || null));

        this.loginRequired = !this.userInfo.permissions[`${this.module}:book`];

        this.upgradeRequired =
            this.userInfo.upgrade[`${this.module}:book`].length &&
            !this.userInfo.upgrade[`${this.module}:book`].includes(this.userInfo.tierId);
    }

    handleLoginRedirect(): void {
        this.router.navigate([this.url.getUrl('login')]);
    }

    handleUpgradeRedirect(): void {
        this.router.navigate([this.url.getUrl('upgrade')]);
    }
}
