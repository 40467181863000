import { RouterModule, Routes } from '@angular/router';
import {
    AboutComponent,
    CancelInstructionsComponent,
    ContactComponent,
    HomeComponent,
    MembershipBookingComponent,
    PrivacyPolicyComponent,
    TermsAndConditionsComponent,
    UpgradeMembershipComponent,
} from '@core/components';
import { AuthGuardService, ModuleGuardService } from '@core/services';
import { NotFoundComponent, RedirectHandlerComponent } from '@shared/components';
import { I18nModuleResolverService, I18nRouteResolverService } from '@shared/services';

const routes: Routes = [
    {
        path: 'home',
        component: HomeComponent,
        data: {
            title: 'Home',
        },
    },

    {
        path: 'about',
        component: AboutComponent,
        data: {
            title: 'About Us',
        },
    },

    {
        path: 'contact',
        component: ContactComponent,
        data: {
            title: 'Contact Us',
        },
    },

    {
        path: 'privacy',
        component: PrivacyPolicyComponent,
        data: {
            title: 'Privacy Policy',
        },
    },

    {
        path: 'terms',
        component: TermsAndConditionsComponent,
        data: {
            title: 'Terms and Conditions',
        },
    },

    {
        path: 'itinerary/checkReqCancel/:id',
        redirectTo: 'cancel-instructions',
    },
    {
        path: 'cancel-instructions',
        component: CancelInstructionsComponent,
        data: {
            title: 'Cancel Instructions',
        },
    },
    {
        path: 'upgrade',
        component: UpgradeMembershipComponent,
        data: {
            title: 'Upgrade Membership',
        },
    },
    {
        path: 'upgrade/checkout',
        component: MembershipBookingComponent,
        data: {
            page: 'membership-checkout',
            title: 'Upgrade Membership - Checkout',
        },
        resolve: {
            keys: I18nRouteResolverService,
        },
    },
    {
        path: 'account',
        canActivate: [AuthGuardService],
        resolve: { keys: I18nModuleResolverService },
        loadChildren: () => import('@account/account.module').then((m) => m.AccountModule),
    },

    {
        path: 'offers',
        canActivate: [AuthGuardService],
        resolve: { keys: I18nModuleResolverService },
        loadChildren: () => import('@offers/offers.module').then((m) => m.OffersModule),
    },

    {
        path: 'hotels',
        canActivate: [AuthGuardService, ModuleGuardService],
        data: { module: 'hotels' },
        resolve: { keys: I18nModuleResolverService },
        loadChildren: () => import('@hotels/hotels.module').then((m) => m.HotelsModule),
    },

    {
        path: 'flights',
        canActivate: [AuthGuardService, ModuleGuardService],
        data: { module: 'flights' },
        resolve: { keys: I18nModuleResolverService },
        loadChildren: () => import('@flights/flights.module').then((m) => m.FlightsModule),
    },

    {
        path: 'cars',
        canActivate: [AuthGuardService, ModuleGuardService],
        data: { module: 'cars' },
        resolve: { keys: I18nModuleResolverService },
        loadChildren: () => import('@cars/cars.module').then((m) => m.CarsModule),
    },

    {
        path: 'cruises',
        canActivate: [AuthGuardService, ModuleGuardService],
        data: { module: 'cruises' },
        resolve: { keys: I18nModuleResolverService },
        loadChildren: () => import('@cruises/cruises.module').then((m) => m.CruisesModule),
    },

    {
        path: 'weeks',
        canActivate: [AuthGuardService, ModuleGuardService],
        data: { module: 'weeks' },
        resolve: { keys: I18nModuleResolverService },
        loadChildren: () => import('@weeks/weeks.module').then((m) => m.WeeksModule),
    },

    {
        path: 'activities',
        canActivate: [AuthGuardService, ModuleGuardService],
        data: { module: 'activities' },
        resolve: { keys: I18nModuleResolverService },
        loadChildren: () => import('@activities/activities.module').then((m) => m.ActivitiesModule),
    },

    {
        path: 'cards',
        canActivate: [AuthGuardService, ModuleGuardService],
        data: { module: 'ecards' },
        resolve: { keys: I18nModuleResolverService },
        loadChildren: () => import('@cards/cards.module').then((m) => m.CardsModule),
    },

    {
        path: 'rewards',
        canActivate: [AuthGuardService, ModuleGuardService],
        data: { module: 'rewards' },
        resolve: { keys: I18nModuleResolverService },
        loadChildren: () => import('@rewards/rewards.module').then((m) => m.RewardsModule),
    },

    {
        path: 'escapes',
        canActivate: [AuthGuardService, ModuleGuardService],
        data: { module: 'escapes' },
        resolve: { keys: I18nModuleResolverService },
        loadChildren: () => import('@escapes/escapes.module').then((m) => m.EscapesModule),
    },

    { path: '', component: RedirectHandlerComponent, pathMatch: 'full' },
    {
        path: '**',
        component: NotFoundComponent,
        pathMatch: 'full',
        data: {
            title: 'Page not found',
        },
    },
];

export const AppRoutes = RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top',
    relativeLinkResolution: 'legacy',
    anchorScrolling: 'enabled',
});
