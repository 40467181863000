import { AssetValue, Rate } from '@shared/models';

import { RateCancellationPolicy } from './rate-cancellation-policy';

export class Room {
    id: string;
    name: string;
    sleeps: number;
    images: string[];
    description: string;
    amenities: string;
    rates: HotelRate[];
    invsrcId: number;
}

export class HotelRate extends Rate {
    rooms: number;
    boardCode: string;
    cancellationPolicy: RateCancellationPolicy;
    earns: AssetValue;
    earnDelayDate: string;
    retailPrice: AssetValue;
    tax: AssetValue;
    taxIncluded: boolean;
    youPay: AssetValue;
    youSave: AssetValue;
    youSavePct: number;
    bookingFee: AssetValue;
    providerTax: AssetValue;
    hotelFees: AssetValue[];
    includedFees: AssetValue[];
    optionalFees: AssetValue[];
    totalDueAtHotelCode: string;
    totalDueAtHotelValue: number;
    originalTotalDueAtHotelCode: string;
    originalTotalDueAtHotelValue: number;

    youSavePercentage?: number;

    constructor() {
        super();
    }
}

export function getBoardCodeIcon(boardCode: string): string {
    switch (boardCode) {
        case 'RO':
            return 'bed';
        case 'BB':
        case 'CB':
            return 'breakfast';
        case 'HB':
        case 'FB':
            return 'utensils';
        case 'AI':
            return 'glass';
    }
}
