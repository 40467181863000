import { AssetValue } from '@shared/models';
import { Entity, Of } from '@utils';

@Entity
export class TierDetails {
    @Of(() => Number)
    id: number;

    @Of(() => String)
    name: string;

    @Of(() => AssetValue)
    price: AssetValue;

    @Of(() => AssetValue)
    pointsBonus: AssetValue;

    @Of(() => String)
    membershipValidUntil: string;

    @Of(() => AssetValue)
    recurringPrice: AssetValue;

    static of = Entity.of<TierDetails>();
}
