<div class="login-footer-wrapper flex-container justify-content-space-between">
    <span
        [innerHtml]="siteBranding?.uiConfig?.siteOptions?.copyright | sanitizeHtml"
        class="copyright hide-below-xs"
    ></span>
    <div class="footer-static-menu-wrapper flex-container">
        <a class="link footer-link" [routerLink]="url.getUrl('about')">
            <span [attr.data-i18n-code]="'nav.about.label'"></span>
        </a>
        <a class="link footer-link margin-left-50 margin-sm-left-15" [routerLink]="url.getUrl('contact')">
            <span [attr.data-i18n-code]="'nav.contact.label'"></span>
        </a>
        <a class="link footer-link margin-left-50 margin-sm-left-15" [routerLink]="url.getUrl('privacy')">
            <span [attr.data-i18n-code]="'nav.privacy.label'"></span>
        </a>
        <a class="link footer-link margin-left-50 margin-sm-left-15" [routerLink]="url.getUrl('terms')">
            <span [attr.data-i18n-code]="'nav.terms.label'"></span>
        </a>
    </div>
</div>
