import { Entity, Of } from '@utils';

@Entity
export class TravelerProfile {
    @Of(() => Number, { nullable: true, optional: true })
    id?: number = null;

    @Of(() => Number, { nullable: true, optional: true })
    tpId?: number = null;

    @Of(() => String)
    firstName: string;

    @Of(() => String)
    lastName: string;

    @Of(() => String)
    email: string;

    @Of(() => String)
    phone: string;

    @Of(() => String, { nullable: true, optional: true })
    address1?: string = null;

    @Of(() => String, { nullable: true, optional: true })
    city?: string = null;

    @Of(() => String, { nullable: true, optional: true })
    postalCode?: string = null;

    @Of(() => Number, { nullable: true, optional: true })
    provinceId?: number = null;

    @Of(() => String, { nullable: true, optional: true })
    provinceName: string = null;

    @Of(() => Number, { nullable: true, optional: true })
    countryId?: number = null;

    @Of(() => String, { nullable: true, optional: true })
    dob?: string = null;

    @Of(() => String, { nullable: true, optional: true })
    dateOfBirth?: string = null;

    @Of(() => String, { nullable: true, optional: true })
    middleName?: string = null;

    @Of(() => Number, { nullable: true, optional: true })
    ffpId?: number = null;

    @Of(() => String, { nullable: true, optional: true })
    ffpNo?: string = null;

    @Of(() => String, { nullable: true, optional: true })
    gender?: string = null;

    @Of(() => String, { nullable: true, optional: true })
    psgType?: string = null;

    @Of(() => Number, { nullable: true, optional: true })
    passportCountryId?: number = null;

    @Of(() => String, { nullable: true, optional: true })
    passportNumber?: string = null;

    @Of(() => String, { nullable: true, optional: true })
    passportIssueDate?: string = null;

    @Of(() => String, { nullable: true, optional: true })
    passportExpDate?: string = null;

    @Of(() => String, { nullable: true, optional: true })
    redressNumber?: string = null;

    @Of(() => Number, { nullable: true, optional: true })
    knownTravelerNumber?: number = null;

    @Of(() => String, { nullable: true, optional: true })
    type?: string = null;

    @Of(() => Boolean)
    active = false;

    @Of(() => Boolean)
    disabled = false;

    @Of(() => Boolean)
    available = true;

    @Of(() => Boolean)
    hidden = false;

    @Of(() => Boolean)
    saveTraveler = false;

    @Of(() => Boolean)
    updateTraveler = false;

    @Of(() => Boolean)
    isPrimary = false;

    @Of(() => Boolean)
    isSecondary = false;

    @Of(() => String)
    ticketNumbers = '';

    @Of(() => String)
    ageBand?: string;

    @Of(() => String)
    bandId?: string;

    static of = Entity.of<TravelerProfile>();
}
