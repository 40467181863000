<div [class.hint]="hint" [formGroup]="group">
    <ng-container *ngIf="autoFocus; else noFocus">
        <mat-form-field
            appearance="fill"
            [floatLabel]="floatLabel"
            [class.remove-validation-space]="removeValidationSpace"
        >
            <mat-label *ngIf="label && translate" [attr.data-i18n-code]="label"></mat-label>
            <mat-label *ngIf="label && !translate">{{ label }}</mat-label>

            <input
                *ngIf="!required"
                appAutofocus
                appDisableControl
                matInput
                [attr.tabindex]="tabindex"
                [attr.aria-required]="required"
                [attr.aria-describedby]="name + '-error'"
                [attr.aria-invalid]="errorMessages"
                [type]="inputType"
                [placeholder]="placeholder"
                [formControlName]="name"
                [disableControl]="disabled"
                [readonly]="readonly"
                [step]="step"
                [attr.autocomplete]="autocomplete"
            />

            <input
                *ngIf="required"
                appAutofocus
                appDisableControl
                matInput
                required
                [attr.tabindex]="tabindex"
                [attr.aria-required]="required"
                [attr.aria-describedby]="name + '-error'"
                [attr.aria-invalid]="errorMessages"
                [type]="inputType"
                [placeholder]="placeholder"
                [formControlName]="name"
                [disableControl]="disabled"
                [readonly]="readonly"
                [step]="step"
                [attr.autocomplete]="autocomplete"
            />

            <span matSuffix>
                <ng-content select="[inputSuffix]"></ng-content>
            </span>

            <mat-hint *ngIf="hint && !translate">
                <span>{{ hint }}</span>
            </mat-hint>
            <mat-hint *ngIf="hint && translate && !hintParams">
                <span [attr.data-i18n-code]="hint"></span>
            </mat-hint>
            <mat-hint *ngIf="hint && translate && hintParams">
                <span
                    [attr.data-i18n-code]="hint"
                    [attr.data-i18n-params]="hintParams"
                    [attr.data-i18n-withParams]="'inputHint'"
                    [attr.data-i18n-persist]="true"
                ></span>
            </mat-hint>

            <mat-error role="alert" [attr.id]="name + '-error'" *ngIf="errorMessages">
                {{ errorMessages }}
            </mat-error>
        </mat-form-field>
    </ng-container>

    <ng-template #noFocus>
        <mat-form-field
            appearance="fill"
            [floatLabel]="floatLabel"
            [class.remove-validation-space]="removeValidationSpace"
        >
            <mat-label *ngIf="label && translate" [attr.data-i18n-code]="label"></mat-label>
            <mat-label *ngIf="label && !translate">{{ label }}</mat-label>

            <input
                *ngIf="!required"
                matInput
                appDisableControl
                class="app-form-element"
                [attr.tabindex]="tabindex"
                [attr.aria-describedby]="name + '-error'"
                [attr.aria-invalid]="errorMessages"
                [type]="inputType"
                [placeholder]="placeholder"
                [formControlName]="name"
                [disableControl]="disabled"
                [readonly]="readonly"
                [step]="step"
                [attr.autocomplete]="autocomplete"
            />

            <input
                *ngIf="required"
                matInput
                appDisableControl
                required
                class="app-form-element"
                [attr.tabindex]="tabindex"
                [attr.aria-describedby]="name + '-error'"
                [attr.aria-invalid]="errorMessages"
                [type]="inputType"
                [placeholder]="placeholder"
                [formControlName]="name"
                [disableControl]="disabled"
                [readonly]="readonly"
                [step]="step"
                [attr.autocomplete]="autocomplete"
            />

            <span matSuffix>
                <ng-content select="[inputSuffix]"></ng-content>
            </span>

            <mat-hint *ngIf="hint && !translate">
                <span>{{ hint }}</span>
            </mat-hint>
            <mat-hint *ngIf="hint && translate && !hintParams">
                <span [attr.data-i18n-code]="hint"></span>
            </mat-hint>
            <mat-hint *ngIf="hint && translate && hintParams">
                <span
                    [attr.data-i18n-code]="hint"
                    [attr.data-i18n-params]="hintParams"
                    [attr.data-i18n-withParams]="'inputHint'"
                    [attr.data-i18n-persist]="true"
                ></span>
            </mat-hint>

            <mat-error role="alert" [attr.id]="name + '-error'" *ngIf="errorMessages">
                {{ errorMessages }}
            </mat-error>
        </mat-form-field>
    </ng-template>
</div>
