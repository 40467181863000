export const pageTitles = {
    landing: 'Hotels',
    search: 'Hotel Search Results',
    book: 'Hotel Guest & Payment Information',
    bookConfirmation: 'Hotel Transaction Completed',
    bookConfirmationProfile: 'Hotel Transaction Confirmation',
    bookReceipt: 'Hotel Transaction Receipt',
    cancellation: 'Hotel Cancellation',
    cancellationConfirmation: 'Hotel Cancellation Confirmation',
    details: 'Hotel Room Details & Selection',
};
