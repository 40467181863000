import { Component, Inject, SecurityContext } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

export interface IframeDialogData {
    title: string;
    iframeUrl: string;
}

@Component({
    selector: 'app-iframe-dialog',
    templateUrl: './iframe-dialog.component.html',
    styleUrls: ['./iframe-dialog.component.scss'],
})
export class IframeDialogComponent {
    sanitizedUrl: SafeResourceUrl;

    constructor(
        public dialogRef: MatDialogRef<IframeDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IframeDialogData,
        private sanitizer: DomSanitizer
    ) {
        this.sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
            this.sanitizer.sanitize(SecurityContext.URL, this.data.iframeUrl)
        );
    }
}
