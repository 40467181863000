import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import {
    BestDeal,
    BookPrice,
    CardProfile,
    ComputePriceInput,
    DataTemplate,
    FeaturedDestination,
    GenericSearchResponse,
    PaginatedSearchResponse,
    TravelerProfile,
} from '@shared/models';
import {
    BookRequest,
    BookResponse,
    Destinations,
    DestinationSearch,
    Room,
    SavedSearch,
    SearchRequest,
    Week,
    WeekDetails,
} from '@weeks/models';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { siteOffersGroupTypes } from 'src/app/app.globals';

@Injectable({
    providedIn: 'root',
})
export class WeeksService {
    constructor(private http: HttpClient) {}

    getFeaturedDestinations(): Observable<DataTemplate<GenericSearchResponse<FeaturedDestination>>> {
        const url = `${environment.corePath}/weeks/offers/${siteOffersGroupTypes.FEATURED_DESTINATIONS}`;

        return this.http.get(url) as Observable<
            // eslint-disable-next-line @typescript-eslint/indent
            DataTemplate<GenericSearchResponse<FeaturedDestination>>
        >;
    }

    getBestDeals(): Observable<DataTemplate<GenericSearchResponse<BestDeal>>> {
        const url = `${environment.corePath}/weeks/offers/${siteOffersGroupTypes.BEST_DEALS}`;

        return this.http.get(url) as Observable<
            // eslint-disable-next-line @typescript-eslint/indent
            DataTemplate<GenericSearchResponse<BestDeal>>
        >;
    }

    getDestinations(): Observable<DataTemplate<Destinations>> {
        const url = `${environment.weeksPath}/rciLocations`;

        return this.http.get(url) as Observable<
            // eslint-disable-next-line @typescript-eslint/indent
            DataTemplate<Destinations>
        >;
    }

    search(request: SearchRequest): Observable<DataTemplate<PaginatedSearchResponse<Week>>> {
        const url = `${environment.weeksPath}/search`;

        return this.http.post(url, request) as Observable<
            // eslint-disable-next-line @typescript-eslint/indent
            DataTemplate<PaginatedSearchResponse<Week>>
        >;
    }

    getSavedSearch(savedSearchCode: string, persist = false): Observable<DataTemplate<SavedSearch>> {
        const url = `${environment.weeksPath}/search/${savedSearchCode}`;

        if (persist) {
            return this.http.post(url, null).pipe(
                tap((data) => {
                    sessionStorage.setItem(`weeks_saved_search_${savedSearchCode}`, JSON.stringify(data));
                }),
                map((data) => data as DataTemplate<SavedSearch>)
            ) as Observable<DataTemplate<SavedSearch>>;
        } else {
            const savedSearch = JSON.parse(
                sessionStorage.getItem(`weeks_saved_search_${savedSearchCode}`)
            ) as DataTemplate<SavedSearch>;

            if (savedSearch) {
                sessionStorage.removeItem(`weeks_saved_search_${savedSearchCode}`);
                return of(savedSearch as DataTemplate<SavedSearch>);
            } else {
                return this.http.post(url, null) as Observable<
                    // eslint-disable-next-line @typescript-eslint/indent
                    DataTemplate<SavedSearch>
                >;
            }
        }
    }

    getRecentSearches(limit = 3): Observable<DataTemplate<GenericSearchResponse<SearchRequest>>> {
        const url = `${environment.weeksPath}/recentSearches?limit=${limit}`;

        return this.http.get(url) as Observable<DataTemplate<GenericSearchResponse<SearchRequest>>>;
    }

    searchDestinations(criteria: string): Observable<DataTemplate<DestinationSearch>> {
        const url = `${environment.weeksPath}/locations`;

        const params = new HttpParams().set('term', criteria);

        return this.http.get(url, { params }) as Observable<
            // eslint-disable-next-line @typescript-eslint/indent
            DataTemplate<DestinationSearch>
        >;
    }

    getDetails(weekId: string): Observable<DataTemplate<WeekDetails>> {
        const url = `${environment.weeksPath}/${weekId}`;

        return this.http.get(url) as Observable<
            // eslint-disable-next-line @typescript-eslint/indent
            DataTemplate<WeekDetails>
        >;
    }

    getRooms(weekId: string): Observable<DataTemplate<Room[]>> {
        const url = `${environment.weeksPath}/${weekId}/rooms`;

        return this.http.get(url) as Observable<DataTemplate<Room[]>>;
    }

    getTrustYouUrl(weekId: string): Observable<DataTemplate<any>> {
        const url = `${environment.weeksPath}/${weekId}/trustyou`;

        return this.http.get(url) as Observable<DataTemplate<any>>;
    }

    getTravelerProfiles(): Observable<DataTemplate<TravelerProfile[]>> {
        const url = `${environment.weeksPath}/travprofiles`;

        return this.http.get(url) as Observable<
            // eslint-disable-next-line @typescript-eslint/indent
            DataTemplate<TravelerProfile[]>
        >;
    }

    getCardProfiles(): Observable<DataTemplate<CardProfile[]>> {
        const url = `${environment.weeksPath}/ccardprofiles`;

        return this.http.get(url) as Observable<
            // eslint-disable-next-line @typescript-eslint/indent
            DataTemplate<CardProfile[]>
        >;
    }

    computePrice(rateId: string, options?: ComputePriceInput): Observable<DataTemplate<BookPrice>> {
        const url = `${environment.weeksPath}/rates/${rateId}/price`;

        let params = new HttpParams();

        if (options?.payPoints || options?.payPoints === 0) {
            params = params.append('payPoints', options.payPoints.toString());
        }

        if (options?.useCredit) {
            params = params.append('useCredit', 'true');
        }

        return this.http.get(url, { params }) as Observable<
            // eslint-disable-next-line @typescript-eslint/indent
            DataTemplate<BookPrice>
        >;
    }

    hold(rateId: string): Observable<any> {
        const url = `${environment.weeksPath}/rates/${rateId}/hold`;

        return this.http.get(url) as Observable<
            // eslint-disable-next-line @typescript-eslint/indent
            DataTemplate<any>
        >;
    }

    book(request: BookRequest): Observable<DataTemplate<BookResponse>> {
        const url = `${environment.weeksPath}/book`;

        return this.http.post(url, request) as Observable<
            // eslint-disable-next-line @typescript-eslint/indent
            DataTemplate<BookResponse>
        >;
    }
}
