import { AccountTravelerProfile } from '@account/models';
import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class SwitchTravelerService {
    traveler: EventEmitter<AccountTravelerProfile> = new EventEmitter<AccountTravelerProfile>();
    travelerAdded: EventEmitter<AccountTravelerProfile> = new EventEmitter<AccountTravelerProfile>();
    travelerUpdated: EventEmitter<AccountTravelerProfile> = new EventEmitter<AccountTravelerProfile>();
}
