import { Entity, Of } from '@utils';

@Entity
export class ComputePriceInput {
    @Of(() => Number, { optional: true })
    payPoints = 0;

    @Of(() => Boolean)
    useCredit = true;

    @Of(() => String, { nullable: true, optional: true })
    code?: string = null;

    static of = Entity.of<ComputePriceInput>();
}
