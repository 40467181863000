import { Entity, Of } from '@utils';

@Entity
export class AccountSettings {
    @Of(() => String)
    language = '';

    @Of(() => String)
    currency = '';

    @Of(() => Number, { optional: true })
    timezone?: number;

    static of = Entity.of<AccountSettings>();
}
