import { Entity, Of } from '@utils';

@Entity
export class PrimaryMember {
    @Of(() => String)
    firstName: string;

    @Of(() => String)
    lastName: string;

    @Of(() => String)
    email: string;

    @Of(() => String)
    phone: string;

    @Of(() => String)
    mobilePhone: string;

    @Of(() => String)
    workPhone: string;

    @Of(() => String)
    address1: string;

    @Of(() => String)
    city: string;

    @Of(() => String)
    postalCode: string;

    @Of(() => Number, { optional: true })
    provinceId: number;

    @Of(() => String)
    provinceName: string;

    @Of(() => Number)
    countryId: number;

    static of = Entity.of<PrimaryMember>();
}
