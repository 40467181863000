import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TravelerProfile } from '@shared/models';
import { DisableTravelerProfileService } from '@shared/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-traveler-profiles',
    templateUrl: './traveler-profiles.component.html',
    styleUrls: ['./traveler-profiles.component.scss'],
})
export class TravelerProfilesComponent implements OnInit, OnDestroy {
    @Input()
    set restrictTraveler(value: boolean) {
        this.restrictedTraveler = value;
    }

    @Input()
    multipleTravelers: boolean;

    @Input()
    travelerProfiles: TravelerProfile[] = [];

    @Input()
    newTravelerLabel = 'core.labels.newTraveler';

    @Input()
    currentTravelerId: number;

    @Input()
    multipleTravelerIndex: number;

    @Output()
    selectedProfile: EventEmitter<TravelerProfile> = new EventEmitter<TravelerProfile>();

    newTraveler = true;
    restrictedTraveler: boolean;

    private unsubscribe: Subject<void> = new Subject<void>();

    constructor(private disableTravelerProfileService: DisableTravelerProfileService) {}

    ngOnInit(): void {
        this.travelerProfiles.forEach((t) => {
            t.available = true;

            if (this.restrictedTraveler && this.multipleTravelerIndex === 0 && !t.isPrimary && !t.isSecondary) {
                t.available = false;
            }
        });

        if (!this.multipleTravelerIndex) {
            this.emitTravelerProfile(this.travelerProfiles[0]);
        }

        if (!this.restrictTraveler && this.multipleTravelers && this.travelerProfiles?.length) {
            this.travelerProfiles[0].disabled = true;
        }

        this.disableTravelerProfileService.enable.pipe(takeUntil(this.unsubscribe)).subscribe((response: number) => {
            if (response) {
                this.newTraveler = true;

                this.travelerProfiles.forEach((travelerProfile: TravelerProfile) => {
                    if (travelerProfile.id === response) {
                        travelerProfile.disabled = false;
                        travelerProfile.active = true;
                        this.newTraveler = false;
                    } else {
                        travelerProfile.active = false;
                    }
                });
            }
        });

        this.disableTravelerProfileService.disable.pipe(takeUntil(this.unsubscribe)).subscribe((response: number) => {
            if (response) {
                this.travelerProfiles.some((travelerProfile: TravelerProfile) => {
                    if (travelerProfile.id === response) {
                        travelerProfile.disabled = true;
                        return true;
                    }
                });
            }
        });

        this.disableTravelerProfileService.switch
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((response: { prev: number; next: number }) => {
                if (response) {
                    this.travelerProfiles.forEach((travelerProfile: TravelerProfile) => {
                        if (travelerProfile.id === response.next) {
                            travelerProfile.disabled = true;
                        }
                        if (travelerProfile.id === response.prev) {
                            travelerProfile.disabled = false;
                        }
                    });
                }
            });

        this.disableTravelerProfileService.target.pipe(takeUntil(this.unsubscribe)).subscribe((response: number) => {
            if (response) {
                this.travelerProfiles.some((travelerProfile: TravelerProfile) => {
                    if (travelerProfile.id === response) {
                        travelerProfile.disabled = true;
                        return true;
                    }
                });

                this.emitTravelerProfile(null);
            }
        });

        this.disableTravelerProfileService.checkDisable.emit();
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    emitTravelerProfile(profile: TravelerProfile): void {
        if (profile && (profile.disabled || !profile.available)) {
            return;
        }

        this.travelerProfiles.forEach((t) => (t.active = false));

        if (!profile) {
            this.newTraveler = true;
        } else {
            this.newTraveler = false;
            profile.active = true;
        }

        this.selectedProfile.emit(profile);

        if (this.multipleTravelers) {
            this.disableTravelerProfileService.switch.emit({
                prev: this.currentTravelerId,
                next: profile?.id,
            });
        }
    }
}
