import { SimplePagination } from '@shared/models/service-helpers/simple-pagination';

export class PaginatedSearchResponse<T> {
    apiSessionId: string;
    items: T[] = [];
    pagination: SimplePagination;

    static of =
        <T>(EntityMapper: (data: Partial<unknown>) => unknown) =>
        (object: Partial<PaginatedSearchResponse<T>>) => {
            const items = object.items;

            if (items?.length) {
                Object.assign(
                    items,
                    items.map((item) => EntityMapper(item as Partial<T>))
                );
            }

            return { ...object, items };
        };
}
