<mat-icon [svgIcon]="'flight'" class="module-icon"></mat-icon>
<div>
    <p class="primary-data margin-bottom-5">
        <span [attr.data-i18n-code]="'core.labels.from'"></span>{{ ' ' }} <span>{{ search.departFromName }}</span
        >{{ ' ' }} <span [attr.data-i18n-code]="'core.labels.to'"></span>{{ ' ' }}
        <span>{{ search.arriveToName }}</span>
    </p>

    <p class="secondary-data margin-bottom-5">
        {{ search.departDate | date: 'EE, dd MMM'
        }}<ng-container *ngIf="search.returnDate"> - {{ search.returnDate | date: 'EE, dd MMM' }}</ng-container>
    </p>

    <div class="secondary-data flex-container align-items-center">
        <span
            class="text-capitalize"
            [attr.data-i18n-code]="'flights.search.roundTrip.label'"
            *ngIf="search.returnDate"
        ></span>
        <span
            class="text-capitalize"
            [attr.data-i18n-code]="'flights.search.oneway.label'"
            *ngIf="!search.returnDate"
        ></span>

        <span class="bullet margin-left-5 margin-right-5"></span>

        <span
            >{{ adults }}
            <span class="text-lowercase">
                <span [class.hide]="adults !== 1" [attr.data-i18n-code]="'core.labels.adult'"></span>
                <span [class.hide]="adults === 1" [attr.data-i18n-code]="'core.labels.adults'"></span>
            </span>
        </span>

        <span appTranslate *ngIf="children > 0"
            >, {{ children }}
            <span class="text-lowercase">
                <span [class.hide]="children !== 1" [attr.data-i18n-code]="'core.labels.child'"></span>
                <span [class.hide]="children === 1" [attr.data-i18n-code]="'core.labels.children'"></span>
            </span>
        </span>
    </div>
</div>
