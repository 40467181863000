import { SearchRequest } from '../request/search-request';

import { Car } from './car';
import { SearchFilters } from './search-filters';

export class SavedSearch {
    items: Car[];
    searchParams: SearchRequest;
    filters: SearchFilters;
}
