import { Entity, Of } from '@utils';

import { I18nContent } from './i18n-content';

@Entity
export class ModuleDialogContent {
    @Of(() => String)
    image: string;

    @Of(() => I18nContent)
    title: I18nContent;

    @Of(() => [I18nContent])
    content: I18nContent[] = [];

    @Of(() => [String])
    partners?: string[];

    static of = Entity.of<ModuleDialogContent>();
}
