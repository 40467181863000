import { Entity, Of } from "@utils";

@Entity
export class SimplePagination {
    @Of(() => Number)
    seek = 0;

    @Of(() => Number)
    pageSize = 10;

    @Of(() => Boolean)
    hasMore = false;

    static of = Entity.of<SimplePagination>();
}
