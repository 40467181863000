<div [class.hint]="hint" [formGroup]="group">
    <mat-form-field appearance="fill">
        <mat-label *ngIf="label">{{ label }}</mat-label>
        <textarea
            *ngIf="!required"
            matInput
            class="app-form-element"
            [placeholder]="placeholder"
            [maxlength]="maxChar"
            [formControlName]="name"
            [readonly]="readonly"
            [rows]="rows"
            [cdkTextareaAutosize]="autoSize"
            [attr.aria-describedby]="name + '-error'"
            [attr.aria-invalid]="errorMessages"
        ></textarea>

        <textarea
            *ngIf="required"
            matInput
            required
            class="app-form-element"
            [placeholder]="placeholder"
            [maxlength]="maxChar"
            [formControlName]="name"
            [readonly]="readonly"
            [rows]="rows"
            [cdkTextareaAutosize]="autoSize"
            [attr.aria-describedby]="name + '-error'"
            [attr.aria-invalid]="errorMessages"
        ></textarea>

        <span matSuffix>
            <ng-content select="[textareaSuffix]"></ng-content>
        </span>
        <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
        <mat-error role="alert" [attr.id]="name + '-error'" *ngIf="errorMessages">
            {{ errorMessages }}
        </mat-error>
        <mat-hint *ngIf="maxChar" align="end">{{ group.controls[name].value?.length || 0 }}/{{ maxChar }}</mat-hint>
        <mat-hint *ngIf="charCount" align="end">{{ group.controls[name].value?.length || 0 }} characters</mat-hint>
    </mat-form-field>
</div>
