import { AbstractControl, ValidatorFn } from '@angular/forms';

export function mastercardLength(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null =>
        control.value?.length === 16 ? null : { invalid: true };
}

export function mastercardStartsWith(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null =>
        control.value?.startsWith('5') && ['1', '2', '3', '4', '5'].includes(control.value.charAt(1))
            ? null
            : { invalid: true };
}
