import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { LoaderService } from '@core/services';
import { I18nService } from '@shared/services/i18n/i18n.service';
import { of, Subject } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class I18nRouteResolverService implements Resolve<any>, OnDestroy {
    private unsubscribe: Subject<void> = new Subject<void>();

    constructor(private i18nService: I18nService, private loader: LoaderService) {}

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    resolve(route: ActivatedRouteSnapshot): any {
        let keysArr: string[] = [];
        const target: string = route.data.page;

        switch (target) {
            case 'account-membership':
                keysArr = this.i18nService.getAccountMembershipKeys();
                break;
            case 'account-trips':
                keysArr = this.i18nService.getAccountTripsKeys();
                break;
            case 'account-travelers':
                keysArr = this.i18nService.getAccountTravelersKeys();
                break;
            case 'account-payments':
                keysArr = this.i18nService.getAccountPaymentsKeys();
                break;
            case 'account-purchases':
                keysArr = this.i18nService.getAccountPurchasesKeys();
                break;
            case 'account-settings':
                keysArr = this.i18nService.getAccountSettingsKeys();
                break;
            case 'hotel-details':
                keysArr = [...this.i18nService.getHotelDetailsKeys(), ...this.i18nService.getHotelRoomKeys()];
                break;
            case 'hotel-book':
                keysArr = this.i18nService.getHotelBookingKeys();
                break;
            case 'hotel-book-confirmation':
                keysArr = this.i18nService.getHotelBookingConfirmationKeys();
                break;
            case 'hotel-book-cancel':
                keysArr = this.i18nService.getHotelBookingCancelKeys();
                break;
            case 'flight-book':
                keysArr = this.i18nService.getFlightBookingKeys();
                break;
            case 'flight-book-confirmation':
                keysArr = this.i18nService.getFlightBookingConfirmationKeys();
                break;
            case 'flight-book-cancel':
                keysArr = this.i18nService.getFlightBookingCancelKeys();
                break;
            case 'car-book':
                keysArr = this.i18nService.getCarBookingKeys();
                break;
            case 'car-book-confirmation':
                keysArr = this.i18nService.getCarBookingConfirmationKeys();
                break;
            case 'car-book-cancel':
                keysArr = this.i18nService.getCarBookingCancelKeys();
                break;
            case 'cruise-details':
                keysArr = this.i18nService.getCruiseDetailsKeys();
                break;
            case 'cruise-book':
                keysArr = this.i18nService.getCruiseBookingKeys();
                break;
            case 'cruise-book-confirmation':
                keysArr = this.i18nService.getCruiseBookingConfirmationKeys();
                break;
            case 'cruise-book-cancel':
                keysArr = this.i18nService.getCruiseBookingCancelKeys();
                break;
            case 'week-details':
                keysArr = this.i18nService.getWeekDetailsKeys();
                break;
            case 'week-book':
                keysArr = this.i18nService.getWeekBookingKeys();
                break;
            case 'week-book-confirmation':
                keysArr = this.i18nService.getWeekBookingConfirmationKeys();
                break;
            case 'week-book-cancel':
                keysArr = this.i18nService.getWeekBookingCancelKeys();
                break;
            case 'activity-details':
                keysArr = this.i18nService.getActivityDetailsKeys();
                break;
            case 'activity-book':
                keysArr = this.i18nService.getActivityBookingKeys();
                break;
            case 'activity-book-confirmation':
                keysArr = this.i18nService.getActivityBookingConfirmationKeys();
                break;
            case 'activity-book-cancel':
                keysArr = this.i18nService.getActivityBookingCancelKeys();
                break;
            case 'card-book':
                keysArr = this.i18nService.getCardBookingKeys();
                break;
            case 'card-book-confirmation':
                keysArr = this.i18nService.getCardBookingConfirmationKeys();
                break;
            case 'reward-purchase':
                keysArr = this.i18nService.getRewardsPurchaseKeys();
                break;
            case 'rewards-user':
                keysArr = this.i18nService.getRewardsUserKeys();
                break;
            case 'rewards-redeem-code':
                keysArr = this.i18nService.getRewardsRedeemCodeKeys();
                break;
            case 'offer-details':
                keysArr = this.i18nService.getOfferDetailsKeys();
                break;
            case 'offer-request':
                keysArr = this.i18nService.getOfferRequestReservationKeys();
                break;
            case 'offer-book':
                keysArr = this.i18nService.getOfferBookingKeys();
                break;
            case 'offer-book-confirmation':
                keysArr = this.i18nService.getOfferBookingConfirmationKeys();
                break;
            case 'escape-details':
                keysArr = this.i18nService.getEscapeDetailsKeys();
                break;
            case 'escape-book':
                keysArr = this.i18nService.getEscapeBookingKeys();
                break;
            case 'escape-book-confirmation':
                keysArr = this.i18nService.getEscapeBookingConfirmationKeys();
                break;
            case 'escape-book-cancel':
                keysArr = this.i18nService.getEscapeBookingCancelKeys();
                break;
            case 'book-cancel-confirmation':
                keysArr = this.i18nService.getBookingCancelConfirmationKeys();
                break;
            case 'membership-checkout':
                keysArr = this.i18nService.getMembershipBookingKeys();
                break;
            default:
                return true;
        }

        this.loader.show();

        return this.i18nService.preloadKeys(keysArr).pipe(
            take(1),
            mergeMap(() => {
                this.loader.hide();
                return of(true);
            })
        );
    }
}
