import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { NguCarouselConfig } from '@ngu/carousel';

@Component({
    selector: 'app-details-page-header',
    templateUrl: './details-page-header.component.html',
    styleUrls: ['./details-page-header.component.scss'],
})
export class DetailsPageHeaderComponent {
    @Input()
    images: string[];

    @Input()
    showOverview: boolean;

    @Input()
    simpleContent: boolean;

    headerCarouselTileConfig: NguCarouselConfig = {
        grid: { xs: 1, sm: 1, md: 1, lg: 2, all: 0 },
        speed: 250,
        point: {
            visible: true,
        },
        touch: false,
        loop: true,
        animation: 'lazy',
    };

    headerCarouselPrev: ElementRef;
    @ViewChild('headerCarouselPrev') set contentPrev(content: ElementRef) {
        this.headerCarouselPrev = content;
    }

    headerCarouselNext: ElementRef;
    @ViewChild('headerCarouselNext') set contentNext(content: ElementRef) {
        this.headerCarouselNext = content;
    }

    triggerHeaderCarouselNav(direction: string): void {
        switch (direction) {
            case 'prev':
                this.headerCarouselPrev.nativeElement.click();
                break;
            case 'next':
                this.headerCarouselNext.nativeElement.click();
                break;
        }
    }
}
