import { HotelsSearchType } from '@hotels/models/request/search-request';
import { Location } from '@shared/models';

export class CityDestination {
    id: string;
    label: string;
    location: Location;
    type: HotelsSearchType = HotelsSearchType.CITY;
    oaLocation: string;
}
