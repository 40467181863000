import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ModuleDialogContent } from '@shared/models';

@Component({
    selector: 'app-rewards-dialog',
    templateUrl: './rewards-dialog.component.html',
})
export class RewardsDialogComponent {
    content: ModuleDialogContent = {
        title: {
            value: {
                en: 'RAINTREE REWARDS',
                es: 'RAINTREE REWARDS (Recompensas)',
            },
        },
        image: 'https://images.leisureloyalty.com/myholidaypass/images/holiday_rewards.jpg',
        content: [
            {
                type: 'paragraph',
                value: {
                    en: 'You will be rewarded every time you transact with Raintree Rewards',
                    es: 'Usted será recompensado en cada vez que usted haga una transacción con Raintree Rewards.',
                },
            },
            {
                type: 'paragraph',
                value: {
                    en: 'Book a hotel stay, a cruise, a flight, or go shopping - these will all earn you valuable rewards that you can use to redeem against hotel nights, activities, shopping, and other benefits.',
                    es: 'Reservando una estancia de hotel, crucero, vuelo, o de compras – estas transacciones le darán valiosas recompensas que usted puede redimir y usar en noches de hotel, actividades, compras y otros beneficios.',
                },
            },
        ],
    };

    constructor(public dialogRef: MatDialogRef<RewardsDialogComponent>) {}
}
