export class BookingBillingInfo {
    firstName: string;
    lastName: string;
    address: string;
    city: string;
    country: string;
    state: string;
    zip: string;
    email: string;
    mobilePhone: string;
    homePhone?: string;
    workPhone?: string;
}
