import { Entity, Of } from '@utils';

import { EscapeDestination } from './escape-destination';

@Entity
export class SearchResponse {
    @Of(() => [EscapeDestination])
    items: EscapeDestination[] = [];

    static of = Entity.of<SearchResponse>();
}
