import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageContent } from '@core/models';
import { environment } from '@env/environment';
import { DataTemplate } from '@shared/models';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PagesService {
    constructor(private http: HttpClient) {}

    getPage(code: string): Observable<DataTemplate<PageContent>> {
        const url = `${environment.pagesPath}/${code}`;

        return this.http.get(url) as Observable<DataTemplate<PageContent>>;
    }
}
