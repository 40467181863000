import { Entity, Of } from '@utils';

@Entity
export class AssetValue {
    @Of(() => String, { optional: true })
    code?: string;

    @Of(() => Number, { optional: true })
    value?: number;

    @Of(() => String, { optional: true })
    type?: string;

    @Of(() => String, { optional: true })
    label?: string;

    @Of(() => String, { optional: true })
    fmtValue?: string;

    static of = Entity.of<AssetValue>();
}
