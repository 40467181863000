import { AfterViewInit, Component, Input } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { FloatLabelType } from '@angular/material/form-field';

@Component({
    selector: 'app-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss'],
})
export class InputComponent implements AfterViewInit {
    required: boolean;

    @Input()
    tabindex: number;

    @Input()
    hint: string;

    @Input()
    hintParams: string;

    @Input()
    group: UntypedFormGroup;

    @Input()
    label: string;

    @Input()
    errorMessages: string;

    @Input()
    inputType: string;

    @Input()
    placeholder: string;

    @Input()
    name: string;

    @Input()
    readonly: boolean;

    @Input()
    disabled: boolean;

    @Input()
    step: string;

    @Input()
    floatLabel: FloatLabelType;

    @Input()
    autoFocus: boolean;

    @Input()
    autocomplete: string;

    @Input()
    translate = true;

    @Input()
    removeValidationSpace: boolean;

    ngAfterViewInit(): void {
        if (
            typeof this.group.get(this.name).validator === 'function' &&
            this.group.get(this.name).validator({} as AbstractControl)?.required
        ) {
            this.required = true;
        }
    }
}
