import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SiteBranding, UserInfo } from '@core/models';
import { AuthService, LoaderService, UserAuthService } from '@core/services';
import { AllowedCardTypes, SharedStorageKeys } from '@shared/models';
import { DataTemplate } from '@shared/models/service-helpers/data-template';
import {
    CurrencyChangeService,
    HeaderService,
    I18nRefreshService,
    I18nService,
    LanguageChangeService,
    StorageService,
    UrlService,
} from '@shared/services';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

import { ConfirmCurrencyChangeDialogComponent } from './confirm-currency-change-dialog/confirm-currency-change-dialog.component';
import { RewardsDialogComponent } from './rewards-dialog/rewards-dialog.component';

@Component({
    selector: 'app-navigation-locales',
    templateUrl: './navigation-locales.component.html',
    styleUrls: ['./navigation-locales.component.scss'],
})
export class NavigationLocalesComponent implements OnInit, OnDestroy {
    @Input()
    onLogin: boolean;

    sharedStorageKeys = SharedStorageKeys;
    userInfo: UserInfo;
    siteBranding: SiteBranding;
    activeLanguage: string;
    activeCurrency: string;
    navTarget: string;

    isModalModules: boolean;

    showLanguages: boolean;
    showCurrencies: boolean;

    private unsubscribe: Subject<void> = new Subject();

    constructor(
        public url: UrlService,
        private i18nService: I18nService,
        private i18nRefreshService: I18nRefreshService,
        private loaderService: LoaderService,
        private currencyChange: CurrencyChangeService,
        private dialog: MatDialog,
        private router: Router,
        private headerService: HeaderService,
        private userAuth: UserAuthService,
        private languageChange: LanguageChangeService,
        private authService: AuthService,
        private storage: StorageService
    ) {}

    ngOnInit(): void {
        this.userInfo = JSON.parse(localStorage.getItem(this.sharedStorageKeys.USER_INFO)) as UserInfo;
        this.siteBranding = JSON.parse(
            localStorage.getItem(this.sharedStorageKeys.SITE_BRANDING) || '{}'
        ) as SiteBranding;
        this.navTarget = sessionStorage.getItem(this.sharedStorageKeys.HEADER_TARGET);

        this.activeLanguage = sessionStorage.getItem(this.sharedStorageKeys.CURRENT_LANGUAGE);
        this.activeCurrency = sessionStorage.getItem(this.sharedStorageKeys.CURRENT_CURRENCY);

        this.isModalModules =
            this.siteBranding?.uiConfig?.siteOptions?.moduleModalsForGuests && this.authService.isGuest();

        this.i18nRefreshService
            .getState()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.activeLanguage = sessionStorage.getItem(this.sharedStorageKeys.CURRENT_LANGUAGE);
            });

        this.headerService.target.pipe(takeUntil(this.unsubscribe)).subscribe((response: string) => {
            this.navTarget = response;
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    switchLanguage(language: string): void {
        if (this.activeLanguage === language) {
            return;
        }

        this.activeLanguage = language;
        sessionStorage.setItem(this.sharedStorageKeys.CURRENT_LANGUAGE, language);

        this.languageChange.update.emit(language);

        this.loaderService.show();
        this.i18nService
            .changeLanguage(language)
            .pipe(
                takeUntil(this.unsubscribe),
                finalize(() => this.loaderService.hide())
            )
            .subscribe(
                (response: DataTemplate<{ pointsName: string }>) => {
                    this.siteBranding.uiConfig.pointsOptions.pointsName = response?.data?.pointsName;
                    sessionStorage.setItem(this.sharedStorageKeys.SITE_BRANDING, JSON.stringify(this.siteBranding));
                    this.i18nRefreshService.updateState();
                },
                (error: string) => {
                    console.error(error);
                }
            );
    }

    switchCurrency(currency: string): void {
        if (this.activeCurrency === currency) {
            return;
        }

        if (sessionStorage.getItem(this.sharedStorageKeys.SHOW_CURRENCY_ALERT)) {
            this.dialog
                .open(ConfirmCurrencyChangeDialogComponent, {
                    data: {
                        offerDisclaimer:
                            sessionStorage.getItem(this.sharedStorageKeys.SHOW_CURRENCY_ALERT) === 'offers',
                    },
                })
                .afterClosed()
                .subscribe((response: boolean) => {
                    if (response) {
                        this.applySwitchCurrency(currency, true);
                    }
                });
        } else {
            this.applySwitchCurrency(currency);
        }
    }

    applySwitchCurrency(currency: string, redirect?: boolean): void {
        this.activeCurrency = currency;
        sessionStorage.setItem(this.sharedStorageKeys.CURRENT_CURRENCY, currency);
        sessionStorage.setItem(this.sharedStorageKeys.CURRENCY_CHANGED, 'true');

        this.loaderService.show();
        this.i18nService
            .changeCurrency(currency)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(
                (response: DataTemplate<AllowedCardTypes>) => {
                    this.userAuth
                        .refreshUserInfo()
                        .pipe(
                            takeUntil(this.unsubscribe),
                            finalize(() => this.loaderService.hide())
                        )
                        .subscribe(
                            () => {
                                if (response?.data?.cardTypes?.length) {
                                    this.siteBranding.uiConfig.localeOptions.cardTypes = response.data.cardTypes;
                                    sessionStorage.setItem(
                                        this.sharedStorageKeys.SITE_BRANDING,
                                        JSON.stringify(this.siteBranding)
                                    );
                                }

                                if (redirect) {
                                    if (
                                        sessionStorage.getItem(this.sharedStorageKeys.SHOW_CURRENCY_ALERT) === 'offers'
                                    ) {
                                        const offer = JSON.parse(sessionStorage.getItem('offer_to_book'));
                                        this.router.navigateByUrl(this.url.getUrl('offer-details', { id: offer.id }));
                                    } else {
                                        this.router.navigate(
                                            [
                                                this.url.getUrl(
                                                    `${sessionStorage.getItem(
                                                        this.sharedStorageKeys.SHOW_CURRENCY_ALERT
                                                    )}-search`
                                                ),
                                            ],
                                            {
                                                queryParams: {
                                                    sid: this.storage.getSID(),
                                                },
                                            }
                                        );
                                    }
                                } else {
                                    this.currencyChange.update.emit(true);
                                }
                            },
                            (error: string) => {
                                console.error(error);
                            }
                        );
                },
                (error: string) => {
                    console.error(error);
                }
            );
    }

    openRewardsDialog(): void {
        this.dialog.open(RewardsDialogComponent, {
            width: '100%',
            maxWidth: '640px',
        });
    }
}
