import { AssetValue } from '@shared/models';
import { Entity, Of } from '@utils';

@Entity
export class PointsSummary {
    @Of(() => Number)
    available: number;

    @Of(() => Number)
    redeemed: number;

    @Of(() => AssetValue)
    savings: AssetValue;

    static of = Entity.of<PointsSummary>();
}
