import { PayPriceBreakdown, TravelerProfile } from '@shared/models';

import { ItineraryItem } from './cruise-details';

export class BookResponse {
    confirmCode: string;
    cruiseImage: string;
    cruiseName: string;
    arriveDate: string;
    departDate: string;
    lineName: string;
    cruiseNoOfNights: number;
    shipName: string;
    cabinNo: string;
    cabinName: string;
    cabinCode: string;
    cabinBedType: string;
    cabinLocation: string;
    cabinMaxGuests: number;
    cabinDeckName: string;
    gradeCategoryName: string;
    gradeCategoryDesc: string;
    itineraryItems: ItineraryItem[];
    travelers: TravelerProfile[];
    rentalConditions: string;
    cancellationPolicy: string;
    payCardName: string;
    payCardType: string;
    payCardNumber: string;
    payCardExpDate: string;
    payPriceBreakdown: PayPriceBreakdown[];
    payTotalAmount: string;
    payPointsEarned: string;
    refundable: boolean;
    refundableUntil: string;
    txGuid: string;
    additionalInfo: AdditionalInformation;
}

interface AdditionalInformation {
    dining: DiningInformation;
    onboardCredit: string;
}

interface DiningInformation {
    seating: string;
    tableSize: string;
}
