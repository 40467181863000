import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataTemplate } from '@shared/models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

type ResponseMapper = (data: Partial<unknown>) => unknown;

const mapHttpResponse = <T>(response: DataTemplate<T>, mapper: ResponseMapper): DataTemplate<T> => {
    if (!mapper) {
        return response;
    }

    return {
        data: mapper(response.data) as T,
    };
};

interface ClientOptions {
    url: string;
    payload?: unknown;
    params?: HttpParams;
    mapper?: ResponseMapper;
}

@Injectable({
    providedIn: 'root',
})
export class AppHttpClient {
    constructor(private http: HttpClient) {}

    get<T>(options: ClientOptions): Observable<DataTemplate<T>> {
        return this.http
            .get(options.url, {
                params: options.params,
            })
            .pipe(map((response: DataTemplate<T>) => mapHttpResponse<T>(response, options.mapper)));
    }

    post<T>(options: ClientOptions): Observable<DataTemplate<T>> {
        return this.http
            .post(options.url, options.payload, {
                params: options.params,
            })
            .pipe(map((response: DataTemplate<T>) => mapHttpResponse<T>(response, options.mapper)));
    }

    put<T>(options: ClientOptions): Observable<DataTemplate<T>> {
        return this.http
            .put(options.url, options.payload, {
                params: options.params,
            })
            .pipe(map((response: DataTemplate<T>) => mapHttpResponse<T>(response, options.mapper)));
    }

    delete<T>(options: ClientOptions): Observable<DataTemplate<T>> {
        return this.http
            .delete(options.url, {
                params: options.params,
            })
            .pipe(map((response: DataTemplate<T>) => mapHttpResponse<T>(response, options.mapper)));
    }
}
