import { CardProfile } from '@shared/models';
import { Entity, Of } from '@utils';

@Entity
export class MembershipBookRequest {
    @Of(() => Number)
    membershipId = 0;

    @Of(() => Number, { nullable: true, optional: true })
    ppId?: number = null;

    @Of(() => CardProfile, { nullable: true, optional: true })
    card: CardProfile;

    @Of(() => Boolean, { nullable: true, optional: true })
    saveCard = false;

    static of = Entity.of<MembershipBookRequest>();
}
