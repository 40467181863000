export class TokenInfo {
    userId: string;
    accessToken: string;
    expiresIn: number;

    constructor(userId: string, accessToken: string, expiresIn: number) {
        this.userId = userId;
        this.accessToken = accessToken;
        this.expiresIn = expiresIn;
    }
}
