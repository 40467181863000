import { AdobeAnalyticsDetails, GoogleAnalyticsDetails, SelectOption } from '@shared/models';

import { BOOKING_QUESTIONS } from '../booking-questions';

import { LangService, Schedule } from './schedule';

export class ActivityDetails {
    id: string;
    images: string[];
    location: string;
    duration: string;
    name: string;
    rating: number;
    description: string;
    additionalInfo: string[];
    inclusions: string[];
    exclusions: string[];
    itinerary: Itinerary;
    ticketInfo: TicketInfo;
    scheduleDetails: ScheduleDetails;
    schedules: Schedule[];
    langServices: LangService[] = [];
    bookingQuestions?: BookingQuestion[];
    bookingRequirements?: BookingRequirements;
    travelerPickup?: TravelerPickup;
    logisticsStart: PickupLocation[] = [];
    logisticsEnd: PickupLocation[] = [];
    reviews?: Review[];
    refundable?: boolean;
    refundableUntil?: string;
    information: string;
    ageBands: AgeBand[];
    ga: GoogleAnalyticsDetails;
    oa: AdobeAnalyticsDetails;

    index?: number;
    goodStars?: number[];
    badStars?: number[];
}

export class Itinerary {
    itineraryItems: ItineraryItem[];
    unstructuredDescription: string;
}

export class ItineraryItem {
    description: string;
    admissionIncluded: 'YES' | 'NO';
    passByWithoutStopping: boolean;
    pointOfInterestLocation: PointOfInterestLocation;
}

export class PointOfInterestLocation {
    location: LocationItem;
}

export class TravelerPickup {
    additionalInfo?: string;
    allowCustomTravelerPickup?: boolean;
    locations: PickupLocation[];
    pickupOptionType: 'PICKUP_EVERYONE' | 'PICKUP_AND_MEET_EVERYONE_AT_START_POINT' | 'MEET_EVERYONE_AT_START_POINT';
}

export class PickupLocation {
    pickupType?: 'HOTEL' | 'AIRPORT' | 'PORT' | 'LOCATION' | 'OTHER';
    description?: string;
    location: LocationItem;
}

export class LocationItem {
    details: LocationDetails;
    ref: string;
}

export class LocationDetails {
    reference: string;
    name: string;
    formattedAddress?: string;
    address?: LocationAddress;

    static formatAddress(location: LocationDetails): string {
        if (!location) return '';

        if (location.formattedAddress) {
            return location.formattedAddress;
        }

        if (location.address) {
            const addressItems = [
                location.address.street,
                location.address.administrativeArea,
                location.address.country,
                location.address.postcode,
                location.address.countryCode,
            ];

            return addressItems.filter((item) => item).join(', ');
        }

        return '';
    }
}

export class LocationAddress {
    administrativeArea: string;
    country: string;
    countryCode: string;
    postcode: string;
    state: string;
    street: string;
}

export class TicketInfo {
    ticketTypes: ('PAPER' | 'MOBILE_ONLY')[] = [];
}

export class ScheduleDetails {
    departurePoint: string;
    duration: string;
    departureTime: string;
    returnDetails: string;
    cancellationPolicy: string;
}

export class BookingQuestion {
    id: BOOKING_QUESTIONS;
    title: string;
    subtitle: string;
    message: string;
    required: boolean;
    options?: SelectOption[];
    details: QuestionDetails;
}

export class BookingRequirements {
    maxTravelersPerBooking: number;
    minTravelersPerBooking: number;
    requiresAdultForBooking: boolean;
}

export class QuestionDetails {
    id: string;
    group: 'PER_BOOKING' | 'PER_TRAVELER';
    required?: 'OPTIONAL' | 'MANDATORY';
    maxLength?: number;
    allowedAnswers?: string[];
    type: 'DATE' | 'SELECT' | 'STRING' | 'NUMBER_AND_UNIT';
    units?: string[] = [];
}

export class Review {
    ownerName: string;
    publishedDate: string;
    rating: number;
    review: string;
    goodStars?: any[];
    badStars?: any[];
}

export class AgeBand {
    bandId: string;
    ageBand: string;
    description: string;
    endAge: number;
    maxTravelersPerBooking: number;
    minTravelersPerBooking: number;
    startAge: number;
}

export class ActivityHotel {
    id: string;
    name: string;
}
