<div class="login-header flex-container justify-content-space-between">
    <a
        *ngIf="!logoUrl.startsWith('http')"
        class="site-name-link flex-container"
        [routerLink]="logoUrl"
        [attr.aria-label]="siteBranding?.uiConfig?.siteOptions?.siteName"
    >
        <div class="confirmation-logo"></div>
    </a>

    <a
        *ngIf="logoUrl.startsWith('http')"
        class="site-name-link flex-container"
        [attr.href]="logoUrl"
        [attr.aria-label]="siteBranding?.uiConfig?.siteOptions?.siteName"
    >
        <div class="confirmation-logo"></div>
    </a>

    <div class="flex-container align-items-center">
        <app-navigation-phones [onLogin]="true"></app-navigation-phones>
        <app-navigation-locales [onLogin]="true"></app-navigation-locales>
    </div>
</div>
