import { AbstractControl, ValidatorFn } from '@angular/forms';

export function americanExpressLength(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null =>
        control.value?.length === 15 ? null : { invalid: true };
}

export function americanExpressStartsWith(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null =>
        control.value?.startsWith('34') || control.value?.startsWith('37') ? null : { invalid: true };
}
