import { StorageKeys as ActivitiesStorageKeys } from '@activities/models';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StorageKeys as CardsStorageKeys } from '@cards/models';
import { StorageKeys as CarsStorageKeys } from '@cars/models';
import { StorageKeys as CruisesStorageKeys } from '@cruises/models';
import { StorageKeys as EscapesStorageKeys } from '@escapes/models';
import { StorageKeys as FlightsStorageKeys } from '@flights/models';
import { StorageKeys as HotelsStorageKeys } from '@hotels/models';
import { NguCarouselConfig } from '@ngu/carousel';
import { BestDeal } from '@shared/models';
import { UrlService } from '@shared/services';
import { StorageKeys as WeeksStorageKeys } from '@weeks/models';

@Component({
    selector: 'app-best-deals',
    templateUrl: './best-deals.component.html',
    styleUrls: ['./best-deals.component.scss'],
})
export class BestDealsComponent implements OnInit, AfterViewInit {
    @Input()
    tiles: BestDeal[];

    @Input()
    title: string;

    @Input()
    hasDetailsPage: boolean;

    @Input()
    module: string;

    @Input()
    originPointLabel: string;

    @Output()
    resolveSavedSearch: EventEmitter<BestDeal> = new EventEmitter<BestDeal>();

    dealsCarouselTileConfig: NguCarouselConfig = {
        grid: { xs: 1, sm: 2, md: 2, lg: 3, all: 0 },
        speed: 250,
        point: {
            visible: true,
        },
        touch: false,
        loop: true,
        animation: 'lazy',
    };

    fragment: string;

    @ViewChild('dealsCarouselPrev') dealsCarouselPrev: ElementRef;
    @ViewChild('dealsCarouselNext') dealsCarouselNext: ElementRef;

    constructor(private route: ActivatedRoute, public url: UrlService) {}

    ngOnInit(): void {
        this.route.fragment.subscribe((fragment) => {
            this.fragment = fragment;
        });
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            if (this.fragment) {
                document.querySelector(`#${this.fragment}`).scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }

    triggerDealsCarouselNav(direction: string): void {
        switch (direction) {
            case 'prev':
                this.dealsCarouselPrev.nativeElement.click();
                break;
            case 'next':
                this.dealsCarouselNext.nativeElement.click();
                break;
        }
    }

    setOriginPoint(): void {
        if (this.getModuleKeys()) {
            sessionStorage.setItem(this.getModuleKeys().SEARCH_START_TIME, new Date().getTime().toString());
            sessionStorage.setItem(this.getModuleKeys().SEARCH_ORIGIN_POINT, this.originPointLabel);
            sessionStorage.setItem(this.getModuleKeys().SEARCH_TRIGGERED, 'true');
        }
    }

    private getModuleKeys(): any {
        switch (this.module) {
            case 'hotels':
                return HotelsStorageKeys;
            case 'flights':
                return FlightsStorageKeys;
            case 'cars':
                return CarsStorageKeys;
            case 'cruises':
                return CruisesStorageKeys;
            case 'weeks':
                return WeeksStorageKeys;
            case 'activities':
                return ActivitiesStorageKeys;
            case 'cards':
                return CardsStorageKeys;
            case 'escapes':
                return EscapesStorageKeys;
        }
    }
}
