import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'formatCardLabel',
})
export class FormatCardLabelPipe implements PipeTransform {
    transform(value: any): any {
        switch (value) {
            case 'MAST':
                return 'Mastercard';
            case 'AMEX':
                return 'American Express';
            case 'VISA':
                return 'Visa';
            case 'DISC':
                return 'Discover';
            default:
                return value;
        }
    }
}
