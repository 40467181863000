import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
    name: 'sortBy',
})
export class SortByPipe implements PipeTransform {
    transform(value: any[], order: _.Many<boolean | 'asc' | 'desc'> = 'asc', column = ''): any[] {
        if (!value || !order) {
            return value;
        }

        if (!column || column === '') {
            return _.sortBy(value);
        }

        if (value.length <= 1) {
            return value;
        }

        return _.orderBy(value, [column], [order] as _.Many<boolean | 'asc' | 'desc'>);
    }
}
