import { SearchRequest as Activities } from '@activities/models';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SearchRequest as Cars } from '@cars/models';
import { SearchRequest as Cruises } from '@cruises/models';
import { SearchRequest as Flights } from '@flights/models';
import { SearchRequest as Hotels } from '@hotels/models';
import { Modules } from '@utils';
import { SearchRequest as Weeks } from '@weeks/models';

type _SearchRequest = Hotels | Cars | Cruises | Flights | Weeks | Activities;

@Component({
    selector: 'app-recent-searches',
    templateUrl: './recent-searches.component.html',
    styleUrls: ['./recent-searches.component.scss'],
})
export class RecentSearchesComponent {
    @Input()
    searches: any[] = [];

    @Input()
    module: Modules;

    @Output()
    onSelect: EventEmitter<any> = new EventEmitter<any>();

    modules = Modules;
}
