import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RoomGuests, SelectedRoom } from '@shared/models';

@Component({
    selector: 'app-rooms-selector',
    templateUrl: './rooms-selector.component.html',
    styleUrls: ['./rooms-selector.component.scss'],
})
export class RoomsSelectorComponent {
    @Input()
    isFixed: boolean;

    @Input()
    isActive: boolean;

    @Input()
    maxGuestsPerRoom = 6;

    @Input()
    maxGuests = 10;

    @Input()
    hotelDetailsPage: boolean;

    @Input()
    adultsCounter: number;

    @Input()
    childrenCounter: number;

    @Input()
    set roomGuests(value: RoomGuests[]) {
        this.populateContent(value);
    }

    @Output()
    onUpdateRooms: EventEmitter<SelectedRoom[]> = new EventEmitter<SelectedRoom[]>();

    showCounters: boolean;
    disableAdultsIncrement: boolean;
    disableChildrenIncrement: boolean;
    selectedAge: boolean;

    rooms: SelectedRoom[] = [
        {
            adults: 2,
            children: 0,
            childrenAges: [],
        },
    ];

    ageValues: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17];

    populateContent(value: RoomGuests[]): void {
        if (value?.length) {
            this.rooms = value.map((room) => {
                return {
                    adults: room.adults,
                    children: room.childrenAges ? room.childrenAges.length : 0,
                    childrenAges: room.childrenAges || [],
                };
            });
        }

        this.computeGuests();
    }

    computeGuests(): void {
        this.adultsCounter = this.rooms.map((r) => r.adults).reduce((acc = 0, curr) => curr + acc);
        this.childrenCounter = this.rooms.map((r) => r.children).reduce((acc = 0, curr) => curr + acc);
    }

    addRoom(): void {
        this.rooms = [
            ...this.rooms,
            {
                adults: 1,
                children: 0,
                childrenAges: [],
            },
        ];

        this.computeGuests();

        setTimeout(() => {
            this.showCounters = true;
        }, 0);
    }

    removeRoom(roomIndex: number): void {
        this.rooms.splice(roomIndex, 1);
        this.computeGuests();

        setTimeout(() => {
            this.showCounters = true;
        }, 0);
    }

    updateGuests(): void {
        if (this.selectedAge) {
            this.selectedAge = false;
        } else if (this.showCounters) {
            this.onUpdateRooms.emit(this.rooms);
            this.showCounters = false;
        }
    }

    updateAdults(amount: number, roomIndex: number): void {
        this.rooms[roomIndex].adults += this.rooms[roomIndex].adults > 1 ? amount : amount < 0 ? 0 : amount;
        this.computeGuests();
    }

    updateChildren(amount: number, roomIndex: number): void {
        this.rooms[roomIndex].children += this.rooms[roomIndex].children > 0 ? amount : amount < 0 ? 0 : amount;

        if (this.rooms[roomIndex].children > this.rooms[roomIndex].childrenAges?.length || 0) {
            this.rooms[roomIndex].childrenAges.push(0);
        } else {
            this.rooms[roomIndex].childrenAges.splice(this.rooms[roomIndex].childrenAges.length - 1, 1);
        }

        this.computeGuests();
    }

    checkMaxOccupancy(roomIndex: number): boolean {
        if (this.rooms[roomIndex].adults + this.rooms[roomIndex].children === this.maxGuestsPerRoom) {
            return true;
        }

        return false;
    }

    checkMaxAdults(roomIndex: number): boolean {
        if (this.rooms[roomIndex].adults === this.maxGuestsPerRoom - this.rooms[roomIndex].children) {
            return true;
        }

        return false;
    }

    checkMaxChildren(roomIndex: number): boolean {
        if (this.rooms[roomIndex].children === this.maxGuestsPerRoom - this.rooms[roomIndex].adults) {
            return true;
        }

        return false;
    }

    addListeners(): void {
        setTimeout(() => {
            document.querySelectorAll('.cdk-overlay-container').forEach((el: Element) => {
                el.addEventListener('click', () => {
                    this.selectedAge = true;
                });
            });
        }, 0);
    }

    removeListeners(): void {
        document.querySelectorAll('.cdk-overlay-container').forEach((el: Element) => {
            el.removeEventListener('click', null);
        });
    }
}
