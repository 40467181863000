import { RouterModule, Routes } from '@angular/router';
import { AssistMemberComponent, AutoLoginComponent, LoginComponent, PageComponent } from '@core/components';
import { I18nModuleResolverService } from '@shared/services';

const routes: Routes = [
    {
        path: 'login',
        component: LoginComponent,
        data: {
            title: 'Login / Register',
        },
        resolve: {
            keys: I18nModuleResolverService,
        },
    },

    {
        path: 'pages/:code',
        component: PageComponent,
        data: {
            title: 'Page',
        },
    },

    {
        path: 'assist/:token/:mid',
        component: AssistMemberComponent,
        data: {
            title: 'Member Assist',
        },
    },

    {
        path: 'sso/:jwt',
        component: AutoLoginComponent,
        data: {
            title: 'Member Auto Login',
        },
    },
];

export const CoreRoutes = RouterModule.forChild(routes);
