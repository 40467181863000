import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class DisableTravelerProfileService {
    enable: EventEmitter<number> = new EventEmitter<number>();
    disable: EventEmitter<number> = new EventEmitter<number>();
    switch: EventEmitter<{ prev: number; next: number }> = new EventEmitter<{ prev: number; next: number }>();
    target: EventEmitter<number> = new EventEmitter<number>();
    checkDisable: EventEmitter<void> = new EventEmitter<void>();
    toggleProfiles: EventEmitter<{ target: string; value: boolean; restrictTraveler?: boolean; index?: number }> =
        new EventEmitter<{
            target: string;
            value: boolean;
            restrictTraveler?: boolean;
            index?: number;
        }>();
}
