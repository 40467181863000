import { CancellationResponse } from '@account/models';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SiteBranding } from '@core/models';
import { SharedStorageKeys } from '@shared/models';
import { UrlService } from '@shared/services';

@Component({
    selector: 'app-cancellation-confirmation',
    templateUrl: './cancellation-confirmation.component.html',
    styleUrls: ['./cancellation-confirmation.component.scss'],
})
export class CancellationConfirmationComponent implements OnInit {
    sharedStorageKeys = SharedStorageKeys;
    cancellationResponse: CancellationResponse;
    siteBranding: SiteBranding;

    constructor(private route: ActivatedRoute, private router: Router, private url: UrlService) {}

    ngOnInit(): void {
        this.siteBranding = JSON.parse(localStorage.getItem(this.sharedStorageKeys.SITE_BRANDING)) as SiteBranding;
        this.cancellationResponse = JSON.parse(sessionStorage.getItem('cancellation_response')) as CancellationResponse;
        sessionStorage.removeItem('cancellation_response');

        if (!this.cancellationResponse) {
            this.router.navigateByUrl(this.url.getUrl(this.route.snapshot.data['module']));
        }
    }
}
